<section class="flex flex-col p-20 m-10 rounded bg-gray-lightest gap-y-20">
  <h1 class="font-bold" *ngIf="!!purchaseModel.Title">{{purchaseModel.Title}} Purchase Options</h1>
  <p *ngIf="!!purchaseModel.PurchaseOptions">
    Select a purchase option below:
  </p>

  <form [formGroup]="paymentForm" (ngSubmit)="purchase()" class="flex flex-col gap-y-20">
    <mat-radio-group formControlName="selectedPurchaseOption" class="flex flex-col" [(ngModel)]="selectedPurchaseOption"
      (ngModelChange)="getPaymentAmount()">
      <mat-radio-button *ngFor="let po of purchaseModel.PurchaseOptions index as i" [value]="i"
        [checked]="i === selectedPurchaseOption">
        <span class="font-bold">{{po.Description}} - ${{po.Amount}}</span>
      </mat-radio-button>
    </mat-radio-group>
    <div class="flex flex-col gap-y-20"
      *ngIf="!!purchaseModel.AgentMarketingDollars && purchaseModel.AgentMarketingDollars > 0">
      <p>
        You currently have&nbsp;&nbsp;<span class="font-bold text-blue-dark">{{purchaseModel.AgentMarketingDollars |
          currency}}</span>&nbsp;&nbsp;marketing dollars available. Would you like to use any for this purchase?
      </p>
      <div class="flex items-center justify-between gap-x-20">
        <div class="flex items-center">
          <label for="marketingDollarsToUse" class="mr-10 text-sm text-gray">Marketing Dollars To Use: </label>
          <input formControlName="marketingDollarsToUse" [(ngModel)]="marketingDollarsToUse" currencyMask class="input"
            (ngModelChange)="getPaymentAmount()" />
        </div>
        <div class="flex items-center">
          <span class="mr-10 text-sm text-gray">You Pay:</span>
          <p class="font-bold text-blue-dark">{{paymentAmount | currency}}</p>
        </div>
      </div>
    </div>
    <div class="flex flex-row items-center justify-between mt-10">
      <!-- <a target="_blank" href="{{purchaseModel.TermsLink}}" *ngIf="purchaseModel.TermsLink" class="text-lg text-blue">
        View Terms
      </a> -->
      <a mat-button class="flex items-center justify-center button is-primary is-outlined" target="_blank"
        href="{{purchaseModel.TermsLink}}" *ngIf="purchaseModel.TermsLink">
        <span class="leading-normal">View Terms</span>
      </a>
      <button type="submit" class="ml-auto button is-primary " mat-button
        [disabled]="selectedPurchaseOption === undefined">Purchase Renewal</button>
    </div>
  </form>

  <!--
  <form name="paymentForm">
    <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedPurchaseOption"
      [ngModelOptions]="{standalone: true}"
      (ngModelChange)="paymentAmount = purchaseModel.PurchaseOptions[(selectedPurchaseOption)].Amount">
      <mat-radio-button *ngFor="let po of purchaseModel.PurchaseOptions index as i" value="{{(i | number)}}">
        <span *ngIf="!purchaseModel.PurchaseOptions[(selectedPurchaseOption)].PurchaseSeats">{{po.Description}} -
          ${{po.Amount}}</span>
        <span *ngIf="purchaseModel.PurchaseOptions[(selectedPurchaseOption)].PurchaseSeats">{{po.Description}}</span>
      </mat-radio-button>
    </mat-radio-group>

    <div
    *ngIf="((!purchaseModel.PurchaseOptions[(selectedPurchaseOption)].IsRecurringPayment) || purchaseModel.PurchaseOptions)">
      <p *ngIf="!!purchaseModel.AgentMarketingDollars && purchaseModel.AgentMarketingDollars > 0" class="instructions">
        You currently have&nbsp;&nbsp;<span class="is-blue-light">{{purchaseModel.AgentMarketingDollars |
          currency}}</span>&nbsp;&nbsp;marketing dollars available. Would you like to use any for this purchase?
      </p>
      <div class="marketing-dollars is-blue-light" *ngIf="!!purchaseModel.AgentMarketingDollars && purchaseModel.AgentMarketingDollars > 0">
        <div>Marketing Dollars To Use: </div>
        <span>$&nbsp;</span><input class="input has-py-half" [(ngModel)]="marketingDollarsToUse" type="number"
          (ngModelChange)="getPaymentAmount()" [ngModelOptions]="{standalone: true}"/>



      </div>
      <div class="payment">
        <div class="you-pay">You Pay: </div>
        <span>{{paymentAmount | currency}}</span>
      </div>
    </div>

    <div class="purchase">
      <a target="_blank" href="{{purchaseModel.TermsLink}}" *ngIf="purchaseModel.TermsLink">
        View Terms
      </a>
      <button mat-button class="button"
        (click)="purchase(purchaseModel.PurchaseOptions[selectedPurchaseOption])">
        <i class="fa fa-circle-o-notch fa-spin" aria-hidden="true" *ngIf="submittingOrder"></i>
        <span *ngIf="!submittingOrder">Purchase</span>
      </button>
      <button mat-button class="button" (click)="goBack()" *ngIf="!submittingOrder">Cancel</button>

      <!--
      <md-button class="md-accent" ng-disabled="!$ctrl.selectedPurchaseOption || (!!$ctrl.purchaseModel.PurchaseOptions[$ctrl.selectedPurchaseOption].ClassDates ? !$ctrl.selectedClassDate : false) || $ctrl.submittingOrder || ($ctrl.paymentAmount <= 0 && $ctrl.marketingDollarsToUse <= 0) || !paymentForm.$valid" ng-click="$ctrl.purchase($ctrl.purchaseModel.PurchaseOptions[$ctrl.selectedPurchaseOption])">
          <i class="fa fa-circle-o-notch fa-spin" aria-hidden="true" ng-if="$ctrl.submittingOrder"></i>
          <span ng-if="!$ctrl.submittingOrder">Purchase</span>
      </md-button>
      <md-button class="md-accent" ng-click="$ctrl.goBack()" ng-if="!$ctrl.submittingOrder">
          <span>Cancel</span>
      </md-button>
    --
    </div>

    <div class="error" *ngIf="error">
      There was an error submitting this order, please contact support if the issue persists.
    </div>
  </form>-->
</section>