import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Table } from 'src/app/shared/classes/table';
import { InvestmentTableHeaders } from '../../constants/investmentPolicyTableHeaders.constants';
import { RiaAccount } from '../../models/riaAccount.model';

@Component({
  selector: 'app-investment-policy-table',
  templateUrl: './investment-policy-table.component.html'
})
export class InvestmentPolicyTableComponent implements OnChanges {
  @Input() riaAccounts: RiaAccount[] = [];
  @Input() reportType!: string;
  table: Table<RiaAccount> = new Table<RiaAccount>(InvestmentTableHeaders, 1, true);
  isLoading: boolean = true;
  isError: boolean = false;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes[ 'riaAccounts' ]?.isFirstChange() && changes[ 'riaAccounts' ]?.previousValue !== changes[ 'riaAccounts' ]?.currentValue) {
      this.table.setBody(this.riaAccounts);
      this.isLoading = false;
    }
  }
}
