import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fadeIn } from 'src/app/animations';
import { PermissionService } from 'src/app/core/services/auth/permission.service';
import { industryEvents } from 'src/app/shared/constants/industry-event.constants';
import { IndustryEvent } from 'src/app/shared/models/industry-event.models';

@Component({
  animations: [fadeIn],
  selector: 'app-industry-event',
  templateUrl: './industry-event.component.html'
})
export class IndustryEventComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private permissionService: PermissionService,
  ) { }
  industryEvent?: IndustryEvent;
  eventsList: IndustryEvent[] = industryEvents;
  @ViewChild('toolboxContainerContent')
  private toolboxContentDiv!: ElementRef<HTMLElement>

  ngOnInit(): void {
    const eventUrl: string = this.activatedRoute.snapshot.paramMap.get('eventUrl')!;
    this.industryEvent = this.eventsList.find(event => {
      return event.Url === eventUrl;
    });
    if (!!this.industryEvent?.Permissions && !this.permissionService.hasOnePermission(this.industryEvent.Permissions)) window.location.href = '/NewPortal/Portal/Toolbox';
    // added to fix scroll to top issue
    (this.toolboxContentDiv?.nativeElement as HTMLElement).scrollTop = 0;
  }
}
