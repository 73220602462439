<div class="flex flex-col items-center" @fadeIn>
    <!-- <p class="text-sm uppercase">Countdown to full rollout:</p>
    <span class="ml-auto font-sans text-lg text-gray-darkest" id="demo" [innerHtml]="result"></span> -->
    <div class="flex items-center">
        <!-- <ng-container *ngFor="let unit of results | keyvalue:keepOriginalOrder; let last = last"> 
            <div class="flex flex-col items-center gap-y-5">
                <div
                    class="flex-col border-2 w-60 h-72 border-blue-light bg-gradient-to-b from-blue-dark to-blue-darker">
                    <span class="text-3xl text-white" [innerHtml]=""></span>
                </div>
                <span class="text-sm text-center uppercase text-blue-lighter">Days</span>
            </div>
            <span class="w-1 h-20 mx-10 bg-blue" [innerHtml]="unit.key"></span>
        </ng-container>-->
        <div class="flex flex-col items-center gap-y-5">
            <div
                class="flex flex-col items-center justify-center border-2 rounded w-60 h-50 border-blue-light bg-gradient-to-b from-blue-darker to-blue-darkest">
                <span class="-mt-2 text-3xl font-bold leading-none text-white" [innerHtml]="results.Days" [@slideInOut]="!!results"></span>
            </div>
            <span class="text-xs leading-tight text-center uppercase text-blue-lighter">Days</span>
        </div>
        <span class="w-1 h-20 mx-10 mb-24 bg-blue"></span>
        <div class="flex flex-col items-center rounded gap-y-5">
            <div
                class="flex flex-col items-center justify-center border-2 rounded w-60 h-50 border-blue-light bg-gradient-to-b from-blue-darker to-blue-darkest">
                <span class="-mt-2 text-3xl font-bold leading-none text-white" [innerHtml]="results.Hours" [@slideInOut]="!!results"></span>
            </div>
            <span class="text-xs leading-tight text-center uppercase text-blue-lighter">Hours</span>
        </div>
        <span class="w-1 h-20 mx-10 mb-24 bg-blue"></span>
        <div class="flex flex-col items-center gap-y-5">
            <div
                class="flex flex-col items-center justify-center border-2 rounded w-60 h-50 border-blue-light bg-gradient-to-b from-blue-darker to-blue-darkest">
                <span class="-mt-2 text-3xl font-bold leading-none text-white" [innerHtml]="results.Minutes" [@slideInOut]="!!results"></span>
            </div>
            <span class="text-xs leading-tight text-center uppercase text-blue-lighter">Minutes</span>
        </div>
        <span class="w-1 h-20 mx-10 mb-24 bg-blue"></span>
        <div class="flex flex-col items-center gap-y-5">
            <div
                class="flex flex-col items-center justify-center border-2 rounded w-60 h-50 border-blue-light bg-gradient-to-b from-blue-darker to-blue-darkest">
                <span class="-mt-2 text-3xl font-bold leading-none text-white" [innerHtml]="results.Seconds" [@slideInOut]="!!results"></span>
            </div>
            <span class="text-xs leading-tight text-center uppercase text-blue-lighter">Seconds</span>
        </div>
    </div>
</div>