import { Injectable } from '@angular/core';
import { Routes, RouterModule, Data, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PathToDataStoreService {

  private pathToDataMap: {[key: string]: Data|undefined} = {};

  constructor(private router: Router) {
    this.dfs(this.router.config, '');
  }

  private dfs(list: Routes, parentPath:string){
    list.forEach((item) => {
      let path = item.path !== '' ? parentPath + '/' + item.path : parentPath;
      if (item.path !== '') {
        const n = path.indexOf(':');
        if (n > 0) {
          path = path.substring(n-1,0);
        }

        this.pathToDataMap[path] = item.data;
      }

      if (typeof item.children !== 'undefined' && item.children.length > 0) {
        this.dfs(item.children, path);
      }
    });
  }

  dataLookup(path: string): Data|undefined {
    let data: Data|undefined;
    let newPath = path;
    do {
      data = this.pathToDataMap[newPath];
      const n = newPath.lastIndexOf('/');
      newPath = newPath.substring(n,0);
    } while (typeof data === 'undefined' && newPath !== '');
    return data;
  }

  stateLookUp(path: string): string {
    const data = this.dataLookup(path);
    if (data && data.state) {
      // check to see if this is a resource state like the FigPlatform/ForceCrm or FigPlatform/Hegna, etc
      if (data.isDynamicState) {
        const n = path.lastIndexOf('/');
        const resource = path.substring(n+1);
        return data.state + '.' + resource;
      }
      return data.state;
    }
    return 'Undefined State';
  }
}
/*
let dfs = function(list, parentPath) {
  list.forEach((item) => {
    let path = item.path !== "" ? parentPath + "/" + item.path : parentPath;
    if (item.path !== "") {
      var n = path.indexOf(":");
      if (n > 0) {
        path = path.substring(n-1,0);
      }

      pathToDetailsMap[path] = item.data;
    }

    if (item.children.length > 0) {
      dfs(item.children, path);
    }
  });
}

dfs(routes, "");

let pathLookup = function(path) {
  let title;
  let newPath = path;
  do {
    title = pathToDetailsMap[newPath];
    let n = newPath.lastIndexOf("/");
    newPath = newPath.substring(n,0);
  } while (typeof title === 'undefined')
  return title;
}
*/