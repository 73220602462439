import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OnboardingComponent } from './onboarding.component';
import { AgreementComponent } from './components/agreement/agreement.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { CarrierSetupComponent } from './components/carrier-setup/carrier-setup.component';
import { TrainingComponent } from './components/training/training.component';
import { PortalPreviewComponent } from './components/portal-preview/portal-preview.component';
import { UserMasterAdvisorAgreementResolver } from '../core/services/resolvers/user-master-advisor-agreement-resolver';
import { ContractingCompletedResolver } from '../core/services/resolvers/contracting-completed-resolver';
import { UserMasterAdvisorAgreementRequiredResolver } from '../core/services/resolvers/user-master-advisor-agreement-required-resolver';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { PlatformConfigurationComponent } from './components/platform-configuration/platform-configuration.component';
import { AgentLicenseOptOutResolver } from '../core/services/resolvers/crd-npn-opt-out-resolver';
import { AuthenticationGuard } from '../core/services/guards/authentication-guard/authentication-guard.service';
import { LoggedInUserTermsOfUseCompletedResolver } from '../core/services/resolvers/logged-in-user-terms-of-use-completed.resolver';

const routes: Routes = [
  {
    path: 'Onboarding',
    canActivate: [AuthenticationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: '',
        component: OnboardingComponent,
        resolve: {
        },
        children: [
          {path: 'Welcome', component: WelcomeComponent},
          {path: 'TermsOfUse', component: TermsOfUseComponent},
          {path: 'PlatformConfiguration', component: PlatformConfigurationComponent,
            resolve: {
              userAgentLicensesOptOut: AgentLicenseOptOutResolver
            }},
          {
            path: 'Agreement',
            component: AgreementComponent,
            resolve: {
              userMasterAdvisorAgreement: UserMasterAdvisorAgreementResolver,
              userMasterAdvisorAgreementRequired: UserMasterAdvisorAgreementRequiredResolver
            }
          },
          {
            path: 'CarrierContracting',
            component: CarrierSetupComponent,
            resolve : {
              userContracting: ContractingCompletedResolver
            }
          },
          {path: 'Training', component: TrainingComponent},
          {path: 'PortalPreview', component: PortalPreviewComponent},
          {path: '', redirectTo: 'Welcome', pathMatch: 'full',}
        ]
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OnboardingRoutingModule { }
