export interface ListResponse<T> {
  Entries: T[];
}

export interface SingleResponse<T>{
  Entries: T;
}

export interface EntryResponse<T>{
  Entry: T;
}

export enum EnvironmentEnum {
  'local',
  'qa',
  'beta',
  'prod'
}

export interface BasicModel {
  Id: number;
  Title: string;
}

export interface StateBasicModel {
  ShortName: string;
  LongName: string;
}

export interface State{
  StateID: number|null;
  State: string;
  StateName: string|null;
  IsVisible: boolean|null;
}