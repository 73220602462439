import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from '../../modals/confirmation/confirmation.component';
import { RenewalFormComponent } from '../../modals/renewal-form/renewal-form.component';
import { EnrollmentFormApiService } from 'src/app/core/services/http/enrollment-form-api.service';
import { EnrollmentFormComponent } from '../../modals/enrollment-form/enrollment-form.component';
import { PurchaseModalComponent } from '../../modals/purchase-modal/purchase-modal.component';
import { SurveyPurchaseModel, SurveyPurchaseOptionsModel } from 'src/app/shared/models/surveyMarketingStrategy.models';
import { PurchaseApiService } from 'src/app/core/services/http/purchase-api.service';
import { forkJoin } from 'rxjs';
import { MarketingStrategy } from 'src/app/shared/models/marketingStrategy.models';
import { SubmitApplicationModalComponent } from '../../modals/submit-application-modal/submit-application-modal.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SimonIframeData } from 'src/app/shared/models/simon-iframe-data.model';
import { User } from 'src/app/shared/models/user.models';
import { UserGroup } from 'src/app/shared/models/usergroups.models';
import { SureLcSsoUser } from 'src/app/shared/models/sso.models';
import { SsoApiService } from 'src/app/core/services/http/sso-api.service';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { LifeInsuranceResourcesApiService } from 'src/app/core/services/http/airtable-api/life-insurance-resources-api.service';
import { SimonApiService } from 'src/app/core/services/http/simon-api.service';
import { ConfirmationData } from 'src/app/shared/models/modal-data.models';
import { fadeIn } from 'src/app/animations';
import { EAppPreferenceSelectorComponent } from '../product-rd/components/e-application/modals/e-app-preference-selector/eapp-preference-selector.component';

@Component({
  selector: 'app-angular-sandbox',
  templateUrl: './angular-sandbox.component.html',
  animations: [fadeIn]
})
export class AngularSandboxComponent implements OnInit {
  genericOptions: string[] = ['Option 1', 'Option 2', 'Option 3'];
  favoriteOption: string = this.genericOptions[0];
  panelOpenState: boolean = false;
  items: string[] = ['item 1', 'item 2', 'item 3'];
  selectedDate: Date = new Date();
  bobForm = new FormGroup({
    name: new FormControl(),
    height: new FormControl('6')
  });
  user!: User;
  userGroup!: UserGroup | null;
  onBehalfOfUserGroup!: UserGroup | null;
  sureLcUser!: SureLcSsoUser;
  iframeUrl: string = 'https://www.simonmarkets.com/simon/#/learning-center2/annuities/resources';
  licenses: any;
  simonPolicies!: any;
  limit: number = 100;

  constructor(private dialog: MatDialog, private enrollmentFormApiService: EnrollmentFormApiService, private purchaseApiService: PurchaseApiService,
    private ssoApiService: SsoApiService, private sessionStore: SessionStoreService, private lifeInsuranceResourcesApiService: LifeInsuranceResourcesApiService,
    private simonApiService: SimonApiService) { }
  ngOnInit(): void {
    this.user = this.sessionStore.User;
    this.userGroup = this.sessionStore.UserGroup;
    this.onBehalfOfUserGroup = this.sessionStore.OnBehalfOfUserGroup;

    this.ssoApiService.getSureLcUser()
      .subscribe(res => {
        this.sureLcUser = res;
      });
  }

  openApplicationOptinDialog(): void {
    const dialogRef = this.dialog.open<EAppPreferenceSelectorComponent>(EAppPreferenceSelectorComponent);
    dialogRef.afterClosed().subscribe({
      next: (res: boolean) => {
        alert(`Opt into Quinci Eapp: ${res}`);
      }
    });
  }

  getSimonPoliciesRecursive() {
    this.simonApiService.getAllHoldings().then(res => {
      console.log(res);
    });
  }

  getSimonPolicies() {
    this.simonApiService.getAllHoldings(this.limit).then(r => {
      this.simonPolicies = r;
    });
  }


  onDateRangeChange(selectedDate: Date) {
    console.log(selectedDate);
  }

  openEnrollmentModal() {
    const dialogRef = this.dialog.open(EnrollmentFormComponent, {
      data: {
        Event: 'hi',
        SurveyId: 2, // 1027,
        Disclaimer: 'This is some disclaiemr text'
      }
    });
  }

  openConfirmationModal(): void {
    const ref = this.dialog.open<ConfirmationComponent, ConfirmationData, boolean>(ConfirmationComponent, {
      data: {
        title: 'Do the thing TITLE',
        message: 'Are sure you want to do the thing?',
        confirmationButtonText: 'Red Confirm Button',
        isWarning: true
      }
    });

    ref.afterClosed().subscribe(result => {
      if (result) {
        alert('confirmation result: ' + result);
      } else {
        alert('confirmation result: ' + result);
      }
    });
  }

  openRenewalFormModal(): void {
    this.enrollmentFormApiService.getSurveyRenewals(null).subscribe(result => {
      const ref = this.dialog.open(RenewalFormComponent, {
        data: {
          SurveyRenewals: result,
          FilterBySurveyId: null
        }
      });

      ref.afterClosed().subscribe(result => {
        if (result) {
          console.log('confirmed');
        } else {
          console.log('cancelled');
        }
      });
    });
  }

  openPurchaseModal(): void {
    forkJoin({ orders: this.purchaseApiService.getAllOrders() })
      .subscribe(res => {
        const ref = this.dialog.open(PurchaseModalComponent, {
          data: {
            payComponentCallback: (callback: any) => {
              console.log(callback);
            },
            purchaseModel: {
              TermsLink: 'https://www.figmarketing.com/Documents/Terms_of_Use.pdf',
              PurchaseOptions: [
                {
                  Description: 'Force CRM - One Time Payment  ($500 per seat)',
                  Amount: 500,
                  IsRecurringPayment: false,
                  PurchaseSeats: true,
                  MarketingStrategy: MarketingStrategy.ForceCrm
                },
                {
                  Description: 'Force CRM II - Recurring Payment ($500 a year)',
                  Amount: 500,
                  IsRecurringPayment: true,
                  PurchaseSeats: true,
                  MarketingStrategy: MarketingStrategy.ForceCrm
                },
                {
                  Description: 'Force CRM III - ($1000 for all)',
                  Amount: 1000,
                  IsRecurringPayment: false,
                  PurchaseSeats: false,
                  MarketingStrategy: MarketingStrategy.ForceCrm
                }
              ],
              AgentMarketingDollars: 700000,
              Title: 'Force CRM',
              Purchased: false,
              Orders: res.orders
            }
          }
        });

        ref.afterClosed().subscribe(result => {
          if (result) {
            console.log('confirmed');
          } else {
            console.log('cancelled');
          }
        });
      });
  }

  onBobFormSubmit(): void {
    console.log('Name:' + this.bobForm.get('name')?.value);
    console.log('Height: ' + this.bobForm.get('height')?.value);
  }

}
