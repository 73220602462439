import { Injectable } from '@angular/core';
import { FeatureToggleService } from '../featuretoggle.service';
import { AmplitudeApiService } from './amplitude-api.service';
import { PermissionService } from '../auth/permission.service';
import { ActivatedRoute } from '@angular/router';
import { RouteHistoryStoreService } from '../stores/route-history-store.service';
import { PathToDataStoreService } from '../stores/path-to-data-store.service';
// import { environment } from 'src/environments/environment';
// import { EnvironmentEnum } from 'src/app/shared/models/generic.models';

@Injectable({
  providedIn: 'root'
})
export class AmplitudeEventService {

  eventTypeList = {
    default: 'Undefined Event',
    click: 'Click',
    navigation: 'Navigation'
  }

  constructor(
    private amplitudeApiService: AmplitudeApiService,
    private featureToggleService: FeatureToggleService,
    private permissionService: PermissionService,
    private activatedRoute: ActivatedRoute,
    private routeHistoryStore: RouteHistoryStoreService,
    private pathToDataStore: PathToDataStoreService
  ) { }

  sendEvent(clickTarget: any, eventType: string, eventObj: any): void{
    const event = {
      event_type: eventType || 'Undefined Event',
      event_properties: Object.assign(
        {
          click_target: clickTarget || 'Undefined Click Target',
          current_state: this.pathToDataStore.stateLookUp(this.routeHistoryStore.getCurrentUrl())
        }, eventObj),
      user_properties: {
        environment: this.featureToggleService.getEnvironment(),
        hasElevate: this.permissionService.hasOnePermission('Elevate:Access') ? true : false,
        hasElevateForLife: this.permissionService.hasOnePermission('ElevateForLife:Access') ? true : false,
        hasEitherElevate: this.permissionService.hasOnePermission('ElevateForLife:Access|Elevate:Access') ? true : false
      }
    };

    this.amplitudeApiService.logEvent(event).subscribe();
  }

  logClickEvent(clickTarget: string): void{
    this.sendEvent(clickTarget, this.eventTypeList.click, false);
  }

  logCustomEvent(clickTarget: string, eventType: string, eventObj: object): void{
    this.sendEvent(clickTarget, eventType, eventObj);
  }

  logNavigationEvent(eventObj: object): void{
    this.sendEvent(false, this.eventTypeList.navigation, eventObj);
  }

  logBusinessReporting(reportType: string, policyListType: string, policyCount: number): void{
    this.logCustomEvent('undefined', 'Business Reporting by Report Type', {
      report_type: reportType,
      policy_list_type: policyListType,
      policy_count: this.policyCountMap(policyCount)
    });
  }

  policyCountMap(count: number): string {
    if (1 <= count && count <= 100) return '1-100';
    if (101 <= count && count <= 200) return '101-200';
    if (201 <= count && count <= 300) return '201-300';
    if (301 <= count && count <= 400) return '301-400';
    if (401 <= count && count <= 500) return '401-500';
    if (501 <= count && count <= 750) return '501-750';
    if (751 <= count && count <= 1000) return '751-1000';
    if (1001 <= count && count <= 1500) return '1001-1500';
    if (1501 <= count && count <= 2000) return '1501-2000';
    if (2001 <= count && count <= 3000) return '2000-3000';
    if (3001 <= count) return '> 3000';
    return '0';
  }
}
