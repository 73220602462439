import { Component, OnInit } from '@angular/core';
import { fadeIn } from 'src/app/animations';
import { PermissionService } from 'src/app/core/services/auth/permission.service';
import { UpcomingEventV2 } from '../../../../models/dashboard.models';

@Component({
  templateUrl: './dashboard-upcoming-events-v2.component.html',
  selector: 'app-dashboard-upcoming-events-v2',
  animations: [fadeIn]
})

export class DashboardUpcomingEventsV2Component implements OnInit {
  upcomingEvents: UpcomingEventV2[] = [
    {
      title: 'Symposium 2023 | Sheraton Dallas',
      registrationUrl: '',
      interestUrl: '',
      attendUrl: '',
      viewNowUrl: '',
      agenda: '',
      location: 'Dallas, TX',
      date: 'February 22-24, 2023',
      viewPermissions: 'FigSymposium:View|FIGTripsAndEvents:View'
    },
    {
      title: 'Aruba Agent Convention 2023',
      registrationUrl: '',
      interestUrl: '/NewPortal/Portal/FigPlatform/Details/Aruba',
      attendUrl: '',
      viewNowUrl: '',
      agenda: '',
      location: 'Aruba',
      date: 'May 8-13,&nbsp;2023',
      viewPermissions: 'FIGTripsAndEvents:View'
    },
  ];
  filteredEvents: UpcomingEventV2[] = [];

  constructor(
    private permissionService: PermissionService,
  ) { }

  ngOnInit(): void {
    this.filterEvents();
  }

  filterEvents(): void {
    this.filteredEvents = this.upcomingEvents.filter(event => {
      return event.viewPermissions ? this.permissionService.hasOnePermission(event.viewPermissions) : true;
    });
  }
}