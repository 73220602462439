import { Component, OnInit } from '@angular/core';
import { fadeIn } from 'src/app/animations';

@Component({
  animations: [fadeIn],
  selector: 'app-annuity-rate-watch-myga',
  templateUrl: './annuity-rate-watch-myga.component.html',
})
export class AnnuityRateWatchMygaComponent {
  //need to add prod url once whitelisted
  iframeUrl: string = 'https://qa.portal.figmarketingusercontent.com/annuity-rate-watch-myga.template.html';

  constructor() { }

}
