import { IndustryEvent } from '../models/industry-event.models';

export const industryEvents: IndustryEvent[] = [
  {
    Title: 'Fig Symposium 2022',
    Url: 'FIGSymposium2022',
    DateStart: new Date('02/23/2022'),
    DateEnd: new Date('02/25/2022'),
    Description: 'From inspirational and educational mainstage sessions to targeted mastermind breakouts, the FIG Sales Symposium is committed to delivering solutions and innovation that brings the industry of tomorrow into&nbsp;today!',
    Location: 'Orlando, FL',
    Image: 'sym_2022.png',
    Permissions: 'FigSymposium:View|FIGTripsAndEvents:View',
    IndustryEventGroups: [
      {
        Date: new Date('02/23/2022'),
        Name: 'Day 1',
        Events: [
          {
            Name: 'Welcome + Opening Comments - FIG CEOs',
            WistiaId: 'bh4lx272eb',
          },
          {
            Name: 'New Elevate Model Office Experience - Elevate Coaches + Nic Ross',
            WistiaId: '7nq79ytnge',
          },
          {
            Name: 'Your Growth Strategy, Marketing - Tom Lamendola',
            WistiaId: 'egt0ph0xno',
          },
          {
            Name: 'Building Today\'s FinTech + InsurTech Applications - Andrew Barnett + Josh Lewis',
            WistiaId: '8gt3xlorfh',
          },
          {
            Name: 'Breakout Session, Mainstage - Cornerstone',
            WistiaId: '9cbxx677i2',
          },
          {
            Name: 'Breakout Session, Mainstage - Scott Moore + Gary Reed',
            WistiaId: 'sgtlrleyek',
          },
          {
            Name: 'Breakout Session, Mainstage - Carson Coaching',
            WistiaId: '1l34kwcrhf',
          },
          {
            Name: 'New Product Concepts - Bobby Samuelson',
            WistiaId: '0c370bp76i',
          },
          {
            Name: 'Advanced Strategies Panel - Jeremy Green + Dave Henry',
            WistiaId: 'zyzvyu5dqt',
          },
          {
            Name: 'DOL Regulation Updates - Brian Williams',
            WistiaId: 'c9ou3mu4km',
          },
        ]
      }
    ]
  },
  {
    Title: 'Fig Symposium 2021',
    Url: 'FIGSymposium2021',
    DateStart: new Date('07/14/2021'),
    DateEnd: new Date('07/16/2021'),
    Description: 'The culmination of incredible ideas developed during 2020 and truly transformative models for your continued acceleration!',
    Location: 'Las Vegas, NV',
    Image: 'fig-symposium-2021.png',
    BackgroundImageUrl: './assets/images/symposium-background.png',
    Permissions: 'FigSymposium:View|FIGTripsAndEvents:View',
    IndustryEventGroups: [
      {
        Date: new Date('07/15/2021'),
        Name: 'Day 1',
        Events: [
          {
            Name: 'Welcome from the CEOs',
            WistiaId: '2ktxfqi4ho',
          },
          {
            Name: 'Solving the Tech Gap to Accelerate Growth: FIG&nbsp;+&nbsp;SIMON',
            WistiaId: '61fo1bce7n',
          },
          {
            Name: '5 Industry Trends Reshaping Financial Advice',
            Speakers: 'Michael Kitces',
            WistiaId: '2wm0yet6dr',
          },
          {
            Name: 'Elevate, There is No Summit',
            Speakers: 'Nicholas Ross, Monica Breeding, Jamie&nbsp;Hopkins',
            WistiaId: 'neecouineq',
          },
          {
            Name: 'Product Strategies in Today\'s Economy',
            Speakers: 'Bobby Samuelson',
            WistiaId: 'd6kb1ctz95',
          },
          {
            Name: 'Breakout Session',
            Speakers: 'Carson Coaching',
            WistiaId: 'ww4872rthm',
          },
          {
            Name: 'Breakout Session',
            Speakers: 'The FIG Product Platform',
            WistiaId: '87cx6u5e9u',
          },
          {
            Name: 'Breakout Session',
            Speakers: 'Life Innovators, Deep Dive on FILA',
            WistiaId: 'b167866y7d',
          },
          {
            Name: 'Breakout Session',
            Speakers: 'High Converting Talks, Pat Quinn',
            WistiaId: 'c4g1a3iwow',
          },
          {
            Name: 'The Irresistible Power of Storytelling',
            Speakers: 'Kindra Hall',
            WistiaId: 'r1p9n0xwdb',
          },
          {
            Name: 'Unified, Rising Above Division to Lead',
            Speakers: 'Trey Goudy',
            WistiaId: 'i3ima2vjov',
          },
        ]
      }
    ]
  },
];