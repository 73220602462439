import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { fadeIn } from 'src/app/animations';
import { AdditionalResourceGroup } from 'src/app/shared/models/resource-page.models';

@Component({
  animations: [fadeIn],
  selector: 'app-life-insurance',
  templateUrl: './life-insurance.component.html',
})
export class LifeInsuranceComponent implements OnInit {
  productResources: AdditionalResourceGroup[] = [
    {
      GroupName: 'Life Insurance Needs Estimator',
      AdditionalResources: [
        {
          Name: 'Napkin Approach to LIFE Insurance',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/Napkin_Approach_to_L.I.F.E_Insurance.pdf'
        },
        {
          Name: 'Lifepipe',
          ExternalUrl: 'https://lifepipe.ipipeline.com/NeedsAnalysis/?r=-8948513824943793071&GAID=1514'
        },
        {
          Name: 'How Much Life Insurance Do I Need',
          ExternalUrl: 'https://lifehappens.org/life-insurance-101/how-much-life-insurance-do-i-need/'
        },
      ]
    },
    {
      GroupName: 'Carrier/Product Intel',
      AdditionalResources: [
        {
          Name: 'Accelerated Underwriting Program Details',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/Accelerated-Underwriting-Program-DetailsAIN.pdf'
        },
        {
          Name: 'CheatSheet - 1st year Prem Restrictions',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/CheatSheet_1st_year_Prem_Restrictions_FINAL.xlsx'
        },
        {
          Name: 'CheatSheet - 7702',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/CheatSheet_7702'
        },
        {
          Name: 'CheatSheet - External Term Conversion',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/CheatSheet_External_Term_Conversion_FINAL'
        },
        {
          Name: 'CheatSheet - LTC Riders',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/CheatSheet_LTC_Riders_FINAL.xlsx'
        },
        {
          Name: 'Cheatsheet - Prem Deposit Accounts',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/Cheatsheet_Prem_Deposit_Accounts_Final.xlsx'
        },
        {
          Name: 'CheatSheet - Product Type by Carrier',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/CheatSheet_Product_Type_by_Carrier_FINAL.xlsx'
        },
        {
          Name: 'LTC and Chronic Illness Rider Details AIN',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/LTC-and-Chronic-Illness-Rider-Details_AIN.pdf'
        },
      ]
    },
    {
      GroupName: 'Life Settlements',
      AdditionalResources: [
        { Name: 'Abacus Life Settlements', ExternalUrl: 'https://abacuslifesettlements.com/fig/' },
        { Name: 'Life Settlement Questionnaire', ExternalUrl: 'https://www.figmarketing.com/files/resources/Life_Settlement_Questionnaire_Form_FINAL.pdf' },
        { Name: 'Life Settlements Overview Co-Branded piece', ExternalUrl: 'https://www.figmarketing.com/files/resources/Life_Settlements_Overview_Co-Branded_piece.pdf' },
        // {Name: '', ExternalUrl: 'https://www.figmarketing.com/files/resources/'},
      ]
    },
  ];
  carResources: AdditionalResourceGroup[] = [
    {
      GroupName: 'Sample Client Output',
      AdditionalResources: [
        { Name: 'CAR Sample Output', ExternalUrl: 'https://www.figmarketing.com/files/resources/CAR_Sample_Output1.pdf' },
      ]
    },
    {
      GroupName: 'Required Forms',
      AdditionalResources: [
        { Name: 'CAR Authorization and Goals Form FILLABLE', ExternalUrl: 'https://www.figmarketing.com/files/resources/CAR_Authorization_and_Goals_Form_FILLABLE.pdf' },
        { Name: 'CAR InForce Carrier Checklist FILLABLE', ExternalUrl: 'https://www.figmarketing.com/files/resources/CAR_InForce_Carrier_Checklist_FILLABLE.pdf' }
      ]
    },
    {
      GroupName: 'Marketing Pieces',
      AdditionalResources: [
        { Name: 'CAR Life Insurance Concepts', ExternalUrl: 'https://fig.wistia.com/medias/qj72dcp0aq/download?media_file_id=508172366' },
        { Name: 'CAR Agent Process', ExternalUrl: 'https://fig.wistia.com/medias/phmk1c2vdp/download?media_file_id=507312761' },
        { Name: 'CAR Review Process', ExternalUrl: 'https://fig.wistia.com/medias/atg612iv8h/download?media_file_id=508163122' },
      ]
    }
  ];
  underwritingResources: AdditionalResourceGroup[] = [
    {
      GroupName: 'Carrier Underwriting Guides',
      AdditionalResources: [
        {
          Name: '417212 0121',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/417212_0121.pdf'
        },
        {
          Name: 'AIG UW Guide',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/AIG UW Guide.pdf'
        },
        {
          Name: 'Allianz Underwriting Guide',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/Allianz Underwriting Guide.pdf'
        },
        {
          Name: 'Ameritas UW Guide.pdf',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/Ameritas UW Guide.pdf'
        },
        {
          Name: 'Brighthouse Financial Field Underwriting Guide',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/Brighthouse-Financial-Field-Underwriting-Guide.pdf'
        },
        {
          Name: 'Equitable Field Underwriting Guide',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/Equitable-Field-Underwriting-Guide.pdf'
        },
        {
          Name: 'F&G UW Guidelines',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/F&G_UW Guidelines.pdf'
        },
        {
          Name: 'Global Atlantic UW Guide',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/global Atlantic UW Guide.pdf'
        },
        {
          Name: 'John Hancock Field Underwriting Guide',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/John-Hancock-Field-Underwriting-Guide.pdf'
        },
        {
          Name: 'Lafayette Life Field Underwriting guide',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/Lafayette Life Field Underwriting guide.pdf'
        },
        {
          Name: 'Lincoln-Financial-Field-Underwriting-Guide',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/Lincoln-Financial-Field-Underwriting-Guide.pdf'
        },
        {
          Name: 'Lincoln-Financial-TermAccel-Field-Underwriting-Guide',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/Lincoln-Financial-TermAccel-Field-Underwriting-Guide.PDF'
        },
        {
          Name: 'LSW_Underwriting Guide',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/LSW_Underwriting Guide.pdf'
        },
        {
          Name: 'MassMutual UW Guide',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/MassMutual UW Guide.pdf'
        },
        {
          Name: 'Nationwide-Field-Underwriting-Guide',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/Nationwide-Field-Underwriting-Guide.pdf'
        },
        {
          Name: 'North-American-Field-Underwriting-Guide',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/North-American-Field-Underwriting-Guide.pdf'
        },
        {
          Name: 'PacLife Underwriting Guide',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/PacLife Underwriting Guide.pdf'
        },
        {
          Name: 'PennMutual Underwriting Guide',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/PennMutual Underwriting Guide.pdf'
        },
        {
          Name: 'Principal-Field-Underwriting-Guide',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/Principal-Field-Underwriting-Guide.pdf'
        },
        {
          Name: 'Protective-Life-Field-Underwriting-Guide',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/Protective-Life-Field-Underwriting-Guide.pdf'
        },
        {
          Name: 'Prudential-Field-Underwriting-Guide',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/Prudential-Field-Underwriting-Guide.pdf'
        },
        {
          Name: 'Sagicor-Field-Underwriting-Guide',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/Sagicor-Field-Underwriting-Guide.pdf'
        },
        {
          Name: 'Securian-Financial-Field-Underwriting-Guide',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/Securian-Financial-Field-Underwriting-Guide.pdf'
        },
        {
          Name: 'Symetra-Field-Underwriting-Guide',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/Symetra-Field-Underwriting-Guide.pdf'
        },
        {
          Name: 'Transamerica-Field-Underwriting-Guide',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/Transamerica-Field-Underwriting-Guide.pdf'
        },
        {
          Name: 'Zurich-Field-Underwriting-Guide',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/Zurich-Field-Underwriting-Guide.pdf'
        }
      ]
    },
    {
      GroupName: 'UW Cheat Sheets',
      AdditionalResources: [
        {
          Name: 'UW CheatSheet Accelerated UW Programs',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/UW CheatSheet Accelerated UW Programs.xlsx'
        },
        {
          Name: 'UW CheatSheet BP EDITS',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/UW CheatSheet BP EDITS.xlsx'
        },
        {
          Name: 'UW CheatSheet Chol EDITS',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/UW CheatSheet Chol EDITS.xlsx'
        },
        {
          Name: 'UW CheatSheet Family History EDITS',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/UW CheatSheet Family History EDITS.xlsx'
        },
        {
          Name: 'UW CheatSheet Tobacco EDITS',
          ExternalUrl: 'https://www.figmarketing.com/files/resources/UW CheatSheet Tobacco EDITS.xlsx'
        },
      ]
    },
    {
      GroupName: 'Accelerated Underwriting Intel',
      AdditionalResources: [
        { Name: 'Accelerated-Underwriting-Program-Details AIN', ExternalUrl: 'https://www.figmarketing.com/files/resources/Accelerated-Underwriting-Program-DetailsAIN.pdf' }
      ]
    },
    {
      GroupName: 'Impairment Fact-Finders',
      AdditionalResources: [
        { Name: 'Underwriting Questionnaires Source File Workbook', ExternalUrl: 'https://www.figmarketing.com/files/resources/Underwriting-Questionnaires-Source-File-Workbook.xlsx' },
        { Name: 'Alcohol', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=ecf380e2-2822-493c-918c-df7dc12a4775&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
        { Name: 'Angio', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=6280c1da-e8c5-45ee-b8d1-df3bb6dd5bc7&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
        { Name: 'AFib', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=04b324c3-972f-4915-aee3-a8f53d625441&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
        { Name: 'CAD', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=44f3f631-4f5e-4caf-b68d-4fdf09985790&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
        { Name: 'Colon Polyps', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=632d5736-6266-48f6-bc98-748421aad13a&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
        { Name: 'Crohns', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=0a1ca54c-172c-4bc9-a41e-fc4def6ee324&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
        { Name: 'Depression', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=27cf66df-af9f-43ac-9898-351fdd4c874b&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
        { Name: 'Drugs', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=06ce4c9f-365b-46f8-92dc-ab97feaa03a0&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
        { Name: 'Diabetes', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=79fd14d4-3bd4-4ed9-a496-13ae7197500f&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
        { Name: 'Hepatitis', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=1d1d88e0-32d9-4d96-8ce7-222863af5bd8&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
        { Name: 'MS', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=f1ecc21b-d951-48c5-bacd-ef0fb92fa5f9&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
        { Name: 'Pace', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=25cbbf47-b825-49ce-be8f-3d980083da86&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
        { Name: 'PD', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=df8bd1d2-041a-4270-849a-4e98f0e37dbf&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
        { Name: 'PKD', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=99b505d4-40d6-40f3-80cd-86a746446d69&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
        { Name: 'SApnea', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=3266d572-5b63-4764-8d62-fcb4b879bdce&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
        { Name: 'Stroke', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=c7d59ede-495f-4555-a326-d90eafe95b62&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
        { Name: 'Valve Surgery', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=02e98d90-6af9-420e-9164-a7d6c5b42d73&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
        { Name: 'Bladder C', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=ad4369f9-8103-45b0-b24b-62dadad7c9bc&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
        { Name: 'Breast C', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=de50a18a-e23b-4aca-9819-d2a97da47f49&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
        { Name: 'Cervical C', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=75821659-2354-458f-865d-c9a63c3c4d37&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
        { Name: 'Colorectal C', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=44ef02ce-d624-4574-93ac-250addeffd6b&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
        { Name: 'Ovarian C', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=3726937a-2117-48cd-bdcb-a7837e5f765c&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
        { Name: 'Prostate C', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=1c06ad35-a9ff-4c10-95ca-61883d42e5cc&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
        { Name: 'Skin C', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=091d2147-86bf-4728-82b9-e6d438c31b66&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
        { Name: 'Testicular C', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=0b2fa04f-73de-4e8b-ac15-4a462f2afbe7&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
        { Name: 'Lymphoma', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=b8a5cfca-1d8a-479e-bc7e-5aead03005a0&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
        { Name: 'U Colitis', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=0e12c125-62bb-4112-b0b9-27e62cae73eb&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
        { Name: 'Pilots', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=cc8a5ff2-65cf-4e3f-a75a-aac0db1ae6e0&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
        { Name: 'Climbing', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=8aca6224-aabb-41e3-89a8-40869d092020&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
        { Name: 'F. Travel', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=e42c3ce5-a168-4dc6-a58b-fe121842edac&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
        { Name: 'F. National', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=6895ef0a-2eae-47d9-8a7a-78ac7b8b4518&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
        { Name: 'F. Resident', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=e3e0502a-ca77-4331-b67a-82a8b129945b&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
        { Name: 'Racing', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=a9e330b0-3cdb-44e1-823b-8fb47be95ea1&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
        { Name: 'Scuba', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=89411ad4-9b3c-45db-af14-1851fac6891f&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
        { Name: 'Sky Sports', ExternalUrl: 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=a4681262-c094-447e-b6c7-2bf151d57df5&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2' },
      ]
    }
  ];
  constructor(
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.productResources.forEach(group => {
      return group.AdditionalResources = !!group.AdditionalResources ? this.orderBy(group.AdditionalResources, ['Name']) : [];
    });
    this.carResources.forEach(group => {
      return group.AdditionalResources = !!group.AdditionalResources ? this.orderBy(group.AdditionalResources, ['Name']) : [];
    });
    this.underwritingResources.forEach(group => {
      return group.AdditionalResources = !!group.AdditionalResources ? this.orderBy(group.AdditionalResources, ['Name']) : [];
    });
  }

  getResourceUrl() {
    return this.activatedRoute.snapshot.paramMap.get('resource')!;
  }

  orderBy(items: any[], sortProperty: string[]) {
    const result: any[] = _.sortBy(items, sortProperty);
    return result;
  }
}

