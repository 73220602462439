<section class="flex items-center justify-start p-10 border-b border-solid lg:p-20 border-gray-lighter">
  <div class="flex flex-row items-center h-24 m-10 mr-20">
    <h2 class="text-lg font-bold leading-none">General Training</h2>
  </div>
</section>
<ul class="flex flex-row flex-wrap">
  <li *ngFor="let item of trainings; let last = last; let first = first" class="w-1/2 border-solid border-gray-lighter border border-opacity-75">
    <a class="flex flex-col items-center justify-start h-full p-20 no-underline transition duration-150 outline-none hover:bg-blue-lighter hover:bg-opacity-25 focus:shadow-outline"
      [href]="item.Link" rel="noopener" target="_blank" attr.aria-label="Go to {{item.Title}} training">
      <span
        class="flex flex-row items-center justify-center flex-none flex-grow-0 w-32 h-32 mb-20 rounded-full bg-gray-lightest">
        <span class="material-icons mi-18 text-gray">school</span>
      </span>
      <div class="flex flex-col text-center">
        <span class="mb-5 text-sm font-bold">{{item.Title}}</span>
        <!-- <span>{{item.Description}}</span> -->
      </div>
    </a>
  </li>
</ul>