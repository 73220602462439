<div class="flex flex-col w-full h-full p-20 gap-y-20 card">
  <h2 class="text-lg font-bold">What's New</h2>
  <ng-container *ngIf="hasSpotlightPermission">
    <ul class="flex flex-wrap gap-x-10 gap-y-10" *ngIf="spotlightTiles.length && !error.hasError" @fadeIn>
      <li *ngFor="let item of spotlightTiles">
        <ng-container *showIfAll="item.Fields.ViewPermission">
          <a [routerLink]="item.Fields.AngularRoute" class="flex flex-col p-10 transition duration-150 rounded hover:bg-blue-lightest focus:bg-blue-lightest gap-y-10 w-256">
            <div class="flex w-full rounded h-128" [class]="item.Fields.BackgroundClass">
              <img src="./assets/images/bg/pattern-1-sm.png"
                   [alt]="item.Fields.SpotlightName" class="object-cover w-full h-full opacity-50" />
            </div>
            <div class="flex flex-col">
              <h3 class="text-sm font-bold">{{item.Fields.SpotlightName}}</h3>
              <label class="text-xs text-gray">{{item.Fields.Subtitle}}</label>
            </div>
          </a>
        </ng-container>
      </li>
    </ul>

    <span @fadeIn *ngIf="error.hasError" class="flex items-center p-10 mx-10 my-auto border border-solid rounded border-red-lighter bg-red-lightest text-red gap-x-10">
      <i class="flex-none material-icons mi-18">warning</i>
      <span class="text-sm">{{error.message}}</span>
    </span>

    <app-message-notification *ngIf="!spotlightTiles.length">We have nothing new at this point.</app-message-notification>
  </ng-container>

  <ng-container *ngIf="!hasSpotlightPermission">
    <app-message-notification>You do not have permissions to view this tile. Please contact our support staff.</app-message-notification>
  </ng-container>

</div>