import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { fadeIn } from 'src/app/animations';
import { FigPlatformService } from 'src/app/core/services/figplatform.service';
import { FigPlatformCategory, FigPlatformTile } from 'src/app/shared/models/figplatform.models';
import { searchFilter } from 'src/app/shared/helpers/search.helper';
import { EnvironmentEnum } from 'src/app/shared/models/generic.models';
import { FeatureToggleService } from 'src/app/core/services/featuretoggle.service';

@Component({
  selector: 'app-fig-platform',
  templateUrl: './fig-platform.component.html',
  animations: [fadeIn]
})
export class FigPlatformComponent implements OnInit, AfterViewInit {
  categories: FigPlatformCategory[] = [];
  environment!: EnvironmentEnum;
  environmentEnum = EnvironmentEnum;
  filteredList: FigPlatformTile[] = [];
  loadingCategories: boolean = false;
  loadingTile: boolean = false;
  resourceUrl: string = 'Explore';
  searchText: string = '';
  showFilteredList: boolean = false;
  tempTags: string[] = ['Education', 'Financial Planning', 'Marketplace', 'E-Application'];
  tiles: FigPlatformTile[] = [];

  constructor(
    private route: ActivatedRoute,
    private featureToggleService: FeatureToggleService,
    private figPlatformService: FigPlatformService,
    private titleService: Title) {
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.resourceUrl + ' | Agent Portal');
    this.loadingCategories = true;
    this.loadingTile = true;
    this.environment = this.featureToggleService.getEnvironment();
    this.figPlatformService.getCategories()
      .subscribe(
        (res) => {
          this.categories = res.filter(cat => {
            return cat.Id !== 'recvZM30AUpnvcoRP';
          });
        },
        (error) => console.log(error),
        () => {
          this.loadingCategories = false;
          this.categories = this.orderBy(this.categories, ['Fields.Order']);
        });
    this.figPlatformService.getUserPermittedTiles()
      .subscribe(
        res => {
          this.tiles = res;
        },
        (error) => console.log(error))
      .add(() => {
        this.loadingTile = false;
      });
  }

  ngAfterViewInit(): void {
    this.route.fragment.subscribe((fragment: string) => {
      if (fragment) setTimeout(() => {
        this.scrollTo(fragment);
      }, 1000);
    });
  }

  applyFilters(): void {
    this.showFilteredList = !!this.searchText && this.searchText.length > 0 ? true : false;
    let result: FigPlatformTile[] = Object.assign(this.tiles);
    result = result.filter(tile => searchFilter(tile.Fields, this.searchText));
    this.filteredList = result;
  }

  clearSearch(): void {
    this.searchText = '';
    this.showFilteredList = false;
  }

  filterTilesByCategory(categoryName: string): FigPlatformTile[] {
    const result: FigPlatformTile[] = _.filter(this.tiles, tile => {
      return tile.Fields.CategoryName === categoryName;
    });
    return result;
  }

  filterTiles(categoryName: string, searchText: string): FigPlatformTile[] {
    return this.tiles.filter(x => x.Fields.CategoryName === categoryName).filter(x => searchFilter(x.Fields, searchText));
  }

  getTileCountByCategory(category: string): Number {
    return this.tiles.filter(tile => tile.Fields.CategoryName == category).length;
  }

  orderBy(items: any[], sortProperty: string[]): any[] {
    const result: any[] = _.sortBy(items, sortProperty);
    return result;
  }

  scrollTo(id: string): void {
    const el = document.getElementById(id);
    el?.scrollIntoView({ behavior: 'smooth' });
  }
}
