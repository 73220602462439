<!-- 
<div [class]="getComponent().includes('Workflow') ? 'p-0' : 'p-10 sm:p-20'">
    <router-outlet></router-outlet>
</div> -->
<section class="h-full" id="quinci-container-content" #quinciContainerContent>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
</section>
<ng-template #simpleDialog let-data>
    <section class="flex flex-col overflow-hidden">
        <section class="flex flex-col flex-grow flex-shrink-0 p-20 overflow-auto">
            <p class="m-10 my-auto text-lg">{{data}}</p>
        </section>
        <section class="flex flex-none p-20 border-t border-solid border-gray-lighter dark:border-gray">
            <button class="m-10 ml-auto button is-primary" mat-button mat-dialog-close>Close</button>
        </section>
    </section>
</ng-template>
