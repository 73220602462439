<div class="flex flex-col h-full p-20 md:p-30" [@fadeIn]>
    <h3 class="mb-20 text-lg font-bold">Production Insights</h3>
    <div class="relative flex flex-col items-center justify-center flex-grow max-w-full max-h-full mx-auto my-20 w-192 h-192 xs:pr-10 md:pr-20 xl:pr-48">
        <mat-progress-spinner class="absolute z-10" [color]="color" mode="determinate" diameter="160" [value]="value">
        </mat-progress-spinner>
        <mat-progress-spinner class="absolute z-0 stroke-gray-light" mode="determinate" diameter="160" value="100">
        </mat-progress-spinner>
        <div class="absolute z-20 flex flex-col items-center">
            <label class="text-sm uppercase text-gray-light">YTD</label>
            <p class="mb-10 text-base font-bold text-gray-dark" >${{ currentYearProduction && currentYearProduction.Premium ? formatTotal(currentYearProduction.Premium) : 0  }}</p>
            <label class="text-sm upppercase text-gray-light" [textContent]="pastYear"></label>
            <p class="text-base font-bold text-gray-dark">${{pastYearProduction && pastYearProduction.Premium ? formatTotal(pastYearProduction.Premium) : 0 }}</p>
        </div>
    </div>
</div>
<!-- <div class="relative flex flex-row flex-wrap flex-grow pt-20 loader-wrap md:pt-40" *ngIf="loading" [@fadeIn]>
    <div class="loader-sm is-blue"></div>
</div> -->