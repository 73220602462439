<section class="flex flex-col flex-grow h-full overflow-hidden card">
  <section
    class="flex flex-row items-center justify-start w-full p-10 border-b border-solid lg:p-20 border-gray-lighter">
    <h2 class="m-10 mr-20 text-lg font-bold leading-none">Business</h2>
    <button class="m-10 ml-auto button is-primary is-outlined" type="button" [disabled]="exportingList"
      (click)="exportReport(selectedReportType)">{{exportingList ? 'Exporting' : 'Export'}} List</button>
  </section>
  <mat-sidenav-container class="relative flex flex-grow overflow-hidden bg-white">
    <mat-sidenav #businessSidenav [mode]="sidenavConfig.Mode" [opened]="sidenavConfig.IsOpened"
      class="p-10 bg-white border-r border-solid shadow w-320 lg:w-320 border-gray-lighter sm:shadow-none md-sidenav-left lg:p-20">
      <div class="flex flex-col">
        <div class="flex flex-col p-10 mb-10 border-b border-solid border-gray-lighter" *ngIf="selectedReportType !== 'Informal'">
          <div class="flex flex-row items-center justify-start w-full mb-10 text-sm">
            <span class="mr-5">Policies:</span>
            <span class="font-semibold">{{table.Body.length}}</span>
          </div>
          <div class="flex flex-col" *ngIf="selectedLobIds.includes(1)">
            <div class="flex flex-row items-center justify-start w-full mb-10 text-sm">
              <span class="mr-5">Avg. Premium:</span>
              <span class="font-semibold">{{getAverage(table.Body, "SinglePremium")| currency}}</span>
            </div>
            <div class="flex flex-row items-center justify-start w-full mb-10 text-sm">
              <span class="mr-5">Total Premium:</span>
              <span class="font-semibold">{{getSum(table.Body, "SinglePremium") | currency}}</span>
            </div>
          </div>
          <div class="flex flex-col" *ngIf="selectedLobIds.includes(2) || selectedLobIds.includes(4)">
            <div class="flex flex-row items-center justify-start w-full mb-10 text-sm">
              <span class="mr-5">Avg. Face Amount:</span>
              <span class="font-semibold">{{getAverage(table.Body, "FaceAmount") | currency}}</span>
            </div>
            <div class="flex flex-row items-center justify-start w-full mb-10 text-sm">
              <span class="mr-5">Total Face Amount:</span>
              <span class="font-semibold">{{getSum(table.Body, "FaceAmount") | currency}}</span>
            </div>

            <div class="flex flex-row items-center justify-start w-full mb-10 text-sm">
              <span class="mr-5">Avg. Ann. Premium:</span>
              <span class="font-semibold">{{getAverage(table.Body, "AnnualizedPremium") | currency}}</span>
            </div>
            <div class="flex flex-row items-center justify-start w-full mb-10 text-sm">
              <span class="mr-5">Total Ann. Premium:</span>
              <span class="font-semibold">{{getSum(table.Body, "AnnualizedPremium") | currency}}</span>
            </div>
          </div>
        </div>
        <div class="flex flex-col p-10 mb-10 border-b border-solid border-gray-lighter" *ngIf="selectedReportType === 'Informal'">
          <div class="flex flex-row items-center justify-start w-full mb-10 text-sm">
            <span class="mr-5">Policies:</span>
            <span class="font-semibold">{{informalTable.Body.length}}</span>
          </div>
          <div class="flex flex-row items-center justify-start w-full mb-10 text-sm">
            <span class="mr-5">Avg. Face Amount:</span>
            <span class="font-semibold">{{getAverage(informalTable.Body, "FaceAmount") | currency}}</span>
          </div>
          <div class="flex flex-row items-center justify-start w-full mb-10 text-sm">
            <span class="mr-5">Total Face Amount:</span>
            <span class="font-semibold">{{getSum(informalTable.Body, "FaceAmount") | currency}}</span>
          </div>
        </div>
        <div class="flex flex-col m-10">
          <button [disabled]="loadingRows" class="flex-auto border border-solid rounded button is-primary border-gray-lighter" attr.aria-label="Filter by pending policies"
            [ngClass]="{'is-inverted':selectedReportType !== 'Pending'}"
            (click)="toggleReportType('Pending')">Pending</button>
          <button [disabled]="loadingRows" class="flex-auto border border-solid rounded button is-primary border-gray-lighter" attr.aria-label="Filter by inforce & paid policies"
            [ngClass]="{'is-inverted':selectedReportType !== 'Inforce'}"
            (click)="toggleReportType('Inforce')">Inforce/Paid</button>
          <button [disabled]="loadingRows" class="flex-auto border border-solid rounded button is-primary border-gray-lighter" attr.aria-label="Filter by inforce & paid policies"
            [ngClass]="{'is-inverted':selectedReportType !== 'Informal'}"
            (click)="toggleReportType('Informal')">Informal</button>
        </div>
        <div class="flex flex-col m-10" *ngIf="selectedReportType === 'Inforce'">
          <label class="mb-10 text-sm font-medium text-gray">Select Inforce Date Range:</label>
          <div layout class="flex flex-row">
            <input class="flex-auto mr-10 input" [matDatepicker]="startDate" (click)='startDate.open()'
              [(ngModel)]="selectedDate.StartDate" (ngModelChange)="onDateRangeChange()">
            <mat-datepicker #startDate></mat-datepicker>
            <input class="flex-auto mr-10 input" [matDatepicker]="endDate" (click)='endDate.open()'
              [(ngModel)]="selectedDate.EndDate" (ngModelChange)="onDateRangeChange()">
            <mat-datepicker #endDate></mat-datepicker>
          </div>
        </div>
        <div class="flex flex-col m-10"  *ngIf="selectedReportType !== 'Informal'">
          <label class="mb-10 text-sm font-semibold text-gray">Lines of Business:</label>
          <ul class="flex flex-col">
            <li>
              <mat-checkbox class="mb-10 checkbox" [(ngModel)]="allLobsSelected"
                (ngModelChange)="toggleAllCheckboxes(lobs, selectedLobIds, 'Lob', allLobsSelected)">Select All
              </mat-checkbox>
            </li>
            <li *ngFor="let line of lobs">
              <mat-checkbox class="mb-10 checkbox" [(ngModel)]="line.Selected"
                (ngModelChange)="toggleCheckbox(lobs, selectedLobIds, 'Lob', line)">{{line.Name}}</mat-checkbox>
            </li>
          </ul>
        </div>
        <div class="flex flex-col m-10" *ngIf="selectedReportType !== 'Informal' && subEntities && subEntities.length > 0">
          <label class="mb-10 text-sm font-semibold text-gray">Commission Groups:</label>
          <ul class="flex flex-col">
            <li *ngFor="let subEntity of subEntities">
              <mat-checkbox class="mb-10 checkbox" [(ngModel)]="subEntity.Selected"
                (ngModelChange)="toggleCheckbox(subEntities, selectedSubEntities, 'SubEntity', subEntity)">{{subEntity.CommissionGroup}}</mat-checkbox>
            </li>
          </ul>
        </div>
        <div class="flex flex-col m-10" *ngIf="selectedReportType !== 'Informal' && agents.length > 0">
          <label class="mb-10 text-sm font-semibold text-gray">Sub-agents:</label>
          <ul class="flex flex-col">
            <li>
              <mat-checkbox class="mb-10 checkbox" [(ngModel)]="allAgentsSelected"
                (ngModelChange)="toggleAllCheckboxes(agents, selectedAgentIds, 'Agent', allAgentsSelected)">Select All
              </mat-checkbox>
            </li>
            <li *ngFor="let agent of agents">
              <mat-checkbox class="mb-10 checkbox" [(ngModel)]="agent.Selected"
                (ngModelChange)="toggleCheckbox(agents, selectedAgentIds, 'Agent', agent)">{{agent.FirstName}}
                {{agent.LastName}} {{agent.CurrentUserTag}}</mat-checkbox>
            </li>
          </ul>
        </div>
        <div class="flex m-10 mt-20">
          <button class="flex-grow mx-auto button is-primary is-outlined md:hidden"
            (click)="toggleNav(businessSidenav)">Done</button>
        </div>
      </div>
    </mat-sidenav>
    <mat-sidenav-content class="flex flex-col w-full h-full">
      <div class="flex items-center flex-none border-b border-solid border-gray-lighter">
        <div
          class="flex items-center justify-center flex-none p-10 border-r border-solid md:hidden border-gray-lighter">
          <button type="button" class="icon-button is-primary is-inverted" mat-button matTooltip="Toggle Filters"
            (click)="toggleNav(businessSidenav)" attr.aria-label="Toggle filters">
            <span class="material-icons-outlined">
              filter_alt
            </span>
          </button>
        </div>
        <div class="relative flex flex-grow p-10">
          <i class="absolute mr-10 transform -translate-y-1/2 left-15 fig-24px fig-search text-gray top-1/2"></i>
          <input type="search" name="search" aria-label="Search policies and cases"
            placeholder="Find policies by keyword" (keyup)='setPolicyListTable()' [(ngModel)]="searchText"
            class="flex-grow input is-inverted pl-30" />
          <!-- <button class="icon-button is-primary is-inverted" attr.aria-label="Clear search box" *ngIf="searchText.length > 0" (click)="clearSearch()"><i class="material-icons">close</i></button> -->
        </div>
      </div>
      <section class="relative flex-grow w-full h-0 overflow-auto">
        <table class="w-full table-auto" *ngIf="selectedReportType !== 'Informal'">
          <thead>
            <tr class="sticky top-0">
              <td
                class="sticky top-0 z-10 w-48 p-10 text-xs font-semibold uppercase bg-white border-b border-solid border-gray-lighter text-gray">
              </td>
              <td *ngFor="let cell of table.Header"
                class="p-10 px-5 sm:px-10 border-b border-solid border-gray-lighter hover:bg-blue-lightest bg-white z-10 sticky top-0 text-xs text-gray font-semibold uppercase {{cell.WidthClass}}">
                <span (click)="orderRows(cell.ColumnName)" class="flex items-center cursor-pointer focus:outline-none"
                  attr.aria-label="Sort by {{cell.DisplayName}}">
                  <span class="mr-5 min-h-24">{{cell.DisplayName}}</span>
                  <span *ngIf="cell.ColumnName === columnName" class="material-icons text-gray">
                    {{reverseOrder ? 'arrow_drop_up' : 'arrow_drop_down'}}
                  </span>
                </span>
              </td>
              <td
                class="sticky top-0 z-10 p-10 text-xs font-semibold uppercase bg-white border-b border-solid border-gray-lighter text-gray min-w-128">
                Actions</td>
            </tr>
          </thead>
          <tbody class="overflow-auto" *ngIf="!loadingRows" [@fadeIn]>
            <tr id="policyRow" class="bg-opacity-25 cursor-pointer hover:bg-blue-lightest"
              aria-label="Go to policy details" matTooltip="{{!loadingModal ? 'Go to policy Details' : ''}}"
              *ngFor="let row of table.Body" (click)="!loadingModal ? openPolicyDetails(row) : ''">
              <td class="p-10 text-sm border-b border-solid border-gray-lighter">
                <button (click)="openRequirementModal(row, $event)" *ngIf="row.ActionNeeded"
                matTooltip="Action needed. Click to Resolve" aria-label="Action needed. Click to resolve"
                  class="icon-button is-alert is-inverted">
                  <span class="material-icons mi-18">
                    warning_amber
                  </span>
                </button>
              </td>
              <td class="p-10 px-5 text-sm border-b border-solid border-gray-lighter sm:px-10">{{row.FirstName}}
                {{row.LastName}}</td>
              <td class="p-10 px-5 text-sm border-b border-solid border-gray-lighter sm:px-10">{{row.Clients}}</td>
              <td class="p-10 px-5 text-sm border-b border-solid border-gray-lighter sm:px-10">
                <span class="flex flex-col w-full">
                  <span class="font-bold">{{row.Carrier}}</span>
                  <span>{{row.Product}} {{ row.PolicyNumber ? '(' + row.PolicyNumber + ')' : ''}}</span>
                </span>
              </td>
              <td *ngIf="row.LineOfBusinessId === 1" class="p-10 px-5 text-sm border-b border-solid border-gray-lighter sm:px-10">{{row.SinglePremium |
          currency}}</td>
          <td *ngIf="row.LineOfBusinessId !== 1" class="p-10 px-5 text-sm border-b border-solid border-gray-lighter sm:px-10">{{row.TotalFirstYearPremium |
            currency}}</td>
              <td class="p-10 px-5 text-sm border-b border-solid border-gray-lighter sm:px-10">
                {{row.LineOfBusinessId != 1 ? (row.FaceAmount | currency) : '---'}}
              </td>
              <td class="p-10 px-5 text-sm border-b border-solid border-gray-lighter sm:px-10">
                {{row.LineOfBusinessId != 1 ? (row.Target | currency) : '---'}}
              </td>
              <td class="p-10 px-5 text-sm border-b border-solid border-gray-lighter sm:px-10">
                {{row.LineOfBusinessId != 1 ? (row.AnnualizedPremium | currency) : '---'}}
              </td>
              <td class="p-10 px-5 text-sm border-b border-solid border-gray-lighter sm:px-10"
                *ngIf="selectedReportType === 'Inforce'">{{row.InforceDate | date: 'MM/dd/yyyy' : 'UTC'}}</td>
              <td class="p-10 px-5 text-sm border-b border-solid border-gray-lighter sm:px-10"
                *ngIf="selectedReportType === 'Inforce'">{{row.CommissionAmount ? (row.CommissionAmount | currency) : '---'}}</td>
              <td class="p-10 px-5 text-sm border-b border-solid border-gray-lighter sm:px-10">
                <div class="flex flex-col w-full">
                  <span class="font-bold" [ngClass]="{'text-orange' : row.ActionNeeded}">{{row.PolicyStatus}} -
                    {{row.PolicyStatusDate | date: 'MM/dd/yyyy' : 'UTC'}}</span>
                  <span>{{row.PolicyStatusNote | slice: 0:128}}<span
                      *ngIf="row.PolicyStatusNote !== null && row.PolicyStatusNote.length !== null && row.PolicyStatusNote.length > 128">...
                      <a *ngIf="!row.IsPaidPolicy" class="underline link text-blue-dark" [routerLink]="['../PolicyDetails', row.PolicyGuid]">View More</a></span></span>
                </div>
              </td>
              <td class="p-10 text-sm border-b border-solid border-gray-lighter">
                <span class="font-bold" *ngIf="row.IsPaidPolicy">Paid Policy</span>
                <div *ngIf="!row.IsPaidPolicy">
                  <div class="flex flex-row" *ngIf="!row.ActionNeeded">
                    <button class="mr-5 icon-button is-primary is-inverted" aria-label="Upload file"
                    matTooltip="Respond to Case Management" (click)="openRequirementModal(row, $event)"><span
                        class="material-icons mi-18">file_upload</span></button>
                    <button class="icon-button is-primary is-inverted" matTooltip="Go to Policy Details"
                      aria-label="Go to policy details" [routerLink]="['../PolicyDetails', row.PolicyGuid]"><span class="material-icons mi-18">east</span></button>
                  </div>
                  <div class="flex flex-row" *ngIf="row.ActionNeeded">
                    <button class="button is-alert is-sm is-outlined"
                      (click)="openRequirementModal(row, $event)">Resolve</button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <table *ngIf="selectedReportType === 'Informal'" class="w-full table-auto">
          <thead>
              <tr class="sticky top-0">
                  <td
                      class="sticky top-0 z-10 w-48 p-10 text-xs font-semibold uppercase bg-white border-b border-solid border-gray-lighter text-gray">
                  </td>
                  <td *ngFor="let cell of informalTable.Header"
                      class="sticky top-0 z-10 p-10 px-5 text-xs font-semibold uppercase bg-white border-b border-solid sm:px-10 border-gray-lighter hover:bg-blue-lightest text-gray">
                      <span (click)="orderRows(cell.ColumnName)"
                          class="flex items-center leading-6 cursor-pointer min-h-24 focus:outline-none"
                          attr.aria-label="Sort by {{cell.DisplayName}}">
                          <span class="mr-5">{{cell.DisplayName}}</span>
                          <!--
                          <span *ngIf="cell.ColumnName === columnName" class="material-icons text-gray">
                              {{reverseOrder ? 'arrow_drop_up' : 'arrow_drop_down'}}
                          </span>
                        -->
                      </span>
                  </td>
                  <td
                      class="sticky top-0 z-10 w-48 p-10 text-xs font-semibold uppercase bg-white border-b border-solid border-gray-lighter text-gray">
                  </td>
              </tr>
          </thead>
          <tbody class="overflow-auto">
              <tr id="policyRow" class="bg-opacity-25 cursor-pointer hover:bg-blue-lightest"
                  aria-label="Go to policy details"
                  *ngFor="let row of informalTable.Body"
                  (click)="openInformalPolicyDetails(row)">
                  <td
                      class="sticky top-0 z-10 w-48 p-10 text-xs font-semibold uppercase bg-white border-b border-solid border-gray-lighter text-gray">
                  </td>
                  <td class="p-10 px-5 text-sm border-b border-solid border-gray-lighter sm:px-10">
                      {{row.Agents}}
                  </td>
                  <td class="p-10 px-5 text-sm border-b border-solid border-gray-lighter sm:px-10">
                      {{row.RelatedPeople}}
                  </td>
                  <td class="p-10 px-5 text-sm border-b border-solid border-gray-lighter sm:px-10">
                      {{row.FaceAmount |
                      currency}}
                  </td>
                  <td class="p-10 px-5 text-sm border-b border-solid border-gray-lighter sm:px-10">
                      {{row.AnnualizedPremium |
                      currency}}
                  </td>
                  <td class="p-10 px-5 text-sm border-b border-solid border-gray-lighter sm:px-10">
                      {{row.StateName}}
                  </td>
                  <td class="p-10 px-5 text-sm border-b border-solid border-gray-lighter sm:px-10">
                      {{row.Carrier}}
                  </td>
                  <td
                      class="w-48 p-10 text-xs font-semibold uppercase bg-white border-b border-solid border-gray-lighter text-gray">
                  </td>
              </tr>
          </tbody>
        </table>
      </section>
      <div class="relative w-full p-20 loader-wrap" *ngIf="!!loadingRows" [@fadeIn]>
        <div class="loader-sm is-blue"></div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</section>