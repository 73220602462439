import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fadeIn } from 'src/app/animations';
import { privacyPolicyUrl, termsOfUseUrl } from 'src/app/shared/constants/fig-terms-of-use.constants';
import { AgentLicenses } from 'src/app/shared/models/agent.models';
import { OnboardingTermsOfUse } from 'src/app/shared/models/onboarding.models';
import { NgForm } from '@angular/forms';
import { AmplitudeEventService } from 'src/app/core/services/amplitude/amplitude-event.service';
import { QuinciOptInDataModel } from 'src/app/shared/models/product-rd-optin-data.models';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { OnboardingApiService } from 'src/app/core/services/http/onboarding-api.service';
import { UserApiService } from 'src/app/core/services/http/user-api.service';
import { PermissionApiService } from 'src/app/core/services/http/permission-api.service';
import { OptInEventService } from 'src/app/core/services/stores/opt-in-event.service';
import { PermissionService } from 'src/app/core/services/auth/permission.service';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { quinciLaunchDateUTC } from 'src/app/shared/constants/quinci-dates.constants';


@Component({
  animations: [fadeIn],
  selector: 'app-terms-of-use-modal',
  templateUrl: './terms-of-use-modal.component.html',
})

export class TermsOfUseModalComponent implements OnInit {
  isLoading: boolean = false;
  @ViewChild('optInForm') optInForm!: NgForm;
  confirmOptOut: boolean = false;
  showOptOutConfirmation: boolean = false;
  model: { userAccepts: boolean } = {
    userAccepts: true,
  };
  termsOfUseUrl: string = termsOfUseUrl.figSimon;
  privacyPolicyUrl: string = privacyPolicyUrl;
  requiresLicensesCapture: boolean = !this.data.isArcUser && !this.data.agentLicenses?.Npn && !this.data.agentLicenses?.Crd;
  quinciLaunchDate: Date = quinciLaunchDateUTC;

  constructor(
    public dialogRef: MatDialogRef<TermsOfUseModalComponent, { userAccepts: boolean }>,
    @Inject(MAT_DIALOG_DATA) public data: QuinciOptInDataModel,
    private amplitudeEventService: AmplitudeEventService,
    private sessionStoreService: SessionStoreService,
    private onboardingApiService: OnboardingApiService,
    private userApiService: UserApiService,
    private permissionApiService: PermissionApiService,
    private optInEventService: OptInEventService,
    private permissionService: PermissionService,
    private router: Router,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.trackNotOptedInUsers();
  }

  trackNotOptedInUsers(): void {
    const eventData = {
      userId: this.sessionStoreService.User.Id,
      onBehalfOfUserId: this.data.onBehalfOfUser?.Id
    };

    if(this.data.onBehalfOfUser) {
      this.amplitudeEventService.logCustomEvent('Undefined', 'Quinci Opt-in - Parent Not Opted In', eventData);
    }

  }

  updateProductRDTermsOfUse() {
    this.onboardingApiService.acceptTermsOfUse().subscribe(
      res => {},
      error => console.error(`Failed to accept terms of use. Error: ${error}`)
    );
  }

  updateAgentLicenses(agentLicenses: AgentLicenses) {
    if (!!agentLicenses.Crd || !!agentLicenses.Npn)
      this.sessionStoreService.setAgentLicenses(agentLicenses.Crd!, agentLicenses.Npn!).subscribe();
  }

  activateQuinciOptIn() {
    this.data.isOptedIn = true;
    this.userApiService.updateOptIn().subscribe(
      res => {
        this.permissionApiService.getPolicyStatements().subscribe(permissions => {
          this.isLoading = false;
          this.permissionService.setPermissions(permissions);
          this.optInEventService.broadcastIsOptin(true);
          this.router.navigate(['/Portal/ProductRD/Preview']);
        });
      },
      error => {
        this.data.isOptedIn = false;
        this.openSnackBar('Sorry, an error has occurred. Please try again later or contact us for support.');
        Observable.throw(new Error(error));
      });
  }

  declineQuinciOptIn():void {
    this.authService.logout();
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'close');
  }

  optOutConfirmation(): void {
    this.showOptOutConfirmation = !this.showOptOutConfirmation;
  }

  changedMyMind($event: Event): void {
    this.showOptOutConfirmation = false;
    if (!this.requiresLicensesCapture && this.optInForm.valid) this.optInForm.onSubmit($event);
  }

  submitUserResponse(delay: number = 0): void {
    // Form will only submit if user accepts & at least npn/crd/i don't have either has been filled out
    if(this.optInForm.invalid) return;

    this.dialogRef.afterClosed()
      .subscribe(res => {
        // if no model is passed into dialog.close(model) or on behalf of, return
        if (!res || this.data.onBehalfOfUser) return;

        if (res.userAccepts) {
          this.activateQuinciOptIn();

          // Update tou if applic.
          if (!this.data.onboardingTermsOfUse || !this.data.onboardingTermsOfUse.HasAgreedToTermsOfUse)
            this.updateProductRDTermsOfUse();

          this.amplitudeEventService.logClickEvent('Quinci Rollout: Opted In');
        } else this.amplitudeEventService.logClickEvent('Quinci Rollout: Declined Opt In');
      });
    setTimeout(() => {
      this.dialogRef.close(this.model);
    }, delay);
  }
}