import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AgentMarketingDollarsResponse, MarketingConsultant, MarketingDollarEmailData, MarketingDollarPointAdjustment } from 'src/app/shared/models/marketingdollar.models';

@Injectable({
  providedIn: 'root'
})
export class MarketingDollarApiService {

  constructor(private http:HttpClient) { }

  submitMarketingDollarPointAdjustmentForm(marketingDollarAdjustment:MarketingDollarPointAdjustment):Observable<number>{
    return this.http.post<number>('/api/MarketingDollarPointAdjustment/', marketingDollarAdjustment);
  }

  sendMarketingDollarPointAdjustmentEmails(marketingDollarRequestId:number, emailData:MarketingDollarEmailData){
    return this.http.post(`/api/MarketingDollarPointAdjustment/${marketingDollarRequestId}/SendEmails/?firstName=${emailData.FirstName}&lastName=${emailData.LastName}&sendToEmail=${emailData.sendToEmail}`, {},);
  }

  uploadToRequest(marketingDollarRequestId:number, form:FormData):Observable<number>{
    return this.http.post<number>(`/api/MarketingDollarPointAdjustment/${marketingDollarRequestId}/FileUpload`, form);
  }

  getMarketingConsultant():Observable<MarketingConsultant>{
    return this.http.get<MarketingConsultant>('/api/MarketingDollarPointAdjustment/MarketingConsultant');
  }

  getUserMarketingDollars():Observable<AgentMarketingDollarsResponse>{
    return this.http.get<AgentMarketingDollarsResponse>('/api/MarketingDollars');
  }

  exportMarketingDollars():Observable<Blob>{
    return this.http.get<Blob>('/api/MarketingDollars/Export', {responseType: 'blob' as 'json'});
  }

}
