import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MasterAdvisorAgreement, MasterAdvisorAgreementDocument } from 'src/app/shared/models/agreement.models';
import { OnboardingAgentLicensesOptOut } from 'src/app/shared/models/onboarding.models';
import { OnboardingApiService } from '../http/onboarding-api.service';

@Injectable({
  providedIn: 'root'
})
export class AgentLicenseOptOutResolver implements Resolve<Observable<OnboardingAgentLicensesOptOut|null>> {

  constructor(private onboardingApiService:OnboardingApiService) {
  }

  resolve(route:ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<OnboardingAgentLicensesOptOut|null>{
    return this.onboardingApiService.getUserAgentLicensesOptOut().pipe(catchError(() => of(null)));
  }
}