import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { fadeIn } from './animations';
import { Router, NavigationEnd, Data } from '@angular/router';
import { AmplitudeEventService } from './core/services/amplitude/amplitude-event.service';
import { PathToDataStoreService } from './core/services/stores/path-to-data-store.service';
import { RouteHistoryStoreService } from './core/services/stores/route-history-store.service';
import { ThirdLevelNavEventService } from './core/services/stores/third-level-nav-event.service';
import { environment } from 'src/environments/environment';

@Component({
  animations: [fadeIn],
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit{
  environment = environment;

  constructor(
    private amplitudeEventService: AmplitudeEventService,
    private pathToDataStore: PathToDataStoreService,
    private routeHistoryStore: RouteHistoryStoreService,
    private router: Router,
    private thirdLevelNavEventService: ThirdLevelNavEventService,
    private titleService: Title, ){
  }

  ngOnInit():void{
    this.titleService.setTitle('Agent Portal');
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Amplitude route tracking
        const fromState = this.pathToDataStore.stateLookUp(this.routeHistoryStore.getPreviousUrl());
        const toState = this.pathToDataStore.stateLookUp(this.routeHistoryStore.getCurrentUrl());
        this.amplitudeEventService.logNavigationEvent({from_state: fromState, to_state: toState});

        // Third level nav
        const currentData = (this.pathToDataStore.dataLookup(this.routeHistoryStore.getCurrentUrl()) as Data);
        if (currentData && currentData.thirdLevelNav) {
          this.thirdLevelNavEventService.broadcastIsThirdLevelNavOn(true);
        } else {
          this.thirdLevelNavEventService.broadcastIsThirdLevelNavOn(false);
        }
      }
    });
  }
}
