import { Observable } from 'rxjs';
import { AgentSubAgent } from './agent.models';

export interface ExportPolicyListModel {
  IsInforceReport: boolean;
  AveragePremium?: number;
  TotalPremium?: number;
  AverageFaceAmount?: number;
  TotalFaceAmount?: number;
  AverageAnnualPremium?: number;
  TotalAnnualPremium?: number;
  Policies: Policy[];
}

export interface Policy {
  PolicyGuid: string | null;
  AgentGuid: string;
  FirstName: string | null;
  LastName: string | null;
  PolicyNumber: string | null;
  Clients: string | null;
  Carrier: string | null;
  Product: string | null;
  AnnualizedPremium: number | null;
  Target: number | null;
  TotalFirstYearPremium: number;
  SubmittedDate: Date | null;
  PolicyStatus: string | null;
  PolicyStatusNote: string | null;
  PolicyStatusDate: Date | null;
  InforceDate: Date | null;
  FaceAmount: number | null;
  IsVariable: boolean | null;
  ActionNeeded: boolean | null;
  StatementDate: Date | null;
  CommissionAmount: number | null;
  SinglePremium: number;
  CurrentValue: number;
  LineOfBusinessName: string;
  LineOfBusinessId: number;
  IsPaidPolicy: boolean;
  Owners: string | null;
  JoinOwners: string | null;
  Annuitants: string | null;
  JointAnnuitants: string | null;
  Insureds: string | null;
  CoInsureds: string | null;
  AssociatedPortalAgentGUIDS: string | null;
  IssuedPremium: number | null;
  AccountValue: number | null;
  SurrenderEndDate: Date | null;
  SurrenderValue: number | null;
  SimonPolicyUrl?: string;
  IssuedCpcs: number;
  Cpcs: number;
  ModalPremium?: number;
  TotalIssuedPremium?: number;
  ClientModels? : PolicyClientModel[];
  IssuedHealthClasses? : PolicyHealthClass[];
  AppliedHealthClasses? : PolicyHealthClass[];
  ClientResidentState?: string;
  IsPartial?: boolean;
}

export interface PolicyDetail {
  PolicyGuid: string;
  LineOfBusiness: string;
  LineOfBusinessId: number;
  QualificationType: string | null;
  PolicyNumber: string | null;
  Clients: string | null;
  Carrier: string;
  Product: string;
  SinglePremium: number;
  FaceAmount: number | null;
  Notes: CarrierNotes[];
  PolicyStatus: string | null;
  StageHistory: PolicyStage[];
  StageHistoryObject: any[]
  TaskInformation: PolicyRequirements[];
  CarrierRequirements: CarrierRequirements[];
  CaseManager: string | null;
  IsPartial: boolean | null;
  ModalPremium: number;
  TotalIssuedPremium?: number;
}

export interface CarrierNotes {
  Notes: string;
  CreatedOn: Date | null;
}

export interface PolicyStage {
  PolicyStageId: number;
  PolicyStage: string | null;
  PolicyStageDate: Date | null;
}

export interface PolicyRequirements {
  TaskID: number;
  Name: string | null;
  Description: string | null;
  TaskStatus: string | null;
  Note: string | null;
  ModifiedBy: string | null;
  ModifiedOn: Date | null;
  RequirementDate: Date | null;
  ModifiedByFigAuthID: Date | null;
  CaseManagerName: string | null;
}

export interface CarrierRequirements {
  CarrierRequirement: string | null;
  Description: string | null;
  Status: string | null;
  AssignedTo: string | null;
  Note: string | null;
  RequestedDate: Date | null;
}

export interface PolicyFunding {
  PolicyGuid: string;
  Amount: number | null;
  Method: string | null;
  IssuedPremiumAmount: number | null;
  ModifiedBy: string | null;
  ModifiedOn: Date | null;
  IsFullyIssued: boolean | null;
}

export interface PolicySuitability {
  PolicyGuid: string;
  ModifiedBy: string | null;
  ModifiedOn: Date | null;
  IsSuitable: boolean;
}

export interface PolicyAgentUpload {
  FiledId: number;
  FileName: string | null;
  CreatedOn: Date | null;
  Type: string | null;
  Date: Date | null;
}

export interface PolicyTask {
  TaskId: number;
  TaskName: string | null;
  TaskStatus: {
    Name: string;
    ID: number;
  };
  CreatedOn: Date | null;
  ModifiedOn: Date | null;
  Notes: Note[];
  Type: string | null;
  Date: Date | null;
  ActionNeeded: boolean | null;
  FileArray: File[];
  Message?: string;
}

export interface Note {
  NoteId: number;
  Note: string | null;
  CreatedBy: string | null;
  CreatedOn: Date | null;
  ModifiedOn: Date | null;
  IsExternalUser: boolean;
  Type: string | null;
  Date: Date | null;
}

export interface PaperPolicyApplication {
  ClientFirstName: string | null;
  ClientLastName: string | null;
  PublicProductGuid: string | null;
  Notes: string;
}

// TODO Remove when My-Buisness is removed
export interface PolicyTable {
  Header: PolicyTableHeader[];
  Body: Policy[];
}

// TODO Remove when My-Buisness is removed
export interface InformalPolicyTable {
  Header: PolicyTableHeader[];
  Body: InformalPolicy[];
}


// TODO Remove when My-Buisness is removed
export interface PolicyTableHeader {
  DisplayName: string;
  ColumnName: string[];
  WidthClass: string;
  ReportTypes: string[];
}

export interface PolicyLob {
  Id: number;
  Name: string;
  Selected: boolean;
}

export class PolicyListFilter {
  SelectedLobIds: number[];
  SelectedAgentIds: string[];

  SelectedSubEntities: string[];
  IsClearingPolicies: boolean | null;
  ParentAgentGuid: string;

  StartDate: Date;
  EndDate: Date;
  AllLobIds: number[];
  AllAgentIds: (string | null)[];
  BaseStartDate: Date;
  BaseEndDate: Date;
  AllAgents?: AgentSubAgent[];
  CommisionGroupsFilterOn: boolean;

  constructor() {
    this.SelectedLobIds = [];
    this.SelectedSubEntities = [];
    this.SelectedAgentIds = [];
    this.IsClearingPolicies = null;
    this.ParentAgentGuid = '';
    this.StartDate = new Date(new Date().getFullYear(), (new Date().getMonth() -12), new Date().getDate());
    this.EndDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    this.AllLobIds = [];
    this.AllAgentIds = [];
    this.BaseStartDate = new Date(new Date().getFullYear(), (new Date().getMonth() -12), new Date().getDate());
    this.BaseEndDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    this.AllAgents = [];
    this.CommisionGroupsFilterOn = false;
  }
}

export interface PolicyList {
  export: () => Observable<Blob>;
  setSearchText: (searchText: string | null) => void;
  updateReportType: () => void;
  toggleSideNavMenu: () => void;
  title: string;
}

export enum PolicyListTypeEnum {
  Pending = 'Pending',
  Lifecycle = 'Lifecycle',
  Informal = 'Informal',
  Disability = 'Disability'
}

export interface InformalPolicy {
  AgentGuid: string;
  Agents: string;
  AnnualizedPremium: number;
  AwaitingOffer: boolean;
  Carrier: string | null;
  FaceAmount: number;
  InformalGuid: string;
  RelatedPeople: string | null;
  State: string;
  StateName: string;
}

export interface CarrierDetail {
  Carrier: string;
  HealthClassReason: string;
  HealthClasses: string;
  OfferDetails: string;
  PolicyNumber: string;
}

export interface InformalPolicyDetails {
  CarrierDetails: CarrierDetail[];
}

export interface InformalPolicyDetailsEntry {
  Entry: InformalPolicyDetails;
}

export interface RequirementStatus {
  Name: string;
  ID: number;
}

export interface PolicyClientModel {
  PersonId: number;
  FirstName: string;
  LastName: string;
  Dob: Date | null;
  PolicyRelationship: string;
  PolicyRelationshipId: number;
}

export interface PolicyHealthClass {
  HealthClass: string;
  HealthClassId: number;
  Notes: string;
}