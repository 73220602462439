import { AmplitudeEventService } from 'src/app/core/services/amplitude/amplitude-event.service';
import { Directive, Input } from '@angular/core';
import { HostListener } from '@angular/core';

@Directive({
  selector: '[amplitudeEvent]'
})
export class AmplitudeEventDirective {

  eventType: string = 'Click';
  propertyName: string = '';
  eventObject: object = {};
  //HTML element example:
  //<element... [amplitudeEvent]="'Header Eapp Menu: Firelight' + tile.Name" ...>
  //<element... amplitudeEvent="DashButton" [amplitudeEventOptions]="{'EventType': 'Custom', 'EventObject':{'FakeLabel': getComponent()}}" ...>

  constructor(
    private amplitudeEventService: AmplitudeEventService) { }

  @HostListener('click', ['$event']) onClick(event: Event) {
    if (this.eventType === 'Custom') {
      this.amplitudeEventService.logCustomEvent(this.propertyName, this.eventType, this.eventObject);
    } else {
      this.amplitudeEventService.logClickEvent(this.propertyName);
    }
    // Not sure of the purpose of stopping event propagation here since it will prevent any other directive attached on the same element from executing
    // May remove completely in the future
    // event.stopPropagation();
  }

  //Default directive
  @Input() set amplitudeEvent(propertyName: string) {
    this.propertyName = propertyName ? propertyName : '';
  }

  //set optional options for amplitude event
  @Input() set amplitudeEventOptions(amplitudeObject: AmplitudeObject) {
    this.eventType = amplitudeObject.EventType ? amplitudeObject.EventType : 'Custom';
    this.eventObject = amplitudeObject.EventObject ? amplitudeObject.EventObject : {};
  }
}
export interface AmplitudeObject {
  EventType?: string,
  EventObject?: object
}
