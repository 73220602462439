import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterAdvisorAgreement } from 'src/app/shared/models/agreement.models';
import { User } from 'src/app/shared/models/user.models';
import { AgreementsApiService } from 'src/app/core/services/http/agreements-api.service';
import { noop, Observable } from 'rxjs';
import { trigger, transition, style, animate } from '@angular/animations';
import { fadeIn } from 'src/app/animations';
import { CookieService } from 'ngx-cookie-service';
import { getRequiredSteps, isFirstStep, isLastStep } from 'src/app/shared/helpers/onboarding.helper';

// import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  animations: [fadeIn]
})
export class AgreementComponent implements OnInit {
  isLastStep:boolean = false;
  isFirstStep:boolean = false;
  Id:number = 2;
  loading: boolean = false;
  signError: boolean = false;
  workflow: string = 'ror';
  isRedirecting: boolean = false;
  taskCompleted: boolean = false;
  taskCompletedFromParent: boolean = false;
  requiredSteps!:number[]|null
  @Input() userMasterAdvisorAgreementRecord!: MasterAdvisorAgreement;
  @Input() envelopeId!: string | null;
  @Input() user!: User;
  @Output() taskCompletedEvent: EventEmitter<any> = new EventEmitter();
  @Output() goToPreviousEvent: EventEmitter<any> = new EventEmitter();
  @Output() goToNextEvent: EventEmitter<any> = new EventEmitter();
  @Output() goToPortalEvent: EventEmitter<string> = new EventEmitter();

  constructor(private activatedRoute: ActivatedRoute, private agreementsApiService: AgreementsApiService, private agreementsApi: AgreementsApiService,
    private cookieService:CookieService, private router:Router) { }

  ngOnInit(): void {
    // test to make sure user hasn't already done the MAA
    if (!this.taskCompletedFromParent) {

      // check api to see if they haven't completed MAA
      this.loading = true;

      this.activatedRoute.data.subscribe(x => {
        if (x.userMasterAdvisorAgreement !== null || x.userMasterAdvisorAgreementRequired == null) {
          this.completeTask();
          this.loading = false;
        } else {
          // do the normal MAA workflow
          this.agreementsApiService.getLoggedInUserMasterAdvisorAgreement().subscribe(
            x => {
              this.userMasterAdvisorAgreementRecord = x;
              this.activatedRoute.queryParams.subscribe(params => {
                const event = params['event'];
                const envelopeId = params['envelopeId'];
                if (!this.userMasterAdvisorAgreementRecord && !!envelopeId && event === 'signing_complete') {
                  const envelopeData: any = {
                    DocusignEnvelopeId: envelopeId
                  };
                  this.agreementsApiService.createMasterAdvisorAgreement(envelopeData).subscribe((res) => {
                    this.completeTask();
                  }, (error) => {
                    console.log(error.data);
                    if (error.data.indexOf('already exists') !== -1) {
                      this.completeTask();
                    }
                    this.signError = true;
                  });
                } else {
                  this.taskCompleted = false;
                  this.loading = false;
                }
              });
            }, noop, () => {
              this.loading = false;
            });
        }
      });
    }
    this.requiredSteps = getRequiredSteps(this.activatedRoute.snapshot.queryParamMap.get('requiredSteps'));
    // this.isFirstStep = isFirstStep(this.requiredSteps, this.Id);
    this.isLastStep = isLastStep(this.requiredSteps, this.Id);
  }

  completeTask(): void {
    this.taskCompleted = true;
    this.taskCompletedEvent.emit(this.taskCompleted);
  }

  createAgreement(): void {
    let httpCall:Observable<string>;
    this.requiredSteps ? httpCall = this.agreementsApi.initMasterAdvisorAgreement(this.workflow, this.requiredSteps) : httpCall = this.agreementsApi.initMasterAdvisorAgreement(this.workflow);
    this.isRedirecting = true;

    httpCall.subscribe(
      (res) => {
        window.open(res, '_self');
      },
      (error) => {
        console.log(error);
        if (error.data === 'An agreement for this user already exists.') {
          this.completeTask();
        } else if (location.hostname === 'localhost') {
          // bypass for localhost
          this.completeTask();
        }
        this.signError = true;
      },
      () => {
        this.isRedirecting = false;
      }
    );
  }

  goToNext(): void {
    if (this.isLastStep) {
      this.goToPortalEvent.emit('Dashboard');
    } else {
      this.taskCompletedEvent.emit(true);
      this.goToNextEvent.emit();
    }
  }

  goToPrevious(): void {
    this.goToPreviousEvent.emit();
  }

  loadTaskCompleted(fromParent: boolean): void {
    this.taskCompletedFromParent = fromParent;
    this.taskCompleted = this.taskCompletedFromParent;
  }
}
