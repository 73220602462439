import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { fadeIn } from 'src/app/animations';
import { RiaApiService } from 'src/app/core/services/http/ria-api.service';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { searchFilter } from 'src/app/shared/helpers/search.helper';
import { PolicyList,  } from 'src/app/shared/models/policy.models';
import { RiaAccount } from './models/riaAccount.model';

@Component({
  selector: 'app-investment-policy-list',
  templateUrl: './investment-policy-list.component.html',
  animations: [fadeIn]
})
export class InvestmentPolicyListComponent implements OnInit, PolicyList {
  title:string = 'Wealth';
  columnName:string[] = ['DateOpened']
  isError:boolean = false;
  searchText:string|null = null;
  isLoading:boolean = true;
  searchFilter = searchFilter
  allPolicies:RiaAccount[] = [];
  filteredAccounts: RiaAccount[] = [];

  constructor(
    private riaApi:RiaApiService,
    private snackbar: MatSnackBar,
    private sessionStoreService:SessionStoreService) { }

  ngOnInit(): void {
    this.getPolicies();
  }

  getPolicies(): void {
    const crd = this.sessionStoreService.OnBehalfOfUser ? this.sessionStoreService.OnBehalfOfUser.AgentLicenses.Crd : this.sessionStoreService.User.AgentLicenses.Crd;
    if (crd) {
      this.riaApi.getAcmAccountsByAgentCRD(crd).subscribe(accounts => {
        this.allPolicies = accounts;
        this.setTable();
      }, (error) => {
        console.log(error);
        this.isError = true;
        this.openSnackbar('Sorry, an error has occurred. Please try again later.', 'is-error');
      });
    }
  }

  setTable(): void {
    this.isLoading = true;
    this.filteredAccounts = this.getFilteredPolicies();
    this.isLoading = false;
  }

  getFilteredPolicies(): RiaAccount[] {
    if (this.searchText){
      return this.allPolicies.filter(policy => {
        return this.searchTextFilter(policy);
      });
    } else {
      return this.allPolicies;
    }

  }

  searchTextFilter(policy:RiaAccount): boolean{
    if (!this.searchText) return true;
    return searchFilter(policy, this.searchText);
  }

  setSearchText(searchText:string|null): void {
    this.searchText = searchText;
    this.setTable();
  }

  export(): Observable<Blob> {
    if (this.filteredAccounts.length === 0) {
      this.openSnackbar('No policies. Please select different filters.', 'is-default');
    }
    return this.riaApi.exportRiaAccounts(this.filteredAccounts);
  }

  openSnackbar(message: string, toastClass: string): void {
    this.snackbar.open(message, 'Close', {
      panelClass: [toastClass]
    });
  }

  // Non used interface methods
  updateReportType(): void {}
  toggleSideNavMenu(): void {}

}
