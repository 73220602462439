import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { PermissionService } from '../../auth/permission.service';
// import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BusinessGuardService implements CanActivate {
  constructor(private router: Router, private permissionService:PermissionService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const hasSimonOptIn = this.permissionService.hasOnePermission('ProductRDOptedIn:View');
    if (hasSimonOptIn) {
      this.router.navigate(['Portal/Business/Fixed/Pending']);
      return false;
    } else {
      return true;
    }
  }
}