import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-annuity-rate-watch-lifetime-income-tool',
  templateUrl: './annuity-rate-watch-lifetime-income-tool.component.html',
})
export class AnnuityRateWatchLifetimeIncomeToolComponent {
  //need to add prod url once whitelisted
  iframeUrl: string = 'https://qa.portal.figmarketingusercontent.com/annuity-rate-watch-quoting-tool.template.html';

  constructor() { }

}
