export function searchFilter(data: any, searchTerm: string): boolean;
export function searchFilter(data: any, searchTerm: string[]): boolean;
export function searchFilter(data: any, searchTerm: any): boolean {
  if (isSearchTermNullOrEmpty(searchTerm)) return true;

  let isMatch: boolean = false;
  let searchTermArray: string[] = [];
  const values: any[] = Object.values(data);
  if (typeof searchTerm === 'string') searchTermArray.push(searchTerm.trim());
  else searchTermArray = searchTerm;

  for (const term of searchTermArray.map(x => x.toLowerCase())) {
    if (isMatch) break;

    for (const value of values) {
      if (containsTerm(value, term)) {
        isMatch = true;
        break;
      }
    }
  }
  return isMatch;
}

const isSearchTermNullOrEmpty = (searchTerm: any): boolean => {
  return !searchTerm || searchTerm == '' || !searchTerm.length;
};

const containsTerm = (value: any, searchTerm: string): boolean => {
  return value && String(value).toLowerCase().includes(searchTerm);
};