import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { fadeIn } from 'src/app/animations';
import { PermissionService } from 'src/app/core/services/auth/permission.service';
import { UserApiService } from 'src/app/core/services/http/user-api.service';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { PortalNavItem } from 'src/app/shared/models/portal-nav-item';
import { environment } from 'src/environments/environment';

@Component({
  animations: [fadeIn],
  selector: 'app-subnav',
  templateUrl: './subnav.component.html'
})
export class SubnavComponent implements OnInit {
  @Input() navItem!: PortalNavItem;
  isArcUser:boolean = false;
  isFigProd = this.sessionStore.User.Id == 'a608d7e6-a9fc-44f5-850b-6c660f0f1fca'
  environment = environment;
  constructor(
    private permissionService: PermissionService,
    private userApiService: UserApiService,
    private sessionStore:SessionStoreService,
    private navigationService:NavigationService
  ) { }

  ngOnInit(): void {
    this.userApiService.getIsArcUser().subscribe(res => this.isArcUser = res);
    this.navItem.SubNav!.forEach(item => {
      item.SubNav ? item.SubNav = this.navigationService.filterSubNavItem(item.SubNav) : '';
    });
  }
}
