import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SurelcApiService } from 'src/app/core/services/http/surelc-api.service';
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { stateLongNameByShortName, statesPlusDCAndVI } from 'src/app/shared/constants/states.constants';
import { SureLcLicense } from 'src/app/shared/models/surelc-license';


@Component({
  selector: 'app-contracting-licenses',
  templateUrl: './contracting-licenses.component.html',
})
export class ContractingLicensesComponent implements OnInit {
  licenses!: SureLcLicense[];
  stateLongNameByShortName: Record<string, string> = stateLongNameByShortName

  constructor(
    public dialogRef: MatDialogRef<ContractingLicensesComponent>,
    private surelcApiService: SurelcApiService) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.surelcApiService.getStateLicenses().subscribe(r => {
      this.licenses = r;
    });
  }
}
