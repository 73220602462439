import { ActivatedRoute, Router} from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fadeIn } from 'src/app/animations';
import { OnboardingApiService } from '../../../core/services/http/onboarding-api.service';
import { PermissionApiService } from '../../../core/services/http/permission-api.service';
import { PermissionService } from '../../../core/services/auth/permission.service';
import { SsoApiService } from '../../../core/services/http/sso-api.service';
import { SsoService } from 'src/app/core/services/sso.service';
import { SureLcSsoUser } from '../../../shared/models/sso.models';
import { User } from 'src/app/shared/models/user.models';
import { UserGroup } from 'src/app/shared/models/usergroups.models';
import { UserApiService } from '../../../core/services/http/user-api.service';
import { CookieService } from 'ngx-cookie-service';
import { getRequiredSteps, isFirstStep, isLastStep } from 'src/app/shared/helpers/onboarding.helper';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';

@Component({
  selector: 'app-carrier-setup',
  templateUrl: './carrier-setup.component.html',
  animations: [fadeIn]
})
export class CarrierSetupComponent implements OnInit {
  isFirstStep:boolean = false;
  isLastStep:boolean = false;
  Id:number = 4;
  loading: boolean = false;
  isRedirecting: boolean = false;
  taskCompleted: boolean = false;
  taskCompletedFromParent: boolean = false;
  sureLcUser!: SureLcSsoUser;
  requiredSteps!:number[]|null;
  @Output() taskCompletedEvent: EventEmitter<any> = new EventEmitter();
  @Output() goToPreviousEvent: EventEmitter<any> = new EventEmitter();
  @Output() goToNextEvent: EventEmitter<any> = new EventEmitter();
  @Output() goToPortalEvent: EventEmitter<string> = new EventEmitter();

  checklist: string[] = ['Scan of your E&amp;O', 'Scan of a voided check', 'EIN &amp; business address'];
  constructor(private activatedRoute: ActivatedRoute, private ssoService: SsoService, private ssoApiService:SsoApiService, private onboardingApiService: OnboardingApiService, private userApiService: UserApiService, private permissionApiService: PermissionApiService, private permissionService: PermissionService,
    private cookieService:CookieService, private router:Router, private sessionStore:SessionStoreService) { }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(resolve => {
      if (resolve.userContracting == null || (resolve.userContracting !== null && resolve.userContracting.IsCompleted == true)) {
        this.completeTask();
      }
    });

    this.ssoApiService.getSureLcUser().subscribe(x => {
      this.sureLcUser = x;
    });

    this.requiredSteps = getRequiredSteps(this.activatedRoute.snapshot.queryParamMap.get('requiredSteps'));
    // this.isFirstStep = isFirstStep(this.requiredSteps, this.Id);
    this.isLastStep = isLastStep(this.requiredSteps, this.Id);
  }

  carrierSetupLaunchSureLc() {
    this.isRedirecting = true;

    if (this.sessionStore.UserGroup && this.sessionStore.UserGroup.AffiliationLink) {
      window.open(this.sessionStore.UserGroup.AffiliationLink);
      this.isRedirecting = false;
    } else {
      this.ssoService.sureLcSso(this.sureLcUser);
      this.isRedirecting = false;
    }

    setTimeout(() => {
      this.isRedirecting = false;
      this.goToNext();
    }, 5000);

    this.carrierSetupCompleteContracting();
  }

  carrierSetupCompleteContracting() {
    this.completeTask();
    this.onboardingApiService.updateContractingCompleted().subscribe();
    this.carrierSetupUpdatePermissions();
  }

  carrierSetupUpdatePermissions() {
    if (!this.taskCompleted || this.sessionStore.User.UserGroupId !== null) return;

    this.userApiService.updateOnboardingCompletedPermissions().subscribe(res => {
      this.permissionApiService.getPolicyStatements().subscribe(permissions => {
        this.permissionService.setPermissions(permissions);
      });
    });
  }

  loadTaskCompleted(fromParent: boolean): void {
    this.taskCompletedFromParent = fromParent;
    this.taskCompleted = this.taskCompletedFromParent;
  }

  completeTask(): void {
    this.taskCompleted = true;
    this.taskCompletedEvent.emit(this.taskCompleted);
  }

  goToNext(): void {
    if (this.isLastStep) {
      this.goToPortalEvent.emit('Dashboard');
    } else {
      this.taskCompletedEvent.emit(true);
      this.goToNextEvent.emit();
    }
  }

  goToPrevious(): void {
    this.goToPreviousEvent.emit();
  }
}
