import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionService } from 'src/app/core/services/auth/permission.service';

@Directive({
  selector: '[showIfAll]'
})
export class ShowIfAllDirective {

  private hasView = false;

  constructor(private templateRef:TemplateRef<any>, private viewContainerRef:ViewContainerRef, private permissionService:PermissionService) { }

  @Input() set showIfAll(permissions:string) {

    const isAuthorized = this.permissionService.hasAllPermissions(permissions);
    if(isAuthorized && !this.hasView){
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!isAuthorized && this.hasView) {
      this.viewContainerRef.clear();
      this.hasView = false;
    }
  }
}
