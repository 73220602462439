import { AuthService } from '../core/services/auth/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { LeaderboardComponent } from './modals/leaderboard/leaderboard.component';
import { MatDialog } from '@angular/material/dialog';
import { PermissionService } from '../core/services/auth/permission.service';
import { PermissionApiService } from '../core/services/http/permission-api.service';
import { PortalNavItem } from '../shared/models/portal-nav-item';
import { RedeemMarketingDollarsComponent } from './modals/redeem-marketing-dollars/redeem-marketing-dollars.component';
import { Router, ActivatedRoute, RouterOutlet } from '@angular/router';
import { SubmitPaperApplicationComponent } from './modals/submit-paper-application/submit-paper-application.component';
import { SupportComponent } from './modals/support/support.component';
import { Title } from '@angular/platform-browser';
import { User, UserDelegate } from '../shared/models/user.models';
import { UserApiService } from '../core/services/http/user-api.service';
import { UserGroup } from '../shared/models/usergroups.models';
import { SessionStoreService } from '../core/services/stores/session-store.service';
import { SsoService } from '../core/services/sso.service';
import { fadeIn, fadeInOut, routerTransition, slideInOut } from '../animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { sidenavConfig } from '../shared/models/sidenav-config.model';
import { AgentMarketingDollarsResponse } from '../shared/models/marketingdollar.models';
import { MarketingDollarApiService } from '../core/services/http/marketingdollar-api.service';
import { MarketingExpenseHistoryComponent } from './modals/marketing-expense-history/marketing-expense-history/marketing-expense-history.component';
import { CookieService } from 'ngx-cookie-service';
import { CpcApiService } from '../core/services/http/cpc-api.service';
import { CpcModel } from '../shared/models/cpc.models';
import { StartIllustrationComponent } from './modals/start-illustration/start-illustration/start-illustration.component';
import { ThirdLevelNavEventService } from '../core/services/stores/third-level-nav-event.service';
import { OptInEventService } from 'src/app/core/services/stores/opt-in-event.service';
import { NavigationService } from '../core/services/navigation.service';
import { environment } from 'src/environments/environment';
import { UserLogoUploadComponent } from './modals/user-logo-upload/user-logo-upload.component';
import { figLogoUrl } from 'src/app/shared/constants/misc.constants';
import { ConfirmationComponent } from './modals/confirmation/confirmation.component';
import { ConfirmationData } from '../shared/models/modal-data.models';
import { UserSettingsContainerComponent } from './modals/user-settings-container/user-settings-container.component';
import { EnrollmentFormApiService } from '../core/services/http/enrollment-form-api.service';
import { RenewalFormComponent } from './modals/renewal-form/renewal-form.component';
import { SurveyRenewalModel } from '../shared/models/surveyRenewal.models';
import { CountdownOptInComponent } from './modals/countdown-opt-in/countdown-opt-in.component';
import { OnboardingService } from '../core/services/onboarding.service';
import { TermsOfUseModalComponent } from './modals/product-rd-tou/terms-of-use/terms-of-use-modal.component';
import { QuinciOptInDataModel } from '../shared/models/product-rd-optin-data.models';
import { OnboardingAgentLicensesOptOut, OnboardingTermsOfUse } from '../shared/models/onboarding.models';
import { renewalStatusEnum } from '../shared/enums/renewal-status.enum';
import { DateComparisonHelper } from '../shared/helpers/dates-comparison/date-comparison.helper';
import { quinciHardDeadlineDateUTC, quinciLaunchDateUTC } from '../shared/constants/quinci-dates.constants';
import { termsOfUseUrl } from '../shared/constants/fig-terms-of-use.constants';
import { simonLogout } from '../shared/helpers/simon-logout.helper';

@Component({
  animations: [fadeInOut, fadeIn, slideInOut, routerTransition],
  selector: 'app-portal',
  templateUrl: './portal.component.html',
})

export class PortalComponent implements OnInit {
  showBanner = false;
  figLogoUrl: string = figLogoUrl;
  termsOfUseUrl: string = '';
  termsOfUseNoSimonUrl: string = termsOfUseUrl.figOnly;
  termsOfUseSimonUrl: string = termsOfUseUrl.figSimon;
  cpcStartDate: Date = this.getCPCStartDate();
  cpcEndDate: Date = this.getCPCMaxEndDate();
  userCPCs!: CpcModel;
  confirmResult: boolean = false;
  toggleTestQuinciLaunch: boolean = false;
  countdownUTCTime: number = quinciLaunchDateUTC.getTime() + quinciLaunchDateUTC.getTimezoneOffset();
  countdownCookie = this.cookieService.get('quinci-countdown-cookie');
  delegates: UserDelegate[] = [];
  delegateId!: string | null;
  fromOnboarding!: boolean;
  hasCompletedOnboardingCheck: boolean = false;
  hasSubmitMarketingExpensesPermissions: boolean = (this.permissionService.hasAllPermissions('AccessMarketingPoints:View|PointAdjustments:View'));
  hasCPCPermission: boolean = (this.permissionService.hasOnePermission('AgentCPCs:View'));
  loading: boolean = false;
  logo!: string | null;
  menuOpen: boolean = false;
  navItems: PortalNavItem[] = this.navigationService.portalNavItems.filter(item => !item.Resolve || item.Resolve());
  onBehalfOfUser!: User | null;
  sideNavIsOpen: boolean = true;
  user!: User;
  testUserId: string | null = environment.figPortalTestUserId;
  userGroup!: UserGroup | null;
  userMarketingDollars!: AgentMarketingDollarsResponse | null;
  viewingUser!: User;
  userSidenavConfig: sidenavConfig = {
    IsOpened: false,
    Mode: 'side',
  };
  portalSidenavConfig: sidenavConfig;
  mediaMobile: boolean = false;
  @ViewChild('userSidenav') userSidenav: any;
  @ViewChild('portalSidenav') portalSidenav: any;
  environment = environment;
  isThirdLevelNavOn: boolean = false;
  isArcUser: boolean = false;
  termsOfUse?: OnboardingTermsOfUse;
  npnCrdOptOut?: OnboardingAgentLicensesOptOut;
  isOptedIntoQuinci: boolean | null = null;
  canSeeIllustrationButton: boolean = false;
  clockTick: number = Date.now();
  selectedUserId: string | 1 = 1;
  userHasOpenRenewals: boolean = false;
  renewals?: SurveyRenewalModel[];
  logoutFromSimon: boolean = false;
  quinciLaunchDateUTC: Date = quinciLaunchDateUTC;
  quinciHardDeadlineDateUTC: Date = quinciHardDeadlineDateUTC;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private breakpointObserver: BreakpointObserver,
    private cookieService: CookieService,
    private cpcApiService: CpcApiService,
    private dialog: MatDialog,
    private permissionApiService: PermissionApiService,
    private permissionService: PermissionService,
    private router: Router,
    private marketingDollarApiService: MarketingDollarApiService,
    private sessionStore: SessionStoreService,
    private ssoService: SsoService,
    private thirdLevelNavEventService: ThirdLevelNavEventService,
    private titleService: Title,
    private userApiService: UserApiService,
    private optInEventService: OptInEventService,
    private navigationService: NavigationService,
    private enrollmentFormApiService: EnrollmentFormApiService,
    private onboardingService: OnboardingService,
  ) {

    //define main sidenav config here to ensure it loads first to avoid stuttering issue
    this.portalSidenavConfig = {
      IsOpened: true,
      Mode: 'over',
    };

    this.thirdLevelNavEventService.isThirdLevelNavOn$.subscribe(val => {
      this.isThirdLevelNavOn = val;
    });

    this.router.events
      .subscribe(() => {
        const sideNavContainer = document.querySelector('.mat-sidenav-content');
        if (sideNavContainer) sideNavContainer.scrollTop = 0;
      });
  }

  ngOnInit(): void {
    this.loading = true;
    this.titleService.setTitle('Agent Portal');
    this.fromOnboarding = this.activatedRoute.snapshot.queryParamMap.get('fromOnboarding') == 'true' ? true : false;
    this.configureUserInfo();
    this.activatedRoute.data.subscribe(resolve => {
      this.termsOfUse = resolve.userTermsOfUse;
      this.npnCrdOptOut = resolve.userAgentLicensesOptOut;
      if (!this.sessionStore.OnBehalfOfUser && !this.fromOnboarding) {
        this.runOnboardingCheck(resolve);
      } else {
        this.hasCompletedOnboardingCheck = true;
      }
      if (this.hasCompletedOnboardingCheck && this.needsQuinciOptIn() && !this.user.IsArcUser) this.openQuinciOptInModal();
      this.isOptedIntoQuinci = this.permissionService.hasOnePermission('ProductRDOptedIn:View');
      this.termsOfUseUrl = this.isOptedIntoQuinci ? this.termsOfUseSimonUrl : this.termsOfUseNoSimonUrl;
      this.setCanSeeIllustrationButton();
      this.getMarketingDollars();
      this.getCpcs();
      this.navItems.forEach(item => {
        item.SubNav ? item.SubNav = this.navigationService.filterSubNavItem(item.SubNav) : '';
      });
      this.configureLayOut();
      if (!this.getComponent()) this.router.navigateByUrl('Portal/Dashboard');
      this.loading = false;
    });

    this.optInEventService.isOptIn$
      .subscribe(val => {
        // TODO: this is not always hit
        this.isOptedIntoQuinci = this.permissionService.hasOnePermission('ProductRDOptedIn:View');
        this.termsOfUseUrl = this.isOptedIntoQuinci ? this.termsOfUseSimonUrl : this.termsOfUseNoSimonUrl;
        this.navItems = this.navigationService.portalNavItems.filter(item => !item.Resolve || item.Resolve());
      });

    if (this.hasCompletedOnboardingCheck) this.checkCountdownRenewals();
    // this.openAlert();
  }

  checkShowBanner(): void {
    const todaysTime = new Date().getTime();
    const endDateTime = new Date('April 2, 12022 10:30:00').getTime();
    this.showBanner = todaysTime < endDateTime;
  }

  configureUserInfo(): void {
    this.user = this.sessionStore.User;
    this.onBehalfOfUser = this.sessionStore.OnBehalfOfUser;
    this.userGroup = this.sessionStore.OnBehalfOfUserGroup ?? this.sessionStore.UserGroup;
    this.logo = this.determineUserLogo();
    this.userApiService.getUsersForDelegateUser(this.user.Id).subscribe(res => {
      this.delegates = res;
      this.delegateId = this.permissionService.getUserDelegateId();
      if (this.delegateId !== null && this.onBehalfOfUser === null) {
        const delegate = this.delegates.find(d => d.Id === this.delegateId);
        this.onBehalfOfUser = delegate === undefined ? null : delegate.OnBehalfOfUser;
      }
      this.selectedUserId = this.delegateId ? this.delegateId : this.onBehalfOfUser ? this.onBehalfOfUser.Id : 1;
    });
  }

  getLogo(): string | null {
    return this.logo;
  }

  determineUserLogo(): string | null {
    return this.sessionStore.UserLogo;
  }

  getCpcs(): void {
    this.cpcApiService.getUserCPCs(this.cpcStartDate, this.cpcEndDate)
      .subscribe(res => {
        this.userCPCs = res;
      });
  }

  getMarketingDollars(): void {
    this.marketingDollarApiService.getUserMarketingDollars()
      .subscribe(res => {
        this.userMarketingDollars = res;
      });
  }

  configureLayOut(): void {
    this.breakpointObserver.observe([
      Breakpoints.TabletLandscape,
      Breakpoints.WebLandscape
    ]).subscribe(result => {
      if (result.matches) {
        this.mediaMobile = false;
        this.portalSidenavConfig.IsOpened = true;
        this.portalSidenavConfig.Mode = 'side';
        this.userSidenavConfig.Mode = 'over';
      } else {
        this.mediaMobile = true;
        this.portalSidenavConfig.IsOpened = false;
        this.portalSidenavConfig.Mode = 'push';
        this.userSidenavConfig.Mode = 'push';
      }
    }, () => {
      console.log('error');
    });
  }

  runOnboardingCheck(resolve: any): void {
    const onboardingStepModel = this.onboardingService.getRequiredOnboardingSteps(resolve.userMasterAdvisorAgreement, resolve.userMasterAdvisorAgreementRequired, resolve.userAgentLicensesOptOut, resolve.userContracting);
    if (onboardingStepModel.RequiredSteps.length > 0) {
      this.router.navigate(['/Onboarding/Welcome'], { queryParams: { requiredSteps: encodeURIComponent(JSON.stringify(onboardingStepModel.RequiredSteps)), alternateView: onboardingStepModel.ShowAlternateView } });
    } else {
      this.hasCompletedOnboardingCheck = true;
    }
  }

  setCanSeeIllustrationButton(): boolean {
    if (environment.local || environment.qa) return true;
    else return false;

    // let hasOptedIn = this.hasOptedIn;
    // let hasMarketPlaceAnnuityPermission = this.permissionService.hasOnePermission('MarketplaceAnnuity:View');
    // if (!hasOptedIn || !hasMarketPlaceAnnuityPermission) return;

    // this.canSeeIllustrationButton = this.sessionStore.OnBehalfOfUser ? !!this.sessionStore.OnBehalfOfUser.AgentLicenses.Npn : !!this.sessionStore.User.AgentLicenses.Npn;
  }

  activateFirelightSso(): void {
    this.ssoService.firelightSso();
  }

  activateIpipelineSso(): void {
    this.ssoService.iPipelineEAppsSso();
  }

  checkCountdownDays(): boolean {
    if (environment.local || environment.qa) return true;
    // date as index, starting from 15
    if (this.getCountdownDaysRemaining() === 6 ||
      this.getCountdownDaysRemaining() === 2 ||
      this.getCountdownDaysRemaining() === 1 ||
      this.getCountdownDaysRemaining() === 0) return true;
    return false;
  }

  checkCountdownRenewals(): void {
    const showCountDown: boolean =
      !!this.checkCountdownDays() &&
      this.permissionService.hasOnePermission('ShowProductRDOptIn:View') &&
      !this.isOptedIntoQuinci &&
      !this.onBehalfOfUser;
    this.checkForRenewals();
  }

  getCountdownDaysRemaining(): number {
    const nowDate = new Date();
    const now = nowDate.getTime() + nowDate.getTimezoneOffset();

    // Find the distance between now and the count down date
    const distance = this.countdownUTCTime - now;

    // Time calculations for days, hours, minutes and seconds
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    return days;
  }

  getComponent(): string {
    const homeUrl: string = '/Portal/';
    const path: string[] = window.location.pathname.split(homeUrl);
    return path[1];
  }

  getState(outlet: RouterOutlet): string | any | null {
    const ret = outlet.activatedRoute.component;
    return ret;
  }

  logout(): void {
    this.dialog.open<ConfirmationComponent, ConfirmationData>(ConfirmationComponent, {
      data: {
        message: 'Are you sure you want to log out?',
        isWarning: true,
        confirmationButtonText: 'Log Out'
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.authService.logout();
      } else return;
    });
  }

  getCPCMaxEndDate(): Date {
    const today = new Date();
    const dayOfMonth = today.getDate();
    let endDate = null;
    if (dayOfMonth >= 1 && dayOfMonth <= 15) {
      endDate = new Date(today.getUTCFullYear(), today.getMonth() - 1, 1);
    } else {
      endDate = new Date(today.getUTCFullYear(), today.getMonth(), 1);
    }

    return endDate;
  }

  getCPCStartDate(): Date {
    const today = new Date();
    const dayOfMonth = today.getDate();
    const monthOfYear = today.getMonth();
    let startDate = null;
    if (dayOfMonth >= 1 && dayOfMonth <= 15 && monthOfYear <= 1) {
      startDate = new Date(new Date().getFullYear() - 1, 0, 1);
    } else {
      startDate = new Date(new Date().getFullYear(), 0, 1);
    }

    return startDate;
  }

  getUserCPCs(): void {
    this.cpcApiService.getUserCPCs(this.cpcStartDate, this.cpcEndDate)
      .subscribe(res => {
        this.userCPCs = res;
      });
  }

  openAlert(): void {
    this.dialog.open(ConfirmationComponent, {
      data: {
        title: 'Alert', //optional
        message: 'Due to a nationwide outage by our host provider, some resources in the Agent Portal may be negatively impacted.',
        confirmationButtonText: 'close', //optional
        isWarning: true, //optional
        hideCancelButton: true, //optional
      }
    });
  }

  openCountdownModal(): void {
    this.dialog.open(CountdownOptInComponent, {
      minHeight: '58rem',
    }).afterClosed().subscribe(() => {
      this.setCountdownCookie();
      this.checkForRenewals();
    });
  }

  openLeaderboardModal(): void {
    this.dialog.open(LeaderboardComponent, {
      height: '74rem',
    });
  }

  openPaperApplicationModal(): void {
    this.dialog.open(SubmitPaperApplicationComponent, {
      height: '74rem',
    });
  }

  openRedeemMarketingDollars(): void {
    this.dialog.open(RedeemMarketingDollarsComponent, {
      height: '74rem',
    });
  }

  openEAppGuide(): void {
    window.open('https://www.figmarketing.com/files/resources/EApp_Quick_Start_Guide.pdf');
  }

  openSupportModal(): void {
    this.dialog.open(SupportComponent, {
      height: '74rem',
    });
  }

  openMarketingExpenseHistoryModal(): void {
    this.dialog.open(MarketingExpenseHistoryComponent, {
      data: {
        marketingExpenseHistory: this.userMarketingDollars ? this.userMarketingDollars.Details : []
      },
      height: '74rem',
    });
  }

  openStartIllustrationModal(): void {
    this.dialog.open(StartIllustrationComponent);
  }

  redirectToEAppWorkflow(): void {
    this.router.navigate(['/Portal/ProductRD/EApplication/Workflow/PrepareEApplication']);
  }

  redirectToIcomplyLite(): void {
    this.ssoService.iComplyLiteSso();
  }

  redirectToMarketplaceOne(): void {
    let onBehalfOfId: string | undefined = undefined;

    if (this.delegateId) {
      const delegate = this.delegates.find(x => x.Id == this.delegateId!);
      if (delegate) onBehalfOfId = delegate.OnBehalfOfUserId;
    } else if (this.onBehalfOfUser) onBehalfOfId = this.onBehalfOfUser.Id;

    this.ssoService.marketPlaceOneSso(onBehalfOfId);
  }

  selectMeAsUser(): void {
    this.delegateId = null;
    this.viewingUser = this.user;
    this.logoutFromSimon = true;
    simonLogout.subscribe(() => {
      this.permissionService.removeStoredOnBehalfOfUserId();
      window.location.href = '/Portal';
    });
  }

  selectOnBehalfOfUser(delegateId: string): void {
    const delegate = this.delegates.find((row) => row.Id == delegateId);
    if (delegate) {
      simonLogout.subscribe(() => {
        this.logoutFromSimon = true;
        this.viewingUser = delegate.User;
        this.delegateId = delegateId;
        this.permissionService.setOnBehalfOfUserId(delegate.OnBehalfOfUserId);
        this.permissionService.setUserDelegateId(delegate.Id);
        window.location.href = '/Portal';
      });
    }
    return;
  }

  selectActiveUser(id: string | 1): void {
    if (id == 1) this.selectMeAsUser();
    else this.selectOnBehalfOfUser(id);
  }

  setCountdownCookie(): void {
    if (!this.countdownCookie) {
      const minutes: number = 1440;
      const d = new Date();
      const dateString = (d.getMonth() + 1) + '/' + d.getDate() + '/' + d.getFullYear();
      d.setMinutes(d.getMinutes() + minutes);
      const expireDate = d;
      //cookie expiry datetime deletes the cookie immediately
      this.cookieService.set('quinci-countdown-cookie', dateString, { 'expires': expireDate });
    }
  }

  togglePortalSidenav(): void {
    if (this.mediaMobile) {
      this.portalSidenav.toggle();
      if (this.userSidenav.opened) this.userSidenav.close();
    }
  }

  toggleUserSidenav(): void {
    this.userSidenav.toggle();
    if (this.mediaMobile) {
      if (this.portalSidenav.opened) this.portalSidenav.close();
    }
  }

  // Only allow when user is logged in as themselves and not part of a group
  openUploadLogoModal(): void {
    if (this.onBehalfOfUser || this.user.UserGroupId) return;

    const ref = this.dialog.open(UserLogoUploadComponent, {
      data: {
        logo: this.logo
      },
      minHeight: '60rem',
    });
    ref.afterClosed().subscribe(r => {
      if (!!r && r.refresh) {
        this.user.HasUserLogo = r.hasPhoto;
        this.clockTick = Date.now();
        this.logo = this.determineUserLogo();
      }
    });
  }

  checkForRenewals(): void {
    this.enrollmentFormApiService.getSurveyRenewals(null).subscribe(result => {
      this.renewals = result;
      // 1 === 'Open', 4 === 'Ignored'
      if (result.some(r => r.SurveyRenewalStatusId === renewalStatusEnum.Ignored)) this.userHasOpenRenewals = true;
      if (result.some(r => r.SurveyRenewalStatusId === renewalStatusEnum.Open)) this.openEnrollmentModal(result, true);
    });
  }

  openEnrollmentModal(renewals: SurveyRenewalModel[], disableClose: boolean = false): void {
    if (this.renewals)
      this.dialog.open(RenewalFormComponent, {
        width: '72rem',
        disableClose: disableClose,
        data: {
          SurveyRenewals: renewals,
          FilterBySurveyId: null
        }
      }).afterClosed().subscribe(() => {
        this.checkForRenewals();
      });
  }

  openUserSettingsModal(): void {
    this.dialog.open(UserSettingsContainerComponent, {
      height: '74rem',
      width: '90rem',
      data: {
        user: this.user,
        onBehalfOfUser: this.onBehalfOfUser,
        renewals: {
          SurveyRenewals: this.renewals,
          FilterBySurveyId: null
        }
      }
    });
  }

  // the selected date is used to for testing, if omitted it will default to production behavior, otherwise you can set a custom date to test
  openQuinciOptInModal(selectedDate: Date = new Date()): void {
    this.dialog.open<TermsOfUseModalComponent, QuinciOptInDataModel>(TermsOfUseModalComponent, {
      data: {
        isOptedIn: !!this.permissionService.hasOnePermission('ProductRDOptedIn:View'),
        isArcUser: !!this.user.IsArcUser,
        onboardingTermsOfUse: this.termsOfUse,
        agentLicenses: this.user.AgentLicenses,
        onBehalfOfUser: this.onBehalfOfUser,
        isPostQuinciLaunch: DateComparisonHelper.trueIfAfterOrEqualDate(selectedDate, quinciLaunchDateUTC),
        isPostHardDeadline: DateComparisonHelper.trueIfAfterOrEqualDate(selectedDate, quinciHardDeadlineDateUTC),
      },
      disableClose: true,
      height: '72rem'
    });
  }

  needsQuinciOptIn(selectedDate: Date = new Date()): boolean {
    const needsTermsOfUse = (!this.termsOfUse?.HasAgreedToTermsOfUse && (this.permissionService.hasOnePermission('ShowProductRDOptIn:View') || !this.permissionService.hasOnePermission('ProductRDOptedIn:View')));
    const needsOptedInPermission = this.permissionService.hasOnePermission('ShowProductRDOptIn:View') && !this.permissionService.hasOnePermission('ProductRDOptedIn:View');
    const isPastQuinciGoLiveDate = DateComparisonHelper.trueIfAfterOrEqualDate(selectedDate, quinciLaunchDateUTC);

    return isPastQuinciGoLiveDate && (needsTermsOfUse || needsOptedInPermission);
  }

  deactivateOptIn(): void {
    this.userApiService.updateOptOut().subscribe(
      res => {
        this.permissionApiService.getPolicyStatements().subscribe(permissions => {
          this.permissionService.setPermissions(permissions);
          this.optInEventService.broadcastIsOptin(false);
          this.router.navigate(['/Portal/ProductRDPreview']);
          this.optInEventService.isOptIn$.subscribe(res => this.isOptedIntoQuinci = res);
        });
      },
      error => {
        this.isOptedIntoQuinci = true;
      });
  }
}
