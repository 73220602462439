import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AgentLicenses } from 'src/app/shared/models/agent.models';
import { RiaAgent } from 'src/app/shared/models/ria.models';
import { EAppPreferenceEnum, UserSettingsModel } from 'src/app/shared/models/user-settings.model';
import { User } from 'src/app/shared/models/user.models';
import { UserGroup } from 'src/app/shared/models/usergroups.models';
import { PermissionService } from '../auth/permission.service';
import { AgentLicenseApiService } from '../http/agent-license-api.service';
import { RiaApiService } from '../http/ria-api.service';
import { UserApiService } from '../http/user-api.service';
import { UserGroupApiService } from '../http/user-group-api.service';
import { UserSettingsApiService } from '../http/user-settings-api.service';

@Injectable({
  providedIn: 'root'
})
export class SessionStoreService {

  constructor(private userApiService: UserApiService,
    private userGroupApiService: UserGroupApiService,
    private permissionService: PermissionService,
    private riaApiService: RiaApiService,
    private agentLicenseApiService: AgentLicenseApiService,
    private userSettingsApiService:UserSettingsApiService) { }

  private _user!: User;
  private _userGroup: UserGroup | null = null
  private _onBehalfOfUser: User | null = null;
  private _onBehalfOfUserGroup: UserGroup | null = null;
  private _userLogo: string | null = null
  private _userAcmAgent: RiaAgent | null = null;
  private _userSettings: UserSettingsModel | null = null;

  get User(): User{
    return this._user;
  }

  get UserGroup(): UserGroup|null {
    return this._userGroup;
  }

  get OnBehalfOfUser(): User|null {
    return this._onBehalfOfUser;
  }

  get OnBehalfOfUserGroup(): UserGroup|null {
    return this._onBehalfOfUserGroup;
  }

  get UserLogo(): string|null {
    return this._userLogo;
  }

  get UserAcmAgent(): RiaAgent|null {
    return this._userAcmAgent;
  }

  get UserSettings():UserSettingsModel|null {
    return this._userSettings;
  }

  setAgentLicenses(Crd: string, Npn: string): Observable<null> {
    if (!!this._onBehalfOfUser) throw 'Unable to update NPN/CRD on behalf of someone';
    return this.agentLicenseApiService.updateUserAgentLicenses(Crd, Npn)
      .pipe(
        tap(res => {
          this._user.AgentLicenses.Npn = Npn;
          this._user.AgentLicenses.Crd = Crd;
        })
      );
  }

  updateEAppPreference(eAppPreference:EAppPreferenceEnum): Observable<UserSettingsModel> {
    if (!this._userSettings) throw 'User settings do not exist for this user';
    return this.userSettingsApiService.put({...this._userSettings, EAppPreference: eAppPreference})
      .pipe(
        tap((res:UserSettingsModel) => this._userSettings = res)
      );
  }

  setUser(): Promise<void | AgentLicenses | {
    Npn: null;
    Crd: null;
  }> {
    return this.userApiService.getUserInfo().toPromise()
      .then((user) => {
        this._user = user;
        return this.agentLicenseApiService.getLoggedInUserAgentLicenses().toPromise()
          .then(agentLicenses => this._user.AgentLicenses = agentLicenses)
          .catch(()=> this._user.AgentLicenses = { Npn: null, Crd: null })
          .finally(() => {
            if (this._user.UserGroupId) {
              localStorage.setItem('UserGroupId', this._user.UserGroupId);
              return this.userGroupApiService.get(this._user.UserGroupId).toPromise().then(userGroup => this._userGroup = userGroup);
            } else {
              localStorage.removeItem('UserGroupId');
            }
            return;
          });
      })
      .catch(() => {
        throw new Error('Unable to get user');
      });
  }

  async setUserV2(): Promise<User|null> {
    try {
      const user: User = await this.userApiService.getUserInfo().toPromise();
      const isArcUser: boolean = await this.userApiService.getIsArcUser().toPromise().catch(() => user.IsArcUser = false);
      const agentLicenses = await this.agentLicenseApiService.getLoggedInUserAgentLicenses().toPromise().catch(() => user.AgentLicenses = { Npn: null, Crd: null });
      const userSettings = await this.userSettingsApiService.get().toPromise().catch(() => null);
      user.IsArcUser = isArcUser;
      user.AgentLicenses = agentLicenses;
      this._userSettings = userSettings;
      return this._user = user;
    } catch (e) {
      return null;
    }
  }

  async setUserGroup(user: User|null): Promise<UserGroup|null>{
    if (!user?.UserGroupId) {
      localStorage.removeItem('UserGroupId');
      return null;
    } else {
      const userGroup = await this.userGroupApiService.get(user.UserGroupId).toPromise().catch(() => this._userGroup = null);
      localStorage.setItem('UserGroupId', user.UserGroupId);
      this._userGroup = userGroup;
      return userGroup;
    }
  }

  async setUserLogo(): Promise<string|null|undefined> {
    if (!this._user) return;

    if (this._onBehalfOfUser?.UserGroupId) {
      try {
        await this.userGroupApiService.getLogo(this._onBehalfOfUser.UserGroupId).toPromise();
        return this._userLogo = `/api/UserGroups/${this._onBehalfOfUser.UserGroupId}/Logo`;
      } catch (e) {
        return this._userLogo = null;
      }
    } else if (this._user.UserGroupId) {
      try {
        await this.userGroupApiService.getLogo(this._user.UserGroupId).toPromise();
        return this._userLogo = `/api/UserGroups/${this._user.UserGroupId}/Logo`;
      } catch (e) {
        return this._userLogo = null;
      }
    } else if (this._onBehalfOfUser) {
      try {
        await this.userApiService.getUserLogo(this._onBehalfOfUser.Id).toPromise();
        return this._userLogo = `/api/Users/${this._onBehalfOfUser.Id}/Logo`;
      } catch (e) {
        return this._userLogo = null;
      }
    } else {
      try {
        await this.userApiService.getUserLogo(this._user.Id).toPromise();
        return this._userLogo = `/api/Users/${this._user.Id}/Logo`;
      } catch (e) {
        return this._userLogo = null;
      }
    }
  }

  async setOnBehalfOfUserV2(): Promise<User|null> {
    const onBehalfOfId = this.permissionService.getOnBehalfOfUserId();
    if (onBehalfOfId) {
      const user = await this.userApiService.get(onBehalfOfId).toPromise();
      this._onBehalfOfUser = user;

      let agentLicenses = null;
      try {
        agentLicenses = await this.agentLicenseApiService.getUserAgentLicenses().toPromise();
        if (agentLicenses && agentLicenses.Crd && agentLicenses.Npn) this._onBehalfOfUser.AgentLicenses = agentLicenses;
      } catch (e) {
        console.log(e);
        this._onBehalfOfUser.AgentLicenses = {Npn: null, Crd: null};
      }

      if (this._onBehalfOfUser && this._onBehalfOfUser.UserGroupId) {
        const onBehalfOfUserGroup = await this.userGroupApiService.get(this._onBehalfOfUser.UserGroupId).toPromise();
        this._onBehalfOfUserGroup = onBehalfOfUserGroup;
      }
    }

    return this._onBehalfOfUser;
  }

  setOnBehalfOfUser(): Promise<void | AgentLicenses | {
    Npn: null;
    Crd: null;
  }> | undefined {
    const onBehalfOfId = this.permissionService.getOnBehalfOfUserId();
    if (onBehalfOfId) {
      return this.userApiService.get(onBehalfOfId).toPromise().then(user => {
        this._onBehalfOfUser = user;
        return this.agentLicenseApiService.getUserAgentLicenses().toPromise()
          .then(agentLicenses => this.OnBehalfOfUser!.AgentLicenses = agentLicenses)
          .catch(() => this._user.AgentLicenses = { Npn: null, Crd: null })
          .finally(() => {
            if (this._onBehalfOfUser && this._onBehalfOfUser.UserGroupId) {
              return this.userGroupApiService.get(this._onBehalfOfUser.UserGroupId).toPromise().then(userGroup => this._onBehalfOfUserGroup = userGroup);
            }
            return;
          });
      })
        .catch(() => console.log('error getting on behalf of user'));
    }
    return;
  }

  async setUserAcmAgentV2(): Promise<RiaAgent|undefined> {
    let acmAgent;
    try {
      acmAgent = await this.riaApiService.getAcmAgent().toPromise();
      this._userAcmAgent = acmAgent;
    } catch (e) {
      console.log(e);
    }
    return acmAgent;
  }

  setUserAcmAgent(): Promise<void> {
    return this.riaApiService.getAcmAgent().toPromise()
      .then(riaAgent => {
        this._userAcmAgent = riaAgent;
        return;
      })
      .catch(error => {
        console.log(error);
      });
  }
}