import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import { DigitalMarketingAssetsService } from 'src/app/core/services/digital-marketing-assets.service';
import { DmaCategory, Link, SubCategory } from 'src/app/shared/models/digital-marketing-assets.models';
import { fadeIn } from 'src/app/animations';

@Component({
  selector: 'app-digital-marketing-assets',
  templateUrl: './digital-marketing-assets.component.html',
  animations: [fadeIn]
})
export class DigitalMarketingAssetsComponent implements OnInit {
  categories: DmaCategory[] = this.dmaService.getCategories();
  loading: boolean = false;
  searchText: string = '';

  constructor(
    private dmaService: DigitalMarketingAssetsService
  ) { }

  ngOnInit(): void {
    this.categories = _.orderBy(this.categories, 'Order');
  }

  clearSearch() {
    this.searchText = '';
  }

  filterLinks(link: Link, subcat: SubCategory, searchTerm: string) {
    this.searchText = searchTerm.toLowerCase();
    if (subcat.Title && subcat.Title.toLowerCase().indexOf(searchTerm) !== -1) return true;
    if (subcat.Description && subcat.Description.toLowerCase().indexOf(searchTerm) !== -1) return true;
    if (link.Url && link.Url.toLowerCase().indexOf(searchTerm) !== -1) return true;
    if (link.Title && link.Title.toLowerCase().indexOf(searchTerm) !== -1) return true;
    return false;
  }

  filterSubcats(subcat: SubCategory, searchTerm: string) {
    this.searchText = searchTerm.toLowerCase();
    if (subcat.Title && subcat.Title.toLowerCase().indexOf(searchTerm) !== -1) return true;
    if (subcat.Description && subcat.Description.toLowerCase().indexOf(searchTerm) !== -1) return true;
    if (subcat.Links && _.find(subcat.Links, function (row) {
      return row.Url.toLowerCase().indexOf(searchTerm) !== -1 || row.Title.toLowerCase().indexOf(searchTerm) !== -1;
    })) return true;
    return false;
  }

  logAmplitude(logString: string) {
    console.log('amplitude', logString);
  }
  orderBy(array: any[], orderColumn: string[]) {
    return _.orderBy(array, [orderColumn]);
  }
}
