<section *ngIf="!isLoading" [@fadeIn]>
  <div class="flex flex-row" *ngIf="resource.EnrollmentSurveyData">
    <div *ngFor="let survey of submitted; let index = index">
      <ng-container *hideIfOne="'HegnaRHNTierI:Access|HegnaRHNTierII:Access|HegnaRHNTierIII:Access'">
        <button class="button is-primary mt-20 md:mt-30 mr-20"
          (click)="!!survey && !survey.isEnrolled ? openEnrollmentModal(survey.surveyId) : ''"
          [disabled]="survey.isEnrolled">
          <span *ngIf="!!survey && !survey.isEnrolled">{{ !!resource.EnrollmentSurveyData.Labels
            &&
            resource.EnrollmentSurveyData.Labels[index] ?
            resource.EnrollmentSurveyData.Labels[index] : 'Enroll Today'}}</span>
          <span [matTooltip]="!!resource.EnrollmentSurveyData.Labels && resource.EnrollmentSurveyData.Labels[index] ? resource.EnrollmentSurveyData.Labels[index] + ' form has already been submitted' : 'Enrollment form already submitted'" matTooltipPosition="above" 
           *ngIf="!!survey && survey.isEnrolled">Submitted</span>
        </button>
      </ng-container>
      <!-- <button class="button is-primary mt-20 md:mt-30 mr-20" (click)="openPurchaseDialog()"
        *ngIf="!!survey && survey.isEnrolled && !!resource.PurchasePageData"
        [disabled]="!!purchased && purchased.Complete">
        <ng-container *hideIfOne="'HegnaRHNTierII:Access|HegnaRHNTierIII:Access'">
          <span *ngIf="!purchased">
            Purchase{{resource.EnrollmentSurveyData.Labels &&
            resource.EnrollmentSurveyData.Labels[index] ? ' ' +
            resource.EnrollmentSurveyData.Labels[index] : ''}}
          </span>
        </ng-container>
        <span *ngIf="!!purchased && purchased.Complete">
          {{resource.EnrollmentSurveyData.Labels &&
          resource.EnrollmentSurveyData.Labels[index] ? ' ' +
          resource.EnrollmentSurveyData.Labels[index] : ''}} Purchased On {{purchased?.PurchaseDate}}
        </span>
      </button> -->
    </div>
  </div>

  <ng-container *hideIfOne="'HegnaRHNTierII:Access|HegnaRHNTierIII:Access'">
    <button class="button is-primary mt-20 md:mt-30 mr-20" (click)="openDocusignLink()"
      *ngIf="!!purchased && !!purchased.DocusignUrl && !!purchased.MarketingStrategy && (purchased.MarketingStrategy == 'HegnaRHNTierII' || purchased.MarketingStrategy == 'HegnaRHNTierIII')">
      <span>
        Sign Agreement
      </span>
    </button>
  </ng-container>

  <button class="button is-primary mt-20 md:mt-30 mr-20" (click)="openExternalLoginLink()"
    *showIfOne="'HegnaRHNTierII:Access|HegnaRHNTierIII:Access'">
    <span>
      Log Into Tom Hegna on Demand
    </span>
  </button>
</section>
