<section *ngIf="!showFilteredList" [@fadeIn]>
    <section *ngFor="let category of categories" class="mb-30">
        <h3 class="mx-10 text-lg font-bold capitalize">{{category.Name}} ({{tilesByCategory[category.Name].length}})
        </h3>
        <p class="mx-10 mb-10 max-w-960" [innerHtml]="category.Description"></p>
        <ul class="flex flex-wrap">
            <li *ngFor="let tile of orderBy(tilesByCategory[category.Name], ['Order', 'Name'])" class="p-10"
                [class]="tile.Category === calculatorsAndRates ? 'w-1/2 sm:w-1/3 md:w-256' : 'md:w-460 w-full sm:w-1/2'">
                <div class="flex h-full overflow-hidden transition duration-150 cursor-pointer card min-h-192 focus:outline-none hover:shadow-lg"
                    (click)="!!tile.Loading ? '' : tile.Act(); sendToAmplitude(tile.Name);" *ngIf="tile.Category !== calculatorsAndRates">
                    <div class="flex flex-col p-20 pr-0 md:p-30 md:pr-0 w-256">
                        <h3 class="mb-10 font-bold">{{tile.Name}}</h3>
                        <p class="mb-20 text-sm min-h-72" [innerHtml]="tile.ShortDescription"></p>
                        <button mat-button class="button is-primary is-outlined w-128">{{tile.Category ===
                            fullFinancialPlanning ? tile.Id === '3c1ae436-e6de-4c6c-95ee-75bc20ec934c' ? 'Launch' : 'Sign In' : 'Get Started'}}</button>
                    </div>
                    <div class="relative flex flex-grow bg-center bg-no-repeat bg-cover"
                        style="background:url({{tile.ImgUrl}})">
                        <div class="absolute top-0 left-0 w-48 h-full bg-left bg-no-repeat bg-cover"
                            style="background: url('./assets/images/sm-wave-vertical.svg')"></div>
                    </div>
                </div>
                <div class="flex overflow-hidden transition duration-150 cursor-pointer card min-h-256 focus:outline-none hover:shadow-lg"
                    *ngIf="tile.Category === calculatorsAndRates" (click)="!!tile.Loading ? '' : tile.Act(); sendToAmplitude(tile.Name);">
                    <div class="flex flex-col p-20 text-center">
                        <span class="flex mx-auto mb-20 rounded-full w-36 h-36 bg-gray-lightest">
                            <span class="m-auto material-icons" [class]="tile.Class">{{tile.Icon}}</span>
                        </span>
                        <h3 class="mb-10 font-bold">{{tile.Name}}</h3>
                        <p class="mb-20 text-sm min-h-64" [innerHtml]="tile.ShortDescription"></p>
                        <button mat-button class="mx-auto button is-primary is-outlined w-128">Get Started</button>
                    </div>
                </div>
            </li>
        </ul>
    </section>
</section>
<section *ngIf="!!showFilteredList" [@fadeIn]>
    <h3 class="m-10 text-lg font-bold capitalize ">Results ({{filteredList.length}})</h3>
    <ul class="flex flex-wrap">
        <li class="w-1/2 p-10 sm:w-1/3 md:w-256" *ngFor="let tile of filteredList ">
            <div class="flex overflow-hidden card">
                <div class="flex flex-col p-20 text-center">
                    <h3 class="mb-10 font-bold">{{tile.Name}}</h3>
                    <p class="mb-20 min-h-64" [innerHtml]="tile.ShortDescription"></p>
                    <button mat-button class="mx-auto button is-primary is-outlined w-128" (click)="tile.Act(); sendToAmplitude(tile.Name);">Get
                        Started</button>
                </div>
            </div>
        </li>
        <li class="m-10">No results.</li>
    </ul>
</section>