import { StateBasicModel } from '../models/generic.models';

export const states:StateBasicModel[] = [
  { ShortName: 'AL', LongName: 'Alabama' },
  { ShortName: 'AK', LongName: 'Alaska' },
  { ShortName: 'AZ', LongName: 'Arizona' },
  { ShortName: 'AR', LongName: 'Arkansas' },
  { ShortName: 'CA', LongName: 'California' },
  { ShortName: 'CO', LongName: 'Colorado' },
  { ShortName: 'CT', LongName: 'Connecticut' },
  { ShortName: 'DE', LongName: 'Delaware' },
  { ShortName: 'FL', LongName: 'Florida' },
  { ShortName: 'GA', LongName: 'Georgia' },
  { ShortName: 'HI', LongName: 'Hawaii' },
  { ShortName: 'ID', LongName: 'Idaho' },
  { ShortName: 'IL', LongName: 'Illinois' },
  { ShortName: 'IN', LongName: 'Indiana' },
  { ShortName: 'IA', LongName: 'Iowa' },
  { ShortName: 'KS', LongName: 'Kansas' },
  { ShortName: 'KY', LongName: 'Kentucky' },
  { ShortName: 'LA', LongName: 'Louisiana' },
  { ShortName: 'ME', LongName: 'Maine' },
  { ShortName: 'MD', LongName: 'Maryland' },
  { ShortName: 'MA', LongName: 'Massachusetts' },
  { ShortName: 'MI', LongName: 'Michigan' },
  { ShortName: 'MN', LongName: 'Minnesota' },
  { ShortName: 'MS', LongName: 'Mississippi' },
  { ShortName: 'MO', LongName: 'Missouri' },
  { ShortName: 'MT', LongName: 'Montana' },
  { ShortName: 'NE', LongName: 'Nebraska' },
  { ShortName: 'NV', LongName: 'Nevada' },
  { ShortName: 'NH', LongName: 'New Hampshire' },
  { ShortName: 'NJ', LongName: 'New Jersey' },
  { ShortName: 'NM', LongName: 'New Mexico' },
  { ShortName: 'NY', LongName: 'New York' },
  { ShortName: 'NC', LongName: 'North Carolina' },
  { ShortName: 'ND', LongName: 'North Dakota' },
  { ShortName: 'OH', LongName: 'Ohio' },
  { ShortName: 'OK', LongName: 'Oklahoma' },
  { ShortName: 'OR', LongName: 'Oregon' },
  { ShortName: 'PA', LongName: 'Pennsylvania' },
  { ShortName: 'RI', LongName: 'Rhode Island' },
  { ShortName: 'SC', LongName: 'South Carolina' },
  { ShortName: 'SD', LongName: 'South Dakota' },
  { ShortName: 'TN', LongName: 'Tennessee' },
  { ShortName: 'TX', LongName: 'Texas' },
  { ShortName: 'UT', LongName: 'Utah' },
  { ShortName: 'VT', LongName: 'Vermont' },
  { ShortName: 'VA', LongName: 'Virginia' },
  { ShortName: 'WA', LongName: 'Washington' },
  { ShortName: 'WV', LongName: 'West Virginia' },
  { ShortName: 'WI', LongName: 'Wisconsin' },
  { ShortName: 'WY', LongName: 'Wyoming' }
];

export const statesPlusDCAndVI:StateBasicModel[] = states.concat([
  {ShortName: 'DC', LongName: 'District of Columbia'},
  {ShortName: 'VI', LongName: 'Virgin Islands'}]);


export const stateLongNameByShortName:Record<string,string> = {
  'AK': 'Alaska',
  'AL': 'Alabama',
  'AR': 'Arkansas',
  'AZ': 'Arizona',
  'CA': 'California',
  'CO': 'Colorado',
  'CT': 'Connecticut',
  'DC': 'District of Columbia',
  'DE': 'Delaware',
  'FL': 'Florida',
  'GA': 'Georgia',
  'HI': 'Hawaii',
  'IA': 'Iowa',
  'ID': 'Idaho',
  'IL': 'Illinois',
  'IN': 'Indiana',
  'KS': 'Kansas',
  'KY': 'Kentucky',
  'LA': 'Louisiana',
  'MA': 'Massachusetts',
  'MD': 'Maryland',
  'ME': 'Maine',
  'MI': 'Michigan',
  'MN': 'Minnesota',
  'MO': 'Missouri',
  'MS': 'Mississippi',
  'MT': 'Montana',
  'NC': 'North Carolina',
  'ND': 'North Dakota',
  'NE': 'Nebraska',
  'NH': 'New Hampshire',
  'NJ': 'New Jersey',
  'NM': 'New Mexico',
  'NV': 'Nevada',
  'NY': 'New York',
  'OH': 'Ohio',
  'OK': 'Oklahoma',
  'OR': 'Oregon',
  'PA': 'Pennsylvania',
  'RI': 'Rhode Island',
  'SC': 'South Carolina',
  'SD': 'South Dakota',
  'TN': 'Tennessee',
  'TX': 'Texas',
  'UT': 'Utah',
  'VA': 'Virginia',
  'VI': 'Virgin Islands',
  'VT': 'Vermont',
  'WA': 'Washington',
  'WI': 'Wisconsin',
  'WV': 'West Virginia',
  'WY': 'Wyoming',
};