import { Directive, ElementRef, Input, HostListener } from '@angular/core';
import { FeatureToggleService } from 'src/app/core/services/featuretoggle.service';
import { EnvironmentEnum } from '../models/generic.models';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FirelightApiService } from 'src/app/core/services/http/firelight-api.service';

@Directive({
  selector: '[appIframeData]'
})
export class IframeDataDirective {
  environment = this.featureToggleService.getEnvironment();
  environmentEnum = EnvironmentEnum;
  @Input() appIframeData?: any;

  constructor(private el: ElementRef,
    private featureToggleService: FeatureToggleService,
    private router: Router,
    private firelightApiService: FirelightApiService,
    private activatedRoute: ActivatedRoute) {
  }

  @HostListener('window:message', ['$event']) onPostMessage(event: any) {
    this.logEventIfNotProd(event);
    if (!this.isValidEvent(event))
      return;
    switch (event.data.command) {
      case 'resize': this.resize(event);
        break;
      case 'fireLightSign': this.redirectToEApplicationSubmittedPage();
        break;
      case 'newApplication': this.consumeFirelightApplicationFromSimon(event);
        break;
      case 'lifeCycleView': this.goBack();
        break;
      default:
        break;
    }
  }

  goBack(): void {
    if (!this.appIframeData) window.history.back();
    else {
      let simonUrl: string = '';
      this.activatedRoute.queryParamMap.subscribe(params => simonUrl = params.get('simonUrl') as string);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['Portal/Business/' + this.appIframeData], { queryParams: { simonUrl: simonUrl } }));
    }
  }

  isValidEvent(event: any) {
    if ((event.origin !== environment.simonIframeLocalOrigin && event.origin !== environment.simonIframeOrigin) || !event.data || event.source !== this.el.nativeElement.contentWindow)
      return false;
    else
      return true;
  }

  logEventIfNotProd(event: any) {
    if (this.environment !== this.environmentEnum.prod && this.isValidEvent(event)) {
      console.log(`Iframe event from: ${event.origin}`);
      console.log(event);
    }
  }

  resize(event: any) {
    if (!event.data.height) return;

    this.el.nativeElement.style.height = event.data.height + 'px';
  }

  consumeFirelightApplicationFromSimon(event: any) {
    if (!event.data.activityId) return;

    this.firelightApiService.postFirelightActivityProvisionData(event.data.activityId).subscribe();
    this.redirectToCanSellWorkflow(event);
  }

  redirectToEApplicationSubmittedPage() {
    this.router.navigate(['Portal/ProductRD/EApplication/Workflow/Submitted']);
  }

  redirectToCanSellWorkflow(event: any) {
    this.router.navigate(['Portal/ProductRD/EApplication/Workflow/PrepareEApplication'],
      { queryParams: { activityId: event.data.activityId, state: event.data.state ?? null, cusip: event.data.cusip ?? null, isNew: false } });
  }
}
