import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { values } from 'lodash';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
})
export class FormComponent implements OnInit {

  constructor(
    private _snackbar: MatSnackBar,
    private _fb: FormBuilder,
  ) { }

  myForm: any;
  hasValidations: boolean = true;
  get form() {
    return this.myForm.controls;
  }

  generateForm(): void {
    this.myForm = this._fb.group({
      personal: this._fb.group({
        firstName: ['Jonny', [Validators.required, Validators.minLength(3)]],
        lastName: ['', [Validators.required, Validators.minLength(3)]],
        dob: ['', [Validators.required]],
      }),
      contact: this._fb.group({
        phone: ['', [Validators.required, Validators.pattern('^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$')]],
        email: ['', [Validators.required, Validators.email]]
      }),
      message: ['']
    });
  }

  //loop thru a form group and remove required fields
  private removeValidator(form: any) {
    for (const field in form.controls) { // 'field' is a string
      const con = form.get(field); // 'control' is a FormControl
      con.clearValidators();
      con.updateValueAndValidity();
    }
  }

  toggleValidations(checked: boolean) {
    const personal = this.myForm.get('personal');
    const contact = this.myForm.get('contact');
    if (checked === false) {
      //clear all validators from each group
      this.removeValidator(personal);
      this.removeValidator(contact);
    } else this.generateForm();

    //a soft reset of sort
    this.myForm.updateValueAndValidity();
    this.hasValidations = checked;
  }

  onSubmit(): void {
    if (this.myForm?.valid) {
      alert('Form submitted! See console for details');
      console.log(this.myForm.value);
    } else {
      //this touches all fields on form to trigger validation messages
      this.myForm.markAllAsTouched();
    }
  }

  ngOnInit(): void {
    this.generateForm();
  }

}
