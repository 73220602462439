<mat-sidenav-container class="relative flex flex-grow h-full overflow-hidden bg-white">
  <mat-sidenav #businessSidenav class="h-full bg-white" [mode]="sidenavConfig.Mode" [opened]="sidenavConfig.IsOpened" position="end">
    <div class="p-20 border-b border-solid border-gray-lighter" *ngIf="currentFilters && filteredPolicies.length > 0">
      <div class="flex flex-row items-center justify-start w-full mb-10 text-sm">
        <span class="mr-5">Policies:</span>
        <span class="font-semibold">{{policyCount}}</span>
      </div>
      <div class="flex flex-col" *ngIf="currentFilters.SelectedLobIds.includes(1)">
        <div class="flex flex-row items-center justify-start w-full mb-10 text-sm">
          <span class="mr-5">Avg. Premium:</span>
          <span class="font-semibold">{{averagePremium | currency}}</span>
        </div>
        <div class="flex flex-row items-center justify-start w-full mb-10 text-sm">
          <span class="mr-5">Total Premium:</span>
          <span class="font-semibold">{{totalPremium | currency}}</span>
        </div>
      </div>
      <div *ngIf="reportType === 'Fixed'" class="flex flex-row items-center justify-start w-full mb-10 text-sm">
        <span class="mr-5">Total CPCs:</span>
        <span class="font-semibold">{{cpcTotal}}</span>
      </div>
    </div>
    <app-policy-list-sidebar [reportType]="reportType" (filterChangeEvent)="onFilterChange($event)">
    </app-policy-list-sidebar>

    <div class="flex m-10 mt-20 md:hidden">
      <button mat-button class="flex-grow mx-auto button is-primary is-outlined md:hidden" (click)="toggleNav(businessSidenav)">
        Done
      </button>
    </div>
  </mat-sidenav>
  <mat-sidenav-content class="flex flex-col flex-grow">
    <section class="flex flex-col flex-grow m-30">
      <app-pending-policy-table [pendingPolicies]="pendingPolicies" [selectedAgentIds]="this.currentFilters.SelectedAgentIds" class="flex flex-col flex-grow"></app-pending-policy-table>
      <div class="flex-grow w-full loader-wrap" *ngIf="isLoading" @fadeIn>
        <div class="loader-sm is-blue"></div>
      </div>
    </section>
  </mat-sidenav-content>
</mat-sidenav-container>