<section class="flex flex-col h-full overflow-hidden">
    <section class="flex items-center p-20 border-b border-solid border-gray-lighter">
        <h2 class="m-10 mr-auto text-lg font-bold">Policy Note History</h2>
        <button mat-button class="icon-button is-primary is-inverted" mat-dialog-close type="button">
            <span class="material-icons">close</span>
        </button>
    </section>
    <section class="flex flex-auto overflow-auto">
        <ul class="flex flex-col w-full p-20">
            <li *ngFor="let item of orderBy(caseStatusNotes, ['CreatedOn'], ['desc']) | callback: filterExternalUsers; last as last"
                class="flex items-center p-10" [ngClass]="{'border-b border-solid border-gray-lighter': !last}">
                <span class="flex-none m-10 material-icons text-gray">message</span>
                <p class="flex-grow m-10">{{item.Note}}</p>
                <span class="flex flex-col flex-none m-10 text-sm text-right">
                    <span class="font-bold">{{item.CreatedBy}}</span>
                    <span class="text-gray">{{item.CreatedOn | date}}</span>
                </span>
            </li>
        </ul>
    </section>
</section>