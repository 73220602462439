import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  templateUrl: './page-not-found.component.html',
  selector: 'app-page-not-found',
})

export class PageNotFoundComponent {

  constructor(private titleService: Title) {
    this.titleService.setTitle('Page Not Found' + ' | Agent Portal');
  }

}
