export const antiPurgeCss:string[] = [
  'col-start-3', 'col-start-4', 'col-start-5', 'col-start-6',
  'md:col-start-3', 'md:col-start-4', 'md:col-start-5', 'md:col-start-6',
  'lg:col-start-3', 'lg:col-start-4', 'lg:col-start-5', 'lg:col-start-6',
  'col-span-2 md:col-span-4 lg:col-start-4 lg:col-span-2',
  'md:col-span-2 lg:col-start-4 lg:col-span-1',
  'md:col-span-2 lg:col-span-1',
  'md:col-span-2 lg:col-start-3 lg:col-span-1',
  'md:col-span-2 lg:col-span-1',
  'md:col-span-2 lg:col-span-1',
  'bg-gradient-to-b', 'bg-gradient-to-r', 'bg-gradient-to-br', 'from-blue-darker', 'to-gray-dark', 'to-gray-darker', 'to-purple-darker',
  'text-blue-dark text-red text-purple text-green text-pink text-orange-dark',
  'bg-blue-lightest bg-red-lightest bg-purple-lightest bg-green-lightest bg-pink-lightest bg-orange-lightest',
  'bg-blue-lighter bg-yellow-light bg-pink-lighter bg-teal-lighter',
  'bg-pink-light from-pink-light bg-purple-light to-purple-light',
  'bg-purple-dark',
];