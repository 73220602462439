<div class="flex flex-row" *ngFor="let survey of submitted; let index = index">
  <button class="button is-primary mt-20 md:mt-30 mr-20"
    (click)="!!survey && !survey.isEnrolled ? openEnrollmentModal(survey.surveyId) : ''" [disabled]="survey.isEnrolled">
    <span *ngIf="!!survey && !survey.isEnrolled">
      Enroll Today
    </span>
    <span matTooltip="Surge enrollment form already submitted" matTooltipPosition="above" 
    *ngIf="!!survey && survey.isEnrolled">Submitted</span>
  </button>
  <button class="button is-primary mt-20 md:mt-30 mr-20" (click)="openPurchaseDialog()"
    *ngIf="!!survey && survey.isEnrolled && !!resource.PurchasePageData" [disabled]="!!purchased && purchased.Complete">
    <span *ngIf="!purchased">
      Purchase
    </span>
    <span *ngIf="!!purchased && purchased.Complete">
      Purchased On {{purchased?.PurchaseDate}}
    </span>
  </button>

  <button class="button is-primary mt-20 md:mt-30 mr-20" (click)="openDocusignLink(purchased.DocusignUrl)"
    *ngIf="!!purchased && purchased.Complete && purchased.DocusignUrl">
    <span>
      Sign Surge Agreement
    </span>
  </button>

  <ng-container *ngIf="capstone.DocusignUrl">
    <button class="button is-primary mt-20 md:mt-30 mr-20" (click)="openDocusignLink(capstone.DocusignUrl)"
      *showIfOne="'Capstone:access'"> 
      <span>
        Sign Capstone Agreement
      </span>
    </button>
  </ng-container>
</div>