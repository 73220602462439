<section class="flex flex-col flex-grow p-20 overflow-auto" @fadeIn>
    <section class="flex flex-col flex-none">
        <p class="m-10 leading-loose">
            Get a head start on your carrier training by accessing the training guides below. You can come back to this
            any time once you're all&nbsp;setup.
        </p>
        <!-- <input class="flex flex-grow-0 mx-10 input md:m-10" type="text" placeholder="Find a carrier" [(ngModel)]="searchText" /> -->
        <ul class="grid flex-grow-0 grid-cols-1 m-10 gap-x-10 gap-y-10 xs:grid-cols-2 lg:gap-x-20 lg:gap-y-20" *ngIf="!loading">
            <li class="flex flex-row trining-item" *ngFor="let training of carrierTrainings">
                <!-- | filter: $ctrl.searchText | orderBy: 'Carrier'-->
                <a class="flex flex-row items-center justify-center w-full p-10 transition duration-150 border border-solid rounded cursor-pointer md:px-20 hover:no-underline focus:no-underline focus:outline-none focus:shadow-outline hover:bg-blue-lighter hover:bg-opacity-25 border-gray-lighter"
                    (click)="goToCarrierTraining(training)">
                    <span class="mr-20">
                        <img src="{{training.LogoUrl}}" class="object-contain w-48 h-48 overflow-hidden bg-white rounded-full" *ngIf="training.LogoUrl" />
                        <span class="flex flex-row items-center justify-center w-48 h-48 rounded-full bg-gray-lighter"
                            *ngIf="!training.LogoUrl">
                            <!-- <i class="fig-24px fig-truck text-gray"></i> -->
                            <span class="material-icons mi-24 text-gray">
                                local_shipping
                            </span>
                        </span>
                    </span>
                    <h4 class="mr-auto font-bold">{{training.Carrier}}</h4>
                    <span class="flex flex-row items-center justify-center w-24 h-24 ml-10 rounded-full text-gray"
                        attr.aria-label="Training Guide">
                        <!-- <i class="font-bold fig-18px fig-arrow-right"></i> -->
                        <span class="material-icons mi-18">
                            east
                        </span>
                    </span>
                </a>
            </li>
        </ul>
        <div *ngIf="loading" class="relative p-20 loader-wrap">
            <div class="loader-sm is-blue"></div>
        </div>
    </section>
    <!--<section layout="column" flex="none" class="mb-10 lg:mb-20">
        <h3 class="m-10 text-lg heading">General Training</h3>
        <ul class="grid grid-cols-2 m-10 gap-x-20 gap-y-20">
            <li layout ng-repeat="training in $ctrl.generalTraining">
                <a class="w-full p-10 text-center transition duration-150 rounded card hover:no-underline focus:no-underline focus:outline-none focus:shadow-outline hover:bg-blue-lighter hover:bg-opacity-25"
                   target="_blank"
                   href="{{training.Link}}">
                    <h4 class="m-10 text-base heading text-blue-dark">{{training.Title}}</h4>
                    <p class="m-10 text-sm text-gray-dark">{{training.Description}}</p>
                </a>
            </li>
        </ul>
    </section>-->
</section>
<section
    class="flex items-center justify-start flex-none p-10 mt-auto border-t border-solid lg:p-20 border-gray-lighter">
    <button mat-button class="m-10 mr-auto button is-lg is-primary is-outlined" *ngIf="!isFirstStep" (click)='goToPrevious()'>
        <span class="flex flex-row items-center justify-center">
            <span class="material-icons mi-18">
                chevron_left
            </span>
            <span class="mr-10 leading-none">Previous</span>
        </span>
    </button>
    <button *ngIf="!isLastStep" mat-button class="m-10 ml-auto button is-lg is-primary" (click)="goToNext()">
        <span class="flex flex-row items-center justify-center">
            <span class="ml-10 leading-none">{{isLastStep ? 'Return To Portal' : 'Next'</span>
            <span class="material-icons mi-18">
                chevron_right
            </span>
        </span>
    </button>
</section>