<section class="flex flex-col sm:min-h-720">
    <section class="flex flex-grow" *ngIf="!loadingCategories" [@fadeIn]>
        <div class="grid flex-grow-0 grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-y-20 gap-x-20">
            <div #categoryCard1 id="categoryCard1"
                class="flex flex-col items-center justify-start col-span-2 lg:col-span-3 md:row-span-2">
                <div class="max-w-full mx-auto my-20 md:max-w-640 md:mt-48 md:p-40">
                    <h1 class="mb-10 mr-auto text-xl font-bold leading-none md:text-2xl">Explore</h1>
                    <p class="my-20 text-lg">
                        Our verticals are designed to grow your business by targeting every facet of your
                        practice.<br /><br />
                        Access platforms, programs, and tools laser-focused on the building blocks that make up your
                        business.
                    </p>
                    <button mat-button class="mt-20 button is-primary is-outlined is-lg"
                        amplitudeEvent="Platforms Verticals - View More" (click)="scrollTo('browseSection')">
                        <span class="flex items-center justify-center">
                            <span>View More</span>
                            <i class="ml-5 material-icons mi-18">arrow_downward</i>
                        </span>
                    </button>
                </div>
            </div>
            <div #categoryCard2 id="categoryCard2"
                class="flex flex-row overflow-hidden transition duration-150 bg-center bg-no-repeat bg-cover card hover:shadow-lg"
                [class]="block.Fields.Class" *ngFor="let block of categories">
                <!-- <a attr.aria-label="Explore the Platform" [amplitudeEvent]="'Dashboard Verticals: ' + block.Fields.Name"
                    routerLink="/Portal/FigPlatform" [fragment]="block.Id"
                    class="flex flex-row flex-grow hover:no-underline hover:text-gray-dark focus:no-underline focus:text-gray-dark"
                    [class]="filterTilesByCategory(block.Fields.Name).length ? 'cursor-pointer' : 'cursor-default'"
                    *ngIf="block.Id === 'recPBIrLIAEGOfWgu'">
                    <div class="flex-col flex-grow p-10 bg-white lg:p-20"
                        *ngIf="!!filterTilesByCategory(block.Fields.Name).length">
                        <div class="flex flex-row mb-10 md:mb-20">
                            <span class="z-10 flex flex-row items-center justify-center w-48 h-48 rounded-full"
                                [class]="block.Fields.BackgroundColor + ' ' + block.Fields.ForegroundColor">
                                <i class="material-icons">{{block.Fields.Icon}}</i>
                            </span>
                            <div class="flex flex-col justify-center">
                                <label class="mb-5 ml-10 text-xs uppercase text-gray" *ngIf="!!block.Fields.Tags.length && environment !== environmentEnum.prod">Introducing</label>
                                <h2 class="ml-10 font-bold text-left" [innerHtml]="block.Fields.Name"></h2>
                            </div>
                            <app-simon-powered class="inline-block m-10 ml-auto"
                            *ngIf="environment !== environmentEnum.prod"></app-simon-powered>
                        </div>
                        <p class="m-10 mb-10 text-left md:mb-20 text-gray-dark"
                            [textContent]="block.Fields.Description"></p>
                        <div class="flex flex-row items-center mx-10"
                            *ngIf="!!tempTags.length && environment !== environmentEnum.prod">
                            <span
                                class="flex items-center justify-center py-5 text-xs font-bold text-center uppercase rounded-full italics text-gray"
                                *ngFor="let tag of tempTags; let last = last">{{tag}}<span *ngIf="!last"
                                    class="mx-10">|</span>
                            </span>
                        </div>
                        <div class="flex flex-row items-center mx-10"
                            *ngIf="!!block.Fields.Tags.length && environment === environmentEnum.prod">
                            <span
                                class="flex items-center justify-center py-5 text-xs font-bold uppercase rounded-full italics text-gray"
                                *ngFor="let tag of block.Fields.Tags; let last = last">{{tag}}<span *ngIf="!last"
                                    class="mx-10">|</span>
                            </span>
                        </div>
                    </div>
                    <div class="flex flex-row w-1/5 bg-center bg-no-repeat bg-cover"
                        style="background: url({{block.Fields.BackgroundUrl}})">
                        <div class="flex-grow-0 w-48 h-full mr-auto bg-left bg-no-repeat bg-cover"
                            style="background: url('./assets/images/sm-wave-vertical.svg')"></div>
                    </div>
                </a> -->
                <a attr.aria-label="Explore the Platform" (click)="scrollTo(block.Id)"
                    [amplitudeEvent]="'Platforms Verticals - ' + block.Fields.Name"
                    class="flex flex-col flex-grow hover:no-underline hover:text-gray-dark focus:no-underline focus:text-gray-dark"
                    [class]="filterTilesByCategory(block.Fields.Name).length > 0 ? 'cursor-pointer' : 'cursor-default'">
                    <div class="flex flex-col bg-center bg-no-repeat bg-cover"
                        [class]="filterTilesByCategory(block.Fields.Name).length > 0 ? 'h-96' : 'flex-grow'"
                        style="background: url({{block.Fields.BackgroundUrl}})">
                        <div class="flex-grow-0 w-full h-48 mt-auto bg-bottom bg-no-repeat bg-cover"
                            style="background: url('./assets/images/wave-sm.svg')"
                            *ngIf="filterTilesByCategory(block.Fields.Name).length"></div>
                    </div>
                    <div class="flex-grow p-10 bg-white lg:p-20"
                        *ngIf="filterTilesByCategory(block.Fields.Name).length">
                        <span
                            class="flex flex-row items-center justify-center w-48 h-48 mx-auto mb-20 -mt-64 rounded-full"
                            [class]="block.Fields.BackgroundColor + ' ' + block.Fields.ForegroundColor">
                            <i class="material-icons"
                                *ngIf="block.Fields.Name !== 'Product R&D'">{{block.Fields.Icon}}</i>
                            <i class="fig-24px fig-box-open" *ngIf="block.Fields.Name === 'Product R&D'"></i>
                        </span>
                        <h2 class="m-10 text-lg font-semibold text-center" [innerHtml]="block.Fields.Name"></h2>
                        <p class="m-10 text-center" [textContent]="block.Fields.Description"></p>
                    </div>
                </a>
            </div>
        </div>
    </section>
    <div class="relative flex-grow p-20 loader-wrap" *ngIf="loadingCategories" [@fadeIn]>
        <div class="loader-sm is-blue"></div>
    </div>
</section>

<section class="h-screen px-20 -mx-20 bg-top bg-no-repeat bg-cover pt-96 md:-mx-30 md:px-30" [attr.id]="'browseSection'"
    style="background-image:url('.assets/images/wave-lg.svg')" @fadeIn>
    <section class="flex items-center justify-start flex-layout">
        <!-- <div
            class="relative flex flex-row items-center justify-start my-10 ml-10 mr-10 sm:my-20 sm:flex-grow-0 sm:w-384">
            <i class="absolute transform -translate-y-1/2 fig-24px fig-search top-1/2 left-5 text-gray"></i>
            <input type="text" class="w-full input is-lg pl-36" placeholder="Search..." (keyup)="applyFilters()"
                [(ngModel)]="searchText" />
        </div> -->
        <div class="relative flex m-10 border border-solid rounded sm:my-20 sm:flex-grow-0 sm:w-384 border-gray-lighter">
            <input type="text" class="w-full rounded-r-none input is-lg is-inverted" placeholder="Search..."
                (keyup)="applyFilters()" [(ngModel)]="searchText" />
            <button class="rounded-l-none icon-button is-primary is-lg">
                <i class="material-icons">search</i>
            </button>
        </div>
        <button class="mr-auto icon-button is-primary is-inverted" attr.aria-label="Clear search box"
            *ngIf="!!searchText" (click)="clearSearch()"><i class="material-icons">close</i></button>
    </section>
    <section class="mb-20" *ngIf="!showFilteredList && !loadingTile" [@fadeIn]>
        <ng-container *ngFor="let catGroup of orderBy(categories, ['CategoryOrder']); let last = last">
            <div class="mb-30 {{last ? 'md:pb-20' : ''}}" *ngIf="filterTilesByCategory(catGroup.Fields.Name).length">
                <div id="{{catGroup.Id}}" class="flex flex-row items-center justify-start m-10">
                    <i class="mr-10 material-icons" [class]="catGroup.Fields.ForegroundColor"
                        *ngIf="catGroup.Fields.Name !== 'Product R&D'">{{catGroup.Fields.Icon}}</i>
                    <i class="mr-10 fig-24px fig-box-open" [class]="catGroup.Fields.ForegroundColor"
                        *ngIf="catGroup.Fields.Name === 'Product R&D'"></i>
                    <div>
                        <h3 class="mb-5 text-lg font-bold capitalize heading">
                            {{catGroup.Fields.Name + ' (' + getTileCountByCategory(catGroup.Fields.Name) + ')'}}</h3>
                        <p class="text-base">{{catGroup.Fields.Description}}</p>
                    </div>
                </div>
                <div class="flex flex-wrap items-start flex-layout justify-self-stretch">
                    <div class="p-10 h-256" [id]="'tileCard' + tile.Fields.TileOrder"
                        [ngClass]="tile.Fields.Size === 'Large' ? 'sm:w-460 w-full' : tile.Fields.Size === 'Medium' ? 'sm:w-320 w-1/2' : tile.Fields.Size === 'Small' ? 'sm:w-220 w-1/2' : ''"
                        *ngFor="let tile of orderBy(filterTiles(catGroup.Fields.Name, searchText), ['Fields.Size', 'Fields.TileOrder', 'Fields.Title'])">

                        <div
                            class="relative h-full overflow-hidden transition duration-150 cursor-pointer card hover:shadow-lg">
                            <!--workaround for tiles having ext url or state url-->
                            <a class="absolute top-0 left-0 w-full h-full opacity-0" [href]="tile.Fields.ExternalUrl"
                                target="_blank" [amplitudeEvent]="'Platform Tiles - ' + tile.Fields.Title"
                                *ngIf="tile.Fields.ExternalUrl"></a>
                            <a class="absolute top-0 left-0 w-full h-full opacity-0"
                                [routerLink]="tile.Fields.AngularRoute" [fragment]="catGroup.Id"
                                [amplitudeEvent]="'Platform Tiles - ' + tile.Fields.Title"
                                *ngIf="tile.Fields.AngularRoute"></a>
                            <!-- [queryParams]="{tileId: tile.Id}"-->
                            <div class="flex flex-row h-full text-center" *ngIf="tile.Fields.Size === 'Large'">
                                <div class="flex flex-col items-center justify-center w-3/5 p-20 pr-10">
                                    <img class="w-auto h-64 m-10" src="./assets/images/icons/{{tile.Fields.Logo}}"
                                        [alt]="tile.Fields.Title" *ngIf="!!tile.Fields.Logo" />
                                    <span
                                        class="z-10 flex items-center justify-center w-auto m-20 font-bold leading-tight uppercase max-h-64 border-gray-dark text-gray-dark border-3"
                                        [class]="tile.Fields.Subtitle.length > 10 ? 'p-5 text-lg max-w-150' : tile.Fields.Subtitle.length > 4 ? 'p-10 text-xl' : 'p-15 text-3xl'"
                                        [textContent]="tile.Fields.Subtitle" *ngIf="!tile.Fields.Logo"></span>
                                    <h4 class="m-10 mb-0 text-base font-semibold">{{tile.Fields.Title}}</h4>
                                    <p class="m-10 text-sm">{{tile.Fields.Description}}</p>
                                </div>
                                <div class="flex flex-row w-2/5 bg-center bg-no-repeat bg-cover"
                                    style="background:url({{tile.Fields.CoverImgUrl}})">
                                    <div class="flex-grow-0 w-48 h-full mr-auto bg-left bg-no-repeat bg-cover"
                                        style="background: url('./assets/images/sm-wave-vertical.svg')"></div>
                                </div>
                            </div>

                            <div class="flex flex-col h-full text-center" *ngIf="tile.Fields.Size === 'Medium'">
                                <div
                                    class="relative flex flex-col items-center justify-center flex-grow-0 bg-opacity-50 bg-gray-lightest">
                                    <img class="z-10 m-20 mb-0 max-h-64" *ngIf="!!tile.Fields.Logo"
                                        src="./assets/images/icons/{{tile.Fields.Logo}}" [alt]="tile.Fields.Title" />
                                    <span
                                        class="z-10 flex items-center justify-center w-auto m-20 font-bold leading-tight uppercase max-h-64 border-gray-dark text-gray-dark border-3"
                                        [class]="tile.Fields.Subtitle.length > 10 ? 'p-5 text-lg max-w-150' : tile.Fields.Subtitle.length > 4 ? 'p-10 text-xl' : 'p-15 text-3xl'"
                                        [textContent]="tile.Fields.Subtitle" *ngIf="!tile.Fields.Logo"></span>
                                    <div class="absolute bottom-0 left-0 z-0 flex-grow-0 w-full h-48 mt-auto bg-bottom bg-no-repeat bg-cover"
                                        style="background: url('../../../Images/wave-sm.svg')"></div>
                                </div>
                                <div class="flex flex-col flex-grow p-20">
                                    <h4 class="m-10 mb-0 text-base font-semibold">{{tile.Fields.Title}}</h4>
                                    <p class="m-10 text-sm">{{tile.Fields.Description}}</p>
                                </div>
                            </div>

                            <div class="flex flex-col h-full text-center" *ngIf="tile.Fields.Size === 'Small'">
                                <div
                                    class="relative flex flex-col items-center justify-center flex-grow-0 bg-opacity-50 bg-gray-lightest">
                                    <span
                                        class="z-10 flex flex-row items-center justify-center mb-0 rounded-full w-36 h-36 bg-gray-lightest m-30">
                                        <i
                                            class="material-icons {{tile.Fields.ForegroundColor}}">{{tile.Fields.Icon}}</i>
                                    </span>
                                    <div flex="none"
                                        class="absolute bottom-0 left-0 z-0 w-full h-48 mt-auto bg-bottom bg-no-repeat"
                                        style="background: url('../../../Images/wave-sm.svg')"></div>
                                </div>
                                <div class="flex flex-col flex-grow-0 p-10 pt-20">
                                    <h4 class="m-10 mb-0 text-base font-semibold">{{tile.Fields.Title}}</h4>
                                    <p class="m-10 text-sm">{{tile.Fields.Description}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </section>
    <section class="mb-20" *ngIf="!!showFilteredList && !loadingTile" [@fadeIn]>
        <h3 class="m-10 text-lg font-bold capitalize heading">Results ({{filteredList.length}})</h3>
        <div class="flex flex-row flex-wrap items-stretch justify-self-stretch">
            <div class="p-10 pb-30 h-256" *ngFor="let filteredTile of filteredList"
                [ngClass]="filteredTile.Fields.Size === 'Large' ? 'sm:w-460' : filteredTile.Fields.Size === 'Medium' ? 'sm:w-320' : filteredTile.Fields.Size === 'Small' ? 'sm:w-220' : ''">
                <!-- | orderBy: ['Title']-->
                <div
                    class="relative h-full overflow-hidden transition duration-150 cursor-pointer card hover:shadow-lg">
                    <!--workaround for tiles having ext url or state url-->
                    <!-- <a [routerLink]="filteredTile.Fields.ExternalUrl" target="_blank" *ngIf="!!filteredTile.Fields.ExternalUrl" class="absolute top-0 left-0 w-full h-full opacity-0"></a>
                    <a [routerLink]="filteredTile.Fields.Url" *ngIf="!!filteredTile.Fields.Url" class="absolute top-0 left-0 w-full h-full opacity-0"></a> -->
                    <a class="absolute top-0 left-0 w-full h-full opacity-0" [href]="filteredTile.Fields.ExternalUrl"
                        target="_blank"
                        [amplitudeEvent]="'Platform Tiles (Search Results) - ' + filteredTile.Fields.Title"
                        *ngIf="filteredTile.Fields.ExternalUrl"></a>
                    <a class="absolute top-0 left-0 w-full h-full opacity-0"
                        [routerLink]="filteredTile.Fields.AngularRoute"
                        [amplitudeEvent]="'Platform Tiles (Search Results) - ' + filteredTile.Fields.Title"
                        *ngIf="filteredTile.Fields.AngularRoute"></a>
                    <div class="flex flex-row h-full text-center" *ngIf="filteredTile.Fields.Size === 'Large'">
                        <div class="flex flex-col items-center justify-center w-3/5 p-20 pr-10">
                            <img class="w-auto h-64 m-10" src="./assets/images/icons/{{filteredTile.Fields.Logo}}"
                                [alt]="filteredTile.Fields.Title" *ngIf="!!filteredTile.Fields.Logo" />
                            <span
                                class="z-10 flex items-center justify-center w-auto m-20 font-bold leading-tight uppercase max-h-64 border-gray-dark text-gray-dark border-3"
                                [class]="filteredTile.Fields.Subtitle.length > 10 ? 'p-5 text-lg max-w-150' : filteredTile.Fields.Subtitle.length > 4 ? 'p-10 text-xl' : 'p-15 text-3xl'"
                                [textContent]="filteredTile.Fields.Subtitle" *ngIf="!filteredTile.Fields.Logo"></span>
                            <h4 class="m-10 mb-0 text-base font-semibold">{{filteredTile.Fields.Title}}</h4>
                            <p class="m-10 text-sm">{{filteredTile.Fields.Description}}</p>
                        </div>
                        <div class="flex flex-row w-2/5 bg-center bg-no-repeat bg-cover"
                            style="background:url({{filteredTile.Fields.CoverImgUrl}})">
                            <div class="flex-grow-0 w-48 h-full mr-auto bg-left bg-no-repeat bg-cover"
                                style="background: url('./assets/images/sm-wave-vertical.svg')"></div>
                        </div>
                    </div>

                    <div class="flex flex-col h-full text-center" *ngIf="filteredTile.Fields.Size === 'Medium'">
                        <div
                            class="relative flex flex-col items-center justify-center flex-grow-0 bg-opacity-50 bg-gray-lightest">

                            <img class="z-10 w-auto h-64 m-10" src="./assets/images/icons/{{filteredTile.Fields.Logo}}"
                                [alt]="filteredTile.Fields.Title" *ngIf="!!filteredTile.Fields.Logo" />
                            <span
                                class="z-10 flex items-center justify-center w-auto m-20 font-bold leading-tight uppercase max-h-64 border-gray-dark text-gray-dark border-3"
                                [class]="filteredTile.Fields.Subtitle.length > 10 ? 'p-5 text-lg max-w-150' : filteredTile.Fields.Subtitle.length > 4 ? 'p-10 text-xl' : 'p-15 text-3xl'"
                                [textContent]="filteredTile.Fields.Subtitle" *ngIf="!filteredTile.Fields.Logo"></span>

                            <div class="absolute bottom-0 left-0 z-0 flex-grow-0 w-full h-48 mt-auto bg-bottom bg-no-repeat bg-cover"
                                style="background: url('../../../Images/wave-sm.svg')"></div>
                        </div>
                        <div class="flex flex-col flex-grow p-20" flex="grow" layout="column">
                            <h4 class="m-10 mb-0 text-base font-semibold">{{filteredTile.Fields.Title}}</h4>
                            <p class="m-10 text-sm">{{filteredTile.Fields.Description}}</p>
                        </div>
                    </div>

                    <div class="flex flex-col h-full text-center" *ngIf="filteredTile.Fields.Size === 'Small'">
                        <div
                            class="relative flex flex-col items-center justify-center flex-grow-0 bg-opacity-50 bg-gray-lightest">
                            <span
                                class="z-10 flex flex-row items-center justify-center mb-0 rounded-full w-36 h-36 bg-gray-lightest m-30">
                                <i class="material-icons"
                                    [class]="filteredTile.Fields.ForegroundColor">{{filteredTile.Fields.Icon}}</i>
                            </span>
                            <div class="absolute bottom-0 left-0 z-0 flex-grow-0 w-full h-48 mt-auto bg-bottom bg-no-repeat"
                                style="background: url('../../../Images/wave-sm.svg')"></div>
                        </div>
                        <div class="flex flex-col flex-grow p-10">
                            <h4 class="m-10 mb-0 text-base font-semibold">{{filteredTile.Fields.Title}}</h4>
                            <p class="m-10 text-sm">{{filteredTile.Fields.Description}}</p>
                            <!-- <button class="m-10 mt-auto mb-20 button is-primary is-inverted">Learn More</button> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="relative flex-grow p-20 loader-wrap" *ngIf="!!loadingTile">
        <div class="loader-sm is-blue"></div>s
    </div>
</section>