<div class="w-full h-full">
    <div class="grid flex-grow-0 grid-cols-1 xs:grid-cols-2 md:grid-cols-4 gap-x-20 gap-y-20" [class]="loading ? 'hidden' : ''" @fadeIn>
        <app-dashboard-spotlight class="order-2 md:order-1 w-full h-auto col-span-2 xs:col-span-1" (loaded)="addEvent($event)"></app-dashboard-spotlight>
        <app-dashboard-business-summary class="order-1 md:order-2 h-auto col-span-2"
            (loaded)="addEvent($event)"></app-dashboard-business-summary>
        <app-dashboard-upcoming class="order-3 w-full h-auto col-span-2 xs:col-span-1" (loaded)="addEvent($event)"
            *ngIf="canSeeTrips"></app-dashboard-upcoming>
        <app-dashboard-explore class="order-4 h-auto col-span-2 md:col-span-4" (loaded)="addEvent($event)"></app-dashboard-explore>
    </div>
    <div class="relative flex flex-row flex-wrap flex-grow pt-20 loader-wrap md:pt-40" *ngIf="loading" @fadeIn>
        <div class="loader-sm is-blue"></div>
    </div>
</div>