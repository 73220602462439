<div class="max-w-full p-20 md:max-w-1280 md:p-30" @fadeIn>
    <h2 class="mb-10 mr-auto text-lg font-bold leading-none">Marketplace</h2>
    <p class="mb-20 text-base text-gray md:mb-30">
        Delivering risk-managed solutions to investment professionals across multiple asset classes, centralized under
        one marketplace.
    </p>
    <!-- <mat-checkbox class="p-5 checkbox" [(ngModel)]="showLocalSimon" (change)="openSimonSso(null)">Local Simon
    </mat-checkbox> -->
    <div class="flex flex-row flex-wrap w-full">
        <section class="w-full pb-20 md:w-1/2 md:pr-30 md:pb-0" *showIfOne="'MarketplaceAnnuity:View'">
            <div class="flex flex-col items-center justify-start flex-grow h-full p-20 card md:p-30">
                <i class="mb-10 fig-48px fig-product text-blue-light md:mb-20 md:mt-10"></i>
                <h2 class="text-lg font-bold mb-15">Annuities</h2>
                <p class="w-full mx-auto mb-20 text-base text-center text-gray md:w-3/4 md:mb-30">Access to an array of annuity
                    products utilizing SIMON's proprietary
                    Efficient Frontier and our exclusive&nbsp;carriers.
                </p>
                <div class="mb-20 sm:mb-30 lg:mb-50">
                    <button mat-button class="button is-primary" [routerLink]="['../Details', 'Annuities']" attr.aria-label="View Annuity Products">View Products</button>
                </div>
                <h3 class="mb-10 text-base font-bold md:mb-20">Exclusive Carriers:</h3>
                <!-- <iframe class="w-full h-320" name="marketplaceIframe"></iframe> -->
                <div class="flex flex-row flex-wrap justify-center" *ngIf="!!annuitiesCarriers.length">
                    <div class="flex flex-col items-center justify-center p-10 h-100 md:p-15" *ngFor="let aCarrier of annuitiesCarriers | orderBy: 'Title'">
                        <img class="h-auto w-100 filter grayscale-50" [src]="aCarrier.ExternalUrl" [attr.aria-label]="aCarrier.Title + ' logo'"
                        [matTooltip]="aCarrier.Title" matTooltipPosition="below" />
                        <!-- <label class="mt-10 text-sm text-grey" [textContent]="aCarrier.Title"></label> -->
                    </div>
                </div>
            </div>
        </section>
        <section class="w-full md:w-1/2" *showIfOne="'MarketplaceStructuredInvestments:View'">
            <div class="flex flex-col items-center justify-start flex-grow h-full p-20 card md:p-30">
                <i class="mb-10 fig-48px fig-product text-blue-light md:mb-20 md:mt-10"></i>
                <h2 class="text-lg font-bold mb-15">Structured Investments</h2>
                <p class="w-full mx-auto mb-20 text-base text-center text-gray md:w-3/4 md:mb-30">Choose from a wide selection of
                    current structured investments across multiple&nbsp;issuers.
                </p>
                <div class="mb-20 sm:mb-30 lg:mb-50">
                    <button mat-button class="button is-primary" [routerLink]="['../Details', 'Structured Notes']"  attr.aria-label="View Offerings">View Offerings</button>
                </div>
                <h3 class="mb-10 text-base font-bold md:mb-20">Exclusive Issuers:</h3>
                <!-- <iframe class="w-full h-320" name="marketplaceIframe"></iframe> -->
                <div class="flex flex-row flex-wrap justify-center" *ngIf="!!structuredCarriers.length">
                    <div class="flex flex-col items-center justify-center p-10 h-100 md:p-15" *ngFor="let sCarrier of structuredCarriers | orderBy: 'Title'">
                        <img class="h-auto w-100 filter grayscale-50" [src]="sCarrier.ExternalUrl" [attr.aria-label]="sCarrier.Title + ' logo'"
                        [matTooltip]="sCarrier.Title" matTooltipPosition="below" />
                        <!-- <label class="mt-10 text-sm text-grey" [textContent]="sCarrier.Title"></label> -->
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>