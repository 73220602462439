<section class="grid grid-cols-1 pb-20 gap-x-20 gap-y-20 md:gap-x-30 md:gap-y-30 md:grid-cols-4 md:pb-30" @fadeIn>
    <section class="flex flex-col col-span-1" [class]="!getResourceUrl() ? 'md:col-span-3': 'md:col-span-4'">
        <h1 class="mb-10 mr-auto text-xl font-bold leading-none md:text-2xl" *ngIf="!getResourceUrl()">Life
            Insurance</h1>
    </section>
    <section class="flex flex-wrap col-span-1 md:col-span-3 "
        [class]="!getResourceUrl() ? 'lg:col-span-2': 'lg:col-span-3'">
        <div
            class="flex flex-row flex-wrap items-start flex-grow-0 w-full gap-0 mb-10 overflow-hidden text-white bg-center bg-no-repeat bg-cover card sm:mb-20 md:mb-30">
            <div class="flex flex-col w-full p-10 border-b border-solid sm:p-20 md:p-30 border-gray-lighter">
                <div class="flex flex-row items-center h-24 text-gray-dark">
                    <i class="mr-10 fig-24px fig-product text-gray"></i>
                    <h2 class="text-lg font-bold leading-none">Life Insurance Resources</h2>
                </div>
            </div>
            <div class="flex flex-row flex-wrap items-start w-full p-10 sm:p-20 md:p-30">
                <div class="w-full h-full pb-20 sm:w-1/3">
                    <a class="flex flex-row cursor-pointer"
                        href="https://www.figmarketing.com/files/resources/FIG_Life_Quote_Request_Form_FINAL.pdf"
                        target="_blank" aria-label="FIG Life Quote Requirement Form link" rel="noopener"
                        matTooltip="FIG Life Quote Requirement Form link" matTooltipPosition="above">
                        <i class="h-24 mr-10 material-icons-outlined text-gray">article</i>
                        <span class="pb-0 text-lg font-bold text-center underline text-gray-dark">FIG Life Quote Req.
                            Form
                        </span>
                    </a>
                </div>
                <div class="flex flex-row flex-wrap w-full sm:w-2/3 sm:pl-20">
                    <ng-container *ngFor="let resourceGroup of productResources; let index = index">
                        <button
                            class="flex items-center justify-center h-auto p-10 mb-5 capitalize border border-solid rounded button is-primary is-inverted text-gray-dark border-gray-lighter md:mb-20 md:mr-20"
                            mat-button amplitudeEvent="Open {{resourceGroup.GroupName}} Menu"
                            [matMenuTriggerFor]="menu0">
                            <span class="flex items-center justify-center">
                                <span class="ml-5 text-base" [innerHtml]="resourceGroup.GroupName"></span><i
                                    class="ml-10 fig-18px fig-triangle-down-filled"></i>
                            </span>
                        </button>

                        <mat-menu #menu0="matMenu">
                            <div class="flex flex-col items-start">
                                <div class="flex flex-row items-center justify-start w-full mb-5"
                                    *ngFor="let resource of resourceGroup.AdditionalResources">
                                    <a class="flex flex-row items-center w-full p-5 whitespace-pre-line cursor-pointer"
                                        [href]="resource.ExternalUrl" target="_blank"
                                        [attr.aria-label]="resource.Name + ' Link'" mat-menu-item
                                        [amplitudeEvent]="'Open ' + resource.Name">
                                        <span class="w-full" [innerHtml]="resource.Name"></span>
                                    </a>
                                </div>
                            </div>
                        </mat-menu>
                    </ng-container>
                </div>
            </div>
        </div>

        <div
            class="flex flex-row flex-wrap items-start w-full mb-10 overflow-hidden text-white bg-center bg-no-repeat bg-cover card sm:mb-20 md:mb-30">
            <div class="flex flex-col w-full p-10 border-b border-solid sm:p-20 md:p-30 border-gray-lighter">
                <div class="flex flex-row items-start text-gray-dark">
                    <i class="mr-10 fig-24px fig-product text-gray"></i>
                    <div>
                        <h2 class="flex items-center h-24 mb-5 text-lg font-bold leading-none">CAR Program</h2>
                        <p class="text-base">Our CAR review analyzes potential changes to current life insurance
                            policies</p>
                    </div>
                </div>
            </div>
            <div class="flex flex-row flex-wrap justify-between w-full p-20">
                <ng-container class="" *ngFor="let carResourceGroup of carResources; let index = index">
                    <div class="w-full p-10 md:w-1/3">
                        <button
                            class="flex items-center justify-center w-full h-auto p-10 mx-5 mb-5 capitalize border border-solid rounded button is-primary is-inverted text-gray-dark border-gray-lighter md:mb-10"
                            mat-button amplitudeEvent="Open {{carResourceGroup.GroupName}} Menu"
                            [matMenuTriggerFor]="menu0">
                            <span class="flex items-center justify-center">
                                <span class="ml-5 text-base" [innerHtml]="carResourceGroup.GroupName"></span><i
                                    class="ml-10 fig-18px fig-triangle-down-filled"></i>
                            </span>
                        </button>
                    </div>
                    <mat-menu #menu0="matMenu">
                        <div class="flex flex-col items-start">
                            <div class="flex flex-row items-center justify-start w-full mb-5"
                                *ngFor="let carResource of carResourceGroup.AdditionalResources">
                                <a class="flex flex-row items-center w-full whitespace-pre-line cursor-pointer"
                                    [href]="carResource.ExternalUrl" target="_blank" rel="noopener"
                                    [attr.aria-label]="carResource.Name + ' Link'" mat-menu-item
                                    [amplitudeEvent]="'Open ' + carResource.Name">
                                    <span class="w-full" [innerHtml]="carResource.Name"></span>
                                </a>
                            </div>
                        </div>
                    </mat-menu>
                </ng-container>
            </div>
        </div>

        <div
        class="flex flex-row flex-wrap items-start flex-grow-0 w-full gap-0 mb-10 overflow-hidden text-white bg-center bg-no-repeat bg-cover card sm:mb-20 md:mb-30">
        <div class="flex flex-col w-full p-10 border-b border-solid sm:p-20 md:p-30 border-gray-lighter">
            <div class="flex flex-row items-center h-24 text-gray-dark">
                <i class="mr-10 material-icons-outlined text-gray">gpp_good</i>
                <h2 class="text-lg font-bold leading-none">Underwriting</h2>
            </div>
        </div>
        <div class="flex flex-row flex-wrap items-start w-full p-10 sm:p-20 md:p-30">
            <div class="flex flex-col w-full h-full pb-20 sm:w-1/3">
                <a class="flex flex-row mb-20 cursor-pointer"
                    href="https://www.figmarketing.com/files/resources/FIG_HIPAA_Feb_2021.pdf"
                    target="_blank" aria-label="FIG HIPAA link" rel="noopener"
                    matTooltip="FIG HIPAA link" matTooltipPosition="above">
                    <i class="h-24 mr-10 material-icons-outlined text-gray">article</i>
                    <span class="pb-0 text-lg font-bold text-center underline text-gray-dark">
                        FIG HIPAA Form
                    </span>
                </a>
                <a class="flex flex-row cursor-pointer"
                    href="https://www.figmarketing.com/files/resources/FIG_INFORMAL_APP_2021_FINAL.pdf"
                    target="_blank" aria-label="FIG Informal Application link" rel="noopener"
                    matTooltip="FIG Informal Application link" matTooltipPosition="above">
                    <i class="h-24 mr-10 material-icons-outlined text-gray">article</i>
                    <span class="pb-0 text-lg font-bold text-center underline text-gray-dark">
                        Informal Application
                    </span>
                </a>
            </div>
            <div class="flex flex-row flex-wrap w-full sm:w-2/3 sm:pl-20">
                <ng-container *ngFor="let underwritingGroup of underwritingResources; let index = index">
                    <button
                        class="flex items-center justify-center h-auto p-10 mb-5 capitalize border border-solid rounded button is-primary is-inverted text-gray-dark border-gray-lighter md:mb-20 md:mr-20"
                        mat-button amplitudeEvent="Open {{underwritingGroup.GroupName}} Menu"
                        [matMenuTriggerFor]="menu0">
                        <span class="flex items-center justify-center">
                            <span class="ml-5 text-base" [innerHtml]="underwritingGroup.GroupName"></span><i
                                class="ml-10 fig-18px fig-triangle-down-filled"></i>
                        </span>
                    </button>

                    <mat-menu #menu0="matMenu">
                        <div class="flex flex-col items-start">
                            <div class="flex flex-row items-center justify-start w-full mb-5"
                                *ngFor="let underwritingResource of underwritingGroup.AdditionalResources">
                                <a class="flex flex-row items-center w-full p-5 whitespace-pre-line cursor-pointer"
                                    [href]="underwritingResource.ExternalUrl" target="_blank" rel="noopener"
                                    [attr.aria-label]="underwritingResource.Name + ' Link'" mat-menu-item
                                    [amplitudeEvent]="'Open ' + underwritingResource.Name">
                                    <span class="w-full" [innerHtml]="underwritingResource.Name"></span>
                                </a>
                            </div>
                        </div>
                    </mat-menu>
                </ng-container>
            </div>
        </div>
    </div>

        <div
            class="flex flex-row flex-wrap items-start w-full overflow-hidden text-white bg-center bg-no-repeat bg-cover card">
            <div class="flex flex-col w-full p-10 border-b border-solid sm:p-20 md:p-30 border-gray-lighter">
                <div class="flex flex-row items-start text-gray-dark">
                    <i class="mr-10 fig-24px fig-product text-gray"></i>
                    <div>
                        <h2 class="flex items-center h-24 text-lg font-bold leading-none">Advanced Market Resources</h2>
                    </div>
                </div>
            </div>

            <div class="flex flex-row flex-wrap items-start w-full p-10 sm:p-20 md:p-30">
                <div class="w-full h-full pb-20 sm:w-1/3">
                    <a class="flex flex-row cursor-pointer"
                        href="https://www.figmarketing.com/files/resources/2022_Tax_Guide.pdf"
                        target="_blank" aria-label="2022 Tax Summary Guide link" rel="noopener"
                        matTooltip="2022 Tax Summary Guide link" matTooltipPosition="above">
                        <i class="h-24 mr-10 material-icons-outlined text-gray">article</i>
                        <span class="pb-0 text-lg font-bold text-center underline text-gray-dark">2021 Tax Summary
                            Guide</span>
                    </a>
                </div>
                <div class="flex flex-row flex-wrap w-full sm:w-1/2 sm:pl-20">
                    <p class="w-full mb-20 text-base text-gray-dark">
                        The Advanced Planning Resource Center is a robust educational resource page for all of your life insurance planning needs.
                    </p>
                    <a class="flex flex-row items-center justify-center border button is-primary is-inverted border-blue"
                    href="https://www.pgih02.biz/asofig/amrc" target="_blank" aria-label="Advanced Planning Micro-site link" rel="noopener"
                    matTooltip="Advanced Planning Micro-site link" matTooltipPosition="above">Check it out!</a>
                </div>
            </div>
        </div>
    </section>
    <section class="flex flex-col items-start col-span-1">
        <!-- <div class="flex flex-row flex-wrap flex-grow w-full mb-10 overflow-hidden text-white bg-center bg-no-repeat bg-cover card sm:mb-20 md:mb-30">
                <div class="flex flex-row items-center justify-start w-full p-10 border-b border-solid lg:p-30 border-gray-lighter">
                    <div class="flex flex-row items-start text-gray-dark">
                        <i class="mr-10 material-icons-outlined">architecture</i>
                        <div>
                            <h2 class="flex items-center h-24 mb-10 text-lg font-bold leading-none">Underwriting</h2>
                        </div>
                    </div>
                </div>
            </div> -->
        <app-toolbox-sidebar class="w-full mb-20 md:mb-30" grouping="Life">
        </app-toolbox-sidebar>

        <div class="flex flex-row flex-wrap w-full overflow-hidden text-white bg-center bg-no-repeat bg-cover card">
            <div
                class="flex flex-row flex-wrap items-start w-full overflow-hidden text-white bg-center bg-no-repeat bg-cover card">
                <div
                    class="flex flex-row items-center justify-start w-full p-10 border-b border-solid sm:p-20 md:p-30 border-gray-lighter">
                    <div class="flex flex-row items-start text-gray-dark">
                        <i class="mr-10 material-icons-outlined">mic</i>
                        <div>
                            <h2 class="flex items-center h-24 text-lg font-bold leading-none">Media</h2>
                        </div>
                    </div>
                </div>
                <div class="flex flex-row flex-wrap items-center w-full p-10 sm:p-20 md:p-30">
                    <a class="relative mb-20 rounded-lg hover:shadow-lg w-120 h-80 md:mr-20 md:mb-30" href="https://sigma.libsyn.com/"
                        target="_blank" rel="noopener" aria-label="Sigma Podcast link" matTooltip="Sigma Inside Podcast link" matTooltipPosition="above">
                        <img class="w-auto mx-auto overflow-hidden rounded-lg"
                            src="./assets/images/podcast_background.png" />
                        <div class="absolute top-0 flex flex-col justify-center h-full p-10">
                            <h3 class="text-base font-bold text-white">SIGMA</h3>
                            <p class="text-xs text-white uppercase">Podcast</p>
                        </div>
                    </a>
                    <a class="relative flex flex-row border rounded-lg hover:shadow-lg w-120 h-80 md:mr-20 md:mb-30 border-gray-lighter" href="https://www.retirementinsideout.com/"
                        target="_blank" rel="noopener" aria-label="Retirement Inside Podcast link" matTooltip="Retirement Inside Podcast link" matTooltipPosition="above">
                        <img class="w-full h-auto m-auto overflow-hidden rounded-lg"
                            src="./assets/images/icons/retirement-inside-out.png" />
                    </a>
                </div>
            </div>
        </div>
    </section>
</section>
