import { Component, OnInit } from '@angular/core';
import { fadeIn } from 'src/app/animations';

@Component({
  animations: [fadeIn],
  selector: 'app-financial-planning',
  templateUrl: './financial-planning.component.html',
})
export class FinancialPlanningComponent {
  constructor() { }
}

