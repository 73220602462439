import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { orderBy } from 'lodash';
import { fadeIn, fadeInOut } from 'src/app/animations';
import { AgentLicenseApiService } from 'src/app/core/services/http/agent-license-api.service';
import { ReportsApiService } from 'src/app/core/services/http/reports-api.service';
import { PolicyListStoreService } from 'src/app/core/services/stores/policy-list-store.service';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { policyLobs } from 'src/app/shared/constants/policyList.constants';
import { areLengthsEqual } from 'src/app/shared/helpers/areLenghtsEqual.helper';
import { AgentGuidList, AgentLicenses, AgentSubAgent, AgentSubEntity } from 'src/app/shared/models/agent.models';
import { PolicyListFilter, PolicyLob } from 'src/app/shared/models/policy.models';
import { User } from 'src/app/shared/models/user.models';

@Component({
  animations: [fadeIn, fadeInOut],
  selector: 'app-policy-list-sidebar',
  templateUrl: './policy-list-sidebar.component.html',
})
export class PolicyListSidebarComponent implements OnInit {
  allAgentsSelected!:boolean;
  allLobsSelected!:boolean;
  agents!:AgentSubAgent[];
  subEntities!:AgentSubEntity[];
  lobs:PolicyLob[] = policyLobs;
  user!:User;
  isClearingPolicies!: boolean;
  selectedAgentIds!: string[];
  selectedSubEntities!: string[];
  selectedLobIds!:number[];
  selectedStartDate!:Date;
  selectedEndDate!:Date;
  queryStartDate!:Date;
  queryEndDate!:Date;
  agentLicenses!:AgentLicenses;
  commisionGroupsFilterOn: boolean = false;
  @Output() filterChangeEvent = new EventEmitter<PolicyListFilter>();
  @Input() reportType!:string;
  @Input() isLifeCycle: boolean = false;


  constructor(private reportsApiService:ReportsApiService, private sessionStore:SessionStoreService,
    private policyListStore:PolicyListStoreService,
    private agentLicenseApiService: AgentLicenseApiService) { }

  ngOnInit(): void {
    if (this.reportType === 'Variable') {
      // Remove Disability Lob if we are in the Variable Business list
      this.lobs = this.lobs.filter(l => l.Name !== 'Disability');
    }
    this.user = this.sessionStore.User;
    this.getFilters();
    this.reportsApiService.getSubAgents()
      .subscribe((subAgents) => {
        this.agentLicenses = this.sessionStore.OnBehalfOfUser ? this.sessionStore.OnBehalfOfUser.AgentLicenses : this.sessionStore.User.AgentLicenses;
        this.setAgentList(subAgents);
        this.setLobList();
        this.sendFilters();
      });
    this.reportsApiService.getSubEntities()
      .subscribe((subEntities) => {
        this.setSubEntityList(subEntities);
      });
  }

  getFilters(){
    //Check to see if any current filters have been applied this session
    this.selectedAgentIds = this.policyListStore.getSelectedAgentIds() ? this.policyListStore.getSelectedAgentIds() : [this.user.AgentArcGuid!];
    this.selectedSubEntities = [];
    this.selectedLobIds = this.policyListStore.getSelectedLobIds();
    this.selectedStartDate = this.policyListStore.getStartDate();
    this.selectedEndDate = this.policyListStore.getEndDate();
    this.queryStartDate = this.selectedStartDate;
    this.queryEndDate = this.selectedEndDate;
  }

  setPolicyStore(){
    // store current filters in service so they persist if user goes to another page
    this.policyListStore.setSelectedAgentIds(this.selectedAgentIds);
    this.policyListStore.setSelectedLobIds(this.selectedLobIds);
    this.policyListStore.setStartDate(this.selectedStartDate);
    this.policyListStore.setEndDate(this.selectedEndDate);
  }

  setSubEntityList(subEntities:AgentSubEntity[]){
    if (subEntities.length){
      this.subEntities = subEntities.map(subEntity => {
        return {...subEntity, Selected: false};
      });
    }
  }

  setAgentList(subAgents:AgentSubAgent[]){
    if (subAgents.length){
      this.agents = subAgents.map(subAgent => {
        return {...subAgent, ...{Selected: this.selectedAgentIds.includes(subAgent.ChildAgentGuid!) ? true : false}};
      });
      this.agents = orderBy(this.agents, 'Firstname');
    } else {
      this.agents = [];
    }

    this.agents.unshift(this.convertUserToAgent());
    this.setSelectAllIfNeeded();
  }

  setLobList(){
    this.lobs.forEach(lob => {
      if(this.selectedLobIds.includes(lob.Id)) lob.Selected = true;
    });
    this.setSelectAllIfNeeded();
  }

  setSelectAllIfNeeded(){
    this.allAgentsSelected = areLengthsEqual(this.selectedAgentIds, this.agents);
    this.allLobsSelected = areLengthsEqual(this.selectedLobIds, this.lobs);
  }

  toggleCheckbox(array:any[], selectedArray:any[], item: any){
    const id = item.ChildAgentGuid || item.AgentId || item.ChildCommissionGroupId || item.Id;
    const index = selectedArray.indexOf(id);

    if(item.Selected && index === -1) selectedArray.push(id);
    else selectedArray.splice(index, 1);

    if (item.ChildCommissionGroupId) {
      if (this.selectedAgentIds.length > 0) this.isClearingPolicies = true;
      this.selectedAgentIds = [];
      this.allAgentsSelected = false;
      this.agents.map((a) => a.Selected = false);
      this.commisionGroupsFilterOn = true;
    } else {
      if (this.selectedSubEntities.length > 0) this.isClearingPolicies = true;
      if (item.AgentId) {
        // if checkbox is for agent, reset all subEntities
        this.commisionGroupsFilterOn = false;
        this.selectedSubEntities = [];
        if (!!this.subEntities) {
          this.subEntities.map((se) => se.Selected = false);
        }
      }
    }

    this.setPolicyStore();
    this.setSelectAllIfNeeded();
    this.sendFilters();
  }

  toggleAllCheckboxes(array:any[], selectedArray:any[], allSelected:boolean, checkboxType:string){
    if (allSelected){
      if (this.selectedSubEntities.length > 0) {
        this.isClearingPolicies = true;
        this.commisionGroupsFilterOn = true;
      } else {
        this.commisionGroupsFilterOn = false;
      }
      array.forEach(item => {
        const id = item.ChildAgentGuid || item.AgentId || item.Id;
        const isDuplicate = selectedArray.indexOf(id) > -1;
        item.Selected = true;

        if (!isDuplicate) selectedArray.push(id);
      });

      this.selectedSubEntities = [];
      if (!!this.subEntities) {
        this.subEntities.map((se) => se.Selected = false);
      }
    } else {
      array.forEach(item => {
        item.Selected = false;
      });
      if (checkboxType == 'Agents') {
        this.selectedAgentIds = [];
      }  else this.selectedLobIds = [];
      this.setSelectAllIfNeeded();
    }

    this.setPolicyStore();
    this.sendFilters();
  }

  sendFilters(){
    const filters:PolicyListFilter = {
      SelectedLobIds: this.selectedLobIds,
      SelectedAgentIds: this.selectedAgentIds,

      SelectedSubEntities: this.selectedSubEntities,
      IsClearingPolicies: this.isClearingPolicies,
      ParentAgentGuid: this.user.AgentArcGuid!,

      StartDate: this.selectedStartDate,
      EndDate: this.selectedEndDate,
      AllAgentIds: this.agents.map(x => x.ChildAgentGuid),
      AllLobIds: this.lobs.map(x => x.Id),
      BaseStartDate: this.policyListStore.baseStartDate,
      BaseEndDate: this.policyListStore.baseEndDate,
      AllAgents: this.agents.map(x => {
        x.AgentId = x.ChildAgentGuid;
        return x;
      }),
      CommisionGroupsFilterOn: this.commisionGroupsFilterOn,
    };
    this.filterChangeEvent.emit(filters);

    this.isClearingPolicies = false;
  }

  convertUserToAgent():AgentSubAgent{
    return {
      FirstName: this.sessionStore.OnBehalfOfUser ? this.sessionStore.OnBehalfOfUser.FirstName : this.user.FirstName,
      LastName: this.sessionStore.OnBehalfOfUser ? this.sessionStore.OnBehalfOfUser.LastName : this.user.LastName,
      AgentId: this.user.AgentArcGuid ?? null,
      CurrentUserTag: '(Me)',
      ChildAgentGuid: this.user.AgentArcGuid ?? null,
      ParentAgentGuid: null,
      Selected: this.selectedAgentIds.includes(this.user.AgentArcGuid!) ? true : false,
      Npn: this.agentLicenses ? this.agentLicenses.Npn : null,
      CrdNumber: this.agentLicenses ? this.agentLicenses.Crd : null
    };
  }

  onDateRangeChange() {
    if (this.validDate(this.selectedStartDate) && this.validDate(this.selectedEndDate) && this.selectedEndDate > this.selectedStartDate) {
      this.setPolicyStore();
      this.sendFilters();
    }
  }

  validDate(d: Date | null): boolean {
    // Min/Max Dates for date picker. SQL's min/max dates
    const minDate = new Date(1754, 1, 1);
    const maxDate = new Date(9999, 12, 31);
    const day = (d || new Date());
    return day > minDate && day < maxDate;
  }
}
