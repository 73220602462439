import { Pipe, PipeTransform } from '@angular/core';

//Simple generic pipe to pass in callback filters to ngFor
@Pipe({
  name: 'callback'
})
export class CallbackPipe implements PipeTransform {

  transform(items: any[], callback: (item: any) => boolean): any {
    if (!items || !callback) {
      return items;
    }
    return items.filter(item => callback(item));
  }

}
