import { SimonIframeUrl } from '../models/simon-iframe-url.models';

export const variableAnnuity: SimonIframeUrl[] = [
  // {
  //     Name: 'Resources',
  //     Route: '/learning-center2/annuities/resources',
  //     Params: 'contractTypeName=Variable%20Annuity&authorName=SIMON%20Admin&showAuthorFilter=false'
  // },
  {
    Name: 'Training',
    Route: '/learning-center2/annuities/training',
    Params: 'contractTypeName=Variable%20Annuity&showProgressPanel=false&showSearch=false',
  },
  {
    Name: 'Glossary',
    Route: '/learning-center2/annuities/glossary',
  },
];

export const rilas: SimonIframeUrl[] = [
  {
    Name: 'Resources',
    Route: '/learning-center2/annuities/resources',
    Params: 'contractTypeName=Structured%20Annuity&authorName=SIMON%20Admin&showAuthorFilter=false'
  },
  {
    Name: 'Training',
    Route: '/learning-center2/annuities/training',
    Params: 'contractTypeName=Structured%20Annuity&showProgressPanel=false&showSearch=false'
  },
  {
    Name: 'Glossary',
    Route: '/learning-center2/annuities/glossary',
  },
];

export const structuredInvestment: SimonIframeUrl[] = [
  {
    Name: 'Resources',
    Route: '/learning-center2/structured-investments/resources',
  },
  {
    Name: 'Training',
    Route: '/learning-center2/structured-investments/training',
    Params: 'showProgressPanel=false&showSearch=false',
  },
  {
    Name: 'Glossary',
    Route: '/learning-center2/structured-investments/glossary',
  },
];

export const productTraining: SimonIframeUrl = {
  Name: 'Product Training',
  Route: '/learning-center2/annuities/training',
  Params: 'showSearchbox=false&showProgressPanel=false'
};

export const fia: SimonIframeUrl[] = [
  {
    Name: 'Fixed Indexed Annuity',
    Route: '/learning-center2/annuities/resources',
    Params: 'contractTypeName=Fixed%20Indexed%20Annuity&authorName=SIMON%20Admin&showAuthorFilter=false'
  },
  // {
  //     Name: 'Fixed Annuity',
  //     Route: '/learning-center2/annuities/resources',
  //     Params: 'contractTypeName=Fixed%20Annuity'
  // },
  // {
  //     Name: 'Immediate Annuity',
  //     Route: '/learning-center2/annuities/resources',
  //     Params: 'contractTypeName=Immediate%20Annuity'
  // },
];