import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PermissionService } from 'src/app/core/services/auth/permission.service';
import { SsoApiService } from 'src/app/core/services/http/sso-api.service';
import { externalLoadingHtml } from 'src/app/shared/constants/externalLoading.constants';
import { simonLogout } from 'src/app/shared/helpers/simon-logout.helper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.scss']
})
export class SsoComponent implements AfterViewInit {

  constructor(private permissionService: PermissionService, private activatedRoute: ActivatedRoute, private ssoApiService: SsoApiService, private router: Router) { }
  externalLoadingHtml = externalLoadingHtml;
  isError: boolean = false;
  environment = environment;

  ngAfterViewInit(): void {
    // Give time for simon iframe to load so we can logout old user properly
    setTimeout(() => {
      simonLogout.subscribe({
        next: () => {
          const onBehalfOfUserId = this.activatedRoute.snapshot.queryParamMap.get('onBehalfOfUserId');
          const accessToken = this.activatedRoute.snapshot.queryParamMap.get('accessToken');
          if (!accessToken) {
            this.isError = true;
          } else {
            this.permissionService.clearAllLocalStorage();
            if (onBehalfOfUserId) this.permissionService.setOnBehalfOfUserId(onBehalfOfUserId);
            this.ssoIntoPortal(accessToken);
          }
        }
      });
    }, 10000);
  }

  ssoIntoPortal(accessToken: string): void {
    this.ssoApiService.initiatePortalSso(accessToken).subscribe({
      next: () => {
        window.location.href = window.location.origin + '/Portal/Dashboard';
      },
      error: () => {
        this.isError = true;
      }
    });
  }
}
