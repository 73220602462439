<section class="flex flex-col h-full">
    <section class="flex flex-none p-20">
        <button mat-button mat-dialog-close tabindex="-1" type="button"
            class="m-10 ml-auto icon-button is-primary is-inverted">
            <i class="material-icons">close</i>
        </button>
    </section>
    <ng-container *ngIf="!isSkippingTraining">
        <section @fadeIn class="flex flex-col flex-auto p-20 overflow-auto text-center">
            <h1 class="m-10 text-lg font-bold">
                Make sure training has completed before starting application. Are you done with&nbsp;training?
            </h1>
        </section>
        <section class="flex-none p-20 border-t border-solid border-gray-lighter">
            <div class="flex flex-row justify-end">
                <button class="m-10 button is-primary is-inverted" (click)="goBackToTraining()">Not Done</button>
                <button class="m-10 button is-primary" (click)="skipTraining()">Yes, proceed to application</button>
            </div>
        </section>
    </ng-container>
    <ng-container *ngIf="isSkippingTraining">
        <section @fadeIn class="flex flex-col flex-auto p-20 overflow-auto text-center">
            <div *ngIf="!isStartingApplication">
                <h1 class="p-10 m-10 text-lg font-bold">
                    Please choose the type of application you would like to start.
                </h1>
                <div class="flex flex-row justify-end mr-20">

                </div>
            </div>
            <div *ngIf="isStartingApplication">
                <h2 class="p-10 m-10 text-lg text-gray-dark">
                    Starting application, this may take a moment...
                </h2>
                <div class="loader-sm is-blue"></div>
            </div>
            <ng-container *ngIf="errorMessage">
                <div @fadeIn
                    class="flex items-center px-20 py-10 m-20 border border-solid rounded bg-orange-lightest border-orange text-gray-dark"
                    *ngIf="errorMessage">
                    <i class="mr-20 material-icons text-orange">warning</i>
                    {{errorMessage}}
                </div>
            </ng-container>
        </section>
        <section class="flex justify-between flex-none p-20 border-t border-solid border-gray-lighter">
            <button class="m-10 button is-primary is-inverted" (click)="openPaperApp()">Upload Paper
                Application</button>
            <button [disabled]="!product?.ProductCusip" class="m-10 button is-primary" (click)="startEApplication()">Start E-Application</button>
        </section>
    </ng-container>
</section>