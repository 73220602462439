import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FirelightApiService } from 'src/app/core/services/http/firelight-api.service';
import { Carrier, Product, FirelightState, FirelightCarrierProduct, CreateActivityRequest, CreateActivityBasicResponse } from 'src/app/shared/models/firelightApi.models';
import { orderBy, sortBy, uniqBy } from 'lodash';
import { map } from 'rxjs/operators';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductsApiService } from 'src/app/core/services/http/products-api.service';
import { EAppCarrier, EAppProduct } from 'src/app/shared/models/eapps.models';
import { SsoService } from 'src/app/core/services/sso.service';
import { iGoApplication } from 'src/app/shared/models/iGo-application.models';
import { SubmitPaperApplicationComponent } from '../../modals/submit-paper-application/submit-paper-application.component';
import { StateBasicModel } from 'src/app/shared/models/generic.models';
import { statesPlusDCAndVI } from 'src/app/shared/constants/states.constants';
import { EAppService } from 'src/app/core/services/e-app.service';

@Component({
  selector: 'app-submit-application',
  templateUrl: './submit-application.component.html',
})
export class SubmitApplicationComponent implements OnInit {
  activityName!:string;
  productList!: EAppProduct[]
  loading: boolean = false;
  carriers!: EAppCarrier[];
  products!: EAppProduct[];
  product!: EAppProduct|null;
  carrier!: EAppCarrier;
  carrierName!: string;
  states: StateBasicModel[] = statesPlusDCAndVI;
  state!: StateBasicModel
  isSubmitting: boolean = false;
  @Output() closed: EventEmitter<any> = new EventEmitter()

  constructor(
    private firelightApiService: FirelightApiService,
    private productsApiService: ProductsApiService,
    private ssoService:SsoService,
    private dialog:MatDialog,
    private router: Router,
    private _snackBar: MatSnackBar,
    private eAppService:EAppService
  ) { }

  ngOnInit(): void {
    console.dir(window);
    this.loading = true;

    this.productsApiService.get()
      .subscribe(
        (res) => {
          this.productList = res;
          this.getCarriers();
        },
        (error) => console.log(error),
        () => this.loading = false
      );
  }


  getCarriers() {
    const carrierList = this.productList.map(item => {
      return {
        CarrierName: item.CarrierName,
        CarrierCode: item.CarrierCode
      };
    });

    this.carriers = sortBy(uniqBy(carrierList, 'CarrierName'), 'CarrierName');
  }

  setCarrier($event:any) {
    this.carrier = $event.value;
    this.filterProducts();
  }

  setState($event:any){
    this.state = $event.value;
    this.filterProducts();
  }
  setProduct($event:any){
    this.product = $event.value;
  }

  filterProducts(){
    this.products = [];
    this.product = null;
    if (this.state && this.carrier){
      const filteredProducts = this.productList.filter(p => p.CarrierName == this.carrier.CarrierName && p.States.map(x => x.StateAbbrev).includes(this.state.ShortName));
      this.products = orderBy(filteredProducts, 'ProductName');
    }
  }

  submitIPipelineEApplication(){
    this.eAppService.startiPipelineEApplication(this.state.ShortName, this.product?.ProductId!, this.product!.ProductTypeId!);
  }

  openPaperApplicationModal(){
    this.closed.emit();
    this.dialog.open(SubmitPaperApplicationComponent, {
      height: '84rem',
      width: '64rem',
    });
  }

  submitFirelightEApplication() {
    const request:CreateActivityRequest = {
      ActivityName: this.activityName,
      CarrierCode: this.carrier.CarrierCode!,
      CUSIP: this.product!.ProductCusip!,
      Jurisdiction: this.product!.States.find(state => state.StateAbbrev === this.state.ShortName)!.JurisdictionId.toString(),
      Client: {
        FirstName: 'FirstName',
        LastName: 'LastName'
      }
    };

    let message = '', toastClass = '', activityId = '';
    this.firelightApiService.postFirelightApplication(request)
      .subscribe(
        (res) => {
          message = 'Application submitted successfully!';
          toastClass = 'is-success';
          activityId = res.ActivityId;
          this.closed.emit();
        },
        (error) => {
          console.log(error);
          message = 'Error submitting application';
          toastClass = 'is-error';
          this._snackBar.open(message, 'Close', {
            panelClass: [toastClass]
          });
        },
        () => {
          this.router.navigate(['/Portal/ProductRD/Execute/App'], {queryParams : {activityId: activityId}});
        });
  }

}
