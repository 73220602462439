import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { fadeIn } from 'src/app/animations';
import { EnvironmentEnum } from 'src/app/shared/models/generic.models';
import { environment } from 'src/environments/environment';

@Component({
  animations: [fadeIn],
  selector: 'app-structured-lifecycle',
  templateUrl: './structured-lifecycle.component.html',
})
export class StructuredLifecycleComponent implements OnInit {
  id?: string | null;
  showLocalSimon: boolean = false;
  iframeUrl: string = environment.simonUrl;
  environment = environment;
  env?: EnvironmentEnum | null;

  constructor(
    private route: ActivatedRoute,
    private titleService: Title) {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      this.ngOnInit();
    });
    this.titleService.setTitle(route.snapshot.data.title + ' | Agent Portal');
  }

  ngOnInit(): void {
    this.openSimonSso(this.id);
  }

  openSimonSso(id?: string | null) {
    if (this.showLocalSimon) {
      this.iframeUrl = environment.simonLocalUrl;
    } else {
      this.iframeUrl = environment.simonUrl;
    }
    if (id === 'Annuities') {
      this.iframeUrl += '/salesbook/lifecycle';
    } else {
      this.iframeUrl += '/salesbook/lifecycle';
    }
  }

}
