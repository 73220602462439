import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { fadeIn } from 'src/app/animations';
import { SsoApiService } from 'src/app/core/services/http/sso-api.service';
import { ToastClassEnum } from 'src/app/core/services/snackbar/snackbar.models';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';

@Component({
  animations: [fadeIn],
  selector: 'app-moneyguide-sso-modal',
  templateUrl: './moneyguide-sso-modal.component.html',
})
export class MoneyguideSsoModalComponent implements OnInit, AfterViewInit {
  constructor(
    private dialogRef: MatDialogRef<MoneyguideSsoModalComponent>,
    private formBuilder: FormBuilder,
    private snackbarService: SnackbarService,
    private ssoApiService: SsoApiService,
  ) { }
  institutionalIdForm: FormGroup = this.formBuilder.group({
    institutionalId: ['', [Validators.required,]],
  });
  isDisabled = false;
  isSaved = false;
  isSubmitting = false;

  ngOnInit(): void {
    this.ssoApiService.getMoneyGuideInstitutionId()
      .subscribe({
        next: (result) => {
          this.institutionalIdForm.controls['institutionalId'].setValue(result);
          this.isSaved = true;
        }
      });
  }

  ngAfterViewInit(): void {
    const width = '50rem';
    const height = '50rem';
    this.dialogRef.updateSize(width, height);
    this.dialogRef.disableClose = false;
  }

  onCompleteSubmit(): void {
    if (this.institutionalIdForm.invalid) {
      this.institutionalIdForm.markAllAsTouched();
    } else {
      this.ssoApiService.setMoneyGuideInstitutionId(this.institutionalIdForm.controls['institutionalId'].value)
        .subscribe({
          next: () => {
            this.snackbarService.openSnackbar('Institutional ID connected successfully!', ToastClassEnum.success, 7, 'top');
            setTimeout(() => {
              window.open('../../api/money-guide-sso', '_blank', 'noopener');
            }, 1500);
            this.dialogRef.close();
          },
          error: () => {
            this.snackbarService.openSnackbar('Error saving Institution ID. Please try again.', ToastClassEnum.warning, 7, 'top');
            this.isSaved = false;
          },
        })
        .add(() => this.isSubmitting = false);
    }
  }
}
