<section class="flex flex-col w-full h-full bg-center bg-no-repeat bg-cover"
  [class]="!!getComponent() && !getComponent().includes('PolicyDetails') && !getComponent().includes('DisabilityDetails') && selectedReportType !== 'Structured' && selectedReportType !== 'Simon' ? 'p-20 md:p-30' : 'overflow-auto'"
  [style]="selectedReportType !== 'Structured' && selectedReportType !== 'Simon' ? 'background: url(./assets/images/wave-bg.svg);' : ''"
  @fadeInOut>
  <div class="flex flex-col h-full" [class]="!getComponent().includes('PolicyDetails') && !getComponent().includes('DisabilityDetails') && selectedReportType !== 'Structured' && selectedReportType !== 'Simon' ? 'overflow-hidden card' : ''">
    <section class="items-center w-full p-20 border-b border-solid border-gray-lighter"
    [class]="!!getComponent() && !getComponent().includes('PolicyDetails') && !getComponent().includes('DisabilityDetails') ? 'flex' : 'hidden'"
      *ngIf="selectedReportType !== 'Structured' && selectedReportType !== 'Simon'" @fadeInOut>
      <div class="flex mr-auto">
        <div class="relative flex flex-grow p-10 sm:w-384">
          <i class="absolute mr-10 transform -translate-y-1/2 left-15 fig-24px fig-search text-gray top-1/2"></i>
          <input class="w-full border input is-inverted is-lg pl-36 border-gray-light" type="text" name="search"
            aria-label="Search policies and cases" [placeholder]="policyListType == 'Wealth' ? 'Search Accounts' : 'Search Policies'" (keyup)='updateSearchText()'
            [(ngModel)]="searchText" />
        </div>
      </div>
      <button class="m-10 button icon-button is-primary is-inverted" mat-button matTooltip="Export List"
        (click)="export()" aria-label="Export Carriers List">
        <span class="material-icons-outlined">
          file_download
        </span>
      </button>
      <button *ngIf="policyListType != 'Informal' && policyListType != 'Wealth'" class="m-10 button icon-button is-primary is-inverted"
        aria-label="Open menu" mat-button matTooltip="Toggle Menu" (click)="toggleSidenav()">
        <span class="material-icons ">menu</span>
      </button>
    </section>
    <!--<section class="flex flex-row items-center justify-between p-10 border-b border-solid md:p-20 border-gray-lighter" 
      *ngIf="selectedReportType !== 'Structured'" @fadeIn>

      </section>-->
    <div class="flex-grow h-0 overflow-auto" [class]="!!getComponent() && !getComponent().includes('PolicyDetails') ? '' : 'flex justify-center'"
    id="business-container-content" #businessContainerContent>
      <router-outlet (activate)="onActivate($event)" @fadeIn>
      </router-outlet>
    </div>
  </div>
</section>