import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { fadeIn } from 'src/app/animations';
import { ReportsApiService } from 'src/app/core/services/http/reports-api.service';
import { UserApiService } from 'src/app/core/services/http/user-api.service';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { AgentBusinessSummary, AgentYearlyProduction } from 'src/app/shared/models/agent.models';
import { ErrorMessageModel } from 'src/app/shared/models/error-message.model';
import { ThresholdV2 } from '../../../../models/dashboard.models';

@Component({
  templateUrl: './dashboard-production-summary.component.html',
  selector: 'app-dashboard-production-summary',
  animations: [fadeIn]
})

export class DashboardProductionSummaryComponent implements OnInit {
  businessSummary: AgentBusinessSummary = { ActiveContractCount: 0, PendingPolicyActionNeededCount: 0, IssuedPolicyCount: 0 };
  loading = false;
  error: ErrorMessageModel = {
    hasError: false,
    message: 'There was a problem retrieving data. Please try again later.'
  };
  value = 0;
  currentYear = new Date().getFullYear();
  pastYear = this.currentYear - 1;
  currentYearProductionString = '';
  pastYearProductionString = '';
  productionDefaultValueString = '$0K';
  thresholds: ThresholdV2[] = [
    { abbreviation: '', lowerBound: null, upperBound: 1000 },
    { abbreviation: 'K', lowerBound: 1000, upperBound: 1000000 },
    { abbreviation: 'M', lowerBound: 1000000, upperBound: 1000000000 },
    { abbreviation: 'B', lowerBound: 1000000000, upperBound: 1000000000000 },
    { abbreviation: 'T', lowerBound: 1000000000000, upperBound: null }
  ];

  constructor(
    private reportsApiService: ReportsApiService,
    private sessionStore: SessionStoreService,
    private userApiService: UserApiService
  ) { }

  ngOnInit(): void {
    this.getProductionData();
  }

  getProductionData(): void {
    this.loading = true;
    this.canGetYearlyProduction().subscribe({
      next: res => {
        if (res) this.reportsApiService.getAgentYearlyProduction(this.sessionStore.User.AgentArcGuid!).subscribe({
          next: res => {
            this.configureYearlyProduction(res);
          },
          error: () => {
            this.configureYearlyProduction();
          }
        }).add(() => {
          this.loading = false;
        });
        else {
          this.configureYearlyProduction();
        }
      }
    });
  }

  canGetYearlyProduction(): Observable<boolean> {
    if (this.sessionStore.User.AgentArcGuid) {
      if (this.sessionStore.OnBehalfOfUser) {
        return this.userApiService.getIsArcUser();
      } else {
        return of(true);
      }
    } else {
      return of(false);
    }
  }

  configureYearlyProduction(production?: AgentYearlyProduction[]): void {
    if (production) {
      const currentYearProduction = production.find(x => x.Year == this.currentYear);
      this.currentYearProductionString = currentYearProduction ? this.formatTotal(currentYearProduction.Premium) : this.productionDefaultValueString;

      const pastYearProduction = production.find(x => x.Year == this.pastYear);
      this.pastYearProductionString = pastYearProduction ? this.formatTotal(pastYearProduction.Premium)  : this.productionDefaultValueString;
    } else {
      this.currentYearProductionString = this.productionDefaultValueString;
      this.pastYearProductionString = this.productionDefaultValueString;
    }
  }

  formatTotal(num: number): string {
    if (!num) return this.productionDefaultValueString;
    const threshold = _.find(this.thresholds, function (t) {
      return (!t.lowerBound || t.lowerBound <= num) && (!t.upperBound || t.upperBound > num);
    });
    const preFormat = !!threshold?.lowerBound ? num / threshold.lowerBound : num;
    return `$${preFormat.toLocaleString(undefined, { maximumFractionDigits: 2 }) + threshold?.abbreviation}`;
  }
}