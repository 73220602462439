<section class="flex flex-col w-full h-full">
  <header class="flex items-center flex-none p-10 border-b border-solid border-gray-lighter lg:p-20s">
    <h2 class="m-10 text-lg font-bold">State Licenses</h2>
    <button class="m-10 ml-auto icon-button is-primary is-inverted" attr.aria-label="Close dialog"
      [mat-dialog-close]="false"><i class="material-icons">close</i></button>
  </header>
  <section class="grid grid-cols-1 overflow-auto mat-drawer-container"
  [class]="!!licenses && !!licenses.length ? 'md:grid-cols-3' : ''">
    <div class="flex flex-col w-auto p-10 m-20 card" *ngFor="let l of licenses">
      <header class="border-b border-solid border-gray-lighter">
        <h2 class="text-lg heading">
          {{stateLongNameByShortName[l.State.toUpperCase()]}}
        </h2>
        <div>
          License #: {{l.LicenseNum}} 
        </div>
      </header>
      <div class="grid grid-cols-2">
          <div class="mb-10">
            <span class="font-bold">License Class</span><br />
            {{l.LicenseClass}} 
          </div>
          <div class="mb-10">
            <span class="font-bold">Lines of Authority</span>
            <div *ngFor="let a of l.Loas; let isLast=last">{{a.Loa}}{{isLast ? '' : ', '}}</div>
          </div>
          <div class="mb-10">
            <span class="font-bold">Effective Date</span><br />
            {{l.LicensedOn}} 
          </div>
          <div class="mb-10">
            <span class="font-bold">Expiration Date</span> <br />
            {{l.Expires}}
          </div>
      </div>
    </div>
    <div *ngIf="!licenses">
      <p class="w-full p-40 text-center">No state licenses available.</p>
    </div>
  </section>
  <div class="flex flex-none p-10 mt-auto border-t border-solid lg:p-20 border-gray-lighter">
    <button mat-button class="m-10 ml-auto button is-primary" [mat-dialog-close]="true">Close</button>
  </div>
</section>
