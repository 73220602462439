import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { PolicyStatement } from '../../../shared/models/permission.models';
import { ListResponse } from '../../../shared/models/generic.models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PermissionApiService {

  constructor(private http:HttpClient) { }

  getPolicyStatements():Observable<PolicyStatement[]> {
    return this.http.get<ListResponse<PolicyStatement>>('/api/Permissions/PolicyStatements')
      .pipe(map((x:ListResponse<PolicyStatement>) => x.Entries));
  }

  getDelegatePermissions(userDelegateId:string):Observable<PolicyStatement[]> {
    return this.http.get<ListResponse<PolicyStatement>>(`/api/Permissions/Delegates/${userDelegateId}`)
      .pipe(map((x:ListResponse<PolicyStatement>) => x.Entries));
  }
}
