import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LogOutComponent } from './auth/components/log-out/log-out.component';
import { SsoComponent } from './auth/components/sso/sso.component';
import { AuthenticationGuard } from './core/services/guards/authentication-guard/authentication-guard.service';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { PortalComponent } from './portal/portal.component';

const routes: Routes = [
  { path: 'Onboarding', component: OnboardingComponent, canActivate: [AuthenticationGuard] },
  { path: 'Portal', component: PortalComponent, canActivate: [AuthenticationGuard] },
  { path: 'Sso', component: SsoComponent},
  { path: 'Logout', component: LogOutComponent},
  {
    path: '',
    redirectTo: 'Portal',
    pathMatch: 'full',
    canActivate: [AuthenticationGuard]
  },
  {
    path: '**',
    redirectTo: 'Portal',
    pathMatch: 'full',
    canActivate: [AuthenticationGuard]
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
