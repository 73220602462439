<section class="flex flex-row flex-wrap w-full h-full p-20 md:p-30 bg-center bg-no-repeat bg-cover" style="background: url(./assets/images/wave-bg.svg);" @fadeIn >
  <section class="flex flex-col h-full w-full pb-10 md:w-3/4 md:pb-0 md:pr-30">
    <!-- *ngIf="!!showAppointments" show-if-all="Reports:Contracting"-->
    <app-my-carriers class="flex flex-col flex-grow w-auto overflow-hidden card" @fadeIn></app-my-carriers>
  </section>
  <div class="flex flex-col order-1 md:order-2" [class]="showAppointments ? 'md:w-1/4 w-full' : 'w-full'">
    <section class="flex flex-col pb-10 md:pb-30">
      <app-contract-quick-links class="w-auto card" @fadeIn>
      </app-contract-quick-links>
    </section>
    <section class="flex flex-col">
      <app-general-training class="w-auto card" @fadeIn></app-general-training>
    </section>
  </div>
</section>