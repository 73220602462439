
<button class="button is-primary mt-20 md:mt-30 mr-20" (click)="openEnrollmentModal()"
  [disabled]="hasSubmittedAnyForm">
  <span *ngIf="!hasSubmittedAnyForm">
    Enroll Today
  </span>
  <span matTooltip="Elevate enrollment form already submitted" matTooltipPosition="above" 
  *ngIf="hasSubmittedAnyForm">Submitted</span>
</button>

<div *ngIf="pendingRenewal">
  <p class="mt-10 mb-0"><em>Your enrollment in Elevate will expire soon, please click the button below to view your renewal options.</em></p>
  <button class="button is-primary mt-20 md:mt-30 mr-20" (click)="openSurveyRenewalDialog()">
    <span>
      View My Renewal
    </span>
  </button>
</div>