<div class="relative flex flex-col h-full overflow-y-auto justify-self-stretch">
    <button mat-button class="absolute top-0 right-0 m-20 text-white icon-button" matTooltip="Ignore for now"
        type="button" (click)="declineEnrollment()" amplitudeEvent="Quinci Rollout - Ignore">
        <i class="material-icons">close</i>
    </button>
    <ng-container *ngIf="!isLoading && !onBehalfOfUser">
        <div @fadeIn class="flex flex-col items-center justify-center w-full bg-center bg-no-repeat bg-cover min-h-220"
            style="background-image:url('./assets/images/modal-wave.svg')">
            <label class="uppercase text-blue-lighter font-sm mt-30">Countdown to Quinci</label>
            <app-countdown-timer class="z-10 pt-15 p-30"></app-countdown-timer>
            <img class="" src="./assets/images/rocket-graphics.svg" />
        </div>
        <form @fadeIn class="relative flex flex-col flex-grow w-full" #optInForm="ngForm"
            (ngSubmit)="submitEnrollment(0)">
            <div class="flex flex-col my-auto p-30" *ngIf="!optInForm.submitted">
                <div class="relative flex flex-col w-full gap-y-30">
                    <p class="text-xl font-bold text-center">
                        We are Launching the Brand-new Quinci Portal Experience <br />
                        Enroll Today to Gain Early Access
                    </p>
                    <div class="flex flex-wrap mx-auto text-left max-w-460 gap-x-40 gap-y-20"
                        *ngIf="requiresLicensesCapture">
                        <div class="flex flex-col flex-grow xs:flex-auto">
                            <label class="mb-5 text-sm text-gray required-label">NPN</label>
                            <input class="input" [(ngModel)]="model.agentLicenses.Npn" name="npn"
                                pattern="^(0|[1-9][0-9]*)$"
                                [required]="!model.agentLicenses.Npn && !model.agentLicenses.Crd" #npn="ngModel" />
                            <span *ngIf="optInForm.submitted && optInForm.invalid" class="w-full mt-5 text-xs text-red">
                                <span *ngIf="npn.errors?.pattern">Please enter only numeric value.</span>
                                <span *ngIf="npn.errors?.required">Please enter at least one license number.</span>
                            </span>
                        </div>
                        <div class="flex flex-col flex-grow xs:flex-auto">
                            <label class="mb-5 text-sm text-gray required-label">CRD</label>
                            <input class="input" [(ngModel)]="model.agentLicenses.Crd" name="crd"
                                pattern="^(0|[1-9][0-9]*)$"
                                [required]="!model.agentLicenses.Npn && !model.agentLicenses.Crd" #crd="ngModel" />
                            <span *ngIf="optInForm.submitted && optInForm.invalid" class="w-full mt-5 text-xs text-red">
                                <span *ngIf="crd.errors?.pattern">Please enter only numeric value.</span>
                                <span *ngIf="crd.errors?.required">Please enter at least one license number.</span>
                            </span>
                        </div>
                    </div>
                    <a class="mx-auto font-bold text-center underline text-blue-dark hover:text-blue-darker"
                        [attr.href]="termsOfUseUrl" target="_blank">Terms of Use</a>
                </div>
                <!-- <div
            class="flex flex-row p-20 m-20 text-sm leading-loose bg-opacity-25 border border-solid rounded md:m-30 border-blue-lighter bg-blue-lighter">
            <i class="mt-5 mr-10 material-icons mi-18 text-gray">info</i>
            TOU and NPN/CRD are required for continued use of portal. If you don't accept the TOU and/or provide
            your
            NPN/CRD prior to 11/15 you will no be shut out of your account until you accept and provide the
            necessary
            information.
        </div> -->
            </div>
            <section *ngIf="!optInForm.submitted" type="button"
                class="flex flex-none p-20 border-t border-solid border-gray-lighter">
                <button type="button" class="m-10 button is-warning is-inverted" mat-button amplitudeEvent="Quinci Rollout - Ignore"
                    (click)="declineEnrollment()">Ignore for now</button>
                <button type="button" class="m-10 ml-auto border button is-primary is-inverted border-blue-dark" amplitudeEvent="Quinci Rollout - Learn More"
                    (click)="learnMore()">Learn More</button>
                <button mat-button amplitudeEvent="Quinci Rollout - Enroll" class="m-10 button is-primary" type="submit" [disabled]="optInForm.invalid">Enroll
                    Into Quinci</button>
            </section>
        </form>
    </ng-container>
    <div @fadeIn *ngIf="!isLoading && onBehalfOfUser" class="flex flex-col w-full p-20 m-auto gap-y-30">
        <p class="m-10 text-lg">You cannot enroll in Quinci while logged in as a different user. Please log in as yourself to make changes.</p>
    </div>    
    <div *ngIf="isLoading" class="relative p-20 loader-wrap">
        <div class="loader-sm is-blue"></div>
    </div>
</div>