import { Component, Input, OnInit } from '@angular/core';
import { AdditionalResource, AdditionalResourceGroup, EnrollmentSurveyData, ResourcePage, PurchasePageData } from 'src/app/shared/models/resource-page.models';
import { OrderModel } from 'src/app/shared/models/order.models';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { PurchaseModalComponent } from 'src/app/portal/modals/purchase-modal/purchase-modal.component';
import { PurchaseApiService } from 'src/app/core/services/http/purchase-api.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from 'src/app/portal/modals/confirmation/confirmation.component';
import { fadeIn } from 'src/app/animations';

@Component({
  selector: 'app-poz-workflow',
  templateUrl: './poz-workflow.component.html',
  animations: [fadeIn]
})
export class PozWorkflowComponent implements OnInit {
  @Input() resource!: ResourcePage
  purchased?: OrderModel|null;
  intervals: any[] = [];
  isLoading!:boolean;
  @Input() submitted!: {surveyId:number, isEnrolled:boolean}[]|undefined;

  constructor(
    private sessionStore: SessionStoreService,
    private purchaseApiService: PurchaseApiService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.verifyPurchased();
  }

  openExternalLoginLink (): void {
    window.open('https://www.thepowerofzeropercent.com/login');
  }

  openDocusignLink () {
    window.open(this.purchased?.DocusignUrl);
  }

  openExternalInterestForm () {
    window.open(this.resource.ExternalInterestFormUrl);
  }

  verifyPurchased(): any {
    this.purchased = null;
    let o: OrderModel | null = null;
    return this.purchaseApiService.getUserOrders(this.sessionStore.User.Id).subscribe(orders => {
      orders.forEach(order => {
        if (order.MarketingStrategy === this.resource.PurchasePageData?.PurchaseModel.MarketingStrategy) {
          const d = new Date(order.CompletedDate as Date);
          const n = d.toLocaleDateString();
          order.PurchaseDate = n;
          this.purchaseApiService.getMarketingStrategyOfOrder(order.Id).subscribe(strategy => {
            if (strategy.DocusignUrl && strategy.DocusignUrl !== null && strategy.DocusignUrl !== '') {
              order.DocusignUrl = strategy.DocusignUrl;
            }
            this.isLoading = false;
          });

          o = order;
        } else {
          this.isLoading = false;
        }
      });

      if (o !== null) {
        this.intervals?.forEach((interval: any) => {
          clearInterval(interval);
        });
        this.purchased = o;
      }

    }).add(()=>{
      this.isLoading = false;
    });
  }

  pollVerifyPurchase(paymentAmount: number): void {
    if (paymentAmount > 0) {
      this.intervals.push(<any>setInterval(() => {
        this.verifyPurchased();
      }, 3000));
    } else {
      this.verifyPurchased();
    }
  }

  openPurchaseDialog(): void {
    const data = this.resource.PurchasePageData;
    if (data) {
      if (data?.ConfirmationMessage) {
        // Confirmation required
        const confirmation = this.dialog.open(ConfirmationComponent, {
          data: {
            title: data.PurchaseModel.Title,
            message: data.ConfirmationMessage
          }
        });

        confirmation.afterClosed().subscribe(result => {
          if (result) {
            // confirmation accepted
            this.purchaseRsource(data);
          }
        });
      } else {
        // confirmation not requried
        this.purchaseRsource(data);
      }
    }
  }

  purchaseRsource(data: PurchasePageData): void {

    this.purchaseApiService.getAllOrders().subscribe(orders => {

      data.PurchaseModel.Orders = orders;
      this.purchaseApiService.getMarketingDollars().subscribe(agentMarketingDollars => {
        data.PurchaseModel.AgentMarketingDollars = agentMarketingDollars.Entry.MarketingDollarsTotal;
      },
      error => {
        console.log(error);
        data.PurchaseModel.AgentMarketingDollars = 0;
      },
      () => {
        const ref = this.dialog.open(PurchaseModalComponent, {
          data: {
            purchaseModel: data.PurchaseModel,
          }
        });

        ref.afterClosed().subscribe(result => {
          if (result) {
            this.pollVerifyPurchase(result.paymentAmount);
          }
        });
      });
    });
  }
}

