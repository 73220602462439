import { Injectable } from '@angular/core';
import { onboardingSteps } from 'src/app/shared/constants/onboarding.constants';
import { MasterAdvisorAgreement, MasterAdvisorAgreementRequiredModel } from 'src/app/shared/models/agreement.models';
import { OnboardingAgentLicensesOptOut, OnboardingContracting } from 'src/app/shared/models/onboarding.models';
import { UserMasterAdvisorAgreementRequiredResolver } from './resolvers/user-master-advisor-agreement-required-resolver';
import { SessionStoreService } from './stores/session-store.service';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {

  constructor(private sessionStore:SessionStoreService) { }

  getRequiredOnboardingSteps(
    userMasterAdvisorAgreement:MasterAdvisorAgreement,
    userMasterAdvisorAgreementRequired:MasterAdvisorAgreementRequiredModel,
    userAgentLicensesOptOut:OnboardingAgentLicensesOptOut,
    userContracting:OnboardingContracting
  ) : OnboardingStepModel {
    const hasCompletedMAA = !!userMasterAdvisorAgreement || !userMasterAdvisorAgreementRequired;
    const hasCompletedContracting = userContracting == null || (userContracting !== null && userContracting.IsCompleted == true);
    const hasCompletedNpnCrd = (userAgentLicensesOptOut !== null && userAgentLicensesOptOut.HasOptedOut == true) || (!!this.sessionStore.User.AgentLicenses.Crd || !!this.sessionStore.User.AgentLicenses.Npn);
    const onboardingStepModel:OnboardingStepModel = {
      RequiredSteps: [],
      ShowAlternateView: false
    };

    // If they haven't completed anything give them all the steps
    if (!hasCompletedMAA && !hasCompletedContracting){
      onboardingStepModel.RequiredSteps = onboardingSteps.filter(step => step.Id !== 1).map(step => step.Id);
      return onboardingStepModel;
    }
    if (!hasCompletedMAA) {
      onboardingStepModel.RequiredSteps.push(2); onboardingStepModel.ShowAlternateView = true;
    }
    if (!hasCompletedNpnCrd && !!this.sessionStore.User.AgentArcGuid){
      onboardingStepModel.RequiredSteps.push(3); onboardingStepModel.ShowAlternateView = true;
    }

    // Not completing contracting is a sign that this is their first onboarding, so add portal preview pages
    if (!hasCompletedContracting) {
      onboardingStepModel.RequiredSteps.push(4,5); onboardingStepModel.ShowAlternateView = false;
    }

    onboardingStepModel.RequiredSteps.sort();
    return onboardingStepModel;
  }
}

export interface OnboardingStepModel {
  RequiredSteps: number[],
  ShowAlternateView:boolean
}
