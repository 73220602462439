import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CarrierReportsApiService } from 'src/app/core/services/http/carrier-reports-api.service';
import { CarrierTraining } from 'src/app/shared/models/carrier.models';
import { fadeIn } from 'src/app/animations';
import { GeneralTraining } from 'src/app/shared/models/training.models';
import { generalTrainingConstant } from '../../../shared/constants/training.constants';
import { CookieService } from 'ngx-cookie-service';
import { getRequiredSteps, isFirstStep, isLastStep } from 'src/app/shared/helpers/onboarding.helper';
import { environment } from 'src/environments/environment';
import { PermissionService } from 'src/app/core/services/auth/permission.service';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  animations: [fadeIn]
})
export class TrainingComponent implements OnInit {
  isLastStep: boolean = false;
  isFirstStep: boolean = false;
  Id: number = 100;
  @Output() taskCompletedEvent: EventEmitter<any> = new EventEmitter();
  @Output() goToPreviousEvent: EventEmitter<any> = new EventEmitter();
  @Output() goToNextEvent: EventEmitter<any> = new EventEmitter();
  @Output() goToPortalEvent: EventEmitter<string> = new EventEmitter();
  carrierTrainings!: CarrierTraining[]
  generalTrainings: GeneralTraining[] = generalTrainingConstant;
  loading: boolean = false;
  searchText: string | undefined;
  requiredSteps!: number[] | null;
  environment = environment;

  constructor(
    private carrierReportsApiService: CarrierReportsApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cookieService: CookieService,
    private permissionService: PermissionService) { }

  ngOnInit(): void {
    this.getCarrierTraining();
    this.requiredSteps = getRequiredSteps(this.activatedRoute.snapshot.queryParamMap.get('requiredSteps'));
    // this.isFirstStep = isFirstStep(this.requiredSteps, this.Id);
    this.isLastStep = isLastStep(this.requiredSteps, this.Id);
  }

  getCarrierTraining() {
    this.loading = true;
    this.carrierReportsApiService.getCarrierTraining().subscribe(x => {
      this.carrierTrainings = x;
      this.loading = false;
    });
  }

  goToNext(): void {
    if (this.isLastStep) {
      this.goToPortalEvent.emit('Dashboard');
    } else {
      this.taskCompletedEvent.emit(true);
      this.goToNextEvent.emit();
    }
  }

  goToPrevious(): void {
    this.goToPreviousEvent.emit();
  }

  loadTaskCompleted(fromParent: boolean): void { }

  goToCarrierTraining(carrierTraining: CarrierTraining): void {
    if (carrierTraining.HasSimonTraining && this.permissionService.hasOnePermission('ProductRDOptedIn:View')) {
      this.cookieService.delete('is-onboarding', '/');
      // this.router.navigateByUrl('/Portal/ProductRD/Education/ProductTraining/' + carrierTraining.Carrier);
      this.router.navigate(['/Portal/CarriersTraining/ProductTraining'], { queryParams: { searchValue: carrierTraining.Carrier } });
    } else {
      window.open(carrierTraining.TrainingUrl, '_blank');
    }
  }
}
