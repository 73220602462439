import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, from } from 'rxjs';
import { map, switchMap, delay } from 'rxjs/operators';
import { carrierNameByNSCCId } from 'src/app/shared/constants/carrier-names-map.constants';
import { AgentSubAgent } from 'src/app/shared/models/agent.models';
import { Policy } from 'src/app/shared/models/policy.models';
import { SimonApiHolding, SimonApiHoldings } from 'src/app/shared/models/simon-api.models';
import { environment } from 'src/environments/environment';
import { ArcApiService } from './arc-api.service';
// import { tap } from 'lodash';
// import { PolicyDetailsComponent } from 'src/app/portal/components/business/components/policy-details/policy-details.component';

@Injectable({
  providedIn: 'root'
})
export class SimonApiService {

  constructor(private http: HttpClient, private arcApiService: ArcApiService,) { }

  getLifecycleEventsData(): Observable<SimonLifecycleEventResponseModelV1> {
    const payload = {
      'depth': 0,
      'limit': 500000,
      'groupBy': ['AnnuityHoldingLifecycleEvents.event_name'],
      'filters': [{
        'column': 'AnnuityHoldingLifecycleEvents.event_date',
        'operator': '>=',
        'value': '2022-03-18'
      }, {
        'column': 'AnnuityHoldingLifecycleEvents.event_date',
        'operator': '<=',
        'value': '2022-04-17'
      }],
      'offset': 0,
      'sortBy': [],
      'columns': ['AnnuityHoldingLifecycleEvents.event_name', 'AnnuityHoldingLifecycleEvents.annuity_holding_id', 'AnnuityHoldingLifecycleEvents.policy_number', 'AnnuityHoldingLifecycleEvents.cusip', 'AnnuityHoldingLifecycleEvents.event_date', 'AnnuityHoldingLifecycleEvents.notional'],
      'skipCount': false
    };

    return this.http.post<SimonLifecycleEventResponseModelV1>(`${environment.simonApi}/ubertable/query`, payload);
  }

  getAllHoldings(limit: number = 100, offset: number = 0, allHoldings: SimonApiHolding[] = []): Promise<SimonApiHolding[]> {

    return this.getHoldings(offset, limit).toPromise().then(response => {
      if (response.results && response.results.length > 0) {
        allHoldings = allHoldings.concat(response.results);
      }
      if (response.total > (offset + limit)) {
        return this.getAllHoldings(limit, offset += limit, allHoldings);
      }
      return allHoldings;
    });
  }

  getHoldings(offset: number = 0, limit: number = 100): Observable<SimonApiHoldings> {
    return this.http.get<SimonApiHoldings>(`${environment.simonApi}/annuity-holdings/user?offset=${offset}&offset=0&limit=${limit}&limit=0`, { headers: { 'Content-Type': 'application/json' } });
  }

  getAnnuityHolding(policyNumber: string): Observable<SimonApiHolding> {
    return this.http.get<SimonApiHolding>(`${environment.simonApi}/annuity-holdings/user?policyNum=${policyNumber}`, { headers: { 'Content-Type': 'application/json' } });
  }

  getSimonPolicies(reportType: string, allAgents: AgentSubAgent[], limit: number = 100): Observable<Policy[]> {
    if (environment.local || environment.qa) {
      // For testing simulate 10 seconds when getting stubbed results
      return of(this.stubbedUserHoldings)
        .pipe(switchMap(holdings => {
          return this.holdingsToPoliciesMapping(holdings.results, reportType, allAgents);
        }), delay(10000));
    }
    return from(this.getAllHoldings(limit)).pipe(switchMap(holdings => {
      return this.holdingsToPoliciesMapping(holdings, reportType, allAgents);
    }));
    /*return this.http.get<SimonApiHoldings>(`${environment.simonApi}/annuity-holdings/user`, {headers: {'Content-Type': 'application/json'}})
    .pipe(switchMap(holdings => {
      return this.holdingsToPoliciesMapping(holdings, reportType, allAgents);
    }));*/
  }


  holdingsToPoliciesMapping(holdings: SimonApiHolding[], reportType: string, allAgents: AgentSubAgent[]) {
    /*if (environment.qa) {
      holdings = holdings.concat(this.stubbedUserHoldings.results);
    }*/
    const cusipSet = new Set();
    holdings.forEach(h => cusipSet.add(h.cusip));
    return this.arcApiService.getArcProductsByCusip([...cusipSet].join(',')).pipe(map(products => {
      return holdings.filter(h => {
        const fixedClasses = [
          'FixedAnnuity',
          'FixedIndexAnnuity',
          'DeferredIncomeAnnuity',
          'FixedImmediateAnnuity',
          'FixedUniversalLifeInsurance',
          'FixedWholeLifeInsurance',
          'LongTermCare',
          'TermLife',
          'IndexLife'
        ];
        const variableClasses = [
          'VariableAnnuity',
          'VariableImmediateAnnuity',
          'IndexVariableAnnuity',
          'VariableUniversalLifeInsurance',
          'VariableWholeLifeInsurance',
          'RegisteredIndexLinkedAnnuity'
        ];

        // other classes to be used for future holdings from simon
        const otherClasses = [
          'Disability',
          'MarketValueAdjustment',
          'MoneyMarketProduct',
          'OffshoreInvestmentProduct',
          'PensionPlan',
          'RetirementPlan'
        ];
        if (reportType === 'Fixed') {
          // Filter only fixed classes
          return fixedClasses.includes(h.assetClass);
        } else {
          // Filter only variable classes
          return variableClasses.includes(h.assetClass);
        }
      }).map(r => {
        const product = products.find(p => p.Cusip === r.cusip);

        const simonPrimaryAgent = r.agents.find(a => {
          return r.agents.length === 1 || a.role === 'PrimaryAgent';
        });

        const npn = simonPrimaryAgent?.licenses.find(l => {
          return l.name === 'NPN';
        });

        const crd = simonPrimaryAgent?.licenses.find(l => {
          return l.name === 'CRD';
        });

        const policyAgent = allAgents.find(a => {

          // Display Demo Holdings for testing
          // fed2ffc3-53ac-4f65-9868-72b74787c777 for fig devTeam (QA)
          // 6a45ca9c-b1c2-4dc9-8f8a-2c314fb24b70 for FIG APP Test (Prod)
          // if (r.carrierNetworkName === "North South" && (a.AgentId === "fed2ffc3-53ac-4f65-9868-72b74787c777" || a.AgentId === "6a45ca9c-b1c2-4dc9-8f8a-2c314fb24b70")) return true;

          return a.Npn?.trim().toLowerCase() === npn?.number.trim().toLocaleLowerCase()
            || a.CrdNumber?.trim().toLocaleLowerCase() === crd?.number.trim().toLocaleLowerCase();
        });


        return {
          PolicyGuid: null,
          AgentGuid: policyAgent?.AgentId ? policyAgent.AgentId : '',
          FirstName: policyAgent?.FirstName ? policyAgent.FirstName : '',
          LastName: policyAgent?.LastName ? policyAgent.LastName : '',
          PolicyNumber: r.policyNumber,
          Clients: r.parties.filter(p => !p.role.toLowerCase().includes('Beneficiary'.toLowerCase())).map(p => p.name).join(', '), // Filter out Beneficiaries from clients
          Carrier: product?.Business ? product.Business : carrierNameByNSCCId.hasOwnProperty(r.carrierNscc) ? carrierNameByNSCCId[Number(r.carrierNscc)] : r.carrierNetworkName,
          Product: product?.Product ? product.Product : '',
          AnnualizedPremium: null,
          Target: null,
          TotalFirstYearPremium: 0,
          IssuedCpcs: 0,
          Cpcs: 0,
          SubmittedDate: null,
          PolicyStatus: `Contract Status - ${r.contractStatus}`,
          PolicyStatusNote: null,
          PolicyStatusDate: null,
          InforceDate: new Date(r.issueEffectiveDate),
          FaceAmount: null,
          IsVariable: null,
          ActionNeeded: null,
          StatementDate: null,
          CommissionAmount: null,
          SinglePremium: 0,
          CurrentValue: 0,
          LineOfBusinessName: 'Annuity',
          LineOfBusinessId: 1,
          IsPaidPolicy: false,
          Owners: null,
          JoinOwners: null,
          Annuitants: null,
          JointAnnuitants: null,
          Insureds: null,
          CoInsureds: null,
          AssociatedPortalAgentGUIDS: null,
          IssuedPremium: r.totalPremium > 0 ? r.totalPremium : null,
          AccountValue: r.totalContractAmount,
          SurrenderEndDate: r.surrenderChargeExpirationDate ? new Date(r.surrenderChargeExpirationDate) : null,
          SurrenderValue: r.surrenderValue,
          SimonPolicyUrl: r.policyDetailsUri
        };
      });
    }));
  }

  stubbedUserHoldings: any = {
    'total': 0,
    'count': 2,
    'next': 'string',
    'results': [
      {
        'policyNumber': 'FIA50001120FIGDEMOSS',
        'cusip': '2020C312VD',
        'carrierNscc': 'FIN1',
        'carrierNetworkName': 'North South',
        'distributorNscc': 'FIG',
        'assetClass': 'FixedIndexAnnuity',
        'state': 'NY',
        'mva': false,
        'totalContractAmount': 147920.83,
        'surrenderValue': 143175.18,
        'totalPremium': 125000.0,
        'originalInvestmentAmount': 125000.0,
        'issueEffectiveDate': '2021-01-17',
        'allocations': [
          {
            'strategyId': 'S5P',
            'fundPercent': 50.0,
            'maturityDate': '2021-06-28',
            'startDate': '2016-06-29',
            'endDate': '2021-06-28',
            'securityName': 'SH 5 Yr SandP500 PTP',
            'securityType': 'IDX',
            'fundValue': 295841.66
          },
          {
            'strategyId': 'S5P',
            'fundPercent': 25.0,
            'maturityDate': '2021-07-28',
            'startDate': '2016-07-29',
            'endDate': '2021-07-28',
            'securityName': 'SH 5 Yr SandP500 PTP',
            'securityType': 'IDX',
            'fundValue': 147920.83
          },
          {
            'strategyId': 'S5',
            'fundPercent': 25.0,
            'maturityDate': '2021-07-28',
            'startDate': '2016-07-29',
            'endDate': '2021-07-28',
            'securityName': 'SH 5 Yr SandP500',
            'securityType': 'IDX',
            'fundValue': 147920.83
          }
        ],
        'parties': [
          {
            'name': 'HoldingUser31 Rene Demo31',
            'role': 'JointOwner',
            'accountId': '999999999',
            'dateOfBirth': '1936-07-11',
            'primaryOwner': true
          },
          {
            'name': 'HoldingUser32 Emma Demo32',
            'role': 'PrimaryBeneficiary',
            'accountId': '999999999',
            'dateOfBirth': '1938-02-13',
            'primaryOwner': false
          },
          {
            'name': 'Toruno-Steiner Ltd.',
            'role': 'ContingentBeneficiary',
            'accountId': '999999999',
            'accountIdType': 'SocialSecurityNumber',
            'ssnLast4Digits': '9999',
            'primaryOwner': false
          }
        ],
        'agents': [
          {
            'name': 'Fig DevTeam',
            'licenses': [
              {
                'name': 'CRD',
                'number': '3636238',
                'type': 'Securities',
                'state': 'AL'
              },
              {
                'name': 'NPN',
                'number': '798756',
                'type': 'Insurance',
                'state': 'AL'
              }
            ],
            'role': 'PrimaryAgent'
          }
        ],
        'riders': [],
        'irsQualification': 'NonQualified',
        'canSourceRenewalRates': true,
        'canSourceCurrentRates': true,
        'contractStatus': 'Active',
        'policyDetailsUri': '/annuities/lifecycle-contract-details/FIA50001120FIGDEMOSS:FIN1/contract-highlights'
      },
      {
        'cusip': '18815217',
        'policyNumber': 'ABCD',
        'carrierNscc': 'FIN1',
        'carrierNetworkName': 'North South',
        'distributorNscc': 'FIG',
        'assetClass': 'FixedIndexAnnuity',
        'state': 'NY',
        'mva': false,
        'totalContractAmount': 147920.83,
        'surrenderValue': 143175.18,
        'totalPremium': 125000.0,
        'originalInvestmentAmount': 125000.0,
        'issueEffectiveDate': '2021-01-17',
        'allocations': [
          {
            'strategyId': 'S5P',
            'fundPercent': 50.0,
            'maturityDate': '2021-06-28',
            'startDate': '2016-06-29',
            'endDate': '2021-06-28',
            'securityName': 'SH 5 Yr SandP500 PTP',
            'securityType': 'IDX',
            'fundValue': 295841.66
          },
          {
            'strategyId': 'S5P',
            'fundPercent': 25.0,
            'maturityDate': '2021-07-28',
            'startDate': '2016-07-29',
            'endDate': '2021-07-28',
            'securityName': 'SH 5 Yr SandP500 PTP',
            'securityType': 'IDX',
            'fundValue': 147920.83
          },
          {
            'strategyId': 'S5',
            'fundPercent': 25.0,
            'maturityDate': '2021-07-28',
            'startDate': '2016-07-29',
            'endDate': '2021-07-28',
            'securityName': 'SH 5 Yr SandP500',
            'securityType': 'IDX',
            'fundValue': 147920.83
          }
        ],
        'parties': [
          {
            'name': 'HoldingUser31 Rene Demo31',
            'role': 'JointOwner',
            'accountId': '999999999',
            'dateOfBirth': '1936-07-11',
            'primaryOwner': true
          },
          {
            'name': 'HoldingUser32 Emma Demo32',
            'role': 'Annuitant',
            'accountId': '999999999',
            'dateOfBirth': '1938-02-13',
            'primaryOwner': false
          },
          {
            'name': 'Toruno-Steiner Ltd.',
            'role': 'Owner',
            'accountId': '999999999',
            'accountIdType': 'SocialSecurityNumber',
            'ssnLast4Digits': '9999',
            'primaryOwner': false
          }
        ],
        'agents': [
          {
            'name': 'Fig DevTeam',
            'licenses': [
              {
                'name': 'CRD',
                'number': '3636238',
                'type': 'Securities',
                'state': 'AL'
              },
              {
                'name': 'NPN',
                'number': '798756',
                'type': 'Insurance',
                'state': 'AL'
              }
            ],
            'role': 'PrimaryAgent'
          }
        ],
        'riders': [],
        'irsQualification': 'NonQualified',
        'canSourceRenewalRates': true,
        'canSourceCurrentRates': true,
        'contractStatus': 'Active',
        'policyDetailsUri': '/annuities/lifecycle-contract-details/FIA50001120FIGDEMOSS:FIN1/contract-highlights'
      },
      {
        'cusip': '018815217',
        'policyNumber': '777777',
        'carrierNscc': 'FIN1',
        'carrierNetworkName': 'North South',
        'distributorNscc': 'FIG',
        'assetClass': 'FixedIndexAnnuity',
        'state': 'NY',
        'mva': false,
        'totalContractAmount': 147920.83,
        'surrenderValue': 143175.18,
        'totalPremium': 125000.0,
        'originalInvestmentAmount': 125000.0,
        'issueEffectiveDate': '2021-01-17',
        'allocations': [
          {
            'strategyId': 'S5P',
            'fundPercent': 50.0,
            'maturityDate': '2021-06-28',
            'startDate': '2016-06-29',
            'endDate': '2021-06-28',
            'securityName': 'SH 5 Yr SandP500 PTP',
            'securityType': 'IDX',
            'fundValue': 295841.66
          },
          {
            'strategyId': 'S5P',
            'fundPercent': 25.0,
            'maturityDate': '2021-07-28',
            'startDate': '2016-07-29',
            'endDate': '2021-07-28',
            'securityName': 'SH 5 Yr SandP500 PTP',
            'securityType': 'IDX',
            'fundValue': 147920.83
          },
          {
            'strategyId': 'S5',
            'fundPercent': 25.0,
            'maturityDate': '2021-07-28',
            'startDate': '2016-07-29',
            'endDate': '2021-07-28',
            'securityName': 'SH 5 Yr SandP500',
            'securityType': 'IDX',
            'fundValue': 147920.83
          }
        ],
        'parties': [
          {
            'name': 'HoldingUser31 Rene Demo31',
            'role': 'JointOwner',
            'accountId': '999999999',
            'dateOfBirth': '1936-07-11',
            'primaryOwner': true
          },
          {
            'name': 'HoldingUser32 Emma Demo32',
            'role': 'Annuitant',
            'accountId': '999999999',
            'dateOfBirth': '1938-02-13',
            'primaryOwner': false
          },
          {
            'name': 'Toruno-Steiner Ltd.',
            'role': 'Owner',
            'accountId': '999999999',
            'accountIdType': 'SocialSecurityNumber',
            'ssnLast4Digits': '9999',
            'primaryOwner': false
          }
        ],
        'agents': [
          {
            'name': 'Jeff Bucher',
            'licenses': [
              {
                'name': 'NPN',
                'number': '7366526',
                'type': 'Insurance',
                'state': 'AL'
              }
            ],
            'role': {
              'value': 'PrimaryAgent'
            }
          },
          {
            'name': 'Fig DevTeam',
            'licenses': [
              {
                'name': 'CRD',
                'number': '3636238',
                'type': 'Securities',
                'state': 'AL'
              },
              {
                'name': 'NPN',
                'number': '798756',
                'type': 'Insurance',
                'state': 'AL'
              }
            ],
            'role': 'SecondaryAgent'
          }
        ],
        'riders': [],
        'irsQualification': 'NonQualified',
        'canSourceRenewalRates': true,
        'canSourceCurrentRates': true,
        'contractStatus': 'Active',
        'policyDetailsUri': '/annuities/lifecycle-contract-details/FIA50001120FIGDEMOSS:FIN1/contract-highlights'
      },
      {
        'cusip': '55',
        'policyNumber': '5678910',
        'carrierNscc': '1777',
        'carrierNetworkName': 'QA Error: Wrong Carrier Name',
        'distributorNscc': 'FIG',
        'assetClass': 'FixedIndexAnnuity',
        'state': 'NY',
        'mva': false,
        'totalContractAmount': 147920.83,
        'surrenderValue': 143175.18,
        'totalPremium': 125000.0,
        'originalInvestmentAmount': 125000.0,
        'issueEffectiveDate': '2021-01-17',
        'allocations': [
          {
            'strategyId': 'S5P',
            'fundPercent': 50.0,
            'maturityDate': '2021-06-28',
            'startDate': '2016-06-29',
            'endDate': '2021-06-28',
            'securityName': 'SH 5 Yr SandP500 PTP',
            'securityType': 'IDX',
            'fundValue': 295841.66
          },
          {
            'strategyId': 'S5P',
            'fundPercent': 25.0,
            'maturityDate': '2021-07-28',
            'startDate': '2016-07-29',
            'endDate': '2021-07-28',
            'securityName': 'SH 5 Yr SandP500 PTP',
            'securityType': 'IDX',
            'fundValue': 147920.83
          },
          {
            'strategyId': 'S5',
            'fundPercent': 25.0,
            'maturityDate': '2021-07-28',
            'startDate': '2016-07-29',
            'endDate': '2021-07-28',
            'securityName': 'SH 5 Yr SandP500',
            'securityType': 'IDX',
            'fundValue': 147920.83
          }
        ],
        'parties': [
          {
            'name': 'HoldingUser31 Rene Demo31',
            'role': 'JointOwner',
            'accountId': '999999999',
            'dateOfBirth': '1936-07-11',
            'primaryOwner': true
          },
          {
            'name': 'HoldingUser32 Emma Demo32',
            'role': 'Annuitant',
            'accountId': '999999999',
            'dateOfBirth': '1938-02-13',
            'primaryOwner': false
          },
          {
            'name': 'Toruno-Steiner Ltd.',
            'role': 'Owner',
            'accountId': '999999999',
            'accountIdType': 'SocialSecurityNumber',
            'ssnLast4Digits': '9999',
            'primaryOwner': false
          }
        ],
        'agents': [
          {
            'name': 'Fig DevTeam',
            'licenses': [
              {
                'name': 'CRD',
                'number': '3636238',
                'type': 'Securities',
                'state': 'AL'
              },
              {
                'name': 'NPN',
                'number': '798756',
                'type': 'Insurance',
                'state': 'AL'
              }
            ],
            'role': 'PrimaryAgent'
          }
        ],
        'riders': [],
        'irsQualification': 'NonQualified',
        'canSourceRenewalRates': true,
        'canSourceCurrentRates': true,
        'contractStatus': 'Active',
        'policyDetailsUri': '/annuities/lifecycle-contract-details/FIA50001120FIGDEMOSS:FIN1/contract-highlights'
      }
    ]
  }
}


export interface SimonLifecycleEventResponseModelV1 {
  count: number;
  offset: number;
  requesterId: string;
  result: {
    childrenCount: number;
    groupValue: 'Strategy Renewal' | 'Policy Anniversary' | 'Surr. Charge Exp.'; // this is an assumption that Simon
    groupedBy: string;
    data: unknown;
  }
}