<div class="flex flex-col flex-grow w-full h-0 h-full overflow-auto">
    <section id="copyApplicationModal" class="flex-none dialog-toolbar">
        <button class="absolute top-0 right-0 m-20 icon-button is-primary is-inverted" attr.aria-label="Close"
            [mat-dialog-close] type="button">
            <i class="material-icons">close</i>
        </button>
        <h2 class="mb-20 text-lg heading">Copy A Firelight E-Application</h2>
    </section>
    <div class="relative p-20 m-auto" *ngIf="isLoading || isSubmitting">
        <div class="loader-wrap">
            <div class="loader-sm is-blue"></div>
          </div>
        <p *ngIf="isSubmitting" class="m-10 text-base leading-loose text-center">Uploading application. This may take a moment...</p>
    </div>
    <div [@fadeIn] class="flex flex-col items-center my-auto text-center" *ngIf="isError && !isSubmitting">
        <span class="flex items-center justify-center w-64 h-64 m-10 rounded-full bg-orange-lightest text-orange">
          <i class="material-icons mi-36">warning</i>
        </span>
        <p class="m-10 leading-loose">
            {{errorMessage}}
        </p>
      </div>
    <section *ngIf="!isLoading&& !isError && !isSubmitting" class="flex flex-col p-20 overflow-auto lg:p-30 dialog-content">
        <p class="mx-10 text-base">
            To submit a electronic application to Firelight, please complete the information below and click submit.
        </p>
        <form class="flex flex-wrap items-start justify-start mt-10" name="firelightApp"
            (ngSubmit)="submitEApplication()">
                <div class="flex w-full sm:w-1/2">
                    <div class="flex flex-col flex-grow mx-10 mt-20">
                        <label class="mb-10 text-sm font-semibold text-gray">E-Application Title</label>
                        <input class="h-40 px-10 border border-solid input is-inverted border-gray-light" type="text"
                            name="applicationTitle" [(ngModel)]="activityName"
                            aria-label="E-Application Title" required minlength="1" />
                    </div>
                </div>   
                <div class="flex w-full sm:w-1/2">
                    <div class="flex flex-col flex-grow mx-10 mt-20">
                        <label class="mb-10 text-sm font-semibold text-gray">Carrier</label>
                        <mat-select [disableOptionCentering]="true" (selectionChange)="setProducts()" name="carrier" [(value)]="carrier"
                            class="h-40 px-10 m-0 border border-solid select is-inverted md-no-underline border-gray-light"
                            required placeholder="Select Carrier" aria-label="Select Carrier">
                            <mat-option *ngFor="let c of carriers" [value]="c"
                                 required>
                                {{c.CarrierName}}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>            
                <div class="flex w-full sm:w-1/2">
                    <div class="flex flex-col flex-grow mx-10 mt-20">
                        <label class="mb-10 text-sm font-semibold text-gray">State</label>
                        <mat-select [disableOptionCentering]="true" (selectionChange)="setProducts()" name="state" [(value)]="state"
                            class="h-40 px-10 m-0 border border-solid select is-inverted md-no-underline border-gray-light"
                            required placeholder="Select State" aria-label="Select State">
                            <mat-option *ngFor="let s of firelightStates" [value]="s"
                                 required>
                                {{stateShortNameByLongName[s.StateAbbrev]}}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>            
                <div class="flex w-full sm:w-1/2">
                    <div class="flex flex-col flex-grow mx-10 mt-20">
                        <label class="mb-10 text-sm font-semibold text-gray">Product</label>
                        <mat-select [disabled]="!carrier || !state" [disableOptionCentering]="true" name="product" [(value)]="product"
                            class="h-40 px-10 m-0 border border-solid select is-inverted md-no-underline border-gray-light"
                            required placeholder="Select Product" aria-label="Select Product">
                            <mat-option *ngFor="let p of products" [value]="p"
                                required>
                                {{p.Product}}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>         
        </form>
    </section>
    <section class="flex flex-none p-10 border-t border-solid lg:p-20 border-gray-lighter">
        <button class="m-10 ml-auto button is-primary is-lg p1-20 pr-25" type="submit"
            name="submitApplication" aria-label="Submit E-Application"
            (click)="submitEApplication();"
            [disabled]="!activityName || !carrier || !product || !state || isSubmitting || isError || isLoading ">
            <span class="flex items-center justify-center">
                <i class="mr-5 fig-24px fig-send"></i>
                <span>Start{{ isSubmitting ? 'ing' : ''}} E-Application</span>
            </span>
        </button>   
    </section>
</div>



