import { Component, OnInit, Renderer2, Input } from '@angular/core';

@Component({
  selector: 'app-dark-mode-toggle',
  templateUrl: './dark-mode-toggle.component.html',
  styleUrls: ['./dark-mode-toggle.component.scss']
})
export class DarkModeToggleComponent implements OnInit {
  darkModeStorageName: string = 'preferDarkMode';
  darkMode: boolean = false;
  @Input() labelText?: string;

  constructor(
    private _renderer: Renderer2) { }

  ngOnInit(): void {
    this.checkDarkMode() ? this.setDarkMode() : this.removeDarkMode();
  }

  checkDarkMode(): boolean {
    const preferDarkMode = localStorage.getItem(this.darkModeStorageName)?.toString();
    return preferDarkMode === 'true' ? true : false;
  }

  setDarkMode(): void {
    this.darkMode = true;
    const html = document.querySelector('html');
    if (!html) return;
    this._renderer.addClass(html, 'dark');
    localStorage.setItem(this.darkModeStorageName, JSON.stringify(this.darkMode));
  }

  removeDarkMode(): void {
    this.darkMode = false;
    const html = document.querySelector('html');
    if (!html) return;
    this._renderer.removeClass(html, 'dark');
    localStorage.removeItem(this.darkModeStorageName);
  }

  toggleDarkMode(): void {
    this.darkMode = !this.darkMode;
    if (this.darkMode) this.setDarkMode();
    else this.removeDarkMode();
  }

}
