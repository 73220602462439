import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionService } from 'src/app/core/services/auth/permission.service';

@Directive({
  selector: '[hideIfOne]'
})
export class HideIfOneDirective {

  private hasView = false;

  constructor(private templateRef:TemplateRef<any>, private viewContainerRef:ViewContainerRef, private permissionService:PermissionService) { }

  @Input() set hideIfOne(permissions:string) {

    const isAuthorized = this.permissionService.hasOnePermission(permissions);
    if(!isAuthorized && !this.hasView){
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (isAuthorized && this.hasView) {
      this.viewContainerRef.clear();
      this.hasView = false;
    }
  }
}

