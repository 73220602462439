<div class="flex flex-col gap-y-20" [ngClass]="{'py-20 border-t border-solid border-gray-lighter': hasSimonOptIn}">
    <div class="flex flex-col w-full lg:w-1/2" [@fadeIn]="!!categories.length" *ngIf="!hasSimonOptIn">
        <h3 class="mb-10 text-lg font-bold">Explore{{!!userGroup || !!onBehalfOfUserGroup ? '': ' FIG'}}</h3>
        <p class="text-sm leading-8">
            Our verticals are designed to grow your business by targeting every facet of your
            practice.<br />
            Access platforms, programs, and tools laser-focused on the building blocks that make up your
            business.
        </p>
    </div>

    <div class="grid flex-grow grid-cols-4 gap-x-20 gap-y-20" [@fadeIn]="!!categories.length" *ngIf="hasSimonOptIn">
        <div *ngFor="let block of categories; let index = index; let first = first; let last = last"
            [ngClass]="{'lg:col-span-2 col-span-4': block.Id === 'recvZM30AUpnvcoRP', 'lg:col-span-1 col-span-2' : block.Id !== 'recPBIrLIAEGOfWgu'}"
            class="flex flex-col w-full min-h-220">

            <!-- Product R&D Tile -->
            <div id="categoryCard{{index}}" *ngIf="block.Id === 'recvZM30AUpnvcoRP'"
                class="relative flex h-full overflow-hidden transition duration-150 card hover:shadow-lg">
                <a attr.aria-label="Explore the Platform" [amplitudeEvent]="'Dashboard Verticals: ' + block.Fields.Name"
                    routerLink="/Portal/ProductRDPreview"
                    class="flex flex-col flex-grow p-20 text-white bg-center bg-no-repeat bg-cover cursor-pointer"
                    style="background: url({{block.Fields.BackgroundUrl}})">

                    <div class="flex items-start m-10">
                        <div class="flex flex-col flex-grow">
                            <div class="flex flex-col mb-10 md:mb-20">
                                <div class="flex items-start justify-between">
                                    <div class="flex flex-col">
                                        <p
                                            class="relative text-xs font-semibold tracking-widest uppercase text-blue-lighter">
                                            Introducing
                                        </p>
                                        <div class="flex flex-row flex-wrap items-center mb-20">
                                            <img class="w-auto mb-10 mr-20 h-36 xl:mb-0"
                                                src="./assets/images/icons/quinci-logo-light.svg">
                                            <img class="w-auto h-24 mr-auto"
                                                src="./assets/images/icons/pw-simon-dark.svg">
                                        </div>
                                    </div>
                                    <!-- <app-countdown-timer [@slideInOut]="loaded" *hideIfOne="'ProductRDOptedIn:View'">
                                    </app-countdown-timer> -->
                                </div>
                                <p class="" [innerHtml]="block.Fields.Description"
                                    *showIfOne="'ProductRDOptedIn:View'"></p>
                                <p class="mt-20 md:mt-30" *hideIfOne="'ProductRDOptedIn:View'">
                                    We're excited to announce the full release of our brand-new Quinci Platform, an
                                    all-in-one platform bringing insurance and investments together, allowing financial
                                    professionals to fully manage everything from training, product selection and
                                    analysis, to order entry and post-sale management.
                                </p>
                            </div>

                            <div class="flex flex-wrap -mx-10 item-center">
                                <ng-container *showIfOne="'ProductRDOptedIn:View'">
                                    <div class="flex items-center flex-none m-10 mr-auto"
                                        *ngIf="!!block.Fields.Tags && !!block.Fields.Tags.length">
                                        <span
                                            class="flex items-center justify-center text-xs font-bold text-center uppercase rounded-full"
                                            *ngFor="let tag of block.Fields.Tags; let last = last">{{tag}}<span
                                                *ngIf="!last" class="mx-10">|</span>
                                        </span>
                                    </div>
                                </ng-container>
                                <button mat-button class="flex-none px-20 m-10 mr-auto rounded-full button is-primary">
                                    <span class="flex items-center justify-center">
                                        <span>Learn More</span>
                                        <i class="ml-10 material-icons mi-18">east</i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </a>
            </div>

            <!--Not Product R&D Tile  -->
            <div id="categoryCard{{index}}" *ngIf="block.Id !== 'recvZM30AUpnvcoRP'"
                class="flex h-full overflow-hidden transition duration-150 bg-center bg-no-repeat bg-cover card hover:shadow-lg">
                <a *ngIf="block.Id !== 'recPBIrLIAEGOfWgu'" attr.aria-label="Explore the Platform"
                    [amplitudeEvent]="'Dashboard Verticals: ' + block.Fields.Name" routerLink="/Portal/FigPlatform"
                    [fragment]="block.Id"
                    class="flex flex-col flex-grow hover:no-underline hover:text-gray-dark focus:no-underline focus:text-gray-dark"
                    [class]="filterTilesByCategory(block.Fields.Name).length ? 'cursor-pointer' : 'cursor-default'">
                    <div class="flex flex-col bg-center bg-no-repeat bg-cover"
                        [class]="filterTilesByCategory(block.Fields.Name).length ? 'h-96' : 'flex-grow'"
                        style="background: url({{block.Fields.BackgroundUrl}})">
                        <div class="flex-grow-0 w-full h-48 mt-auto bg-bottom bg-no-repeat bg-cover"
                            style="background: url('./assets/images/wave-sm-flip.svg'); margin-bottom:-2px"
                            *ngIf="filterTilesByCategory(block.Fields.Name).length"></div>
                    </div>
                    <div class="flex-grow p-10 bg-white lg:p-20"
                        *ngIf="!!filterTilesByCategory(block.Fields.Name).length">
                        <span
                            class="z-10 flex flex-row items-center justify-center w-48 h-48 mb-20 mr-auto -mt-64 rounded-full"
                            [class]="block.Fields.BackgroundColor + ' ' + block.Fields.ForegroundColor">
                            <i class="material-icons"
                                *ngIf="block.Fields.Name !== 'Product R&D'">{{block.Fields.Icon}}</i>
                            <i class="fig-24px fig-box-open" *ngIf="block.Fields.Name === 'Product R&D'"></i>
                        </span>
                        <h2 class="m-10 mb-5 font-bold text-left" [innerHtml]="block.Fields.Name"></h2>
                        <p class="m-10 mt-0 text-left" [innerHtml]="block.Fields.Description"></p>
                    </div>
                </a>
            </div>
        </div>
    </div>

    <div class="grid flex-grow grid-cols-2 lg:grid-cols-3 sm:grid-cols-2 gap-x-20 gap-y-20"
        [@fadeIn]="!!categories.length" *ngIf="!hasSimonOptIn">
        <ng-container *ngFor="let block of categories; let index = index; let first = first">
            <!-- <div class="flex flex-col flex-grow w-full p-10 pl-0 sm:w-1/2 lg:w-2/5 min-h-220"
                *ngIf="environment !== environmentEnum.prod && block.Id === 'recPBIrLIAEGOfWgu'">
                <div id="categoryCard{{index}}"
                    class="flex flex-row flex-grow overflow-hidden transition duration-150 bg-center bg-no-repeat bg-cover card hover:shadow-lg">
                    <a attr.aria-label="Explore the Platform"
                        [amplitudeEvent]="'Dashboard Verticals: ' + block.Fields.Name" routerLink="/Portal/FigPlatform"
                        [fragment]="block.Id"
                        class="flex flex-row flex-grow hover:no-underline hover:text-gray-dark focus:no-underline focus:text-gray-dark"
                        [class]="filterTilesByCategory(block.Fields.Name).length ? 'cursor-pointer' : 'cursor-default'">
                        <div class="flex-col flex-grow p-10 bg-white lg:p-20"
                            *ngIf="!!filterTilesByCategory(block.Fields.Name).length">
                            <div class="flex flex-row items-center mb-10 md:mb-20">
                                <span class="z-10 flex flex-row items-center justify-center w-48 h-48 rounded-full"
                                    [class]="block.Fields.BackgroundColor + ' ' + block.Fields.ForegroundColor">
                                    <i class="material-icons">{{block.Fields.Icon}}</i>
                                </span>
                                <div>
                                    <label class="mb-5 ml-10 text-sm uppercase text-gray">Introducing</label>
                                    <h2 class="ml-10 font-bold text-left" [innerHtml]="block.Fields.Name"></h2>
                                </div>
                                <app-simon-powered class="inline-block m-10 ml-auto"></app-simon-powered>
                            </div>
                            <p class="m-10 mb-10 text-left md:mb-20 text-gray-dark"
                                [innerHtml]="block.Fields.Description"></p>
                            <div class="flex flex-row items-center mx-10"
                                *ngIf="!!tempTags && !!tempTags.length">
                                <span
                                    class="flex justify-center items-center text=center py-5 text-xs font-bold rounded-full uppercase italics text-gray"
                                    *ngFor="let newTag of tempTags; let last = last">{{newTag}}<span *ngIf="!last"
                                        class="mx-10">|</span>
                                </span>                                
                            </div>
                        </div>
                        <div class="flex flex-row w-1/5 bg-center bg-no-repeat bg-cover"
                            style="background: url({{block.Fields.BackgroundUrl}})">
                            <div class="flex-grow-0 w-48 h-full mr-auto bg-left bg-no-repeat bg-cover"
                                style="background: url('./assets/images/sm-wave-vertical.svg')"></div>
                        </div>
                    </a>
                </div>
            </div> -->
            <div id="categoryCard{{index}}"
                class="flex flex-row flex-grow overflow-hidden transition duration-150 min-h-220 card hover:shadow-lg"
                [class]="index === categories.length - 1 ? 'pr-0' : !!first ? 'pl-0' : ''">
                <a attr.aria-label="Explore the Platform" [amplitudeEvent]="'Dashboard Verticals: ' + block.Fields.Name"
                    routerLink="/Portal/FigPlatform" [fragment]="block.Id"
                    class="flex flex-col flex-grow hover:no-underline hover:text-gray-dark focus:no-underline focus:text-gray-dark"
                    [class]="filterTilesByCategory(block.Fields.Name).length ? 'cursor-pointer' : 'cursor-default'">
                    <div class="flex flex-col bg-center bg-no-repeat bg-cover"
                        [class]="filterTilesByCategory(block.Fields.Name).length ? 'h-96' : 'flex-grow'"
                        style="background: url({{block.Fields.BackgroundUrl}})">
                        <div class="flex-grow-0 w-full h-48 mt-auto bg-bottom bg-no-repeat bg-cover"
                            style="background: url('./assets/images/wave-sm-flip.svg'); margin-bottom:-2px"
                            *ngIf="filterTilesByCategory(block.Fields.Name).length"></div>
                    </div>
                    <div class="flex-grow p-10 bg-white lg:p-20"
                        *ngIf="!!filterTilesByCategory(block.Fields.Name).length">
                        <span
                            class="z-10 flex flex-row items-center justify-center w-48 h-48 mb-20 mr-auto -mt-64 rounded-full"
                            [class]="block.Fields.BackgroundColor + ' ' + block.Fields.ForegroundColor">
                            <i class="material-icons"
                                *ngIf="block.Fields.Name !== 'Product R&D'">{{block.Fields.Icon}}</i>
                            <i class="fig-24px fig-box-open" *ngIf="block.Fields.Name === 'Product R&D'"></i>
                        </span>
                        <h2 class="m-10 mb-5 font-bold text-left" [innerHtml]="block.Fields.Name"></h2>
                        <p class="m-10 mt-0 text-left" [innerHtml]="block.Fields.Description"></p>
                    </div>
                </a>
            </div>
        </ng-container>
    </div>
    <!-- <div class="relative flex flex-row flex-wrap flex-grow pt-20 loader-wrap md:pt-40" *ngIf="loading" [@fadeIn]>
    <div class="loader-sm is-blue"></div>
</div> -->