<div class="flex flex-col w-full h-full p-20 card md:p-30">
    <h3 class="mb-20 text-lg font-bold">Upcoming Trips &amp; Events</h3>
    <div class="overflow-auto sm:max-h-320">
        <div class="relative flex flex-col overflow-hidden rounded-lg" [class]="!last ? 'mb-20': ''"
            *ngFor="let event of upcomingEvents; let last = last" 
            [matTooltip]="(!!event.RegistrationUrl || !!event.InterestUrl 
            ? 'Register for' 
            : !!event.AttendUrl 
                ? 'Attend' : 'View') + ' ' + event.Title" 
            matTooltipPosition="above" >
            <!-- TODO: need a better method of handling if there are multiple external links -->
            <a class="absolute top-0 left-0 w-full h-full opacity-0" [href]="event.ViewNowUrl" 
            target="_blank" attr.aria-label="View {{event.Title}}" rel="noopener" *ngIf="event.ViewNowUrl"></a>
            <a class="absolute top-0 left-0 w-full h-full opacity-0" [href]="event.AttendUrl" 
            target="_blank" attr.aria-label="Attend {{event.Title}}" rel="noopener" *ngIf="event.AttendUrl"></a>
            <a class="absolute top-0 left-0 w-full h-full opacity-0" [href]="event.RegistrationUrl" 
            target="_blank" attr.aria-label="Register for {{event.Title}}" rel="noopener" *ngIf="event.RegistrationUrl"></a>
            <a class="absolute top-0 left-0 w-full h-full opacity-0" [href]="event.InterestUrl" 
            [target]="event.InterestUrl.includes('Portal/') ? '_self' : '_blank'" attr.aria-label="Show Interest in {{event.Title}}" *ngIf="event.InterestUrl"></a>
            <!-- end TODO -->
            <div class="flex flex-row h-full">
                <div class="flex flex-col items-start justify-start w-3/5 p-20 pr-0 bg-gray-lightest">
                    <h4 class="mb-5 text-sm font-bold">{{event.Title}}</h4>
                    <p class="text-xs" [innerHtml]="event.Location" *ngIf="event.Location"></p>
                    <p class="text-xs" [innerHtml]="event.Date"></p>
                </div>
                <div class="flex flex-row w-2/5 bg-center bg-no-repeat bg-cover"
                    style="background:url('{{ event.Img ? event.Img : 'https://images.unsplash.com/photo-1493612276216-ee3925520721?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2200&q=80'}}')">
                    <div class="flex-grow w-48 h-full mr-auto bg-left bg-no-repeat"
                        style="background: url('./assets/images/sm-wave-vertical-gray-lightest.svg')"></div>
                </div>
            </div>
        </div>
        <div class="bg-gray-lighter rounded-lg p-10" *ngIf="!upcomingEvents.length">
            <p class="text-sm italic">No upcoming events. Check back soon! </p>
        </div>
    </div>
</div>