<section *ngIf="!isLoading" [@fadeIn]>
  <div *ngIf="pendingRenewal">
    <p class="mt-10 mb-0"><em>Your enrollment In RetireUpPro will expire soon, please click the button below to view your renewal options.</em></p>
    <button class="button is-primary mt-20 md:mt-30 mr-20"
      (click)="openSurveyRenewalDialog()">
      <span>
        View My Renewal
      </span>
    </button>
  </div>
  
  <div class="flex flex-row" *ngIf="!pendingRenewal && !hasPastRenewals">
    <div *ngFor="let survey of submitted; let index = index">
      <button class="button is-primary mt-20 md:mt-30 mr-20"
        (click)="!!survey && !survey.isEnrolled ? openEnrollmentModal(survey.surveyId) : ''" [disabled]="survey.isEnrolled">
        <span *ngIf="!!survey && !survey.isEnrolled">
          Enroll Today
        </span>
        <span  matTooltip="RetireUp enrollment form already submitted" matTooltipPosition="above" 
         *ngIf="!!survey && survey.isEnrolled">Submitted</span>
      </button>
      <button class="button is-primary mt-20 md:mt-30 mr-20" (click)="openPurchaseDialog()"
        *ngIf="!!survey && survey.isEnrolled && !!resource.PurchasePageData" [disabled]="!!purchased && purchased.Complete">
        <span *ngIf="!purchased">
          Purchase
        </span>
        <span *ngIf="!!purchased && purchased.Complete">
          Purchased On {{purchased?.PurchaseDate}}
        </span>
      </button>  
    </div>
  </div>
  <button [@fadeIn] class="button is-primary mt-20 md:mt-30 mr-20" (click)="openDocusignLink(purchased.DocusignUrl)"
    *ngIf="!!purchased && purchased.Complete && purchased.DocusignUrl">
    <span>
      Sign RetireUp Pro Agreement
    </span>
  </button>
  
  <button class="button is-primary mt-20 md:mt-30 mr-20" (click)="activateRetireUpSso()"
    *showIfOne="'RetireUp:Access|RetireUpFree:Access'">
    <span>
      Log Into RetireUp Pro
    </span>
  </button>  
</section>