import { AgentGuidList, AgentSubAgent, AgentSubEntity, AgentCommissionGroupList } from 'src/app/shared/models/agent.models';
import { Component, OnDestroy, OnInit, HostListener, ViewChild } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { fadeIn } from 'src/app/animations';
import { forkJoin, Observable, fromEvent, ReplaySubject } from 'rxjs';
import { getAverage } from 'src/app/shared/helpers/average.helper';
import { getSum } from 'src/app/shared/helpers/sum.helper';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { orderBy, Many } from 'lodash';
import { InformalPolicy, InformalPolicyTable, Policy, PolicyLob, PolicyTable, PolicyTableHeader } from 'src/app/shared/models/policy.models';
import { informalPolicyTable, policyLobs, policyTable } from 'src/app/shared/constants/policyList.constants';
import { PolicyListStoreService } from 'src/app/core/services/stores/policy-list-store.service';
import { RequirementsComponent } from '../../business/components/requirements/requirements.component';
import { ReportsApiService } from 'src/app/core/services/http/reports-api.service';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { User } from 'src/app/shared/models/user.models';
import { takeUntil } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints, MediaMatcher } from '@angular/cdk/layout';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { sidenavConfig } from 'src/app/shared/models/sidenav-config.model';
import { searchFilter } from 'src/app/shared/helpers/search.helper';
import { Router } from '@angular/router';
import { downloadFile } from 'src/app/shared/helpers/filedownloader.helper';


@Component({
  selector: 'app-policy-list',
  templateUrl: './policy-list.component.html',
  animations: [fadeIn]
})
export class PolicyListComponent implements OnInit, OnDestroy {
  @ViewChild('businessSidenav') businessSidenav!: MatSidenav;
  allLobsSelected: boolean = false;
  allAgentsSelected: boolean = false;
  allInforcePolicies: Policy[] = [];
  allPendingPolicies: Policy[] = [];
  allInformalPolicies: InformalPolicy[] = [];
  agentGuidList: AgentGuidList = {
    AgentIds: '',
    LobIds: [],
    StartDate: null,
    EndDate: null,
    IncludeUser: false,
    CanSeeAgentCommissions: false
  }
  agents: AgentSubAgent[] = [];
  subEntities!:AgentSubEntity[];
  isClearingPolicies!: boolean;
  columnName: string[] = ['ActionNeeded', 'PolicyStatusDate'];
  currentUser!: any;
  defaultHeaders: PolicyTableHeader[] = [...policyTable.Header]
  exportingList: boolean = false;
  getAverage = getAverage;
  getSum = getSum;
  loadingRows: boolean = false;
  loadingModal: boolean = false;
  lobs: PolicyLob[] = policyLobs;
  orderBy = orderBy;
  queryStartDate!: Date;
  queryEndDate!: Date;
  reverseOrder: boolean = false;
  selectedAgentIds: string[] = [];
  selectedSubEntities: string[] = [];
  selectedLobIds: number[] = [];
  searchText: string = '';
  selectedReportType!: string;
  selectedDate!: any;
  table: PolicyTable = policyTable;
  user!: User;
  onBehalfOfUser!: User|null;
  sidenavConfig: sidenavConfig;
  informalPolicies!:InformalPolicy[];
  informalTable:InformalPolicyTable = informalPolicyTable;

  constructor(
    private cookieService: CookieService,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private policyListStore: PolicyListStoreService,
    private reportsApiService: ReportsApiService,
    private router: Router,
    private sessionStore: SessionStoreService,
    private _snackBar: MatSnackBar) {
    this.sidenavConfig = {
      IsOpened: true,
      Mode: 'side',
    };
  }

  ngOnInit(): void {
    this.breakpointObserver.observe([
      Breakpoints.WebLandscape,
    ]).subscribe(result => {
      if (result.matches) {
        this.sidenavConfig.IsOpened = true;
        this.sidenavConfig.Mode = 'side';
      } else {
        this.sidenavConfig.IsOpened = false;
        this.sidenavConfig.Mode = 'over';
      }
    });

    this.loadingRows = true;

    this.user = this.sessionStore.User;
    this.onBehalfOfUser = this.sessionStore.OnBehalfOfUser;
    this.currentUser = { ...this.user, ...{ selected: true } };
    this.getFilters();
    this.generateTableHeaders();
    this.reportsApiService.getSubAgents()
      .subscribe((res) => {
        this.createAgentList(res);
        this.getAllPolicies(false);
      });
    this.reportsApiService.getSubEntities()
      .subscribe((subEntities) => {
        this.setSubEntityList(subEntities);
      });
    this.getLobList();
    this.reportsApiService.getInformalPolicies()
      .subscribe(policies => {
        this.allInformalPolicies = policies;
        this.informalPolicies = policies;
        this.informalTable.Body = policies;
      }, (error) => {
        if (this.selectedReportType === 'Informal') {
          const message = 'Sorry, an error has occurred. Please try again later.';
          const toastClass = 'is-error';
          this._snackBar.open(message, 'Close', {
            panelClass: [toastClass]
          });
        }
      }, () => {
        if (this.selectedReportType === 'Informal') {

          this.loadingRows = false;
        }
      });
  }

  ngOnDestroy() {
    this.table.Body = [];
  }

  agentMatch(value: Policy): boolean {
    let match = false;
    if (this.selectedAgentIds && this.selectedAgentIds.length > 0) {
      match = this.selectedAgentIds.some(function (a) {
        return (a.replace(/[{}]/g, '').toLowerCase() === value.AgentGuid.replace(/[{}]/g, '').toLowerCase())
          || (value.AssociatedPortalAgentGUIDS != null ? value.AssociatedPortalAgentGUIDS.split(',').some(function (aa: any) {
            return a.replace(/[{}]/g, '').toLowerCase() === aa.replace(/[{}]/g, '').toLowerCase();
          }) : false);
      });
    }
    return match;
  }

  areLengthsEqual(array1: any[], array2: any[]): boolean {
    return array1.length == array2.length;
  }

  clearSearch() {
    this.searchText = '';
    this.setPolicyListTable();
  }

  setSubEntityList(subEntities:AgentSubEntity[]){
    if (subEntities.length){
      this.subEntities = subEntities.map(subEntity => {
        return {...subEntity, Selected: false};
      });
    }
  }

  createAgentList(agents?: AgentSubAgent[]) {
    if (agents?.length) {
      this.agents = agents.map(agent => {
        return Object.assign(agent, {
          Selected: this.selectedAgentIds.includes(agent.ChildAgentGuid!) ? true : false
        });
      });
      this.agents = orderBy(this.agents, 'FirstName');
    }

    this.agents.unshift({
      FirstName: this.onBehalfOfUser ? this.onBehalfOfUser.FirstName : this.user.FirstName,
      LastName: this.onBehalfOfUser ? this.onBehalfOfUser.LastName : this.user.LastName,
      AgentId: this.user.AgentArcGuid!,
      CurrentUserTag: '(Me)',
      ChildAgentGuid: this.user.AgentArcGuid ?? null,
      ParentAgentGuid: null,
      Selected: this.selectedAgentIds.includes(this.user.AgentArcGuid!) ? true : false
    });

    this.allAgentsSelected = this.areLengthsEqual(this.selectedAgentIds, this.agents);
    return this.agents;
  }

  dateMatch(value: Policy): boolean {
    return (new Date(value.InforceDate!) > new Date(this.selectedDate.StartDate) && (new Date(value.InforceDate!)) < new Date(this.selectedDate.EndDate));
  }

  exportReport(selectedReportType: string) {
    if (this.table.Body.length === 0) {
      const message = 'No policies. Please select different filters.';
      const toastClass = 'is-default';

      this._snackBar.open(message, 'Close', {
        panelClass: [toastClass]
      });
    }
    this.exportingList = true;
    const agentGuidList = Object.assign(this.agentGuidList, {
      AgentIds: this.selectedAgentIds.join(','),
      LobIds: this.selectedLobIds,
      StartDate: this.selectedDate.StartDate,
      EndDate: this.selectedDate.EndDate
    });
    if (selectedReportType === 'Pending') {
      this.reportsApiService.exportPendingPolicies(agentGuidList)
        .subscribe(
          (res) => downloadFile(res, 'PolicyData.xlsx'),
          (error) => {
            const message = 'Sorry, an error has occurred. Please try again later.';
            const toastClass = 'is-error';
            this._snackBar.open(message, 'Close', {
              panelClass: [toastClass]
            });
          }
        ).add(() => this.exportingList = false);
    } else if (this.selectedReportType === 'Inforce') {
      this.reportsApiService.exportInforcePolicies(agentGuidList)
        .subscribe(
          (res) => downloadFile(res, 'PolicyData.xlsx'),
          (error) => {
            const message = 'Sorry, an error has occurred. Please try again later.';
            const toastClass = 'is-error';
            this._snackBar.open(message, 'Close', {
              panelClass: [toastClass]
            });
          }
        ).add(() => this.exportingList = false);
    } else if (selectedReportType === 'Informal') {
      this.reportsApiService.exportInformalPolicies().subscribe(
        (res) => downloadFile(res, 'PolicyData.xlsx'),
        (error) => {
          const message = 'Sorry, an error has occurred. Please try again later.';
          const toastClass = 'is-error';
          this._snackBar.open(message, 'Close', {
            panelClass: [toastClass]
          });
          this.exportingList = false;
        }
      ).add(() => this.exportingList = false);
    }

  }

  generateTableHeaders(selectedReportType?: string) {
    this.selectedReportType = selectedReportType || this.selectedReportType;
    this.table.Header = this.defaultHeaders.filter(cell => {
      return cell.ReportTypes.includes(this.selectedReportType);
    });
  }

  getCommissionGroupList(): AgentCommissionGroupList {
    return {
      ParentAgentGuid: this.user.AgentArcGuid!,
      CommissionGroupIds: this.selectedSubEntities.join(','),
      LobIds: this.selectedLobIds,
      StartDate: this.selectedDate.StartDate,
      EndDate: this.selectedDate.EndDate,
      IncludeUser: false,
      CanSeeAgentCommissions: false
    };
  }

  getAllPolicies(isSubEntities:boolean) {
    const httpCalls: Observable<Policy[]>[] = [];
    let commissionGroupList:AgentCommissionGroupList|null = null;
    let agentGuidList = null;

    if (isSubEntities) {
      // SubEntities
      commissionGroupList = this.getCommissionGroupList();
      if (this.selectedReportType === 'Pending') {
        httpCalls.push(this.reportsApiService.getCommissionGroupPendingPolicies(commissionGroupList));
        httpCalls.push(this.reportsApiService.getCommissionGroupPendingVariablePolicies(commissionGroupList));
      }
    } else {
      // Agents
      agentGuidList = Object.assign(this.agentGuidList, {
        AgentIds: this.agents.map(x => x.ChildAgentGuid).join(','),
        LobIds: [1, 2, 4],
        StartDate: this.selectedDate.StartDate,
        EndDate: this.selectedDate.EndDate,
      });

      httpCalls.push(this.reportsApiService.getPendingPolicies(agentGuidList));
      httpCalls.push(this.reportsApiService.getPendingVariablePolicies(agentGuidList));
    }

    forkJoin(httpCalls)
      .subscribe(res => {
        this.allPendingPolicies = res[0].concat(res[1]);

        if (this.selectedReportType === 'Pending') {
          this.table.Body = this.allPendingPolicies.filter(x => this.pendingPolicyFilter(x));
          this.orderRows();
        }
      }, (error) => {
        if (this.selectedReportType === 'Pending') {
          const message = 'Sorry, an error has occurred. Please try again later.';
          const toastClass = 'is-error';
          this._snackBar.open(message, 'Close', {
            panelClass: [toastClass]
          });
        }
      }, () => {
        if (this.selectedReportType === 'Pending') {

          this.loadingRows = false;
        }
      });

    const secondaryHttpCalls: Observable<Policy[]>[] = [];
    if (isSubEntities && commissionGroupList) {
      secondaryHttpCalls.push(this.reportsApiService.getCommissionGroupInforcePolicies(commissionGroupList));
      secondaryHttpCalls.push(this.reportsApiService.getCommissionGroupInforcePaidPolicies(commissionGroupList));
      secondaryHttpCalls.push(this.reportsApiService.getCommissionGroupInforceVariablePolicies(commissionGroupList));
    } else if (agentGuidList) {
      secondaryHttpCalls.push(this.reportsApiService.getInforcePaidPolicies(agentGuidList));
      secondaryHttpCalls.push(this.reportsApiService.getInforcePolicies(agentGuidList));
      secondaryHttpCalls.push(this.reportsApiService.getInforceVariablePolicies(agentGuidList));
    }

    forkJoin(secondaryHttpCalls)
      .subscribe(res => {
        this.allInforcePolicies = res[0].concat(res[1]).concat(res[2]);

        if (this.selectedReportType === 'Inforce') {
          this.table.Body = this.allInforcePolicies.filter(x => this.inforcePolicyFilter(x));
          this.orderRows();
        }
      }, (error) => {
        if (this.selectedReportType === 'Inforce') {
          const message = 'Sorry, an error has occurred. Please try again later.';
          const toastClass = 'is-error';
          this._snackBar.open(message, 'Close', {
            panelClass: [toastClass]
          });
        }
      }, () => {
        if (this.selectedReportType === 'Inforce') {

          this.loadingRows = false;
        }
      });
    this.isClearingPolicies = false;
  }

  getFilters() {
    this.selectedAgentIds = this.policyListStore.getSelectedAgentIds() ? this.policyListStore.getSelectedAgentIds() : [this.user.AgentArcGuid!];
    this.selectedReportType = this.policyListStore.getSelectedReportType();
    this.selectedLobIds = this.policyListStore.getSelectedLobIds();
    this.selectedDate = {
      StartDate: this.policyListStore.getStartDate(),
      EndDate: this.policyListStore.getEndDate()
    };
    this.queryStartDate = this.selectedDate.StartDate;
    this.queryEndDate = this.selectedDate.EndDate;
  }

  getLobList() {
    this.lobs.forEach(x => {
      if (this.selectedLobIds.includes(x.Id)) x.Selected = true;
    });

    this.allLobsSelected = this.areLengthsEqual(this.selectedLobIds, this.lobs);
  }

  inforcePolicyFilter(value: any) {
    return this.lobMatch(value) && (this.selectedSubEntities.length > 0 || this.agentMatch(value)) && this.dateMatch(value) && this.searchTextMatch(value);
  }

  lobMatch(value: Policy): boolean {
    let match = false;
    if (this.selectedLobIds && this.selectedLobIds.length > 0) {
      match = this.selectedLobIds.some(function (l) {
        return l === value.LineOfBusinessId;
      });
    }
    return match;
  }

  openPolicyDetails(row: Policy) {
    if (!!row.PolicyGuid){
      this.router.navigate(['/Portal/MyBusiness/PolicyDetails/' + row.PolicyGuid]);
    } else {
      this._snackBar.open('Policy Details Unavailable For Paid Policies', 'Close', {
        duration:2000,
        verticalPosition: 'top',
        panelClass: ['is-default']
      });
    }
  }

  openInformalPolicyDetails(row: InformalPolicy): void {
    this.router.navigate(['/Portal/MyBusiness/InformalPolicyDetails/' + row.InformalGuid],
      {
        state: {
          InformalPolicy: row
        }
      });
  }

  openRequirementModal(row: Policy, $event: any) {
    this.loadingModal = true;
    const dialogRef = this.dialog.open(RequirementsComponent, {
      data: {
        policyGuid: row.PolicyGuid,
        isVariable: row.IsVariable,
        requirementHeader: {Carrier: row.Carrier, Product: row.Product},
        policyStage: row.PolicyStatus,
        policyStageDate: row.PolicyStatusDate,
        detailsUrl: `/Portal/MyBusiness/PolicyDetails/${row.PolicyGuid}`

      },
      width: '72rem',
      height: '84rem'
    });
    dialogRef.afterClosed().subscribe(() => {
      this.loadingModal = false;
    });
  }

  orderRows(columnName?: string[]) {
    if (columnName) {
      this.reverseOrder = (columnName.toString() === this.columnName.toString()) ? !this.reverseOrder : false;
      this.columnName = columnName;
    } else {
      columnName = this.columnName;
    }

    const order = this.columnName.map(x => this.reverseOrder ? 'asc' : 'desc');
    if (this.selectedReportType !== 'Informal') this.table.Body = orderBy(this.table.Body, ['ActionNeeded', ...columnName], ['desc', ...order]);
    else this.informalTable.Body = orderBy(this.informalTable.Body, [...columnName], [...order]);
  }

  onDateRangeChange() {
    this.setFilters();

    if (this.selectedReportType === 'Inforce' && this.allInforcePolicies) {

      if (this.selectedDate.StartDate < this.queryStartDate
        || this.selectedDate.StartDate > this.queryEndDate
        || this.selectedDate.EndDate > this.queryEndDate
        || this.selectedDate.EndDate < this.queryStartDate) {

        const agentGuidList = Object.assign(this.agentGuidList, {
          AgentIds: this.agents.map(function (a) {
            return a.ChildAgentGuid != null ? a.ChildAgentGuid : a.AgentId;
          }).join(','),
          LobIds: this.lobs.map(function (l) {
            return l.Id;
          }),
          StartDate: this.selectedDate.StartDate,
          EndDate: this.selectedDate.EndDate
        });

        forkJoin({
          inforcePolicies: this.reportsApiService.getInforcePolicies(agentGuidList),
          variableInforcePolicies: this.reportsApiService.getInforceVariablePolicies(agentGuidList),
          paidInforcePolicies: this.reportsApiService.getInforcePaidPolicies(agentGuidList),
        })
          .subscribe(res => {
            this.queryStartDate = this.selectedDate.StartDate;
            this.queryEndDate = this.selectedDate.EndDate;
            this.allInforcePolicies = orderBy(res.inforcePolicies.concat(res.paidInforcePolicies).concat(res.variableInforcePolicies), 'InforceDate');
            this.setPolicyListTable();
          });
      } else {
        this.setPolicyListTable();
      }
    }
  }

  pendingPolicyFilter(value: any) {
    return this.lobMatch(value) && (this.selectedSubEntities.length > 0 || this.agentMatch(value)) && this.searchTextMatch(value);
  }

  setFilters() {
    this.policyListStore.setSelectedAgentIds(this.selectedAgentIds);
    this.policyListStore.setSelectedLobIds(this.selectedLobIds);
    this.policyListStore.setSelectedReportType(this.selectedReportType);
    this.policyListStore.setStartDate(this.selectedDate.StartDate);
    this.policyListStore.setEndDate(this.selectedDate.EndDate);
  }

  setPolicyListTable() {
    if (this.selectedReportType === 'Pending') {
      this.table.Body = this.allPendingPolicies.filter(x => this.pendingPolicyFilter(x));
      this.orderRows();
    } else if (this.selectedReportType === 'Inforce') {
      this.table.Body = this.allInforcePolicies.filter(x => this.inforcePolicyFilter(x));
      this.orderRows();
    } else if (this.selectedReportType === 'Informal') {
      this.loadingRows = true;
      this.informalTable.Body = this.allInformalPolicies.filter(x => this.searchTextMatch(x));
      this.orderRows();
      this.loadingRows = false;
    }
  }

  toggleAllCheckboxes(array: any[], selectedArray: any[], type: string, allSelected: boolean) {
    if (allSelected) {
      if (this.selectedSubEntities.length > 0) {
        this.isClearingPolicies = true;
      }
      array.forEach(item => {
        const id = item.ChildAgentGuid || item.AgentId || item.Id;
        const isDuplicate = selectedArray.indexOf(id) > -1;
        item.Selected = true;

        if (!isDuplicate) selectedArray.push(id);
      });
      this.selectedSubEntities = [];
      this.subEntities.map((se) => se.Selected = false);
    } else {
      array.forEach(item => {
        item.Selected = false;
      });
      if (type === 'Agent') {
        this.selectedAgentIds = [];
      }
      if (type === 'Lob') this.selectedLobIds = [];
    }

    // refresh policy list if switching between comm groups or agents
    if (this.isClearingPolicies)
      this.getAllPolicies(false);

    this.setFilters();
    this.setPolicyListTable();
  }

  toggleCheckbox(array: any[], selectedArray: any[], type: string, item: any) {
    const id = item.ChildAgentGuid || item.AgentId || item.ChildCommissionGroupId || item.Id;
    const index = selectedArray.indexOf(id);

    if (item.Selected && index === -1) selectedArray.push(id);
    else selectedArray.splice(index, 1);

    if (item.ChildCommissionGroupId) {
      if (this.selectedAgentIds.length > 0) this.isClearingPolicies = true;
      // reset agents if for subEntities
      this.allAgentsSelected = false;
      this.selectedAgentIds = [];
      this.agents.map((a) => a.Selected = false);

      // will always need to refresh policies with subEntities
      this.getAllPolicies(true);
    } else {
      if (this.selectedSubEntities.length > 0) this.isClearingPolicies = true;
      if (type === 'Agent') {
        // if checkbox is for agent, reset all subEntities
        this.selectedSubEntities = [];
        this.subEntities.map((se) => se.Selected = false);
        this.allLobsSelected = this.areLengthsEqual(selectedArray, array);
      }
      if (type === 'Lob') this.allLobsSelected = this.areLengthsEqual(selectedArray, array);
    }

    if (this.isClearingPolicies)
      this.getAllPolicies(false);

    this.setFilters();
    this.setPolicyListTable();
  }

  toggleNav(sidenav: MatSidenav): void {
    sidenav.toggle();
  }

  toggleReportType(selectedReportType: string) {
    if (selectedReportType === this.selectedReportType) return;
    this.selectedReportType = selectedReportType;
    this.setFilters();
    this.generateTableHeaders(selectedReportType);
    this.setPolicyListTable();
  }

  searchTextMatch(value: any): boolean {
    return searchFilter(value, this.searchText);
  }
}
