import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SsoComponent } from './components/sso/sso.component';
import { LogOutComponent } from './components/log-out/log-out.component';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [
    SsoComponent,
    LogOutComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule
  ]
})
export class AuthModule { }
