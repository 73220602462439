import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-submit-application-modal',
  templateUrl: './submit-application-modal.component.html',
})
export class SubmitApplicationModalComponent {
  constructor (private dialog: MatDialog) { }

  onCloseCallback($event: any): void {
    this.dialog.closeAll();
  }
}