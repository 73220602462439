<section class="grid grid-cols-1 gap-x-20 gap-y-20 md:gap-x-30 md:gap-y-30 md:grid-cols-4 h-full max-w-1920" @fadeIn>
    <section class="flex flex-wrap col-span-1 md:col-span-3 h-full">
        <div class="flex flex-col flex-grow w-auto h-full overflow-auto card">
            <div class="flex flex-row items-center justify-start p-10 border-b border-solid lg:p-20 border-gray-lighter">
                <span class="m-10 mr-0 material-icons mi-24">school</span>
                <div class="h-24 m-10 mr-20">
                    <h2 class="text-lg font-bold">Learning Center</h2>
                </div>
                <app-simon-powered class="inline-block m-10 ml-auto"></app-simon-powered>
            </div>
            <app-simon-education [navItems]="navItems"></app-simon-education>    
        </div>
    </section>
    <section class="flex flex-wrap items-start col-span-1">
        <app-toolbox-sidebar grouping="Annuity"></app-toolbox-sidebar>
    </section>

    <!-- <section class="grid grid-cols-1 gap-x-20 gap-y-20 md:gap-x-30 md:gap-y-30 md:grid-cols-4" @fadeIn>
        <section class="flex flex-col col-span-1 md:col-span-3">
            <h1 class="mb-10 mr-auto text-xl font-bold leading-none md:text-2xl" *ngIf="!getResourceUrl()">
                Fixed/Indexed Annuities
            </h1>
            <p class="mb-10 text-base text-gray-dark">
                Fixed Annuities provide reduced risk and in some instances floor guarantees for usage in conservative
                portfolio
                designs. The yield or upside return ranges from term yield, to a ceiling rate of return or participation
                rate
                both applied against the underlying investments.
            </p>
            <p class="text-base text-gray-dark">
                Most financial professionals use the Fixed Annuity suite of offerings for protection and hedged
                accumulation.
                Many Fixed Annuity products offer strong income guarantees and structures needed for retirement income
                planning.
                Fixed Annuities are backed by the issuer only.
            </p>
        </section>
        <section class="flex flex-wrap col-span-1 md:col-span-3">
            <div class="flex flex-col flex-grow w-auto h-full card">
                <div class="flex flex-row items-center justify-start p-10 border-b border-solid lg:p-20 border-gray-lighter">
                    <span class="m-10 mr-0 material-icons mi-24">school</span>
                    <div class="h-24 m-10 mr-20">
                        <h2 class="text-lg font-bold">Learning Center</h2>
                    </div>
                </div>
                <div class="flex flex-row items-center justify-between border-b border-solid px-30 border-gray-lighter">
                    <div class="flex flex-row items-center justify-center rounded">
                        <button class="flex-auto h-48 pr-20 pl-15 button is-inverted is-tab"
                            aria-label="Fixed/Indexed Annuities view button"
                            [class]="param === 'Fixed Indexed Annuity' ? 'active' : ''"
                            (click)="openSimonSso('Fixed Indexed Annuity'); param = 'Fixed Indexed Annuity'">
                            <span class="flex items-center justify-center">
                                <span>Fixed Indexed Annuities</span>
                            </span>
                        </button>
                        <button class="flex-auto h-48 pr-20 pl-15 button is-inverted is-tab"
                            aria-label="Fixed Annuities view" [class]="param === 'Fixed Annuity' ? 'active' : ''"
                            (click)="openSimonSso('Fixed Annuity');param = 'Fixed Annuity'">
                            <span class="flex items-center justify-center">
                                <span>Fixed Annuities</span>
                            </span>
                        </button>
                        <button class="flex-auto h-48 pr-20 pl-15 button is-inverted is-tab"
                            attr.aria-label="Filter by inforce & paid policies"
                            [class]="param === 'Immediate Annuity' ? 'active' : ''"
                            (click)="openSimonSso('Immediate Annuity'); param = 'Immediate Annuity'">
                            <span class="flex items-center justify-center">
                                <span>Immediate Annuities</span>
                            </span>
                        </button>
                    </div>
                    <mat-checkbox class="p-5 checkbox" [(ngModel)]="localSimon" (change)="openSimonSso()">Local Simon
                    </mat-checkbox>
                </div>
                <iframe class="w-full min-h-640" [appIframeData] [src]="iframeUrl | safeUrl"></iframe>
            </div>
        </section>
        <section class="flex flex-wrap items-start col-span-1">
            <app-toolbox-sidebar grouping="Annuity"></app-toolbox-sidebar>
        </section> -->