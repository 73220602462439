import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { antiPurgeCss } from 'src/app/shared/constants/anti-purge-css.constants';
import { FigPlatformCategory, FigPlatformSpotlightTile, FigPlatformTile } from 'src/app/shared/models/figplatform.models';
import { PermissionService } from './auth/permission.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FigPlatformService {

  constructor(private http: HttpClient, private permissionService: PermissionService) { }

  antiPurgeCss: string[] = antiPurgeCss;

  getCategories(): Observable<FigPlatformCategory[]> {
    return this.http.get<FigPlatformCategory[]>('/api/FigPlatform/Categories');
  }

  getUserPermittedSpotlightTiles(): Observable<FigPlatformSpotlightTile[]> {
    //check user permitted first, return shared tiles
    return this.http.get<FigPlatformSpotlightTile[]>('/api/FigPlatform/SpotlightTiles')
      .pipe(
        map(x => x.filter(tile => {
          return (tile.Fields.ViewPermission === null || this.permissionService.hasAllPermissions(tile.Fields.ViewPermission)) && (!tile.Fields.DenyPermission || !this.permissionService.hasOnePermission(tile.Fields.DenyPermission));
        }))
      );
  }

  getUserPermittedTiles(): Observable<FigPlatformTile[]> {
    return this.http.get<FigPlatformTile[]>('/api/FigPlatform/Tiles')
      .pipe(
        map(x => x.filter(tile => {
          return (tile.Fields.ViewPermission === null || this.permissionService.hasAllPermissions(tile.Fields.ViewPermission)) && (!tile.Fields.DenyPermission || !this.permissionService.hasOnePermission(tile.Fields.DenyPermission));
        }))
      );
  }

  hasRequiredPermission(tile: FigPlatformTile): boolean {
    if (tile.Fields.ViewPermission == null || this.permissionService.hasAllPermissions(tile.Fields.ViewPermission))
      return true;
    else
      return false;
  }

  getTile(tileId: string): Observable<FigPlatformTile> {
    return this.http.get<FigPlatformTile>('/api/FigPlatform/Tiles/' + tileId);
  }

}
