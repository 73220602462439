import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WistiaApiService {

  constructor(private http:HttpClient) { }

  getLiveWireAssets():Observable<string>{
    return this.http.get<string>('/api/WistiaApi/Livewire');
  }

  getSocialSecurityResourceAssets():Observable<string>{
    return this.http.get<string>('/api/WistiaApi/SocialSecurityResources');
  }

  getDolAssets():Observable<string>{
    return this.http.get<string>('/api/WistiaApi/Dol');
  }

  getLtcTrainingAssets():Observable<any>{
    return this.http.get<string>('/api/WistiaApi/LtcTraining');
  }

  getSalesCoachJdAssets():Observable<string>{
    return this.http.get<string>('/api/WistiaApi/SalesCoachJd');
  }

  getAffinityGroupAssets():Observable<string>{
    return this.http.get<string>('/api/WistiaApi/AffinityGroup');
  }

  getNextAssets():Observable<string>{
    return this.http.get<string>('/api/WistiaApi/Next');
  }

  getMillenniumAssets():Observable<string>{
    return this.http.get<string>('/api/WistiaApi/Millennium');
  }

  getSurgeAssets():Observable<string>{
    return this.http.get<string>('/api/WistiaApi/Surge');
  }

  getElevateForLifeSchechterAssets():Observable<string>{
    return this.http.get<string>('/api/WistiaApi/ElevateForLife/Schechter');
  }

  getElevateForLifeLangdonAssets():Observable<string>{
    return this.http.get<string>('/api/WistiaApi/ElevateForLife/Langdon');
  }

  getCarAssets():Observable<string>{
    return this.http.get<string>('/api/WistiaApi/Car');
  }

  getHegnaAssets():Observable<string>{
    return this.http.get<string>('/api/WistiaApi/Hegna');
  }

}
