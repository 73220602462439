export const nationwideLifeHtml: string =
    `<html>
        <head>
            <title></title>
            <link href='https://ltc.ixn.tech/css/reset.css' rel='stylesheet' type='text/css'>
        </head>

        <body>
            <div id='ltc-quoter'></div>
            <script type='text/javascript'>
                window.IXN_LTC_CONFIG = { id: '7befa4ad-be08-4d37-93a1-59f93288ce41' };
            </script>
            <script type='text/javascript' src='https://ltc.ixn.tech/js/ltc_quoter.widget.js'></script> 
        </body> 
    </html>`;