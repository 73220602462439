import { Directive, ElementRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionService } from 'src/app/core/services/auth/permission.service';

@Directive({
  selector: '[showIfOne]'
})
export class ShowIfOneDirective {

  private hasView = false;

  constructor(
    private permissionService:PermissionService,
    private templateRef:TemplateRef<any>,
    private viewContainerRef:ViewContainerRef) { }

  @Input() set showIfOne(permissions:string) {

    const isAuthorized = this.permissionService.hasOnePermission(permissions);
    if(isAuthorized && !this.hasView){
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!isAuthorized && this.hasView) {
      this.viewContainerRef.clear();
      this.hasView = false;
    }
  }
}
