import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { fadeIn } from 'src/app/animations';
import { OnboardingApiService } from 'src/app/core/services/http/onboarding-api.service';
import { getRequiredSteps, isFirstStep, isLastStep } from 'src/app/shared/helpers/onboarding.helper';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  animations: [fadeIn]
})
export class TermsOfUseComponent implements OnInit {
  hasAcceptedTermsOfUse!:boolean;
  Id:number =1;
  isLastStep:boolean = false;
  isFirstStep:boolean = false;
  taskCompleted:boolean = false;
  taskCompletedFromParent: boolean = false;
  errorMessage!:string;
  isError!:boolean;
  isLoading!:boolean;
  requiredSteps!:number[]|null
  @Output() taskCompletedEvent: EventEmitter<any> = new EventEmitter();
  @Output() goToPreviousEvent: EventEmitter<any> = new EventEmitter();
  @Output() goToNextEvent: EventEmitter<any> = new EventEmitter();
  @Output() goToPortalEvent: EventEmitter<string> = new EventEmitter();

  constructor(
    private cookieService:CookieService,
    private router:Router,
    private activatedRoute:ActivatedRoute,
    private onboardingApiService:OnboardingApiService
  ) { }

  ngOnInit(): void {
    this.getTermsOfUse();
    this.requiredSteps = getRequiredSteps(this.activatedRoute.snapshot.queryParamMap.get('requiredSteps'));
    // this.isFirstStep = isFirstStep(this.requiredSteps, this.Id);
    this.isLastStep = isLastStep(this.requiredSteps, this.Id);
  }

  getTermsOfUse(){
    this.isLoading = true;
    this.onboardingApiService.getLoggedInUserTermsOfUse()
      .subscribe(res => {
        if (res !== null && res.HasAgreedToTermsOfUse == true) {
          this.completeTask();
        }
      }).add(() => this.isLoading = false);
  }

  submit() {
    if (this.taskCompleted) {
      this.goToNext();
    } else {
      this.acceptTermsOfUse();
    }
  }

  acceptTermsOfUse(){
    this.isLoading = true;
    this.onboardingApiService.acceptTermsOfUse()
      .subscribe(res => {
        this.completeTask();
      },
      (error) => {
        this.isError = true;
        this.errorMessage = 'An error occurred. Please try again.';
      })
      .add(() => {
        this.isLoading = false;
        this.goToNext();
      });
  }

  completeTask(): void {
    this.taskCompleted = true;
    this.taskCompletedEvent.emit(this.taskCompleted);
  }

  goToNext(): void {
    if (this.isLastStep) {
      this.goToPortalEvent.emit('Dashboard');
    } else {
      this.taskCompletedEvent.emit(true);
      this.goToNextEvent.emit();
    }
  }

  goToPrevious(): void {
    this.goToPreviousEvent.emit();
  }

  loadTaskCompleted(fromParent: boolean): void {
    this.taskCompletedFromParent = fromParent;
    this.taskCompleted = this.taskCompletedFromParent;
  }
}
