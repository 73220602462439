import { Component, OnInit, Input } from '@angular/core';
import { PortalNavItem } from 'src/app/shared/models/portal-nav-item';
import { PermissionService } from 'src/app/core/services/auth/permission.service';
import { EnvironmentEnum } from 'src/app/shared/models/generic.models';
import { FeatureToggleService } from 'src/app/core/services/featuretoggle.service';
import { ThirdLevelNavEventService } from 'src/app/core/services/stores/third-level-nav-event.service';
import { SsoService } from 'src/app/core/services/sso.service';
import { UserDelegate, User } from 'src/app/shared/models/user.models';
import { fadeIn } from 'src/app/animations';


@Component({
  animations: [fadeIn],
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
})
export class MainNavComponent {
  @Input() onBehalfOfUser?: User | null;
  @Input() nav?: PortalNavItem[];
  @Input() delegateId?: string | null;
  @Input() delegates?: UserDelegate[];
  @Input() goToMp1!: () => void;
  @Input() isThirdLevelNavOn!: boolean;
  environmentEnum: typeof EnvironmentEnum = EnvironmentEnum;
  environment: EnvironmentEnum = this.environmentEnum.local;
  // isThirdLevelNavOn: boolean = false;

  constructor(permissionService: PermissionService, private featureToggleService: FeatureToggleService, private thirdLevelNavEventService: ThirdLevelNavEventService, private ssoService: SsoService,) {
    this.environment = featureToggleService.getEnvironment();

  }

  setOldPortalViewAsDefault() {
    return;
  }

}
