import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PolicyListStoreService {

  constructor() { }

  public baseStartDate:Date = new Date(new Date().getFullYear(), (new Date().getMonth() -12), new Date().getDate());
  public baseEndDate:Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

  private selectedAgentIds!:string[];
  getSelectedAgentIds():string[]{
    return this.selectedAgentIds;
  }
  setSelectedAgentIds(agentIds:string[]){
    this.selectedAgentIds = agentIds;
  }

  private selectedReportType:string = 'Pending';
  getSelectedReportType(){
    return this.selectedReportType;
  }
  setSelectedReportType(reportType:string){
    this.selectedReportType = reportType;
  }

  private selectedLobIds:number[] = [1, 2, 4, 3];
  getSelectedLobIds(){
    return this.selectedLobIds;
  }
  setSelectedLobIds(lobIds:number[]){
    this.selectedLobIds = lobIds;
  }

  private startDate:Date = this.baseStartDate;
  getStartDate(){
    return this.startDate;
  }
  setStartDate(date:Date){
    this.startDate = date;
  }

  private endDate:Date = this.baseEndDate;
  getEndDate(){
    return this.endDate;
  }
  setEndDate(date:Date){
    this.endDate = date;
  }
}
