<div class="flex flex-col w-full h-full text-base">
  <header class="flex items-center p-20 border-b border-solid border-gray-lighter" *ngIf="!formParametersComponent">
    <h2 class="m-10 text-lg font-bold">Action Required</h2>
    <button mat-button class="m-10 ml-auto icon-button is-primary is-inverted" attr.aria-label="Close dialog"
      mat-dialog-close (click)="closeDialog()" matTooltip="Ignore All"><i class="material-icons">close</i></button>
  </header>
  <section class="flex flex-col p-20 overflow-auto gap-y-20" @fadeIn>
    <ng-container *ngIf="!onBehalfOfUser">
      <section class="flex flex-col" *ngIf="!showPaymentSection && !showCompletedSection">
        <p class="m-10 text-lg">
          Our records indicate that your enrollment in the following program<span
            *ngIf="surveyRenewals.length > 1">s</span>
          will expire soon. Please select how you would like to proceed.
        </p>
        <div class="flex flex-col m-10 gap-y-20">
          <div *ngFor="let renewal of surveyRenewals"
            class="flex flex-row items-center rounded p-15 min-h-48 bg-gray-lightest">
            <span class="mr-20 font-bold w-128">{{renewal.Name}}</span>
            <div *ngIf="!renewal.ActionTaken" class="flex flex-row items-center ml-auto gap-x-10">
              <button
                *ngIf="(renewal.SurveyRenewalStatusId === this.renewalStatus.Open || renewal.SurveyRenewalStatusId === this.renewalStatus.Ignored) && renewal.OrderId === null"
                (click)="setSurveyRenewalAsAccepted(renewal)" class="button is-primary is-sm" mat-button>
                Renew Now
              </button>
              <button
                *ngIf="(renewal.SurveyRenewalStatusId === this.renewalStatus.Open || renewal.SurveyRenewalStatusId === this.renewalStatus.Ignored) && renewal.OrderId === null"
                (click)="openDeclinedModal(renewal)" class="button is-warning is-outlined is-sm" mat-button>
                I'm Not Renewing
              </button>
              <button
                *ngIf="!filterBySurveyId && (renewal.SurveyRenewalStatusId === this.renewalStatus.Open && renewal.OrderId === null)"
                (click)="setSurveyRenewalAsIgnored(renewal)" class="button is-primary is-outlined is-sm" mat-button>
                Ignore
              </button>
              <div *ngIf="renewal.SurveyRenewalStatusId=== this.renewalStatus.Declined">
                <em class="mx-10 text-base">{{renewal.RenewalStatus}} on
                  {{renewal.RenewModifiedOn | date : 'shortDate'}}</em>
              </div>
              <div *ngIf="renewal.SurveyRenewalStatusId=== this.renewalStatus.Accepted && renewal.OrderId === null">
                <em class="mx-10 text-base">{{renewal.RenewalStatus}} & Signed as of
                  {{renewal.RenewModifiedOn | date : 'shortDate'}}</em>
              </div>
              <div *ngIf="renewal.SurveyRenewalStatusId=== this.renewalStatus.Accepted && renewal.OrderComplete">
                <em class="mx-10 text-base">{{renewal.RenewalStatus}}, Paid & Signed as of
                  {{renewal.RenewModifiedOn | date : 'shortDate'}}</em>
              </div>
              <div
                *ngIf="(renewal.SurveyRenewalStatusId === this.renewalStatus.Open && renewal.OrderId !== null && !renewal.OrderComplete)">
                <em class="mx-10 text-base">Order In Progress</em>
                <button (click)="setSurveyRenewalAsAccepted(renewal); renewal.ActionTaken = true;" mat-button
                  class="button is-primary is-outlined">Go to Payment</button>
              </div>
              <button *ngIf="renewal.SurveyRenewalStatusId===1 && renewal.OrderComplete" mat-button
                (click)="openSignatureModal(renewal); renewal.ActionTaken = true;"
                class="button is-primary is-outlined">
                Sign Agreement
              </button>
            </div>
          </div>
        </div>
        <button mat-button class="m-10 ml-auto button is-primary is-outlined" attr.aria-label="Close dialog"
          mat-dialog-close (click)="closeDialog()" matTooltip="Ignore All">Ignore All</button>
      </section>
      <div *ngIf="showPaymentSection" @fadeIn>
        <app-purchase-page [purchaseModel]="purchaseModel" (purchaseCompleted)="payComponentCallback($event)"
          (purchaseCanceled)="closeDialog($event)">
        </app-purchase-page>
      </div>
      <div *ngIf="showCompletedSection" class="flex flex-col items-center p-20 text-center gap-y-20" @fadeIn>
        <span class="flex items-center justify-center w-48 h-48 rounded-full bg-green-lightest">
          <i class="material-icons text-green">check_circle</i>
        </span>
        <div class="text-lg">
          <p>Thank You!</p>
          <p>You can also manage your enrollments via the user menu to the right.</p>
        </div>
        <button mat-dialog-close mat-button class="mt-10 button is-primary">Close</button>
      </div>
    </ng-container>
    <ng-container *ngIf="onBehalfOfUser">
      <p class="m-10 text-lg">You cannot manage enrollment renewals on behalf of someone else. Please log in as yourself to make changes.</p>
    </ng-container>
    
  </section>
</div>