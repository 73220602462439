import { Component, Input, OnInit } from '@angular/core';
import { AdditionalResource, AdditionalResourceGroup, EnrollmentSurveyData, ResourcePage, PurchasePageData } from 'src/app/shared/models/resource-page.models';
import { OrderModel } from 'src/app/shared/models/order.models';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { PurchaseModalComponent } from 'src/app/portal/modals/purchase-modal/purchase-modal.component';
import { PurchaseApiService } from 'src/app/core/services/http/purchase-api.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from 'src/app/portal/modals/confirmation/confirmation.component';
import { EnrollmentFormComponent } from 'src/app/portal/modals/enrollment-form/enrollment-form.component';
import { fadeIn } from 'src/app/animations';


@Component({
  selector: 'app-force-crm-workflow',
  templateUrl: './force-crm-workflow.component.html',
  animations: [fadeIn]
})
export class ForceCrmWorkflowComponent implements OnInit {
  @Input() resource!: ResourcePage;
  isLoading!: boolean;
  purchased?: OrderModel | null;
  intervals: any[] = [];
  @Input() submitted?: { surveyId: number, isEnrolled: boolean }[] | undefined;

  constructor(
    private sessionStore: SessionStoreService,
    private purchaseApiService: PurchaseApiService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.verifyPurchased();
  }

  openEnrollmentModal(surveyId: Number) {
    const dialogRef = this.dialog.open(EnrollmentFormComponent, {
      data: {
        Event: this.resource.EnrollmentSurveyData?.Event,
        SurveyId: surveyId,
        Disclaimer: this.resource.EnrollmentSurveyData?.Disclaimer
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      const survey = this.submitted?.find(item => item.surveyId === surveyId);
      if (survey && res.hasCompletedEnrollment) survey.isEnrolled = true;
    });
  }

  openExternalInterestForm() {
    window.open(this.resource.ExternalInterestFormUrl);
  }

  checkExpiration(purchaseDate: Date | null): boolean {
    if (!purchaseDate) return true;
    const monthsUntilExpiration: number = 10;
    const expirationDate: Date = new Date(purchaseDate.setMonth(new Date().getMonth() + monthsUntilExpiration));
    let isExpired: boolean = false;
    const currentDate: Date = new Date();
    isExpired = (expirationDate.getTime() - currentDate.getTime()) <= 0 ? true : false;

    return isExpired;
  }


  verifyPurchased(): any {
    this.purchased = null;
    let o: OrderModel | null = null;

    return this.purchaseApiService.getUserOrders(this.sessionStore.User.Id).subscribe(orders => {
      orders.forEach(order => {
        if (order.MarketingStrategy === this.resource.PurchasePageData?.PurchaseModel.MarketingStrategy) {
          const completeDate = new Date(order.CompletedDate as Date);
          const completeDateString = completeDate.toLocaleDateString();

          order.PurchaseDate = completeDateString;
          order.Expired = this.checkExpiration(order.CompletedDate);
          o = order;
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      });

      if (o !== null) {
        this.intervals?.forEach((interval: any) => {
          clearInterval(interval);
        });
        this.purchased = o;

        if (o?.PurchaseDate) { }
      }
    }).add(() => {
      this.isLoading = false;
    });
  }

  pollVerifyPurchase(paymentAmount: number): void {
    if (paymentAmount > 0) {
      this.intervals.push(<any>setInterval(() => {
        this.verifyPurchased();
      }, 3000));
    } else {
      this.verifyPurchased();
    }
  }

  openPurchaseDialog(): void {
    const data = this.resource.PurchasePageData;
    if (data) {
      if (data?.ConfirmationMessage) {
        // Confirmation required
        const confirmation = this.dialog.open(ConfirmationComponent, {
          data: {
            title: data.PurchaseModel.Title,
            message: data.ConfirmationMessage
          }
        });

        confirmation.afterClosed().subscribe(result => {
          if (result) {
            // confirmation accepted
            this.purchaseResource(data);
          }
        });
      } else {
        // confirmation not required
        this.purchaseResource(data);
      }
    }
  }

  purchaseResource(data: PurchasePageData): void {

    this.purchaseApiService.getAllOrders().subscribe(orders => {

      data.PurchaseModel.Orders = orders;
      this.purchaseApiService.getMarketingDollars().subscribe(agentMarketingDollars => {
        data.PurchaseModel.AgentMarketingDollars = agentMarketingDollars.Entry.MarketingDollarsTotal;
      },
      error => {
        console.log(error);
        data.PurchaseModel.AgentMarketingDollars = 0;
      },
      () => {
        const ref = this.dialog.open(PurchaseModalComponent, {
          data: {
            purchaseModel: data.PurchaseModel,
          }
        });

        ref.afterClosed().subscribe(result => {
          if (result) {
            this.pollVerifyPurchase(result.paymentAmount);
          }
        });
      });
    });
  }
}
