
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { OnboardingModule } from './onboarding/onboarding.module';
import { SessionStoreService } from './core/services/stores/session-store.service';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { ConfigService } from './core/services/config/config.service';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { SharedModule } from './shared/shared.module';
import { PortalModule } from './portal/portal.module';
import { tooltipDefaultOptions } from './shared/config/mat-tooltip.config';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { DevelopmentGuardService } from './core/services/guards/development-guard/development-guard.service';
import { AuthService } from './core/services/auth/auth.service';
import { matSnackBarDefaultConfig } from './shared/config/mat-snack-bar.config';
import { matDialogDefaultConfig } from './shared/config/mat-dialog.config';
import { AuthModule } from './auth/auth.module';
import { OldPortalRouterService } from './core/services/old-portal-router/old-portal-router.service';
import { RiaAgent } from './shared/models/ria.models';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CoreModule,
    PortalModule,
    OnboardingModule,
    AppRoutingModule,
    AuthModule
  ],
  providers: [
    DevelopmentGuardService,
    //Mat tooltip global config
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: tooltipDefaultOptions,
    },
    //Mat dialog global config
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: matDialogDefaultConfig,
    },
    //Angular Snackbar global config
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: matSnackBarDefaultConfig,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (config: ConfigService) => ():Promise<void> => config.setPermissions(),
      deps: [ConfigService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (oldPortalRouter: OldPortalRouterService) => () => oldPortalRouter.handleOldPortalLinks(),
      deps: [OldPortalRouterService],
      multi: true,
    },
    {
      provide: Window,
      useValue: window,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (ss: SessionStoreService, as: AuthService) => {
        return (): Promise<RiaAgent|undefined>|void => {
          if(as.isAuthenticated())
            return ss.setUserV2().then(user => ss.setUserGroup(user)).then(() => ss.setOnBehalfOfUserV2()).then(() => ss.setUserLogo()).then(() => ss.setUserAcmAgentV2());

          else return console.error('Not authenticated');
        };
      },
      deps: [SessionStoreService, AuthService],
      multi: true
    },
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
