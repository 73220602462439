<section class="flex flex-col items-start justify-start w-full h-full mx-auto md:max-w-1280" @fadeIn>
    <!--*ngIf="style == 1"-->
    <div id="platformDetailsHeader" class="flex flex-col items-start justify-start" [class]="resource.Url.toLowerCase() !== 'annuityresources' &&
    resource.Url.toLowerCase() !== 'careplanning' &&
    resource.Url.toLowerCase() !== 'disabilityresources' &&
    resource.Url.toLowerCase() !== 'lifeinsurance' &&
    resource.Url.toLowerCase() !== 'medicareplanning' ? 'mb-20' : 'mb-10'">
        <a class="flex items-center mb-20 text-sm font-medium cursor-pointer text-blue-dark hover:text-blue-darker"
           routerLink="/Portal/FigPlatform" [fragment]="categoryId" target="_self" aria-label="Back">
            <i class="mr-5 no-underline material-icons mi-18">west</i>
            <span>Back</span>
        </a>
        <div class="flex flex-row items-center justify-start">
            <h2 class="mr-20 text-xl font-bold heading" [innerHtml]="resource.Title"></h2>
            <span class="px-10 py-5 text-sm rounded-full bg-green-lightest text-green-dark"
                  [class]="!!resource.AdditionalResourceGroups && resource.AdditionalResourceGroups.length && showAdditionalResources() ? 'cursor-pointer' : ''"
                  (click)="!!resource.AdditionalResourceGroups && resource.AdditionalResourceGroups.length && showAdditionalResources() ? scrollTo('additionalResourceSection') : ''"
                  *ngIf="!!resource.AccessPermissions && hideEnrollment">
                <!--*ngIf="resource. has access permission"-->
                You have access
            </span>
        </div>
    </div>
    <div class="flex flex-col w-full p-20 card md:p-30" *ngIf="resource.Url.toLowerCase() !== 'annuityresources' &&
    resource.Url.toLowerCase() !== 'careplanning' &&
    resource.Url.toLowerCase() !== 'disabilityresources' &&
    resource.Url.toLowerCase() !== 'medicareplanning' &&
    resource.Url.toLowerCase() !== 'lifeinsurance'">
        <div class="flex flex-col flex-wrap md:flex-row">
            <div class="flex flex-col order-2 w-full pb-20 md:order-1 md:pb-0 md:pr-40"
                 [class]="!!resource.Img || !!resource.WistiaId ? 'md:w-3/5' : ''">
                <div class="text-base leading-loose" [innerHtml]="resource.Template"></div>

                <div class="flex flex-row flex-wrap items-start">
                    <button class="mt-20 mr-20 border button is-primary is-inverted border-blue-dark md:mt-30"
                            (click)="openLearnMore(resource.LearnMoreUrl)" *ngIf="!!resource.LearnMoreUrl">
                        Learn More
                    </button>
                    <button
                            class="mt-20 mr-20 border button is-primary {{!cta.Label.toLowerCase().includes('enroll') ? 'is-inverted border-blue-dark' : ''}} md:mt-30"
                            (click)="openLearnMore(cta.Url)" [attr.aria.label]="cta.Label + ' Link'"
                            *ngFor="let cta of resource.CallsToAction">
                        {{cta.Label}}
                    </button>

                    <div *ngIf="!!resource.EnrollmentSurveyData">
                        <div [ngSwitch]="resource.Url.toLowerCase()">
                            <app-creative-services-workflow *ngSwitchCase="'creativeservices'" [resource]="resource"
                                                            [submitted]="submitted" (clickEvent)="redirectToMarketplaceOne($event)"></app-creative-services-workflow>
                            <app-force-crm-workflow *ngSwitchCase="'forcecrm'" [resource]="resource"
                                                    [submitted]="submitted"></app-force-crm-workflow>
                            <app-hegna-workflow *ngSwitchCase="'hegna'" [resource]="resource" [submitted]="submitted">
                            </app-hegna-workflow>
                            <app-retire-up-workflow *ngSwitchCase="'retireup'" [resource]="resource"
                                                    [submitted]="submitted"></app-retire-up-workflow>
                            <app-surge-workflow *ngSwitchCase="'surge'" [resource]="resource" [submitted]="submitted">
                            </app-surge-workflow>
                            <app-elevate-workflow *ngSwitchCase="'elevate'" [resource]="resource"
                                                  [submitted]="submitted"></app-elevate-workflow>

                            <div *ngSwitchDefault>
                                <ng-container *ngIf="!hideEnrollment">
                                    <ng-container *ngFor="let survey of
                                    orderBy(submitted, ['SurveyId']) ; let index = index">
                                        <button class="mt-20 mr-20 button is-primary md:mt-30"
                                                (click)="!!survey && !survey.isEnrolled ? openEnrollmentModal(survey.surveyId) : ''"

                                                [disabled]="survey.isEnrolled">
                                            <span *ngIf="!!survey && !survey.isEnrolled">{{
                                                !!resource.EnrollmentSurveyData.Labels
                                                &&
                                                resource.EnrollmentSurveyData.Labels[index] ?
                                                resource.EnrollmentSurveyData.Labels[index] : 'Enroll Today'}}</span>
                                            <span [matTooltip]="!!resource.EnrollmentSurveyData.Labels && resource.EnrollmentSurveyData.Labels[index] ? resource.EnrollmentSurveyData.Labels[index] + ' form has already been submitted' : 'Enrollment form already submitted'" matTooltipPosition="above"
                                                  *ngIf="!!survey && survey.isEnrolled">Submitted</span>
                                        </button>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <app-poz-workflow *ngIf="resource.Url.toLowerCase() === 'powerofzero'" [resource]="resource"
                                      [submitted]="submitted">
                    </app-poz-workflow>
                    <ng-container *ngIf="resource.Url.toLowerCase() === 'icomply'">
                        <button class="mt-20 mr-20 button is-primary md:mt-30" (click)="redirectToIcomply()"
                                *showIfOne="'IComply:Access'">
                            GO TO ICOMPLY
                        </button>
                    </ng-container>
                    <button class="mt-20 mr-20 button is-primary md:mt-30" (click)="redirectToCannex()"
                            *ngIf="resource.Url.toLowerCase() === 'cannex'">
                        GO TO Cannex
                    </button>
                    <button class="mt-20 mr-20 button is-primary md:mt-30" (click)="redirectToAnnuityRateWatch()"
                            *ngIf="resource.Url.toLowerCase() === 'annuityratewatch'">
                        GO TO Annuity Rate Watch
                    </button>
                    <ng-container *ngIf="resource.Url.toLowerCase() === 'truelytics'">
                        <a *showIfOne="'Truelytics:Access'" href="/api/Truelytics/Sso"
                           class="pt-10 mt-20 mr-20 button is-primary md:mt-30" target="_blank" rel="noopener">Login to
                            Truelytics</a>
                    </ng-container>
                </div>
                <div class="mx-20 mt-20 italic text-justify md:mt-30" [innerHtml]="resource.Disclaimer"
                     *ngIf="!!resource.Disclaimer"></div>
            </div>
            <div class="flex flex-col items-center order-1 w-full pb-20 md:w-2/5 md:h-480 md:order-2 md:pb-0"
                 *ngIf="resource.Img || resource.WistiaId || resource.AdditionalImages?.length">
                <div class="relative block w-4/5 max-w-full m-20" [@fadeIn] *ngIf="!!resource.Img">
                    <img class="w-auto max-w-full mx-auto mb-20 overflow-hidden rounded"
                         [class]="resource.ImgSize ? resource.ImgSize : 'h-192'"
                         src="./assets/images/icons/{{resource.Img}}" @fadeIn>
                    <div class="text-center" [innerHtml]="resource.ImgSubtitle" *ngIf="!!resource.ImgSubtitle"></div>
                </div>
                <div class="relative w-full mx-auto overflow-hidden rounded shadow wistia_responsive_padding h-320 min-w-128"
                     *ngIf="!!resource.WistiaId">
                    <div class="wistia_responsive_wrapper"
                         style="height: 100%; left: 0; position: absolute; top: 0; width: 100%;">
                        <span id="resourceVideo{{resource.WistiaId}}"
                              class="wistia_embed wistia_async_{{resource.WistiaId}} popover=true popoverAnimateThumbnail=false videoFoam=false videoQuality=hd-only"
                              style="display: inline-block; height: 100%; width: 100%;" @fadeIn> </span>
                    </div>
                </div>
                <div class="relative block w-full max-w-full p-20 text-center" [@fadeIn]
                     *ngIf="resource.Url.toLowerCase() === 'theamericancollege'">
                    <h4 class="mb-10 text-base"><strong>Why Designations Are Important</strong></h4>
                    <iframe class="mx-auto w-9/10 h-256"
                            src="https://www.youtube.com/embed/eXpuAoR1WtU?rel=0&amp;showinfo=0" frameborder="0"
                            allow="autoplay; encrypted-media" allowfullscreen @fadeIn></iframe>

                </div>
                <div class="relative block w-5/6 max-w-full m-20" [@fadeIn] *ngFor="let img of resource.AdditionalImages">
                    <a [href]="img.Url" target="_blank" aria-label="Click to see full version">
                        <img class="w-auto max-w-full mx-auto mb-20 overflow-hidden rounded shadow-md" [attr.aria-label]="img.Label"
                             [class]="img.ImageSize ? img.ImageSize : 'h-220'"
                             [src]="img.Url" @fadeIn></a>
                    <div class="text-center" [innerHtml]="img.Subtitle" *ngIf="!!img.Subtitle"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="" *ngIf="resource.Url.toLowerCase() === 'annuityresources'">
        <app-fixed-indexed-annuities class="p-0"></app-fixed-indexed-annuities>
    </div>
    <div class="" *ngIf="resource.Url.toLowerCase() === 'careplanning'">
        <app-care-planning class="p-0"></app-care-planning>
    </div>
    <div class="" *ngIf="resource.Url.toLowerCase() === 'disabilityresources'">
        <app-disability class="p-0"></app-disability>
    </div>
    <div class="" *ngIf="resource.Url.toLowerCase() === 'lifeinsurance'">
        <app-life-insurance-new class="p-0"></app-life-insurance-new>
    </div>
    <div class="" *ngIf="resource.Url.toLowerCase() === 'medicareplanning'">
        <app-medicare class="p-0"></app-medicare>
    </div>
    <!-- <div class="w-full overflow-hidden card" *ngIf="resource.Url.toLowerCase() === 'annuityincomeanalysis'">
        <app-i-block class="p-0"></app-i-block>
    </div> -->
    <div class="flex flex-col w-full p-20 mt-20 card md:p-30 md:mt-30" *ngIf="!!resource.AdditionalTemplate">
        <div class="text-base leading-loose" [innerHtml]="resource.AdditionalTemplate"></div>
    </div>
    <div class="flex flex-col w-full p-20 mt-20 card md:p-30 md:mt-30" *ngIf="resource.Url.toLowerCase() === 'covid19'">
        <app-digital-marketing-assets></app-digital-marketing-assets>
    </div>
    <div class="flex flex-col w-full p-20 mt-20 card md:p-30 md:mt-30"
         style="background:url('./assets/images/elevate-bg.png') 50% 75% no-repeat"
         *ngIf="resource.Url.toLowerCase() === 'elevate'">
        <app-elevate></app-elevate>
    </div>
    <div class="flex flex-col w-full p-20 mt-20 card md:p-30 md:mt-30"
         *ngIf="resource.Url.toLowerCase() === 'next' && showAdditionalResources">
        <app-next></app-next>
    </div>
    <div class="mt-20 md:mt-30" *ngIf="resource.Url.toLowerCase() === 'hegna' && showAdditionalResources()">
        <app-hegna></app-hegna>
    </div>
    <div class="mt-20 md:mt-30" #additionalResourceSection id="additionalResourceSection"
         *ngIf="!!resource.AdditionalResourceGroups && resource.AdditionalResourceGroups.length && showAdditionalResources()">
        <h2 class="mb-20 text-xl font-bold text-gray-dark">Resources</h2>
        <!-- <div class="flex items-center justify-start flex-layout mb-30">
            <div class="relative flex flex-row items-center justify-start mr-10 sm:flex-grow-0 sm:w-384">
                <i class="absolute transform -translate-y-1/2 fig-24px fig-search top-1/2 left-5 text-gray"></i>
                <input type="search" class="w-full input is-lg pl-36" placeholder="Search..." (keyup)="applyFilters()"
                    [(ngModel)]="searchText" />
            </div>
            <button class="mr-auto icon-button is-primary is-inverted" attr.aria-label="Clear search box" *ngIf="!!searchText"
                (click)="clearSearch()"><i class="material-icons">close</i></button>
        </div> -->
        <div class="mb-20 md:mb-30" *ngFor="let resourceGroup of resource.AdditionalResourceGroups">
            <h2 class="mb-10 text-lg font-semibold text-gray-dark" [innerHtml]="resourceGroup.GroupName"></h2>
            <div class="grid flex-grow-0 grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-x-20 gap-y-20">
                <div class="flex flex-col p-20 overflow-hidden transition duration-150 bg-center bg-no-repeat bg-cover cursor-pointer card hover:shadow-lg md:p-30"
                     *ngFor="let resource of resourceGroup.AdditionalResources | orderBy: 'Name'">
                    <div class="flex flex-col items-center w-full h-full"
                         [attr.aria-label]="'Open ' + resource.Name"
                         (click)="openLearnMore('https://www.figmarketing.com/files/resources/' + resource.Url)"
                         *ngIf="resource.Url">
                        <i class="mb-20 fig-48px text-gray-light" [class]="resource.FigIcon"
                           *ngIf="resource.FigIcon"></i>
                        <span class="mb-20 mi-48 text-gray-light material-icons"
                              *ngIf="resource.Icon">{{resource.Icon}}</span>
                        <p class="text-center text-gray-dark" [innerHtml]="resource.Name"></p>
                    </div>
                    <div class="flex flex-col items-center w-full h-full"
                         [attr.aria-label]="'Open ' + resource.Name" (click)="openLearnMore(resource.ExternalUrl)"
                         *ngIf="resource.ExternalUrl">
                        <i class="mb-20 fig-36px text-gray-light" [class]="resource.FigIcon"
                           *ngIf="resource.FigIcon"></i>
                        <span class="mb-20 mi-36 text-gray-light material-icons"
                              *ngIf="resource.Icon">{{resource.Icon}}</span>
                        <p class="text-center text-gray-dark" [innerHtml]="resource.Name"></p>
                    </div>
                    <div class="flex flex-col items-center w-full h-full" *ngIf="resource.WistiaId">
                        <div
                             class="relative w-full mx-auto mb-20 overflow-hidden rounded shadow wistia_responsive_padding h-100 min-w-128">
                            <div class="wistia_responsive_wrapper"
                                 style="height: 100%; left: 0; position: absolute; top: 0; width: 100%;">
                                <span id="resourceVideo{{resource.WistiaId}}"
                                      class="wistia_embed wistia_async_{{resource.WistiaId}} popover=true popoverAnimateThumbnail=false videoFoam=false videoQuality=hd-only"
                                      style="display: inline-block; height: 100%; width: 100%;"> </span>
                            </div>
                        </div>
                        <p class="text-center text-gray-dark" [innerHtml]="resource.Name"></p>
                    </div>
                </div>
            </div>
        </div>
        <p class="mt-20 text-sm italic disclaimer text-gray-dark md:mt-30"
           [innerHtml]="resource.AdditionalResourceDisclaimer"></p>
    </div>
</section>