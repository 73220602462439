<nav class="flex flex-col flex-grow mt-30 portal-nav">
    <ng-container *ngFor="let item of nav">
        <ng-container
            *ngIf="!(!!item.HideWhenThreeNav && isThirdLevelNavOn) && ((item.EnvironmentVis !== undefined && item.EnvironmentVis.indexOf(environment) !== -1) || item.EnvironmentVis === undefined)">
            <!-- Router links -->
            <a class="pl-0 portal-nav-item" [amplitudeEvent]="'Sidenav Link: ' + item.Name" #lvl1Nav="routerLinkActive"
                [routerLink]="'/Portal/' + item.RouterUrl" [routerLinkActive]="['is-active']" *ngIf="!!item.RouterUrl">
                <span class="relative flex flex-col items-center justify-center w-64 h-48" [matTooltip]="item.Name"
                    [matTooltipDisabled]="!isThirdLevelNavOn" matTooltipPosition="right">
                    <span class="mi-18 material-icons" *ngIf="item.Icon">{{item.Icon}}</span>
                    <i class="fig-18px {{item.FigIcon}}" *ngIf="item.FigIcon"></i>
                    <!-- <img class="w-18 h-18" *ngIf="item.FigIcon && item.FigIcon === 'fig-quinci'" src="./assets/images/icons/quinci-icon-blue.svg"> -->
                    <span class="absolute top-0 right-0 w-4 h-full bg-blue-dark" *ngIf="!!isThirdLevelNavOn"
                        [class]="lvl1Nav.isActive ? 'block' : 'hidden'"></span>
                </span>
                <span class="portal-nav-label" [innerHtml]="item.Name" *ngIf="!isThirdLevelNavOn"></span>
                <span class="p-5 ml-5 text-xs uppercase bg-transparent rounded text-blue-light" *ngIf="!!item.IsNew" @fadeIn>New!</span>
                <i *ngIf="item.SubNav" class="p-10 ml-auto mr-10 material-icons mi-18" matTooltip="View More"
                    matTooltipPosition="right">chevron_right</i>
            </a>
            <!-- External links -->
            <a class="pl-0 portal-nav-item" [matTooltip]="item.Name" [matTooltipDisabled]="!isThirdLevelNavOn"
                matTooltipPosition="right" [amplitudeEvent]="'Sidenav External link: ' + item.Name"
                matTooltipShowDelay="1000" [href]="item.ExternalUrl" *ngIf="item.ExternalUrl && !item.ImageUrl">
                <span class="flex items-center justify-center w-64 h-48">
                    <span class="mi-18 material-icons">{{item.Icon}}</span>
                </span>
                <span class="portal-nav-label">{{item.Name}}</span>
            </a>
            <!-- img logo links -->
            <a class="pl-0 portal-nav-item" [matTooltip]="item.Name" [matTooltipDisabled]="!isThirdLevelNavOn"
                matTooltipPosition="right" [amplitudeEvent]="'Sidenav Navigation link: ' + item.Name"
                matTooltipShowDelay="1000" [href]="item.ExternalUrl" *ngIf="!!item.ImageUrl">
                <img *ngIf="!isThirdLevelNavOn" [src]="item.ImageUrl" class="m-auto">
                <span *ngIf="isThirdLevelNavOn" class="relative flex flex-col items-center justify-center w-64 h-48"
                    [matTooltip]="item.Name" [matTooltipDisabled]="!isThirdLevelNavOn" matTooltipPosition="right">
                    <i class="fig-24px {{item.FigIcon}}" *ngIf="item.FigIcon"></i>
                </span>
            </a>

            <ng-container *ngIf="item.SubNav">
                <app-subnav [navItem]="item" class="block"></app-subnav>
            </ng-container>
        </ng-container>
    </ng-container>
    <div class="flex flex-col py-20 mt-auto border-t border-solid border-gray-lighter dark:border-gray-dark">
        <a *showIfOne="'CreativeServices:Access|ElevateForLife:Access|Elevate:Access|ElevateEnhanced:Access'"
            class="pl-0 portal-nav-item" (click)="goToMp1()" amplitudeEvent="'Sidenav: Marketplace One Link">
            <img class="w-auto h-48 m-auto" *ngIf="!isThirdLevelNavOn" src="./assets/images/logos/marketplace-one-logo.svg">
            <span *ngIf="isThirdLevelNavOn" class="relative flex flex-col items-center justify-center w-64 h-48"
                [matTooltip]="'Go to Marketplace One'" [matTooltipDisabled]="!isThirdLevelNavOn"
                matTooltipPosition="right">
                <i class="fig-24px fig-marketplace-logo"></i>
            </span>
        </a>
        <a *showIfOne="'MarketingTracker:Access'" class="pl-0 portal-nav-item" href="/v1/#/Tools/MarketingTrackerNew"
            amplitudeEvent="'Sidenav: Marketing Tracker">
            <img class="w-full h-48 m-auto" *ngIf="!isThirdLevelNavOn" src="./assets/images/logos/marketing-tracker-logo.svg">
            <span *ngIf="isThirdLevelNavOn" class="relative flex flex-col items-center justify-center w-64 h-48"
                [matTooltip]="'Go to Marketing Tracker'" [matTooltipDisabled]="!isThirdLevelNavOn"
                matTooltipPosition="right">
                <i class="fig-24px fig-marketing-tracker"></i>
            </span>
        </a>
    </div>
</nav>