import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fadeIn } from 'src/app/animations';
import { sidenavConfig } from 'src/app/shared/models/sidenav-config.model';
import { userSettingsComponents } from 'src/app/shared/models/user-settings-components.models';
import { RenewalFormParameters } from '../renewal-form/renewal-form.component';

@Component({
  selector: 'app-user-settings-container',
  templateUrl: './user-settings-container.component.html',
  animations: [fadeIn]
})
export class UserSettingsContainerComponent implements OnInit {
  constructor(
    private breakpointObserver: BreakpointObserver,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  loading: boolean = true;
  mediaMobile: boolean = false;
  settingsComponents = userSettingsComponents.filter(x => !x.hidden);
  selectedComponent: userSettingsComponents = this.settingsComponents[0];
  userSettingsConfig: sidenavConfig = {
    IsOpened: true,
    Mode: 'side',
  };

  @ViewChild('userSettingsSidenav') userSettingsSidenav: any;

  ngOnInit(): void {
    this.loading = false;
    this.configureLayOut();
  }

  configureLayOut() {
    this.breakpointObserver.observe([
      Breakpoints.TabletLandscape,
      Breakpoints.WebLandscape
    ]).subscribe(result => {
      if (result.matches) {
        this.mediaMobile = false;
        this.userSettingsConfig.Mode = 'side';
      } else {
        this.mediaMobile = true;
        this.userSettingsConfig.Mode = 'push';
      }
    }, () => {
      console.log('error');
    });
  }

  componentIsSelected(component: userSettingsComponents): boolean {
    return this.selectedComponent === component;
  }


  selectComponent(component: userSettingsComponents): void {
    this.selectedComponent = component;
  }

  toggleUserSidenav(): void {
    this.userSettingsSidenav.toggle();
  }
}
