import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { fadeIn } from 'src/app/animations';
import { PermissionService } from 'src/app/core/services/auth/permission.service';
import { FeatureToggleService } from 'src/app/core/services/featuretoggle.service';
import { EnvironmentEnum } from 'src/app/shared/models/generic.models';

@Component({
  selector: 'app-carriers-training',
  templateUrl: './carriers-training.component.html',
  animations: [fadeIn]
})
export class CarriersTrainingComponent implements OnInit {
  resourceUrl: string = 'Contracting & Training ';
  showAppointments: boolean = true;
  environment?: EnvironmentEnum;
  environmentEnum: typeof EnvironmentEnum = EnvironmentEnum;

  constructor(
    public permissionService: PermissionService,
    private featureToggleService: FeatureToggleService,
    private titleService: Title) {
    this.environment = this.featureToggleService.getEnvironment();
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.resourceUrl + ' | Agent Portal');
    this.showAppointments = this.permissionService.hasAllPermissions('Reports:Contracting'); //expand Get Contracted and General Training if no Appointments reporting permision
  }
}
