import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReportsApiService } from 'src/app/core/services/http/reports-api.service';
import { Note, PolicyDetail, PolicyTask, RequirementStatus } from 'src/app/shared/models/policy.models';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { orderBy } from 'lodash';
import { CaseStatusNotesComponent } from 'src/app/portal/modals/case-status-notes/case-status-notes.component';
import { ActivatedRoute, Router } from '@angular/router';
import { fadeIn } from 'src/app/animations';
import { policyLobs } from 'src/app/shared/constants/policyList.constants';
import { RequirementModalHeader, RequirementStatusEnum } from 'src/app/shared/enums/policy-details.enum';

@Component({
  selector: 'app-requirements',
  templateUrl: './requirements.component.html',
  animations: [fadeIn],
})
export class RequirementsComponent implements OnInit {
  @Input() requirementModalHeader: RequirementModalHeader | null = null;
  @Input() policyGuid!: string;
  @Input() isVariable!: boolean | null;
  @Input() policyStage!: string | null;
  @Input() policyStageDate!: Date | null;
  @Input() lobId!: number;
  @Input() hideResponseSubmission!: boolean | null;
  agentFilter: any = { IsExternalUser: true };
  arcUserFilter: any = { IsExternalUser: false };
  agentResponses: any[] = [];
  agentResponseForm: any;
  caseStatus!: PolicyTask;
  requirements!: PolicyTask[];
  filteredRequirements = this.requirements;
  showCaseStatusView: boolean = false;
  showRequirementView: boolean = false;
  showPolicyStageView: boolean = false;
  orderBy = orderBy;
  latestNote!: any;
  lobIds: number[] = policyLobs.map(lob => lob.Id);
  loading: boolean = false;
  isUploading: boolean = false;
  isModalView!: boolean
  notes: string = '';
  policyDetails!: PolicyDetail
  today: Date = new Date();
  showNotes: boolean = false;
  uploadingFiles: File[] = [];
  updateList: any[] = [];
  detailsUrl: string = '';
  showFeedback: boolean = false;
  feedbackMessage: string = '';
  isSuccess: boolean = false;
  requirementStatusList: RequirementStatus[] = [
    {
      Name: 'Open',
      ID: RequirementStatusEnum.Open
    },
    {
      Name: 'Complete',
      ID: RequirementStatusEnum.Complete
    }
  ];
  selectedRequirementStatus: RequirementStatus = this.requirementStatusList[0];


  get formMessage() {
    return this.agentResponseForm.get('message');
  }
  get fileArray() {
    return this.agentResponseForm.get('fileArray');
  }

  constructor(private reportsApiService: ReportsApiService, private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private snackbar: MatSnackBar,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.loading = true;
    this.configureVariables();
    if (this.lobId == 1) {
      this.getCaseStatusAndUploads();
    } else {
      this.getRequirementHistory();
    }
  }

  configureVariables() {
    this.policyGuid = this.policyGuid ?? this.data.policyGuid;
    this.isVariable = this.isVariable ?? this.data ? this.data.isVariable : false;
    this.hideResponseSubmission = this.hideResponseSubmission ?? false;
    this.requirementModalHeader = this.data ? this.data.requirementHeader : null;
    this.isModalView = this.data ? true : false;
    this.lobId = this.lobId ?? this.data.lobId;
    if (this.isModalView) this.detailsUrl = this.data.detailsUrl;
    if (!this.policyStage && !this.data) {
      this.policyStage = null;
      this.policyStageDate = null;
    } else {
      this.policyStage = this.policyStage ?? this.data.policyStage;
      this.policyStageDate = this.policyStageDate ?? this.data.policyStageDate;
    }
  }

  getCaseStatusAndUploads() {
    let caseStatusHttp: Observable<PolicyTask>;
    if (this.isVariable) caseStatusHttp = this.reportsApiService.getVariablePolicyCaseStatusLatestByGuid(this.policyGuid);
    else caseStatusHttp = this.reportsApiService.getPolicyCaseStatusLatestByGuid(this.policyGuid);

    forkJoin({ caseStatus: caseStatusHttp, agentUploads: this.reportsApiService.getAgentUploadsByGuid(this.policyGuid) })
      .subscribe({
        next: (res) => {
          this.caseStatus = res.caseStatus;
          if (this.caseStatus) {
            this.showCaseStatusView = true;
            this.latestNote = res.caseStatus.Notes.filter(note => !note.IsExternalUser)[0];
            const agentNotes = res.caseStatus.Notes.filter(note => note.IsExternalUser).map(note => {
              return { ...note, ...{ Type: 'Note' } };
            });
            const agentUploads = res.agentUploads.map(file => {
              return { ...file, ...{ Type: 'File' } };
            });

            this.agentResponses = [...agentNotes, ...agentUploads];
          } else {
            this.showPolicyStageView = true;
          }
          this.createForm();
        }
      }).add(() => {
        this.loading = false;
      });
  }

  filterRequirementByStatus(statusId: number): void {
    this.filteredRequirements = this.requirements.filter(x => x.TaskStatus.ID === statusId);
  }

  getRequirementHistory(): void {
    this.reportsApiService.getPolicyRequirmentHistoryByGuid(this.policyGuid).subscribe({
      next: requirements => {
        this.requirements = requirements.map(x => {
          return Object.assign(x, { FileArray: [] });
        });
        this.filterRequirementByStatus(this.selectedRequirementStatus.ID);
        if (this.requirements.length && this.requirements.length >= 1) this.showRequirementView = true;
        else this.showPolicyStageView = true;
        this.createForm();
      },
      error: () => {
        this.showPolicyStageView = true;
      }
    }).add(() => {
      this.loading = false;
    });
  }

  showCaseStatusNotes(notes: Note[]): void {
    this.dialog.open(CaseStatusNotesComponent, {
      data: {
        notes: notes
      },
      height: '74rem',
      width: '64rem'
    });
  }

  createItem(data: any): FormGroup {
    return this.fb.group(data);
  }

  createForm(): void {
    this.agentResponseForm = this.fb.group({
      fileArray: this.fb.array([]),
      message: [''],
    });
  }

  onFileUpload(event: any, item?: PolicyTask): void {
    const files = event.target.files;

    if (files) {
      for (const file of files) {
        if (item) item?.FileArray.push(file);
        else this.fileArray.push(this.createItem({ file }));
      }
    }
  }

  sendNotesOnlyToArc(taskId: number | null, lifeLtcForm?: NgForm): void {
    if (!taskId) return;
    this.isUploading = true;
    let toastMessage = ''; let toastClass = '';
    const formMessage = lifeLtcForm ? lifeLtcForm.value.Message.toString() : this.formMessage.value;

    this.reportsApiService.postPolicyTaskNotes(taskId, formMessage).subscribe({
      next: res => {
        toastMessage = 'Note submitted succesfully!';
        toastClass = 'is-success';
        this.isSuccess = true;
        const agentNote = { ...res, ...{ Type: 'Note', CreatedOn: this.today.toISOString() } };
        this.agentResponses.unshift(agentNote);
      },
      error: () => {
        toastMessage = 'Error: something went wrong';
        toastClass = 'is-error';
        this.isSuccess = false;
      },
      complete: () => {
        this.createForm();
        lifeLtcForm?.form.reset();
        this.showSystemFeedback(toastMessage, lifeLtcForm);
      }
    }).add(() => {
      this.isUploading = false;
      this._snackBar.open(toastMessage, 'Close', { panelClass: [toastClass] });
    });
  }

  sendFilesAndNotesToArc(lifeLtcRequirement?: PolicyTask, lifeLtcForm?: NgForm): void {
    const formData = new FormData();
    this.isUploading = true;
    let formMessage = '';
    if (lifeLtcRequirement) {
      formMessage = lifeLtcRequirement.TaskName + ' - ';
      formMessage += lifeLtcRequirement.Message ? lifeLtcRequirement.Message.toString() : 'Agent Upload:';
      for (const file of lifeLtcRequirement.FileArray) {
        formData.append(formMessage, file, file.name);
      }
    } else {
      formMessage = this.formMessage.value ? this.formMessage.value : 'Agent Upload: ';
      for (const item of this.fileArray.value) {
        formData.append(formMessage, item.file, item.file.name);
      }
    }

    let toastMessage = ''; let toastClass = '';
    this.reportsApiService.postPolicyFiles(this.policyGuid, formData)

      .subscribe({
        next: res => {
          toastMessage = res === 1 ? '1 file uploaded successfully!' : res + ' files uploaded successfully!';
          toastClass = 'is-success';

          const agentFiles = this.fileArray.value.map((file: any) => {
            return { ...file, ...{ Type: 'File', CreatedOn: this.today.toISOString() } };
          });

          agentFiles.forEach((file: any) => {
            this.agentResponses.unshift(file);
          });
          this.isSuccess = true;

          if (lifeLtcRequirement) {
            lifeLtcRequirement.FileArray = []; //Aware of this complaint will look at it later - Harry
          }
        },
        error: () => {
          toastMessage = 'Error: something went wrong';
          toastClass = 'is-error';
          this.isSuccess = false;
        },
        complete: () => {
          this.createForm();
          lifeLtcForm?.form.reset();
        }
      }).add(() => {
        this.showSystemFeedback(toastMessage, lifeLtcForm);
        this._snackBar.open(toastMessage, 'Close', { panelClass: [toastClass] });
        this.isUploading = false;
      });
  }

  resolveRequirement(taskId: number | null): void {
    if (this.fileArray.value.length > 0)
      this.sendFilesAndNotesToArc();
    else if (this.formMessage.value)
      this.sendNotesOnlyToArc(taskId);
  }

  resolveLifeLtcRequirement(requirement: PolicyTask, form: NgForm): void {
    if (requirement.FileArray.length > 0) this.sendFilesAndNotesToArc(requirement, form);
    else this.sendNotesOnlyToArc(requirement.TaskId, form);
  }

  removeFile(index: number, lifeLtcRequirement?: PolicyTask): void {
    if (lifeLtcRequirement) lifeLtcRequirement.FileArray.splice(index, 1);
    else this.fileArray.removeAt(index);
  }

  showSystemFeedback(message: string, lifeLtcForm?: NgForm): void {
    this.showFeedback = true;
    this.feedbackMessage = message;
    setTimeout(() => this.showFeedback = false, 4000);
  }
}