<section class="flex flex-col flex-grow h-0 overflow-auto">
  <div [@fadeIn] class="flex flex-col flex-grow h-full" *ngIf="!isLoading && !isError && !taskCompleted">
    <iframe src="https://www.figmarketing.com/files/FIG_SIMON-TERMS_AND_CONDITIONS_OF_USE.pdf#toolbar=0&navpanes=0" class="flex-grow w-full h-0"></iframe>
    <div class="flex flex-none p-20 text-white px-30 bg-blue-darker">
      <mat-checkbox (change)="hasAcceptedTermsOfUse = $event.checked" class="font-bold">
        I have read and agree to the Terms of Use and acknowledge I am bound by the same.
      </mat-checkbox>
    </div>
  </div>
  <div [@fadeIn] id="docIsSigned" class="flex flex-col items-center justify-center flex-grow p-20 text-lg font-bold text-center"
    *ngIf="taskCompleted && !isLoading">
    <span class="flex items-center justify-center w-64 h-64 m-10 rounded-full bg-green-lightest text-green">
      <i class="material-icons mi-36">verified</i>
    </span>
    <p class="m-10 leading-loose">
      Thank you for agreeing to the terms of use!
    </p>
  </div>
  <div [@fadeIn] id="docError" class="flex flex-col items-center justify-center flex-grow p-20 text-lg font-bold text-center" *ngIf="isError">
    <span class="flex items-center justify-center w-64 h-64 m-10 rounded-full bg-orange-lightest text-orange">
      <i class="material-icons mi-36">warning</i>
    </span>
    <p class="m-10 leading-loose">
      Sorry, an error has occurred. Please contact Support.
    </p>
  </div>
  <div [@fadeIn] *ngIf="isLoading" class="relative flex-grow p-20 loader-wrap">
    <div class="loader-sm is-blue"></div>
  </div>
</section>
  <section
    class="flex items-center justify-start flex-none p-10 mt-auto border-t border-solid lg:p-20 border-gray-lighter">
    <button mat-button class="m-10 mr-auto button is-lg is-primary is-outlined" *ngIf="!isFirstStep" (click)='goToPrevious()'>
      <span class="flex flex-row items-center justify-center">
        <span class="material-icons mi-18">
          chevron_left
        </span>
        <span class="mr-10 leading-none">Previous</span>
      </span>
    </button>
    <button mat-button class="m-10 ml-auto button is-lg is-primary" [disabled]="!hasAcceptedTermsOfUse && !taskCompleted" (click)="submit()">
      <span class="flex flex-row items-center justify-center">
        <span class="ml-10 leading-none">{{isLastStep ? 'Return To Portal' : 'Next'}}</span>
        <span class="material-icons mi-18">
          chevron_right
        </span>
      </span>
    </button>
  </section>
