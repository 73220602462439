export interface ToolboxCategory{
  Name: string;
  Description: string;
  Class: string;
  Icon: string;
  Selected: boolean;
  Order: number;
  IsVisible: boolean;
}

export interface ToolboxTile{
  Id: string;
  Name: string;
  Subtitle?: string;
  ShortDescription: string;
  LongDescription: string|null;
  Category: string;
  Tags: string[];
  Act: () => void;
  Permission: string|null;
  Class: string | null;
  Icon: string | null;
  IsFeatured: boolean;
  Order: number|null;
  ImgUrl: string|null;
  IsFavorite: boolean;
  Loading: boolean|null;
  IsVisible: boolean;
}

export enum ToolboxCategoryEnum {
  Forms = 'Forms',
  RatesAndQuotes = 'Rates & Quotes',
  Calculators = 'Calculators',
  Application = 'Application',
  Tutorials = 'Tutorials',
  SimpleFinancialPlanning = 'Simple Financial Planning',
  FullFinancialPlanning = 'Full Financial Planning',
  CalculatorsAndRates = 'Calculators & Rates',
  IndustryEvents = 'Events'
}

export enum ToolboxClassEnum {
  Purple = 'text-purple',
  Blue = 'text-blue',
  Pink = 'text-pink',
  Green = 'text-green',
  Orange = 'text-orange',
  Indigo = 'text-indigo',
  Teal = 'text-teal'
}

export enum ToolboxIconEnum {
  Document = 'grading',
  Coin = 'receipt_long',
  Calculator = 'calculate',
  School = 'school',
  Analytics = 'analytics',
  Assessment = 'assessment',
  Event = 'confirmation_number'
}

export interface ToolboxGrouping {
  [id:string] : string;
}
