<ng-container *ngIf="eAppOption">
  <div class="flex flex-col h-full overflow-hidden border-4 border-solid rounded-lg w-460"
       [class]="eAppOption.eAppPreferenceType === eAppPreferenceEnum.FirelightEmbedded ? 'bg-blue-lightest border-blue-light shadow-md': 'bg-gray-lightest border-gray-light'">
    <div class="flex-none bg-top bg-no-repeat bg-cover shadow-inner h-220"
         [class]="eAppOption.eAppPreferenceType === eAppPreferenceEnum.FirelightEmbedded ? 'bg-quinci-eapp-thumb': 'bg-firelight-eapp-thumb'"></div>
    <div class="flex flex-col items-center flex-grow p-20 gap-y-10">
      <span class="flex items-center justify-center w-64 h-64 transform rounded-full -translate-y-50 -mb-50"
            [class]="eAppOption.eAppPreferenceType === eAppPreferenceEnum.FirelightEmbedded ? 'bg-blue-lightest': 'bg-gray-lightest'">
        <img [src]="eAppOption.iconUrl" class="w-36 h-36">
      </span>
      <h2 class="text-lg font-bold" [innerHtml]="eAppOption.title"></h2>
      <p>{{eAppOption.description}}</p>
      <button mat-button class="mx-auto my-auto button is-primary is-lg w-240" (click)="selectOption(eAppOption.eAppPreferenceType)">{{eAppOption.callbackBtnText}}</button>
    </div>
  </div>
</ng-container>