<table *ngIf="!isLoading" [@fadeIn] class="text-sm table-auto -my-60 custom-table">
  <thead>
    <tr>
      <td class="sticky top-0 z-10 w-48 p-10 text-xs font-semibold uppercase bg-white text-gray">
      </td>
      <td *ngFor="let cell of table.Header" class="p-10 hover:bg-blue-lightest bg-white z-10 sticky top-0 text-xs text-gray font-semibold uppercase {{cell.WidthClass}}">
        <span (click)="table.orderBy(cell)"
              class="flex items-center cursor-pointer min-h-24 focus:outline-none"
              attr.aria-label="Sort by {{cell.DisplayName}}">
          <span class="mr-5">{{cell.DisplayName}}</span>
          <span *ngIf="cell === table.OrderByColumn" class="material-icons text-gray">
            {{table.AscendingOrder ? 'arrow_drop_up' : 'arrow_drop_down'}}
          </span>
        </span>
      </td>
    </tr>
  </thead>
  <tbody>
    <tr id="policyRow" class="cursor-pointer" aria-label="Go to policy details"
        matTooltip="{{!loadingModal ? 'Go to Policy Details' : ''}}" *ngFor="let row of table.Body" [ngClass]="{'is-warning': row.ActionNeeded}"
        (click)="!loadingModal ? openPolicyDetails(row) : ''">
      <td class="p-10">
        <button mat-button (click)="openRequirementModal(row)" *ngIf="row.ActionNeeded"
                matTooltip="Action Needed. Click to Resolve"
                aria-label="Action needed. Click to resolve" class="icon-button is-alert is-inverted">
          <span class="material-icons mi-18">
            warning_amber
          </span>
        </button>
      </td>
      <td *ngIf="showAgentColumn" class="p-10">
        {{row.Agent}}</td>
      <td class="p-10">
        {{row.Clients}}</td>
      <td class="p-10">
        {{row.Carrier}}</td>
      <td class="p-10">
        <span class="flex flex-col w-full">
          <span class="font-bold">{{row.Product}}</span>
          <span>{{ row.PolicyNumber ? '(' + row.PolicyNumber + ')' :
            ''}} {{row.FaceAmount ? (row.FaceAmount | currency) + ' (Face Amount)' : ''}} </span>
        </span>
      </td>
      <td class="p-10">
        <div class="flex flex-col w-full">
          <span class="font-bold">{{row.Premium | currency}}</span>
        </div>
        <span>
          {{row.Target ? (row.Target | currency) + ' (TargetPrem.)' : ''}}
        </span>
      </td>
      <td class="p-10">
        <div class="flex flex-row" *ngIf="!row.ActionNeeded">
          <div class="flex flex-col w-full">
            <span class="font-bold">{{row.PolicyStatus}}</span>
            <span>{{row.PolicyStatusDate | date: 'MM/dd/yyyy' : 'UTC'}}</span>
          </div>
        </div>
        <div class="flex flex-row items-center font-bold text-orange hover:text-orange-dark"
             *ngIf="row.ActionNeeded" (click)="openRequirementModal(row)">
          <span>{{row.PolicyStatus}}</span>
          <i class="ml-10 material-icons mi-18">open_in_new</i>
        </div>
      </td>
    </tr>
    <tr id="policyErrorRow" class="" aria-label="Error getting policies" *ngIf="isError">
      <td class="p-10"></td>
      <td class="p-10" colspan="6">Sorry, an error has occurred. Please try again later.</td>
    </tr>
    <tr id="policyNoPoliciesRow" class="" aria-label="No policies found"
        *ngIf="!isError && table.Body.length===0 && !isLoading">
      <td class="p-10"></td>
      <td class="p-10" colspan="6">No policies found.</td>
    </tr>
  </tbody>
</table>