import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { fadeIn } from 'src/app/animations';

@Component({
  selector: 'app-my-business',
  templateUrl: './my-business.component.html',
  animations: [fadeIn]
})
export class MyBusinessComponent implements OnInit {
  resourceUrl:string = 'My Business'
  constructor(private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.resourceUrl + ' | Agent Portal');
  }

}
