<section class="grid w-full h-full grid-cols-2 gap-x-20">
  <div class="flex flex-col p-30 card">
    <span class="flex w-48 h-48 mb-20 rounded-full bg-gray-lightest">
      <i class="m-auto material-icons text-orange">warning</i>
    </span>
    <label class="text-sm text-gray">Policies with Action Needed</label>
    <a [routerLink]="pendingBusinessRoute"
       matTooltip="Go to Pending Business"
       class="mr-auto text-xl font-bold underline transition duration-150 text-orange hover:text-orange-dark">{{businessSummary.PendingPolicyActionNeededCount}} Policies Needing Attention</a>
  </div>
  <div class="flex flex-col p-30 card">
    <span class="flex w-48 h-48 mb-20 rounded-full bg-gray-lightest">
      <i class="m-auto material-icons text-gray">work</i>
    </span>
    <label class="text-sm text-gray">Issued Policies in the Past 30 Days</label>
    <a [routerLink]="issuedBusinessRoute"
       matTooltip="Go to Issues/Inforce Business"
       class="mr-auto text-xl font-bold underline transition duration-150 text-blue-dark hover:text-blue-darker">{{businessSummary.IssuedPolicyCount}} Issued Policies</a>
  </div>
</section>