import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { orderBy, sortBy, uniqBy } from 'lodash';
import { ArcApiService } from 'src/app/core/services/http/arc-api.service';
import { statesPlusDCAndVI } from 'src/app/shared/constants/states.constants';
import { EAppCarrier } from 'src/app/shared/models/eapps.models';
import { StateBasicModel } from 'src/app/shared/models/generic.models';
import { ArcProductDetail } from 'src/app/shared/models/product.models';

@Component({
  selector: 'app-start-illustration',
  templateUrl: './start-illustration.component.html',
})
export class StartIllustrationComponent implements OnInit {
  loading:boolean = false;
  carrier!:EAppCarrier;
  carriers!:EAppCarrier[];
  productList!:ArcProductDetail[];
  products!:ArcProductDetail[];
  product!:ArcProductDetail|null;
  states:StateBasicModel[] = statesPlusDCAndVI;
  state!:StateBasicModel|null;

  constructor(private arcApiService:ArcApiService, private router:Router, private dialog:MatDialog) { }

  ngOnInit(): void {
    this.loading = true;
    this.arcApiService.getSimonProducts().subscribe(res => {
      this.productList = res;
      this.getCarriers();
      this.loading = false;
    });
  }

  getCarriers() {
    const carriers = this.productList.map(item => {
      return {
        CarrierName: item.Business,
        CarrierCode: 'fake carrier code'
      };
    });

    this.carriers = sortBy(uniqBy(carriers, 'CarrierName'), 'CarrierName');
  }

  setCarrier($event:any){
    this.carrier = $event.value;
    this.filterProducts();
  }

  setState($event:any){
    this.state = $event.value;
    this.filterProducts();
  }

  setProduct($event:any){
    this.product = $event.value;
  }

  filterProducts(){
    this.products = [];
    this.product = null;
    if (this.state && this.carrier){
      const filteredProducts = this.productList.filter(prod => {
        return prod.Business == this.carrier.CarrierName && (prod.StateCodes == null || prod.StateCodes.map(state => state.trim()).includes(this.state!.ShortName));
      });
      this.products = orderBy(filteredProducts, 'Product');
    }
  }

  runIllustration(){
    if (this.product && this.state){
      this.router.navigate(['Portal/ProductRD/Marketplace/Illustration'], {
        queryParams: {
          cusip: this.product.Cusip,
          state: this.state.ShortName,
          reload: true
        }
      });
      this.dialog.closeAll();

    }
  }

}
