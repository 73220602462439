<form *ngIf="toggleNewForm && !loading" class="flex flex-col flex-shrink-0 w-full h-full overflow-hidden" #enrollmentForm="ngForm" (ngSubmit)="submitForm()">
    <section *ngIf="!loading" id="enrollmentFormToolbar"
        class="flex-none border-b border-solid p-30 border-gray-lighter">
        <h2 class="text-lg font-bold heading">
            <span *ngIf="survey.SurveyId !== 1025">
                Enroll in {{survey.SurveyName}} today!
            </span>
            <span *ngIf="survey.SurveyId === 1025">
                It looks like you do not have an {{survey.SurveyName}} account. <br />
                Submit the form below to enroll today!
            </span>
        </h2>
        <button class="absolute top-0 right-0 m-20 icon-button is-primary is-inverted" attr.aria-label="Close"
            (click)="closeModal(false)" type="button">
            <i class="material-icons">close</i>
        </button>
    </section>
    <section div class="flex flex-col flex-grow min-h-0 overflow-y-auto p-30">
        <div *ngIf="!loading">
            <div class="flex flex-row flex-wrap items-end">
                <div class="flex w-full sm:w-1/2" *ngFor="let q of questions; let index = index">
                    <div class="flex flex-col flex-grow mx-10 mt-20" *ngIf="q.SurveyQuestionTypeId === questionType.Text">
                        <label class="mb-10 text-sm font-semibold label text-gray">
                            {{q.SurveyQuestionText}} <span *ngIf="q.IsRequired" class="text-red">(required)</span>
                        </label>
                        <input class="h-40 px-10 border border-solid input is-inverted border-gray-light" type="text"
                            name="question{{q.SurveyQuestionId}}" [required]="q.IsRequired" [(ngModel)]="q.AnswerValue"/>
                    </div>
                    <div class="flex flex-col flex-grow mx-10 mt-20" *ngIf="q.SurveyQuestionTypeId === questionType.Radio">
                        <label class="mb-5 text-sm label is-semibold is-white">{{q.SurveyQuestionText}} <span
                                *ngIf="q.IsRequired" class="text-red">(required)</span></label>
                        <mat-radio-group layout="column" [(ngModel)]="q.AnswerValue"
                            (change)="questionAnsweredNoText(q.SurveyQuestionId, $event.value)">
                            <div class="flex flex-row items-center h-40" *ngFor="let a of q.PossibleAnswers">
                                <mat-radio-button class="w-full p-10 radio-button is-primary-2 md:w-1/3"
                                    *ngIf="!a.IsText" [value]="a.PossibleAnswerId">{{a.PossibleAnswerText}}
                                </mat-radio-button>
                            </div>
                        </mat-radio-group>
                    </div>
                    <div class="flex flex-col flex-grow mx-10 mt-20" *ngIf="q.SurveyQuestionType === 'State'">
                        <label class="mb-10 text-sm label is-semibold is-white">{{q.SurveyQuestionText}} <span
                                *ngIf="q.IsRequired" class="text-red">(required)</span></label>
                        <mat-select class="h-40 m-0 select is-primary-2 md-no-underline"
                            (selectionChange)="questionAnswered(q.SurveyQuestionId, q.PossibleAnswers[0].PossibleAnswerId, $event.value)">
                            <mat-option class="w-full h-40" *ngFor="let state of states" [value]="state.ShortName">
                                {{state.LongName}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="flex flex-col flex-grow mx-10 mt-20" *ngIf="q.SurveyQuestionType === 'SalesConsultant'">
                        <label class="mb-10 text-sm label is-semibold is-white">{{q.SurveyQuestionText}} <span
                                *ngIf="q.IsRequired" class="text-red">(required)</span></label>
                        <mat-select class="h-40 m-0 select is-primary-2 md-no-underline"
                            (selectionChange)="questionAnswered(q.SurveyQuestionId, q.PossibleAnswers[0].PossibleAnswerId, $event.value)">
                            <mat-option class="flex flex-row items-center w-full h-40"
                                *ngFor="let sc of salesConsultants" [value]="sc.PersonId">
                                {{sc.FirstName}} {{sc.LastName}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="flex flex-col flex-grow mx-10 mt-20" *ngIf="q.SurveyQuestionType === 'Textarea'">
                        <label class="text-sm label is-semibold is-white">{{q.Question}} <span *ngIf="q.IsRequired"
                                class="text-red">(required)</span></label>
                        <label class="mb-5 label is-grey-light">{{q.TextDescription}}</label>
                        <textarea class="textarea is-primary-2" name="question{{index}}" rows="8"
                            (change)="textInputAnswered(q.SurveyQuestionId, q.PossibleAnswers[0].PossibleAnswerId, $event)"></textarea>
                    </div>
                    <div class="flex flex-col flex-grow mx-10 mt-20" *ngIf="q.SurveyQuestionType === 'Checkbox'">
                        <label class="mb-5 text-sm label is-semibold is-white">{{q.SurveyQuestionText}} <span
                                *ngIf="q.IsRequired" class="text-red">(required)</span></label>
                        <div layout="row" layout-wrap>
                            <div class="w-1/2" *ngFor="let a of q.PossibleAnswers">
                                <mat-checkbox *ngIf="!a.IsText" class="mb-5 checkbox is-primary-2"
                                    (change)=" checkboxAnswered(q.SurveyQuestionId, $event);"
                                    value="{{a.PossibleAnswerId}}">
                                    {{a.PossibleAnswerText}}
                                </mat-checkbox>
                                <input class="mb-5 input is-primary-2" *ngIf="a.IsText"
                                    placeholder="{{a.PossibleAnswerText}}"
                                    (change)="textInputAnswered(q.SurveyQuestionId, a.PossibleAnswerId, $event)">
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col flex-grow mx-10 mt-20" *ngIf="q.SurveyQuestionType === 'RadioList'">
                        <label class="mb-5 text-sm label is-semibold is-white">{{q.SurveyQuestionText}} <span
                                *ngIf="q.IsRequired" class="text-red">(required)</span></label>
                        <div class="flex flex-row items-center justify-start">
                            <mat-radio-group class="flex flex-row"
                                (change)="questionAnsweredNoText(q.SurveyQuestionId, $event.value)">
                                <div class="flex flex-row items-center w-full md:w-1/3"
                                    *ngFor="let a of q.PossibleAnswers">
                                    <mat-radio-button class="p-10 radio-button is-primary-2" *ngIf="!a.IsText"
                                        [value]="a.PossibleAnswerId">{{a.PossibleAnswerText}}</mat-radio-button>
                                </div>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="disclaimer" class="pt-20 mx-20 mb-10 border-t border-white border-solid">
                <p class="text-base text text-gray">{{disclaimer}}</p>
            </div>
        </div>
        <div class="relative flex-grow p-20 m-auto loader-wrap" *ngIf="!!loading" [@fadeIn]>
            <div class="loader-sm is-blue"></div>
        </div>
    </section>
    <section class="flex flex-none mt-auto border-t border-solid p-30 border-gray-lighter">
        <button class="ml-auto button is-primary w-256" type="submit" [disabled]="!canSubmit">
            {{isSubmitting ? 'Submitting...' : 'Submit'}}
        </button>
    </section>
</form>

<form *ngIf="!toggleNewForm" class="flex flex-col flex-shrink-0 w-full h-full overflow-hidden" name="enrollmentForm" (ngSubmit)="submit()">
    <section *ngIf="!loading" id="enrollmentFormToolbar"
        class="flex-none border-b border-solid p-30 border-gray-lighter">
        <h2 class="text-lg font-bold heading">
            <span *ngIf="survey.SurveyId !== 1025">
                Enroll in {{survey.SurveyName}} today!
            </span>
            <span *ngIf="survey.SurveyId === 1025">
                It looks like you do not have an {{survey.SurveyName}} account. <br />
                Submit the form below to enroll today!
            </span>
        </h2>
        <button class="absolute top-0 right-0 m-20 icon-button is-primary is-inverted" attr.aria-label="Close"
            (click)="closeModal(false)" type="button">
            <i class="material-icons">close</i>
        </button>
    </section>
    <section div class="flex flex-col flex-grow min-h-0 overflow-y-auto p-30">
        <div *ngIf="!loading">
            <div class="flex flex-row flex-wrap items-end">
                <div class="flex w-full sm:w-1/2" *ngFor="let q of questions; let index = index">
                    <div class="flex flex-col flex-grow mx-10 mt-20" *ngIf="q.SurveyQuestionType === 'Text'">
                        <label class="mb-10 text-sm font-semibold label text-gray">
                            {{q.SurveyQuestionText}} <span *ngIf="q.IsRequired" class="text-red">(required)</span>
                        </label>
                        <input class="h-40 px-10 border border-solid input is-inverted border-gray-light" type="text"
                            name="question{{index}}"
                            (change)="textInputAnswered(q.SurveyQuestionId, q.PossibleAnswers[0] ? q.PossibleAnswers[0].PossibleAnswerId : 0, $event)" />
                    </div>
                    <div class="flex flex-col flex-grow mx-10 mt-20" *ngIf="q.SurveyQuestionType === 'Radio'">
                        <label class="mb-5 text-sm label is-semibold is-white">{{q.SurveyQuestionText}} <span
                                *ngIf="q.IsRequired" class="text-red">(required)</span></label>
                        <mat-radio-group layout="column"
                            (change)="questionAnsweredNoText(q.SurveyQuestionId, $event.value)">
                            <div class="flex flex-row items-center h-40" *ngFor="let a of q.PossibleAnswers">
                                <mat-radio-button class="w-full p-10 radio-button is-primary-2 md:w-1/3"
                                    *ngIf="!a.IsText" [value]="a.PossibleAnswerId">{{a.PossibleAnswerText}}
                                </mat-radio-button>
                            </div>
                        </mat-radio-group>
                    </div>
                    <div class="flex flex-col flex-grow mx-10 mt-20" *ngIf="q.SurveyQuestionType === 'State'">
                        <label class="mb-10 text-sm label is-semibold is-white">{{q.SurveyQuestionText}} <span
                                *ngIf="q.IsRequired" class="text-red">(required)</span></label>
                        <mat-select class="h-40 m-0 select is-primary-2 md-no-underline"
                            (selectionChange)="questionAnswered(q.SurveyQuestionId, q.PossibleAnswers[0].PossibleAnswerId, $event.value)">
                            <mat-option class="w-full h-40" *ngFor="let state of states" [value]="state.ShortName">
                                {{state.LongName}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="flex flex-col flex-grow mx-10 mt-20" *ngIf="q.SurveyQuestionType === 'SalesConsultant'">
                        <label class="mb-10 text-sm label is-semibold is-white">{{q.SurveyQuestionText}} <span
                                *ngIf="q.IsRequired" class="text-red">(required)</span></label>
                        <mat-select class="h-40 m-0 select is-primary-2 md-no-underline"
                            (selectionChange)="questionAnswered(q.SurveyQuestionId, q.PossibleAnswers[0].PossibleAnswerId, $event.value)">
                            <mat-option class="flex flex-row items-center w-full h-40"
                                *ngFor="let sc of salesConsultants" [value]="sc.PersonId">
                                {{sc.FirstName}} {{sc.LastName}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="flex flex-col flex-grow mx-10 mt-20" *ngIf="q.SurveyQuestionType === 'Textarea'">
                        <label class="text-sm label is-semibold is-white">{{q.Question}} <span *ngIf="q.IsRequired"
                                class="text-red">(required)</span></label>
                        <label class="mb-5 label is-grey-light">{{q.TextDescription}}</label>
                        <textarea class="textarea is-primary-2" name="question{{index}}" rows="8"
                            (change)="textInputAnswered(q.SurveyQuestionId, q.PossibleAnswers[0] ? q.PossibleAnswers[0].PossibleAnswerId : 0, $event)"></textarea>
                    </div>
                    <div class="flex flex-col flex-grow mx-10 mt-20" *ngIf="q.SurveyQuestionType === 'Checkbox'">
                        <label class="mb-5 text-sm label is-semibold is-white">{{q.SurveyQuestionText}} <span
                                *ngIf="q.IsRequired" class="text-red">(required)</span></label>
                        <div layout="row" layout-wrap>
                            <div class="w-1/2" *ngFor="let a of q.PossibleAnswers">
                                <mat-checkbox *ngIf="!a.IsText" class="mb-5 checkbox is-primary-2"
                                    (change)=" checkboxAnswered(q.SurveyQuestionId, $event);"
                                    value="{{a.PossibleAnswerId}}">
                                    {{a.PossibleAnswerText}}
                                </mat-checkbox>
                                <input class="mb-5 input is-primary-2" *ngIf="a.IsText"
                                    placeholder="{{a.PossibleAnswerText}}"
                                    (change)="textInputAnswered(q.SurveyQuestionId, a.PossibleAnswerId, $event)">
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col flex-grow mx-10 mt-20" *ngIf="q.SurveyQuestionType === 'RadioList'">
                        <label class="mb-5 text-sm label is-semibold is-white">{{q.SurveyQuestionText}} <span
                                *ngIf="q.IsRequired" class="text-red">(required)</span></label>
                        <div class="flex flex-row items-center justify-start">
                            <mat-radio-group class="flex flex-row"
                                (change)="questionAnsweredNoText(q.SurveyQuestionId, $event.value)">
                                <div class="flex flex-row items-center w-full md:w-1/3"
                                    *ngFor="let a of q.PossibleAnswers">
                                    <mat-radio-button class="p-10 radio-button is-primary-2" *ngIf="!a.IsText"
                                        [value]="a.PossibleAnswerId">{{a.PossibleAnswerText}}</mat-radio-button>
                                </div>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="disclaimer" class="pt-20 mx-20 mb-10 border-t border-white border-solid">
                <p class="text-base text text-gray">{{disclaimer}}</p>
            </div>
        </div>
        <div class="relative flex-grow p-20 m-auto loader-wrap" *ngIf="!!loading" [@fadeIn]>
            <div class="loader-sm is-blue"></div>
        </div>
    </section>
    <section class="flex flex-none mt-auto border-t border-solid p-30 border-gray-lighter">
        <button class="ml-auto button is-primary w-256" type="submit" [disabled]="!canSubmit">
            {{isSubmitting ? 'Submitting...' : 'Submit'}}
        </button>
    </section>
</form>