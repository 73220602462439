<div class="flex flex-col w-full h-full p-20 card md:p-30">
    <h3 class="mb-20 text-lg font-bold">Spotlight</h3>
    <div @fadeIn class="overflow-auto sm:max-h-300">
        <ng-container *ngFor="let spotlightTile of spotlightTiles | orderBy: ['Fields.Order']; let last = last">
            <div class="relative flex flex-col overflow-hidden rounded-lg" [class]="!last ? 'mb-20': ''"
                 [matTooltip]="'Go to ' + spotlightTile.Fields.SpotlightName" matTooltipPosition="above"
                 (click)="sendToAmplitude(spotlightTile.Fields.SpotlightName)">
                <a class="absolute top-0 left-0 z-20 w-full h-full opacity-0 cursor-pointer"
                   [href]="spotlightTile.Fields.ExternalUrl"
                   target="_blank" attr.aria-label="Go to  {{spotlightTile.Fields.SpotlightName}}" rel="noopener"
                   *ngIf="spotlightTile.Fields.ExternalUrl"></a>
                <a class="absolute top-0 left-0 z-20 w-full h-full opacity-0 cursor-pointer"
                   [routerLink]="spotlightTile.Fields.AngularRoute" target="_self"
                   attr.aria-label="Go to {{spotlightTile.Fields.SpotlightName}}"
                   *ngIf="spotlightTile.Fields.AngularRoute"></a>

                <div class="flex flex-row h-full">
                    <div class="flex flex-row items-center w-4/5 bg-center bg-no-repeat bg-cover"  
                         [class]="spotlightTile.Fields.BackgroundClass">
                        <div class="w-3/4 ml-10 md:ml-20">
                            <!-- Make sure images are 128x64 to keep proportion consistent -->
                            <img class="h-64 w-128" src="./assets/images/{{spotlightTile.Fields.LogoLight}}"
                                 *ngIf="spotlightTile.Fields.LogoLight">
                            <span
                                  class="z-10 flex flex-col justify-center w-auto h-full py-10 leading-tight"
                                  [textContent]="" *ngIf="!spotlightTile.Fields.LogoLight">
                                  <span class="font-bold uppercase" [class]="spotlightTile.Fields.SpotlightName.length > 10 ? 'text-base max-w-150' : spotlightTile.Fields.Subtitle.length > 4 ? 'text-lg' : 'text-lg'">{{spotlightTile.Fields.SpotlightName}}</span>
                                  <span class="z-10 w-auto mt-5 text-sm text-white opacity-80" *ngIf="spotlightTile.Fields.SpotlightName.indexOf('DOL ') !== -1">Click here to find out more!</span>
                            </span>
                        </div>
                        <div class="right-0 h-full ml-auto -mr-px transform rotate-180 bg-right bg-no-repeat bg-cover"
                        [class]="spotlightTile.Fields.BackgroundClass.indexOf('h-200') === -1 ? 'w-34' : 'w-100'"
                             style="background: url('./assets/images/sm-wave-vertical-gray-lightest.svg')"></div>
                    </div>
                    <div
                         class="flex flex-col items-start justify-center w-1/5 py-20 pr-10 cursor-pointer bg-gray-lightest">
                        <span
                              class="z-10 flex flex-row items-center justify-center mx-auto border rounded-full w-36 h-36 text-blue-dark border-blue-dark">
                            <span class="material-icons-outlined">
                                arrow_right_alt
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="p-10 rounded-lg bg-gray-lighter" *ngIf="!spotlightTiles.length">
            <p class="text-sm italic">No Spotlight Tiles. Check back soon! </p>
        </div>
    </div>
    <!-- <ng-container *ngIf="isOptedIntoQuinci">
        <a @fadeIn class="flex flex-col p-20 text-white bg-center bg-no-repeat rounded-lg min-h-128 bg-quinci-pattern gap-y-20" routerLink="/Portal/Business/Fixed/Lifecycle" matTooltip="Check Out the Lifecycle View">
            <div class="flex gap-y-20">
                <div class="flex flex-col flex-grow gap-y-10">
                    <p class="font-bold text-blue-lighter">The New Lifecycle View</p>
                    <img class="w-auto mr-auto h-18"
                         src="./assets/images/icons/pw-simon-dark.svg">
                </div>
            </div>
            <p class="text-sm">Gain access to key policy data, such as account values and events, that has been both simplified and optimized to assist you in managing your entire book of business.</p>
        </a>
    </ng-container> -->
</div>