<div class="flex flex-row flex-wrap h-full card overflow-hidden xs:flex-nowrap" @fadeIn>
    <div class="flex flex-col flex-grow relative mr-auto w-full xs:w-1/2 lg:w-2/5">
        <!--border-b border-solid xs:border-b-0 xs:border-r border-gray-lighter-->
        <div class=" hidden xs:flex flex-grow w-48 h-full ml-auto right-0 bg-right bg-no-repeat bg-cover absolute"
            style="background: url('./assets/images/sm-wave-vertical-flip-gray-lightest.svg')"></div>
        <app-dashboard-yearly-production></app-dashboard-yearly-production>
    </div>
    <div class="flex flex-col flex-grow w-full xs:w-1/2 lg:w-3/5 bg-gray-lightest p-20 md:py-30">
        <h3 class="mb-20 text-lg font-bold">My Business</h3>
        <div class="flex flex-col h-full justify-center">
            <div class="flex flex-row items-center py-15">
                <span
                    class="flex flex-row items-center justify-center w-24 h-24 p-20 mr-10 rounded-full bg-orange-lightest">
                    <span class="material-icons text-orange">warning_amber</span>
                </span>
                <p class="text-base">You have&nbsp;
                    <span class="font-bold underline cursor-pointer text-orange"
                        routerLink="/Portal/MyBusiness/PolicyList" *hideIfOne="'ProductRDOptedIn:View'">
                        <!-- *ngIf="environment === environmentEnum.prod" -->{{businessSummary?(businessSummary.PendingPolicyActionNeededCount
                        + ' Pending policies'):''}}
                    </span>
                    <span class="font-bold underline cursor-pointer text-orange"
                        routerLink="/Portal/Business/Fixed/Pending" *showIfAll="'ProductRDOptedIn:View'">
                        <!-- *ngIf="environment !== environmentEnum.prod" -->{{businessSummary?(businessSummary.PendingPolicyActionNeededCount
                        + ' Pending policies'):''}}
                    </span>
                    &nbsp;requiring your attention.
                </p>
            </div>
            <div class="flex flex-row items-center py-15">
                <span
                    class="flex flex-row items-center justify-center w-24 h-24 p-20 mr-10 rounded-full bg-blue-lightest">
                    <span class="material-icons-outlined text-blue-dark">work_outline</span>
                </span>
                <p class="text-base">You have&nbsp;
                    <span class="font-bold underline cursor-pointer text-blue-dark"
                        routerLink="/Portal/MyBusiness/PolicyList" *hideIfOne="'ProductRDOptedIn:View'">
                        <!-- *ngIf="environment === environmentEnum.prod" -->{{businessSummary?(businessSummary.IssuedPolicyCount
                        + ' Policies'):''}}
                    </span>
                    <span class="font-bold underline cursor-pointer text-blue-dark"
                        routerLink="/Portal/Business/Fixed/Lifecycle" *showIfAll="'ProductRDOptedIn:View'">
                        <!-- *ngIf="environment !== environmentEnum.prod" -->{{businessSummary?(businessSummary.IssuedPolicyCount
                        + ' Policies'):''}}
                    </span>
                    &nbsp;that were issued in the last 30 days.
                </p>
            </div>
            <div class="flex flex-row items-center py-15">
                <span
                    class="flex flex-row items-center justify-center w-24 h-24 p-20 mr-10 rounded-full bg-blue-lightest">
                    <span class="material-icons text-blue-dark">history_edu</span>
                </span>
                <p class="text-base">You are contracted with&nbsp;<span routerLink="/Portal/CarriersTraining"
                        class="font-bold underline cursor-pointer text-blue-dark">{{businessSummary ?
                        businessSummary.ActiveContractCount + ' Carriers.': ' Carriers.'}}</span></p>
            </div>
        </div>
    </div>
</div>
<!-- <div class="relative flex flex-row flex-wrap flex-grow pt-20 loader-wrap md:pt-40" *ngIf="loading" [@fadeIn]>
    <div class="loader-sm is-blue"></div>
</div> -->