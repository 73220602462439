<section class="flex flex-col flex-grow h-0 p-20 overflow-auto" [@fadeIn]>
  <div [@fadeIn] class="flex flex-col flex-none m-auto w-460" *ngIf="!isLoading && !isError && !taskCompleted">
    <div class="flex flex-col items-center m-20 text-center">
      <!-- In order to get all the Portal has to offer, we will need either your NPN or CRD (or both)! -->
      <h2 class="mb-10 text-lg font-bold">Licenses</h2>
      <p>To generate your customized experience, please provide your National Producer Number (NPN) and/or Central Registration Depository (CRD) number as applicable.</p>
    </div>
    <form class="grid grid-cols-2 m-20 gap-x-30 gap-y-30" [formGroup]="myForm">
      <div class="flex flex-col col-span-1">
        <label class="mb-10 text-sm font-semibold text-gray">NPN</label>
        <input formControlName="npn" class="h-40 px-10 border border-solid input is-inverted border-gray-light" type="text" />
        <div *ngIf="myForm.controls.npn.errors?.pattern" class="mt-5 text-sm text-red">
          NPN must consist of numbers only
        </div>
      </div>
      <div class="flex flex-col col-span-1">
        <label class="mb-10 text-sm font-semibold text-gray">CRD</label>
        <input formControlName="crd" class="h-40 px-10 border border-solid input is-inverted border-gray-light" type="text" />
        <div *ngIf="myForm.controls.crd.errors?.pattern" class="mt-5 text-sm text-red">
          CRD must consist of numbers only
        </div>
      </div>
    </form>
    <div class="flex flex-col items-center m-20">
      <mat-checkbox [disabled]="myForm.controls.npn.value !== '' || myForm.controls.crd.value !== ''" (change)="toggleOptout($event)">
        Not insurance licensed and don't have CRD
      </mat-checkbox>
    </div>
    <div *ngIf="hasOptedOutNpnCrd" @fadeIn
         class="p-10 m-20 mt-0 text-sm text-left border border-solid rounded max-w-460 bg-orange-lightest border-orange">
      If you are a licensed professional, please provide your NPN and/or CRD above. By choosing to not provide your NPN and/or CRD, you are opting to receive a limited user experience and <strong>will not be able to conduct business on this platform.</strong>
    </div>
  </div>
  <ng-container *ngIf="!isError && !isLoading && !taskCompleted">
    <p class="m-20 font-semibold text-center">By clicking the NEXT button below you acknowledge that you have read, understand, and agree to our <a [href]="termsOfUseUrl" target="_blank" class="font-bold underline text-blue-dark">Terms of Use</a> and <a [href]="privacyPolicyUrl" target="_blank" class="font-bold underline text-blue-dark">Privacy Policy</a>.</p>
  </ng-container>
  <div [@fadeIn] id="docError" class="flex flex-col items-center justify-center m-auto text-center" *ngIf="isError">
    <span class="flex items-center justify-center w-64 h-64 m-10 rounded-full bg-orange-lightest text-orange">
      <i class="material-icons mi-36">warning</i>
    </span>
    <p class="m-10 leading-loose">
      Sorry, an error has occurred. Please contact Support.
    </p>
  </div>
  <div [@fadeIn] id="docIsSigned" class="flex flex-col items-center justify-center flex-none m-auto text-lg font-bold text-center"
       *ngIf="taskCompleted && !isLoading">
    <span class="flex items-center justify-center w-64 h-64 m-10 rounded-full bg-green-lightest text-green">
      <i class="material-icons mi-36">verified</i>
    </span>
    <p class="m-10 leading-loose">
      Thank you for submitting NPN and CRD information! <br />
    </p>
  </div>
  <div *ngIf="isLoading" class="relative p-20 loader-wrap">
    <div class="loader-sm is-blue"></div>
  </div>
</section>
<section class="flex items-center justify-start flex-none p-10 mt-auto border-t border-solid lg:p-20 border-gray-lighter">
  <button mat-button class="m-10 mr-auto button is-lg is-primary is-outlined" *ngIf="!isFirstStep" (click)='goToPrevious()'>
    <span class="flex flex-row items-center justify-center">
      <span class="material-icons mi-18">
        chevron_left
      </span>
      <span class="mr-10 leading-none">Previous</span>
    </span>
  </button>
  <button mat-button class="m-10 ml-auto button is-lg is-primary" [disabled]="((myForm.controls.npn.value == '' && myForm.controls.crd.value == '') && !hasOptedOutNpnCrd) || myForm.invalid" (click)="submit()">
    <span class="flex flex-row items-center justify-center">
      <span class="ml-10 leading-none">{{isLastStep ? 'Return To Portal' : 'Next'}}</span>
      <span class="material-icons mi-18">
        chevron_right
      </span>
    </span>
  </button>
</section>