<div class="h-full bg-cover" style="background: url('./assets/images/wave-bg.svg');">
    <div class="flex-col justify-center min-h-full p-20 mx-auto md:p-30 max-w-1280">
        <div class="flex flex-row items-center mb-20 md:mb-30 md:min-h-50" @fadeIn>
            <h2 class="flex items-center text-lg font-bold text-white">
                <!-- <i class="mr-10 material-icons">drive_file_rename_outline</i> -->
                <span>E-Application</span>
              </h2>
            <div class="flex flex-row items-center justify-center ml-auto bg-opacity-50 rounded-full bg-blue-darkest" @fadeIn>
                <ng-container *ngFor="let step of workflowSteps; let index= index; let last = last">
                    <div class="flex-auto px-10 font-bold" [attr.aria-label]="step.Title + 'button'"
                        [class]="activeStep.State === step.State ? 'text-blue-light' : step.Id < activeStep.Id ? 'text-green' :'text-white'"
                        *ngIf="step.Id !== 4">
                        <div class="flex flex-row items-center justify-start m-10">
                            <span class="flex flex-row items-center justify-center mr-10 rounded-full w-30 h-30"
                                [class]="activeStep.State === step.State ? 'bg-blue text-white' : step.Id < activeStep.Id ? 'bg-green text-white' :'border border-white'">
                                <span class="text-sm font-semibold text-center text-white w-30">&nbsp;{{step.Id}}&nbsp;
                                </span>
                            </span>
                            <span class="leading-none" [innerHtml]="step.Title"></span>
                        </div>
                    </div>
                    <span class="h-4 rounded-full bg-blue-light w-25" *ngIf="index < 2"></span>
                </ng-container>
            </div>
        </div>
        <div class="w-full h-full overflow-hidden card min-h-460" @fadeIn>
            <div *ngIf="getComponent().includes('Training')" class="flex items-baseline justify-end p-20 border-b border-gray-lighter">
                Once you've completed all the required training:
                 <button class="flex items-center p-10 ml-10 border rounded-full md:ml-20 button is-primary is-inverted md:p-20" (click)="openSkipTraining()" amplitudeEvent="Eapp Product Training - Start Embedded Eapp">
                    Start Application
                </button>
            </div>
            <router-outlet (activate)="onActivate($event)"></router-outlet>
        </div>
    </div>
</div>