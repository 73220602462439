import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { EnvironmentEnum } from 'src/app/shared/models/generic.models';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-structured-investments-builder',
  templateUrl: './structured-investments-builder.component.html',
})
export class StructuredInvestmentsBuilderComponent implements OnInit {
  env?: EnvironmentEnum | null;
  environment = environment;
  resourceUrl: string = 'Structured Investment Builder';
  showLocalSimon: boolean = false;
  iframeUrl: string = environment.simonUrl;

  constructor(
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.resourceUrl + ' | Agent Portal');
    this.openSimonSso();
    console.log(this.iframeUrl);
  }

  openSimonSso() {
    if (this.showLocalSimon) {
      this.iframeUrl = environment.simonLocalUrl + '/builder';
    } else {
      this.iframeUrl = environment.simonUrl + '/builder';
    }
  }
}
