import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { fadeIn } from 'src/app/animations';
import { environment } from 'src/environments/environment';

@Component({
  animations: [fadeIn],
  selector: 'app-illustration',
  templateUrl: './illustration.component.html',
})
export class IllustrationComponent implements OnInit {
  environment = environment;
  cusip: string = '';
  state: string = '';
  iframeUrl: string = '';
  localSimon: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe(params => {
      // Simon IFrame needs forced reload to start a new illustration. Check if component needs to be reloaded
      if (params.get('reload') == 'true') this.reloadPage();

      this.state = params.get('state')!;
      this.cusip = params.get('cusip')!;
      this.openSimonSso();
    });
  }

  reloadPage(){
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['Portal/ProductRD/Marketplace/Illustration'], {
        queryParams: {
          cusip: this.cusip,
          state: this.state,
        }
      });
    });
  }

  openSimonSso(param?: string) {
    if(! this.state || !this.cusip) return;
    this.iframeUrl = `${this.localSimon ? environment.simonLocalUrl : environment.simonUrl}/annuities/contract-details/${this.cusip}/${this.state}/allocation?state=${this.state}`;
  }
}
