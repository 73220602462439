import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { states } from 'src/app/shared/constants/states.constants';
import { ArcProductDetail, ArcProductList } from 'src/app/shared/models/product.models';

@Injectable({
  providedIn: 'root'
})
export class ArcApiService {

  constructor(
    private http:HttpClient
  ) { }

  getArcProducts():Observable<ArcProductList>{
    return this.http.get<ArcProductList>('/api/Arc/Products');
  }

  getSimonProducts():Observable<ArcProductDetail[]>{
    return this.http.get<ArcProductDetail[]>('/api/Arc/SimonProducts');
  }

  getArcProductsByCusip(cusips: string):Observable<ArcProductDetail[]>{
    return this.http.post<ArcProductDetail[]>('/api/Arc/ArcProductsByCusip', { Cusips: cusips });
  }

  products:ArcProductDetail[] = [
    {
      Business: 'Great American Life Insurance Company',
      PublicBusinessGuid: '',
      Product: 'Index Protector 7 (MVA- Retail)',
      PublicProductGuid: '',
      LineOfBusiness: 'Annuity',
      PublicLineOfBusinessGuid: '',
      Cusip: '38983T438',
      StateCodes: states.map(state => state.ShortName)
    },
    {
      Business: 'Great American Life Insurance Company',
      PublicBusinessGuid: '',
      Product: 'Index Protector 4',
      PublicProductGuid: '',
      LineOfBusiness: 'Annuity',
      PublicLineOfBusinessGuid: '',
      Cusip: '38983V136',
      StateCodes: states.map(state => state.ShortName)
    },
    {
      Business: 'Great American Life Insurance Company',
      PublicBusinessGuid: '',
      Product: 'Index Protector 5 MVA',
      PublicProductGuid: '',
      LineOfBusiness: 'Annuity',
      PublicLineOfBusinessGuid: '',
      Cusip: '38983V144',
      StateCodes: states.map(state => state.ShortName)
    }
  ]
}
