<div class="flex flex-col w-full h-full">
  <section class="justify-center dialog-content gap-y-30" @fadeIn>
    <div class="flex items-center justify-center mx-10">
      <img class="w-auto h-60" src="./assets/images/icons/money-guide-logo-color.svg">
    </div>
    <span class="m-10 text-lg">
      Welcome to MoneyGuide Elite by Envestnet. Please enter your <strong>Institutional ID</strong> below to continue.
    </span>
    <form [formGroup]="institutionalIdForm" (submit)="onCompleteSubmit()">
      <div class="flex flex-col flex-shrink-0 mx-10 gap-y-5">
        <label class="text-sm label text-gray" for="institutionalId">Institutional ID</label>
        <input class="input" type="text" name="institutionalId" formControlName="institutionalId"
               [disabled]="isDisabled || isSubmitting" placeholder="EX: 1234" />
        <span class="w-full text-xs text-red"
              *ngIf="institutionalIdForm.controls['institutionalId'].touched && institutionalIdForm.controls['institutionalId'].errors">
          <span *ngIf="institutionalIdForm.controls['institutionalId'].hasError('required')">This field is
            required.</span>
        </span>
      </div>
    </form>
    <div class="flex flex-shrink-0 mx-10">
      <span class="">Need help finding your ID? <a class="underline text-blue-dark" href="https://www.figmarketing.com/files/resources/LocateInstitutionId.pdf" target="_blank"
           rel="noopener" aria-label="Log into MoneyGuide Elite">Log into MoneyGuide to find out how.</a></span>
    </div>
  </section>
  <footer @fadeIn>
    <button mat-button class="m-10 ml-auto button is-primary" type="submit"
            (click)="isSubmitting = true; !isSaved && !institutionalIdForm.invalid ? onCompleteSubmit() : ''" 
            [disabled]="isSaved || isDisabled || isSubmitting || institutionalIdForm.invalid || !institutionalIdForm.controls['institutionalId'].value">
            Launch{{isSubmitting ? 'ing' : ''}}
    </button>
  </footer>
</div>
