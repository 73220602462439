<section class="grid grid-cols-1 gap-x-20 gap-y-20 md:gap-x-30 md:gap-y-30 md:grid-cols-4" @fadeIn>
    <!-- <section class="flex flex-col col-span-1" [class]="!getResourceUrl() ? 'md:col-span-3': 'md:col-span-4'">
        <h1 class="mb-10 mr-auto text-2xl font-bold leading-none md:text-2xl" *ngIf="!getResourceUrl()">Care Planning
        </h1>
        <p class="">Care planning is a significant component of a client’s complete retirement portfolio, so we strive
            to help financial professionals understand the various products and solutions in this space. But with these
            solutions come various underwriting challenges and opportunities. Our experienced professionals are here to
            assist you to make the process simple for you.</p>
    </section> -->
    <section style="background: url('./assets/images/care-planning-bg-overlay.svg');"
        class="flex flex-wrap col-span-1 overflow-hidden text-white bg-center bg-no-repeat bg-cover md:col-span-4 card bg-blue-darkest">
        <section class="flex flex-col w-full border-solid lg:w-2/3">
            <div class="m-20 mb-0 md:mb-0 md:m-30">
                <h1 class="mb-10 text-xl font-bold">Care Planning: What's the Best Fit?</h1>
                <p>Long-term care (LTC) and life insurance tools all provide solutions...but which
                    is best? <strong>It depends on the client.</strong></p>
            </div>
            <!-- <img src="./assets/images/care-planning-chart.svg" alt="Care Planning Chart" class="h-auto mx-auto w-460 lg:hidden"> -->
            <ul class="flex flex-wrap flex-grow p-20">
                <li *ngFor="let item of ltcInfoArray" class="relative flex flex-col w-1/2 p-10 text-center md:w-1/4">
                    <div class="flex-grow p-10 bg-opacity-75 border border-solid rounded bg-gray-darkest" [class]="item.Class">
                        <h3 class="flex flex-col items-center justify-center pb-10 mb-20 font-bold border-b md:text-lg h-96" [class]="item.Class">
                            <span>{{item.CategoryName}}</span>
                        </h3>
                        <h4 class="mb-5 font-bold uppercase text-gray-lighter">Pro:</h4>
                        <p class="mb-10 text-sm sm:mb-20">{{item.Pros}}</p>
                        <h4 class="mb-5 font-bold uppercase text-gray-lighter">Con:</h4>
                        <p class="mb-10 text-sm sm:mb-20">{{item.Cons}}</p>
                        <h4 class="mb-5 font-bold uppercase text-gray-lighter">Best For:</h4>
                        <p class="text-sm">{{item.BestFor}}</p>
                    </div>
                </li>
            </ul>
        </section>
        <section class="items-center justify-center hidden w-full p-20 lg:w-1/3 lg:flex">
            <img src="./assets/images/care-planning-chart.png" alt="Care Planning Chart" class="w-full h-auto p-20 mx-auto">
        </section>
    </section>
    <section class="col-span-1 md:col-span-3 card">
        <section class="p-10 border-b border-solid lg:p-20 border-gray-lighter">
            <div class="flex m-10">
                <span class="mr-10 material-icons text-gray">
                    perm_media
                    </span>
                <h2 class="text-lg font-bold">Documents, Videos, & Resources</h2>
            </div>
        </section>
        <section class="p-10 border-b border-solid lg:p-20 border-gray-lighter">
            <ul class="flex flex-wrap">
                <li *ngFor="let item of ltcDocArray" class="flex items-center w-1/2 p-20">
                    <i class="mr-10 material-icons mi-18 text-gray">description</i>
                    <a class="font-bold underline cursor-pointer text-blue-dark hover:text-blue-darker"
                        [attr.target]="item.ExtUrl ? '_blank' : null" (click)="item.Act ? item.Act(): null"
                        [attr.href]="item.ExtUrl ? item.ExtUrl : null">{{item.Name}}</a>
                </li>
            </ul>
        </section>
        <section class="flex flex-col p-10">
            <ul class="flex flex-wrap p-10">
                <li *ngFor="let item of ltcVideoArray" class="flex flex-col items-center p-10">
                    <span
                        class="wistia_embed wistia_async_{{item.hashed_id}} popover=true w-192 h-128 rounded overflow-hidden">
                    </span>
                    <label class="mt-10 text-sm text-center text-bold w-192">{{item.name}}</label>
                </li>
            </ul>
        </section>
    </section>
    <section class="flex items-start col-span-1">
        <app-toolbox-sidebar grouping="CarePlanning"></app-toolbox-sidebar>
    </section>
</section>
