import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FastTrackToken } from 'src/app/shared/models/icomply.models';

@Injectable({
  providedIn: 'root'
})
export class IcomplyApiService {

  constructor(private http:HttpClient) { }

  getFastTrackToken():Observable<FastTrackToken>{
    return this.http.get<FastTrackToken>('/api/iComply/Token/FastTrack');
  }
}
