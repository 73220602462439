<section class="grid grid-cols-1 gap-x-20 gap-y-20 md:grid-cols-3 max-w-1280" @fadeIn>
    <section class="flex flex-col col-span-1 md:col-span-2 card">
        <div class="flex flex-col p-20 border-b border-solid md:p-30 border-gray-lighter">
            <h2 class="text-lg font-bold">Disability</h2>
            <p>Contact us to find out more about Disability.</p>
        </div>
        <div class="flex p-10">
            <a mat-button
                class="flex flex-row items-center justify-center px-20 m-10 button is-lg is-primary is-inverted"
                href="tel:8888413045" target=_self>
                <i class="mr-10 mi-18 material-icons">smartphone</i>
                <span>(888) 841-3045</span>
            </a>
            <a mat-button
                class="flex flex-row items-center justify-center px-20 m-10 button is-lg is-primary is-inverted"
                href="mailTo:di@mgaprt.com" target=_blank>
                <i class="mr-10 mi-18 material-icons">email</i>
                <span>di@mgaprt.com</span>
            </a>
        </div>
    </section>
    <section class="flex flex-col flex-grow-0 col-span-1 bg-center bg-no-repeat bg-cover md:row-span-2 card">
        <div class="flex items-center p-20 border-b border-solid md:p-30 border-gray-lighter">
            <i class="pr-10 material-icons-outlined">local_shipping</i>
            <h2 class="text-lg font-bold leading-none">Carriers</h2>
        </div>
        <section class="flex flex-row flex-wrap w-full p-10 md:p-20">
            <div class="relative w-full p-10 overflow-hidden rounded sm:w-1/2" [class]="last ? '' : 'mb-10 sm:mb-20'"
                *ngFor="let product of carriers; let last = last">
                <a class="absolute top-0 left-0 w-full h-full transition-colors duration-150 bg-opacity-0 hover:bg-blue-lighter hover:bg-opacity-25"
                    [href]="product.Url" matTooltip="Visit {{product.Title}}" matTooltipPosition="below" target="_blank"
                    rel="noopener" attr.aria-label="{{product.Title}} link" *ngIf="product.Url"></a>
                <img class="w-full h-auto mx-auto max-w-128" [src]="product.ImgUrl" />
            </div>
        </section>
    </section>
    <section class="flex flex-col col-span-1 md:col-span-2 card">
        <div class="flex p-20 border-b border-solid md:p-30 border-gray-lighter">
            <i class="mr-10 material-icons-outlined">menu_book</i>
            <h2 class="text-lg font-bold">News &amp; Updates</h2>
        </div>
        <div class="flex flex-col m-10 md:m-20">
            <div class="flex flex-row items-center flex-none p-10 overflow-hidden rounded cursor-pointer hover:bg-blue-lighter hover:bg-opacity-25"
                [class]="last ? '' : 'mb-20 md:mb-30'" matTooltip="View pdf" matTooltipPosition="above" rel="noopener"
                attr.aria-label="{{block.Title}} link" *ngFor="let block of disabilityBlocks; let last = last"
                (click)="openUrl(block.Url)">
                <img class="h-auto mr-20 rounded w-192" src="./assets/images/{{block.Img}}" />
                <div class="flex flex-col w-full md:w-4/5">
                    <h3 class="mb-5 font-bold leading-tight text-gray-dark" [innerHtml]="block.Title"></h3>
                    <!-- <img class="w-auto h-48 mb-10 mr-auto" [src]="block.Icon"> -->
                    <p class="text-sm text-left text-gray-dark" [innerHtml]="block.Description"></p>
                </div>
            </div>
        </div>
    </section>
</section>
<!-- 
<section class="grid grid-cols-1 gap-x-20 gap-y-20 md:gap-x-30 md:gap-y-30 md:grid-cols-4" @fadeIn>
    <section class="flex flex-col col-span-1 md:col-span-3">
        <h1 class="mb-10 mr-auto text-xl font-bold leading-none md:text-2xl" *ngIf="!getResourceUrl()">
            Disability
            </h1>
        <p class="mb-0">
            FIG Disability Income now offers complete planning options for disability needs, partnering with premier
            disability manufacturers to offer your clients the best products and niche solutions to fit their most
            complex needs.
        </p>
    </section>
    <section class="flex flex-wrap col-span-1 md:col-span-2">
        <div class="flex flex-row flex-wrap w-full p-10 mb-10 overflow-hidden bg-center bg-no-repeat bg-cover card md:p-30 md:mb-30">
            <p class="w-full mb-20 text-lg font-bold text-gray-dark">
                Contact us to find out more about Disability
            </p>
            <a class="flex flex-row items-center justify-center p-20 py-5 mr-20 border button is-primary is-inverted border-blue-dark"
                href="tel:8888413045" target=_self>
                <i class="pr-10 text-xl font-bold material-icons">smartphone</i><span
                    class="mr-5 text-lg font-bold">(888)
                    841-3045</span>
            </a>
            <a class="flex flex-row items-center justify-center p-10 px-20 py-5 mr-20 border button is-primary is-inverted border-blue-dark"
                href="mailTo:di@mgaprt.com" target=_blank>
                <i class="pr-10 text-xl material-icons">email</i><span
                    class="mr-5 text-lg font-bold lowercase">di@mgaprt.com</span>
            </a>
        </div>
        <div class="flex flex-row flex-wrap items-start w-full overflow-hidden text-white bg-center bg-no-repeat bg-cover card">
            <div
                class="flex flex-row items-center justify-start w-full p-10 border-b border-solid md:p-30 border-gray-lighter">
                <div class="flex flex-row items-center h-24 text-gray-dark">
                    <i class="pr-10 material-icons-outlined">menu_book</i>
                    <h2 class="text-lg font-bold leading-none">News &amp; Updates</h2>
                </div>
            </div>
            <div class="p-10 lg:p-30">
                <div class="flex flex-row flex-grow w-full p-5 overflow-hidden bg-opacity-0 rounded cursor-pointer bg-blue-lighter hover:bg-opacity-25" [class]="last ? '' : 'mb-20 md:mb-30'"
                    matTooltip="View pdf" matTooltipPosition="above" rel="noopener" attr.aria-label="{{block.Title}} link"
                    *ngFor="let block of disabilityBlocks; let last = last" (click)="openUrl(block.Url)">
                    <div class="w-full md:w-1/5 md:pr-20">
                        <img class="w-full overflow-hidden rounded-lg md:h-auto" src="./assets/images/{{block.Img}}" />
                    </div>
                    <div class="flex flex-col w-full md:w-4/5">
                        <h3 class="mb-5 text-lg font-bold leading-tight text-gray-dark" [innerHtml]="block.Title"></h3>
                        <p class="text-sm text-left text-gray-dark" [innerHtml]="block.Description"></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section
        class="flex flex-wrap items-start flex-grow-0 col-span-1 overflow-hidden text-white bg-center bg-no-repeat bg-cover card">
        <div
            class="flex flex-row items-center justify-start w-full p-10 border-b border-solid lg:p-30 border-gray-lighter">
            <div class="flex flex-row items-center h-24 text-gray-dark">
                <i class="pr-10 material-icons-outlined">foundation</i>
                <h2 class="text-lg font-bold leading-none">Learning Center</h2>
            </div>
        </div>
        <div class="flex flex-row flex-wrap w-full p-10 md:p-30">
            <div class="relative w-full p-5 overflow-hidden rounded sm:w-1/2"
                [class]="last ? '' : 'mb-10 sm:mb-20'" *ngFor="let product of carriers; let last = last">
                <a class="absolute top-0 left-0 w-full h-full transition-colors duration-150 opacity-0 bg-blue-lighter hover:opacity-25" [href]="product.Url" 
                matTooltip="Visit {{product.Title}}" matTooltipPosition="above" target="_blank" rel="noopener" attr.aria-label="{{product.Title}} link" *ngIf="product.Url"></a>
                <img class="w-full h-auto mx-auto max-w-128" [src]="product.ImgUrl" />
            </div>
        </div>
    </section>
</section> -->