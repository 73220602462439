import { Component, OnInit, Inject } from '@angular/core';
import { CarePlanningGraphics } from 'src/app/shared/models/care-planning-graphics.models';
import { MatDialog } from '@angular/material/dialog';
import { QuestionnairePopupComponent } from 'src/app/portal/components/toolbox/questionnaire-popup/questionnaire-popup/questionnaire-popup.component';
import { WistiaApiService } from 'src/app/core/services/http/wistia-api.service';
import { map, filter } from 'rxjs/operators';
import { EnrollmentFormComponent } from 'src/app/portal/modals/enrollment-form/enrollment-form.component';
import { ResourcePage } from 'src/app/shared/models/resource-page.models';
import { EnrollmentFormApiService } from 'src/app/core/services/http/enrollment-form-api.service';
import { RESOURCE_PAGE_CONSTANTS } from 'src/app/shared/constants/resourcePages.constants';
import { fadeIn } from 'src/app/animations';
import { ActivatedRoute } from '@angular/router';

@Component({
  animations:[fadeIn],
  selector: 'app-care-planning',
  templateUrl: './care-planning.component.html',
})
export class CarePlanningComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private wistiaApiService: WistiaApiService,
    private enrollmentFormApiService: EnrollmentFormApiService,
    @Inject(RESOURCE_PAGE_CONSTANTS) private resourcePages: ResourcePage[],) { }

  resource?: ResourcePage;
  resourceUrl: string = '';
  submitted?: { surveyId: number, isEnrolled: boolean }[] = [];
  ltcInfoArray: CarePlanningGraphics[] = [
    {
      CategoryName: 'Traditional LTC',
      Pros: 'Typically, the most affordable way to cover LTC expenses',
      Cons: '“If you don’t use it, you lose it. No cash value or return',
      BestFor: 'Clients who wants the lowest cost and most efficient way to cover LTC expenses',
      Class: 'border-pink-light',
    },
    {
      CategoryName: 'Asset-based LTC',
      Pros: 'More flexible option, leverages assets to realize cash value accumulation',
      Cons: 'More expensive with opportunity cost  if a single premium is selected',
      BestFor: 'Clients who looks to solve an LTC problem, but want life insurance with return or premium and guaranteed premium',
      Class: 'border-blue-light'
    },
    {
      CategoryName: 'Life Insurance with QLTCI Riders',
      Pros: 'Reduces fear of wasting premiums while gaining a guaranteed death benefit, cash value, and LTC coverage',
      Cons: 'Premiums may not be tax-deductible, and aren’t the “best” at any one function',
      BestFor: 'Clients who primarily want life insurance, but find LTC appealing and nice to have in case they need it',
      Class: 'border-green-light'
    },
    {
      CategoryName: 'Life Insurance with AB Riders',
      Pros: 'Allows the policyholder to have a portion of their death benefit  prior to their death for qualified LTC needs',
      Cons: 'The benefit is calculated at time of claim based on the conditions of the rider specifications',
      BestFor: 'Clients that want a death benefit, but like the security of having benefits for chronic illness, permanent disability, or cognitive impairments',
      Class: 'border-orange-light'
    },
  ];
  ltcDocArray: any = [
    {
      Name: 'Liability Release Form',
      ExtUrl: 'https://www.figmarketing.com/files/LTC-Liabilty-Form.pdf',
    },
    {
      Name: 'Pre-Approach Letter',
      ExtUrl: 'https://www.figmarketing.com/files/Care_Planning_Pre-Approach_Letter.pdf',
    },
    {
      Name: 'Prequalification Form',
      ExtUrl: '',
      Act: () => {
        this.dialog.open(QuestionnairePopupComponent);
      }
    },
    {
      Name: 'LTCI Partners Website',
      ExtUrl: 'https://www.ltcipartners.com/',
    },
  ];
  ltcVideoArray: any = [];

  ngOnInit(): void {
    this.resource = this.resourcePages.find(page => page.Url.toLowerCase() === 'careplanning');
    this.wistiaApiService.getLtcTrainingAssets().pipe(
      map(x => x.medias),
      map(x => x.filter((media: any) => media.type === 'Video'))
    ).subscribe(x => this.ltcVideoArray = x);

    if (!!this.resource) {
      this.resource.EnrollmentSurveyData?.SurveyIds.forEach(surveyId => {
        this.enrollmentFormApiService.isEnrolled(surveyId).subscribe(isEnrolled => {
          if (this.submitted) {
            this.submitted.push({ surveyId: surveyId, isEnrolled: isEnrolled });
          }
        });
      });
    }
  }

  openEnrollmentModal(surveyId: Number) {
    if (this.resource) {
      const dialogRef = this.dialog.open(EnrollmentFormComponent, {
        data: {
          Event: this.resource.EnrollmentSurveyData?.Event,
          SurveyId: surveyId,
          Disclaimer: this.resource.EnrollmentSurveyData?.Disclaimer
        }
      });

      dialogRef.afterClosed().subscribe(res => {
        const survey = this.submitted?.find(item => item.surveyId === surveyId);
        if (survey && res.hasCompletedEnrollment) survey.isEnrolled = true;
      });
    }
  }

  getResourceUrl() {
    // check if loaded from platform: otherwise display title
    // let homeUrl: string = '/Portal/FigPlatform/Details/'
    // return window.location.pathname.split(homeUrl)[1];
    return this.activatedRoute.snapshot.paramMap.get('resource');
  }

}