import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import { LeaderboardApiService } from 'src/app/core/services/http/leaderboard-api.service';
import { Leaderboard } from 'src/app/shared/models/leaderboard.model';
import { trigger, style, animate, transition } from '@angular/animations';
import { fadeInOut, fadeIn, slideInOut, routerTransition } from 'src/app/animations';

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  animations: [fadeInOut, fadeIn, slideInOut, routerTransition],
})
export class LeaderboardComponent implements OnInit {
  activeLeaderboard!: Leaderboard[];
  activeSubCategory: string = 'Overall';
  disclaimer!: string;
  filterByGroup: boolean = false;
  leaderboards!: Leaderboard[];
  loading: boolean = false;
  lodash = _;
  orderByField: string = '';
  reverseSort: boolean = true;
  readonly subCategories: string[] = ['Overall', 'Annuity', 'Life', 'Long-Term Care'];


  constructor(private leaderboardApiService: LeaderboardApiService) { }

  ngOnInit(): void {
    this.loading = true;
    this.leaderboardApiService.get()
      .subscribe(res => {
        this.activeLeaderboard = res.Leaderboards.filter(x => !x.Name.includes('Office') && x.Name.includes(this.activeSubCategory));
        this.leaderboards = res.Leaderboards;
        this.disclaimer = res.Disclaimer;
      },
      (error) => console.log(error),
      () => {
        this.loading = false;
        this.filterLeaderboard(this.filterByGroup, this.activeSubCategory);
      }
      );
    console.dir(this);
  }

  toggleSort(name: string) {
    if (this.orderByField === name) this.reverseSort = !this.reverseSort;
    else this.orderByField = name;
  }

  evalSortClasses(name: string): any {
    const obj: any = {
      'fa-sort': this.orderByField !== name,
      'fa-caret-down': this.orderByField === name && this.reverseSort,
      'fa-caret-up': this.orderByField === name && !this.reverseSort
    };

    return obj;
  }

  filterLeaderboard(filterByGroup: boolean, activeSubCategory: string) {
    this.activeLeaderboard = filterByGroup ? this.leaderboards.filter(x => x.Name.includes('Office') && x.Name.includes(activeSubCategory)) : this.leaderboards.filter(x => !x.Name.includes('Office') && x.Name.includes(activeSubCategory));
  }
}
