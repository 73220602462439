<div class='flex flex-col w-full h-full'>
<section class="flex flex-col flex-none">
    <div id="leaderboardToolbar" class="flex items-center flex-none p-20 border-b border-solid lg:p-30 border-gray-lighter">
        <h1 class="mr-auto text-lg font-bold">Start New Illustration</h1>
        <button class="icon-button is-primary is-inverted" tabindex="-1" attr.aria-label="Close" [mat-dialog-close]
            type="button"><i class="material-icons">close</i>
        </button>
    </div>
</section>
<section class="flex flex-col flex-auto p-10 overflow-auto sm:p-20">
    <form class="" name="startIllustration">
        <div class="flex flex-col flex-grow mx-10 my-10">
            <label class="mb-10 text-sm font-semibold text-gray">Carrier</label>
            <mat-select (selectionChange)="setCarrier($event)" name="carrier"
                class="h-40 px-10 m-0 border border-solid select is-inverted md-no-underline border-gray-light" required
                placeholder="Select Carrier" aria-label="Select Carrier">
                <mat-option *ngFor="let c of carriers" [value]="c" required>
                    {{c.CarrierName}}
                </mat-option>
            </mat-select>
        </div>
        <div class="flex flex-col flex-grow mx-10 my-10">
            <label class="mt-10 mb-10 text-sm font-semibold text-gray">State</label>
            <mat-select name="state" (selectionChange)="setState($event);"
                class="h-40 px-10 m-0 border border-solid select is-inverted md-no-underline border-gray-light" required
                placeholder="Select State" [(ngModel)]="state" aria-label="Select State">
                <mat-option *ngFor="let s of states" [value]="s" required>
                    {{s.LongName}}
                </mat-option>
            </mat-select>
        </div>
        <div class="flex flex-col flex-grow mx-10 my-10">
            <label class="mt-10 mb-10 text-sm font-semibold text-gray">Product</label>
            <mat-select name="product" (selectionChange)="setProduct($event)"
                class="h-40 px-10 m-0 border border-solid select is-inverted md-no-underline border-gray-light" required
                placeholder="Select Product" [(ngModel)]="product" aria-label="Select Product">
                <mat-option *ngFor="let p of products" [value]="p" required>
                    {{p.Product}}
                </mat-option>
            </mat-select>
        </div>
    </form>    
</section>
<section class="flex flex-none p-20 border-t border-solid lg:p-30 border-gray-lighter">
    <button tabindex="-1" [disabled]="!carrier || !product || !state"
    (click)="runIllustration()"
        class="ml-auto button is-primary" type="button">
        Run</button>
</section>
</div>


