import { Component, OnInit } from '@angular/core';
import { SimonIframeUrl } from 'src/app/shared/models/simon-iframe-url.models';
import { rilas } from 'src/app/shared/constants/simon-education-nav-items.constants';

@Component({
  selector: 'app-rilas',
  templateUrl: './rilas.component.html',
})
export class RilasComponent {
  navItems: SimonIframeUrl[] = rilas;

  constructor() { }

}
