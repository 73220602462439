<nav class="z-10 flex flex-col overflow-y-auto border-l border-solid portal-subnav subnav-absolute border-gray-lighter left-64 pt-30"
    [routerLinkActive]="['is-active']">
    <label class="mb-10 text-sm uppercase ml-15 text-gray" *ngIf="navItem.Name !== 'Quinci'">{{navItem.Name}}</label>
    <img class="w-auto h-24 mx-auto mb-10" src="./assets/images/icons/quinci-logo-color.svg" *ngIf="navItem.Name === 'Quinci'" />
    <ng-container *ngFor="let item2 of navItem!.SubNav">
        <a class="h-48 pl-0 portal-nav-item no-bar lvl-2" [amplitudeEvent]="'Sidenav: Subnav link - ' + item2.Name"
            [routerLink]="item2.RouterUrl" [routerLinkActive]="['is-active']" *ngIf="!!item2.RouterUrl">
            <span class="flex flex-col items-center justify-center w-48 h-48">
                <i *ngIf="!!item2.FigIcon" class="fig-icon fig-18px" [class]="item2.FigIcon"></i>
                <span *ngIf="!!item2.Icon" class="material-icons mi-18">{{item2.Icon}}</span>
            </span>
            <span class="portal-nav-label" [innerHtml]="item2.Name"></span>
            <span class="p-5 ml-5 text-xs uppercase bg-transparent rounded text-blue-light" *ngIf="!!item2.IsNew" @fadeIn>New!</span>
        </a>
        <div *ngIf="item2.SubNav" class="mb-10">
            <ng-container *ngFor="let item3 of item2.SubNav">
                <a class="pl-0 portal-nav-item h-28 no-bar lvl-3"
                    [amplitudeEvent]="'Sidenav: Subnav link - ' + item3.Name" [routerLink]="item3.RouterUrl"
                    [routerLinkActive]="['is-active']" *ngIf="!!item3.RouterUrl">
                    <span class="flex flex-col items-center justify-center w-48 h-48">
                    </span>
                    <span class="portal-nav-label" [innerHtml]="item3.Name"></span>
                    <span class="p-5 ml-5 text-xs uppercase bg-transparent rounded text-blue-light" *ngIf="!!item3.IsNew" @fadeIn>New!</span>
                </a>
            </ng-container>
        </div>
    </ng-container>
</nav>