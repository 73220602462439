import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { fadeIn } from 'src/app/animations';
import { EAppService } from 'src/app/core/services/e-app.service';
import { EAppWorkflowStoreService } from 'src/app/core/services/stores/e-app-workflow-store.service';
import { EAppErrorType, ExternalVendorProduct } from 'src/app/shared/models/eapps.models';
import { CreateActivityBasicResponse } from 'src/app/shared/models/firelightApi.models';
import { SubmitPaperApplicationComponent } from '../submit-paper-application/submit-paper-application.component';

@Component({
  animations:[fadeIn],
  selector: 'app-eapp-workflow-start-application',
  templateUrl: './eapp-workflow-start-application.component.html',
})
export class EAppWorkflowStartApplicationComponent implements OnInit {
  iPipelineId = 4;
  firelightId = 1;
  isStartingApplication!:boolean;
  isSkippingTraining:boolean = false;
  errorMessage!:string;
  product!:ExternalVendorProduct|null;

  constructor(private dialog:MatDialog,
    private eAppStore:EAppWorkflowStoreService,
    private eAppService:EAppService) { }

  ngOnInit(): void {
    this.product = this.eAppStore.Product;
    if (!this.product?.ProductCusip) this.errorMessage = EAppErrorType.ProductNotAvailableForEappSubmissionError;
  }

  skipTraining(): void{
    this.isSkippingTraining = true;
    if (this.eAppStore.ActivityId){
      this.eAppService.goToFirelight(this.eAppStore.ActivityId, {isNew: this.eAppStore.IsNew});
      this.dialog.closeAll();
    }
  }

  goBackToTraining(): void{
    this.dialog.closeAll();
  }

  openPaperApp(): void{
    this.dialog.open(SubmitPaperApplicationComponent, {
      data: {
        ClientFirstName: this.eAppStore.ClientFirstName,
        ClientLastName: this.eAppStore.ClientLastName,
        Cusip: this.eAppStore.Product?.ProductCusip,
        StateAbbreviation: this.eAppStore.State?.ShortName
      },
      height: '84rem',
      width: '64rem',
    });
  }

  startEApplication(): void{
    if (this.eAppStore.Product){
      if (this.eAppStore.Product.ExternalVendorID == this.firelightId && this.eAppStore.Product.ProductCusip) this.startFirelightEApplication();
      else if (this.eAppStore.Product.ExternalVendorID == this.iPipelineId && this.eAppStore.Product.ProductCusip) this.startIPipelineEApplication();
      else this.errorMessage = EAppErrorType.ProductNotAvailableForEappSubmissionError;
    } else {
      this.errorMessage = EAppErrorType.StartApplicationError;
    }
  }

  startFirelightEApplication(): void{
    this.isStartingApplication = true;
    if (this.eAppStore.Product && this.eAppStore.State && this.eAppStore.ClientFirstName && this.eAppStore.ClientLastName){
      this.eAppService.startFirelightEApplication(this.eAppStore.Product, this.eAppStore.State, this.eAppStore.ClientFirstName, this.eAppStore.ClientLastName)
        .subscribe({
          next: (activity:CreateActivityBasicResponse) => {
            this.eAppService.goToFirelight(activity.ActivityId, {isNew: this.eAppStore.IsNew});
            this.isStartingApplication = true;
            this.dialog.closeAll();
          },
          error: () => {
            this.isStartingApplication = false;
            this.errorMessage = EAppErrorType.StartApplicationError;
          }
        });
    } else {
      this.errorMessage = EAppErrorType.StartApplicationError;
    }
  }

  startIPipelineEApplication(): void{
    if (this.eAppStore.State && this.eAppStore.Product){
      this.eAppService.startiPipelineEApplication(this.eAppStore.State.ShortName, this.eAppStore.Product.ProductCusip!, this.eAppStore.Product.ExternalVendorProductTypeID!.toString());
    } else {
      this.errorMessage = EAppErrorType.StartApplicationError;
    }
  }
}
