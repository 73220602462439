<div class="flex flex-col w-full h-full p-20 gap-y-10 card">
  <h2 class="text-lg font-bold">Upcoming Events</h2>
  <table class="items-center text-sm border-separate table-auto whitespace-nowrap" style="border-spacing: 0 1rem"
         *ngIf="filteredEvents.length">
    <tbody>
      <tr class="p-10 transition duration-150 rounded gap-x-20 hover:bg-blue-lightest focus:bg-blue-lightest" *ngFor="let event of filteredEvents">
        <td class="p-10 rounded-l">
          <i class="mt-5 material-icons text-gray mi-18">event</i>
        </td>
        <td class="p-10 whitespace-normal">
          <p class="flex-grow font-bold">{{event.title}}</p>
        </td>
        <td class="p-10">
          <p class="text-sm text-gray" [innerHtml]="event.date"></p>
        </td>
        <td class="p-10 rounded-r">
          <div class="flex ml-auto">
            <a mat-button class="button is-primary is-outlined is-sm" [href]="event.viewNowUrl"
               target="_blank" attr.aria-label="View {{event.title}}" rel="noopener" *ngIf="event.viewNowUrl">Learn More</a>
            <a mat-button class="button is-primary is-outlined is-sm" [href]="event.attendUrl"
               target="_blank" attr.aria-label="Attend {{event.title}}" rel="noopener" *ngIf="event.attendUrl">Learn More</a>
            <a mat-button class="button is-primary is-outlined is-sm" [href]="event.registrationUrl"
               target="_blank" attr.aria-label="Register for {{event.title}}" rel="noopener" *ngIf="event.registrationUrl">Register</a>
            <a mat-button class="button is-primary is-outlined is-sm" [href]="event.interestUrl"
               [target]="event.interestUrl.includes('Portal/') ? '_self' : '_blank'" attr.aria-label="Show Interest in {{event.title}}" *ngIf="event.interestUrl">Learn More</a>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <app-message-notification *ngIf="!filteredEvents.length">You do not have any upcoming events.</app-message-notification>
</div>