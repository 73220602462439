<!-- Modal view -->
<!-- Upload Form If has Case Status -->
<form class="flex flex-col h-full" *ngIf="isModalView && !loading && (showCaseStatusView || showPolicyStageView)"
      [formGroup]="agentResponseForm" (ngSubmit)="resolveRequirement(caseStatus ? caseStatus.TaskId : null)" @fadeIn>
    <section class="flex items-center flex-none p-20 border-b border-solid border-gray-lighter">
        <h2 class="m-10 text-lg font-bold" *ngIf="requirementModalHeader">{{requirementModalHeader!.Carrier}} -
            {{requirementModalHeader!.Product}}</h2>
        <h2 class="m-10 text-lg font-bold" *ngIf="!requirementModalHeader">Outstanding Requirement</h2>
        <button mat-button tabindex="-1" type="button" mat-dialog-close
                class="m-10 ml-auto icon-button is-primary is-inverted"><i class="material-icons">close</i></button>
    </section>
    <div class="relative flex items-center justify-center h-full p-20 m-auto loader-wrap" *ngIf="loading">
        <div class="loader-sm is-blue"></div>
    </div>
    <section class="flex flex-col flex-auto p-20 overflow-auto" *ngIf="!loading && (caseStatus || policyStage)">
        <div class="flex flex-col m-10 text-center" [ngClass]="{'text-orange': caseStatus && caseStatus.ActionNeeded}">
            <i *ngIf="caseStatus && caseStatus.ActionNeeded" class="mb-10 fig-48px fig-alert-triangle"></i>
            <h2 class="mb-10 text-xl font-bold">{{caseStatus ? caseStatus.TaskName : policyStage}}</h2>
            <label class="mb-5 text-sm font-bold uppercase text-gray-dark">{{latestNote ? (latestNote.CreatedOn | date:
                'MMM dd,yyyy | hh:mma') : (policyStageDate | date: 'MMM dd,yyyy | hh:mma' : 'UTC') }}</label>
            <label *ngIf="caseStatus && latestNote && latestNote.CreatedBy" class="text-xs font-bold uppercase">By {{latestNote.CreatedBy}}</label>
            <button type="button" mat-button class="mx-auto mt-20 button is-sm is-primary is-inverted" mat-dialog-close
                    [routerLink]="[detailsUrl]">
                View policy details
            </button>
        </div>
        <div class="flex flex-col items-center flex-shrink-0 p-20 m-10 rounded min-h-256 bg-blue-lightest">
            <p *ngIf="latestNote || policyStage" class="my-auto break-words whitespace-pre-line">
                {{latestNote ? latestNote.Note : 'Policy is ' + policyStage + ' as of ' + (policyStageDate | date: 'MMM dd,yyyy' : 'UTC' )}}
            </p>
            <p *ngIf="!latestNote && !policyStage" class="my-auto break-words whitespace-pre-line">
                Policy status is unavailable
            </p>
        </div>
        <ul class="flex flex-col p-20 m-10 gap-y-20" *ngIf="fileArray.value.length">
            <li *ngFor="let item of fileArray.value; index as i" class="flex items-center mb-10 text-sm font-medium">
                <span class="mr-auto">{{item.file.name}}</span>
                <button type="button" mat-button class="ml-10 icon-button is-waring is-inverted is-sm"
                        (click)="removeFile(i)" attr.aria-label="Remove file" matTooltip="Remove File">
                    <span class="material-icons mi-18">
                        close
                    </span>
                </button>
            </li>
        </ul>
    </section>
    <section class="flex flex-col flex-none border-t border-solid p-30 border-gray-lighter" [hidden]="hideResponseSubmission">
        <div class="flex items-start flex-none">
            <button mat-button type="button" attr.aria-label="Attach Files"
                    class="relative m-10 icon-button is-primary is-inverted text-gray">
                <span class="material-icons">
                    attach_file
                </span>
                <input type="file" multiple accept=".pdf,.png,.jpg,.jpeg" (change)="onFileUpload($event)"
                       class="absolute top-0 left-0 w-full h-full opacity-0" />
                <span
                      class="absolute top-0 right-0 flex items-center justify-center -m-5 text-xs leading-none text-white rounded-full w-18 h-18 bg-blue"
                      *ngIf="fileArray.value.length">
                    <span>{{fileArray.value.length}}</span>
                </span>
            </button>
            <input formControlName="message" type="text" class="flex-grow m-10 input is-inverted bg-gray-lightest"
                   placeholder="Enter a message" />
            <button mat-button type="submit"
                    [disabled]="(caseStatus && !formMessage.value && !fileArray.value.length) || (!caseStatus && !fileArray.value.length)  || isUploading"
                    attr.aria-label="Send message" class="m-10 is-primary">
                <span class="flex items-center justify-center">
                    <span>Send Message</span>
                </span>
            </button>
        </div>
        <div class="flex-none p-10 m-10 rounded" [class]="isSuccess ? 'text-green bg-green-lightest': 'text-red bg-red-lightest'" *ngIf="showFeedback" @fadeIn>{{feedbackMessage}}</div>
    </section>
</form>

<!-- Inline View -->
<section class="flex flex-col" *ngIf="!isModalView && !loading && (showCaseStatusView || showPolicyStageView)" @fadeIn>
    <div class="relative flex items-center justify-center p-20 m-auto loader-wrap" *ngIf="loading">
        <div class="loader-sm is-blue"></div>
    </div>

    <div class="flex flex-col p-10 md:px-40">
        <div class="flex flex-col m-10 mt-0 mb-40 text-center md:m-20" [ngClass]="{'text-orange': caseStatus && caseStatus.ActionNeeded}">
            <i *ngIf="caseStatus && caseStatus.ActionNeeded" class="mb-10 fig-48px fig-alert-triangle"></i>
            <h2 class="mb-10 text-xl font-bold">{{caseStatus ? caseStatus.TaskName : policyStage}}</h2>
            <label class="mb-5 text-sm font-bold uppercase text-gray-dark">{{latestNote ? (latestNote.CreatedOn | date: 'MMM dd,yyyy | hh:mma' : 'UTC') : (policyStageDate | date: 'MMM dd,yyyy | hh:mma' : 'UTC') }}</label>
            <label *ngIf="caseStatus && latestNote && latestNote.CreatedBy" class="text-xs font-bold uppercase">By
                {{latestNote.CreatedBy}}</label>
            <button *ngIf="caseStatus && caseStatus.Notes.length && !isModalView"
                    mat-button
                    class="mx-auto mt-10 button is-primary is-inverted"
                    type="button"
                    (click)="showCaseStatusNotes(caseStatus.Notes)">View Past Updates</button>
        </div>
        <!-- Upload Form If has Case Status -->
        <form class="flex flex-col flex-none m-10 text-center md:m-20 min-h-256"
              [formGroup]="agentResponseForm" (ngSubmit)="resolveRequirement(caseStatus ? caseStatus.TaskId : null)">
            <div
                 class="flex flex-col flex-grow p-20 border border-solid rounded-t-lg bg-opacity-10 bg-blue-lighter border-gray-lighter">
                <span *ngIf="latestNote || policyStage" class="my-auto break-words whitespace-pre-line">{{latestNote ? latestNote.Note : 'Policy is ' +
                    policyStage + ' as of ' + (policyStageDate | date: 'MMM dd,yyyy' : 'UTC' )}}</span>
                <span *ngIf="!latestNote && !policyStage" class="my-auto break-words whitespace-pre-line">Policy status unavailable</span>
            </div>
            <div
                 class="flex items-start flex-none mb-10 border-b border-l border-r border-solid rounded-b-lg border-gray-lighter" [ngClass]="{'hidden': hideResponseSubmission}">
                <button mat-button type="button" attr.aria-label="Attach files"
                        class="relative m-10 mr-0 icon-button is-primary is-inverted text-gray">
                    <span class="material-icons">
                        attach_file
                    </span>
                    <input type="file" multiple accept=".pdf,.png,.jpg,.jpeg" (change)="onFileUpload($event)"
                           class="absolute top-0 left-0 w-full h-full opacity-0" />
                    <span
                          class="absolute top-0 right-0 flex items-center justify-center -m-5 text-xs leading-none text-white rounded-full w-18 h-18 bg-blue"
                          *ngIf="fileArray.value.length">
                        <span>{{fileArray.value.length}}</span>
                    </span>
                </button>
                <input formControlName="message" type="text" class="flex-grow m-10 input is-inverted"
                       placeholder="Enter a message" />
                <button mat-button type="submit"
                        [disabled]="(caseStatus && !formMessage.value && !fileArray.value.length) || (!caseStatus && !fileArray.value.length)  || isUploading"
                        attr.aria-label="Send message" class="m-10 ml-0 icon-button is-primary is-inverted">
                    <span class="material-icons">
                        send
                    </span>
                </button>
            </div>
            <ul class="flex flex-col p-10 border border-solid rounded-lg border-gray-lighter gap-y-10"
                *ngIf="fileArray.value.length">
                <li *ngFor="let item of fileArray.value; index as i" class="flex items-center text-sm font-medium">
                    <span class="mr-auto">{{item.file.name}}</span>
                    <button type="button" mat-button class="ml-10 icon-button is-warning is-inverted is-sm"
                            (click)="removeFile(i)" attr.aria-label="Remove file" matTooltip="Remove File">
                        <span class="material-icons mi-18">
                            close
                        </span>
                    </button>
                </li>
            </ul>
        </form>
        <div class="p-10 m-10 mt-0 rounded md:mt-0 md:m-20" [class]="isSuccess ? 'text-green bg-green-lightest': 'text-red bg-red-lightest'" *ngIf="showFeedback" @fadeIn>{{feedbackMessage}}</div>
    </div>
</section>

<!-- TODO: For life/ltc, may need refactoring -->
<section *ngIf="showRequirementView && !showCaseStatusView && !showPolicyStageView"
         [ngClass]="{'p-20 gap-y-30' : !isModalView}" class="flex flex-col h-full overflow-hidden" @fadeIn>
    <div class="flex" [class]="isModalView ? 'flex-col' : 'justify-between'">
        <div class="flex items-center flex-shrink-0" [ngClass]="{'p-30 border-b border-solid border-gray-lighter': isModalView}">
            <h3 class="text-lg font-bold">Case Requirements</h3>
            <button mat-button tabindex="-1" type="button" mat-dialog-close *ngIf="isModalView"
                    class="ml-auto icon-button is-primary is-inverted"><i class="material-icons">close</i></button>
        </div>
        <div class="flex justify-end flex-none" [ngClass]="{'py-10 border-b border-solid px-30 border-gray-lighter' : isModalView}">
            <div class="flex items-center flex-none ml-auto gap-x-10">
                <span class="text-sm text-gray">Filter by Status: </span>
                <mat-button-toggle-group class="is-sm" [(ngModel)]="selectedRequirementStatus" (ngModelChange)="filterRequirementByStatus(selectedRequirementStatus.ID)">
                    <mat-button-toggle class="w-96" *ngFor="let item of requirementStatusList" [value]="item">{{item.Name}}</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>
    </div>
    <ul class="flex flex-col gap-y-10" [ngClass]="{'flex-grow overflow-auto p-30': isModalView}">
        <li *ngFor="let item of filteredRequirements | orderBy: ['-ActionNeeded', '-ModifiedOn']"
            @fadeIn
            class="flex flex-col p-20 rounded gap-y-10"
            [class]="item.ActionNeeded ? 'bg-orange-lightest' : 'bg-gray-lightest'">
            <div class="flex">
                <i class="flex-shrink-0 mt-4 mr-10 material-icons mi-18"
                   [class]="item.ActionNeeded ? 'text-orange' : 'text-blue-light'">{{item.ActionNeeded ? 'warning' :
                    'info'}}</i>
                <div class="flex-grow">
                    <h3 class="font-bold" [class]="item.ActionNeeded ? 'text-orange' : ''">{{item.TaskName}}</h3>
                    <p class="text-sm text-gray">{{item.ModifiedOn | date}}</p>
                </div>
                <button *ngIf="item.Notes.length" mat-button
                        class="ml-20 button is-primary is-sm is-inverted" type="button" (click)="showCaseStatusNotes(item.Notes)">View Past Updates</button>
            </div>
            <div class="flex flex-col ml-30 gap-y-10">
                <ng-container *ngFor="let note of item.Notes | orderBy: '-ModifiedOn'; let first = first">
                    <div class="flex flex-shrink-0" *ngIf="first">
                        <p class="my-auto mr-20 break-words whitespace-pre-line">
                            {{note.Note}}
                        </p>
                        <span class="flex flex-col flex-shrink-0 ml-auto text-sm text-right text-gray w-96">
                            <span>{{note.CreatedBy}}</span>
                            <span>{{note.ModifiedOn | date}}</span>
                        </span>
                    </div>
                </ng-container>
            </div>
            <div [hidden]="hideResponseSubmission">
                <form #form="ngForm" (ngSubmit)="resolveLifeLtcRequirement(item, form)" class="flex mt-10 gap-x-10">
                    <button mat-button type="button" attr.aria-label="Attach files" [disabled]="isUploading"
                            class="relative icon-button is-primary is-inverted text-gray">
                        <span class="material-icons">
                            attach_file
                        </span>
                        <input type="file" multiple accept=".pdf,.png,.jpg,.jpeg" (change)="onFileUpload($event, item)"
                               name="FileArray" class="absolute top-0 left-0 w-full h-full opacity-0" />
                        <span
                              class="absolute top-0 right-0 flex items-center justify-center -m-5 text-xs leading-none text-white rounded-full w-18 h-18 bg-blue"
                              *ngIf="item.FileArray.length">
                            <span>{{item.FileArray.length}}</span>
                        </span>
                    </button>
                    <input type="text" class="flex-grow input" name="Message" [(ngModel)]="item.Message"
                           [required]="item.FileArray.length == 0 ? true : false" />
                    <button mat-button type="submit" class="button is-primary" [disabled]="form.invalid || isUploading">Send Message</button>
                </form>
            </div>

            <ul *ngIf="item.FileArray.length" class="flex flex-wrap border-t border-solid border-gray-lighter">
                <li *ngFor="let file of item.FileArray; index as fileIndex" class="flex items-center w-1/3 p-10 text-sm">
                    <span class="mr-auto">{{file.name}}</span>
                    <button type="button" mat-button class="ml-10 icon-button is-warning is-inverted is-sm" [disabled]="isUploading"
                            (click)="removeFile(fileIndex, item)" attr.aria-label="Remove file" matTooltip="Remove File">
                        <span class="material-icons mi-18">
                            close
                        </span>
                    </button>
                </li>
            </ul>
            <div class="p-10 rounded" [class]="isSuccess ? 'text-green bg-green-lightest': 'text-red bg-red-lightest'" *ngIf="form.submitted && showFeedback" @fadeIn>{{feedbackMessage}}</div>
        </li>
    </ul>
</section>