<div class="flex flex-col p-20 bg-white w-300 sm:shadow-none">
  <div *ngIf="reportType == 'Fixed'" class="flex flex-col mb-20">
    <label class="mb-10 text-sm font-semibold text-gray">Lines of Business:</label>
    <ul class="flex flex-col">
      <li>
        <mat-checkbox class="mb-10 checkbox" [(ngModel)]="allLobsSelected"
          (ngModelChange)="toggleAllCheckboxes(lobs, selectedLobIds, allLobsSelected, 'LinesOfBusiness')">Select All
        </mat-checkbox>
      </li>
      <li *ngFor="let line of lobs">
        <mat-checkbox class="mb-10 checkbox" [(ngModel)]="line.Selected"
          (ngModelChange)="toggleCheckbox(lobs, selectedLobIds, line)">{{line.Name}}</mat-checkbox>
      </li>
    </ul>
  </div>
  <div class="flex flex-col mb-20" *ngIf="isLifeCycle">
    <label class="mb-10 text-sm font-semibold text-gray">Select Inforce Date Range:</label>
    <ul class="flex flex-col">
      <li class="mb-10">
        <mat-form-field appearance="fill">
          <mat-label>Start Date</mat-label>
          <input matInput [matDatepicker]="startDate"
            [(ngModel)]="selectedStartDate" (ngModelChange)="onDateRangeChange()"
            [matDatepickerFilter]="validDate" disabled>
          <mat-datepicker-toggle matSuffix [for]="startDate" class="text-blue-dark"></mat-datepicker-toggle>
          <mat-datepicker #startDate disabled="false"></mat-datepicker>
        </mat-form-field>
      </li>
      <li class="mb-10">
        <mat-form-field appearance="fill">
          <mat-label>End Date</mat-label>
          <input matInput [matDatepicker]="endDate"
            [(ngModel)]="selectedEndDate" (ngModelChange)="onDateRangeChange()"
            [matDatepickerFilter]="validDate" disabled>
          <mat-datepicker-toggle matSuffix [for]="endDate" class="text-blue-dark"></mat-datepicker-toggle>
          <mat-datepicker #endDate disabled="false"></mat-datepicker>
        </mat-form-field>
      </li>
    </ul>
  </div>
  <div class="flex flex-col" *ngIf="subEntities && subEntities.length > 0 && reportType !== 'Disability'">
    <label class="mb-10 text-sm font-semibold text-gray">Commission Groups:</label>
    <ul class="flex flex-col">
      <li *ngFor="let subEntity of subEntities">
        <mat-checkbox class="mb-10 checkbox" [(ngModel)]="subEntity.Selected"
          (ngModelChange)="toggleCheckbox(subEntities, selectedSubEntities, subEntity)">{{subEntity.CommissionGroup}}</mat-checkbox>
      </li>
    </ul>
  </div>
  <div class="flex flex-col" *ngIf="agents && agents.length > 0">
    <label class="mb-10 text-sm font-semibold text-gray">Sub-agents:</label>
    <ul class="flex flex-col">
      <li>
        <mat-checkbox class="mb-10 checkbox" [(ngModel)]="allAgentsSelected"
          (ngModelChange)="toggleAllCheckboxes(agents, selectedAgentIds, allAgentsSelected, 'Agents')">Select All
        </mat-checkbox>
      </li>
      <li *ngFor="let agent of agents">
        <mat-checkbox class="mb-10 checkbox" [(ngModel)]="agent.Selected"
          (ngModelChange)="toggleCheckbox(agents, selectedAgentIds, agent)">{{agent.FirstName}}
          {{agent.LastName}} {{agent.CurrentUserTag}}</mat-checkbox>
      </li>
    </ul>
  </div>
</div>