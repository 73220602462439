import { orderBy } from 'lodash';
import { AdditionalResource, ResourcePage, PurchasePageData } from 'src/app/shared/models/resource-page.models';
import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { RESOURCE_PAGE_CONSTANTS } from 'src/app/shared/constants/resourcePages.constants';
import { Title } from '@angular/platform-browser';
import { fadeIn } from 'src/app/animations';
import { MatDialog } from '@angular/material/dialog';
import { EnrollmentFormComponent } from 'src/app/portal/modals/enrollment-form/enrollment-form.component';
import { SsoService } from 'src/app/core/services/sso.service';
import { PermissionService } from 'src/app/core/services/auth/permission.service';
import { PurchaseModalComponent } from 'src/app/portal/modals/purchase-modal/purchase-modal.component';
import { OrderModel } from 'src/app/shared/models/order.models';
import { PurchaseApiService } from 'src/app/core/services/http/purchase-api.service';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { EnrollmentFormApiService } from 'src/app/core/services/http/enrollment-form-api.service';
import { ConfirmationComponent } from 'src/app/portal/modals/confirmation/confirmation.component';
import { User, UserDelegate } from 'src/app/shared/models/user.models';
import { UserApiService } from 'src/app/core/services/http/user-api.service';

@Component({
  selector: 'app-resource-details',
  templateUrl: './resource-details.component.html',
  animations: [fadeIn]
})
export class ResourceDetailsComponent implements OnInit, AfterViewInit {
  categoryId: string = '';
  filteredResources: AdditionalResource[] = [];
  hideEnrollment: boolean = false;
  loading: boolean = false;
  user!: User;
  onBehalfOfUser!: User | null;
  delegates: UserDelegate[] = [];
  delegateId!: string | null;
  orderBy = orderBy;
  resource!: ResourcePage; //= this.resourcePages[0];
  resourceUrl: string = '';
  searchText: string = '';
  showFilteredList: boolean = false;
  submitted?: { surveyId: number, isEnrolled: boolean }[] = [];
  purchased?: OrderModel | null;
  intervals: any[] = [];
  @ViewChild('dashboardContainerContent')
  private dashboardContentDiv!: ElementRef<HTMLElement>

  constructor(
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private enrollmentFormApiService: EnrollmentFormApiService,
    private permissionService: PermissionService,
    private purchaseApiService: PurchaseApiService,
    @Inject(RESOURCE_PAGE_CONSTANTS) private resourcePages: ResourcePage[],
    private route: ActivatedRoute,
    private sessionStore: SessionStoreService,
    private userApiService: UserApiService,
    private ssoService: SsoService,
    private titleService: Title,) { }

  ngOnInit(): void {
    this.configureUserInfo();
    this.resourceUrl = this.getResourceUrl();
    this.resource = this.resourcePages.find(page => page.Url.toLowerCase() === this.getResourceUrl().toLowerCase()) || this.resourcePages[0];
    this.hideEnrollment = !!this.resource.AccessPermissions
      ? this.permissionService.hasOnePermission(this.resource.AccessPermissions)
      : false;
    this.titleService.setTitle(this.resourceUrl + ' | The Fig Platform | Agent Portal');

    //redirect to platforms if view permission exists, and user doesn't have permission
    if (!!this.resource.ViewPermissions && !this.permissionService.hasOnePermission(this.resource.ViewPermissions)) window.location.href = '/Portal/FigPlatform/';

    if (this.resource.Url.toLowerCase() === 'Cannex'.toLowerCase()) {
      this.redirectToCannex();
    }
    if (this.resource.Url.toLowerCase() === 'AnnuityRateWatch'.toLowerCase()) {
      this.redirectToAnnuityRateWatch();
    }

    this.resource.EnrollmentSurveyData?.SurveyIds.forEach(surveyId => {
      this.enrollmentFormApiService.isEnrolled(surveyId).subscribe(isEnrolled => {
        if (this.submitted) {
          this.submitted.push({ surveyId: surveyId, isEnrolled: isEnrolled });
        }
      });
    });
    this.verifyPurchased();

    // added to fix scroll to top issue
    (this.dashboardContentDiv?.nativeElement as HTMLElement).scrollTop = 0;
  }
  ngAfterViewInit(): void {
    this.route.fragment.subscribe((fragment: string) => {
      this.categoryId = fragment;
    });
  }

  clearSearch(): void {
    this.searchText = '';
    this.showFilteredList = false;
  }

  getResourceUrl(): string {
    return this.activatedRoute.snapshot.paramMap.get('resource')!;
  }

  configureUserInfo(): void {
    this.user = this.sessionStore.User;
    this.onBehalfOfUser = this.sessionStore.OnBehalfOfUser;
    this.userApiService.getUsersForDelegateUser(this.user.Id).subscribe(res => {
      this.delegates = res;
      this.delegateId = this.permissionService.getUserDelegateId();

      if (this.delegateId !== null && this.onBehalfOfUser === null) {
        const delegate = this.delegates.find(d => d.Id === this.delegateId);
        this.onBehalfOfUser = delegate === undefined ? null : delegate.OnBehalfOfUser;
      }
    });
  }

  openEnrollmentModal(surveyId: Number):void {
    const dialogRef = this.dialog.open(EnrollmentFormComponent, {
      data: {
        Event: this.resource.EnrollmentSurveyData?.Event,
        SurveyId: surveyId,
        Disclaimer: this.resource.EnrollmentSurveyData?.Disclaimer
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      const survey = this.submitted?.find(item => item.surveyId === surveyId);
      if (survey && res.hasCompletedEnrollment) survey.isEnrolled = true;
    });
  }

  openExternalInterestForm():void {
    window.open(this.resource.ExternalInterestFormUrl);
  }

  openLearnMore(url: string):void {
    window.open(url, '_blank');
  }

  openPurchaseDialog(): void {
    const data = this.resource.PurchasePageData;
    if (data) {
      if (data?.ConfirmationMessage) {
        // Confirmation required
        const confirmation = this.dialog.open(ConfirmationComponent, {
          data: {
            title: data.PurchaseModel.Title,
            message: data.ConfirmationMessage
          }
        });

        confirmation.afterClosed().subscribe(result => {
          if (result) {
            // confirmation accepted
            this.purchaseResource(data);
          }
        });
      } else {
        // confirmation not required
        this.purchaseResource(data);
      }
    }
  }

  pollVerifyPurchase(paymentAmount: number): void {
    if (paymentAmount > 0) {
      this.intervals.push(<any>setInterval(() => {
        this.verifyPurchased();
      }, 3000));
    } else {
      this.verifyPurchased();
    }
  }

  purchaseResource(data: PurchasePageData): void {
    this.purchaseApiService.getAllOrders().subscribe(orders => {

      data.PurchaseModel.Orders = orders;
      this.purchaseApiService.getMarketingDollars().subscribe(agentMarketingDollars => {
        data.PurchaseModel.AgentMarketingDollars = agentMarketingDollars.Entry.MarketingDollarsTotal;
      },
      error => {
        console.log(error);
        data.PurchaseModel.AgentMarketingDollars = 0;
      },
      () => {
        // finally block
        // open the modal finally
        const ref = this.dialog.open(PurchaseModalComponent, {
          data: {
            purchaseModel: data.PurchaseModel,
          }
        });

        ref.afterClosed().subscribe(result => {
          if (result) {
            this.pollVerifyPurchase(result.paymentAmount);
          }
        });
      });
    });
  }

  redirectToAnnuityRateWatch():void {
    this.ssoService.annuityRateWatchSso();
  }

  redirectToCannex():void {
    this.ssoService.cannexSso();
  }

  redirectToIcomply():void {
    this.ssoService.iComplySso();
  }

  scrollTo(id: string):void {
    const el = document.getElementById(id);
    el?.scrollIntoView({ behavior: 'smooth' });
  }

  showAdditionalResources(): boolean {
    if (!!this.resource.AccessPermissions) {
      return this.permissionService.hasOnePermission(this.resource.AccessPermissions);
    }
    return true;
  }

  redirectToMarketplaceOne(event: boolean): void {
    let onBehalfOfId: string | undefined = undefined;
    console.log(event);
    if (this.delegateId) {
      const delegate = this.delegates.find(x => x.Id == this.delegateId!);
      if (delegate) onBehalfOfId = delegate.OnBehalfOfUserId;
    } else if (this.onBehalfOfUser) onBehalfOfId = this.onBehalfOfUser.Id;

    this.ssoService.marketPlaceOneSso(onBehalfOfId);
  }

  verifyPurchased(): any {
    this.purchased = null;
    let o: OrderModel | null = null;
    // return this.sessionStore.getUser().subscribe(user => {
    return this.purchaseApiService.getUserOrders(this.sessionStore.User.Id).subscribe(orders => {
      orders.forEach(order => {
        const match = (this.resource.PurchasePageData?.PurchaseModel.PurchaseOptions.find(po => po.MarketingStrategy === order.MarketingStrategy));
        if (match) {
          const d = new Date(order.CompletedDate as Date);
          const n = d.toLocaleDateString();
          order.PurchaseDate = n;
          this.purchaseApiService.getMarketingStrategyOfOrder(order.Id).subscribe(strategy => {
            if (strategy.DocusignUrl && strategy.DocusignUrl !== null && strategy.DocusignUrl !== '') {
              order.DocusignUrl = strategy.DocusignUrl;
            }
          });
          o = order;
        }
      });

      if (o !== null) {
        this.intervals?.forEach((interval: any) => {
          clearInterval(interval);
        });
        this.purchased = o;
      }
    });
    // });
  }
}
