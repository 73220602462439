<section class="grid grid-cols-1 gap-x-20 gap-y-20 md:gap-x-30 md:gap-y-30 md:grid-cols-4 max-w-1920 h-full" @fadeIn>
    <!-- <section class="flex flex-col col-span-1 md:col-span-3">
        <h1 class="mb-10 mr-auto text-xl font-bold leading-none md:text-2xl">
            Product Training {{carrierName ? '- ' + carrierName : ''}}
        </h1>
        <p class="text-base text-gray-dark">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus, ac blandit elit tincidunt id. Sed rhoncus, tortor sed eleifend tristique, tortor mauris molestie elit, et lacinia ipsum quam nec dui. Quisque nec mauris sit amet elit iaculis pretium sit amet quis magna. Aenean velit odio, elementum in tempus ut, vehicula eu diam. Pellentesque rhoncus aliquam mattis. Ut vulputate eros sed felis sodales nec vulputate justo hendrerit. Vivamus varius pretium ligula, a aliquam odio euismod sit amet. Quisque laoreet sem sit amet orci ullamcorper at ultricies metus viverra. Pellentesque arcu mauris, malesuada quis ornare accumsan, blandit sed diam.
        </p>
    </section> -->
    <section class="flex flex-col flex-wrap col-span-1 md:col-span-4">
        <div class="flex flex-col flex-grow">
            <a *ngIf="!hideBackButton" class="mb-10 md:mb-20 flex items-center text-sm font-medium text-blue-dark hover:text-blue-darker cursor-pointer"
            (click)="goBack();" target="_self" aria-label="Back">
            <i class="mr-5 no-underline material-icons mi-18">west</i>
            <span>Back</span>
        </a>
        <div class="flex flex-col flex-grow w-auto card">
            <div
                class="flex flex-row items-center justify-start p-10 border-b border-solid lg:p-20 border-gray-lighter">
                <div class="h-24 m-10 mr-20 flex justify-center">
                    <h2 class="text-lg font-bold">Product Training</h2>
                </div>
                <!-- <mat-checkbox class="p-5 ml-auto checkbox" [(ngModel)]="localSimon" (change)="openSimonSso(carrierName)">Local Simon
                </mat-checkbox> -->
                <app-simon-powered class="inline-block m-10 ml-auto"></app-simon-powered>
            </div>
            <div class="relative flex flex-grow">
                <div *ngIf="environment.qa" class="absolute top-0 right-0 px-10 m-10 rounded-full shadow-lg bg-gray-lighter">
                    <mat-slide-toggle class="m-10 ml-auto" [(ngModel)]="localSimon"
                        (change)="openSimonSso(searchValue)">Local Simon</mat-slide-toggle>
                </div>
                <iframe class="w-full min-h-640" [appIframeData] [src]="iframeUrl | safeUrl"></iframe>
            </div>
        </div>
    </div>
    </section>
</section>