import { Component, OnInit, Input } from '@angular/core';
import { SimonIframeUrl } from 'src/app/shared/models/simon-iframe-url.models';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-simon-education',
  templateUrl: './simon-education.component.html',
})

export class SimonEducationComponent implements OnInit {
  environment = environment
  toggleLocal: boolean = false;
  selectedNavItem?: SimonIframeUrl;
  @Input() navItems!: SimonIframeUrl[];
  iframeUrl: string = '';

  constructor() { }

  ngOnInit(): void {
    if (this.navItems) {
      this.selectedNavItem = this.navItems[0];
      this.openSimonSso(this.selectedNavItem);
    }
  }

  openSimonSso(navItem: SimonIframeUrl | undefined) {
    if (!navItem) return;
    this.iframeUrl = this.toggleLocal ? environment.simonLocalUrl : environment.simonUrl;
    this.iframeUrl += navItem.Route;
    if (navItem.Params) this.iframeUrl += '?' + navItem.Params;
    this.selectedNavItem = navItem;
  }

}