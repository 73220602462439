import { TableHeader } from 'src/app/shared/classes/table';
import { PendingTablePolicy } from '../models/pendingTablePolicy.model';

export const PendingPolicyTableHeaders: TableHeader<PendingTablePolicy>[] = [
  {
    DisplayName: 'Agent',
    ColumnName: ['Agent'],
    WidthClass: 'min-w-128',
    CompareFn: (a: PendingTablePolicy, b: PendingTablePolicy, order: 1|-1):number => {
      if (a.ActionNeeded !== b.ActionNeeded) {
        return a.ActionNeeded ? -1 : 1;
      }
      const valA = a.Agent ?? '';
      const valB = b.Agent ?? '';
      return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: 'Clients',
    ColumnName: ['Clients'],
    WidthClass: 'min-w-128',
    CompareFn: (a: PendingTablePolicy, b: PendingTablePolicy, order: 1|-1):number => {
      if (a.ActionNeeded !== b.ActionNeeded) {
        return a.ActionNeeded ? -1 : 1;
      }
      const valA = a.Clients ?? '';
      const valB = b.Clients ?? '';
      return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: 'Carrier',
    ColumnName: ['Carrier'],
    WidthClass: 'min-w-128',
    CompareFn: (a: PendingTablePolicy, b: PendingTablePolicy, order: 1|-1):number => {
      if (a.ActionNeeded !== b.ActionNeeded) {
        return a.ActionNeeded ? -1 : 1;
      }
      const valA = a.Carrier ?? '';
      const valB = b.Carrier ?? '';
      return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: 'Policy',
    ColumnName: ['Product'],
    WidthClass: '',
    CompareFn: (a: PendingTablePolicy, b: PendingTablePolicy, order: 1|-1):number => {
      if (a.ActionNeeded !== b.ActionNeeded) {
        return a.ActionNeeded ? -1 : 1;
      }
      const valA = a.Product ?? '';
      const valB = b.Product ?? '';
      return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: 'Premium',
    ColumnName: ['Premium'],
    WidthClass: '',
    CompareFn: (a: PendingTablePolicy, b: PendingTablePolicy, order: 1|-1):number => {
      if (a.ActionNeeded !== b.ActionNeeded) {
        return a.ActionNeeded ? -1 : 1;
      }
      const valA = a.Premium ?? 0;
      const valB = b.Premium ?? 0;
      return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: 'Latest Status',
    ColumnName: ['PolicyStatusDate'],
    WidthClass: 'min-w-256',
    CompareFn: (a: PendingTablePolicy, b: PendingTablePolicy, order: 1|-1):number => {
      if (a.ActionNeeded !== b.ActionNeeded) {
        return a.ActionNeeded ? -1 : 1;
      }
      const valA = a.PolicyStatusDate ?? new Date(1900, 0, 1);
      const valB = b.PolicyStatusDate ?? new Date(1900, 0, 1);
      return (valA.getTime() === valB.getTime() ? 0 : valA > valB ? 1 : -1) * order;
    },
  }
];