import { AfterViewInit, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { EAppPreferenceEnum } from 'src/app/shared/models/user-settings.model';
import { EAppOption } from '../../models/e-application.model';

@Component({
  selector: 'app-eapp-preference-selector',
  templateUrl: './eapp-preference-selector.component.html',
  styles: [
  ]
})
export class EAppPreferenceSelectorComponent implements AfterViewInit {
  firelightEmbeddedOptIn: EAppOption = {
    iconUrl: './assets/images/icons/quinci-icon-blue.svg',
    title: 'Quinci&trade; Embedded Experience',
    description: 'Experience the power of Quinci and Firelight combined by utilizing our fully-embedded e-application experience.',
    callbackBtnText: 'Choose Quinci Experience',
    eAppPreferenceType: EAppPreferenceEnum.FirelightEmbedded
  }
  firelightDirectOptIn: EAppOption = {
    iconUrl: '/Images/Icons/firelight.svg',
    title: 'Native Firelight&trade; Experience',
    description: 'Launch into native Firelight and complete all of your applications off platform.',
    callbackBtnText: 'Choose Native Experience',
    eAppPreferenceType: EAppPreferenceEnum.FirelightDirect
  }
  constructor(
    private dialogRef: MatDialogRef<EAppPreferenceSelectorComponent>,
    private sessionStore:SessionStoreService
  ) { }

  setEAppPreference(eAppPreference:EAppPreferenceEnum): void {
    this.sessionStore.updateEAppPreference(eAppPreference)
      .subscribe(() => {
        this.dialogRef.close(eAppPreference);
      });
  }

  ngAfterViewInit(): void {
    this.dialogRef.updateSize('108rem', '64rem');
    this.dialogRef.disableClose = true;
  }
}
