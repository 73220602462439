import { Injectable } from '@angular/core';
import { ExternalVendorProduct } from 'src/app/shared/models/eapps.models';
import { StateBasicModel } from 'src/app/shared/models/generic.models';

@Injectable({
  providedIn: 'root'
})
export class EAppWorkflowStoreService {

  constructor() { }
  private _product!:ExternalVendorProduct|null

  private _state!:StateBasicModel|null;

  private _clientFirstName!:string|null;

  private _clientLastName!:string|null;

  private _activityId!:string|null;

  private _isNew:boolean = true;

  clear(): void {
    this._product = null;
    this._state = null;
    this._clientFirstName = null;
    this._clientLastName = null;
    this._activityId = null;
    this._isNew = true;
  }

  setAll(product:ExternalVendorProduct, state:StateBasicModel, clientFirstName:string, clientLastName:string, activityId:string|null, isNew:boolean): void{
    this._product = product;
    this._state = state;
    this._clientFirstName = clientFirstName;
    this._clientLastName = clientLastName;
    this._isNew = isNew;
    if(activityId) this._activityId = activityId;
  }

  get Product():ExternalVendorProduct|null{
    return this._product;
  }

  set Product(product: ExternalVendorProduct|null){
    this._product = product;
  }

  get State():StateBasicModel|null{
    return this._state;
  }

  set State(state:StateBasicModel|null) {
    this._state = state;
  }

  get ClientFirstName():string|null{
    return this._clientFirstName;
  }

  set ClientFirstName(clientFirstName:string|null){
    this._clientFirstName = clientFirstName;
  }

  get ClientLastName():string|null {
    return this._clientLastName;
  }

  set ClientLastName(clientLastName:string|null){
    this._clientLastName = clientLastName;
  }

  get IsNew():boolean {
    return this._isNew;
  }

  set IsNew(isNew:boolean) {
    this._isNew = isNew;
  }

  get ActivityId():string|null {
    return this._activityId;
  }

  set ActivityId(activityId:string|null){
    this._activityId = activityId;
  }
}