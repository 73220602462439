import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fadeIn } from 'src/app/animations';
import { SimonIframeData } from 'src/app/shared/models/simon-iframe-data.model';
import { environment } from 'src/environments/environment';


@Component({
  animations: [fadeIn],
  selector: 'app-embedded-application',
  templateUrl: './embedded-application.component.html',
})
export class EmbeddedApplicationComponent implements OnInit {

  activityId!: string;
  environment = environment;
  isExpanded:boolean = false;
  isNew: boolean = true;
  localSimon: boolean = false;
  iframeData: SimonIframeData = {
    command: 'resize',
    id: 'embeddedFirelight',
  };
  iframeUrl: string = '';
  constructor(
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.activityId = params['activityId'] + '';
      this.isNew = params['isNew'] === 'true' ? true : false;
    });

    this.openSimonSso();
  }

  openSimonSso():void {
    this.iframeUrl = this.localSimon ? environment.simonLocalUrl : environment.simonUrl;

    if (this.activityId !== null) {
      this.iframeUrl += '/annuities/eapp?activityId=' + this.activityId + '&isNew=' + this.isNew;
    }
  }
}
