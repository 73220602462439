<div class="relative h-full overflow-auto" @fadeInOut>
    <div class="md:bg-transparent min-h-384" @fadeInOut>
        <div class="absolute w-full bg-center bg-no-repeat bg-cover h-384"
            style="z-index:-1;background: url('./assets/images/product-rd-wave-bg-blue.svg');"></div>
        <!--TODO:  bg-blue-darker bg-gradient-to-r from-blue-darker to-blue-darkest-->
        <section class="w-full mx-auto max-w-1280">
            <div class="z-10 flex flex-col h-full p-20 md:p-30">
                <div class="flex flex-row flex-wrap">
                    <div class="flex flex-col w-full md:w-2/3">
                        <div class="flex flex-row items-center mb-10">
                            <img class="w-auto h-48 mr-20" src="./assets/images/icons/quinci-logo-light.svg">
                            <img class="w-auto h-24 mr-auto" src="./assets/images/icons/pw-simon-dark.svg">
                        </div>
                        <h2 class="mb-10 text-2xl font-bold leading-tight text-white capitalize lg:text-3xl">
                            The Industry's First End-to-End Digital Sales&nbsp;Platform
                        </h2>
                        <p class="mb-5 text-lg text-white md:mb-10">
                            Bringing Insurance and Investments together by utilizing cutting-edge technology and
                            real-time data&nbsp;exchange.
                        </p>
                        <p class="mb-20 text-lg text-white md:mb-30">
                            Say goodbye to manual processes and embrace the evolution of your business.
                        </p>
                        <span class="flex h-34 ">
                            <button class="mr-20 button is-primary" @fadeIn mat-button *ngIf="!isLoading && !!isOptedIn"
                                (click)="scrollTo('productAvailability')">Product Availability</button>
                            <button class="mr-20 button is-primary" @fadeIn mat-button *ngIf="!isLoading && !!isOptedIn"
                                (click)="scrollTo('tutorials')">Tutorials</button>
                        </span>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div @fadeInOut>
        <section class="w-full mx-auto max-w-1280">
            <div class="flex flex-col items-center justify-center w-full mb-20 md:mb-30">
                <img src="./assets/images/product-rd-landing-img.svg" alt="Quinci desk image" class="h-auto m-20 w-384">
                <p class="mb-20 text-xl font-bold md:mb-30 center">
                    The Pillars of Quinci powered by SIMON
                </p>
                <div
                    class="flex flex-wrap items-center justify-center w-full gap-20 px-10 mx-auto mb-20 md:gap-30 md:mb-30">
                    <ng-container *ngFor="let step of journeyCategories; let index = index; let last = last">
                        <div class="flex flex-col self-stretch p-20 transition duration-150 cursor-pointer md:p-30 gap-15 card hover:shadow-lg" [routerLink]="'../../' + step.RouterUrl"
                            [class]="journeyCategories.length < 6 ? 'w-280': 'w-400'" *ngIf="index !==0" [matTooltip]="'Learn more about ' + step.Name" matTooltipPosition="below">
                            <span
                                class="flex items-center justify-center rounded-full w-36 h-36 bg-blue-lighter text-blue-dark">
                                <i class="material-icons mi-18" [textContent]="step.Icon"></i>
                            </span>
                            <h2 class="font-bold" [innerHtml]="step.Name"></h2>
                            <p class="text-sm" [innerHtml]="step.DescriptionShort"></p>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="w-full">
            </div>
        </section>
    </div>

    <div class="relative" @fadeInOut>
        <div class="absolute w-full bg-cover md:block h-60"
            style="z-index:-1;background: url('./assets/images/blue-wave-short.svg')"></div>
        <div class="min-h-60"></div>
        <!-- TODO: better responsive bg -->
    </div>
    <div class="bg-blue-darker" @fadeInOut>
        <!--TODO:  bg-blue-darker bg-gradient-to-r from-blue-darker to-blue-darkest-->
        <section class="w-full py-20 mx-auto max-w-1280 md:py-50">
            <div class="flex flex-row flex-wrap justify-between">
                <div class="flex flex-col w-full p-20 md:p-30 sm:w-1/2 lg:w-1/3">
                    <div class="flex flex-row items-center mb-10">
                        <h3 class="mr-auto text-lg font-bold leading-none text-white">
                            Cutting Edge
                        </h3>
                    </div>
                    <h2 class="mb-10 text-2xl font-bold leading-tight text-white capitalize lg:text-3xl">
                        Fully Integrated Features
                    </h2>
                    <p class="text-lg text-white md:mb-30">
                        Doing business should be simple. Bring together all the products, tools, and resources that help
                        your clients achieve their&nbsp;goals.
                    </p>
                </div>
                <div class="flex flex-row w-full p-20 sm:w-1/2 lg:w-2/3 md:pb-30">
                    <div class="flex flex-row flex-wrap justify-center mx-auto">
                        <ng-container *ngFor="let tip of fingertips">
                            <div class="flex flex-row w-full p-20 md:w-350 md:p-20">
                                <div class="mr-20">
                                    <span
                                        class="z-10 flex flex-row items-center justify-center rounded-full w-36 h-36 bg-blue-lighter text-blue-dark">
                                        <i class="material-icons mi-18" [textContent]="tip.Icon"></i>
                                    </span>
                                </div>
                                <div class="text-white">
                                    <h2 class="mb-5 text-lg font-bold leading-tight" [innerHtml]="tip.Title"></h2>
                                    <p class="text-sm" [innerHtml]="tip.Description"></p>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div class="relative" *ngIf="!isOptedIn" @fadeInOut>
        <section class="w-full py-10 mx-auto max-w-1280 md:py-30 lg:py-60">
            <div class="flex flex-col flex-grow m-20 overflow-hidden card md:m-30">
                <div class="flex flex-row w-full p-20 min-w-840 md:p-30">
                    <ng-container *ngFor="let col of columns; let first = first; let last = last">
                        <div class="flex flex-col w-1/3 justify-items-center"
                            [class]="last ? 'rounded-lg border-2 border-blue' : ''">
                            <ng-container *ngFor="let row of col.Data; let index = index">
                                <div class="flex flex-col items-center justify-center p-10 md:p-20 min-h-100"
                                    [class]="0 === index % 2 ? '' : 'bg-gray-lightest'">
                                    <h2 class="w-full text-base font-bold"
                                        [class]="first ? '' : last ? 'text-blue text-center'  : 'text-grey text-center'"
                                        [innerHtml]="row.Header" *ngIf="!!row.Header"></h2>
                                    <p class="w-full text-sm" [class]="!first ? 'text-center' : ''"
                                        [innerHtml]="row.Description" *ngIf="!!row.Description"></p>
                                    <i class="material-icons mi-24" [class]="!!row.IconClass ? row.IconClass : ''"
                                        [textContent]="row.Icon"></i>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </div>
        </section>
    </div>
    <div class="relative bg-blue-darker" *ngIf="!isOptedIn" @fadeInOut>
        <!--TODO:  bg-blue-darker bg-gradient-to-r from-blue-darker to-blue-darkest-->
        <section class="w-full mx-auto max-w-1280 py-30 md:py-60">
            <div class="flex flex-col items-center justify-center flex-grow-0">
                <h2 class="mb-10 text-3xl text-white md:mb-30 ">Take your first step into the future of insure-tech.
                </h2>
            </div>
        </section>
    </div>
    <button [disabled]="!isOptedIn" class="fixed bottom-0 right-0 m-20 button is-sm"
        *ngIf="environment.local || environment.qa" (click)="deactivateOptIn()">Deactivate (Dev)</button>
</div>