<div class="flex flex-col w-full h-full p-20 gap-y-10 card">
  <div class="flex items-start justify-between gap-x-20">
    <h2 class="text-lg font-bold">Product Training</h2>
  </div>
  <ng-container *ngIf="hasGuidePermissions">
    <ng-container *ngIf="!loading">
      <div @fadeIn *ngIf="!error.hasError" class="flex flex-col h-full mt-10 gap-y-20">
        <p>Quickly search for a product training/guide below.</p>
        <form class="flex flex-wrap gap-x-10 gap-y-10" [formGroup]="productTrainingForm" (ngSubmit)="onSubmit()">
          <mat-select formControlName="carrier"
                      class="flex-grow w-auto min-w-128 select"
                      placeholder="Select a Carrier">
            <mat-option *ngFor="let item of filteredCarriersFromEappWorkflow" [value]="item">{{item.Business}}</mat-option>
          </mat-select>
          <mat-select formControlName="product"
                      class="flex-grow w-auto min-w-128 select"
                      placeholder="Select a Product">
            <mat-option *ngFor="let item of filteredProducts" [value]="item">{{item.Product}}</mat-option>
          </mat-select>
          <button type="submit" class="flex-none button is-primary min-w-96" [disabled]="formSubmitting" mat-button>Search</button>
        </form>

        <div @fadeIn *ngIf="formSubmitted"
             class="flex items-center p-10 text-sm transition duration-150 rounded hover:bg-blue-lightest focus:bg-blue-lightest gap-x-20">
          <ng-container *ngIf="selectedProductTraining">
            <i class="material-icons mi-18 text-gray">school</i>
            <div class="flex flex-col leading-snug">
              <p class="font-bold">{{selectedProductTraining.productName}}</p>
              <p class="text-gray">{{selectedProductTraining.carrierName}}</p>
            </div>
            <span class="py-2 text-xs font-bold text-center text-white uppercase rounded mx-autopx-8 min-w-96"
                  [class]="selectedProductTraining.trainingStatus === 'Completed' ? 'bg-green' : 'bg-gray'"
                  *ngIf="selectedProductTraining.trainingStatus">{{selectedProductTraining.trainingStatus}}</span>
            <a class="ml-auto button is-primary is-outlined is-sm" mat-button
               *ngIf="selectedProductTraining.hasSimonTraining"
               [routerLink]="'/Portal/CarriersTraining/ProductTraining'" [queryParams]="{searchValue: selectedProductTraining?.productCusip}">View Training</a>
            <a class="ml-auto button is-primary is-outlined is-sm" mat-button
               *ngIf="!selectedProductTraining.hasSimonTraining"
               [href]="selectedProductTraining.trainingGuideUrl" target="_blank">View Carrier Guide</a>
          </ng-container>
          <ng-container *ngIf="!selectedProductTraining">
            <p>No product training/guide found. Please select a different one.</p>
          </ng-container>
        </div>

        <div @fadeIn *ngIf="formSubmitting && !formSubmitted"
             class="relative p-20 m-auto loader-wrap h-96">
          <div class="loader-sm is-blue"></div>
        </div>

        <img *ngIf="!selectedProductTraining && !formSubmitted && !formSubmitting"
             @fadeIn
             src="./assets/images/dashboard-study-img.svg"
             alt="Product Training Study Image" class="w-auto p-10 m-auto opacity-50 h-96">
      </div>

      <app-message-notification *ngIf="error.hasError" [panelConfig]="messagePanelConfig">{{error.message}}</app-message-notification>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!hasGuidePermissions">
    <app-message-notification>You do not have permissions to view this tile. Please contact our support staff.</app-message-notification>
  </ng-container>

  <div @fadeIn *ngIf="loading" class="relative p-20 m-auto loader-wrap">
    <div class="loader-sm is-blue"></div>
  </div>
</div>