<section class="grid grid-cols-1 gap-x-20 gap-y-20 md:gap-x-30 md:gap-y-30 md:grid-cols-4 h-full max-w-1920" @fadeIn>
    <section class="flex flex-wrap col-span-1 md:col-span-3 h-full">
        <div class="flex flex-col flex-grow w-auto h-full overflow-auto card">
            <div
                class="flex flex-row items-center justify-start p-10 border-b border-solid lg:p-20 border-gray-lighter">
                <span class="m-10 mr-0 material-icons mi-24 text-pink" aria-label="Rates and Quotes">receipt_long</span>
                <div class="h-24 m-10 mr-20">
                    <h2 class="text-lg font-bold">Annuity Rate Watch - Top MYGA</h2>
                </div>
            </div>
            <div class="p-20 md:p-30 flex flex-col items-center">
                <img class="w-400 h-auto mb-20 md:mb-40 mx-auto"
                    src="./assets/images/logos/annuity-rate-watch-logo_lg.png" @fadeIn>
                <iframe class="w-full h-1/2 min-h-400 mb-20 md:mb-40" [src]="iframeUrl | safeUrl" @fadeIn></iframe>
                <a class="my-10 md:my-20 mx-auto px-40 button is-primary button flex justify-center items-center"
                    mat-button amplitudeEvent="Annuity Rate Watch: Top MYGA - Contact Us"
                    href="mailto:andrew.legan@annuityratewatch.com?subject=Top MYGA" type="button">
                    Contact Us
                </a>
            </div>
        </div>
    </section>
</section>