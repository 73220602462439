<section class="flex flex-col w-full h-full p-20 mx-auto max-w-1440 md:p-30" @fadeIn>
	<a class="flex items-center mb-20 text-sm font-medium cursor-pointer"
		[class]="!!componentUrl ? 'text-white hover:text-blue-lightest' : 'text-blue-dark hover:text-blue-darker'"
		(click)="goBack()">
		<i class="mr-5 no-underline fig-24px fig-arrow-left"></i>
		<span class="">Back to List View</span>
	</a>
	<section class="grid grid-cols-2 gap-x-20 gap-y-20">
		<div class="flex col-span-2 text-sm font-bold rounded-lg card">
			<div class="flex items-center m-20 md:mx-40" matTooltip="Carriers">
				<span class="mr-10 material-icons text-gray mi-18">work</span>
				<div class="flex flex-col">
					<span class="text-semibold">{{informalPolicy.Carrier}}</span>
				</div>
			</div>
			<div class="flex items-center m-20 md:mx-40" *ngIf="informalPolicy.RelatedPeople"
				matTooltip="Related People">
				<span class="mr-10 material-icons text-gray">people</span>
				<span>{{informalPolicy.RelatedPeople}}</span>
			</div>
			<div class="flex items-center m-20 md:mx-40" *ngIf="informalPolicy.FaceAmount" matTooltip="Face Amount">
				<span class="mr-10 material-icons text-gray">monetization_on</span>
				<span>{{informalPolicy.FaceAmount | currency}}</span>
			</div>
			<div class="flex items-center m-20 md:mx-40" *ngIf="informalPolicy.State" matTooltip="State">
				<span class="mr-10 material-icons text-gray">place</span>
				<span>{{informalPolicy.State}}</span>
			</div>
			<div class="flex items-center m-20 md:mx-40" *ngIf="informalPolicy.AwaitingOffer"
				matTooltip="Awaiting Offer">
				<span class="mr-10 material-icons text-gray">local_offer</span>
				<span>Awaiting Offer</span>
			</div>
		</div>
		<div class="col-span-2 p-10 card">
			<ul class="flex flex-col" *ngIf="!loadingRows">
				<li class="flex flex-col p-10 border-b border-gray-lighter"
					*ngFor="let d of informalPolicyDetails.CarrierDetails">
					<div class="font-bold text-md">{{d.Carrier}}{{d.PolicyNumber ? ' - ' + d.PolicyNumber : ''}}</div>
					<div class="text-sm"><span class="font-bold">Health Classes:</span>
						{{d.HealthClasses}}{{d.HealthClassReason ? ' - ' + d.HealthClassReason : ''}}</div>
					<div class="text-sm"><span class="font-bold">Subject to:</span> {{d.OfferDetails}}</div>
				</li>
			</ul>
			<div class="relative w-full p-20 loader-wrap" *ngIf="!!loadingRows" [@fadeIn]>
				<div class="loader-sm is-blue"></div>
			</div>
		</div>
	</section>
</section>