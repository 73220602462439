import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FirelightCarrierProduct, CreateActivityRequest, CreateActivityBasicResponse, ActivityResponse, ActivityDetails, EApplicationTransactionDocument, FirelightState } from '../../../shared/models/firelightApi.models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FirelightApiService {

  constructor(private http: HttpClient) { }

  copyFirelightApplication(activityId: string, request: CreateActivityRequest): Observable<CreateActivityBasicResponse> {
    return this.http.post<CreateActivityBasicResponse>(`/api/Firelight/Api/Application/Copy/${activityId}`, request);
  }

  downloadFirelightApplicationDocuments(activityId: string, documentId: string): Observable<Blob> {
    return this.http.get(`/api/Firelight/api/Application/${activityId}/Documents/${documentId}`, { responseType: 'blob' });
  }

  getFirelightApplicationDocuments(activityId: string): Observable<EApplicationTransactionDocument[]> {
    return this.http.get<EApplicationTransactionDocument[]>(`/api/Firelight/api/Application/${activityId}/Documents`);
  }

  getFirelightApplicationHistory(excludeStatuses?: string[], limit?:number): Observable<ActivityResponse[]> {
    let url = '/api/Firelight/Api/Application/History';
    let queryParams:string[] = [];
    if(excludeStatuses) {
      queryParams = excludeStatuses.map(status => `excludedDisplayStatuses=${status}`);
    }
    if (limit) {
      queryParams.push(`limit=${limit}`);
    }
    if(queryParams.length >= 1) {
      url += `?${queryParams.join('&')}`;
    }
    return this.http.get<ActivityResponse[]>(url);
  }

  getFirelightApplicationDetails(activityId: string): Observable<ActivityDetails> {
    return this.http.get<ActivityDetails>(`/api/Firelight/Api/Application/${activityId}/Details`);
  }

  postFirelightActivityProvisionData(activityId: string): Observable<unknown> {
    return this.http.post(`/api/Firelight/Api/GetActivityProvisionData/${activityId}`, {});
  }

  getFirelightCarrierProducts(): Observable<FirelightCarrierProduct[]> {
    return this.http.get<FirelightCarrierProduct[]>('/api/Firelight/Api/CarrierProductList');
  }

  postFirelightApplication(request: CreateActivityRequest): Observable<CreateActivityBasicResponse> {
    return this.http.post<CreateActivityBasicResponse>('/api/Firelight/Api/Application', request);
  }

  getFirelightStates(): Observable<FirelightState[]> {
    return this.http.get<FirelightState[]>('/api/Firelight/api/ProductStates');
  }
}
