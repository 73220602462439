<section class="grid grid-cols-1 gap-x-20 gap-y-20 md:gap-x-30 md:gap-y-30 md:grid-cols-4 max-w-1920" @fadeIn>
    <!-- <section class="flex flex-col col-span-1 md:col-span-3">
        <h1 class="mb-10 mr-auto text-xl font-bold leading-none md:text-2xl">
            Structured Investments
        </h1>
    </section> -->
    <section class="flex flex-wrap col-span-1 md:col-span-4">
        <div class="flex flex-col flex-grow w-auto h-full card">
            <div
                class="flex flex-row items-center justify-start p-10 overflow-auto border-b border-solid lg:p-20 border-gray-lighter">
                <div class="h-24 m-10 mr-20">
                    <h2 class="text-lg font-bold">Structured Investments</h2>
                </div>
                <app-simon-powered class="inline-block m-10 ml-auto"></app-simon-powered>
            </div>
            <app-simon-education [navItems]="navItems"></app-simon-education>        
        </div>
    </section>
</section>