import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { fadeIn, slideInOut } from 'src/app/animations';
import { AmplitudeEventService } from 'src/app/core/services/amplitude/amplitude-event.service';
import { PermissionService } from 'src/app/core/services/auth/permission.service';
import { AgentLicenseApiService } from 'src/app/core/services/http/agent-license-api.service';
import { OnboardingApiService } from 'src/app/core/services/http/onboarding-api.service';
import { PermissionApiService } from 'src/app/core/services/http/permission-api.service';
import { UserApiService } from 'src/app/core/services/http/user-api.service';
import { productRdOptInService } from 'src/app/core/services/product-rd-opt-in/product-rd-opt-in.service';
import { OptInEventService } from 'src/app/core/services/stores/opt-in-event.service';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { termsOfUseUrl } from 'src/app/shared/constants/fig-terms-of-use.constants';
import { AgentLicenses } from 'src/app/shared/models/agent.models';
import { ConfirmationData } from 'src/app/shared/models/modal-data.models';
import { OnboardingTermsOfUse } from 'src/app/shared/models/onboarding.models';
import { QuinciOptInDataModel } from 'src/app/shared/models/product-rd-optin-data.models';
import { User } from 'src/app/shared/models/user.models';
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { TermsOfUseModalComponent } from '../product-rd-tou/terms-of-use/terms-of-use-modal.component';

@Component({
  animations: [fadeIn, slideInOut],
  selector: 'app-countdown-opt-in',
  templateUrl: './countdown-opt-in.component.html',
})
export class CountdownOptInComponent implements OnInit, AfterViewInit {
  isLoading = false;
  termsOfUseUrl: string = termsOfUseUrl.figSimon;
  @ViewChild('optInForm') optInForm!: NgForm;
  confirmOptOut: boolean = false;
  showOptOutConfirmation: boolean = false;
  onBehalfOfUser: User | null;
  agentLicenses?: AgentLicenses;
  termsOfUse?: OnboardingTermsOfUse;
  isOptedIn: boolean = false;
  isArcUser: boolean = false;
  model: { userAccepts: boolean, agentLicenses: AgentLicenses } = {
    userAccepts: true,
    agentLicenses: {
      Npn: null,
      Crd: null
    },
  };
  requiresLicensesCapture: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<CountdownOptInComponent>,
    private dialog: MatDialog,
    private agentLicenseApiService: AgentLicenseApiService,
    // private amplitudeEventService: AmplitudeEventService,
    private onboardingApiService: OnboardingApiService,
    private router: Router,
    private sessionStoreService: SessionStoreService,
    private userApiService: UserApiService,
    private snackBar: MatSnackBar,
    private permissionApiService: PermissionApiService,
    private permissionService: PermissionService,
    private optInEventService: OptInEventService,
  ) {
    this.onBehalfOfUser = this.sessionStoreService.OnBehalfOfUser;
  }

  ngOnInit(): void {
    this.getUserData();
  }

  ngAfterViewInit(): void {
    this.dialogRef.disableClose = true;
  }

  getUserData(): void {
    //disable this workflow for impersonation
    if (this.onBehalfOfUser) return;
    this.isLoading = true;
    forkJoin({
      isArcUser: this.userApiService.getIsArcUser().pipe(catchError(error => of(error))),
      isOptedIn: this.userApiService.getIsOptedIn().pipe(catchError(error => of(error))),
      termsOfUse: this.onboardingApiService.getLoggedInUserTermsOfUse().pipe(catchError(error => of(error))),
    }).subscribe(res => {
      this.isArcUser = res.isArcUser;
      this.isOptedIn = res.isOptedIn;
      this.model.agentLicenses = this.sessionStoreService.User.AgentLicenses;
      if (!this.model.agentLicenses.Crd || !this.model.agentLicenses.Npn) {
        this.requiresLicensesCapture = true;
      }
      this.termsOfUse = res.termsOfUse;
      this.isLoading = false;
    });
  }

  declineEnrollment(): void {
    // this.dialog.open<ConfirmationComponent, ConfirmationData>(ConfirmationComponent, {
    //   data: {
    //     message: 'Are your sure you want to opt out of your Quinci enrollment? By November 15, 2021, you will need to enroll into Quinci to avoid any interuption to your services.',
    //     isWarning: true,
    //     confirmationButtonText: 'Ignore for now'
    //   }
    // }).afterClosed().subscribe(res => {
    //   if (res) this.dialogRef.close();
    //   else return;
    // });

    this.dialogRef.close();
  }

  learnMore() {
    this.dialogRef.afterClosed().subscribe(() => {
      this.router.navigate(['/Portal/ProductRD']);
    });
    this.dialogRef.close();
  }

  submitEnrollment(delay: number = 0) {
    if (this.optInForm.invalid) return;
    this.dialogRef.afterClosed().subscribe(res => {
      if (res.userAccepts && !this.onBehalfOfUser) {
        this.activateOptIn();
        if (!this.termsOfUse || !this.termsOfUse.HasAgreedToTermsOfUse) this.updateProductRDTermsOfUse();
        if (res.agentLicenses) this.updateAgentLicenses(res.agentLicenses);
      } else {
        this.router.navigate(['/Portal/ProductRDPreview']);
      }
    });
    // We pass the form model here and then it gets access via dialog.afterClosed() above
    this.dialogRef.close(this.model);
  }

  updateProductRDTermsOfUse() {
    this.onboardingApiService.acceptTermsOfUse().subscribe(
      res => { },
      error => this.openSnackBar('Sorry, an error has occurred. Please try again later or contact us for support.')
    );
  }

  activateOptIn() {
    this.isLoading = true;
    this.isOptedIn = true;
    this.userApiService.updateOptIn().subscribe(
      res => {
        this.permissionApiService.getPolicyStatements().subscribe(permissions => {
          this.isLoading = false;
          this.permissionService.setPermissions(permissions);
          this.optInEventService.broadcastIsOptin(true);
          this.router.navigate(['/Portal/ProductRD/Preview']);
        });
      },
      error => {
        this.isOptedIn = false;
        this.openSnackBar('Sorry, an error has occurred. Please try again later or contact us for support.');
        Observable.throw(new Error(error));
      });
  }

  updateAgentLicenses(agentLicenses: AgentLicenses) {
    if (!!agentLicenses.Crd || !!agentLicenses.Npn)
      this.sessionStoreService.setAgentLicenses(agentLicenses.Crd!, agentLicenses.Npn!).subscribe(
        res => { },
        error => this.openSnackBar('Sorry, an error has occurred. Please try again later or contact us for support.')
      );
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'close');
  }
}
