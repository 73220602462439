import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { MasterAdvisorAgreement } from 'src/app/shared/models/agreement.models';
import { AgreementsApiService } from '../http/agreements-api.service';

@Injectable({
  providedIn: 'root'
})
export class UserMasterAdvisorAgreementResolver implements Resolve<Observable<MasterAdvisorAgreement|null>> {

  constructor(private agreementsApi:AgreementsApiService) { }

  resolve(route:ActivatedRouteSnapshot, state:RouterStateSnapshot): Observable<MasterAdvisorAgreement|null>{
    return this.agreementsApi.getLoggedInUserMasterAdvisorAgreement();
  }
}
