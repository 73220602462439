import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SessionStoreService } from '../../stores/session-store.service';

@Injectable({
  providedIn: 'root',
})
export class DevelopmentGuardService implements CanActivate {
  constructor(
    private router: Router,
    private sessionStore: SessionStoreService
  ) { }
  private allowedUserIds: Array<string | null> = [environment.figPortalTestUserId, environment.figAppTestUserId];

  private isAllowedUser(): boolean {
    return this.allowedUserIds.includes(this.sessionStore.User.Id);
  }

  canActivate(): boolean {
    //Enable route only in dev environments. Disable & redirect if production or beta.
    if ((environment.production || environment.beta) && !this.isAllowedUser()) {
      this.router.navigate(['Portal/404']);
      return false;
    } else {
      return true;
    }
  }
}