<header
  class="fixed top-0 left-0 z-50 flex flex-row items-center justify-start w-full h-64 px-20 bg-white shadow"
  [@fadeIn]>
  <a class="items-start justify-center hidden m-10 mr-auto uppercase sm:flex max-w-256 hover:no-underline focus:no-underline text-ferra"
    attr.aria-label="Portal Onboarding">
    <img class="hidden h-48 sm:block" src="./assets/images/fig-logo.svg" alt="Agent Portal" *ngIf="!logo">
    <img class="block w-auto h-36 sm:hidden" src=".\assets\images\fig-logo-tri-red.svg" alt="Agent Portal"
      *ngIf="!logo">
    <img class="w-auto h-48" [src]="logo" *ngIf="logo">
  </a>

  <button mat-button class="m-10 ml-auto button is-lg is-inverted" [matMenuTriggerFor]="userMenu">
    <span class="flex flex-row items-center justify-center">
      <span class="flex flex-col leading-tight text-right">
        <span class="text-sm font-bold">{{user && user.FirstName ? user.FirstName + ' ' + user.LastName :
          'User'}}</span>
        <span class="text-sm font-normal" *ngIf="onBehalfOfUser">Viewing As
          {{onBehalfOfUser.FirstName + ' ' + onBehalfOfUser.LastName }}</span>
      </span>
      <span
        class="flex flex-row items-center justify-center ml-10 overflow-hidden rounded-full bg-gray-lightest w-36 h-36">
        <span class="text-sm font-bold uppercase text-blue-dark">{{user && user.FirstName ?
          user.FirstName[0] + user.LastName[0] : 'US'}}</span>
      </span>
    </span>
  </button>
  <mat-menu #userMenu="matMenu">
    <label class="block mb-10 text-sm text-gray">
      Viewing As:
    </label>
    <button mat-menu-item class="flex items-center justify-start button is-lg" (click)="selectMeAsUser()">
      <span>Myself&nbsp;({{user ? user.FirstName + ' ' + user.LastName : 'User Name'}})</span>
      <!-- <i *ngIf="!delegateId" class="ml-10 fig-24px fig-eye-filled ng-scope"></i> -->
      <span class="ml-10 mi-18 material-icons text-gray" *ngIf="!delegateId">visibility</span>
    </button>
    <ng-container *ngIf="!!delegates">
      <button mat-menu-item class="flex items-center justify-start" *ngFor="let d of delegates"
        (click)="selectOnBehalfOfUser(d.Id)">
        <span>{{d.OnBehalfOfUser.FirstName + ' ' + d.OnBehalfOfUser.LastName}}</span>
        <span class="ml-10 mi-18 material-icons text-gray" *ngIf="delegateId===d.Id">visibility</span>
      </button>
    </ng-container>

    <span class="block my-10 border-b border-solid border-gray-lighter"></span>
    <button mat-menu-item class="flex flex-row items-center justify-start text-left button is-lg is-warning is-inverted"
      (click)="logout()">
      <span class="mr-10 mi-18 material-icons">power_settings_new</span><span>Log Out</span>
    </button>
  </mat-menu>
</header>
<section *ngIf="!isLoading" class="fixed top-0 bottom-0 left-0 right-0 z-0 flex p-40 mt-64 overflow-hidden">
  <section class="flex justify-center w-full h-full my-auto max-h-960">
    <section *ngIf="navItems && navItems.length > 1"
      class="flex-col flex-none hidden h-full overflow-auto shadow sm:flex md-sidenav-left sm:w-320 lg:-ml-320 sm:z-40 sm:bg-transparent sm:shadow-none sm:mr-30 p-30"
      id="onboardingMainNav" [@fadeInOut]>
      <nav class="flex flex-col flex-grow onboarding-nav">
        <div class="flex flex-col">
          <div class="relative flex flex-row mb-20 onboarding-nav-item focus:outline-none"
            *ngFor="let item of navItems; let navIndex = index" [ngClass]="{
                      'is-active':  currentNavItemIndex === navIndex || navIndex < currentNavItemIndex}">
            <span class="flex-none w-20 h-20 m-10 border border-solid rounded-full onboarding-checkbox">
            </span>
            <div class="w-full">
              <h2 class="my-10 font-bold leading-tight capitalize onboarding-nav-label" [textContent]="item.Name"></h2>
              <p class="text-sm text-gray" [innerHtml]="item.Description"></p>
            </div>
          </div>
        </div>
      </nav>
    </section>
    <section class="flex flex-col w-full h-full overflow-hidden md:w-720 card">
      <section class="flex w-full h-full overflow-auto" [@fadeIn]>
        <router-outlet (activate)="onActivate($event)" [@fadeIn]></router-outlet>
      </section>
    </section>
  </section>
</section>