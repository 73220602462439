<div class="h-full bg-cover" style="background: url('./assets/images/wave-bg.svg');" @fadeIn>
  <div class="flex-col justify-center p-20 mx-auto md:p-30 md:flex md:h-full max-w-1280">
    <div class="flex flex-row items-center mb-20 md:mb-30 md:min-h-50" @fadeIn>
      <h2 class="flex items-center text-lg font-bold text-white">
        <span>Application History</span>
      </h2>
    </div>
    <div class="flex flex-col w-full h-full mx-auto overflow-hidden card" @fadeIn>
      <section class="flex flex-row items-center justify-between p-20 border-b border-solid border-gray-lighter">
        <div class="relative flex flex-row items-center justify-start m-10 sm:flex-grow-0 sm:w-384 ">
          <i class="absolute transform -translate-y-1/2 material-icons top-1/2 left-5 text-gray">search</i>
          <input type="search" class="w-full input is-lg pl-36" placeholder="Search..." [(ngModel)]="searchText"
            (ngModelChange)="filterBySearchText(pastApplications, searchText)" />
        </div>
        <button class="m-10 ml-auto button icon-button is-primary is-inverted" [class]="!!loadingList ? 'disabled' : ''" aria-label="Open menu" mat-button
          matTooltip="Toggle Menu" (click)="toggleSidenav()">
          <span class="material-icons ">menu</span>
        </button>
      </section>
      <ng-container *ngIf="!loadingList">
        <mat-sidenav-container class="relative flex flex-col w-full h-full overflow-hidden">
          <mat-sidenav-content class="relative flex flex-col overflow-auto bg-white">
            <div class="sticky top-0 z-10 grid grid-cols-12 px-20 text-xs font-bold uppercase bg-white md:px-30 text-gray">
              <span class="flex items-center col-span-3 p-10 cursor-pointer focus:outline-none"
                (click)="orderRows('CaseName')" attr.aria-label="Sort by CaseName">
                <span class="mr-5">Application Name</span>
                <span class="material-icons text-gray" *ngIf="columnName === 'CaseName'">
                  {{reverseOrder ? 'arrow_drop_up' : 'arrow_drop_down'}}
                </span>
              </span>
              <span class="flex items-center col-span-3 p-10 cursor-pointer focus:outline-none"
                (click)="orderRows('CarrierProduct')" attr.aria-label="Sort by Carrier/Product">
                <span class="mr-5">Product</span>
                <span class="material-icons text-gray" *ngIf="columnName === 'CarrierProduct'">
                  {{reverseOrder ? 'arrow_drop_up' : 'arrow_drop_down'}}
                </span>
              </span>
              <span class="flex items-center col-span-2 p-10 cursor-pointer focus:outline-none"
                (click)="orderRows('Date')" attr.aria-label="Sort by Date">
                <span class="mr-5">Created On</span>
                <span class="material-icons text-gray" *ngIf="columnName === 'Date'">
                  {{reverseOrder ? 'arrow_drop_up' : 'arrow_drop_down'}}
                </span>
              </span>
              <span class="flex items-center col-span-2 p-10 cursor-pointer focus:outline-none"
                (click)="orderRows('Status')" attr.aria-label="Sort by Date">
                <span class="mr-5">Status</span>
                <span class="material-icons text-gray" *ngIf="columnName === 'Status'">
                  {{reverseOrder ? 'arrow_drop_up' : 'arrow_drop_down'}}
                </span>
              </span>
            </div>
            <div class="grid grid-cols-1 px-10 pt-10 gap-x-20 gap-y-20 md:px-20 pb-30">
              <div class="grid items-center grid-cols-12 p-10 text-sm font-bold rounded bg-gray-lightest"
                *ngFor="let a of filterBySearchText(pastApplications, searchText); let last = last">
                <span class="col-span-3 p-10">
                  {{a.ActivityName}}
                </span>
                <span class="flex flex-col col-span-3 p-10">
                  <span>{{a.ProductName}}</span>
                </span>
                <span class="col-span-2 p-10 font-medium text-gray" *ngIf="!!a.TransactionId">
                  {{a.AuditDate | date: 'MM/dd/yyyy' : 'UTC'}}
                </span>
                <span class="col-span-2 p-10 font-medium text-gray" *ngIf="!a.TransactionId">
                  {{a.AuditDate | date: 'MM/dd/yyyy'}}
                </span>
                <span class="flex col-span-2 p-10">
                  <span class="flex mr-auto text-xs font-bold uppercase ">
                    <span>{{a.DisplayStatus | splitCamelCase: 0}}</span>
                  </span>
                </span>
                <span class="flex justify-end col-span-2 p-10 gap-x-10">
                  <button mat-button class="ml-20 button icon-button is-primary is-inverted" 
                          [matMenuTriggerFor]="flApplicationMenu">
                          <i class="material-icons">more_horiz</i>
                  </button>
                  <mat-menu #flApplicationMenu="matMenu">
                    <div class="flex flex-col items-start gap-y-10">                      
                      <button class="flex items-center justify-start button is-primary is-lg is-inverted" mat-menu-item
                                (click)="goToActivity(a.ActivityId)" attr.aria-label="Continue Firelight e-Application" 
                                *ngIf="a.Status.toLowerCase() !== 'complete'">
                          <i class="material-icons">drive_file_rename_outline</i>
                          <span class="my-auto ml-10">Continue Application</span>              
                      </button>                                            
                      <button class="flex items-center justify-start button is-primary is-lg is-inverted" mat-menu-item
                                (click)="openCopyFireLightModal(a)" attr.aria-label="Copy Firelight e-Application">
                          <i class="material-icons">content_copy</i>
                          <span class="my-auto ml-10">Copy Application</span>              
                      </button>
                      <button class="flex items-center justify-start button is-primary is-lg is-inverted" mat-menu-item 
                          (click)="openEApplicationDownloadsModal(a)" attr.aria-label="View Applications Documents">
                          <i class="material-icons">description</i>
                          <span class="my-auto ml-10">Download Files</span>              
                      </button>                                              
                    </div>
                  </mat-menu>
                </span>
              </div>
            </div>
            <ng-container *ngIf="!pastApplications">
              <p class="w-full p-20 text-center md:px-30">No results found.</p>
            </ng-container>
          </mat-sidenav-content>
          <mat-sidenav class="w-full bg-white xs:w-1/2 sm:shadow sm:w-320" position="end" #sidenav
            [mode]="sidenavConfig.Mode" [opened]="sidenavConfig.IsOpened">
            <div class="flex flex-col w-full p-10 md:p-20">
              <button class="flex items-center m-10 button is-lg is-inverted" aria-label="Firelight e-Applications"  amplitudeEvent="Eapp History - Firelight SSO"
                (click)="activateFirelightSso()" attr.aria-label="Firelight e-Applications">
                <img class="w-24 h-24 mr-10" src="/Images/Icons/firelight.svg" aria-label="Firelight Image">
                <span>Launch Firelight</span>
                <span class="ml-auto material-icons mi-18 hover:text-blue-dark text-gray" matTooltip="View EApp Guide"
                  matTooltipPosition="right" (click)="openEAppGuide()">
                  help
                </span>
              </button>
              <button class="flex items-center justify-center m-10 button is-lg is-inverted" amplitudeEvent="Eapp History - IGO SSO"
                aria-label="iGo e-Applications" (click)="activateIpipelineSso()" attr.aria-label="iGo e-Applications">
                <img class="w-24 h-24 mr-10" src="/Images/Icons/ipipeline.svg" aria-start="iGo Image">
                <span>Launch IPipeline</span>
                <span class="ml-auto material-icons mi-18 hover:text-blue-dark text-gray" matTooltip="View EApp Guide"
                  matTooltipPosition="right" (click)="openEAppGuide()">
                  help
                </span>
              </button>
              <button class="flex items-center justify-center m-10 button is-lg is-inverted"
                aria-label="Go to my business" (click)="goToMyBusiness()">
                <span class="w-24 mr-10 material-icons mi-18 text-gray">work</span>
                <span class="mr-auto">Go to My Business</span>
              </button>
            </div>
          </mat-sidenav>
        </mat-sidenav-container>
      </ng-container>
      <div class="relative flex-grow p-40 m-auto loader-wrap" *ngIf="loadingList" [@fadeIn]>
        <div class="loader-sm is-blue"></div>
      </div>
    </div>
  </div>
</div>