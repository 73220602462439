import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { fadeIn } from 'src/app/animations';
import { FeatureToggleService } from 'src/app/core/services/featuretoggle.service';
import { ReportsApiService } from 'src/app/core/services/http/reports-api.service';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { AgentBusinessSummary } from 'src/app/shared/models/agent.models';
import { EnvironmentEnum } from 'src/app/shared/models/generic.models';
// import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-dashboard-business-summary',
  templateUrl: './dashboard-business-summary.component.html',
  animations: [fadeIn]
})
export class DashboardBusinessSummaryComponent implements OnInit {
  @Output() loaded: EventEmitter<any> = new EventEmitter();
  emptySummary: AgentBusinessSummary = { ActiveContractCount: 0, PendingPolicyActionNeededCount: 0, IssuedPolicyCount: 0 };
  businessSummary!: AgentBusinessSummary;
  environment!: EnvironmentEnum;
  environmentEnum = EnvironmentEnum;

  constructor(
    private featureToggleService: FeatureToggleService,
    private reportsApiService: ReportsApiService,
    private sessionStore: SessionStoreService) { }

  ngOnInit(): void {

    this.environment = this.featureToggleService.getEnvironment();
    if (this.sessionStore.User.AgentArcGuid) this.reportsApiService.getAgentBusinessSummary(this.sessionStore.User.AgentArcGuid)
      .subscribe({
        next: res => {
          this.businessSummary = res;
        },
        error: () => {
          this.businessSummary = this.emptySummary;
        }
      });
    else this.businessSummary = this.emptySummary;
    this.loaded.emit();
  }

}
