import { CurrencyMaskInputMode, NgxCurrencyModule, CurrencyMaskConfig } from 'ngx-currency';

export const customCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'left',
  allowNegative: true,
  allowZero: true,
  decimal: '.',
  precision: 2,
  prefix: '$',
  suffix: '',
  thousands: ',',
  nullable: false,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};