import { Component, Input, OnInit, Inject } from '@angular/core';
import { SurveyPurchaseModel, SurveyPurchaseOptionsModel } from 'src/app/shared/models/surveyMarketingStrategy.models';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-purchase-modal',
  templateUrl: './purchase-modal.component.html',
})
export class PurchaseModalComponent implements OnInit {
  purchaseModel!:SurveyPurchaseModel;

  constructor(@Inject(MAT_DIALOG_DATA) private parmeters: PurchaseModalParmeters,
    private dialogRef:MatDialogRef<PurchaseModalComponent>) { }

  ngOnInit(): void {
    this.purchaseModel = this.parmeters.purchaseModel;
  }

  payComponentCallback(callback: any): void {
    this.dialogRef.close({paymentAmount: callback.paymentAmount});
  }

  closeModal(event: any): void {
    this.dialogRef.close();
  }


}

export interface PurchaseModalParmeters {
  purchaseModel:SurveyPurchaseModel;
}
