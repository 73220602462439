import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Table } from 'src/app/shared/classes/table';
import { InformalTableHeaders } from '../../constants/informalPolicyTableHeaders.constant';
import { InformalTablePolicy } from '../../models/informalTablePolicy.model';

@Component({
  selector: 'app-informal-policy-table',
  templateUrl: './informal-policy-table.component.html'
})
export class InformalPolicyTableComponent implements OnChanges {
  @Input() informalPolicies: InformalTablePolicy[] = [];
  @Input() reportType!: string;
  table: Table<InformalTablePolicy> = new Table<InformalTablePolicy>(InformalTableHeaders, 1, true);
  isLoading: boolean = true;
  isError: boolean = false;

  constructor(private router: Router,) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes[ 'informalPolicies' ]?.isFirstChange() && changes[ 'informalPolicies' ]?.previousValue !== changes[ 'informalPolicies' ]?.currentValue) {
      this.table.setBody(this.informalPolicies);
      this.isLoading = false;
    }
  }

  openPolicyDetails(row: InformalTablePolicy): void {
    this.router.navigate(['/Portal/Business/' + this.reportType + '/InformalPolicyDetails/' + row.InformalGuid],
      {
        state: {
          InformalPolicy: row,
          PastPolicyListState: {
            Url: '/Portal/Business/' + this.reportType + '/Informal',
            FilterOptions: {} // For future use to maintain filtered params.
          }
        }
      });
  }
}
