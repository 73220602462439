export const downloadFile = (data:Blob, fileName:string) => {
  const f = document.createElement('a');
  f.id = 'tmpDownload';
  document.body.appendChild(f);
  f.style.display = 'none';

  const fUrl = window.URL.createObjectURL(data);
  f.href = fUrl;
  f.download = fileName;
  f.click();

  setTimeout(() => {
    const el = document.getElementById(f.id);
    document.body.removeChild(el!);
    window.URL.revokeObjectURL(fUrl);
  }, 100);
};