import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-questionnaire-popup',
  templateUrl: './questionnaire-popup.component.html',
})
export class QuestionnairePopupComponent {
  readonly singleLTCFormUrl:string = 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=f63e1a3d-d96a-46de-be62-2fc6005c4c1b&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2';
  readonly jointLTCFormUrl:string = 'https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=06d621a4-564f-4b3e-b4e8-01cefadfe5c0&env=na2&acct=b4ba3543-10a2-4b97-bfa6-40ef9d0a70bf&v=2'

  constructor() { }

  openSingleLTCForm(){
    window.open(this.singleLTCFormUrl);
  }

  openJointLTCForm(){
    window.open(this.jointLTCFormUrl);
  }

}
