import { TableHeader } from 'src/app/shared/classes/table';
import { RiaAccount } from '../models/riaAccount.model';

export const InvestmentTableHeaders: TableHeader<RiaAccount>[] = [
  {
    DisplayName: 'Clients',
    ColumnName: ['Clients'],
    WidthClass: '',
    CompareFn: (a: RiaAccount, b: RiaAccount, order: 1|-1):number => {
      const valA = a.Clients ?? '';
      const valB = b.Clients ?? '';
      return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: 'Type',
    ColumnName: ['AccountType'],
    WidthClass: '',
    CompareFn: (a: RiaAccount, b: RiaAccount, order: 1|-1):number => {
      const valA = a.AccountType ?? '';
      const valB = b.AccountType ?? '';
      return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: 'Custodian',
    ColumnName: ['Custodian'],
    WidthClass: '',
    CompareFn: (a: RiaAccount, b: RiaAccount, order: 1|-1):number => {
      const valA = a.Custodian ?? '';
      const valB = b.Custodian ?? '';
      return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: 'Model',
    ColumnName: ['Model'],
    WidthClass: '',
    CompareFn: (a: RiaAccount, b: RiaAccount, order: 1|-1):number => {
      const valA = a.Model ?? '';
      const valB = b.Model ?? '';
      return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: 'Current Value',
    ColumnName: ['CurrentValue'],
    WidthClass: '',
    CompareFn: (a: RiaAccount, b: RiaAccount, order: 1|-1):number => {
      const valA = a.CurrentValue ?? 0;
      const valB = b.CurrentValue ?? 0;
      return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: 'Account Status',
    ColumnName: ['AccountStatus'],
    WidthClass: '',
    CompareFn: (a: RiaAccount, b: RiaAccount, order: 1|-1):number => {
      const valA = a.AccountStatus ?? '';
      const valB = b.AccountStatus ?? '';
      return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: 'Date Opened',
    ColumnName: ['DateOpened'],
    WidthClass: '',
    CompareFn: (a: RiaAccount, b: RiaAccount, order: 1|-1):number => {
      const valA = a.DateOpened ?? new Date(1900, 1, 1);
      const valB = b.DateOpened ?? new Date(1900, 1, 1);
      return (valA.getTime() === valB.getTime() ? 0 : valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: 'Client Fee',
    ColumnName: ['ClientFee'],
    WidthClass: '',
    CompareFn: (a: RiaAccount, b: RiaAccount, order: 1|-1):number => {
      const valA = a.ClientFee ?? 0;
      const valB = b.ClientFee ?? 0;
      return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
    },
  }
];