import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { orderBy } from 'lodash';
@Component({
  selector: 'app-case-status-notes',
  templateUrl: './case-status-notes.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CaseStatusNotesComponent {
  caseStatusNotes: Record<string, any>[] = this.data.notes;
  orderBy = orderBy;
  constructor(@Inject(MAT_DIALOG_DATA) private data:any) { }


  filterExternalUsers(note: any) {
    return note.IsExternalUser === false;
  }

}
