import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PermissionService } from 'src/app/core/services/auth/permission.service';
import { EnrollmentFormApiService } from 'src/app/core/services/http/enrollment-form-api.service';
import { SsoService } from 'src/app/core/services/sso.service';
import { EnrollmentFormComponent } from 'src/app/portal/modals/enrollment-form/enrollment-form.component';
import { RenewalFormComponent } from 'src/app/portal/modals/renewal-form/renewal-form.component';
import { OrderModel } from 'src/app/shared/models/order.models';
import { ResourcePage } from 'src/app/shared/models/resource-page.models';
import { SurveyRenewalModel } from 'src/app/shared/models/surveyRenewal.models';
import { User, UserDelegate } from 'src/app/shared/models/user.models';

@Component({
  selector: 'app-creative-services-workflow',
  templateUrl: './creative-services-workflow.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `
  ]
})

export class CreativeServicesWorkflowComponent implements OnInit {
  @Input() resource!: ResourcePage;
  @Output() clickEvent = new EventEmitter<boolean>(); // TODO: recommends rename this to {componentName}{Action} - creativeServicesOnClick
  purchased!: OrderModel | undefined;
  intervals: any[] = [];
  @Input() submitted!: { surveyId: number, isEnrolled: boolean }[] | undefined;
  hasPastRenewals: boolean = false;
  surveyId!: number | undefined;
  pendingRenewal: boolean = false;
  surveyRenewals!: SurveyRenewalModel[];
  hasSubmittedAnyForm: boolean = false;
  enrolled: boolean = false;

  constructor(
    private dialog: MatDialog,
    private enrollmentFormApiService: EnrollmentFormApiService,
    private permissionService: PermissionService,
    private ssoService: SsoService,
  ) { }

  ngOnInit(): void {
    this.enrolled = this.permissionService.hasOnePermission('CreativeServices:Access');
    this.surveyId = this.resource.EnrollmentSurveyData?.SurveyIds[0]; // Uses ElevateId = 2027 and not the Elevate (PreMarch 2019) Id = 2
    this.getAndRefreshSurveyRenewals().subscribe(() => {
      if (this.pendingRenewal) {
        this.openSurveyRenewalDialog();
      }
    });

    if (this.submitted) {
      this.hasSubmittedAnyForm = this.submitted.some(f => f.isEnrolled);
    }
  }

  onClick(): void {
    this.clickEvent.emit(true);
  }

  getAndRefreshSurveyRenewals(): Observable<any> {
    return this.enrollmentFormApiService.getSurveyRenewals(this.surveyId).pipe(map(results => {
      this.surveyRenewals = results;
      const renewal = results.find(r => r.SurveyId === this.surveyId);
      if (renewal) {
        if (renewal.SurveyRenewalStatusId === 1) {
          this.pendingRenewal = true;
        } else {
          this.pendingRenewal = false;
          this.hasPastRenewals = true;
        }
      }
    }, () => {
      return null;
    }));
  }

  openSurveyRenewalDialog() {
    const ref = this.dialog.open(RenewalFormComponent, {
      data: {
        SurveyRenewals: this.surveyRenewals,
        FilterBySurveyId: this.surveyId
      }
    });

    ref.afterClosed().subscribe(result => {
      if (result) {
        this.getAndRefreshSurveyRenewals();
      }
    });
  }

  openEnrollmentModal() {
    const dialogRef = this.dialog.open(EnrollmentFormComponent, {
      data: {
        Event: this.resource.EnrollmentSurveyData?.Event,
        SurveyId: this.surveyId,
        Disclaimer: this.resource.EnrollmentSurveyData?.Disclaimer
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      this.hasSubmittedAnyForm = res.hasCompletedEnrollment;
    });
  }


}
