import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { fadeIn } from 'src/app/animations';
import { EnvironmentEnum } from 'src/app/shared/models/generic.models';
import { environment } from 'src/environments/environment';

@Component({
  animations: [fadeIn],
  selector: 'app-simon-spectrum',
  templateUrl: './simon-spectrum.component.html'
})
export class SimonSpectrumComponent implements OnInit {
  environment = environment;
  showLocalSimon: boolean = false;
  iframeUrl: string = environment.simonUrl;
  env?: EnvironmentEnum | null;
  title: string = 'SIMON Spectrum';

  constructor(
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title + ' | Agent Portal');
    this.openSimonSso();
  }

  openSimonSso() {
    if (this.showLocalSimon) {
      this.iframeUrl = environment.simonLocalUrl;
    } else {
      this.iframeUrl = environment.simonUrl;
    }
    this.iframeUrl += '/spectrum';
  }
}
