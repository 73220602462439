<div class="flex flex-col w-full h-full p-30 card">
  <ng-container *ngIf="!loading">
    <span class="flex w-48 h-48 mb-20 rounded-full bg-gray-lightest">
      <i class="m-auto material-icons text-gray">insights</i>
    </span>
    <div class="flex gap-x-30">
      <div class="flex flex-col">
        <label class="text-sm text-gray">YTD Production</label>
        <p class="text-xl font-bold">{{currentYearProductionString}}</p>
      </div>
      <div class="flex flex-col mx-auto">
        <label class="text-sm text-gray">{{pastYear}} Production</label>
        <p class="text-xl font-bold">{{pastYearProductionString}}</p>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="loading">
    <div class="relative p-20 m-auto loader-wrap">
      <div class="loader-sm is-blue"></div>
    </div>
  </ng-container>
</div>