import { Component, OnInit } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { fadeIn, slideInOut } from 'src/app/animations';
import { AdditionalResource } from 'src/app/shared/models/resource-page.models';
import { AvailableCarriersApiService } from 'src/app/core/services/http/airtable-api/available-carriers-api.service';
import { AvailableCarrier } from 'src/app/shared/models/available-carriers.model';
import { orderBy } from 'lodash';
// import { AmplitudeEventService } from 'src/app/core/services/amplitude/amplitude-event.service';
// import { ActivatedRoute } from '@angular/router';
// import { PermissionService } from 'src/app/core/services/auth/permission.service';
// import { AmplitudeApiService } from 'src/app/core/services/amplitude/amplitude-api.service';
// import { AvailableCarrierProduct } from 'src/app/shared/models/available-carriers.model';

@Component({
  animations: [fadeIn, slideInOut],
  selector: 'app-preview',
  templateUrl: './preview.component.html',
})
export class PreviewComponent implements OnInit {
  constructor(
    // private amplitudeEventService: AmplitudeEventService,
    private availableCarriersApiService: AvailableCarriersApiService
  ) { }

  availableCarriers: AvailableCarrier[] = [];
  // availableCarrierProducts: AvailableCarrierProduct[] = [];
  isError: boolean = false;
  loading: boolean = false;
  orderBy = orderBy;
  tutorials: AdditionalResource[] = [
    // { Name: `Paper App Upload Training`, WistiaId: `gzczs8r8v2` },
    { Name: 'Dec 2021 Structured Product&nbsp;Offerings', WistiaId: 'pzbd7csicf' },
    { Name: 'Quinci™ Annuity Marketplace', WistiaId: 'fj1vjfx3t8' },
    { Name: 'Structured Notes Marketplace', WistiaId: '9lm2bxvvih' },
    { Name: 'Marketplace', WistiaId: 'xi5a0l1t7k' },
    { Name: 'E-Application', WistiaId: 'sm2h9hwbv0' },
  ];

  ngOnInit(): void {
    this.loading = true;
    forkJoin({
      availableCarriers: this.availableCarriersApiService.getAvailableCarriers().pipe(catchError(error => of(error))),
      // availableCarrierProducts: this.availableCarriersApiService.getAvailableCarrierProducts().pipe(catchError(error => of(error))),
    })
      .subscribe(res => {
        this.availableCarriers = orderBy(res.availableCarriers, ['Fields.Name']);
        this.availableCarriers = this.availableCarriers.filter(row =>{
          // Only show rows with all fields having values
          return !!row.Fields.ProductTraining && !!row.Fields.Marketplace && !!row.Fields.Illustrations;
        });
        // this.availableCarrierProducts = orderBy(res.availableCarrierProducts, ['Fields.Name']);
      }, (error)=>{
        this.isError = true;
      })
      .add(() => {
        this.loading = false;
      });
  }

  scrollTo(id: string): void {
    const el = document.getElementById(id);
    el?.scrollIntoView({ behavior: 'smooth' });
  }

  openLifecycleCarrier(): void{ }

  // expandCarrierProducts(carrier:AvailableCarrier): void{
  //   // console.log('Carrier', carrier);

  //   if (carrier.Fields.ShowProducts){
  //     carrier.Fields.ShowProducts = false;
  //     return;
  //   }
  //   // carrier.Fields.ProductList = this.filterProductsByCarrierId(carrier.Id);
  //   carrier.Fields.ShowProducts = !carrier.Fields.ShowProducts;
  //   // this.amplitudeEventService.logCustomEvent('Carrier Availability: Expand ' + carrier.Fields.Name, 'Click', {});
  // }

  // filterProductsByCarrierId(carrierId: string): AvailableCarrierProduct[] {
  //   const result: AvailableCarrierProduct[] = this.availableCarrierProducts.filter(product => {
  //     return product.Fields.CarrierLookup.filter(productCarrierId => {
  //       return productCarrierId === carrierId;
  //     }).length > 0;
  //   });
  //   return result;
  // }

  orderRows(columnName:string):void{
    // console.log('sort by ' + columnName);
    this.availableCarriers = orderBy(this.availableCarriers, ['Field.' + columnName]);
    // console.log(this.availableCarriers);
  }
  // scrollTo(id: string) {
  //   let el = document.getElementById(id);
  //   el?.scrollIntoView({ behavior: 'smooth' });
  // }
}

