import { Component, OnInit } from '@angular/core';
import { PermissionService } from 'src/app/core/services/auth/permission.service';
import { SsoApiService } from 'src/app/core/services/http/sso-api.service';
import { SsoService } from 'src/app/core/services/sso.service';
import { externalLoadingHtml } from 'src/app/shared/constants/externalLoading.constants';

@Component({
  selector: 'app-simon',
  templateUrl: './simon.component.html',
})
export class SimonComponent {

  constructor(private ssoService:SsoService, private permissionService:PermissionService) { }
}
