import { Component, OnInit } from '@angular/core';
import { generalTrainingConstant } from 'src/app/shared/constants/training.constants';
import { GeneralTraining } from 'src/app/shared/models/training.models';

@Component({
  selector: 'app-general-training',
  templateUrl: './general-training.component.html',
})
export class GeneralTrainingComponent {
  trainings:GeneralTraining[] = generalTrainingConstant;

  constructor() { }
}
