import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import { PermissionService } from 'src/app/core/services/auth/permission.service';
import { WistiaApiService } from 'src/app/core/services/http/wistia-api.service';
import { WistiaResource, WistiaResourceGroup } from 'src/app/shared/models/resource-page.models';
@Component({
  selector: 'app-hegna',
  templateUrl: './hegna.component.html'
})
export class HegnaComponent implements OnInit {
  searchText: string = '';
  hegnaResult: any;
  showFilteredList: boolean = false;
  wistiaResourceGroups: WistiaResourceGroup[] = [];

  constructor(
    private permissionService: PermissionService,
    private wistiaApiService: WistiaApiService) { }

  ngOnInit(): void {
    this.wistiaApiService.getHegnaAssets().subscribe((result) => {
      this.hegnaResult = result;
      this.wistiaResourceGroups =
        _.chain(this.hegnaResult.medias)
          .groupBy('section')
          .map((value, key) => ({ GroupName: key, WistiaResources: value }))
          .filter(group => {
            return this.showSection(group.GroupName);
          })
          .value();
    }, (error) => {
      console.log('Error retrieving wistia results', error);
    },
    () => { });
  }

  clearSearch() {
    this.searchText = '';
    this.showFilteredList = false;
  }

  showSection(section: string): boolean {
    const compareString = section.replace(/\s+/g, '');
    if (compareString.includes('TierIIAssets')) {
      return this.permissionService.hasOnePermission('HegnaRHNTierII:Access');
    }
    if (compareString.includes('TierIIIAssets')) {
      return this.permissionService.hasOnePermission('HegnaRHNTierIII:Access');
    }
    return true;
  }
}
