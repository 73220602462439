export const carrierNameByNSCCId: Record<number, string> = {
  4682: 'Allianz',
  4570: 'Allianz',
  4524: 'Allianz',
  3274: 'Allianz',
  4523: 'Allianz',
  1777: 'Allianz',
  3271: 'Allianz',
  4720: 'Athene',
  6030: 'American Equity',
  3192: 'American Equity',
  4595: 'American Equity',
  5949: 'Great American',
  4749: 'Great American',
  5339: 'Global Atlantic (Forethought)',
  7012: 'Global Atlantic (Forethought)',
  4577: 'Nationwide',
  4530: 'Nationwide',
  4514: 'Nationwide',
  4515: 'Nationwide',
  7092: 'Nationwide',
  3881: 'Nationwide',
  4576: 'Nationwide',
  4650: 'Nationwide',
};