import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CanSellResponse } from 'src/app/shared/models/cansell.models';

@Injectable({
  providedIn: 'root'
})
export class CansellApiService {

  constructor(private http:HttpClient) { }

  runCanSellCheck(state:string, productCusip:string):Observable<CanSellResponse> {
    return this.http.get<CanSellResponse>(`/api/CanSell/State/${state}/Cusip/${productCusip}`);
  }
}
