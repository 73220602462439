import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { fadeIn } from 'src/app/animations';
@Component({
  selector: 'app-toolbox',
  templateUrl: './toolbox.component.html',
  animations: [fadeIn]
})
export class ToolboxComponent {

  constructor(){}
  @ViewChild('toolboxContainerContent')
  private toolboxContentDiv!: ElementRef<HTMLElement>

  getState(outlet: RouterOutlet) {
    const ret = outlet.activatedRoute.component;
    return ret;
  }
  onActivate(_event: any): void {
    // Scrolling back to the top
    // Reference: https://stackoverflow.com/questions/48048299/angular-5-scroll-to-top-on-every-route-click/48048822
    if (this.toolboxContentDiv) {
      (this.toolboxContentDiv.nativeElement as HTMLElement).scrollTop = 0;
    }
  }
}

