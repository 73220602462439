<div class="card flex flex-col flex-grow h-full">
    <section class="flex flex-row items-center justify-start p-10 lg:p-20 border-b border-solid border-gray-lighter">
        <span class="material-icons mi-24">{{!!headerIcon ? headerIcon : 'home_repair_service'}} </span>
        <div class="h-24 m-10">
            <h2 class="text-lg font-bold">Tools</h2>
        </div>
    </section>
    <section class="p-10 lg:p-20 mb-10" *ngFor="let category of categories">
        <h2 class="text-sm mb-10 font-semibold text-gray uppercase">{{category}}:</h2>
        <!-- TODO: more elegant solution to making "Start Application" first in each list -->
        <ng-container *ngFor="let tile of tilesByCategory[category]">
            <div class="p-10 mb-10 cursor-pointer rounded transition-colors duration-150 hover:bg-blue-lighter hover:bg-opacity-25 hover:text-blue-dark"
                (click)="tile.Act()" *ngIf="tile.Id === 'abd9cc24-16d7-461e-ab1f-ee54d20904f9'">
                <h3 class="text-base font-bold">{{tile.Name}}</h3>
                <p class="text-sm text-gray" [innerHtml]="tile.ShortDescription"></p>
            </div>
        </ng-container>
        <ng-container *ngFor="let tile of tilesByCategory[category]">
            <div class="p-10 mb-10 cursor-pointer rounded transition-colors duration-150 hover:bg-blue-lighter hover:bg-opacity-25 hover:text-blue-dark"
                (click)="tile.Act()" *ngIf="tile.Id !== 'abd9cc24-16d7-461e-ab1f-ee54d20904f9'">
                <h3 class="text-base font-bold">{{tile.Name}}</h3>
                <p class="text-sm text-gray" [innerHtml]="tile.ShortDescription"></p>
            </div>
        </ng-container>
    </section>
</div>