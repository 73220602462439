import { Component, OnInit } from '@angular/core';
import { orderBy } from 'lodash';
import { fadeIn } from 'src/app/animations';
import { PermissionService } from 'src/app/core/services/auth/permission.service';
import { EAppService } from 'src/app/core/services/e-app.service';
import { FirelightApiService } from 'src/app/core/services/http/firelight-api.service';
import { FirelightDisplayStatusEnum } from 'src/app/shared/enums/firelight-statuses.enum';
import { ErrorMessageModel } from 'src/app/shared/models/error-message.model';
import { ActivityResponse } from 'src/app/shared/models/firelightApi.models';
import { DashboardRecentApplication } from '../../../../models/dashboard.models';

@Component({
  templateUrl: './dashboard-recent-applications.component.html',
  selector: 'app-dashboard-recent-aplications',
  animations: [fadeIn]
})

export class DashboardRecentAplicationsComponent implements OnInit {
  recentApplications: DashboardRecentApplication[] = [];
  loading = false;
  excludeDisplayStatuses = [FirelightDisplayStatusEnum.complete]
  limit = 4;
  hasAppHistoryPermission = this.permissionService.hasOnePermission('SubmitPaperApp:View|IpipelineEApps:Access|Firelight:Access');
  error: ErrorMessageModel = {
    hasError: false,
    message: 'There was a problem retrieving data. Please try again later.'
  };

  constructor(
    private firelightApiService: FirelightApiService,
    private eAppService: EAppService,
    private permissionService: PermissionService
  ) { }

  ngOnInit(): void {
    this.getRecentPendingApplication();
  }

  getRecentPendingApplication(): void {
    this.loading = true;
    this.firelightApiService.getFirelightApplicationHistory(this.excludeDisplayStatuses, this.limit)

      .subscribe({
        next: res => {
          this.recentApplications = res.map(x => {
            return {
              ActivityId: x.ActivityId,
              ActivityName: x.ActivityName,
              AuditDate: x.AuditDate,
              Status: x.Status,
              DisplayStatus: x.DisplayStatus
            };
          });
        },
        error: () => {
          this.error.hasError = true;
        }
      }).add(() => this.loading = false);
  }

  goToActivity(activityId: string): void {
    this.eAppService.goToFirelight(activityId, { isNew: false });
  }
}

// Don't delete just yet. Need this for troubleshooting local data - Harry Nguyen
const mockApplicationsV2: any[] = [{
  'ActivityId': '2d970c7c-9e25-4f7e-9d4f-4a493c780739',
  'PolicyGuid': null,
  'ActivityName': 'barbar-Allianz Preferred-222',
  'Status': 'ActiveApplication',
  'DisplayStatus': 'Data Entry',
  'AuditDate': '2022-03-18T19:49:39.47',
  'StateAbbreviation': 'MD',
  'ProductName': 'Allianz 222 Annuity',
  'ProductCategory': 'Annuity',
  'ProductCarrierName': 'Allianz',
  'TransactionId': null,
  'ReviewIsApproved': null,
  'TransactionDocuments': null
}, {
  'ActivityId': '4e80d869-a4e2-4b79-8efc-a574a0662d09',
  'PolicyGuid': null,
  'ActivityName': 'barabr-Allianz Preferred-222',
  'Status': 'ActiveApplication',
  'DisplayStatus': 'Data Entry',
  'AuditDate': '2022-03-18T15:29:12.31',
  'StateAbbreviation': 'NC',
  'ProductName': 'Allianz 222 Annuity',
  'ProductCategory': 'Annuity',
  'ProductCarrierName': 'Allianz',
  'TransactionId': null,
  'ReviewIsApproved': null,
  'TransactionDocuments': null
}, {
  'ActivityId': '1c62d3de-ce5a-451d-b6fc-6baff92598ed',
  'PolicyGuid': null,
  'ActivityName': 'barabr-Allianz Preferred-222',
  'Status': 'ActiveApplication',
  'DisplayStatus': 'Data Entry',
  'AuditDate': '2022-03-18T15:28:26.08',
  'StateAbbreviation': 'NC',
  'ProductName': 'Allianz 222 Annuity',
  'ProductCategory': 'Annuity',
  'ProductCarrierName': 'Allianz',
  'TransactionId': null,
  'ReviewIsApproved': null,
  'TransactionDocuments': null
}, {
  'ActivityId': 'bc935ec6-a3a9-4112-a505-6cb7f460a7e8',
  'PolicyGuid': null,
  'ActivityName': 'barabr-Allianz Preferred-222',
  'Status': 'ActiveApplication',
  'DisplayStatus': 'Data Entry',
  'AuditDate': '2022-03-18T15:26:36.223',
  'StateAbbreviation': 'NC',
  'ProductName': 'Allianz 222 Annuity',
  'ProductCategory': 'Annuity',
  'ProductCarrierName': 'Allianz',
  'TransactionId': null,
  'ReviewIsApproved': null,
  'TransactionDocuments': null
}, {
  'ActivityId': 'd8639bbe-eecf-41e7-bd0b-d745b7f83d2e',
  'PolicyGuid': null,
  'ActivityName': 'ababare-Allianz Preferred-222',
  'Status': 'ActiveApplication',
  'DisplayStatus': 'Data Entry',
  'AuditDate': '2022-03-17T17:46:13.823',
  'StateAbbreviation': 'MN',
  'ProductName': 'Allianz 222 Annuity',
  'ProductCategory': 'Annuity',
  'ProductCarrierName': 'Allianz',
  'TransactionId': null,
  'ReviewIsApproved': null,
  'TransactionDocuments': null
}, {
  'ActivityId': 'd26cf137-34ec-43a0-b210-894d3f1cc6c7',
  'PolicyGuid': null,
  'ActivityName': 'baba-Allianz Preferred-222',
  'Status': 'ActiveApplication',
  'DisplayStatus': 'Data Entry',
  'AuditDate': '2022-03-17T17:27:31.947',
  'StateAbbreviation': 'NC',
  'ProductName': 'Allianz 222 Annuity',
  'ProductCategory': 'Annuity',
  'ProductCarrierName': 'Allianz',
  'TransactionId': null,
  'ReviewIsApproved': null,
  'TransactionDocuments': null
}, {
  'ActivityId': '0a075033-b6a4-4e81-8402-822de8353b34',
  'PolicyGuid': null,
  'ActivityName': 'fafeafea-Allianz Preferred-222',
  'Status': 'ActiveApplication',
  'DisplayStatus': 'Data Entry',
  'AuditDate': '2022-03-17T15:29:46.903',
  'StateAbbreviation': 'MN',
  'ProductName': 'Allianz 222 Annuity',
  'ProductCategory': 'Annuity',
  'ProductCarrierName': 'Allianz',
  'TransactionId': null,
  'ReviewIsApproved': null,
  'TransactionDocuments': null
}, {
  'ActivityId': '25e94ed2-a352-4cbd-a729-50274aa83807',
  'PolicyGuid': null,
  'ActivityName': 'afefeAE-Allianz Preferred-222',
  'Status': 'ActiveApplication',
  'DisplayStatus': 'Data Entry',
  'AuditDate': '2022-03-16T19:43:37.877',
  'StateAbbreviation': 'NC',
  'ProductName': 'Allianz 222 Annuity',
  'ProductCategory': 'Annuity',
  'ProductCarrierName': 'Allianz',
  'TransactionId': null,
  'ReviewIsApproved': null,
  'TransactionDocuments': null
}, {
  'ActivityId': '79cea25c-4655-4431-ad1e-b3cc8ee0c421',
  'PolicyGuid': null,
  'ActivityName': 'faeaafea-Allianz Preferred-222',
  'Status': 'ActiveApplication',
  'DisplayStatus': 'Data Entry',
  'AuditDate': '2022-03-16T19:41:22.61',
  'StateAbbreviation': 'NC',
  'ProductName': 'Allianz 222 Annuity',
  'ProductCategory': 'Annuity',
  'ProductCarrierName': 'Allianz',
  'TransactionId': null,
  'ReviewIsApproved': null,
  'TransactionDocuments': null
}, {
  'ActivityId': '81e05455-8d05-4a89-a9cc-c8eefe432e5d',
  'PolicyGuid': null,
  'ActivityName': 'afefeawfe-Allianz Preferred-222',
  'Status': 'ActiveApplication',
  'DisplayStatus': 'Data Entry',
  'AuditDate': '2022-03-16T19:22:10.847',
  'StateAbbreviation': 'NE',
  'ProductName': 'Allianz 222 Annuity',
  'ProductCategory': 'Annuity',
  'ProductCarrierName': 'Allianz',
  'TransactionId': null,
  'ReviewIsApproved': null,
  'TransactionDocuments': null
}, {
  'ActivityId': '5e0490ce-3402-4697-be36-000da1115ac6',
  'PolicyGuid': null,
  'ActivityName': 'afefeawfe-Allianz Preferred-222',
  'Status': 'ActiveApplication',
  'DisplayStatus': 'Data Entry',
  'AuditDate': '2022-03-16T19:21:44.79',
  'StateAbbreviation': 'NE',
  'ProductName': 'Allianz 222 Annuity',
  'ProductCategory': 'Annuity',
  'ProductCarrierName': 'Allianz',
  'TransactionId': null,
  'ReviewIsApproved': null,
  'TransactionDocuments': null
}, {
  'ActivityId': '393edaa7-522e-4bb7-92d7-7475da0bd664',
  'PolicyGuid': null,
  'ActivityName': 'afefeawfe-Allianz Preferred-222',
  'Status': 'ActiveApplication',
  'DisplayStatus': 'Data Entry',
  'AuditDate': '2022-03-16T19:20:54.84',
  'StateAbbreviation': 'NE',
  'ProductName': 'Allianz 222 Annuity',
  'ProductCategory': 'Annuity',
  'ProductCarrierName': 'Allianz',
  'TransactionId': null,
  'ReviewIsApproved': null,
  'TransactionDocuments': null
}, {
  'ActivityId': '555f6b4c-b91e-49ce-b001-2b8590f5561a',
  'PolicyGuid': null,
  'ActivityName': 'feafefe-Allianz Preferred-222',
  'Status': 'ActiveApplication',
  'DisplayStatus': 'Data Entry',
  'AuditDate': '2022-03-13T21:21:40.28',
  'StateAbbreviation': 'NC',
  'ProductName': 'Allianz 222 Annuity',
  'ProductCategory': 'Annuity',
  'ProductCarrierName': 'Allianz',
  'TransactionId': null,
  'ReviewIsApproved': null,
  'TransactionDocuments': null
}, {
  'ActivityId': 'f6b3b379-6af7-45ec-94cb-ef0b6f924cc4',
  'PolicyGuid': null,
  'ActivityName': 'feafefe-Allianz Preferred-222',
  'Status': 'ActiveApplication',
  'DisplayStatus': 'Data Entry',
  'AuditDate': '2022-03-11T21:41:43.28',
  'StateAbbreviation': 'NC',
  'ProductName': 'Allianz 222 Annuity',
  'ProductCategory': 'Annuity',
  'ProductCarrierName': 'Allianz',
  'TransactionId': null,
  'ReviewIsApproved': null,
  'TransactionDocuments': null
}, {
  'ActivityId': '75f97e21-bf90-486b-814f-4500bf48fa71',
  'PolicyGuid': null,
  'ActivityName': 'Testing Copied 2',
  'Status': 'ActiveApplication',
  'DisplayStatus': 'Data Entry',
  'AuditDate': '2022-03-11T21:38:48.38',
  'StateAbbreviation': 'NC',
  'ProductName': 'AEL Guarantee Series 5',
  'ProductCategory': 'Annuity',
  'ProductCarrierName': 'AEL-American Equity',
  'TransactionId': null,
  'ReviewIsApproved': null,
  'TransactionDocuments': null
}, {
  'ActivityId': '83a16730-612a-4a17-a8dd-74f3bd845a89',
  'PolicyGuid': null,
  'ActivityName': 'Harry Copied Allianz Preferred',
  'Status': 'ActiveApplication',
  'DisplayStatus': 'Data Entry',
  'AuditDate': '2022-03-11T21:38:00.88',
  'StateAbbreviation': 'NC',
  'ProductName': 'Allianz 222 Annuity',
  'ProductCategory': 'Annuity',
  'ProductCarrierName': 'Allianz',
  'TransactionId': null,
  'ReviewIsApproved': null,
  'TransactionDocuments': null
}, {
  'ActivityId': '844045d7-8ef6-4a41-92c9-b0b3dc22bea8',
  'PolicyGuid': null,
  'ActivityName': 'feafefe-Allianz Preferred-222',
  'Status': 'ActiveApplication',
  'DisplayStatus': 'Data Entry',
  'AuditDate': '2022-03-11T18:09:13.543',
  'StateAbbreviation': 'NC',
  'ProductName': 'Allianz 222 Annuity',
  'ProductCategory': 'Annuity',
  'ProductCarrierName': 'Allianz',
  'TransactionId': null,
  'ReviewIsApproved': null,
  'TransactionDocuments': null
}, {
  'ActivityId': '40e89919-cd36-4002-a387-afecd4d5dc3d',
  'PolicyGuid': null,
  'ActivityName': 'feafefe-Allianz Preferred-222',
  'Status': 'ActiveApplication',
  'DisplayStatus': 'Data Entry',
  'AuditDate': '2022-03-10T20:12:23.26',
  'StateAbbreviation': 'NC',
  'ProductName': 'Allianz 222 Annuity',
  'ProductCategory': 'Annuity',
  'ProductCarrierName': 'Allianz',
  'TransactionId': null,
  'ReviewIsApproved': null,
  'TransactionDocuments': null
}, {
  'ActivityId': 'da699ed7-f704-44ec-92c6-efc18e4986e3',
  'PolicyGuid': null,
  'ActivityName': 'testapp-Fidelity & Guaranty-Accumulator Plus 10',
  'Status': 'ActiveApplication',
  'DisplayStatus': 'Data Entry',
  'AuditDate': '2022-03-07T21:46:46.68',
  'StateAbbreviation': 'NC',
  'ProductName': 'Accumulator Plus 10',
  'ProductCategory': 'Annuity',
  'ProductCarrierName': 'F&G',
  'TransactionId': null,
  'ReviewIsApproved': null,
  'TransactionDocuments': null
}, {
  'ActivityId': '14c737ac-1f7e-4eee-b79c-9f2d12702426',
  'PolicyGuid': null,
  'ActivityName': 'testapp-Fidelity & Guaranty-Accumulator Plus 10',
  'Status': 'ActiveApplication',
  'DisplayStatus': 'Data Entry',
  'AuditDate': '2022-02-22T19:31:13.19',
  'StateAbbreviation': 'AL',
  'ProductName': 'Accumulator Plus 10',
  'ProductCategory': 'Annuity',
  'ProductCarrierName': 'F&G',
  'TransactionId': null,
  'ReviewIsApproved': null,
  'TransactionDocuments': null
}, {
  'ActivityId': '91175117-832f-46d1-be94-d4590968036e',
  'PolicyGuid': null,
  'ActivityName': 'feafeafe-Allianz Preferred-222',
  'Status': 'ActiveApplication',
  'DisplayStatus': 'Data Entry',
  'AuditDate': '2022-02-22T13:12:37.243',
  'StateAbbreviation': 'NC',
  'ProductName': 'Allianz 222 Annuity',
  'ProductCategory': 'Annuity',
  'ProductCarrierName': 'Allianz',
  'TransactionId': null,
  'ReviewIsApproved': null,
  'TransactionDocuments': null
}, {
  'ActivityId': '722356a4-cc76-4124-b015-6c970f69993c',
  'PolicyGuid': null,
  'ActivityName': 'New Application - Allianz 222 Annuity',
  'Status': 'ActiveApplication',
  'DisplayStatus': 'Data Entry',
  'AuditDate': '2022-02-22T13:11:44.09',
  'StateAbbreviation': 'GA',
  'ProductName': 'Allianz 222 Annuity',
  'ProductCategory': 'Annuity',
  'ProductCarrierName': 'Allianz',
  'TransactionId': null,
  'ReviewIsApproved': null,
  'TransactionDocuments': null
}, {
  'ActivityId': 'c6e66599-0d53-4425-a981-d8504a81301a',
  'PolicyGuid': null,
  'ActivityName': 'New Application - Accelerator Plus 10 WIZ',
  'Status': 'ActiveApplication',
  'DisplayStatus': 'Data Entry',
  'AuditDate': '2022-01-27T19:07:06.76',
  'StateAbbreviation': 'AL',
  'ProductName': 'Accelerator Plus 10 WIZ',
  'ProductCategory': 'Annuity',
  'ProductCarrierName': 'F&G',
  'TransactionId': null,
  'ReviewIsApproved': null,
  'TransactionDocuments': null
}, {
  'ActivityId': 'e54a7747-da48-4b7f-978d-69e62c44f0e8',
  'PolicyGuid': null,
  'ActivityName': 'New Application - Accelerator Plus 10 WIZ',
  'Status': 'ActiveApplication',
  'DisplayStatus': 'Data Entry',
  'AuditDate': '2022-01-27T18:58:13.19',
  'StateAbbreviation': 'AK',
  'ProductName': 'Accelerator Plus 10 WIZ',
  'ProductCategory': 'Annuity',
  'ProductCarrierName': 'F&G',
  'TransactionId': null,
  'ReviewIsApproved': null,
  'TransactionDocuments': null
}, {
  'ActivityId': '530b95ed-0f44-4b39-9028-51d5cb6dde5c',
  'PolicyGuid': null,
  'ActivityName': 'New Application - Accelerator Plus 10 WIZ',
  'Status': 'Locked',
  'DisplayStatus': 'Pending signatures',
  'AuditDate': '2022-01-27T18:53:13.07',
  'StateAbbreviation': 'AL',
  'ProductName': 'Accelerator Plus 10 WIZ',
  'ProductCategory': 'Annuity',
  'ProductCarrierName': 'F&G',
  'TransactionId': null,
  'ReviewIsApproved': null,
  'TransactionDocuments': null
}, {
  'ActivityId': '9797506d-ed1a-49c2-af9d-30695630836d',
  'PolicyGuid': null,
  'ActivityName': 'New Application - Accelerator Plus 10 WIZ',
  'Status': 'ActiveApplication',
  'DisplayStatus': 'Data Entry',
  'AuditDate': '2022-01-27T18:35:47.95',
  'StateAbbreviation': 'AL',
  'ProductName': 'Accelerator Plus 10 WIZ',
  'ProductCategory': 'Annuity',
  'ProductCarrierName': 'F&G',
  'TransactionId': null,
  'ReviewIsApproved': null,
  'TransactionDocuments': null
}, {
  'ActivityId': 'bb1759ee-7ec0-4652-8f5d-303c5b6bfb8b',
  'PolicyGuid': null,
  'ActivityName': 'New Application - Accumulator Plus 7 WIZ',
  'Status': 'Complete',
  'DisplayStatus': 'Complete',
  'AuditDate': '2022-01-27T17:07:47.307',
  'StateAbbreviation': 'GA',
  'ProductName': 'Accumulator Plus 7 WIZ',
  'ProductCategory': 'Annuity',
  'ProductCarrierName': 'F&G',
  'TransactionId': null,
  'ReviewIsApproved': null,
  'TransactionDocuments': null
}, {
  'ActivityId': 'ecdb03ad-2b93-4f5b-a9ae-be55f416ee51',
  'PolicyGuid': null,
  'ActivityName': 'New Application - Accumulator Plus 7',
  'Status': 'ActiveApplication',
  'DisplayStatus': 'Data Entry',
  'AuditDate': '2022-01-26T18:34:35.94',
  'StateAbbreviation': 'GA',
  'ProductName': 'Accumulator Plus 7',
  'ProductCategory': 'Annuity',
  'ProductCarrierName': 'F&G',
  'TransactionId': null,
  'ReviewIsApproved': null,
  'TransactionDocuments': null
}, {
  'ActivityId': '2d3ddef0-77da-4840-b1f3-19fa26ce183c',
  'PolicyGuid': null,
  'ActivityName': 'New Application - Accumulator Plus 7',
  'Status': 'ActiveApplication',
  'DisplayStatus': 'Data Entry',
  'AuditDate': '2022-01-26T18:27:13.51',
  'StateAbbreviation': 'GA',
  'ProductName': 'Accumulator Plus 7',
  'ProductCategory': 'Annuity',
  'ProductCarrierName': 'F&G',
  'TransactionId': null,
  'ReviewIsApproved': null,
  'TransactionDocuments': null
}, {
  'ActivityId': 'ccb7d35e-fe1a-4d48-900d-6aae7cd6793a',
  'PolicyGuid': null,
  'ActivityName': 'New Application - Allianz 222 Annuity',
  'Status': 'ActiveApplication',
  'DisplayStatus': 'Data Entry',
  'AuditDate': '2022-01-25T17:48:55.9',
  'StateAbbreviation': 'GA',
  'ProductName': 'Allianz 222 Annuity',
  'ProductCategory': 'Annuity',
  'ProductCarrierName': 'Allianz',
  'TransactionId': null,
  'ReviewIsApproved': null,
  'TransactionDocuments': null
}, {
  'ActivityId': 'd43464be-8b81-4def-80ab-a8dec3001d6e',
  'PolicyGuid': null,
  'ActivityName': 'New Application - Accumulator Plus 7',
  'Status': 'ActiveApplication',
  'DisplayStatus': 'Data Entry',
  'AuditDate': '2022-01-25T16:11:25.18',
  'StateAbbreviation': 'GA',
  'ProductName': 'Accumulator Plus 7',
  'ProductCategory': 'Annuity',
  'ProductCarrierName': 'F&G',
  'TransactionId': null,
  'ReviewIsApproved': null,
  'TransactionDocuments': null
}, {
  'ActivityId': 'b9fd98de-37ae-4bd2-b099-7f47dafaff4f',
  'PolicyGuid': null,
  'ActivityName': 'New Application - Accumulator Plus 7',
  'Status': 'ActiveApplication',
  'DisplayStatus': 'Data Entry',
  'AuditDate': '2022-01-25T15:51:22.92',
  'StateAbbreviation': 'NC',
  'ProductName': 'Accumulator Plus 7',
  'ProductCategory': 'Annuity',
  'ProductCarrierName': 'F&G',
  'TransactionId': null,
  'ReviewIsApproved': null,
  'TransactionDocuments': null
}];