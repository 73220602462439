import { AfterViewInit, Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { fadeIn } from 'src/app/animations';

@Component({
  selector: 'app-carriers-training-overview',
  templateUrl: './carriers-training-overview.component.html',
  animations: [fadeIn],
})
export class CarriersTrainingOverviewComponent implements OnInit, AfterViewInit {
  environment = environment;
  pageTitle: string = '';
  ready: boolean = false;
  showAppointments: boolean = true;
  title: string = '';

  constructor(
    private route: ActivatedRoute,
    private titleService: Title) {
    this.pageTitle = route.snapshot.data.title;
  }


  ngOnInit(): void {
    console.log(this.route);
    this.titleService.setTitle(this.pageTitle + ' | Agent Portal');
  }

  ngAfterViewInit(): void {
    this.ready = true;
  }
}
