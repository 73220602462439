import { Component, OnInit } from '@angular/core';
import { SsoApiService } from 'src/app/core/services/http/sso-api.service';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { SsoService } from 'src/app/core/services/sso.service';
import { User } from 'src/app/shared/models/user.models';
import { UserGroup } from 'src/app/shared/models/usergroups.models';
import { SureLcSsoUser } from 'src/app/shared/models/sso.models';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ContractingLicensesComponent } from 'src/app/portal/modals/contracting-licenses/contracting-licenses.component';
import { GetContractedComponent } from '../get-contracted/get-contracted.component';
import { PermissionService } from 'src/app/core/services/auth/permission.service';

@Component({
  selector: 'app-contract-quick-links',
  templateUrl: './contract-quick-links.component.html',
})
export class ContractQuickLinksComponent implements OnInit {

  constructor(private ssoApiService: SsoApiService, private sessionStore: SessionStoreService, private ssoService: SsoService, private router: Router, private dialog: MatDialog, private permissionService: PermissionService) { }
  user!: User;
  userGroup!: UserGroup | null;
  onBehalfOfUserGroup!: UserGroup | null;
  sureLcUser!: SureLcSsoUser;
  businessLink = {
    Icon: 'business',
    Name: 'Request New Contract',
    Action: () => {
      this.dialog.open(GetContractedComponent, {
      });
    }
  };
  trainingLink = {
    Icon: 'school',
    Name: 'My Product Training',
    Action: () => {
      this.router.navigate(['/Portal/CarriersTraining/ProductTraining'], { queryParams: { searchValue: 'completed' } });
      // this.router.navigate(['Portal/ProductRD/EApplication/Workflow/WorkflowProductTraining'], { queryParams: { searchValue: this.selectedProduct.ProductCusip! } })
    }
  }
  stateLicensesLink = {
    Icon: 'list_alt',
    Name: 'My State Licenses',
    Action: () => {
      this.openContractingLicensesModal();
    }
  }

  links: Record<string, any>[] = this.permissionService.hasOnePermission('ProductRDOptedIn:View') ? [this.businessLink, this.trainingLink, this.stateLicensesLink] : [this.businessLink, this.stateLicensesLink]

  openContractingLicensesModal() {
    const ref = this.dialog.open(ContractingLicensesComponent, {
      width: '125rem'
    });
  }

  ngOnInit(): void {
    this.user = this.sessionStore.User;
    this.userGroup = this.sessionStore.UserGroup;
    this.onBehalfOfUserGroup = this.sessionStore.OnBehalfOfUserGroup;

    this.ssoApiService.getSureLcUser()
      .subscribe(res => {
        this.sureLcUser = res;
      });
  }

}
