import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import { PermissionService } from '../../auth/permission.service';

@Injectable({
  providedIn: 'root'
})
export class OptInGuardService implements CanActivate {

  constructor(
    private permissionService: PermissionService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const canSeeSimonOptIn = this.permissionService.hasOnePermission('ShowProductRDOptIn:View');
    const hasSimonOptIn = this.permissionService.hasOnePermission('ProductRDOptedIn:View');
    if (hasSimonOptIn) return true;
    else if (canSeeSimonOptIn) {
      this.router.navigate(['Portal/ProductRDPreview']);
      return false;
    } else {
      this.router.navigate(['Portal/FigPlatform']);
      return false;
    }
  }
}
