import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThirdLevelNavEventService {

  // Observable source
  private isThirdLevelNavOnSource = new Subject<boolean>();

  // ObservableStream
  public isThirdLevelNavOn$ = this.isThirdLevelNavOnSource.asObservable();

  broadcastIsThirdLevelNavOn(navOn: boolean) {
    this.isThirdLevelNavOnSource.next(navOn);
  }

  constructor() { }
}
