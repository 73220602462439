<section class="flex flex-col max-w-720 gap-y-60">
    <section class="flex flex-col gap-y-30">
        <h1 class="text-lg font-bold">Dialogs</h1>
        <button class="button is-primary" mat-button (click)="openApplicationOptinDialog()">Open E-App Optin Preference</button>
    </section>
    <section class="border-solid border-gray-lighter">
        <p>Simon API Test</p>
        <input type="number" class="w-full input is-lg pl-36" placeholder="Limt" [(ngModel)]="limit" name="limit" />
        <button (click)="getSimonPolicies()" mat-button class="button is-primary ">Get Policies</button>
        <br />

        <button (click)="getSimonPoliciesRecursive()" mat-button class="button is-primary ">Get simon Policies Recursive (api)</button>
        <ul *ngIf="simonPolicies">
            <li *ngFor="let p of simonPolicies">
                Policy Number: {{p.policyNumber}}
            </li>
        </ul>
    </section>

    <section class="flex flex-col m-20">
        <app-form></app-form>
    </section>

    <section>
        <p>Bob's simple form</p>
        <form [formGroup]="bobForm" (ngSubmit)="onBobFormSubmit()">
            Name: <input formControlName="name" placeholder="Enter Name">
            Height: <input formControlName="height" placeholder="Enter Height">
            <button type="submit">Submit</button>
        </form>
    </section>
    <section class="flex flex-col m-20">
        <label for="slider" class="mb-10">Slider</label>
        <mat-slider class="slider" name="slider" min="1" max="10" step="0.5" value="1.5"></mat-slider>
    </section>
    <section class="flex flex-col m-20">
        <label for="slider" class="mb-10">Inputs</label>
        <form class="example-form">

            <input class="my-10 input" currencyMask placeholder="$100.00">

            <mat-form-field class="w-full my-10">
                <mat-label>Favorite food</mat-label>
                <input matInput placeholder="Ex. Pizza" value="Sushi">
            </mat-form-field>

            <mat-form-field class="w-full my-10">
                <mat-label>Leave a comment</mat-label>
                <textarea matInput placeholder="Ex. It makes me feel..."></textarea>
            </mat-form-field>
            <mat-radio-group aria-label="Select an option" [(ngModel)]="favoriteOption">
                <mat-radio-button class="pr-20" *ngFor="let option of genericOptions; let index = index" [disabled]="index=== 0 || index === 2" [value]="option" [checked]="index === 0">{{option}}
                </mat-radio-button>
            </mat-radio-group>
        </form>
    </section>
    <section class="flex m-20">
        <div>
            <input class="flex-auto mr-10 input" [matDatepicker]="endDate" (click)='endDate.open()' [(ngModel)]="selectedDate" (ngModelChange)="onDateRangeChange(selectedDate)">
            <mat-datepicker #endDate [color]="'primary'"></mat-datepicker>
        </div>
    </section>
    <section class="flex flex-col m-20">
        <label for="slider" class="mb-10">Buttons</label>
        <div class="grid grid-cols-3 gap-x-20 gap-y-20">
            <button mat-button class="button is-sm">Button</button>
            <button mat-button class="button">Button</button>
            <button mat-button class="button is-lg">Button</button>
            <button mat-button class="button is-primary ">Button</button>
            <button mat-button class="button is-primary is-outlined ">Button</button>
            <button mat-button class="button is-primary is-inverted ">Button</button>
            <button mat-button class="button is-warning ">Button</button>
            <button mat-button class="button is-warning is-outlined ">Button</button>
            <button mat-button class="button is-warning is-inverted ">Button</button>
            <button mat-button class="button is-success ">Button</button>
            <button mat-button class="button is-success is-outlined ">Button</button>
            <button mat-button class="button is-success is-inverted ">Button</button>
        </div>
    </section>
    <section class="flex flex-col m-20">
        <label for="slider" class="mb-10">Select & Menu</label>
        <div class="grid grid-cols-3 gap-x-20 gap-y-20">
            <mat-select class="select" placeholder="Select">
                <mat-option *ngFor="let item of items" [value]="item">
                    {{item}}
                </mat-option>
            </mat-select>
            <div>
                <button mat-button class="button is-primary" [matMenuTriggerFor]="menu">Menu</button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item>Item 1</button>
                    <button mat-menu-item>Item 2</button>
                </mat-menu>
            </div>
            <div>
                <button mat-button class="button is-primary" [matMenuTriggerFor]="animals">Nested Menu</button>
                <mat-menu #animals="matMenu">
                    <button mat-menu-item [matMenuTriggerFor]="vertebrates">Vertebrates</button>
                    <button mat-menu-item>Invertebrates</button>
                </mat-menu>

                <mat-menu #vertebrates="matMenu">
                    <button mat-menu-item>Birds</button>
                    <button mat-menu-item>Mammals</button>
                </mat-menu>
            </div>
        </div>
    </section>
    <section class="flex flex-col m-20">
        <label for="slider" class="mb-10">Checkboxes</label>
        <div class="grid grid-cols-3 gap-x-20 gap-y-20">
            <mat-checkbox class="checkbox" [checked]="true">Check me!</mat-checkbox>
            <mat-checkbox class="checkbox" [disabled]="true">Disabled</mat-checkbox>
        </div>
    </section>
    <section class="flex flex-col m-20">
        <label for="slider" class="mb-10">Switches</label>
        <div class="grid grid-cols-3 gap-x-20 gap-y-20">
            <mat-slide-toggle class="m-10" [checked]="true">Click Me</mat-slide-toggle>
            <mat-slide-toggle class="m-10" [checked]="true" color="primary">Click Me</mat-slide-toggle>
        </div>
    </section>
    <section class="flex flex-col m-20">
        <label for="confirmation modal" class="mb-10">Confirmation modal</label>
        <button (click)="openConfirmationModal()">Do the thing</button>
    </section>
    <section class="flex flex-col m-20">
        <label for="confirmation modal" class="mb-10">renewal-form modal</label>
        <button (click)="openRenewalFormModal()">Open Renewal</button>
    </section>
    <section class="flex flex-col m-20">
        <label for="confirmation modal" class="mb-10">Enrollment modal</label>
        <button (click)="openEnrollmentModal()">Open Enrollment</button>
    </section>
    <section class="flex flex-col m-20">
        <label for="confirmation modal" class="mb-10">renewal-form modal</label>
        <button (click)="openPurchaseModal()">Purchase Options</button>
    </section>
    <section class="flex flex-col m-20">
        <mat-accordion>
            <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        This is the expansion title
                    </mat-panel-title>
                    <mat-panel-description>
                        This is a summary of the content
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p>This is the primary content of the panel.</p>
            </mat-expansion-panel>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Self aware panel
                    </mat-panel-title>
                    <mat-panel-description>
                        Currently I am {{panelOpenState ? 'open' : 'closed'}}
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p>I'm visible because I am open</p>
            </mat-expansion-panel>
        </mat-accordion>
    </section>
    <app-toolbox-sidebar grouping="Life"></app-toolbox-sidebar>
    <app-submit-application></app-submit-application>
    <section class="flex flex-col m-20">
        <iframe id="testIframe" [src]="iframeUrl | safeUrl" frameborder="0"></iframe>
    </section>
    <!-- <app-business></app-business> -->
    <!-- <app-lifecycle-policy-list></app-lifecycle-policy-list> -->
    <app-informal-policy-list></app-informal-policy-list>
    <app-prepare-e-application></app-prepare-e-application>
    <app-dark-mode-toggle class="fixed bottom-0 right-0 m-30"></app-dark-mode-toggle>
</section>