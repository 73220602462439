import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '../../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate, CanActivateChild {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkAuthentication(route);
  }

  canActivateChild(route:ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkAuthentication(route);
  }


  checkAuthentication(route:ActivatedRouteSnapshot): boolean{
    if (this.authService.isAuthenticated()){
      return true;
    } else {
      const theme = route.queryParamMap.get('theme');
      this.authService.logIn(theme ?? undefined);
      return false;
    }
  }
}
