import { Component, OnInit } from '@angular/core';
import { fadeIn } from 'src/app/animations';
import { PermissionService } from 'src/app/core/services/auth/permission.service';
import { FigPlatformService } from 'src/app/core/services/figplatform.service';
import { ErrorMessageModel } from 'src/app/shared/models/error-message.model';
import { FigPlatformSpotlightTile } from 'src/app/shared/models/figplatform.models';

@Component({
  templateUrl: './dashboard-whats-new.component.html',
  selector: 'app-dashboard-whats-new',
  animations: [fadeIn]
})

export class DashboardWhatsNewComponent implements OnInit {
  hasSpotlightPermission = true;
  spotlightTiles: FigPlatformSpotlightTile[] = [];
  error: ErrorMessageModel = {
    hasError: false,
    message: 'There was a problem retrieving data. Please try again later.'
  };
  loading = false;

  constructor(
    private permissionService: PermissionService,
    private figPlatformService: FigPlatformService
  ) { }

  ngOnInit(): void {
    this.getSpotlightTiles();
  }

  getSpotlightTiles(): void {
    this.loading = true;
    this.figPlatformService.getUserPermittedSpotlightTiles().subscribe({
      next: res => {
        this.spotlightTiles = res;
      },
      error: () => this.error.hasError = true
    }).add(() => {
      this.loading = false;
    });
  }

}