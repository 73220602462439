<section class="flex flex-col w-full h-full">
  <header class="flex items-center flex-none p-10 border-b border-solid md:p-20 border-gray-lighter" *ngIf="data.title">
    <h2 class="m-10 text-lg font-bold">{{data.title}}</h2>
  </header>
  <section class="flex flex-col flex-grow p-20 overflow-auto">
    <p class="m-auto text-lg">{{data.message}}</p>
  </section>
  <div class="flex flex-none p-10 mt-auto border-t border-solid md:p-20 border-gray-lighter">
    <button mat-button class="m-10 button is-primary is-outlined" [mat-dialog-close]="false" *ngIf="!data.hideCancelButton">Cancel</button>
    <button mat-button class="m-10 ml-auto button" [class]="data.isWarning ? 'is-warning' : 'is-primary'"
      [mat-dialog-close]="true">{{data.confirmationButtonText ? data.confirmationButtonText : 'Confirm'}}</button>
  </div>
</section>