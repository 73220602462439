import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { fadeIn } from 'src/app/animations';
import { FigPlatformService } from 'src/app/core/services/figplatform.service';
import { FigPlatformSpotlightTile } from 'src/app/shared/models/figplatform.models';

@Component({
  selector: 'app-dashboard-v2',
  templateUrl: './dashboard-v2.component.html',
  animations: [fadeIn]
})

export class DashboardV2Component implements OnInit {

  spotlightTiles: FigPlatformSpotlightTile[] = [];
  loading = false;
  constructor(
    private figPlatformService: FigPlatformService,
    private title: Title
  ) {
    this.title.setTitle('Dashboard | Agent Portal');
  }

  ngOnInit(): void {
    this.getSpotlightTiles();
  }

  getSpotlightTiles(): void {
    this.loading = true;
    this.figPlatformService.getUserPermittedSpotlightTiles().subscribe({
      next: res => {
        this.spotlightTiles = res;
      }
    }).add(() => {
      this.loading = false;
    });
  }
}