<section class="">
    <h2 class="mb-20 text-xl font-bold text-gray-dark">Resources</h2>
    <div class="mb-20 md:mb-30" *ngFor="let wistiaResourceGroup of wistiaResourceGroups; let index = index">
        <h2 class="mb-10 text-lg font-semibold text-gray-dark" [innerHtml]="wistiaResourceGroup.GroupName"></h2>
        <div class="grid flex-grow-0 grid-cols-2 gap-x-20 gap-y-20 md:grid-cols-3 lg:grid-cols-5">
            <div class="flex flex-col p-20 overflow-hidden transition duration-150 bg-center bg-no-repeat bg-cover cursor-pointer card hover:shadow-lg md:p-30"
                *ngFor="let resource of wistiaResourceGroup.WistiaResources">
                <a class="flex flex-col items-center justify-center w-full h-full"
                    [attr.aria-label]="'Open ' + resource.name"
                    href="https://fig.wistia.com/medias/{{resource.hashed_id}}" 
                    *ngIf="resource.type !== 'Video'">
                    <span class="mb-20 mi-36 material-icons text-gray-light">{{
                        resource.type === 'PdfDocument' ? 'picture_as_pdf' : 'description'}}</span>
                    <p class="center text-gray-dark" [innerHtml]="resource.name"></p>
                </a>
                <div class="relative w-full mx-auto mb-20 overflow-hidden rounded shadow wistia_responsive_padding h-100 min-w-128"
                    *ngIf="resource.type === 'Video'">
                    <div class="wistia_responsive_wrapper"
                        style="height: 100%; left: 0; position: absolute; top: 0; width: 100%;">
                        <span id="resourceVideo{{index}}"
                            class="wistia_embed wistia_async_{{resource.hashed_id}} popover=true popoverAnimateThumbnail=false videoFoam=false videoQuality=hd-only"
                            style="display: inline-block; height: 100%; width: 100%;"> </span>
                    </div>
                </div>
                <p class="mb-0 center text-gray-dark" [innerHtml]="resource.name" *ngIf="resource.type === 'Video'"></p>
            </div>
        </div>
    </div>
    <p class="my-20 text-sm disclaimer md:my-30" [innerHtml]="hegnaResult.description"></p>
</section>