import { Component, Input, OnInit } from '@angular/core';
import { AdditionalResource, AdditionalResourceGroup, EnrollmentSurveyData, ResourcePage, PurchasePageData } from 'src/app/shared/models/resource-page.models';
import { OrderModel } from 'src/app/shared/models/order.models';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { PurchaseModalComponent } from 'src/app/portal/modals/purchase-modal/purchase-modal.component';
import { PurchaseApiService } from 'src/app/core/services/http/purchase-api.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from 'src/app/portal/modals/confirmation/confirmation.component';
import { EnrollmentFormComponent } from 'src/app/portal/modals/enrollment-form/enrollment-form.component';
import { fadeIn } from 'src/app/animations';

@Component({
  selector: 'app-hegna-workflow',
  templateUrl: './hegna-workflow.component.html',
  animations: [fadeIn]
})
export class HegnaWorkflowComponent implements OnInit {
  @Input() resource!: ResourcePage
  purchased?: OrderModel|undefined;
  intervals: any[] = [];
  isLoading!:boolean;
  @Input() submitted?: {surveyId:number, isEnrolled:boolean}[]|undefined;

  constructor(
    private sessionStore: SessionStoreService,
    private purchaseApiService: PurchaseApiService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.verifyPurchased();
  }

  openExternalLoginLink (): void {
    window.open('https://tomhegnavt.lightspeedvt.com/');
  }

  openEnrollmentModal(surveyId: Number) {
    const dialogRef = this.dialog.open(EnrollmentFormComponent, {
      data: {
        Event: this.resource.EnrollmentSurveyData?.Event,
        SurveyId: surveyId,
        Disclaimer: this.resource.EnrollmentSurveyData?.Disclaimer
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      const survey = this.submitted?.find(item => item.surveyId === surveyId);
      if (survey && res.hasCompletedEnrollment) survey.isEnrolled = true;
    });
  }
  openDocusignLink () {
    window.open(this.purchased?.DocusignUrl);
  }

  verifyPurchased(): any {
    return this.purchaseApiService.getUserOrders(this.sessionStore.User.Id).subscribe(orders => {

      this.purchased = orders.find(o => {
        return this.resource.PurchasePageData?.PurchaseModel.PurchaseOptions.some(po => {
          return o.MarketingStrategy === po.MarketingStrategy;
        });
      });

      if (this.purchased) {
        const d = new Date(this.purchased.CompletedDate as Date);
        const n = d.toLocaleDateString();
        this.purchased.PurchaseDate = n;

        this.purchaseApiService.getMarketingStrategyOfOrder(this.purchased.Id).subscribe(strategy => {
          if (this.purchased) {
            this.purchased.DocusignUrl = strategy.DocusignUrl as string;
            this.purchased.Image = strategy.Image as string;
          }
          this.isLoading = false;
        });
        this.intervals?.forEach((interval: any) => {
          clearInterval(interval);
        });
      } else {
        this.isLoading = false;
      }
    });
  }

  pollVerifyPurchase(paymentAmount: number): void {
    if (paymentAmount > 0) {
      this.intervals.push(<any>setInterval(() => {
        this.verifyPurchased();
      }, 3000));
    } else {
      this.verifyPurchased();
    }
  }

  openPurchaseDialog(): void {
    const data = this.resource.PurchasePageData;
    if (data) {
      if (data?.ConfirmationMessage) {
        // Confirmation required
        const confirmation = this.dialog.open(ConfirmationComponent, {
          data: {
            title: data.PurchaseModel.Title,
            message: data.ConfirmationMessage
          }
        });

        confirmation.afterClosed().subscribe(result => {
          if (result) {
            // confirmation accepted
            this.purchaseResource(data);
          }
        });
      } else {
        // confirmation not required
        this.purchaseResource(data);
      }
    }
  }

  purchaseResource(data: PurchasePageData): void {

    this.purchaseApiService.getAllOrders().subscribe(orders => {

      data.PurchaseModel.Orders = orders;
      this.purchaseApiService.getMarketingDollars().subscribe(agentMarketingDollars => {
        data.PurchaseModel.AgentMarketingDollars = agentMarketingDollars.Entry.MarketingDollarsTotal;
      },
      error => {
        console.log(error);
        data.PurchaseModel.AgentMarketingDollars = 0;
      },
      () => {
        // finally block
        // open the modal finally
        const ref = this.dialog.open(PurchaseModalComponent, {
          data: {
            purchaseModel: data.PurchaseModel,
          }
        });

        ref.afterClosed().subscribe(result => {
          if (result) {
            this.pollVerifyPurchase(result.paymentAmount);
          }
        });
      });
    });
  }
}
