import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { UserGroupApiService } from 'src/app/core/services/http/user-group-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-log-out',
  templateUrl: './log-out.component.html',
})
export class LogOutComponent implements OnInit {
  logoUrl!: string;
  environment = environment;
  figLogo: string = './assets/images/fig-logo.svg';
  constructor(private activatedRoute: ActivatedRoute, private authService: AuthService, private userGroupApiService: UserGroupApiService) { }

  ngOnInit(): void {
    const userGroupId = this.activatedRoute.snapshot.queryParamMap.get('userGroupId');
    if (userGroupId) {
      this.userGroupApiService.getLogo(userGroupId!).subscribe(res => {
        this.logoUrl = `/api/UserGroups/${userGroupId}/Logo`;
      },
      (error) => {
        console.log(error);
        this.logoUrl = this.figLogo;
      });
    } else {
      this.logoUrl = this.figLogo;
    }
  }

  goToLogIn(): void {
    this.authService.logIn();
  }

  getYear(): number {
    return new Date().getFullYear();
  }

}
