import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { orderBy } from 'lodash';
import { ArcApiService } from 'src/app/core/services/http/arc-api.service';
import { ReportsApiService } from 'src/app/core/services/http/reports-api.service';
import { marketingDollarStates } from 'src/app/shared/constants/marketingDollars.constants';
import { State } from 'src/app/shared/models/generic.models';
import { PaperPolicyApplication } from 'src/app/shared/models/policy.models';
import { ArcCarrier, ArcProductDetail, ArcProductList } from 'src/app/shared/models/product.models';
// import { FileSelectDirective, FileDropDirective, FileUploader } from 'ng2-file-upload/ng2-file-upload';

export interface PaperApplicationModel {
  ClientFirstName: string;
  ClientLastName: string;
  StateId: string;
  Notes: string;
}
@Component({
  selector: 'app-submit-paper-application',
  templateUrl: './submit-paper-application.component.html',
})
export class SubmitPaperApplicationComponent implements OnInit {
  // Using type any to be able to use bracket notation property access
  paperPolicyApplication: any = { ClientFirstName: null, ClientLastName: null, PublicProductGuid: null, Notes: '', StateId: null }
  carrierGuid!: string;
  carrier: ArcProductDetail | null = null;
  allCarriers!:ArcProductDetail[];
  filteredCarriers!: ArcProductDetail[];
  isSubmitting: boolean = false;
  isUploading: boolean = false;
  isSuccess:boolean = false;
  isError:boolean = false;
  loading: boolean = false;
  product: ArcProductDetail | null = null;
  products!: ArcProductDetail[];
  filteredProducts!: ArcProductDetail[];
  searchTextCarrier: string = '';
  searchTextProduct: string = '';
  states: State[] = marketingDollarStates.filter(state => state.IsVisible);
  uploadingFiles: File[] = [];
  dragover: boolean = false;
  removedAFile: boolean = false;
  // uploader:FileUploader | undefined;

  constructor(
    private arcApiService: ArcApiService,
    private reportsApiService: ReportsApiService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any) { }

  ngOnInit(): void {
    this.loading = true;
    this.arcApiService.getArcProducts()
      .subscribe(
        (res) => {
          this.products = orderBy(res.Products, 'Product');
          this.getCarriers();
          this.filterProducts();
          if (!!this.data) this.prefillData();
        },
        (error) => console.log(error),
        () => this.loading = false
      );
  }

  prefillData() {
    if (this.data.ClientFirstName) this.paperPolicyApplication.ClientFirstName = this.data.ClientFirstName;
    if (this.data.ClientLastName) this.paperPolicyApplication.ClientLastName = this.data.ClientLastName;
    if (this.data.Cusip){
      this.product = this.filteredProducts.find(product => product.Cusip === this.data.Cusip) ?? null;
      this.searchTextProduct = this.product ? this.product.Product : '';
      this.paperPolicyApplication.PublicProductGuid = this.product?.PublicProductGuid;
      this.carrier = this.product ? this.product : null;
      this.searchTextCarrier = this.carrier ? this.carrier!.Business : '';
    }
    if (this.data.StateAbbreviation) {
      const stateMatch = this.states.find(state => state.State == this.data.StateAbbreviation);
      this.paperPolicyApplication.StateId = stateMatch ? stateMatch.StateID : null;
    }

    this.filterProducts();
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  getCarriers() {
    const carriers = this.products.filter((value, index, array) => array.findIndex(x => x.PublicBusinessGuid == value.PublicBusinessGuid) == index);
    const orderedCarriers = orderBy(carriers, 'Business');
    this.allCarriers = orderedCarriers;
    this.filteredCarriers = orderedCarriers;

  }

  setProduct(productName: string) {
    this.product = this.products.find(product => product.Product == productName)!;
    this.paperPolicyApplication.PublicProductGuid = this.product.PublicProductGuid;
  }

  setState(state: State) {
    this.paperPolicyApplication.StateId = state.StateID;
  }

  removeFile(file: File) {
    const index = this.uploadingFiles.indexOf(file);
    this.uploadingFiles.splice(index, 1);
    this.removedAFile = true;
  }

  reset() {
    this.loading = true;
    this.paperPolicyApplication = { ClientFirstName: null, ClientLastName: null, PublicProductGuid: null, Notes: '', StateId: null };
    this.uploadingFiles = [];
    this.searchTextProduct = '';
    this.searchTextCarrier = '';
    this.isSubmitting = false;
    this.isUploading = false;
    this.isError = false;
    this.isSuccess =false;
    this.filterProducts();
    this.loading = false;
  }

  searchCarriers(searchTerm: string): ArcProductDetail[] {
    searchTerm = searchTerm ? searchTerm.toLowerCase() : '';
    const results = searchTerm ?
      this.allCarriers.filter(row => {
        if (row.Business && row.Business.toLowerCase().indexOf(searchTerm) !== -1) return true;
        return false;
      })
      : this.allCarriers;

    return results;
  }

  searchProducts(searchTerm: string): ArcProductDetail[] {
    if (this.carrier == null) return this.products;

    searchTerm = !!searchTerm ? searchTerm.toLowerCase() : '';
    let results = searchTerm ?
      this.products.filter(row => {
        if (row.Product && row.Product.toLowerCase().indexOf(searchTerm) !== -1) return true;
        if (row.LineOfBusiness && row.LineOfBusiness.toLowerCase().indexOf(searchTerm) !== -1) return true;
        return false;
      })
      : this.products;

    results = results.filter(row => {
      return row.PublicBusinessGuid == this.carrier!.PublicBusinessGuid;
    });
    return results;
  }

  sendPolicyAndFilesToArc(paperApplication: any) {
    this.isSubmitting = true;
    const formData = new FormData();

    Object.keys(paperApplication).forEach(x => formData.append(x, paperApplication[x]));
    this.uploadingFiles.forEach(function (file) {
      formData.append(file.name, file, file.name);
    });
    this.reportsApiService.postNewPolicyFiles(formData)
      .subscribe(
        (res) => {
          this.isSubmitting = false;
          this.isSuccess = true;
        },
        (error) => {
          this.isSubmitting = false;
          this.isError = true;
        });
  }

  uploadFiles(event: any) {
    this.dragover = false;
    const files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      this.uploadingFiles.push(files[i]);
    }
  }

  dragOverHandler(event: any) {
    this.dragover = true;
    event.preventDefault();
    event.stopPropagation();
  }

  displayProductFn(product: ArcProductDetail): string {
    return product && product.Product ? product.Product : '';
  }

  displayCarrierFn(carrier: ArcProductDetail): string {
    return carrier && carrier.Business ? carrier.Business : '';
  }

  filterProducts(): void{
    this.filteredProducts = this.searchProducts(this.searchTextProduct);
  }


  filterCarriers(): void {
    this.filteredCarriers = this.searchCarriers(this.searchTextCarrier);
  }

  setCarrier(carrierName:string): void {
    this.carrier = this.filteredCarriers.find(carrier => carrier.Business == carrierName)!;
    this.searchTextProduct = '';
    this.filterProducts();
    this.paperPolicyApplication.PublicProductGuid = null;
  }
}
