import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CansellApiService } from 'src/app/core/services/http/cansell-api.service';
import { OnboardingTermsOfUse } from 'src/app/shared/models/onboarding.models';
import { OnboardingApiService } from 'src/app/core/services/http/onboarding-api.service';
import { Observable, forkJoin, of } from 'rxjs';
import { OptInEventService } from 'src/app/core/services/stores/opt-in-event.service';
import { UserApiService } from 'src/app/core/services/http/user-api.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { TermsOfUseModalComponent } from '../../modals/product-rd-tou/terms-of-use/terms-of-use-modal.component';
import { Router } from '@angular/router';
import { PermissionApiService } from 'src/app/core/services/http/permission-api.service';
import { PermissionService } from 'src/app/core/services/auth/permission.service';
import { AgentLicenseApiService } from 'src/app/core/services/http/agent-license-api.service';
import { AgentLicenses } from 'src/app/shared/models/agent.models';
import { catchError } from 'rxjs/operators';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { User } from 'src/app/shared/models/user.models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QuinciOptInDataModel } from 'src/app/shared/models/product-rd-optin-data.models';
import { ConfirmationData } from 'src/app/shared/models/modal-data.models';
import { ConfirmationComponent } from '../../modals/confirmation/confirmation.component';
import { DateComparisonHelper } from 'src/app/shared/helpers/dates-comparison/date-comparison.helper';
import { quinciHardDeadlineDateUTC, quinciLaunchDateUTC } from 'src/app/shared/constants/quinci-dates.constants';

@Component({
  selector: 'app-product-rd',
  templateUrl: './product-rd.component.html',
})
export class ProductRdComponent {
  @ViewChild('simpleDialog') simpleDialog!: TemplateRef<any>;
  onBehalfOfUser: User | null;
  user: User;
  isArcUser: boolean = false;
  agentLicenses!: AgentLicenses;
  isOptedIn: boolean = true;
  onboardingTermsOfUse?: OnboardingTermsOfUse;
  dialogData: ConfirmationData = {
    message: '',
    confirmationButtonText: 'Back to Home',
    hideCancelButton: true,
  }
  @ViewChild('quinciContainerContent')
  private quinciContentDiv!: ElementRef<HTMLElement>

  constructor(
    private dialog: MatDialog,
    private onboardingApiService: OnboardingApiService,
    private userApiService: UserApiService,
    private router: Router,
    private permissionService: PermissionService,
    private permissionApiService: PermissionApiService,
    private optInEventService: OptInEventService,
    private agentLicenseApiService: AgentLicenseApiService,
    private sessionStoreService: SessionStoreService,
    private snackBar: MatSnackBar) {
    this.onBehalfOfUser = this.sessionStoreService.OnBehalfOfUser;
    this.user = this.sessionStoreService.User;
  }

  onActivate(_event: any): void {
    if (this.quinciContentDiv) {
      (this.quinciContentDiv.nativeElement as HTMLElement).scrollTop = 0;
    }
  }


  getComponent() {
    const homeUrl: string = '/Portal/ProductRD';
    const path: string[] = window.location.pathname.split(homeUrl);
    return path[1];
  }
}
