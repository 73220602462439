<mat-sidenav-container *ngIf="!loading" class="w-full h-full" autosize>
    <!-- User right nav -->
    <mat-sidenav class="w-full h-full xs:w-1/2 sm:w-200 dark:border-gray-dark bg-gray-lightest" position="start"
        #userSettingsSidenav [mode]="userSettingsConfig.Mode" [opened]="userSettingsConfig.IsOpened">
        <div class="flex flex-col m-20 lg:m-30 gap-y-10">
            <ng-container *ngFor="let component of settingsComponents">
                <button class="button is-primary is-inverted" mat-button
                    [class]="this.selectedComponent ===  component ? '': 'text-gray'"
                    (click)="selectComponent(component)"
                    [amplitudeEvent]="'User Settings Menu: Edit User ' + component.name">
                    <span class="flex items-center justify-start">
                        <span class="mr-10 mi-18 material-icons">{{component.icon}}</span>
                        <span>{{component.name}}</span>
                    </span>
                </button>
            </ng-container>
        </div>
        <!-- <div class="flex flex-col m-20 lg:m-30 gap-y-10">
            <button class="button is-primary is-inverted" mat-button
                [class]="getSelectedComponent()==='User Profile' ? '': 'text-gray'"
                (click)="selectComponent('User Profile')" amplitudeEvent="User Settings Menu: Edit User Profile">
                <span class="flex items-center justify-start">
                    <span class="mr-10 mi-18 material-icons">person</span>
                    <span>Profile</span>
                </span>
            </button>
            <button class="button is-primary is-inverted" mat-button
                [class]="getSelectedComponent()==='Website Logo' ? '': 'text-gray'"
                (click)="selectComponent('Website Logo')" amplitudeEvent="User Settings Menu: Edit Logo">
                <span class="flex items-center justify-start">
                    <span class="mr-10 mi-18 material-icons">image</span>
                    <span>Website Logo</span>
                </span>
            </button>
            <button class="button is-primary is-inverted" mat-button
                [class]="getSelectedComponent()==='Renewals' ? '': 'text-gray'" (click)="selectComponent('Renewals')"
                amplitudeEvent="User Settings Menu: View Renewals">
                <span class="flex items-center justify-start">
                    <span class="mr-10 mi-18 material-icons">event</span>
                    <span>Renewals</span>
                </span>
            </button>
        </div> -->
    </mat-sidenav>

    <mat-sidenav-content class="flex flex-col h-full overflow-hidden dark:bg-gray-darkest" @fadeIn>
        <header class="flex items-center flex-none p-20 border-b border-solid border-gray-lighter">
            <h2 class="m-10 text-lg font-bold">{{selectedComponent.name}}</h2>
            <!-- <button mat-button class="m-10 ml-auto icon-button is-primary is-inverted" attr.aria-label="Close dialog"
            mat-dialog-close (click)="closeDialog()"><i class="material-icons">close</i></button> -->
        </header>
        <section class="flex flex-col flex-grow overflow-auto">
            <!-- <app-user-profile *ngIf="componentIsSelected(settingsComponents[0])"></app-user-profile> -->
            <app-user-logo-upload *ngIf="componentIsSelected(settingsComponents[0])"></app-user-logo-upload>
            <app-renewal-form [formParametersComponent]="data.renewals" *ngIf="componentIsSelected(settingsComponents[1])"></app-renewal-form>
        </section>
    </mat-sidenav-content>
</mat-sidenav-container>