import { Component, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { fadeIn, fadeInOut } from 'src/app/animations';
import { PermissionService } from 'src/app/core/services/auth/permission.service';
import { PermissionApiService } from 'src/app/core/services/http/permission-api.service';
import { UserApiService } from 'src/app/core/services/http/user-api.service';
import { OptInEventService } from 'src/app/core/services/stores/opt-in-event.service';
import { PortalNavItem } from 'src/app/shared/models/portal-nav-item';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TermsOfUseModalComponent } from 'src/app/portal/modals/product-rd-tou/terms-of-use/terms-of-use-modal.component';
import { OnboardingApiService } from 'src/app/core/services/http/onboarding-api.service';
import { OnboardingTermsOfUse } from 'src/app/shared/models/onboarding.models';
import { Observable } from 'rxjs';
import { AgentLicenseApiService } from 'src/app/core/services/http/agent-license-api.service';
import { AgentLicenses } from 'src/app/shared/models/agent.models';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { User } from 'src/app/shared/models/user.models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AmplitudeEventService } from 'src/app/core/services/amplitude/amplitude-event.service';
import { EventEmitter } from '@angular/core';
import { NavigationService } from 'src/app/core/services/navigation.service';

@Component({
  animations: [fadeIn, fadeInOut],
  selector: 'app-splash',
  templateUrl: './splash.component.html',
})
export class SplashComponent implements OnInit {
  isLoading: boolean = false;
  onBehalfOfUser: User | null;
  agentLicenses?: AgentLicenses;
  onboardingTermsOfUse?: OnboardingTermsOfUse;
  isOptedIn: boolean = false;
  isArcUser: boolean = false;
  environment = environment;
  columns: column[] = [
    {
      Data: [
        {},
        {
          Header: 'Product Training At Your Fingertips',
          Description: 'Track and complete all your product training in one platform.'
        },
        {
          Header: '"Efficient Frontier" Allocation Analysis',
          Description: 'Tailor the features of your client\'s product to their risk tolerance.'
        },
        {
          Header: 'All Your Product Needs On A Single Platform',
          Description: 'From insurance to structured investments, we\'ve got it all!'
        },
        {
          Header: 'Policy Lifecycle Management',
          Description: 'View and manage your business every step of the way - submission to inforce and beyond.'
        },
        {
          Header: 'Fully-Integrated, Seamless E-Applications',
          Description: 'All-in-one E-application workflow with built-in training checks.'
        },
        {
          Header: 'Income Calculator',
          Description: 'Estimate your client\'s first income payment under the terms of a&nbsp;rider.'
        },
        {

        },
      ]
    },
    {
      Data: [
        { Header: 'Without Quinci powered by SIMON' },
        {
          // Description: `Must go to carrier's website`
          Icon: 'remove_circle',
          IconClass: 'text-red'

        },
        {
          Icon: 'remove_circle',
          IconClass: 'text-red'
        },
        {
          Icon: 'remove_circle',
          IconClass: 'text-red'
        },
        {
          Icon: 'remove_circle',
          IconClass: 'text-red'
        },
        {
          Icon: 'remove_circle',
          IconClass: 'text-red'
          // Description: `Must go to Firelight website`
        },
        {
          Icon: 'remove_circle',
          IconClass: 'text-red'
        },
        {

        },
      ]
    },
    {
      Data: [
        { Header: 'With Quinci powered by SIMON' },
        {
          Icon: 'check_circle',
          IconClass: 'text-green'
        },
        {
          Icon: 'check_circle',
          IconClass: 'text-green'
        },
        {
          Icon: 'check_circle',
          IconClass: 'text-green'
        },
        {
          Icon: 'check_circle',
          IconClass: 'text-green'
        },
        {
          Icon: 'check_circle',
          IconClass: 'text-green'
        },
        {
          Icon: 'check_circle',
          IconClass: 'text-green'
        },
        {

        },
      ]
    },
  ];

  fingertips: fingertip[] = [
    {
      Icon: 'local_library',
      Title: 'Education & Resources',
      Description: 'Grow your knowledge with access to our extensive library in partnership with&nbsp;SIMON.'
    },
    {
      Icon: 'school',
      Title: 'Carrier Product Training',
      Description: 'Take the worry out of tracking what training you\'ve completed by doing everything in one centralized&nbsp;area.'
    },
    {
      Icon: 'show_chart',
      Title: 'Financial Planning & Spectrum',
      Description: 'Dive deep into the goals of your client with our custom integrations and library of the top fintech&nbsp;tools.'
    },
    {
      Icon: 'storefront',
      Title: 'Annuity and Structured Marketplace',
      Description: 'Search, visualize, and analyze products and performance like never&nbsp;before.'
    },
    {
      Icon: 'drive_file_rename_outline',
      Title: 'E-Application Enhanced',
      Description: 'Combine advanced rules and real-time product training checks into your already streamlined e-application process for perfect&nbsp;execution.'
    },
    {
      Icon: 'work',
      Title: 'Business and Lifecycle',
      Description: 'Track and analyze all of your pending and inforce business across all lines of&nbsp;business.'
    },
  ];
  // additionalJourneyCategory: PortalNavItem = {
  //   Icon: 'work',
  //   Name: 'Lifecycle',
  //   DescriptionShort: `Track and analyze all of your pending and inforce business across all lines of business.`,
  //   RouterUrl: 'Business/Fixed/Lifecycle',
  //   Resolve: () => this.permissionService.hasAllPermissions('Reports:NewBusiness|ProductRDOptedIn:View'),
  // };
  journeyCategories: PortalNavItem[] = this.navigationService.portalNavItems.find(item => {
    return item.RouterUrl === 'ProductRD';
  })!.SubNav || [];
  @Output() splashClick: EventEmitter<string> = new EventEmitter();

  //Removed permission filter as user won't have child permissions if they haven't opted in yet
  // .filter(item => item.ShowIfAll ? this.permissionService.hasAllPermissions(item.ShowIfAll) : true);

  constructor(
    private dialog: MatDialog,
    private optInEventService: OptInEventService,
    private permissionApiService: PermissionApiService,
    private permissionService: PermissionService,
    private router: Router,
    private userApiService: UserApiService,
    private onboardingApiService: OnboardingApiService,
    private agentLicenseApiService: AgentLicenseApiService,
    private sessionStoreService: SessionStoreService,
    private snackBar: MatSnackBar,
    private amplitudeEventService: AmplitudeEventService,
    private navigationService: NavigationService,
  ) {
    this.onBehalfOfUser = this.sessionStoreService.OnBehalfOfUser;
  }

  ngOnInit(): void {
    // TODO refactor due to multiple calls
    this.isOptedIn = this.verifyIsOptedIn();
    this.userApiService.getIsArcUser().subscribe(res => this.isArcUser = res);
    this.agentLicenses = this.sessionStoreService.User.AgentLicenses;
    this.onboardingApiService.getLoggedInUserTermsOfUse().subscribe(
      res => this.onboardingTermsOfUse = res,
      error => Observable.throw(new Error(error)));
  }

  scrollTo(id: string) {
    this.splashClick.emit(id);
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'close');
  }

  activateOptIn() {
    //open confirmation modal
    this.isLoading = true;
    this.isOptedIn = true;
    this.userApiService.updateOptIn().subscribe(
      res => {
        this.permissionApiService.getPolicyStatements().subscribe(permissions => {
          this.isLoading = false;
          this.permissionService.setPermissions(permissions);
          this.optInEventService.broadcastIsOptin(true);
          this.router.navigate(['/Portal/ProductRD/Preview']);
        });
      },
      error => {
        this.isOptedIn = false;
        this.openSnackBar('Sorry, an error has occurred. Please try again later or contact us for support.');
        Observable.throw(new Error(error));
      });
  }

  updateProductRDTermsOfUse() {
    this.onboardingApiService.acceptTermsOfUse().subscribe(
      res => this.onboardingTermsOfUse = res,
      error => Observable.throw(new Error(error))
    );
  }

  updateAgentLicenses(agentLicenses: AgentLicenses) {
    if (!!agentLicenses.Crd || !!agentLicenses.Npn)
      this.sessionStoreService.setAgentLicenses(agentLicenses.Crd!, agentLicenses.Npn!).subscribe();
  }

  openOptInModal() {
    if (this.isOptedIn || this.onBehalfOfUser) return;

    this.dialog.open(TermsOfUseModalComponent, {
      minHeight: '52rem',
      disableClose: true,
      data: {
        isArcUser: this.isArcUser,
        isOptedIn: this.isOptedIn,
        onboardingTermsOfUse: this.onboardingTermsOfUse,
        agentLicenses: this.agentLicenses,
        onBehalfOfUser: this.onBehalfOfUser,
      }
    }).afterClosed()
      .subscribe(res => {
        if (res.userAccepts && !this.onBehalfOfUser) {
          if (!this.isOptedIn) this.activateOptIn();
          if (!this.onboardingTermsOfUse || !this.onboardingTermsOfUse.HasAgreedToTermsOfUse) this.updateProductRDTermsOfUse();
          if (res.agentLicenses) this.updateAgentLicenses(res.agentLicenses);
        }

        if (res.userAccepts) this.amplitudeEventService.logClickEvent('Post-Symposium: Opted In');
        else if (!res.userAccepts) this.amplitudeEventService.logClickEvent('Post-Symposium: Cancelled Opt-In');
      });
  }

  deactivateOptIn() {
    this.isLoading = true;
    this.userApiService.updateOptOut().subscribe(
      res => {
        this.permissionApiService.getPolicyStatements().subscribe(permissions => {
          this.permissionService.setPermissions(permissions);
          console.log(res);
          this.optInEventService.broadcastIsOptin(false);
          this.router.navigate(['/Portal/ProductRDPreview']);
          this.isOptedIn = false;
          this.isLoading = false;
        });
      },
      error => {
        this.isOptedIn = true;
        this.openSnackBar('Sorry, en error has occurred. Please try again later or contact us for support.');
        Observable.throw(new Error(error));
      });
  }

  verifyIsOptedIn(): boolean {
    this.isLoading = true;
    this.userApiService.getIsOptedIn()
      .subscribe(
        result => {
          this.isOptedIn = !!result;
        },
        error => {
          console.log('Error', error);
        })
      .add(()=>{
        this.isLoading = false;
      });
    return this.isOptedIn;
  }
}

export interface fingertip {
  Icon: string,
  Title: string,
  Description: string
}
export interface column {
  Data: columnInfo[]
}
export interface columnInfo {
  Header?: string,
  Description?: string,
  Icon?: string,
  IconClass?: string
}