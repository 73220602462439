<!-- <section @fadeIn>
    <iframe class="w-full min-h-640" [appIframeData] [src]="iframeUrl | safeUrl"></iframe>
    <section class="flex flex-wrap col-span-1 md:col-span-3">
        <div class="flex flex-col flex-grow w-auto h-full card">
            <div
                class="flex flex-row items-center justify-start p-10 border-b border-solid lg:p-20 border-gray-lighter">
                <div class="h-24 m-10 mr-20">
                </div>
            </div>            
            <div class="flex flex-row items-center justify-between border-b border-solid px-30 border-gray-lighter">                
                <mat-checkbox class="p-5 ml-auto checkbox" [(ngModel)]="localSimon" (change)="openSimonSso()">Local Simon
                </mat-checkbox>
            </div>
            <iframe class="w-full min-h-640" [appIframeData] [src]="iframeUrl | safeUrl"></iframe>
        </div>
    </section>
</section> -->

<section class="w-full h-full" @fadeIn>
    <div *ngIf="environment.qa" class="absolute top-0 right-0 p-10 px-20 m-10 rounded-full shadow-lg bg-gray-lighter">
        <mat-slide-toggle [(ngModel)]="localSimon" (change)="openSimonSso()">Local Simon</mat-slide-toggle>
    </div>
    <iframe class="w-full h-full min-h-720" [appIframeData] [src]="iframeUrl | safeUrl"></iframe>
</section>