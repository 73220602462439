import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { SsoApiService } from 'src/app/core/services/http/sso-api.service';
import { SsoService } from 'src/app/core/services/sso.service';
import { UserGroupApiService } from 'src/app/core/services/http/user-group-api.service';
import { SureLcSsoUser } from 'src/app/shared/models/sso.models';
import { User } from 'src/app/shared/models/user.models';
import { UserGroup } from 'src/app/shared/models/usergroups.models';

@Component({
  selector: 'app-get-contracted',
  templateUrl: './get-contracted.component.html',
})
export class GetContractedComponent implements OnInit {
  user!:User;
  userGroup!:UserGroup|null;
  onBehalfOfUserGroup!:UserGroup|null;
  sureLcUser!:SureLcSsoUser;
  checklist:string[] = ['Scan of your E&O', 'Scan of a voided check', 'EIN &amp; business address'];

  constructor(private ssoApiService:SsoApiService, private sessionStore:SessionStoreService, private ssoService:SsoService) { }

  ngOnInit(): void {
    this.user = this.sessionStore.User;
    this.userGroup = this.sessionStore.UserGroup;
    this.onBehalfOfUserGroup = this.sessionStore.OnBehalfOfUserGroup;

    this.ssoApiService.getSureLcUser()
      .subscribe(res => {
        this.sureLcUser = res;
      });
  }


  goToSureLc(){
    this.userGroup = this.onBehalfOfUserGroup || this.userGroup;

    if (this.userGroup && this.userGroup.AffiliationLink){
      window.open(this.userGroup.AffiliationLink);
    } else {
      this.ssoService.sureLcSso(this.sureLcUser);
    }
  }

}
