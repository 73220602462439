import { Injectable } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { simonLogout } from 'src/app/shared/helpers/simon-logout.helper';
import { environment } from 'src/environments/environment';
import { SessionStoreService } from '../stores/session-store.service';
import { PermissionService } from './permission.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private permissionService:PermissionService,
    private cookieService:CookieService,
    private sessionStore:SessionStoreService,
    private activatedRoute:ActivatedRoute) { }
  environment = environment;

  logout() {
    simonLogout.subscribe(() => {
      let redirectUri = '/Logout';
      const userGroupId = this.sessionStore.OnBehalfOfUser?.UserGroupId ?? this.sessionStore.User.UserGroupId ?? null;
      if (userGroupId) redirectUri += `?userGroupId=${userGroupId}`;
      this.permissionService.clearAllLocalStorage();
      window.location.href = '/api/Logout/Fig/Redirect?redirectUri=' + window.encodeURIComponent(redirectUri);
    });
  }

  logIn(theme?:string) {
    simonLogout.subscribe(() => {
      theme = theme ?? localStorage.getItem('UserGroupId') ?? undefined;
      this.permissionService.clearAllLocalStorage();
      const redirectUri =  this.getRedirectUri();
      let url = `/api/Login/Fig/Redirect?redirectUri=${window.encodeURIComponent(redirectUri)}`;
      if (theme){
        url += `&theme=${theme}`;
      }
      window.location.href = url;
    });
  }

  private getRedirectUri(): string {
    // To prevent race condition when trying to load simonSso iframe along with another simon iframe at the same time
    // redirect users currently on a ProductRD page to the home component to allow simon sso to have time to load.
    const homeUrl =  `${window.location.origin}/Portal`;
    const currentUrl = window.location.href;
    if (currentUrl.includes('ProductRD') || currentUrl.includes('Logout') || currentUrl.includes('#')) return homeUrl;
    else return currentUrl;
  }

  isAuthenticated():boolean {
    const timerCookie = this.cookieService.get('.timer.portal');

    if (!timerCookie) return false;

    const expiration = new Date(timerCookie);
    if (window.isNaN(expiration.getTime())) return false;

    return expiration > new Date();
  }
}
