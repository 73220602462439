import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PortalComponent } from './portal.component';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../core/core.module';
//Angular Mat imports
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip'; //matTooltipPosition=[“above”, “below”, “left”, “right”]
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { AngularSandboxComponent } from './components/angular-sandbox/angular-sandbox.component';
import { CarriersTrainingComponent } from './components/carriers-training/carriers-training.component';
import { FigPlatformComponent } from './components/dashboard-container/components/fig-platform/fig-platform.component';
import { MyBusinessComponent } from './components/my-business/my-business.component';
import { SimonComponent } from './components/simon/simon.component';
import { ToolboxComponent } from './components/toolbox/toolbox.component';
import { LeaderboardComponent } from './modals/leaderboard/leaderboard.component';
import { RedeemMarketingDollarsComponent } from './modals/redeem-marketing-dollars/redeem-marketing-dollars.component';
import { SubmitPaperApplicationComponent } from './modals/submit-paper-application/submit-paper-application.component';
import { SupportComponent } from './modals/support/support.component';
import { CarriersProductTrainingComponent } from './components/carriers-training/components/carriers-product-training/carriers-product-training.component';
import { GeneralTrainingComponent } from './components/carriers-training/components/general-training/general-training.component';
import { GetContractedComponent } from './components/carriers-training/components/get-contracted/get-contracted.component';
import { MyCarriersComponent } from './components/carriers-training/components/my-carriers/my-carriers.component';
import { RequirementsComponent } from './components/business/components/requirements/requirements.component';
import { PolicyDetailsComponent } from './components/business/components/policy-details/policy-details.component';
import { PolicyListComponent } from './components/my-business/policy-list/policy-list.component';
import { PortalRoutingModule } from './portal-routing.module';
import { ResourceDetailsComponent } from './components/dashboard-container/components/fig-platform/components/resource-details/resource-details.component';
import { NextComponent } from './components/dashboard-container/components/fig-platform/templates/next/next.component';
import { EnrollmentFormComponent } from './modals/enrollment-form/enrollment-form.component';
import { RenewalFormComponent } from './modals/renewal-form/renewal-form.component';
import { PurchasePageComponent } from './components/purchase-page/purchase-page.component';
import { ConfirmationComponent } from './modals/confirmation/confirmation.component';
import { FormComponent } from './components/angular-sandbox/components/form/form.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { customCurrencyMaskConfig } from '../shared/config/ngx-currency.config';
import { MatNativeDateModule } from '@angular/material/core';
import { PurchaseModalComponent } from './modals/purchase-modal/purchase-modal.component';
import { DashboardYearlyProductionComponent } from './components/dashboard-container/components/dashboard/components/dashboard-yearly-production/dashboard-yearly-production.component';
import { DashboardComponent } from './components/dashboard-container/components/dashboard/dashboard.component';
import { DashboardBusinessSummaryComponent } from './components/dashboard-container/components/dashboard/components/dashboard-business-summary/dashboard-business-summary.component';
import { DashboardExploreComponent } from './components/dashboard-container/components/dashboard/components/dashboard-explore/dashboard-explore.component';
import { DashboardUpcomingComponent } from './components/dashboard-container/components/dashboard/components/dashboard-upcoming/dashboard-upcoming.component';
import { ElevateComponent } from './components/dashboard-container/components/fig-platform/templates/elevate/elevate.component';
import { DigitalMarketingAssetsComponent } from './components/dashboard-container/components/fig-platform/templates/digital-marketing-assets/digital-marketing-assets.component';
import { DashboardContainerComponent } from './components/dashboard-container/dashboard-container.component';
import { PozWorkflowComponent } from './components/dashboard-container/components/fig-platform/components/enrollment-purchase-workflows/poz-workflow/poz-workflow.component';
import { ForceCrmWorkflowComponent } from './components/dashboard-container/components/fig-platform/components/enrollment-purchase-workflows/force-crm-workflow/force-crm-workflow.component';
import { SurgeWorkflowComponent } from './components/dashboard-container/components/fig-platform/components/enrollment-purchase-workflows/surge-workflow/surge-workflow.component';
import { RetireUpWorkflowComponent } from './components/dashboard-container/components/fig-platform/components/enrollment-purchase-workflows/retire-up-workflow/retire-up-workflow.component';
import { HegnaWorkflowComponent } from './components/dashboard-container/components/fig-platform/components/enrollment-purchase-workflows/hegna-workflow/hegna-workflow.component';
import { ProductRdComponent } from './components/product-rd/product-rd.component';
import { EducationComponent } from './components/product-rd/components/education/education.component';
import { FinancialPlanningComponent } from './components/product-rd/components/financial-planning/financial-planning.component';
import { MarketplaceComponent } from './components/product-rd/components/marketplace/marketplace.component';
import { FixedIndexedAnnuitiesComponent } from './components/product-rd/components/education/components/fixed-indexed-annuities/fixed-indexed-annuities.component';
import { LifeInsuranceComponent } from './components/product-rd/components/education/components/life-insurance/life-insurance.component';
import { CarePlanningComponent } from './components/product-rd/components/education/components/care-planning/care-planning.component';
import { DisabilityComponent } from './components/product-rd/components/education/components/disability/disability.component';
import { MedicareComponent } from './components/product-rd/components/education/components/medicare/medicare.component';
import { RilasComponent } from './components/product-rd/components/education/components/rilas/rilas.component';
import { VariableAnnuitiesComponent } from './components/product-rd/components/education/components/variable-annuities/variable-annuities.component';
import { StructuredInvestmentsComponent } from './components/product-rd/components/education/components/structured-investments/structured-investments.component';
import { MarketplaceDetailsComponent } from './components/product-rd/components/marketplace/components/marketplace-details/marketplace-details.component';
import { MarketplaceOverviewComponent } from './components/product-rd/components/marketplace/components/marketplace-overview/marketplace-overview.component';
import { QuestionnairePopupComponent } from './components/toolbox/questionnaire-popup/questionnaire-popup/questionnaire-popup.component';
import { HegnaComponent } from './components/dashboard-container/components/fig-platform/templates/hegna/hegna.component';
import { ElevateWorkflowComponent } from './components/dashboard-container/components/fig-platform/components/enrollment-purchase-workflows/elevate-workflow/elevate-workflow.component';
import { ToolboxSidebarComponent } from './components/toolbox/toolbox-sidebar/toolbox-sidebar/toolbox-sidebar.component';
import { OrderCompleteComponent } from './components/externals/order-complete/order-complete.component';
import { SignatureCompleteComponent } from './components/externals/signature-complete/signature-complete.component';
import { MarketingExpenseHistoryComponent } from './modals/marketing-expense-history/marketing-expense-history/marketing-expense-history.component';
import { CarePlanningContainerComponent } from './components/product-rd/components/education/components/care-planning-container/care-planning-container.component';
import { SubmitApplicationComponent } from './components/submit-application/submit-application.component';
import { SubmitApplicationModalComponent } from './modals/submit-application-modal/submit-application-modal.component';
import { CopyApplicationModalComponent } from './modals/copy-application-modal/copy-application-modal.component';
import { SimonSpectrumComponent } from './components/product-rd/components/financial-planning/components/simon-spectrum/simon-spectrum/simon-spectrum.component';
import { FinancialPlanningOverviewComponent } from './components/product-rd/components/financial-planning/components/financial-planning-overview/financial-planning-overview/financial-planning-overview.component';
import { SimonEducationComponent } from './components/product-rd/components/education/components/simon-education/simon-education/simon-education.component';
import { ProductTrainingComponent } from './components/product-rd/components/education/components/product-training/product-training.component';
import { StartIllustrationComponent } from './modals/start-illustration/start-illustration/start-illustration.component';
import { IllustrationComponent } from './components/product-rd/components/illustration/illustration.component';
import { BusinessComponent } from './components/business/business.component';
import { PendingPolicyListComponent } from './components/business/components/pending-policy-list/pending-policy-list.component';
import { PolicyListSidebarComponent } from './components/business/components/policy-list-sidebar/policy-list-sidebar.component';
import { LifecyclePolicyListComponent } from './components/business/components/lifecycle-policy-list/lifecycle-policy-list.component';
import { EApplicationComponent } from './components/product-rd/components/e-application/e-application.component';
import { ContractQuickLinksComponent } from './components/carriers-training/components/contract-quick-links/contract-quick-links.component';
import { ContractingLicensesComponent } from './modals/contracting-licenses/contracting-licenses.component';
import { EAppWorkflowStartApplicationComponent } from './modals/eapp-workflow-start-application/eapp-workflow-start-application.component';
import { MainNavComponent } from './components/main-nav/main-nav.component';
import { SubnavComponent } from './components/main-nav/subnav/subnav.component';
import { PrepareEApplicationComponent } from './components/product-rd/components/e-application/workflow/components/prepare-e-application/prepare-e-application.component';
import { WorkflowComponent } from './components/product-rd/components/e-application/workflow/workflow.component';
import { EmbeddedApplicationComponent } from './components/product-rd/components/e-application/workflow/components/embedded-application/embedded-application.component';
import { SubmittedComponent } from './components/product-rd/components/e-application/workflow/components/submitted/submitted.component';
import { InformalPolicyListComponent } from './components/business/components/informal-policy-list/informal-policy-list.component';
import { CaseStatusNotesComponent } from './modals/case-status-notes/case-status-notes.component';
import { GetContractedV2Component } from './components/carriers-training/components/get-contracted-v2/get-contracted-v2.component';
import { LifeInsuranceNewComponent } from './components/product-rd/components/education/components/life-insurance-new/life-insurance-new.component';
import { NgArrayPipesModule } from 'ngx-pipes';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { StructuredLifecycleComponent } from './components/business/components/structured-lifecycle/structured-lifecycle.component';
import { ApplicationHistoryComponent } from './components/product-rd/components/e-application/application-history/application-history.component';
import { PreviewComponent } from './components/product-rd/components/preview/preview.component';
import { InformalPolicyDetailsComponent } from './components/business/components/informal-policy-details/informal-policy-details.component';
import { SplashComponent } from './components/product-rd/components/splash/splash.component';
import { CarriersTrainingOverviewComponent } from './components/carriers-training/components/carriers-training-overview/carriers-training-overview.component';
import { FactFinderInstructionsComponent } from './modals/fact-finder-instructions/fact-finder-instructions.component';
import { StructuredIndicationOfInterestComponent } from './components/business/components/structured-indication-of-interest/structured-indication-of-interest.component';
import { TermsOfUseModalComponent } from './modals/product-rd-tou/terms-of-use/terms-of-use-modal.component';
import { StructuredHoldingsComponent } from './components/business/components/structured-holdings/structured-holdings.component';
import { DarkModeToggleComponent } from './components/angular-sandbox/components/dark-mode-toggle/dark-mode-toggle.component';
import { IndustryEventComponent } from './components/toolbox/components/industry-event/industry-event.component';
import { ToolboxOverviewComponent } from './components/toolbox/components/toolbox-overview/toolbox-overview.component';
import { CreativeServicesWorkflowComponent } from './components/dashboard-container/components/fig-platform/components/enrollment-purchase-workflows/creative-services-workflow/creative-services-workflow.component';
import { UserLogoUploadComponent } from './modals/user-logo-upload/user-logo-upload.component';
import { InvestmentPolicyListComponent } from './components/business/components/investment-policy-list/investment-policy-list.component';
import { DashboardSpotlightComponent } from './components/dashboard-container/components/dashboard/components/dashboard-spotlight/dashboard-spotlight.component';
import { IcomplyTransactionDocumentsComponent } from './modals/icomply-transaction-documents/icomply-transaction-documents.component';
import { UserSettingsContainerComponent } from './modals/user-settings-container/user-settings-container.component';
import { UserProfileComponent } from './modals/user-settings-container/components/user-profile/user-profile.component';
import { CountdownOptInComponent } from './modals/countdown-opt-in/countdown-opt-in.component';
import { AnnuityRateWatchMygaComponent } from './components/toolbox/components/annuity-rate-watch-myga/annuity-rate-watch-myga.component';
import { SimonLifecyclePolicyListComponent } from './components/business/components/simon-lifecycle-policy-list/simon-lifecycle-policy-list/simon-lifecycle-policy-list.component';
import { AnnuityRateWatchLifetimeIncomeToolComponent } from './components/toolbox/components/annuity-rate-watch-lifetime-income-tool/annuity-rate-watch-lifetime-income-tool.component';
import { StructuredInvestmentsBuilderComponent } from './components/product-rd/components/marketplace/components/structured-investments-builder/structured-investments-builder.component';
import { EApplicationDownloadsComponent } from './modals/e-application-downloads/e-application-downloads.component';
import { StructuredPastOfferingsComponent } from './components/business/components/structured-past-offerings/structured-past-offerings.component';
import { MoneyguideSsoModalComponent } from './modals/moneyguide-sso-modal/moneyguide-sso-modal.component';
import { DashboardV2Component } from './components/dashboard-container/components/dashboard-v2/dashboard-v2.component';
import { DashboardProductionSummaryComponent } from './components/dashboard-container/components/dashboard-v2/components/dashboard-production-summary/dashboard-production-summary.component';
import { DashboardBusinessSummaryV2Component } from './components/dashboard-container/components/dashboard-v2/components/dashboard-business-summary-v2/dashboard-business-summary-v2.component';
import { DashboardRecentAplicationsComponent } from './components/dashboard-container/components/dashboard-v2/components/dashboard-recent-applications/dashboard-recent-applications.component';
import { DashboardUpcomingEventsV2Component } from './components/dashboard-container/components/dashboard-v2/components/dashboard-upcoming-events-v2/dashboard-upcoming-events-v2.component';
import { DashboardSimonLifecyclePoliciesComponent } from './components/dashboard-container/components/dashboard-v2/components/dashboard-simon-lifecycle-policies/dashboard-simon-lifecycle-policies.component';
import { DashboardWhatsNewComponent } from './components/dashboard-container/components/dashboard-v2/components/dashboard-whats-new/dashboard-whats-new.component';
import { DashboardProductTrainingComponent } from './components/dashboard-container/components/dashboard-v2/components/dashboard-product-training/dashboard-product-training.component';
import { EAppPreferenceSelectorComponent } from './components/product-rd/components/e-application/modals/e-app-preference-selector/eapp-preference-selector.component';
import { EAppOptionComponent } from './components/product-rd/components/e-application/modals/e-app-preference-selector/e-app-option/eapp-option.component';
import { AnnouncementBannerComponent } from './components/announcement-banner/announcement-banner.component';
import { PendingPolicyTableComponent } from './components/business/components/pending-policy-list/components/pending-policy-table/pending-policy-table.component';
import { InforcePolicyTableComponent } from './components/business/components/lifecycle-policy-list/componenents/inforce-policy-table/inforce-policy-table.component';
import { InformalPolicyTableComponent } from './components/business/components/informal-policy-list/components/informal-policy-table/informal-policy-table.component';
import { InvestmentPolicyTableComponent } from './components/business/components/investment-policy-list/components/investment-policy-table/investment-policy-table.component';

@NgModule({
  declarations: [
    PortalComponent,
    AngularSandboxComponent,
    CarriersTrainingComponent,
    FigPlatformComponent,
    MyBusinessComponent,
    SimonComponent,
    ToolboxComponent,
    LeaderboardComponent,
    RedeemMarketingDollarsComponent,
    SubmitPaperApplicationComponent,
    SupportComponent,
    CarriersProductTrainingComponent,
    GeneralTrainingComponent,
    GetContractedComponent,
    MyCarriersComponent,
    RequirementsComponent,
    PolicyDetailsComponent,
    PolicyListComponent,
    ResourceDetailsComponent,
    NextComponent,
    FormComponent,
    EnrollmentFormComponent,
    RenewalFormComponent,
    PurchasePageComponent,
    ConfirmationComponent,
    FormComponent,
    PurchaseModalComponent,
    DashboardComponent,
    DashboardYearlyProductionComponent,
    DashboardBusinessSummaryComponent,
    DashboardUpcomingComponent,
    DashboardExploreComponent,
    ElevateComponent,
    DigitalMarketingAssetsComponent,
    DashboardContainerComponent,
    PozWorkflowComponent,
    ForceCrmWorkflowComponent,
    SurgeWorkflowComponent,
    RetireUpWorkflowComponent,
    HegnaWorkflowComponent,
    ProductRdComponent,
    EducationComponent,
    FinancialPlanningComponent,
    MarketplaceComponent,
    FixedIndexedAnnuitiesComponent,
    LifeInsuranceComponent,
    CarePlanningComponent,
    DisabilityComponent,
    MedicareComponent,
    RilasComponent,
    VariableAnnuitiesComponent,
    StructuredInvestmentsComponent,
    MarketplaceDetailsComponent,
    MarketplaceOverviewComponent,
    QuestionnairePopupComponent,
    HegnaComponent,
    ElevateWorkflowComponent,
    ToolboxSidebarComponent,
    OrderCompleteComponent,
    SignatureCompleteComponent,
    MarketingExpenseHistoryComponent,
    SubmitApplicationComponent,
    SubmitApplicationModalComponent,
    CarePlanningContainerComponent,
    EmbeddedApplicationComponent,
    ApplicationHistoryComponent,
    CopyApplicationModalComponent,
    SimonSpectrumComponent,
    FinancialPlanningOverviewComponent,
    SimonEducationComponent,
    ProductTrainingComponent,
    StartIllustrationComponent,
    IllustrationComponent,
    BusinessComponent,
    PendingPolicyListComponent,
    PolicyListSidebarComponent,
    LifecyclePolicyListComponent,
    EApplicationComponent,
    WorkflowComponent,
    PrepareEApplicationComponent,
    SubmittedComponent,
    ContractQuickLinksComponent,
    ContractingLicensesComponent,
    EAppWorkflowStartApplicationComponent,
    MainNavComponent,
    SubnavComponent,
    InformalPolicyListComponent,
    CaseStatusNotesComponent,
    GetContractedV2Component,
    LifeInsuranceNewComponent,
    PageNotFoundComponent,
    StructuredLifecycleComponent,
    PreviewComponent,
    FactFinderInstructionsComponent,
    InformalPolicyDetailsComponent,
    SplashComponent,
    CarriersTrainingOverviewComponent,
    StructuredIndicationOfInterestComponent,
    TermsOfUseModalComponent,
    StructuredHoldingsComponent,
    DarkModeToggleComponent,
    IndustryEventComponent,
    ToolboxOverviewComponent,
    CreativeServicesWorkflowComponent,
    UserLogoUploadComponent,
    InvestmentPolicyListComponent,
    DashboardSpotlightComponent,
    IcomplyTransactionDocumentsComponent,
    UserSettingsContainerComponent,
    UserProfileComponent,
    CountdownOptInComponent,
    AnnuityRateWatchMygaComponent,
    AnnuityRateWatchLifetimeIncomeToolComponent,
    StructuredInvestmentsBuilderComponent,
    SimonLifecyclePolicyListComponent,
    EApplicationDownloadsComponent,
    StructuredPastOfferingsComponent,
    MoneyguideSsoModalComponent,
    DashboardV2Component,
    DashboardProductionSummaryComponent,
    DashboardBusinessSummaryV2Component,
    DashboardRecentAplicationsComponent,
    DashboardUpcomingEventsV2Component,
    DashboardSimonLifecyclePoliciesComponent,
    DashboardBusinessSummaryV2Component,
    DashboardWhatsNewComponent,
    DashboardProductTrainingComponent,
    EAppPreferenceSelectorComponent,
    EAppOptionComponent,
    AnnouncementBannerComponent,
    PendingPolicyTableComponent,
    InforcePolicyTableComponent,
    InformalPolicyTableComponent,
    InvestmentPolicyTableComponent,
  ],
  imports: [
    NgArrayPipesModule,
    SharedModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    PortalRoutingModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatInputModule,
    MatMenuModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    MatButtonToggleModule,
  ],
  exports: [PortalComponent],
  providers: [
  ]
})
export class PortalModule { }
