import { Component, OnInit } from '@angular/core';
import { ToolboxService } from 'src/app/core/services/toolbox.service';
import { ToolboxCategory, ToolboxCategoryEnum, ToolboxTile } from 'src/app/shared/models/toolbox.models';
import { searchFilter } from 'src/app/shared/helpers/search.helper';
import * as _ from 'lodash';
import { fadeInOut, fadeIn, slideInOut, routerTransition } from 'src/app/animations';
import { AmplitudeEventService } from 'src/app/core/services/amplitude/amplitude-event.service';

@Component({
  selector: 'app-financial-planning-overview',
  templateUrl: './financial-planning-overview.component.html',
  animations: [fadeInOut, fadeIn, slideInOut, routerTransition]
})
export class FinancialPlanningOverviewComponent implements OnInit {
  calculatorsAndRates: string = ToolboxCategoryEnum.CalculatorsAndRates;
  categories!: ToolboxCategory[];
  filteredList: ToolboxTile[] = [];
  fullFinancialPlanning: string = ToolboxCategoryEnum.FullFinancialPlanning;
  moneyguideInstitutionId: string | null = null;
  searchText: string = '';
  showFilteredList: boolean = false;
  tiles: ToolboxTile[] = this.toolboxService.getTilesBySimonGrouping('FinancialPlanning');
  tilesByCategory: Record<string, ToolboxTile[]> = {};

  constructor(
    private amplitudeEventService: AmplitudeEventService,
    private toolboxService: ToolboxService) { }

  ngOnInit(): void {
    const names = this.tiles.map(x => x.Category);
    this.categories = this.toolboxService.getAllCategories().filter(x => names.includes(x.Name));
    this.setTilesByCategory();
  }

  applyFilters(): void {
    this.showFilteredList = !!this.searchText.length ? true : false;
    if (!!this.showFilteredList) return;
    let filteredList: ToolboxTile[] = [...this.tiles];
    filteredList = filteredList.filter(tile => searchFilter(tile, this.searchText));
    this.filteredList = filteredList;
  }

  clearSearch(): void {
    this.searchText = '';
    this.showFilteredList = false;
  }

  orderBy(items: any[], sortProperty: string[]): any[] {
    return _.sortBy(items, sortProperty);
  }

  //amplitude directive not working in html
  sendToAmplitude(tileName: string): void {
    if (tileName) this.amplitudeEventService.logClickEvent('Financial Planning - ' + tileName);
  }

  setTilesByCategory(): void {
    this.categories.forEach(category => {
      const matchingTiles = this.tiles.filter(x => x.Category === category.Name);
      this.tilesByCategory[category.Name] = matchingTiles;
    });
  }
}