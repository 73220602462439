import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Table } from 'src/app/shared/classes/table';
import { RequirementsComponent } from '../../../requirements/requirements.component';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { InforceTableHeaders } from '../../constants/inforcePolicyTableHeaders.constant';
import { InforceTablePolicy } from '../../models/inforceTablePolicy.model';
import { PermissionService } from 'src/app/core/services/auth/permission.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-inforce-policy-table',
  templateUrl: './inforce-policy-table.component.html'
})
export class InforcePolicyTableComponent implements OnInit, OnChanges {
  @Input() inforcePolicies: InforceTablePolicy[] = [];
  @Input() selectedAgentIds: string[] = [];
  table: Table<InforceTablePolicy> = new Table<InforceTablePolicy>(InforceTableHeaders, 1, true);
  isError: boolean = false;
  loadingModal: boolean = false;
  reportType: string = '';
  isLoading: boolean = true;
  showAgentColumn: boolean = true;
  isLoadingSimonPolicies: boolean = false;
  hasSimonOptIn: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private sessionStore: SessionStoreService,
    private permissionService: PermissionService,
    private snackbar: MatSnackBar,) { }

  ngOnInit(): void {
    this.reportType = this.activatedRoute.snapshot.data['reportType'] as string;
    this.hasSimonOptIn = this.permissionService.hasOnePermission('ProductRDOptedIn:View');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes[ 'inforcePolicies' ]?.isFirstChange() && changes[ 'inforcePolicies' ]?.previousValue !== changes[ 'inforcePolicies' ]?.currentValue) {
      this.table.setBody(this.inforcePolicies);
      this.isLoading = false;
    }
    if (!changes[ 'selectedAgentIds' ]?.isFirstChange()) {
      this.toggleAgentColumn();
    }
  }


  openPolicyDetails(row: InforceTablePolicy): void {
    if (!!row.PolicyGuid) {
      let queryParams = {};
      const state = { PastPolicyListState: { Url: '/Portal/Business/' + this.reportType + '/Lifecycle', FilterOptions: {} } };
      const url = '/Portal/Business/' + this.reportType + '/PolicyDetails/' + row.PolicyGuid;

      if (!!row.SimonPolicyUrl) queryParams = { simonUrl: encodeURIComponent(row.SimonPolicyUrl) };

      this.router.navigate([url], { state: state, queryParams: queryParams });
    } else if (!row.PolicyGuid && !!row.SimonPolicyUrl) {
      let queryParams = { simonUrl: encodeURIComponent(row.SimonPolicyUrl) };
      const state = { PastPolicyListState: { Url: '/Portal/Business/' + this.reportType + '/Lifecycle', FilterOptions: {} } };
      const url = '/Portal/Business/' + this.reportType + '/PolicyDetails';

      queryParams = { ...queryParams, ...{ showSimonView: true } };

      this.router.navigate([url], { state: state, queryParams: queryParams });
    } else if (row.IsPaidPolicy && !row.PolicyGuid) {
      this.openSnackbar('Details unavailable for policies submitted direct to carrier.', 'is-default');
    } else {
      this.openSnackbar('Sorry, an error has occurred. Please try again later.', 'is-error');
    }
  }

  openSnackbar(message: string, toastClass: string): void {
    this.snackbar.open(message, 'Close', {
      panelClass: [toastClass]
    });
  }

  openRequirementModal(row: InforceTablePolicy): void {
    this.loadingModal = true;
    const dialogRef = this.dialog.open(RequirementsComponent, {
      data: {
        policyGuid: row.PolicyGuid,
        isVariable: row.IsVariable,
        requirementHeader: { Carrier: row.Carrier, Product: row.Product },
        lobId: row.LineOfBusinessId,
        detailsUrl: `/Portal/Business/${this.reportType}/PolicyDetails/${row.PolicyGuid}`
      },
      width: '72rem',
      height: '84rem'
    });
    dialogRef.afterClosed().subscribe(() => {
      this.loadingModal = false;
    });
  }

  toggleAgentColumn(): void {
    if (this.selectedAgentIds.length == 1 && this.selectedAgentIds.includes(this.sessionStore.User.AgentArcGuid!)) {
      this.table.Header = InforceTableHeaders.filter(h => h.DisplayName !== 'Agent');
      this.showAgentColumn = false;
    } else {
      this.table.Header = InforceTableHeaders;
      this.showAgentColumn = true;
    }
  }
}
