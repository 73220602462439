import { Component, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportsApiService } from 'src/app/core/services/http/reports-api.service';
import { getAverage } from 'src/app/shared/helpers/average.helper';
import { searchFilter } from 'src/app/shared/helpers/search.helper';
import { getSum } from 'src/app/shared/helpers/sum.helper';
import { InformalPolicy, PolicyList } from 'src/app/shared/models/policy.models';
import { sidenavConfig } from 'src/app/shared/models/sidenav-config.model';
import { Title } from '@angular/platform-browser';
import { fadeIn } from 'src/app/animations';
import { AmplitudeEventService } from 'src/app/core/services/amplitude/amplitude-event.service';
import { InformalTablePolicy } from './models/informalTablePolicy.model';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-informal-policy-list',
  templateUrl: './informal-policy-list.component.html',
  animations: [fadeIn]
})
export class InformalPolicyListComponent implements OnInit, PolicyList {
  allPolicies: InformalTablePolicy[] = [];
  filteredPolicies: InformalTablePolicy[] = [];
  isError: boolean = false;
  isLoading!: boolean;
  getSum = getSum;
  getAverage = getAverage;
  reportType!: string;
  searchText: string | null = null;
  title: string = 'Informal';

  constructor(
    private activatedRoute: ActivatedRoute,
    private amplitudeEventService: AmplitudeEventService,
    private reportsApiService: ReportsApiService,
    private router: Router,
    private snackbar: MatSnackBar,
    private titleService: Title,
  ) {
    this.titleService.setTitle(activatedRoute.snapshot.data.title + ' | Agent Portal');
  }

  ngOnInit(): void {
    this.getPolicies();
  }

  searchTextFilter(policy: InformalPolicy): boolean {
    if (!this.searchText) return true;
    return searchFilter(policy, this.searchText);
  }

  setSearchText(searchText: string | null): void {
    this.searchText = searchText;
    this.setTable();
  }


  getPolicies(): Subscription {
    this.isLoading = true;

    return this.reportsApiService.getInformalPolicies()
      .subscribe(policies => {
        this.allPolicies = policies;
        this.amplitudeEventService.logBusinessReporting(this.reportType, this.title, this.allPolicies.length);

        this.setTable();
        this.isError = false;
      },
      (error) => {
        console.log(error);
        this.isError = true;
        this.openSnackbar('Sorry, an error has occurred. Please try again later.', 'is-error');
      });
  }

  setTable(): void {
    this.isLoading = true;
    this.filteredPolicies = this.getFilteredPolicies();
    this.isLoading = false;
  }

  getFilteredPolicies(): InformalPolicy[] {
    return this.allPolicies.filter(policy => {
      return this.searchTextFilter(policy);
    });
  }

  export(): Observable<Blob> {
    if (this.filteredPolicies.length === 0) {
      this.openSnackbar('No policies. Please select different filters.', 'is-default');
    }
    return this.reportsApiService.exportInformalPolicies();
  }


  // Not available for this type of Policy List
  updateReportType(): void {
    this.reportType = this.activatedRoute.snapshot.data['reportType'] as string;
    this.getPolicies();
  }


  openSnackbar(message: string, toastClass: string): void {
    this.snackbar.open(message, 'Close', {
      panelClass: [toastClass]
    });
  }

  toggleSideNavMenu(): void {
  }
}
