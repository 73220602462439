<div class="flex flex-row items-center justify-start border-b border-solid px-30 border-gray-lighter"
    *ngIf="navItems.length > 1">
    <div class="flex flex-row items-center justify-center rounded" *ngIf="navItems.length > 1">
        <button *ngFor="let item of navItems" class="flex-auto h-48 px-20 font-bold button is-inverted is-tab"
            [class]="selectedNavItem === item ? 'active' : ''" (click)="openSimonSso(item)">
            {{item.Name}}
        </button>
    </div>
    <!-- <mat-checkbox class="p-5 ml-auto checkbox" [(ngModel)]="toggleLocal" (change)="openSimonSso(selectedNavItem)">Local Simon
    </mat-checkbox> -->
</div>
<div class="relative flex flex-grow">
    <div *ngIf="environment.qa" class="absolute top-0 right-0 px-10 m-10 rounded-full shadow-lg bg-gray-lighter">
        <mat-slide-toggle class="m-10 ml-auto" [(ngModel)]="toggleLocal" (change)="openSimonSso(selectedNavItem)">Local
            Simon</mat-slide-toggle>
    </div>
    <iframe class="w-full min-h-640 flex-grow" [appIframeData] [src]="iframeUrl | safeUrl"></iframe>
</div>