import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OptInEventService {

  // Observable source
  private isOptInSource = new Subject<boolean>();

  // ObservableStream
  public isOptIn$ = this.isOptInSource.asObservable();

  broadcastIsOptin(optIn: boolean) {
    this.isOptInSource.next(optIn);
  }
  constructor() { }
}
