
export interface CanSellResponse{
  CanSellModel: CanSellModel;
  ErrorMessage: string|null;
}

export interface CanSellModel{
  CanSell: boolean;
  Reasons: CanSellReason[];
  CarrierStateAppointmentType: CarrierStateAppointmentType;
  ProductTrainingSupported: boolean;
}

export interface CanSellReason {
  Reason: CanSellReasonType;
  Message: string;
}

export enum CanSellReasonType {
  Contracting = 'Contracting',
  Training = 'Training',
  Appointment = 'Appointment'
}

export enum CarrierStateAppointmentType {
  PreAppointment = 'PreAppointment',
  JustInTime = 'JustInTime',
  DoesNotProcess = 'DoesNotProcess'
}