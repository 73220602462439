<header *ngIf="!loading && hasCompletedOnboardingCheck"
        class="fixed top-0 left-0 z-50 flex flex-row items-center justify-start w-full h-64 px-10 bg-white border-solid shadow dark:bg-gray-darkest dark:shadow-none dark:border-b dark:border-gray-dark lg:px-20"
        @fadeIn>
  <a class="items-start justify-center hidden m-10 mr-auto uppercase sm:flex max-w-256 hover:no-underline focus:no-underline text-ferra"
     *ngIf="!loading" [routerLink]="'/Portal/Dashboard'" attr.aria-label="Home">
    <img class="hidden h-48 sm:block" src="{{figLogoUrl}}" alt="Agent Portal" *ngIf="!logo">
    <img class="block w-auto h-36 sm:hidden" src=".\assets\images\fig-logo-tri-red.svg" alt="Agent Portal"
         *ngIf="!logo">
    <img class="w-auto h-48" src="{{logo}}?d={{clockTick}}" *ngIf="logo"
         [alt]="!!userGroup ? userGroup!.Name : 'Portal'">
  </a>
  <button class="m-10 mr-auto button is-inverted sm:hidden" mat-button (click)="togglePortalSidenav()">
    <span class="flex items-center justify-center">
      <i class="mr-10 material-icons">menu</i>
      <span class="flex items-center font-bold justify-left">
        <img class="block mr-10 w-36 h-36" src=".\assets\images\fig-logo-tri-red.svg" alt="Agent Portal" *ngIf="!logo">
        <img class="w-auto h-36" src="{{logo}}?d={{clockTick}}" *ngIf="logo"
             [alt]="!!userGroup ? userGroup!.Name : 'Portal'">
      </span>
    </span>
  </button>
  <div class="items-center justify-center hidden my-10 ml-auto xs:flex">
    <ng-container *ngIf="canSeeIllustrationButton">
      <button class="ml-20 button is-primary" mat-button amplitudeEvent="Open Start Illustration - header"
              (click)="openStartIllustrationModal()">
        <span class="flex items-center justify-center">
          <span class="ml-5 mr-5">Start Illustration</span>
        </span>
      </button>
    </ng-container>
    <ng-container *ngIf="!isOptedIntoQuinci">
      <button class="ml-20 button is-primary" mat-button amplitudeEvent="Open EApp Menu - header"
              [matMenuTriggerFor]="applicationMenu" *showIfOne="'SubmitPaperApp:View|IpipelineEApps:Access|FireLight:Access'">
        <span class="flex items-center justify-center">
          <span class="ml-5">Start Application</span><i class="fig-18px fig-triangle-down-filled"></i>
        </span>
      </button>

      <mat-menu #applicationMenu="matMenu">
        <div class="flex flex-col items-start">
          <div class="flex flex-row items-center justify-between w-full" *showIfOne="'FireLight:Access'">
            <button class="flex items-center justify-start w-200 button is-lg is-inverted"
                    aria-label="Firelight e-Applications" mat-menu-item amplitudeEvent="Header Menu - Open Firelight"
                    (click)="activateFirelightSso()" attr.aria-label="Firelight e-Applications">
              <img class="w-24 h-24" src="/Images/Icons/firelight.svg" aria-label="iGo Image">
              <span class="my-auto ml-10">E-App via Firelight</span>

            </button>
            <span class="ml-10 cursor-pointer material-icons hover:text-blue-dark text-gray-light"
                  amplitudeEvent="Header Menu - EApp Help" matTooltip="View EApp Guide" matTooltipPosition="right"
                  (click)="openEAppGuide()">
              help
            </span>
          </div>
          <div class="flex flex-row items-center justify-between w-full" *showIfOne="'IpipelineEApps:Access'">
            <button class="flex items-center justify-start w-200 button is-lg is-inverted" aria-label="iGo e-Applications"
                    mat-menu-item amplitudeEvent="Header Menu - Open E-app iGo" (click)="activateIpipelineSso()"
                    attr.aria-label="iGo e-Applications">
              <img class="w-24 h-24" src="/Images/Icons/ipipeline.svg" aria-start="iGo Image">
              <span class="my-auto ml-10">E-App via iPipeline</span>
            </button>
            <span class="ml-10 cursor-pointer material-icons hover:text-blue-dark text-gray-light"
                  amplitudeEvent="Header Menu - EApp Help" matTooltip="View EApp Guide" matTooltipPosition="right"
                  (click)="openEAppGuide()">
              help
            </span>
          </div>
          <div class="flex flex-row items-center justify-start" *showIfOne="'SubmitPaperApp:View'">
            <button class="flex items-center justify-center w-200 button is-lg is-inverted" mat-menu-item
                    amplitudeEvent="Header Menu- Open Paper Application" (click)="openPaperApplicationModal();">
              <span class="flex items-center justify-center w-24 h-24 mi-18 material-icons">note_alt</span>
              <span class="my-auto ml-10">Paper Application</span>
            </button>
          </div>
        </div>
      </mat-menu>
    </ng-container>
    <ng-container *ngIf="!!isOptedIntoQuinci">
      <ng-container *showIfOne="'IpipelineEApps:Access|FireLight:Access'">
        <button class="ml-20 button is-primary" mat-button amplitudeEvent="Open Continue EApp Menu - header"
                [matMenuTriggerFor]="continueApplicationMenu">
          <span class="flex items-center justify-center">
            <span class="ml-5">Continue Application</span><i class="fig-18px fig-triangle-down-filled"></i>
          </span>
        </button>
        <mat-menu #continueApplicationMenu="matMenu">
          <ng-container *showIfOne="'FireLight:Access'">
            <a class="flex items-center justify-start button is-lg is-inverted"
               aria-label="Firelight e-Applications" mat-menu-item amplitudeEvent="Header Menu - Go to App History"
               routerLink="ProductRD/EApplication/History" attr.aria-label="Firelight e-Applications">
              <img class="w-24 h-24" src="./assets/images/icons/quinci-icon-blue.svg" aria-label="iGo Image">
              <span class="my-auto ml-10">Via Quinci&trade;</span>
            </a>
          </ng-container>
          <ng-container *showIfOne="'IpipelineEApps:Access'">
            <button class="flex items-center justify-start button is-lg is-inverted" aria-label="iGo e-Applications"
                    mat-menu-item amplitudeEvent="Header Menu - Continue E-app iGo" (click)="activateIpipelineSso()"
                    attr.aria-label="iGo e-Applications">
              <img class="w-24 h-24" src="/Images/Icons/ipipeline.svg" aria-start="iGo Image">
              <span class="my-auto ml-10">Via iPipeline</span>
            </button>
          </ng-container>
        </mat-menu>
      </ng-container>

      <button class="ml-20 button is-primary" (click)="redirectToEAppWorkflow()"
              *showIfOne="'SubmitPaperApp:View|IpipelineEApps:Access|FireLight:Access'" mat-button
              amplitudeEvent="Open EApp Workflow - header">
        <span class="flex items-center justify-center">
          <span class="ml-5 mr-5">Start Application</span>
        </span>
      </button>
    </ng-container>
    <span class="h-40 mx-20 border-r border-solid border-gray-lighter"
          *showIfOne="'SubmitPaperApp:View|IpipelineEApps:Access|Firelight:Access|IComplyLite:Access'">
    </span>
  </div>
  <button class="my-10 button is-lg is-inverted" mat-button (click)="toggleUserSidenav()">
    <span class="flex flex-row items-center justify-center">
      <span class="flex flex-col leading-tight text-right">
        <span class="text-sm font-bold">{{user && user.FirstName ? user.FirstName + ' ' + user.LastName
          :
          'User'}}</span>
        <span class="text-xs text-gray" *ngIf="onBehalfOfUser">Viewing As
          {{onBehalfOfUser.FirstName + ' ' + onBehalfOfUser.LastName }}</span>
      </span>
      <span
            class="relative flex flex-row items-center justify-center ml-10 rounded-full bg-gray-lightest w-36 h-36">
        <span class="text-sm font-bold uppercase text-blue-dark">
          <span>
            {{user && user.FirstName ? user.FirstName[0] + user.LastName[0] : 'US'}}
          </span>
          <span class="absolute top-0 right-0 -mt-4 -mr-4 rounded-full w-15 h-15 bg-red animate-pulse"
                matTooltip="You have pending enrollment renewals" *ngIf="userHasOpenRenewals"></span>
        </span>
      </span>
    </span>
  </button>
</header>
<mat-sidenav-container *ngIf="!loading && hasCompletedOnboardingCheck" class="w-full mt-64" autosize>
  <!-- Main nav -->
  <mat-sidenav class="w-full overflow-hidden xs:w-1/3 sm:shadow sm:w-300 dark:border-gray-dark" position="start"
               #portalSidenav [mode]="portalSidenavConfig.Mode" [opened]="portalSidenavConfig.IsOpened">
    <app-main-nav [isThirdLevelNavOn]="isThirdLevelNavOn" [goToMp1]="redirectToMarketplaceOne" [delegateId]="delegateId"
                  [delegates]="delegates" [onBehalfOfUser]="onBehalfOfUser" [nav]="navItems" class="flex flex-col h-full">
    </app-main-nav>
  </mat-sidenav>

  <!-- User right nav -->
  <mat-sidenav class="w-full xs:w-1/2 sm:w-320 dark:border-gray-dark" position="end" #userSidenav
               [mode]="userSidenavConfig.Mode" [opened]="userSidenavConfig.IsOpened">
    <section class="flex flex-col p-10 border-b border-solid border-gray-lighter xs:hidden">
      <ng-container>
        <button class="m-10 button is-primary" mat-button [matMenuTriggerFor]="applicationMenuMobile"
                *showIfOne="'SubmitPaperApp:View|IpipelineEApps:Access|Firelight:Access'">
          <span class="flex items-center justify-center">
            <span class="ml-5">Start Application</span>
            <span class="mi-18 material-icons">arrow_drop_down</span>
          </span>
        </button>
        <mat-menu #applicationMenuMobile="matMenu">
          <button class="flex items-center justify-start button is-lg is-inverted" aria-label="Firelight e-Applications"
                  mat-menu-item [amplitudeEvent]="'Header Eapp Menu: Firelight'" (click)="activateFirelightSso()"
                  attr.aria-label="Firelight e-Applications">
            <img class="w-24 h-24" src="/Images/Icons/firelight.svg" aria-label="iGo Image">
            <span class="my-auto ml-10">E-App via Firelight</span>
          </button>
          <button class="flex items-center justify-start button is-lg is-inverted" aria-label="iGo e-Applications"
                  mat-menu-item [amplitudeEvent]="'Header Eapp Menu: IGo'" (click)="activateIpipelineSso()"
                  attr.aria-label="iGo e-Applications">
            <img class="w-24 h-24" src="/Images/Icons/ipipeline.svg" aria-start="iGo Image">
            <span class="my-auto ml-10">E-App via iPipeline</span>
          </button>
          <button class="flex items-center justify-center button is-lg is-inverted" mat-menu-item
                  [amplitudeEvent]="'Header Eapp Menu: Paper Application'" (click)="openPaperApplicationModal();">
            <span class="mi-18 material-icons">note_alt</span>
            <span class="my-auto ml-10">Paper Application</span>
          </button>
        </mat-menu>
      </ng-container>
    </section>
    <section class="flex flex-col h-full">
      <section class="flex flex-col flex-none p-10 border-b border-solid border-gray-lighter"
               *ngIf="onBehalfOfUser || delegates.length">
        <div class="flex flex-col m-10 gap-y-10">
          <label class="text-sm text-gray">
            Viewing As:
          </label>
          <mat-select class="select" [(ngModel)]="selectedUserId" (selectionChange)="selectActiveUser(selectedUserId)">
            <mat-option [value]="1">
              <span>Myself&nbsp;({{user ? user.FirstName + ' ' + user.LastName : 'User Name'}})</span>
            </mat-option>
            <ng-container *ngIf="delegates.length">
              <mat-option *ngFor="let d of delegates" [value]="d.Id">
                <span>{{d.OnBehalfOfUser.FirstName + ' ' + d.OnBehalfOfUser.LastName}}</span>
              </mat-option>
            </ng-container>
            <ng-container *ngIf="!delegates.length && !delegateId && onBehalfOfUser">
              <mat-option [value]="onBehalfOfUser.Id">
                <span>{{onBehalfOfUser.FirstName + ' ' + onBehalfOfUser.LastName}}</span>
              </mat-option>
            </ng-container>
          </mat-select>
          <!-- <ng-container *ngIf="environment.local">
            <button class="button" mat-button (click)="selectMeAsUser()">
              <span class="flex items-center justify-between">
                <span>Myself&nbsp;({{user ? user.FirstName + ' ' + user.LastName : 'User Name'}})</span>
                <span class="ml-10 mi-18 material-icons text-gray"
                  *ngIf="!delegateId && !onBehalfOfUser">visibility</span>
              </span>
            </button>
            <ng-container *ngIf="delegates.length">
              <button class="px-10 button" mat-button *ngFor="let d of delegates" (click)="selectOnBehalfOfUser(d.Id)">
                <span class="flex items-center justify-between">
                  <span>{{d.OnBehalfOfUser.FirstName + ' ' + d.OnBehalfOfUser.LastName}}</span>
                  <span class="ml-10 mi-18 material-icons text-gray" *ngIf="delegateId===d.Id">visibility</span>
                </span>
              </button>
            </ng-container>
            <ng-container *ngIf="!delegates.length && !delegateId && onBehalfOfUser">
              <button class="px-10 button" mat-button>
                <span class="flex items-center justify-between">
                  <span>{{onBehalfOfUser.FirstName + ' ' + onBehalfOfUser.LastName}}</span>
                  <span class="ml-10 mi-18 material-icons text-gray" *ngIf="onBehalfOfUser">visibility</span>
                </span>
              </button>
            </ng-container>
          </ng-container> -->
        </div>
      </section>
      <section class="flex flex-col flex-none p-10 border-b border-solid border-gray-lighter"
               *showIfOne="'AccessMarketingPoints:View'">
        <div class="flex flex-col m-10 gap-y-10">
          <div class="flex items-end justify-between mb-10">
            <label class="block text-sm text-gray">
              Marketing Dollars:
            </label>
            <h2 class="text-lg font-bold"
                [ngClass]="{'text-orange' : userMarketingDollars.Entry.MarketingDollarsTotal < 0}"
                *ngIf="userMarketingDollars">{{userMarketingDollars.Entry.MarketingDollarsTotal | currency}}
            </h2>
          </div>
          <button class="button is-primary is-inverted" mat-button [amplitudeEvent]="'Menu: Submit Marketing Expenses'"
                  *showIfOne="'PointAdjustments:View'" (click)="openRedeemMarketingDollars()">
            <span class="flex items-center justify-start">
              <span class="mr-10 mi-18 material-icons">monetization_on</span>
              <span>Submit Marketing Expenses</span>
            </span>
          </button>
          <button class="button is-primary is-inverted" mat-button [amplitudeEvent]="'Menu: View Expense History'"
                  (click)="openMarketingExpenseHistoryModal()">
            <span class="flex items-center justify-start">
              <span class="mr-10 mi-18 material-icons">history</span>
              <span>View Expense History</span>
            </span>
          </button>
        </div>
      </section>
      <section class="flex flex-col flex-none p-10 border-b border-solid border-gray-lighter"
               *ngIf="!onBehalfOfUser || hasCPCPermission">
        <div class="flex flex-col m-10 gap-y-20" *ngIf="userCPCs">
          <div class="flex items-end justify-between">
            <label class="block text-sm text-gray">
              Total CPCs:
            </label>
            <h2 class="text-lg font-bold" [ngClass]="{'text-orange' : userCPCs.CPCs < 0}"> {{userCPCs.CPCs |
              number}}
            </h2>
          </div>
          <div class="flex flex-row" layout>
            <input class="flex-auto mr-10 input" [matDatepicker]="startDate" [(ngModel)]="cpcStartDate"
                   (click)="startDate.open()" (ngModelChange)="getUserCPCs()">
            <mat-datepicker #startDate=""></mat-datepicker>
            <input class="flex-auto input" [matDatepicker]="endDate" [(ngModel)]="cpcEndDate" [max]="getCPCMaxEndDate()"
                   (click)="endDate.open()" (ngModelChange)="getUserCPCs()">
            <mat-datepicker #endDate=""></mat-datepicker>
          </div>
        </div>
      </section>
      <section class="flex flex-col flex-none p-20 border-b border-solid gap-y-10 border-gray-lighter">
        <button class="button is-primary is-inverted" mat-button [amplitudeEvent]="'Menu: View Leaderboard'"
                (click)="openLeaderboardModal()">
          <span class="flex items-center justify-start">
            <span class="mr-10 mi-18 material-icons">emoji_events</span>
            <span>View leaderboard</span>
          </span>
        </button>
        <button class="button is-primary is-inverted" mat-button [amplitudeEvent]="'Menu: Contact Support'"
                (click)="openSupportModal()">
          <span class="flex items-center justify-start">
            <span class="mr-10 mi-18 material-icons">email</span>
            <span>Contact Support</span>
          </span>
        </button>
        <button class="button is-primary is-inverted" mat-button>
          <a [attr.href]="termsOfUseUrl" target="_blank" class="flex items-center justify-start w-full h-full">
            <span class="mr-10 mi-18 material-icons">history_edu</span>
            <span>Terms Of Use</span>
          </a>
        </button>
        <button class="button is-primary is-inverted" mat-button [amplitudeEvent]="'Menu: Change Site Logo'"
                (click)="openUploadLogoModal()" *ngIf="!onBehalfOfUser && !user.UserGroupId">
          <span class="flex items-center justify-start">
            <span class="mr-10 mi-18 material-icons">manage_accounts</span>
            <span>Change Site Logo</span>
          </span>
        </button>
        <button *ngIf="renewals" class="relative button is-primary is-inverted" mat-button
                [amplitudeEvent]="'Menu: Manage Renewals'" [amplitudeEvent]="'Menu: Manage Enrollments'"
                (click)="openEnrollmentModal(renewals, false)">
          <span
                class="absolute left-0 w-10 h-10 -ml-4 transform -translate-y-1/2 rounded-full top-1/2 bg-red animate-pulse"
                matTooltip="You have pending enrollment renewals" *ngIf="userHasOpenRenewals"></span>
          <span class="flex items-center justify-start">
            <span class="mr-10 mi-18 material-icons">date_range</span>
            <span>Manage Enrollments</span>
          </span>
        </button>
        <button class="button is-warning is-inverted" mat-button (click)="logout()">
          <span class="flex items-center justify-start">
            <span class="mr-10 mi-18 material-icons">power_settings_new</span>
            <span>Log Out</span>
          </span>
        </button>
      </section>
    </section>
  </mat-sidenav>

  <!-- Portal body -->
  <mat-sidenav-content class="relative flex flex-col h-full overflow-auto portal-body dark:bg-black">
    <app-announcement-banner *ngIf="showBanner">
      <span class="font-bold">Be advised:</span> FIG Portal will undergo planned maintenance on Saturday, April 2nd between 8:30 and 10:30 am ET. Access and/or services may be temporarily unavailable during this time. We apologize for any inconvenience.
    </app-announcement-banner>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
<!-- 
  When Switching Between Users:
    1. load the logout iframe
    2. wait a few seconds
    3. remove logout iframe
    4. remove sso iframe
    5. load sso iframe
-->
<!-- Simon invis. SSO iframe -->
<iframe *ngIf="hasCompletedOnboardingCheck" class="absolute hidden w-0 h-0" src="/api/SimonSso/Proxy"></iframe>
<!-- <iframe id="simonIframe" [src]="environment.simonIframeOrigin | safeUrl" class="hidden"></iframe> -->
<!-- <iframe *ngIf="logoutFromSimon" src="/api/SimonSso/Logout" style="display:none"></iframe> -->