<table *ngIf="!isLoading" [@fadeIn] class="text-sm table-auto -my-60 custom-table">
  <thead>
      <tr>
          <td class="sticky top-0 z-10 w-48 p-10 text-xs font-semibold uppercase bg-white text-gray">
          </td>
          <td *ngFor="let cell of table.Header"
              class="sticky top-0 z-10 p-10 text-xs font-semibold uppercase bg-white hover:bg-blue-lightest text-gray">
              <span (click)="table.orderBy(cell)"
                  class="flex items-center leading-6 cursor-pointer min-h-24 focus:outline-none"
                  attr.aria-label="Sort by {{cell.DisplayName}}">
                  <span class="mr-5">{{cell.DisplayName}}</span>
                  <span *ngIf="cell === table.OrderByColumn" class="material-icons text-gray">
                      {{table.AscendingOrder ? 'arrow_drop_up' : 'arrow_drop_down'}}
                  </span>
              </span>
          </td>
      </tr>
  </thead>
  <tbody>
      <tr id="policyRow" class="bg-opacity-25 cursor-pointer" aria-label="Go to policy details"
          *ngFor="let row of table.Body">
      <td
          class="p-10">
      </td>
      <td class="p-10">
          {{row.Clients}}
      </td>
      <td class="p-10">
          {{row.AccountType}}
      </td>
      <td class="p-10">
          <div class="flex flex-col w-full">
              <span class="font-bold">{{row.Custodian}}</span>
              <span>{{row.AccountNumber}}</span>
          </div>
      </td>
      <td class="p-10">
          {{row.Model ? row.Model : '---'}}
      </td>
      <td class="p-10">
          {{row.CurrentValue ? (row.CurrentValue | currency) : '---'}}
      </td>
      <td class="p-10">
          {{row.AccountStatus ? row.AccountStatus : '---'}}
      </td>
      <td class="p-10">
          {{row.DateOpened ? (row.DateOpened | date: 'MM/dd/yyyy' : 'UTC') : '---'}}
      </td>
      <td class="p-10">
          {{row.ClientFee ? (row.ClientFee | percent:'1.0-2') : '---'}}
      </td>
      </tr>
      <tr id="policyErrorRow" class="" aria-label="Error getting policies" *ngIf="isError">
          <td class="p-10"></td>
          <td class="p-10" colspan="6"><span class="text-red-dark">Sorry, an error has occurred. Please try again later.</span></td>
      </tr>
      <tr id="policyEmptyRow" class="" aria-label="No policies found" *ngIf="!isError && table.Body.length===0">
          <td class="p-10"></td>
          <td class="p-10" colspan="6">No policies found.</td>
      </tr>
  </tbody>
</table>
