import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnboardingComponent } from './onboarding.component';
import { AgreementComponent } from './components/agreement/agreement.component';
import { CarrierSetupComponent } from './components/carrier-setup/carrier-setup.component';
import { PortalPreviewComponent } from './components/portal-preview/portal-preview.component';
import { TrainingComponent } from './components/training/training.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { CoreModule } from '../core/core.module';
import { OnboardingRoutingModule } from './onboarding-routing.module';
import { MatMenuModule } from '@angular/material/menu';
import { SharedModule } from '../shared/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PlatformConfigurationComponent } from './components/platform-configuration/platform-configuration.component';


@NgModule({
  declarations: [
    OnboardingComponent,
    AgreementComponent,
    CarrierSetupComponent,
    PortalPreviewComponent,
    TrainingComponent,
    WelcomeComponent,
    TermsOfUseComponent,
    PlatformConfigurationComponent,
  ],
  imports: [
    MatButtonModule,
    MatMenuModule,
    SharedModule,
    CoreModule,
    OnboardingRoutingModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [OnboardingComponent]
})
export class OnboardingModule { }
