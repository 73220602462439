import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Policy } from 'src/app/shared/models/policy.models';
import { PortalComponent } from './portal.component';
import { MyBusinessComponent } from './components/my-business/my-business.component';
import { CarriersTrainingComponent } from './components/carriers-training/carriers-training.component';
import { FigPlatformComponent } from './components/dashboard-container/components/fig-platform/fig-platform.component';
import { SimonComponent } from './components/simon/simon.component';
import { ToolboxComponent } from './components/toolbox/toolbox.component';
import { AngularSandboxComponent } from './components/angular-sandbox/angular-sandbox.component';
import { PolicyListComponent } from './components/my-business/policy-list/policy-list.component';
import { PolicyDetailsComponent } from './components/business/components/policy-details/policy-details.component';
import { ResourceDetailsComponent } from './components/dashboard-container/components/fig-platform/components/resource-details/resource-details.component';
import { DashboardComponent } from './components/dashboard-container/components/dashboard/dashboard.component';
import { DashboardContainerComponent } from './components/dashboard-container/dashboard-container.component';
import { ProductRdComponent } from './components/product-rd/product-rd.component';
import { FinancialPlanningComponent } from './components/product-rd/components/financial-planning/financial-planning.component';
import { MarketplaceComponent } from './components/product-rd/components/marketplace/marketplace.component';
import { FixedIndexedAnnuitiesComponent } from './components/product-rd/components/education/components/fixed-indexed-annuities/fixed-indexed-annuities.component';
import { LifeInsuranceComponent } from './components/product-rd/components/education/components/life-insurance/life-insurance.component';
import { CarePlanningComponent } from './components/product-rd/components/education/components/care-planning/care-planning.component';
import { DisabilityComponent } from './components/product-rd/components/education/components/disability/disability.component';
import { MedicareComponent } from './components/product-rd/components/education/components/medicare/medicare.component';
import { RilasComponent } from './components/product-rd/components/education/components/rilas/rilas.component';
import { VariableAnnuitiesComponent } from './components/product-rd/components/education/components/variable-annuities/variable-annuities.component';
import { StructuredInvestmentsComponent } from './components/product-rd/components/education/components/structured-investments/structured-investments.component';
import { MarketplaceDetailsComponent } from './components/product-rd/components/marketplace/components/marketplace-details/marketplace-details.component';
import { MarketplaceOverviewComponent } from './components/product-rd/components/marketplace/components/marketplace-overview/marketplace-overview.component';
import { OrderCompleteComponent } from './components/externals/order-complete/order-complete.component';
import { SignatureCompleteComponent } from './components/externals/signature-complete/signature-complete.component';
import { CarePlanningContainerComponent } from './components/product-rd/components/education/components/care-planning-container/care-planning-container.component';
import { UserMasterAdvisorAgreementResolver } from '../core/services/resolvers/user-master-advisor-agreement-resolver';
import { ContractingCompletedResolver } from '../core/services/resolvers/contracting-completed-resolver';
import { SimonSpectrumComponent } from './components/product-rd/components/financial-planning/components/simon-spectrum/simon-spectrum/simon-spectrum.component';
import { FinancialPlanningOverviewComponent } from './components/product-rd/components/financial-planning/components/financial-planning-overview/financial-planning-overview/financial-planning-overview.component';
import { ProductTrainingComponent } from './components/product-rd/components/education/components/product-training/product-training.component';
import { IllustrationComponent } from './components/product-rd/components/illustration/illustration.component';
import { BusinessComponent } from './components/business/business.component';
import { PendingPolicyListComponent } from './components/business/components/pending-policy-list/pending-policy-list.component';
import { LifecyclePolicyListComponent } from './components/business/components/lifecycle-policy-list/lifecycle-policy-list.component';
import { SubmittedComponent } from './components/product-rd/components/e-application/workflow/components/submitted/submitted.component';
import { WorkflowComponent } from './components/product-rd/components/e-application/workflow/workflow.component';
import { PrepareEApplicationComponent } from './components/product-rd/components/e-application/workflow/components/prepare-e-application/prepare-e-application.component';
import { ApplicationHistoryComponent } from './components/product-rd/components/e-application/application-history/application-history.component';
import { EmbeddedApplicationComponent } from './components/product-rd/components/e-application/workflow/components/embedded-application/embedded-application.component';
import { InformalPolicyListComponent } from './components/business/components/informal-policy-list/informal-policy-list.component';
import { UserMasterAdvisorAgreementRequiredResolver } from '../core/services/resolvers/user-master-advisor-agreement-required-resolver';
import { LifeInsuranceNewComponent } from './components/product-rd/components/education/components/life-insurance-new/life-insurance-new.component';
import { DevelopmentGuardService } from '../core/services/guards/development-guard/development-guard.service';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AgentLicenseOptOutResolver } from '../core/services/resolvers/crd-npn-opt-out-resolver';
import { StructuredLifecycleComponent } from './components/business/components/structured-lifecycle/structured-lifecycle.component';
import { PreviewComponent } from './components/product-rd/components/preview/preview.component';
import { InformalPolicyDetailsComponent } from './components/business/components/informal-policy-details/informal-policy-details.component';
import { SplashComponent } from './components/product-rd/components/splash/splash.component';
import { CarriersProductTrainingComponent } from './components/carriers-training/components/carriers-product-training/carriers-product-training.component';
import { CarriersTrainingOverviewComponent } from './components/carriers-training/components/carriers-training-overview/carriers-training-overview.component';
import { StructuredIndicationOfInterestComponent } from './components/business/components/structured-indication-of-interest/structured-indication-of-interest.component';
import { OptInGuardService } from '../core/services/guards/opt-in-guard/opt-in-guard.service';
import { CanSeeProductRDOptInGuardService } from '../core/services/guards/can-see-product-rd-optin-guard/can-see-product-rd-optin-guard.service';
import { StructuredHoldingsComponent } from './components/business/components/structured-holdings/structured-holdings.component';
import { AuthenticationGuard } from '../core/services/guards/authentication-guard/authentication-guard.service';
import { BusinessGuardService } from '../core/services/guards/business-guard/business-guard.service';
// import { IBlockComponent } from './components/product-rd/components/financial-planning/components/i-block/i-block.component';
import { IndustryEventComponent } from './components/toolbox/components/industry-event/industry-event.component';
import { ToolboxOverviewComponent } from './components/toolbox/components/toolbox-overview/toolbox-overview.component';
import { InvestmentPolicyListComponent } from './components/business/components/investment-policy-list/investment-policy-list.component';
// import { UserSettingsContainerComponent } from './modals/user-settings-container/user-settings-container.component';
// import { UserProfileComponent } from './modals/user-settings-container/components/user-profile/user-profile.component';
import { AnnuityRateWatchLifetimeIncomeToolComponent } from './components/toolbox/components/annuity-rate-watch-lifetime-income-tool/annuity-rate-watch-lifetime-income-tool.component';
import { AnnuityRateWatchMygaComponent } from './components/toolbox/components/annuity-rate-watch-myga/annuity-rate-watch-myga.component';
import { SimonLifecyclePolicyListComponent } from './components/business/components/simon-lifecycle-policy-list/simon-lifecycle-policy-list/simon-lifecycle-policy-list.component';
import { StructuredInvestmentsBuilderComponent } from './components/product-rd/components/marketplace/components/structured-investments-builder/structured-investments-builder.component';
import { TermsOfUseCompletedResolver } from '../core/services/resolvers/terms-of-use-resolver';
import { StructuredPastOfferingsComponent } from './components/business/components/structured-past-offerings/structured-past-offerings.component';
import { DashboardV2Component } from './components/dashboard-container/components/dashboard-v2/dashboard-v2.component';

export const routes: Routes = [
  {
    path: 'Portal',
    component: PortalComponent,
    canActivate: [AuthenticationGuard],
    canActivateChild: [AuthenticationGuard],
    resolve: {
      userMasterAdvisorAgreement: UserMasterAdvisorAgreementResolver,
      userContracting: ContractingCompletedResolver,
      userMasterAdvisorAgreementRequired: UserMasterAdvisorAgreementRequiredResolver,
      userAgentLicensesOptOut: AgentLicenseOptOutResolver,
      userTermsOfUse: TermsOfUseCompletedResolver,
    },
    data: {
      title: 'Portal',
      state: 'Portal'
    },
    children: [
      {
        path: '',
        component: DashboardContainerComponent,
        children: [
          {
            path: 'Dashboard',
            data: {
              title: 'Dashboard',
              state: 'Portal.Dashboard'
            },
            component: DashboardComponent,
          },
          {
            path: 'DashboardV2',
            data: {
              title: 'Dashboard',
              state: 'Portal.DashboardV2'
            },
            component: DashboardV2Component,
            canActivate: [DevelopmentGuardService]
          },
          {
            path: 'FigPlatform',
            data: {
              title: 'Explore FIG',
              state: 'Portal.FigPlatform'
            },
            children: [
              {
                path: '',
                data: {
                  title: 'Explore FIG',
                  state: 'Portal.FigPlatform'
                },
                component: FigPlatformComponent
              },
              {
                path: 'Details/:resource',
                data: {
                  title: 'Resource Details',
                  state: 'Portal.FigPlatform.Details',
                  isDynamicState: true
                },
                component: ResourceDetailsComponent
              }
            ]
          },
          { path: '', redirectTo: 'Dashboard', pathMatch: 'full' },
        ]
      },
      {
        path: 'MyBusiness',
        data: {
          title: 'My Business',
          state: 'Portal.MyBusiness'
        },
        component: MyBusinessComponent,
        canActivate: [BusinessGuardService],
        children: [
          {
            path: 'PolicyList',
            component: PolicyListComponent,
            data: {
              title: 'Policy List',
              state: 'Portal.MyBusiness.PolicyList'
            }
          },
          {
            path: 'InformalPolicyDetails/:policyGuid',
            component: InformalPolicyDetailsComponent,
            data: {
              title: 'Informal Policy Details',
              state: 'Portal.MyBusiness.InformalPolicyDetails'
            }
          },
          {
            path: 'PolicyDetails/:policyGuid',
            component: PolicyDetailsComponent,
            data: {
              title: 'Policy Details',
              state: 'Portal.MyBusiness.PolicyDetails'
            }
          },
          {
            path: 'PolicyDetails',
            redirectTo: 'PolicyDetails/',
            pathMatch: 'full'
          },
          { path: '', redirectTo: 'PolicyList', pathMatch: 'full' },
        ]
      },
      {
        path: 'Business',
        component: BusinessComponent,
        canActivate: [OptInGuardService],
        data: {
          title: 'Business',
          state: 'Portal.Business',
          thirdLevelNav: true,
        },
        children: [
          { path: '', redirectTo: 'Fixed/Pending', pathMatch: 'full' },
          {
            path: 'Fixed/Pending',
            component: PendingPolicyListComponent,
            data: {
              title: 'Fixed Pending Policy List | Business',
              state: 'Portal.Business.PendingPolicyList',
              thirdLevelNav: true,
              reportType: 'Fixed'
            }
          },
          {
            path: 'Variable/Pending',
            component: PendingPolicyListComponent,
            data: {
              title: 'Variable Pending Policy List | Business',
              state: 'Portal.Business.PendingPolicyList',
              thirdLevelNav: true,
              reportType: 'Variable'
            },
          },
          {
            path: 'Fixed/Inforce',
            component: LifecyclePolicyListComponent,
            data: {
              title: 'Fixed Inforce Policy List | Business',
              state: 'Portal.Business.LifecyclePolicyList',
              thirdLevelNav: true,
              reportType: 'Fixed',
            },
          },
          {
            path: 'Fixed/Lifecycle',
            component: SimonLifecyclePolicyListComponent,
            data: {
              title: 'Fixed Lifecycle Policy List | Business',
              state: 'Portal.Business.SimonLifecyclePolicyList',
              thirdLevelNav: true,
              reportType: 'Simon'
            },
          },
          {
            path: 'Variable/Lifecycle',
            component: LifecyclePolicyListComponent,
            data: {
              title: 'Variable Lifecycle Policy List | Business',
              state: 'Portal.Business.LifecyclePolicyList',
              thirdLevelNav: true,
              reportType: 'Variable'
            },
          },
          {
            path: 'Fixed/Informal',
            component: InformalPolicyListComponent,
            data: {
              title: 'Fixed Informal Policy List | Business',
              state: 'Portal.Business.InformalPolicyList',
              thirdLevelNav: true,
              reportType: 'Fixed'
            }
          },
          {
            path: 'Structured/Structured-Indication-Of-Interest',
            component: StructuredIndicationOfInterestComponent,
            data: {
              title: 'Structured Indication of Interest | Business',
              state: 'Portal.Business.StructuredIndicationOfInterest',
              thirdLevelNav: true,
              reportType: 'Structured'
            },
          },
          {
            path: 'Structured/Structured-Lifecycle',
            component: StructuredLifecycleComponent,
            data: {
              title: 'Structured Lifecycle | Business',
              state: 'Portal.Business.StructuredLifecycle',
              thirdLevelNav: true,
              reportType: 'Structured'
            },
          },
          {
            path: 'Structured/Past-Offerings',
            component: StructuredPastOfferingsComponent,
            data: {
              title: 'Structured Past Offerings | Business',
              state: 'Portal.Business.StructuredPastOfferings',
              thirdLevelNav: true,
              reportType: 'Structured'
            },
          },
          {
            path: 'Structured/Structured-Holdings',
            component: StructuredHoldingsComponent,
            data: {
              title: 'Structured Holdings | Business',
              state: 'Portal.Business.StructuredHoldings',
              thirdLevelNav: true,
              reportType: 'Structured'
            },
          },
          {
            path: 'Wealth',
            component: InvestmentPolicyListComponent,
            data: {
              title: 'Wealth | Business',
              state: 'Portal.Business.Wealth',
              thirdLevelNav: true,
              reportType: 'Wealth'
            }
          },
          {
            path: ':reportType/PolicyDetails/:policyGuid',
            component: PolicyDetailsComponent,
            data: {
              title: 'Policy Details',
              state: 'Portal.Business.PendingPolicyList.PolicyDetails',
              thirdLevelNav: true,
            },
          },
          {
            path: ':reportType/PolicyDetails',
            component: PolicyDetailsComponent,
            data: {
              title: 'Policy Details',
              state: 'Portal.Business.PendingLifeCycleList.PolicyDetails',
              thirdLevelNav: true,
            },
          },
          {
            path: ':reportType/InformalPolicyDetails/:policyGuid',
            component: InformalPolicyDetailsComponent,
            data: {
              title: 'Informal Policy Details',
              state: 'Portal.MyBusiness.InformalPolicyDetails'
            }
          },
          { path: 'Fixed', redirectTo: 'Fixed/Pending', pathMatch: 'full' },
          { path: 'Variable', redirectTo: 'Variable/Pending', pathMatch: 'full' },
          { path: 'Structured', redirectTo: 'Structured/Structured-Lifecycle', pathMatch: 'full' },
        ]
      },
      {
        path: 'ProductRDPreview',
        component: SplashComponent,
        canActivate: [CanSeeProductRDOptInGuardService],
        data: {
          title: 'Quinci powered by SIMON Preview',
          state: 'Portal.ProductRD.Splash',
          thirdLevelNav: false,
        },
      },
      {
        path: 'ProductRD',
        component: ProductRdComponent,
        data: {
          title: 'Quinci Powered by SIMON',
          state: 'Portal.ProductRD',
          thirdLevelNav: true
        },
        canActivate: [OptInGuardService],
        children: [
          { path: '', redirectTo: 'Preview', pathMatch: 'full' },
          {
            path: 'Preview',
            component: PreviewComponent,
            data: {
              title: 'Quinci Powered by SIMON Preview',
              state: 'Portal.ProductRD.Preview',
              thirdLevelNav: true,
            },
          },
          {
            path: 'EApplication',
            data: {
              title: 'E-Application',
              state: 'Portal.ProductRD.EApplication',
              thirdLevelNav: true
            },
            children: [
              {
                path: 'Workflow',
                component: WorkflowComponent,
                data: {
                  title: 'Workflow',
                  state: 'Portal.ProductRD.EApplication.Workflow',
                  thirdLevelNav: true
                },
                children: [
                  {
                    path: 'PrepareEApplication',
                    component: PrepareEApplicationComponent,
                    data: {
                      title: 'Prepare E-Application',
                      state: 'Portal.ProductRD.EApplication.Workflow.PrepareEApplication'
                    }
                  },
                  {
                    path: 'WorkflowProductTraining',
                    component: ProductTrainingComponent,
                    data: {
                      title: 'Product Training',
                      state: 'Portal.ProductRD.EApplication.Workflow.WorkflowProductTraining'
                    }
                  },
                  {
                    path: 'Submitted',
                    component: SubmittedComponent,
                    data: {
                      title: 'E-Application Submitted',
                      state: 'Portal.ProductRD.EApplication.Workflow.Submitted'
                    }
                  },
                  {
                    path: 'ViewEApplication',
                    component: EmbeddedApplicationComponent,
                    data: {
                      title: 'View E-Application',
                      state: 'Portal.ProductRD.EApplication.Workflow.ViewEApplication'
                    }
                  },
                  { path: '', redirectTo: 'PrepareEApplication', pathMatch: 'full' },
                ]
              },
              {
                path: 'History',
                component: ApplicationHistoryComponent,
                data: {
                  title: 'Application History',
                  state: 'Portal.ProductRD.Execute.History',
                  thirdLevelNav: true
                }
              },
              { path: '', redirectTo: 'History', pathMatch: 'full' },
            ]
          },
          {
            path: 'Education',
            data: {
              title: 'Education | Quinci',
              state: 'Portal.ProductRD.Education.FixedIndexedAnnuities',
              thirdLevelNav: true
            },
            children: [
              { path: '', redirectTo: 'FixedIndexedAnnuities', pathMatch: 'full' },
              {
                path:
                  'FixedIndexedAnnuities',
                component: FixedIndexedAnnuitiesComponent,
                data: {
                  title: 'Fixed Indexed Annuities | Education',
                  state: 'Portal.ProductRD.Education.FixedIndexedAnnuities',
                  thirdLevelNav: true
                }
              },
              {
                path: 'CarePlanning', //LTC
                component: CarePlanningContainerComponent,
                data: {
                  title: 'CarePlanning | Education',
                  state: 'Portal.ProductRD.Education.CarePlanning',
                  thirdLevelNav: true
                }
              },
              {
                path: 'Disability',
                component: DisabilityComponent,
                data: {
                  title: 'Disability | Education',
                  state: 'Portal.ProductRD.Education.Disability',
                  thirdLevelNav: true
                }
              },
              {
                path: 'LifeInsurance',
                component: LifeInsuranceComponent,
                data: {
                  title: 'Life-Insurance | Education',
                  state: 'Portal.ProductRD.Education.LifeInsurance',
                  thirdLevelNav: true
                },
              },
              {
                path: 'LifeInsuranceNew',
                component: LifeInsuranceNewComponent,
                data: {
                  title: 'Life-Insurance | Education',
                  state: 'Portal.ProductRD.Education.LifeInsuranceNew',
                  thirdLevelNav: true
                },
              },
              {
                path: 'Medicare',
                component: MedicareComponent,
                data: {
                  title: 'Medicare | Education',
                  state: 'Portal.ProductRD.Education.Medicare',
                  thirdLevelNav: true
                }
              },
              {
                path: 'ProductTraining',
                component: ProductTrainingComponent,
                data: {
                  title: 'Product Training',
                  state: 'Portal.ProductRD.Education.ProductTraining',
                  thirdLevelNav: true
                },
              },
              {
                path: 'RILAs',
                component: RilasComponent,
                data: {
                  title: 'RILAs | Education',
                  state: 'Portal.ProductRD.Education.RILAS',
                  thirdLevelNav: true
                }
              },
              {
                path: 'StructuredInvestments',
                component: StructuredInvestmentsComponent,
                data: {
                  title: 'Structured Investments | Education',
                  state: 'Portal.ProductRD.Education.StructuredInvestments',
                  thirdLevelNav: true
                }
              },
              {
                path: 'VariableAnnuities',
                component: VariableAnnuitiesComponent,
                data: {
                  title: 'Variable Annuities | Education',
                  state: 'Portal.ProductRD.Education.VariableAnnuities',
                  thirdLevelNav: true
                }
              },
            ]
          },
          {
            path: 'FinancialPlanning',
            component: FinancialPlanningComponent,
            data: {
              title: 'Financial Planning | Quinci',
              state: 'Portal.ProductRD.FinancialPlanning',
              thirdLevelNav: true
            },
            children: [
              { path: '', redirectTo: 'Overview', pathMatch: 'full' },
              {
                path: 'Overview',
                component: FinancialPlanningOverviewComponent,
                data: {
                  title: 'Financial Planning Overview',
                  state: 'Portal.ProductRD.FinancialPlanning.Overview',
                  thirdLevelNav: true
                },
              },
              {
                path: 'SimonSpectrum',
                component: SimonSpectrumComponent,
                data: {
                  title: 'Simon Spectrum | Financial Planning',
                  state: 'Portal.ProductRD.FinancialPlanning.SimonSpectrum',
                  thirdLevelNav: true
                },
              },
              // {
              //   path: 'IBlock',
              //   component: IBlockComponent,
              //   data: {
              //     title: "MoneyGuide iBlock | Financial Planning",
              //     state: "Portal.ProductRD.FinancialPlanning.IBlock",
              //     thirdLevelNav: true
              //   },
              // }
              { path: 'IBlock', redirectTo: 'Overview', pathMatch: 'full' },
            ],
          },
          {
            path: 'Marketplace',
            component: MarketplaceComponent,
            data: {
              title: 'Marketplace | Quinci',
              state: 'Portal.ProductRD.Marketplace',
              thirdLevelNav: true,
            },
            children: [
              { path: '', redirectTo: 'Overview', pathMatch: 'full' },
              {
                path: 'Details/:id',
                component: MarketplaceDetailsComponent,
                data: {
                  title: 'Marketplace Details',
                  state: 'Portal.ProductRD.Marketplace.Details',
                  thirdLevelNav: true,
                }
              },
              {
                path: 'Overview',
                component: MarketplaceOverviewComponent,
                data: {
                  title: 'Marketplace Overview',
                  state: 'Portal.ProductRD.Marketplace.Overview',
                  thirdLevelNav: true,
                },
              },
              {
                path: 'Illustration',
                component: IllustrationComponent,
                data: {
                  title: 'Illustration | Marketplace',
                  state: 'Portal.ProductRD.Marketplace.Illustration',
                },
              },
              {
                path: 'StructuredInvestmentsBuilder',
                component: StructuredInvestmentsBuilderComponent,
                data: {
                  title: 'Structured Investments Builder | Marketplace',
                  state: 'Portal.ProductRD.Marketplace.StructuredInvestmentsBuilder',
                  thirdLevelNav: true,
                },
              },
            ]
          },
        ]
      },
      {
        path: 'CarriersTraining',
        component: CarriersTrainingComponent,
        data: {
          title: 'Contracting & Training',
          state: 'Portal.CarriersTraining'
        },
        children: [
          {
            path: '',
            redirectTo: 'Overview',
            pathMatch: 'full',
          },
          {
            path: 'Overview',
            component: CarriersTrainingOverviewComponent,
            data: {
              title: 'Contracting & Training',
              state: 'Portal.CarriersTraining'
            },
          },
          {
            path: 'ProductTraining',
            component: CarriersProductTrainingComponent,
            data: {
              title: 'Completed Product Training',
              state: 'CarriersTraining.CompletedProductTraining'
            }
          },
        ]
      },
      {
        path: 'Simon',
        component: SimonComponent,
        data: {
          title: 'Simon',
          state: 'Portal.Simon'
        }
      },
      {
        component: ToolboxComponent,
        path: 'Toolbox',
        data: {
          title: 'Toolbox',
          state: 'Portal.Toolbox'
        },
        children: [
          {
            path: 'IndustryEvent/:eventUrl',
            component: IndustryEventComponent,
            pathMatch: 'full',
          },
          {
            path: 'ARWLifetimeIncomeTool',
            component: AnnuityRateWatchLifetimeIncomeToolComponent,
            pathMatch: 'full',
          },
          {
            path: 'ARWTopMYGA',
            component: AnnuityRateWatchMygaComponent,
            pathMatch: 'full',
          },
          {
            path: '',
            data: {
              title: 'Explore FIG',
              state: 'Portal.FigPlatform'
            },
            component: ToolboxOverviewComponent
          },
          {
            path: ':category',
            component: ToolboxOverviewComponent,
            data: {
              title: 'Toolbox',
              state: 'Portal.Toolbox'
            }
          },
        ]
      },
      {
        path: 'AngularSandbox',
        component: AngularSandboxComponent,
        data: {
          title: 'Angular Sandbox',
          state: 'Portal.AngularSandbox'
        }
      },
      {
        path: 'OrderComplete',
        component: OrderCompleteComponent,
        data: {
          title: 'Order Complete',
          state: 'Portal.OrderComplete'
        }
      },
      {
        path: 'SignatureComplete',
        component: SignatureCompleteComponent,
        data: {
          title: 'Signature Complete',
          state: 'Portal.SignatureComplete'
        }
      },
      {
        path: '404',
        component: PageNotFoundComponent,
        data: {
          title: 'Page Not Found',
          state: 'Portal.404'
        }
      },
    ]
  },
  { path: 'NewPortal/Portal', redirectTo: 'Portal', pathMatch: 'prefix' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled', // this is not working due to a bug with mat-sidenav-container interfering with scrolling position. Check portal.component lines 129-133 for workaround
  })],
  exports: [RouterModule]
})
export class PortalRoutingModule { }
