import { Injectable } from '@angular/core';
import { EnvironmentEnum } from 'src/app/shared/models/generic.models';

@Injectable({
  providedIn: 'root'
})
export class FeatureToggleService {
  private readonly prodUrl:string = 'portal.figmarketing.com';
  private readonly betaUrl:string = 'beta.agentportal.figdevteam.com';
  private readonly qaUrl:string = 'qa.agentportal.figdevteam.com';


  constructor() { }

  getEnvironment(): EnvironmentEnum{
    const url = location.hostname;


    if (url.includes(this.prodUrl)){
      return EnvironmentEnum.prod;
    } else if (url.includes(this.betaUrl)){
      return EnvironmentEnum.beta;
    } else if (url.includes(this.qaUrl)){
      return EnvironmentEnum.qa;
    } else {
      return EnvironmentEnum.local;
    }
  }
}
