import * as _ from 'lodash';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fadeIn, slideInOut } from 'src/app/animations';
import { FigPlatformCategory, FigPlatformTile } from 'src/app/shared/models/figplatform.models';
import { FigPlatformService } from 'src/app/core/services/figplatform.service';
import { UserGroup } from 'src/app/shared/models/usergroups.models';
import { environment } from 'src/environments/environment';
import { PermissionService } from 'src/app/core/services/auth/permission.service';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  animations: [fadeIn, slideInOut],
  selector: 'app-dashboard-explore',
  templateUrl: './dashboard-explore.component.html',
})
export class DashboardExploreComponent implements OnInit {
  hasSimonOptIn = this.permissionService.hasOnePermission('ShowProductRDOptIn:View|ProductRDOptedIn:View');
  hasQuinciOptIn = this.permissionService.hasOnePermission('ProductRDOptedIn:View');
  categories: FigPlatformCategory[] = [];
  environment = environment;
  loading: boolean = false;
  tiles: FigPlatformTile[] = [];
  //tempTags: string[] = ["Education", "Financial Planning", "Marketplace", "E-Application"];
  @Input() userGroup!: UserGroup | null;
  @Input() onBehalfOfUserGroup!: UserGroup;
  @Output() loaded: EventEmitter<any> = new EventEmitter();

  constructor(
    private figPlatformService: FigPlatformService,
    private permissionService: PermissionService) { }

  ngOnInit(): void {
    this.getCategoryTiles();
  }

  filterTilesByCategory(categoryName: string) {
    const result: FigPlatformTile[] = _.filter(this.tiles, tile => {
      return tile.Fields.CategoryName === categoryName;
    });
    return result;
  }

  getCategoryTiles(): void {
    forkJoin({
      categories: this.figPlatformService.getCategories().pipe(catchError(error => of(error))),
      tiles: this.figPlatformService.getUserPermittedTiles().pipe(catchError(error => of(error)))
    })
      .subscribe(res => {
        this.categories = res.categories;
        if (this.hasSimonOptIn) this.categories = this.categories.filter(cat => {
          return cat.Id !== 'recPBIrLIAEGOfWgu';
        });
        else this.categories = this.categories.filter(cat => {
          return cat.Id !== 'recvZM30AUpnvcoRP';
        });

        this.tiles = res.tiles;
      })
      .add(() => {
        this.loaded.emit();
        this.categories = _.orderBy(this.categories, ['Fields.Order']).slice(0, 3);
      });
  }
}
