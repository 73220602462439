
<section class="relative flex flex-col h-full bg-center bg-no-repeat bg-cover bg-gray-wave">
  <button class="absolute top-0 right-0 flex-none m-20 icon-button is-primary is-inverted" attr.aria-label="Close"
    [mat-dialog-close] type="button">
    <i class="material-icons">close</i>
  </button>
  <div class="flex flex-col p-40 text-center gap-y-30">
    <h1 class="text-xl font-bold">Choose Your E-Application Experience</h1>
    <div class="flex justify-around h-full">
      <app-eapp-option (optionSelected)="setEAppPreference($event)" [eAppOption]="firelightEmbeddedOptIn"></app-eapp-option>
      <span class="w-px my-auto h-200 bg-gray-lighter"></span>
      <app-eapp-option (optionSelected)="setEAppPreference($event)" [eAppOption]="firelightDirectOptIn"></app-eapp-option>
    </div>
    <p class="text-sm text-gray">You can change this setting at any time.</p>
  </div>
</section>
