import { externalLoadingHtml } from 'src/app/shared/constants/externalLoading.constants';
import { IcomplyApiService } from './http/icomply-api.service';
import { Injectable } from '@angular/core';
import { PermissionService } from './auth/permission.service';
import { SsoApiService } from './http/sso-api.service';
import { SureLcSsoUser } from 'src/app/shared/models/sso.models';
import { MarketplaceOneApiService } from './http/marketplace-one-api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SsoService {

  constructor(
    private iComplyApiService: IcomplyApiService,
    private permissionService: PermissionService,
    private ssoApiService: SsoApiService,
    private marketplaceOneApi: MarketplaceOneApiService,) { }

  annexusSso(): void {
    if (this.permissionService.hasOnePermission('AnnexusQuotes:Access')) {
      const w = window.open();
      w?.document.write(externalLoadingHtml);
      this.ssoApiService.initiateAnnexusSso().subscribe((data: any) => {
        w?.document.write(data);
        w?.document.forms.namedItem('samlform')?.submit();
      },
      () => {
        w?.document.write(' Failed.  There was an error accessing this resource.');
      });
    }
  }

  annuityRateWatchSso(): void {
    if (this.permissionService.hasOnePermission('AnnuityRateWatch:Access')) {
      const w = window.open()!;
      w?.document.write(externalLoadingHtml);
      this.ssoApiService.initiateAnnuityRateWatchSso().subscribe((data: any) => {
        w.location = JSON.parse(data.Result);
      },
      () => {
        w?.document.write(' Failed.  There was an error accessing this resource.');
      });
    }
  }

  cannexSso(): void {
    if (this.permissionService.hasOnePermission('Cannex:Access')) {
      const w = window.open();
      w?.document.write(externalLoadingHtml);
      this.ssoApiService.initiateCannexSso().subscribe((data: any) => {
        w?.document.write(data);
        w?.document.forms.namedItem('cannex')?.submit();
      },
      () => {
        w?.document.write(' Failed.  There was an error accessing this resource.');
      });
    }
  }

  ensightSso(): void {
    const w = window.open()!;
    w?.document.write(externalLoadingHtml);
    this.ssoApiService.initiateEnsightSso().subscribe((data: any) => {
      w.location.href = data;
    },
    () => {
      w?.document.write(' Failed.  There was an error accessing this resource.');
    });
  }

  firelightSso(activityId?: string): void {
    if (this.permissionService.hasOnePermission('Firelight:Access')) {
      const w = window.open();
      w?.document.write(externalLoadingHtml);
      this.ssoApiService.initiateFirelightSso(activityId)
        .subscribe(data => {
          w?.document.write(data);
          w?.document.forms.namedItem('samlform')?.submit();
        },
        () => w?.document.write(' Failed.  There was an error accessing this resource.'));
    }
  }

  iComplyLiteSso(): void {
    if (this.permissionService.hasOnePermission('IComplyLite:Access')) {
      const w = window.open()!;
      w?.document.write(externalLoadingHtml);
      this.iComplyApiService.getFastTrackToken().subscribe(result => {
        const auth = JSON.parse(result.Auth);

        let url = environment.iComplyUI +
          '/Sso?accesstoken=' + auth.Token +
          '&expiration=' + auth.Expiration +
          '&fasttrack=true';

        if (result.OnBehalfOfUserFigAuthId != null) {
          url += '&onbehalfofauthuserid=' + result.OnBehalfOfUserFigAuthId;
        }

        w.location.href = url;
      },
      () => {
        w?.document.write(' Failed.  There was an error accessing this resource.');
        window.location.href = '/Portal';
      });
    }
  }

  iComplySso(): void {
    if (this.permissionService.hasOnePermission('IComply:View')) {
      const w = window.open()!;
      w.document.write(externalLoadingHtml);
      this.iComplyApiService.getFastTrackToken().subscribe(result => {
        const auth = JSON.parse(result.Auth);

        let url = environment.iComplyUI +
          '/Sso?accesstoken=' + auth.Token +
          '&expiration=' + auth.Expiration;

        if (result.OnBehalfOfUserFigAuthId != null) {
          url += '&onbehalfofauthuserid=' + result.OnBehalfOfUserFigAuthId;
        }

        w.location.href = url;
      },
      () => {
        w?.document.write(' Failed.  There was an error accessing this resource.');
      });
    }
  }

  iPipelineEAppsSso(): void {
    if (this.permissionService.hasOnePermission('IpipelineEApps:Access')) {
      const w = window.open();
      w?.document.write(externalLoadingHtml);
      this.ssoApiService.initiateIPipelineEappsSso(null)
        .subscribe(data => {
          w?.document.write(data);
          w?.document.forms.namedItem('samlform')?.submit();
        },
        () => w?.document.write(' Failed.  There was an error accessing this resource.'));
    }
  }

  iPipelineFormsSso(): void {
    if (this.permissionService.hasOnePermission('IpipelineFormsQuotesAnnuity:Access|IpipelineFormsQuotesLife:Access|IpipelineFormsQuotesLtc:Access')) {
      const w = window.open();
      w?.document.write(externalLoadingHtml);
      this.ssoApiService.initiateIPipelineSso('forms').subscribe(res => {
        w?.document.write(res);
        w?.document.forms.namedItem('samlform')?.submit();
      },
      () => {
        w?.document.write(' Failed.  There was an error accessing this resource.');
      });
    }
  }

  iPipelineLifeQuotesAnalysisSso(): void {
    if (this.permissionService.hasOnePermission('IpipelineFormsQuotesAnnuity:Access|IpipelineFormsQuotesLife:Access|IpipelineFormsQuotesLtc:Access')) {
      const w = window.open();
      w?.document.write(externalLoadingHtml);
      this.ssoApiService.initiateIPipelineSso('lifeQuotesAnalysis').subscribe((data: any) => {
        w?.document.write(data);
        w?.document.forms.namedItem('samlform')?.submit();
      },
      () => {
        w?.document.write(' Failed.  There was an error accessing this resource.');
      });
    }
  }

  iPipelineLifeQuotesSso(): void {
    if (this.permissionService.hasOnePermission('IpipelineFormsQuotesAnnuity:Access|IpipelineFormsQuotesLife:Access|IpipelineFormsQuotesLtc:Access')) {
      const w = window.open();
      w?.document.write(externalLoadingHtml);
      this.ssoApiService.initiateIPipelineSso('lifeQuotes').subscribe((data: any) => {
        w?.document.write(data);
        w?.document.forms.namedItem('samlform')?.submit();
      },
      () => {
        w?.document.write(' Failed.  There was an error accessing this resource.');
      });
    }
  }

  iPipelineLifeProductsSso(): void {
    if (this.permissionService.hasOnePermission('IpipelineFormsQuotesAnnuity:Access|IpipelineFormsQuotesLife:Access|IpipelineFormsQuotesLtc:Access')) {
      const w = window.open();
      w?.document.write(externalLoadingHtml);
      this.ssoApiService.initiateIPipelineSso('lifeProducts').subscribe((data: any) => {
        w?.document.write(data);
        w?.document.forms.namedItem('samlform')?.submit();
      },
      () => {
        w?.document.write(' Failed.  There was an error accessing this resource.');
      });
    }
  }

  mapleLifeSso(): void {
    const w = window.open()!;
    w?.document.write(externalLoadingHtml);
    this.ssoApiService.initiateMapleLifeCalculatorSso().subscribe((redirectUrl) => {
      w.location.href = redirectUrl;
    },
    () => {
      w?.document.write(' Failed.  There was an error accessing this resource.');
    });
  }

  marketPlaceOneSso(onBehalfOfId?: string): void {
    const now = new Date();
    now.setTime(now.getTime() + 45 * 60 * 1000);
    const expiration = now.toISOString();

    const w = window.open()!;
    w?.document.write(externalLoadingHtml);

    this.marketplaceOneApi.getToken()
      .subscribe(token => {
        let url = `${environment.marketplaceOneUI}!/Sso?accesstoken=${token}&expiration=${expiration}`;
        if (onBehalfOfId) url += `&onBehalfOfUserId=${onBehalfOfId}`;
        w.location.href = url;
      },
      (error) => {
        console.log(error);
        w?.document.write(' Failed.  There was an error accessing this resource.');
      });
  }

  retireUpSso(): void {
    if (this.permissionService.hasOnePermission('RetireUp:Access')) {
      const w = window.open();
      w?.document.write(externalLoadingHtml);
      this.ssoApiService.initiateRetireUpSso()
        .subscribe(data => {
          w?.document.write(data);
          w?.document.forms.namedItem('samlform')?.submit();
        },
        () => w?.document.write(' Failed.  There was an error accessing this resource.'));
    }
  }

  sureLcSso(sureLcUser: SureLcSsoUser): void {
    const w = window.open();
    w?.document.write(externalLoadingHtml);

    this.ssoApiService.initiateSureLcSso(sureLcUser.LastName).subscribe(
      (res) => {
        w?.document.write(res);
        w?.document.forms.namedItem('surelc')?.submit();
      },
      () => w?.document.write(' Failed. There was an error accessing this resource')
    );
  }
}
