<section *ngIf="!isLoading" [@fadeIn]>
  <button class="button is-primary mt-20 md:mt-30 mr-20" (click)="openExternalInterestForm()">
    Show Interest
  </button>
  
  <button class="button is-primary mt-20 md:mt-30 mr-20" (click)="openPurchaseDialog()"
    [disabled]="!!purchased && purchased.Complete">
    <span *ngIf="!purchased">
      Purchase
    </span>
    <span *ngIf="!!purchased && purchased.Complete">
      Purchased On {{purchased?.PurchaseDate}}
    </span>
  </button>
  
  <button class="button is-primary mt-20 md:mt-30 mr-20" (click)="openDocusignLink()"
    *ngIf="!!purchased && purchased.Complete">
    <span>
      Sign Power of Zero Plus Agreement
    </span>
  </button>
  
  
  <button class="button is-primary mt-20 md:mt-30 mr-20" (click)="openExternalLoginLink()"
    *showIfOne="'PowerOfZero:Access'">
    <span>
      Log Into Power of Zero
    </span>
  </button>
</section>