import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Action, PolicyStatement } from '../../../shared/models/permission.models';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  private readonly onBehalfOfCookieName:string = 'X-On-Behalf-Of';

  constructor(private http:HttpClient, private cookieService:CookieService) { }


  setPermissions(permissions:PolicyStatement[]):void {
    localStorage.setItem('Auth.Permissions', JSON.stringify(permissions));
  }

  getPermissions():PolicyStatement[] {
    return JSON.parse(localStorage.getItem('Auth.Permissions') || '[]') as PolicyStatement[];
  }

  getOnBehalfOfUserId():string|null {
    return this.cookieService.check(this.onBehalfOfCookieName) ? this.cookieService.get(this.onBehalfOfCookieName) : null;
  }

  clearAllLocalStorage(): void{
    this.removeStoredPermissions();
    this.removeStoredOnBehalfOfUserId();
    this.removeMarketingTrackerToken();
  }

  removeStoredPermissions():void {
    localStorage.removeItem('Auth.Permissions');
  }

  removeStoredOnBehalfOfUserId(): void{
    this.cookieService.delete(this.onBehalfOfCookieName, '/');
    localStorage.removeItem('Auth.UserDelegateId');
  }

  removeMarketingTrackerToken(): void{
    localStorage.removeItem('Auth.MarketingTrackerAccessToken');
  }

  hasOnePermission(permissions:string): boolean{
    if (permissions == null || !permissions) return true;
    const currentUserPermissions = this.getPermissions();
    const checkPermissions = permissions.split('|');
    const obj:any = {};
    let allow:boolean|undefined;

    checkPermissions.forEach(val => {
      const resource = val.split(':')[0];
      const action = val.split(':')[1];
      let explicitDeny = false;

      if(obj[resource + ':' + action] !== false){
        for (const userPermission of currentUserPermissions) {
          if (explicitDeny) break;
          for (const x of userPermission.Actions){
            if (x.AllowAll === '*' && userPermission.Effect === 'ALLOW'){
              allow = true;
              break;
            }
            if (x.AllowAll === '*' && userPermission.Effect === 'DENY'){
              allow = false;
              break;
            }
            if (this.hasPermission(x, action, resource)){
              if (userPermission.Effect == 'ALLOW'){
                obj[resource + ':' + action] = true;
              }
              if (userPermission.Effect == 'DENY'){
                obj[resource + ':' + action] = false;
                explicitDeny = true;
                break;
              }
            }
          }
        }
      }
    });

    if (allow !== undefined) return allow;

    allow = this.containsAtLeastOneAllowPermission(obj);

    return allow;
  }

  hasAllPermissions(permissions:string):boolean{
    if (permissions == null) return true;
    const currentUserPermissions = this.getPermissions();
    const checkPermissions = permissions.split('|');
    let allow = false;
    let breakRepeat = false;

    for(const val of checkPermissions){
      const resource = val.split(':')[0];
      const action = val.split(':')[1];
      let exists = false;
      let explicitDeny = false;
      if (breakRepeat) break;

      for (const userPermission of currentUserPermissions){
        if (explicitDeny == true) break;

        for (const x of userPermission.Actions){
          if (x.AllowAll === '*' && userPermission.Effect === 'ALLOW'){
            allow = true;
            exists = true;
            break;
          }

          if (x.AllowAll === '*' && userPermission.Effect === 'DENY'){
            allow = false;
            exists = true;
            break;
          }

          if (this.hasPermission(x, action, resource)){
            if (userPermission.Effect == 'ALLOW') {
              allow = true;
            }
            if (userPermission.Effect == 'DENY') {
              allow = false;
              explicitDeny = true;
              breakRepeat = true;
              break;
            }

            exists = true;
          }
        }
      }

      if (!exists){
        allow = false;
        breakRepeat = true;
        return allow;
      }
    }
    return allow;
  }

  private hasPermission(permissionAction:Action, userAction:string, resource: string): boolean{
    if (permissionAction.Action.toLowerCase() == userAction.toLowerCase() && permissionAction.Resource.toLowerCase() == resource.toLowerCase())
      return true;
    else
      return false;
  }

  private containsAtLeastOneAllowPermission(obj: any): boolean{
    // See if any keys in the object contain a "true" value
    return Boolean(Object.keys(obj).find(row => obj[row]));
  }

  getUserDelegateId():string|null {
    return localStorage.getItem('Auth.UserDelegateId');
  }

  setOnBehalfOfUserId(userId:string): void {
    // using cookies here instead of localStorage so that requests for new tabs and iframes will include the cookie automaticalls
    this.cookieService.set(this.onBehalfOfCookieName, userId, {path:'/', sameSite: 'Lax'});
  }

  setUserDelegateId(userDelegateId:string): void {
    localStorage.setItem('Auth.UserDelegateId', userDelegateId);
  }

}
