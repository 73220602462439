import { TableHeader } from 'src/app/shared/classes/table';
import { InformalTablePolicy } from '../models/informalTablePolicy.model';

export const InformalTableHeaders: TableHeader<InformalTablePolicy>[] = [
  {
    DisplayName: 'Agents',
    ColumnName: ['Agents'],
    WidthClass: '',
    CompareFn: (a: InformalTablePolicy, b: InformalTablePolicy, order: 1|-1):number => {
      const valA = a.Agents ?? '';
      const valB = b.Agents ?? '';
      return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: 'Related People',
    ColumnName: ['RelatedPeople'],
    WidthClass: '',
    CompareFn: (a: InformalTablePolicy, b: InformalTablePolicy, order: 1|-1):number => {
      const valA = a.RelatedPeople ?? '';
      const valB = b.RelatedPeople ?? '';
      return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: 'Face Amount',
    ColumnName: ['FaceAmount'],
    WidthClass: '',
    CompareFn: (a: InformalTablePolicy, b: InformalTablePolicy, order: 1|-1):number => {
      const valA = a.FaceAmount ?? 0;
      const valB = b.FaceAmount ?? 0;
      return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: 'Ann. Premium',
    ColumnName: ['AnnualizedPremium'],
    WidthClass: '',
    CompareFn: (a: InformalTablePolicy, b: InformalTablePolicy, order: 1|-1):number => {
      const valA = a.AnnualizedPremium ?? 0;
      const valB = b.AnnualizedPremium ?? 0;
      return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: 'State',
    ColumnName: ['StateName'],
    WidthClass: '',
    CompareFn: (a: InformalTablePolicy, b: InformalTablePolicy, order: 1|-1):number => {
      const valA = a.StateName ?? '';
      const valB = b.StateName ?? '';
      return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: 'Carriers',
    ColumnName: ['Carrier'],
    WidthClass: '',
    CompareFn: (a: InformalTablePolicy, b: InformalTablePolicy, order: 1|-1):number => {
      const valA = a.Carrier ?? '';
      const valB = b.Carrier ?? '';
      return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
    },
  }
];