import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { fadeIn } from 'src/app/animations';
import { forkJoin } from 'rxjs';
import { PermissionService } from 'src/app/core/services/auth/permission.service';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { User } from 'src/app/shared/models/user.models';
import { UserApiService } from 'src/app/core/services/http/user-api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-portal-preview',
  templateUrl: './portal-preview.component.html',
  animations: [fadeIn]
})
export class PortalPreviewComponent implements OnInit {
  @Input() onBehalfOfUser!:User|null;
  @Input() user!:User;
  @Output() taskCompletedEvent: EventEmitter<any> = new EventEmitter();
  @Output() goToPreviousEvent: EventEmitter<any> = new EventEmitter();
  @Output() goToNextEvent: EventEmitter<any> = new EventEmitter();
  @Output() goToPortalEvent: EventEmitter<string> = new EventEmitter();

  constructor(private userApiService:UserApiService, private permissionService:PermissionService, private cookieService: CookieService,
    private sessionStore:SessionStoreService, private router:Router) { }

  ngOnInit(): void {
    this.user = this.sessionStore.User;
    this.onBehalfOfUser = this.sessionStore.OnBehalfOfUser;
  }
  goToNext(): void {
    this.goToNextEvent.emit();
  }

  goToPrevious():void {
    this.goToPreviousEvent.emit();
  }

  loadTaskCompleted(fromParent:boolean):void { }

  returnToPortal() {
    this.goToPortalEvent.emit('Training');
  }
}
