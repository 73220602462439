<section class="flex flex-col flex-grow h-0 p-20 overflow-auto" [@fadeIn]>
  <!-- <div class="flex flex-row flex-wrap items-start justify-center m-10 mb-20 lg:m-20">
    <div class="w-full sm:w-1/2">
      <div class="flex flex-row items-start justify-start m-10 mb-20">
        <span class="flex flex-row items-center justify-center mr-10 rounded-full w-36 h-36 bg-blue-lightest">
          <span class="text-sm font-bold text-enter text-blue-dark">1</span>
        </span>
        <div class="flex flex-col flex-grow">
          <p class="mb-10 text-base font-semibold h-36" style="line-height:3.6rem">Watch the Quick Tutorial</p>
          <div class="w-full sm:w-4/5">
            <div class="relative w-full overflow-auto rounded wistia_responsive_padding" style="padding: 56.25% 0 0 0;">
              <div class="absolute top-0 left-0 w-full h-full wistia_responsive_wrapper">
                <span id="sureLC_video"
                  class="wistia_embed wistia_async_q4kvg1saon popover=true popoverAnimateThumbnail=false videoFoam=false videoQuality=hd-only"
                  style="display: inline-block; height: 100%; width: 100%;"> </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full sm:w-1/2">
      <div class="flex flex-row items-start justify-start m-10 mb-20">
        <span class="flex flex-row items-center justify-center mr-10 rounded-full w-36 h-36 bg-blue-lightest">
          <span class="text-sm font-bold text-enter text-blue-dark">2</span>
        </span>
        <div class="flex flex-col flex-grow">
          <p class="mb-10 text-base font-semibold h-36" style="line-height:3.6rem">Prepare Needed Documents</p>
          <div class="flex flex-col flex-wrap items-start justify-start">
            <span class="flex flex-row items-start content-start w-full pb-10 text-base" *ngFor="let item of checklist">
              <i class="mr-10 leading-10 fig-24px fig-check-circle-filled text-green"></i><span class="leading-10"
                [innerHtml]="item"></span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="flex flex-col flex-none text-center">
    <div class="flex flex-col items-center m-10">
      <span class="flex flex-row items-center justify-center flex-none m-10 rounded-full w-36 h-36 bg-gray-dark">
        <span class="font-bold leading-none text-white">1</span>
      </span>
      <div class="flex flex-col">
        <p class="m-10 font-bold">Watch the Quick Tutorial</p>
        <div class="m-10">
          <iframe width="350" height="175" src="https://www.youtube.com/embed/UmEPdqEMS3o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <!-- <div class="relative m-10 overflow-auto rounded w-256 wistia_responsive_padding" style="padding: 56.25% 0 0 0;">
          <div class="absolute top-0 left-0 w-full h-full wistia_responsive_wrapper">
            <span id="sureLC_video"
              class="wistia_embed wistia_async_q4kvg1saon popover=true popoverAnimateThumbnail=false videoFoam=false videoQuality=hd-only inline-block h-full w-full"> </span>
          </div>
        </div> -->
      </div>
    </div>
    <div class="flex flex-col items-center m-10">
      <span class="flex flex-row items-center justify-center flex-none m-10 rounded-full w-36 h-36 bg-gray-dark">
        <span class="font-bold leading-none text-white">2</span>
      </span>
      <div class="flex flex-col flex-grow">
        <p class="m-10 font-bold">Prepare Needed Documents</p>
        <div class="flex flex-col m-10">
          <span class="flex flex-row items-start content-start w-full pb-10 text-base" *ngFor="let item of checklist">
            <i class="mr-10 leading-10 fig-24px fig-check-circle-filled text-green"></i><span class="leading-10"
              [innerHtml]="item"></span>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div
    class="flex flex-row p-20 m-10 mx-20 text-sm leading-loose bg-opacity-25 border border-solid rounded flexnone border-blue-lighter bg-blue-lighter">
    <i class="mt-5 mr-10 material-icons mi-18 text-gray">info</i>
    <span> You will be launched into a new tab to start contracting. Come back to this page to continue onboarding once
      completed</span>
  </div>
</section>
<section
  class="flex items-center justify-start flex-none p-10 mt-auto border-t border-solid lg:p-20 border-gray-lighter">
  <button mat-button class="m-10 mr-auto button is-lg is-primary is-outlined" *ngIf="!isFirstStep" (click)='goToPrevious()'>
    <span class="flex flex-row items-center justify-center">
      <span class="material-icons mi-18">
        chevron_left
      </span>
      <span class="mr-10 leading-none">Previous</span>
    </span>
  </button>
  <button mat-button class="m-10 ml-auto button is-lg is-primary" (click)="goToNext()" *ngIf="taskCompleted">
    <span class="flex flex-row items-center justify-center">
      <span class="ml-10 leading-none">{{isLastStep? 'Return To Portal' : 'Next'}}</span>
      <span class="material-icons mi-18">
        chevron_right
      </span>
    </span>
  </button>
  <button class="m-10 button is-lg is-success" [disabled]="isRedirecting" *ngIf="!taskCompleted"
    (click)='carrierSetupLaunchSureLc()'>
    <span *ngIf="!isRedirecting">Launch</span>
    <span *ngIf="isRedirecting">Redirecting...</span>
  </button>
</section>