import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-announcement-banner',
  templateUrl: './announcement-banner.component.html',
  styleUrls: ['./announcement-banner.component.scss']
})

export class AnnouncementBannerComponent {
  @Input() panelClass: string = 'flex items-center justify-center w-full px-20 text-sm font-semibold text-white py-15 bg-gray-dark gap-x-10';
}
