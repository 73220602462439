import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { fadeIn } from 'src/app/animations';

@Component({
  animations: [fadeIn],
  selector: 'app-marketplace-overview',
  templateUrl: './marketplace-overview.component.html',
})

export class MarketplaceOverviewComponent implements OnInit {
  resourceUrl: string = 'Marketplace';
  annuitiesCarriers: CarrierImg[] = [
    {Title: 'Allianz', ExternalUrl:'https://www.figmarketing.com/Images/Logos/Carriers/Allianz.png'},
    {Title: 'American Equity', ExternalUrl: 'https://www.figmarketing.com/Images/Logos/Carriers/AmericanEquity.png'},
    {Title: 'Great American', ExternalUrl: 'https://www.figmarketing.com/Images/Logos/Carriers/GreatAmerican.png'},
    {Title: 'Mass Mutual', ExternalUrl: 'https://www.figmarketing.com/Images/Logos/Carriers/MassMutual.png'},
    {Title: 'Fidelity & Guaranty Life', ExternalUrl: 'https://www.figmarketing.com/Images/Logos/Carriers/FidelityandGuaranty_tagline.png'},
    {Title: 'Brighthouse Financial', ExternalUrl: 'https://www.figmarketing.com/Images/Logos/Carriers/BrighthouseFinancial.png'},
    {Title: 'Nationwide', ExternalUrl: 'https://www.figmarketing.com/Images/Logos/Carriers/Nationwide.png'},
    {Title: 'Global Atlantic', ExternalUrl: 'https://www.figmarketing.com/Images/Logos/Carriers/GlobalAtlantic.png'},
    {Title: 'American International Group', ExternalUrl: 'https://www.figmarketing.com/Images/Logos/Carriers/AmericanGeneral.png'},
    {Title: 'Delaware Life', ExternalUrl: 'https://www.figmarketing.com/Images/Logos/Carriers/DelawareLife.png'},
    {Title: 'Symetra', ExternalUrl: 'https://www.figmarketing.com/Images/Logos/Carriers/SymetraFinancial.png'},
  ];
  structuredCarriers: CarrierImg[] = [
    {Title: 'Barclays', ExternalUrl: 'https://www.figmarketing.com/Images/Logos/Carriers/Barclays.png'},
    {Title: 'Citibank', ExternalUrl: 'https://www.figmarketing.com/Images/Logos/Carriers/Citibank.png'},
    {Title: 'Credit Suisse', ExternalUrl: 'https://www.figmarketing.com/Images/Logos/Carriers/CreditSuisse.png'},
    // {Title: 'Goldman Sachs', ExternalUrl: 'https://www.figmarketing.com/Images/Logos/Carriers/GoldmanSachs-filled.png'},
    {Title: 'Goldman Sachs', ExternalUrl: 'https://www.figmarketing.com/Images/Logos/Carriers/GoldmanSachs.png'},
    {Title: 'JP Morgan', ExternalUrl:'https://www.figmarketing.com/Images/Logos/Carriers/JPMorganChase.png'},
    {Title: 'Morgan Stanley', ExternalUrl: 'https://www.figmarketing.com/Images/Logos/Carriers/MorganStanley.png'},
  ];

  constructor(private titleService: Title, private router: Router) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.resourceUrl + ' | Agent Portal');
  }
}

export interface CarrierImg {
  ExternalUrl?: string;
  InternalUrl?: string;
  Title: string;
}