import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { fadeIn } from '../../../../../../../animations';
import { environment } from 'src/environments/environment';

@Component({
  animations: [fadeIn],
  selector: 'app-product-training',
  templateUrl: './product-training.component.html',
})

export class ProductTrainingComponent implements OnInit {
  hideBackButton:boolean = false;
  environment = environment;
  searchValue: string | null = '';
  iframeUrl: string = '';
  localSimon: boolean = false;
  localSimonUrl: string = environment.simonLocalUrl;
  simonUrl: string = environment.simonUrl;

  constructor(
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.hideBackButton = window.location.pathname.includes('Workflow');
    this.searchValue = this.activatedRoute.snapshot.queryParamMap.get('searchValue');
    this.openSimonSso(this.searchValue);
  }

  goBack() {
    window.history.back();
  }

  openSimonSso(param: string | null) {
    let base = '/learning-center2/annuities/training?showSearch=false&showProgressPanel=false';

    if (!!param) {
      // has to be completed lowercase
      param = param === 'completed' ? `&status=${encodeURIComponent(param)}` : `&searchValue=${encodeURIComponent(param)}`;
      base += param;
    }

    let url: string = '';
    if (this.localSimon) {
      url = this.localSimonUrl + base;
    } else {
      url = this.simonUrl + base;
    }
    this.iframeUrl = url;
  }
}
