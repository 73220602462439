<section class="flex justify-center border-b p-30 border-gray-lighter">
    <h2 class="w-full text-lg font-bold leading-tight heading sm:h-24">Start New Application</h2>
</section>
<section class="flex flex-col flex-grow p-20">
    <form class="flex flex-col" name="firelightApp">
        <div class="flex flex-col flex-none m-10">
            <label class="mb-10 text-sm font-semibold text-gray">E-Application Title</label>
            <input class="h-40 px-10 border border-solid input is-inverted border-gray-light" type="text"
                name="applicationTitle" [(ngModel)]="activityName" aria-label="E-Application Title" required
                minlength="1" />
        </div>
        <div class="flex flex-col flex-none m-10">
            <label class="mb-10 text-sm font-semibold text-gray">Carrier</label>
            <mat-select (selectionChange)="setCarrier($event)" name="carrier"
                class="h-40 px-10 m-0 border border-solid select is-inverted md-no-underline border-gray-light" required
                placeholder="Select Carrier" aria-label="Select Carrier">
                <mat-option *ngFor="let c of carriers" [value]="c" required>
                    {{c.CarrierName}}
                </mat-option>
            </mat-select>
        </div>
        <div class="flex flex-col flex-none m-10">
            <label class="mb-10 text-sm font-semibold text-gray">State</label>
            <mat-select name="state" (selectionChange)="setState($event);"
                class="h-40 px-10 m-0 border border-solid select is-inverted md-no-underline border-gray-light" required
                placeholder="Select State" [(ngModel)]="state" aria-label="Select State">
                <mat-option *ngFor="let s of states" [value]="s" required>
                    {{s.LongName}}
                </mat-option>
            </mat-select>
        </div>
        <div class="flex flex-col flex-none m-10">
            <label class="mb-10 text-sm font-semibold text-gray">Product</label>
            <mat-select name="product" (selectionChange)="setProduct($event)"
                class="h-40 px-10 m-0 border border-solid select is-inverted md-no-underline border-gray-light" required
                placeholder="Select Product" aria-label="Select Product">
                <mat-option *ngFor="let p of products" [value]="p" required>
                    {{p.ProductName}}
                </mat-option>
            </mat-select>
        </div>
    </form>
</section>
<section class="grid grid-cols-3 gap-x-20 gap-y-20 border-t border-solid p-30 border-gray-lighter">
    <button class="flex items-center justify-center button is-primary is-outlined is-lg" type="submit" name="submitApplication"
        aria-label="Submit E-Application" (click)="submitFirelightEApplication();"
        [disabled]="!activityName || !carrier || !product || !state || isSubmitting || (product ? product.EAppVendor !== 'Firelight' : false) ">
        <img class="w-24 h-24 mr-10" aria-label="iGo Image" src="/Images/Icons/firelight.svg" />
        <span>Firelight E-App</span>
    </button>
    <button class="flex items-center justify-center button is-primary is-outlined is-lg" type="submit"
        name="submitIPipelineEApplication" aria-label="Submit IPipeline E-Application"
        (click)="submitIPipelineEApplication();"
        [disabled]="!activityName || !carrier || !product || !state || isSubmitting || (product ? product.EAppVendor !== 'IPipeline' : false)">
        <img class="w-24 h-24 mr-10" src="/Images/Icons/ipipeline.svg" />
        <span>iPipeline E-App</span>
    </button>
    <button class="flex items-center justify-center button is-primary is-outlined is-lg" type="submit"
        name="submitPaperApplication" aria-label="Submit Paper Application" (click)="openPaperApplicationModal();">
        <span class="mr-10 material-icons">note_alt</span>
        <span>Paper App</span>
    </button>
</section>