
export const environment = {
  production: false,
  beta: false,
  qa: true,
  local: false,
  marketplaceOneUI: 'https://qa.marketplace-one.figdevteam.com/#',
  iComplyUI: 'https://qa.icomply.io/#',
  simonUrl: 'https://www.qa.simon.figdevteam.com/simon/#',
  simonLocalUrl: 'https://localhost.qa.simonmarkets.com:3000/#',
  simonApi: 'https://www.qa.simon.figdevteam.com/simon/api/v1',
  // used for iframe postmessage validation - just scheme and hostname, no path
  simonIframeOrigin: 'https://www.qa.simon.figdevteam.com',
  truelyticsSsoUrl: 'https://app.truelytics.com/user/sso?auto=true&id=',
  simonIframeLocalOrigin: 'https://localhost.qa.simonmarkets.com:3000',
  useSimonProxy: true,
  //TODO: Portal Test's User ID used for temp testing of Quinci release 11/15. Delete immediately afterward
  figPortalTestUserId: null,
  figAppTestUserId: null,
};
