<section class="flex items-center justify-start flex-layout">
    <div class="relative flex flex-row items-center justify-start m-20 mr-10 sm:flex-grow-0 sm:w-384">
        <i class="absolute transform -translate-y-1/2 fig-24px fig-search top-1/2 left-5 text-gray"></i>
        <input type="text" class="w-full input is-lg pl-36" placeholder="Search..."
            [(ngModel)]="searchText" />
    </div>
    <button class="mr-auto icon-button is-primary is-inverted" attr.aria-label="Clear search box" *ngIf="!!searchText"
        (click)="clearSearch()"><i class="material-icons">close</i></button>
</section>
<!--Main Categories-->
<ul class="flex flex-col">
    <li class="flex flex-col flex-grow-0" id="category{{category.Order}}" [ngClass]="{'mb-20': !last}"
        *ngFor="let category of categories; let last = last">
        <h3 class="heading text-lg text-grey-dark uppercase font-bold m-10 pb-10 border-b border-gray-lighter">
            {{category.Title}}</h3>
        <ul class="flex flex-row flex-wrap">
            <!--Order priority left > right-->
            <ng-container *ngFor="let subCategory of orderBy(category.SubCategories, ['Order']) ">
                <ng-container *ngIf="category.SubCategories.length && filterSubcats(subCategory, searchText)">
                    <li *ngIf="category.Title === 'Market Updates'"
                        class="flex flex-row p-5 w-1/2 sm:w-1/3 md:w-1/2 lg:w-1/3">
                        <!-- wistia embeds -->
                        <div class="bg-blue-darkest-75 rounded p-20 flex flex-col">
                            <h3 class="heading text-gray-dark font-semibold capitalize mb-10">Betashield Strategy by
                                Tony Parish
                            </h3>
                            <div class="wistia_responsive_padding mb-20"
                                style="padding: 56.25% 0 0 0; position: relative;">
                                <div class="wistia_responsive_wrapper"
                                    style="height: 100%; left: 0; position: absolute; top: 0; width: 100%;">
                                    <span class="flex flex-row"
                                        class="wistia_embed wistia_async_8zlvuz7sfw popover=true popoverAnimateThumbnail=true videoFoam=true videoQuality=hd-only w-full h-full"> </span>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="flex flex-row w-1/2 sm:w-1/3 md:w-1/2 lg:w-1/3">
                        <div class="flex flex-row p-10 m-10 rounded-lg border border-gray-lighter">
                            <!-- text & links -->
                            <div class="bg-blue-darkest-75 rounded p-20" class="flex flex-col"
                                *ngIf="subCategory.ContentType === 'text'">
                                <h3 class="heading text-gray-dark font-semibold capitalize mb-10">
                                    {{subCategory.Title}}&nbsp;
                                    <!--<span class="text-green uppercase" *ngIf="categoryRecentUpdates.SubCategories.includes(subcat);">-&nbsp;Updated</span>-->
                                </h3>
                                <p class="text-sm mb-20" *ngIf="!!subCategory.Description">{{subCategory.Description}}
                                </p>
                                <ul class="flex flex-col m-10">
                                    <!--Order priority left > right-->
                                    <li class="flex flex-row flex-grow-0"
                                        *ngFor="let link of orderBy(subCategory.Links, ['Featured', 'Order'])">
                                        <div class="w-full h-full flex flex-row items-center justify-start"
                                            [ngClass]="{'mb-10':!last}"
                                            *ngIf="subCategory.Links.length && filterLinks(link, subCategory, searchText)">
                                            <div class="relative flex flex-row justify-center items-center">
                                                <i class="fig-24px {{link.IconClass}} mr-5"
                                                    [ngClass]="link.Featured ? 'text-orange':'text-gray'"></i>
                                                <!-- <span class="w-10 h-10 top-0 left-0 rounded-full bg-green absolute" *ngIf="categoryRecentUpdates.Links.includes(link);">&nbsp;</span> -->
                                            </div>
                                            <div>
                                                <a class="link capitalize"
                                                    [ngClass]="link.Featured ? 'mb-20 is-size-4 text-orange':'is-size-5 is-primary-2'"
                                                    [href]="link.Url" target="_blank" rel="noopener"
                                                    (click)="logAmplitude(link.Title)" [innerHtml]="link.Title"></a>
                                                &nbsp;<span class="text-green uppercase"></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <!-- wistia embeds -->
                            <div class="bg-blue-darkest-75 rounded p-20" class="flex flex-col" flex
                                *ngIf="subCategory.ContentType === 'wistia'">
                                <h3 class="heading text-gray-dark font-semibold capitalize mb-10">{{subCategory.Title}}
                                    <span *ngIf="subCategory.Date">on {{subCategory.Date | date:
                                        'shortDate'}}</span>&nbsp;
                                    <!--<span class="text-green uppercase" *ngIf="categoryRecentUpdates.SubCategories.includes(subcat);">-&nbsp;Updated</span>-->
                                </h3>
                                <p class="text mb-20" *ngIf="subCategory.Description">{{subCategory.Description}}</p>
                                <div class="wistia_responsive_padding mb-20"
                                    style="padding: 56.25% 0 0 0; position: relative;">
                                    <div class="wistia_responsive_wrapper"
                                        style="height: 100%; left: 0; position: absolute; top: 0; width: 100%;">
                                        <span class="flex flex-row" [ngClass]="subCategory.Url"
                                            class="wistia_embed popover=true popoverAnimateThumbnail=true videoFoam=true videoQuality=hd-only w-full h-full"> </span>
                                    </div>
                                </div>
                                <ul class="flex flex-col">
                                    <li class="flex flex-row flex-grow-0" [ngClass]="{'mb-10':!last}"
                                        *ngFor="let link of orderBy(subCategory.Links, ['Featured', 'Order'])">
                                        <!-- *ngIf="subCategory.Links.length"-->
                                        <div class="relative">
                                            <i class="fig-24px {{link.IconClass}} mr-5"
                                                [ngClass]="link.Featured ? 'text-orange':'text-gray-light'"></i>
                                            <!-- <span class="w-10 h-10 top-0 left-0 rounded-full bg-green absolute" *ngIf="categoryRecentUpdates.Links.includes(link);">&nbsp;</span> -->
                                        </div>
                                        <div>
                                            <a class="link text-lg is-primary-2 capitalize" [href]="link.Url"
                                                target="_blank" rel="noopener"
                                                (click)="logAmplitude(link.Title)">{{link.Title}}</a>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </li>
                </ng-container>
            </ng-container>
        </ul>
    </li>
</ul>