<section class="flex flex-col w-full h-full mx-auto max-w-1920">
    <div class="flex flex-row items-center mb-20 md:mb-30" [ngClass]="{'mx-auto w-full max-w-1280': getComponent() === 'DashboardV2'}" *ngIf="getComponent && !getComponent().includes('Details')"
        @fadeIn>
        <h2 class="mr-auto text-xl font-bold leading-none"
            [class]="getComponent() === 'Dashboard' || getComponent() === 'DashboardV2' ? 'visible' : 'invisible'" @fadeInOut><span></span>Welcome{{
        !!onBehalfOfUser ? ', ' + onBehalfOfUser.FirstName : !!currentUser ? ', ' + currentUser.FirstName : ''}}!</h2>
        <div class="flex flex-row items-center justify-center border border-solid rounded border-gray-lighter" @fadeIn>
            <button mat-button class="flex-auto pr-20 pl-15 button" attr.aria-label="Dashboard view"
                [class]="getComponent() === 'Dashboard' ? 'is-primary' : 'is-inverted'"
                (click)="selectedTab = 'Dashboard'" routerLink="/Portal/Dashboard"
                amplitudeEvent="Home Button - Dashboard">
                <span class="flex items-center justify-center">
                    <span class="mr-10 material-icons mi-18">dashboard</span>
                    <span>Dashboard</span>
                </span>
            </button>
            <button mat-button class="flex-auto pr-20 pl-15 button" attr.aria-label="Explore FIG Platforms"
                [class]="getComponent().includes('FigPlatform') ? 'is-primary' : 'is-inverted'"
                (click)="selectedTab = 'Overview'" routerLink="/Portal/FigPlatform"
                amplitudeEvent="Home Button - Explore">
                <span class="flex items-center justify-center">
                    <span class="mr-10 material-icons mi-18">map</span>
                    <span>Explore</span>
                </span>
            </button>
        </div>
    </div>
    <div class="flex-grow" id="dashboard-container-content" #dashboardContainerContent>
        <router-outlet (activate)="onActivate($event)"></router-outlet>
    </div>
</section>