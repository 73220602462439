import * as _ from 'lodash';
import { AgentYearlyProduction } from 'src/app/shared/models/agent.models';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { fadeIn } from 'src/app/animations';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { ReportsApiService } from 'src/app/core/services/http/reports-api.service';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { ThemePalette } from '@angular/material/core';
import { UserApiService } from 'src/app/core/services/http/user-api.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-dashboard-yearly-production',
  templateUrl: './dashboard-yearly-production.component.html',
  animations: [fadeIn]
})
export class DashboardYearlyProductionComponent implements OnInit {
  color: ThemePalette = 'accent'; //TODO: figure out how to make gray-light bg color
  currentYear!: number;
  currentYearProduction!: AgentYearlyProduction;
  @Output() loaded: EventEmitter<any> = new EventEmitter();
  loading: boolean = false;
  mode: ProgressSpinnerMode = 'determinate';
  pastYear!: number;
  pastYearProduction!: AgentYearlyProduction;
  thresholds: Threshold[] = [
    { abbreviation: '', lowerBound: null, upperBound: 1000 },
    { abbreviation: 'K', lowerBound: 1000, upperBound: 1000000 },
    { abbreviation: 'M', lowerBound: 1000000, upperBound: 1000000000 },
    { abbreviation: 'B', lowerBound: 1000000000, upperBound: 1000000000000 },
    { abbreviation: 'T', lowerBound: 1000000000000, upperBound: null }
  ];
  value: number = 0;

  constructor(
    private reportsApiService: ReportsApiService,
    private sessionStore: SessionStoreService,
    private userApiService:UserApiService) { }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.pastYear = this.currentYear - 1;

    this.canGetYearlyProduction().subscribe(res => {
      if (res) this.reportsApiService.getAgentYearlyProduction(this.sessionStore.User.AgentArcGuid!)
        .subscribe(res => {
          this.configureYearlyProduction(res);
        },
        (error) => {
          console.log(error);
          this.configureYearlyProduction();
        });
      else this.configureYearlyProduction();
    });
  }

  canGetYearlyProduction(){
    if (this.sessionStore.User.AgentArcGuid){
      if (this.sessionStore.OnBehalfOfUser){
        return this.userApiService.getIsArcUser();
      } else {
        return of(true);
      }
    } else {
      return of(false);
    }
  }

  configureYearlyProduction(production?: AgentYearlyProduction[]) {
    if (production) {
      const currentYearProduction = production.find(x => x.Year == this.currentYear);
      this.currentYearProduction = currentYearProduction ? currentYearProduction : { Year: this.currentYear, AgentId: 0, Premium: 0 };

      const pastYearProduction = production.find(x => x.Year == this.pastYear);
      this.pastYearProduction = pastYearProduction ? pastYearProduction : { Year: this.pastYear, AgentId: 0, Premium: 0 };
    } else {
      this.currentYearProduction = { Year: this.currentYear, AgentId: 0, Premium: 0 };
      this.pastYearProduction = { Year: this.pastYear, AgentId: 0, Premium: 0 };
    }
    const percentage =  Math.floor(this.currentYearProduction.Premium * 100 / this.pastYearProduction.Premium);
    this.value = this.currentYearProduction && !!this.currentYearProduction.Premium ? percentage : 0;
    this.loaded.emit();
  }

  formatTotal(num:number):string {
    if (!num) return '0';
    const threshold = _.find(this.thresholds, function (t) {
      return (!t.lowerBound || t.lowerBound <= num) && (!t.upperBound || t.upperBound > num);
    });
    const preFormat = !!threshold?.lowerBound ? num / threshold.lowerBound : num;
    return preFormat.toLocaleString(undefined, { maximumFractionDigits: 2 }) + threshold?.abbreviation;
  }
}

export interface Threshold {
  abbreviation?: string,
  lowerBound: number | null,
  upperBound: number | null
}
