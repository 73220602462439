import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-simon-lifecycle-policy-list',
  templateUrl: './simon-lifecycle-policy-list.component.html',
})
export class SimonLifecyclePolicyListComponent {
  reportType: string = '';
  simonLifeCyclePolicyIframeUrl: string = 'https://www.simon.figmarketing.com/simon/#/annuities/lifecycle-portal/Owners/Owners';

  constructor(
    private titleService: Title,
    private activatedRoute: ActivatedRoute) {
    this.titleService.setTitle(activatedRoute.snapshot.data.title + ' | Agent Portal');
  }

  updateReportType() {
    this.reportType = this.activatedRoute.snapshot.data['reportType'];
  }
}