<section class="flex flex-col w-full h-full">
    <h1 class="my-20 text-xl font-bold">Forms</h1>
    <section class="my-20">
        <h1 class="mb-20 text-lg font-bold">Reactive Form</h1>
        <p class="mb-20">This method below splits our form into 2 groups - personal & contact, each has its own form
            control methods & props to fine-tune the form's behavior. While this is useful for more complex forms, I
            suggest we keep a single form group when we can with no nested groups to make the controls simpler.</p>
        <form [formGroup]="myForm" (ngSubmit)="onSubmit()" class="flex flex-col">
            <!-- <mat-checkbox class="mb-20 checkbox" [checked]="hasValidations"
                (change)="toggleValidations($event.checked)">
                {{hasValidations ? 'Form validation active' : 'Enable form validation'}}</mat-checkbox> -->
            <mat-slide-toggle class="mb-20" [checked]="hasValidations" (change)="toggleValidations($event.checked)">Toggle form validation</mat-slide-toggle>
            <fieldset formGroupName="personal">
                <legend class="mb-20">Personal:</legend>
                <div class="flex flex-col h-96">
                    <label for="firstName" class="mb-10 text-sm font-medium text-gray">First
                        Name</label>
                    <input formControlName="firstName" type="text" class="input" placeholder="Ex. Joane" />
                    <div *ngIf="this.myForm['controls'].personal['controls'].firstName.touched && this.myForm['controls'].personal['controls'].firstName.invalid"
                        class="mt-5 text-sm text-red">
                        <div *ngIf="this.myForm['controls'].personal['controls'].firstName.errors?.required">
                            Please enter a value</div>
                        <div *ngIf="this.myForm['controls'].personal['controls'].firstName.errors?.minlength">
                            Please enter a longer value</div>
                    </div>
                </div>
                <div class="flex flex-col h-96">
                    <label for="lastName" class="mb-10 text-sm font-medium text-gray required-label">Last
                        Name</label>
                    <input formControlName="lastName" type="text" class="input" placeholder="Ex. Appleseed" />
                    <div *ngIf="this.myForm.controls.personal.controls.lastName.touched && this.myForm.controls.personal.controls.lastName.invalid"
                        class="mt-5 text-sm text-red">
                        <div *ngIf="this.myForm.controls.personal.controls.lastName.errors?.required">
                            Please enter a value</div>
                        <div *ngIf="this.myForm.controls.personal.controls.lastName.errors?.minlength">
                            Please enter a longer value</div>
                    </div>
                </div>
                <div class="flex flex-col h-96">
                    <label for="dob" class="mb-10 text-sm font-medium text-gray required-label">Date of
                        Birth</label>
                    <div class="flex items-center">
                        <input formControlName="dob" class="flex-grow mr-10 input" [matDatepicker]="dobPicker"
                            placeholder="mm/dd/yyyy" (click)='dobPicker.open()'>
                        <mat-datepicker-toggle matSuffix [for]="dobPicker"></mat-datepicker-toggle>
                        <mat-datepicker #dobPicker></mat-datepicker>
                    </div>
                    <div *ngIf="this.myForm.controls.personal.controls.dob.touched && this.myForm.controls.personal.controls.dob.invalid"
                        class="mt-5 text-sm text-red">
                        <div *ngIf="this.myForm.controls.personal.controls.dob.errors?.required">
                            Please enter a value</div>
                        <div *ngIf="this.myForm.controls.personal.controls.dob.errors?.pattern">
                            Please enter a proper date value</div>
                    </div>
                </div>
            </fieldset>
            <fieldset formGroupName="contact">
                <legend class="mb-20">Contact:</legend>
                <div class="flex flex-col h-96">
                    <label for="phone" class="mb-10 text-sm font-medium text-gray required-label">Phone
                        Number</label>
                    <input formControlName="phone" type="text" class="input" placeholder="123-456-7890" />
                    <div *ngIf="this.myForm.controls.contact.controls.phone.touched && this.myForm.controls.contact.controls.phone.invalid"
                        class="mt-5 text-sm text-red">
                        <div *ngIf="this.myForm.controls.contact.controls.phone.errors?.required">
                            Please enter a value</div>
                        <div *ngIf="this.myForm.controls.contact.controls.phone.errors?.pattern">
                            Please enter a valid phone value</div>
                    </div>
                </div>
                <div class="flex flex-col h-96">
                    <label for="email" class="mb-10 text-sm font-medium text-gray required-label">Email
                        Address</label>
                    <input formControlName="email" type="text" class="input" placeholder="123-456-7890" />
                    <div *ngIf="this.myForm.controls.contact.controls.email.touched && this.myForm.controls.contact.controls.email.invalid"
                        class="mt-5 text-sm text-red">
                        <div *ngIf="this.myForm.controls.contact.controls.email.errors?.required">
                            Please enter a value</div>
                        <div *ngIf="this.myForm.controls.contact.controls.email.errors?.email">
                            Please enter a valid email value</div>
                    </div>
                </div>
            </fieldset>
            <div class="flex flex-col h-96">
                <label for="message" class="mb-10 text-sm font-medium text-gray">Message</label>
                <textarea formControlName="message" type="text" class="textarea" rows="6"
                    placeholder="Write whatever here"></textarea>
            </div>
            <div class="flex items-center mt-40 justify-space-between">
                <button type="submit" class="m-10 ml-auto button is-primary is-lg" mat-button>Send Form</button>
            </div>
        </form>
    </section>
</section>