<div class="flex flex-col h-full overflow-auto" @fadeIn>
    <div class="flex flex-col flex-none w-full bg-center bg-no-repeat bg-cover h-300"
        style="background-image: url('./assets/images/fig-building-2.jpg');">
        <img src="./assets/images/wave-sm.svg" class="w-full h-auto mt-auto" />
    </div>
    <!-- <img class="flex flex-grow-0 w-auto h-300" src="./assets/images/fig-building-2.jpg" /> -->
    <div class="flex flex-col items-center justify-center flex-grow p-20 flex-shrink-0">
        <!-- <img class="mb-20 w-96" src="{{logo}}" *ngIf="logo" /> -->
        <h2 class="mb-20 text-lg font-bold" *ngIf="!alternateView">Welcome to the FIG Family {{user.FirstName}}!</h2>
        <h2 class="mb-20 text-lg font-bold" *ngIf="alternateView">We need more information from you.</h2>
        <p class="mb-10 text-base leading-10 text-center lg:mb-20 w-384">
            Follow the easy on-screen instructions to get fully integrated and ready to explore the entire platform.
        </p>
        <button mat-button class="m-10 button is-lg is-primary" (click)="goToNext()"><span>Get Started</span></button>
    </div>
</div>