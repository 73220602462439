<mat-sidenav-container class="relative flex flex-grow h-full overflow-hidden bg-white">
    <mat-sidenav #businessSidenav [mode]="sidenavConfig.Mode" [opened]="sidenavConfig.IsOpened" class="h-full bg-white"
        position="end">
        <div class="flex flex-col p-20 border-b border-solid border-gray-lighter"
            *ngIf="currentFilters && filteredPolicies.length > 0 ">
            <div class="flex flex-row items-center justify-start w-full mb-10 text-sm">
                <span class="mr-5">Policies:</span>
                <span class="font-semibold">{{policyCount}}</span>
            </div>
            <div class="flex flex-col" *ngIf="currentFilters.SelectedLobIds.includes(1)">
                <div class="flex flex-row items-center justify-start w-full mb-10 text-sm">
                    <span class="mr-5">Avg. Premium:</span>
                    <span class="font-semibold">{{averagePremium | currency}}</span>
                </div>
                <div class="flex flex-row items-center justify-start w-full mb-10 text-sm">
                    <span class="mr-5">Total Premium:</span>
                    <span class="font-semibold">{{totalPremium | currency}}</span>
                </div>
            </div>
            <div *ngIf="reportType === 'Fixed'" class="flex flex-row items-center justify-start w-full mb-10 text-sm">
                <span class="mr-5">Total CPCs:</span>
                <span class="font-semibold">{{cpcTotal}}</span>
            </div>
        </div>
        <app-policy-list-sidebar [reportType]="reportType" (filterChangeEvent)="onFilterChange($event)" [isLifeCycle]="true">
        </app-policy-list-sidebar>

        <div class="flex m-10 mt-20 md:hidden">
            <button mat-buttopn class="flex-grow mx-auto button is-primary is-outlined"
                (click)="toggleNav(businessSidenav)">Done</button>
        </div>
    </mat-sidenav>
    <mat-sidenav-content class="flex flex-col flex-grow h-full">
        <section class="flex flex-col flex-grow m-30">
            <app-inforce-policy-table [inforcePolicies]="inforcePolicies" [selectedAgentIds]="this.currentFilters.SelectedAgentIds" class="flex flex-col flex-grow"></app-inforce-policy-table>
            <div class="flex-grow w-full loader-wrap" *ngIf="isLoading" @fadeIn>
                <div class="loader-sm is-blue"></div>
            </div>
        </section>
    </mat-sidenav-content>
</mat-sidenav-container>
