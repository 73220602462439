<!-- <div *ngIf="!!order">
  
  <p>Your Order Payment Has Been {{order.Complete ? 'Approved' : 'Declined'}}</p>
</div>
<div *ngIf="!order">
  <p>Order Not Found</p>
</div>
<div *ngIf="!!order && order.Complete">
  <p>Order Description: {{order.Description}}</p>
  <p *ngIf="order.AdditionalInfo.NumberOfSeats">Number of Seats Purchased: {{order.AdditionalInfo.NumberOfSeats}}</p>
  <p>Order Id: {{order.Id}}</p>
  <p *ngIf="order.PaymentAmount > 0">Total Charged To Card: {{order.PaymentAmount | currency}}</p>
  <p *ngIf="order.MarketingDollarsUsed > 0">Marketing Dollars Used: {{order.MarketingDollarsUsed | currency}}</p>
  <p *ngIf="!!order.CompletedDate">Purchase Date: {{order.CompletedDate | date}}</p>
</div>
<div *ngIf="!!order && order.MarketingStrategyDocusignUrl">
  <button (click)="openDocusignLink()">
    Pleas Sign Docusign Agreement
  </button>
</div> -->


<section class="flex flex-col w-full h-full">
  <section class="flex flex-col m-auto overflow-hidden card xs:w-640">
      <!--Figure out to display logo: order.MarketingStrategyImage-->
    <div class="p-20" [class]="!!order && order.Complete ? 'bg-blue-dark' : 'bg-orange'">
      <h2 class="m-10 text-lg font-bold text-white uppercase">
        <span *ngIf="!!order">
          Your Order Payment Has Been {{order.Complete ? 'Approved' : 'Declined'}}
        </span>
      </h2>
    </div>
    <div *ngIf="!!order && order.Complete" class="flex flex-wrap m-20">
      <div class="flex flex-col w-full p-10 xs:pr-20">
        <label class="mb-10 text-sm text-gray">Order ID</label>
        <p class="font-bold">{{order.Id}}</p>
      </div>
      <div class="flex flex-col w-full p-10 xs:pr-30 xs:w-1/2">
        <label class="mb-10 text-sm text-gray">Order Description</label>
        <p class="font-bold">{{order.Description}}</p>
      </div>
      <div class="flex flex-col p-10 xs:pr-30 xs:w-1/2" *ngIf="order.AdditionalInfo.NumberOfSeats">
        <label class="mb-10 text-sm text-gray">Number of Seats Purchased</label>
        <p class="font-bold">{{order.AdditionalInfo.NumberOfSeats}}</p>
      </div>
      <div class="flex flex-col w-full p-10 xs:pr-30 xs:w-1/2">
        <label class="mb-10 text-sm text-gray">Order Date</label>
        <p class="font-bold">{{order.CompletedDate | date}}</p>
      </div>
      <div class="flex flex-col w-full p-10 xs:pr-30 xs:w-1/2" *ngIf="order.PaymentAmount > 0">
        <label class="mb-10 text-sm text-gray">Total Charged to Card</label>
        <p class="text-lg font-bold">{{order.PaymentAmount | currency}}</p>
      </div>
      <div class="flex flex-col w-full p-10 xs:pr-30 xs:w-1/2" *ngIf="order.MarketingDollarsUsed">
        <label class="mb-10 text-sm text-gray">Marketing Dollars Used</label>
        <p class="text-lg font-bold">{{order.MarketingDollarsUsed | currency}}</p>
      </div>
    </div>
    <div *ngIf="!!order && order.MarketingStrategyDocusignUrl" class="flex p-20">
      <button (click)="openDocusignLink()" mat-button class="m-10 ml-auto button is-primary is-lg is-outlined">
        Please Sign The Docusign Agreement
      </button>
    </div>
  </section>
</section>