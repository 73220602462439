import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { orderBy, sortBy, uniqBy } from 'lodash';
import { EAppService } from 'src/app/core/services/e-app.service';
import { CansellApiService } from 'src/app/core/services/http/cansell-api.service';
import { ProductsApiService } from 'src/app/core/services/http/products-api.service';
import { SsoService } from 'src/app/core/services/sso.service';
import { EAppWorkflowStoreService } from 'src/app/core/services/stores/e-app-workflow-store.service';
import { SubmitPaperApplicationComponent } from 'src/app/portal/modals/submit-paper-application/submit-paper-application.component';
import { policyLobs } from 'src/app/shared/constants/policyList.constants';
import { statesPlusDCAndVI } from 'src/app/shared/constants/states.constants';
import { canParseToNumber } from 'src/app/shared/helpers/canParseToNumber.helper';
import { CanSellModel, CanSellReasonType, CanSellResponse, CarrierStateAppointmentType } from 'src/app/shared/models/cansell.models';
import { ExternalVendorProduct, ExternalVendorCarrier, EAppErrorType } from 'src/app/shared/models/eapps.models';
import { StateBasicModel } from 'src/app/shared/models/generic.models';
import { fadeIn, fadeInOut, routerTransition } from 'src/app/animations';
import { PermissionService } from 'src/app/core/services/auth/permission.service';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { EAppPreferenceEnum } from 'src/app/shared/models/user-settings.model';
import { EAppPreferenceSelectorComponent } from '../../../modals/e-app-preference-selector/eapp-preference-selector.component';
import { CreateActivityBasicResponse } from 'src/app/shared/models/firelightApi.models';

@Component({
  animations: [fadeIn, fadeInOut, routerTransition],
  selector: 'app-prepare-e-application',
  templateUrl: './prepare-e-application.component.html',
})
export class PrepareEApplicationComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private canSellApiService: CansellApiService,
    private dialog: MatDialog,
    private productsApiService: ProductsApiService,
    public eAppService: EAppService,
    private eAppStore: EAppWorkflowStoreService,
    private permissionService:PermissionService,
    private router: Router,
    private ssoService: SsoService,
    public sessionStore:SessionStoreService
  ) { }

  allProducts: ExternalVendorProduct[] = [];
  applicationName!: string;
  canSellModel!: CanSellModel|null;
  carrierStateAppointmentType = CarrierStateAppointmentType;
  clientFirstName!: string;
  clientLastName!: string;
  dolMessage = 'Additional requirements may be needed per the DOL PTE 84-24 Rule. ';
  errorMessage!: EAppErrorType|string|null;
  firelightId = 1;
  filteredCarriers: ExternalVendorCarrier[] = [];
  filteredProducts: ExternalVendorProduct[] = [];
  hasFirelightPermission = this.permissionService.hasOnePermission('FireLight:Access');
  hasIPipelinePermission = this.permissionService.hasOnePermission('IpipelineEApps:Access');
  isLoadingProducts: boolean = true;
  isMappingApplication!: boolean;
  isMissingTraining!: boolean;
  iPipelineId = 4;
  isNew = true;
  isRunningCanSellCheck!: boolean;
  isStartingApplication!: boolean;
  linesOfBusiness = policyLobs.map(lob => {
    return { Id: lob.Id, Name: lob.Name };
  });
  productTrainingSupported: boolean = false;
  queryActivityId!: string | null;
  queryCusip!: string | null;
  queryLobId!: string | null;
  queryState!: string | null;
  selectedCarrier!: ExternalVendorCarrier | null;
  selectedLobId!: number;
  selectedProduct!: ExternalVendorProduct | null;
  selectedState!: StateBasicModel | null;
  states: StateBasicModel[] = statesPlusDCAndVI;
  unableToRunCanSellCheck!: boolean;
  eAppPreferenceEnum = EAppPreferenceEnum;

  ngOnInit(): void {
    this.queryActivityId = this.activatedRoute.snapshot.queryParamMap.get('activityId');
    this.queryState = this.activatedRoute.snapshot.queryParamMap.get('state');
    this.queryCusip = this.activatedRoute.snapshot.queryParamMap.get('cusip');
    this.queryLobId = this.activatedRoute.snapshot.queryParamMap.get('lobId');
    this.isNew = this.activatedRoute.snapshot.queryParamMap.get('isNew') === 'false' ? false : true;
    if (this.queryLobId && canParseToNumber(this.queryLobId)) this.selectedLobId = Number(this.queryLobId);


    this.productsApiService.getPermittedProducts().subscribe({
      next: (products:ExternalVendorProduct[]) => {
        this.allProducts = products;
        if (this.selectedLobId) {
          this.filterCarrierList();
        }
      },
      error: () => this.errorMessage = EAppErrorType.UnableToRetrieveProductsError,
      complete: () => {
        if (this.queryActivityId) this.mapExistingApplication();
      }
    }).add(() => this.isLoadingProducts = false);
  }

  activateFirelightSso(): void {
    this.ssoService.firelightSso();
  }

  filterByLob(lobId: number):ExternalVendorProduct[]{
    const result: ExternalVendorProduct[] = _.filter(this.allProducts, product => {
      return product.LineOfBusinessID === lobId;
    });
    return result;
  }

  filterCarrierList(): void {
    if (this.selectedLobId) {
      const filteredCarriers = this.allProducts
        .filter(product => product.LineOfBusinessID === this.selectedLobId)
        .map(product => {
          return { Business: product.Business, CarrierCode: product.CarrierCode };
        });

      this.filteredCarriers = sortBy(uniqBy(filteredCarriers, 'Business'), 'Business');
    }
  }

  filterProducts(): void {
    let filteredProducts: ExternalVendorProduct[] = [];

    if (this.selectedState && this.selectedCarrier) {
      filteredProducts = this.allProducts.filter(p => p.Business == this.selectedCarrier!.Business && p.LineOfBusinessID == this.selectedLobId && p.States.map(x => x.StateAbbrev).includes(this.selectedState!.ShortName));
    } else if (this.selectedCarrier) {
      filteredProducts = this.allProducts.filter(p => p.Business == this.selectedCarrier!.Business && p.LineOfBusinessID == this.selectedLobId);
    }

    this.filteredProducts = orderBy(filteredProducts, 'Product');
  }

  goToTraining(): void {
    if (this.selectedProduct && this.selectedState && (this.queryActivityId || (this.clientFirstName && this.clientLastName))) {

      // Store data so it can be retrieved after user completes training
      this.eAppStore.clear();
      this.eAppStore.setAll(this.selectedProduct, this.selectedState, this.clientFirstName, this.clientLastName, this.queryActivityId, this.isNew);
      this.router.navigate(['Portal/ProductRD/EApplication/Workflow/WorkflowProductTraining'], { queryParams: { searchValue: this.selectedProduct.ProductCusip! } });
    } else {
      if ((!this.clientLastName || !this.clientFirstName) && !this.queryActivityId) this.errorMessage = EAppErrorType.ClientInfoMissingError;
      else this.errorMessage = EAppErrorType.TrainingUnavailableError;
    }
  }

  mapExistingApplication(): void {
    this.isMappingApplication = true;
    if (this.queryCusip && this.queryState) {
      this.setValues(this.queryCusip, undefined, this.queryState);
    }
    this.runCanSellCheck();
    this.isMappingApplication = false;
  }

  needsTraining(canSellResponse: CanSellModel): boolean {
    const reasons = canSellResponse.Reasons.map(x => x.Reason);
    if (reasons.indexOf(CanSellReasonType.Training) > -1) return true;
    else return false;
  }

  openPaperApplication(): void {
    this.dialog.open(SubmitPaperApplicationComponent, {
      data: {
        ClientFirstName: this.clientFirstName,
        ClientLastName: this.clientLastName,
        Cusip: this.selectedProduct?.ProductCusip,
        StateAbbreviation: this.selectedState?.ShortName
      },
      height: '84rem',
      width: '64rem',
    });
  }

  resetForm(): void {
    this.router.navigateByUrl('Portal', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl('Portal/ProductRD/EApplication/Workflow/PrepareEApplication');
    });
  }

  runCanSellCheck(): void {
    if (this.canRunCanSellCheck()) {
      this.isRunningCanSellCheck = true;
      this.errorMessage = '';
      this.unableToRunCanSellCheck = false;

      this.canSellApiService.runCanSellCheck(this.selectedState!.ShortName, this.selectedProduct!.ProductCusip!)
        .subscribe({
          next: (canSellResponse:CanSellResponse) => {
            if (canSellResponse.CanSellModel){
              this.canSellModel = canSellResponse.CanSellModel;
              this.productTrainingSupported = this.canSellModel.ProductTrainingSupported;
              if (this.needsTraining(this.canSellModel)) this.isMissingTraining = true;
            } else {
              this.unableToRunCanSellCheck = true;
              this.errorMessage = canSellResponse.ErrorMessage;
            }
          },
          error: (error) => {
            console.log(error);
            this.generateCanSellError();
          }})
        .add(() => this.isRunningCanSellCheck = false);
    } else {
      this.generateCanSellError();
    }
  }

  canRunCanSellCheck(): boolean {
    return !!this.selectedProduct?.ProductCusip && this.selectedProduct?.ExternalVendorID == this.firelightId && this.selectedProduct.LineOfBusinessID != 4; //LOB: 4 = Life
  }

  generateCanSellError(): void{
    this.isRunningCanSellCheck = false;
    this.unableToRunCanSellCheck = true;
    // Order of these if statements is important, some times an error can fit multiple cases, but one has priority over the other
    if (!this.selectedProduct?.ProductCusip && !this.queryActivityId) this.errorMessage = EAppErrorType.ProductNotAvailableForEappSubmissionError;
    else if (this.selectedProduct?.ExternalVendorID != this.firelightId || this.selectedProduct.LineOfBusinessID == 4) this.errorMessage = null;
    else this.errorMessage = EAppErrorType.UnableToRunCanSellCheckError;
  }

  setValues(cusip: string | undefined, stateCode?: string | undefined, stateAbbreviation?: string): void {
    let product: ExternalVendorProduct | null = null;
    if (cusip) product = this.allProducts.find(product => product.ProductCusip === cusip) ?? null;
    if (product) {
      this.selectedLobId = product.LineOfBusinessID;
      this.filterCarrierList();
      this.selectedCarrier = this.filteredCarriers.find(x => x.CarrierCode == product?.CarrierCode && x.Business == product?.Business) ?? null;
      this.filterProducts();
      this.selectedProduct = this.filteredProducts.find(x => x.ProductCusip === product?.ProductCusip) ?? null;
    }
    if (this.selectedProduct && stateCode) {
      const productState = this.selectedProduct.States.find(x => x.JurisdictionId.toString() === stateCode);
      this.selectedState = this.states.find(state => state.ShortName == productState?.StateAbbrev) ?? null;
    }
    if (this.selectedProduct && stateAbbreviation) {
      this.selectedState = this.states.find(state => state.ShortName.toLowerCase() === stateAbbreviation.toLowerCase()) ?? null;
    }
  }

  startEApplication(): void {
    if (!this.clientFirstName || !this.clientLastName) {
      this.errorMessage = EAppErrorType.ClientInfoMissingError; return;
    }
    if (this.isFirelightProduct()) this.startFirelightApplication();
    else if (this.isIPipelineProduct()) this.submitIPipelineEApplication();
    else this.errorMessage = EAppErrorType.ProductNotAvailableForEappSubmissionError;
  }


  startFirelightApplication(): void {
    if (this.noEAppPreferenceSelected()) {
      this.openEAppPreferenceSelectorModal()
        .afterClosed()
        .subscribe({
          next: (selection:EAppPreferenceEnum) => {
            if (selection) this.submitFirelightEApplication();
            else return;
          }});
    } else {
      this.submitFirelightEApplication();
    }
  }

  submitFirelightEApplication(): void {
    if (this.selectedProduct && this.selectedState && this.clientFirstName && this.clientLastName && this.selectedCarrier) {
      this.isStartingApplication = true;
      this.eAppService.startFirelightEApplication(this.selectedProduct, this.selectedState, this.clientFirstName, this.clientLastName)
        .subscribe({
          next: (activity:CreateActivityBasicResponse) => {
            this.isStartingApplication = false;
            this.eAppService.goToFirelight(activity.ActivityId, {isNew: true});
          },
          error: () => this.errorMessage = EAppErrorType.StartApplicationError
        })
        .add(() => this.isStartingApplication = false);
    }
  }

  submitIPipelineEApplication(): void {
    if (this.selectedState && this.selectedProduct) {
      this.eAppService.startiPipelineEApplication(this.selectedState!.ShortName, this.selectedProduct!.ProductCusip!, this.selectedProduct!.ExternalVendorProductTypeID!.toString());
    } else {
      this.errorMessage = EAppErrorType.StartApplicationError;
    }
  }

  isFirelightProduct(): boolean {
    return (this.selectedProduct?.ExternalVendorID == this.firelightId) && (!!this.selectedProduct.ProductCusip);
  }

  isIPipelineProduct(): boolean {
    return (this.selectedProduct?.ExternalVendorID == this.iPipelineId) && (!!this.selectedProduct.ProductCusip);
  }

  noEAppPreferenceSelected(): boolean{
    return this.sessionStore.UserSettings?.EAppPreference === EAppPreferenceEnum.None;
  }

  openEAppPreferenceSelectorModal(): MatDialogRef<EAppPreferenceSelectorComponent>{
    const dialog = this.dialog.open<EAppPreferenceSelectorComponent>(EAppPreferenceSelectorComponent);
    return dialog;
  }
}