import { Component, OnInit } from '@angular/core';
import { fadeIn } from 'src/app/animations';
import { PermissionService } from 'src/app/core/services/auth/permission.service';
import { ReportsApiService } from 'src/app/core/services/http/reports-api.service';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { AgentBusinessSummary } from 'src/app/shared/models/agent.models';

@Component({
  templateUrl: './dashboard-business-summary-v2.component.html',
  selector: 'app-dashboard-business-summary-v2',
  animations: [fadeIn]
})

export class DashboardBusinessSummaryV2Component implements OnInit {
  businessSummary: AgentBusinessSummary = { ActiveContractCount: 0, PendingPolicyActionNeededCount: 0, IssuedPolicyCount: 0 };
  loading = false;
  hasQuinciPermission = this.permissionService.hasOnePermission('ProductRDOptedIn:View');
  //TODO: When we turn off MyBusiness to users, this will all need to popint to Business V2
  pendingBusinessRoute = this.hasQuinciPermission ? '/Portal/Business/Fixed/Pending' : '/Portal/MyBusiness/PolicyList';
  issuedBusinessRoute = this.hasQuinciPermission ? '/Portal/Business/Fixed/Inforce' : '/Portal/MyBusiness/PolicyList';

  constructor(
    private sessionStoreService: SessionStoreService,
    private reportsApiService: ReportsApiService,
    private permissionService: PermissionService
  ) { }

  ngOnInit(): void {
    this.getBusinessSummaryV2();
  }

  getBusinessSummaryV2(): void {
    this.loading = true;
    if (this.sessionStoreService.User.AgentArcGuid) this.reportsApiService.getAgentBusinessSummary(this.sessionStoreService.User.AgentArcGuid)
      .subscribe({
        next: res => {
          this.businessSummary = res;
        },
        error: () => {
          throw new Error('Method not implemented.');
        }
      }).add(() => {
        this.loading = false;
      });
  }
}