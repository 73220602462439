<div class="flex flex-col w-full h-full p-20 gap-y-10 card">
  <div class="flex items-start justify-between gap-x-20">
    <h2 class="text-lg font-bold">Recent Applications</h2>
    <a class="flex items-center justify-center button is-primary is-inverted is-sm" mat-button
       routerLink="/Portal/ProductRD/EApplication/History">
      <span>View More</span>
      <i class="ml-10 material-icons mi-18">east</i>
    </a>
  </div>

  <ng-container *ngIf="hasAppHistoryPermission">
    <ng-container *ngIf="!loading">
      <table *ngIf="!error.hasError && recentApplications.length" @fadeIn
             class="items-center text-sm border-separate table-auto whitespace-nowrap" style="border-spacing: 0 1rem">
        <tbody>
          <tr class="p-10 transition duration-150 rounded gap-x-20 hover:bg-blue-lightest focus:bg-blue-lightest" *ngFor="let item of recentApplications">
            <td class="p-10 rounded-l">
              <i class="mt-5 material-icons-outlined mi-18 text-gray">description</i>
            </td>
            <td class="p-10 whitespace-normal">
              <p class="flex-grow font-bold">{{item.ActivityName}}</p>
            </td>
            <td class="p-10">
              <p class="text-sm text-gray">{{item.AuditDate | date}}</p>
            </td>
            <td class="p-10 text-center">
              <span class="px-8 py-2 text-xs font-bold text-center text-white uppercase rounded bg-gray min-w-96">{{item.DisplayStatus | splitCamelCase: 0}}</span>
            </td>
            <td class="w-auto p-10 text-right rounded-r">
              <button class="button is-primary is-outlined is-sm" mat-button (click)="goToActivity(item.ActivityId)">Continue Application</button>
            </td>
          </tr>
        </tbody>
      </table>
      <span @fadeIn *ngIf="!error.hasError && !recentApplications.length" class="p-10 my-auto text-sm text-gray">You have no recent pending applications.</span>

      <app-message-notification *ngIf="error.hasError" [panelConfig]="{type: 'error'}">{{error.message}}</app-message-notification>
    </ng-container>
    <div @fadeIn *ngIf="loading" class="relative p-20 m-auto loader-wrap">
      <div class="loader-sm is-blue"></div>
    </div>
  </ng-container>

  <ng-container *ngIf="!hasAppHistoryPermission">
    <app-message-notification>You do not have permissions to view this tile. Please contact our support staff.</app-message-notification>
  </ng-container>

</div>