import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { ReportsApiService } from 'src/app/core/services/http/reports-api.service';
import { Policy, PolicyDetail, PolicyFunding, PolicyStage, PolicySuitability, PolicyTask } from 'src/app/shared/models/policy.models';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { orderBy } from 'lodash';
import { Title } from '@angular/platform-browser';
import { policyStages } from 'src/app/shared/constants/policyList.constants';
import { error } from 'selenium-webdriver';
import { SimonIframeUrl } from 'src/app/shared/models/simon-iframe-url.models';
import { environment } from 'src/environments/environment';
import { PastPolicyListState } from 'src/app/shared/models/pastPolicyListState.model';
import { fadeIn } from 'src/app/animations';
import { SimonApiService } from 'src/app/core/services/http/simon-api.service';

@Component({
  animations: [fadeIn],
  selector: 'app-policy-details',
  templateUrl: './policy-details.component.html',
})
export class PolicyDetailsComponent implements OnInit {
  toggleLocal: boolean = false;
  agentResponseForm: any;
  agentResponses: any[] = [];
  caseStatus!: PolicyTask;
  componentUrl: string = '';
  environment = environment;
  filesToUpload: File[] = [];
  isUploading: boolean = false;
  isVariable: boolean = false;
  iframeUrl!: string
  latestNote: any = {};
  loading: boolean = false;
  orderBy = orderBy;
  policy!: Policy;
  policyDetails!: PolicyDetail;
  policyFundingTotal: FundingModel = { FundingAmountTotal: 0, FundingFullyIssued: false, FundingIssuedTotal: 0, FundingTotal: 0 };
  policyFunding!: PolicyFunding[];
  policyGuid!: string;
  policySuitability!: PolicySuitability;
  resourceUrl: string = 'Case/Policy Details';
  selectedTab: string = 'PolicyDetails'
  showAgentResponses: boolean = false;
  showSimonView: boolean = false;
  showLocalSimon!: boolean;
  simonPolicyNumber!: string | null;
  today: Date = new Date();
  pastPolicyListState!: PastPolicyListState | null;
  currentPolicyStage!: string | null;
  currentPolicyStageDate!: Date | null ;
  simonPolicyUrl!: string | null;

  get formMessage() {
    return this.agentResponseForm.get('message');
  }

  get fileArray() {
    return this.agentResponseForm.get('fileArray');
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private reportsApiService: ReportsApiService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private titleService: Title,
    private simonApiService: SimonApiService,
  ) {
    this.pastPolicyListState = this.router.getCurrentNavigation()?.extras?.state?.PastPolicyListState;
  }

  ngOnInit(): void {
    this.loading = true;
    this.activatedRoute.queryParamMap
      .subscribe(
        (params) => {
          this.showSimonView = params.get('showSimonView') === 'true' ? true : false;
          this.simonPolicyUrl = params.get('simonUrl') ? decodeURIComponent(params.get('simonUrl') as string) : null;
          //testing for Sahat
          this.simonPolicyNumber = params.get('policyNumber');
          this.policyGuid = this.activatedRoute.snapshot.paramMap.get('policyGuid')!;
          this.getPolicyDetails();
          this.componentUrl = this.getComponent();
          if (this.showSimonView) this.openSimonSso(this.componentUrl);
        }, (error) => console.log(error));
  }

  filterStageById(stageId: number): PolicyStage | null {
    const stage = this.policyDetails.StageHistory.filter(row => row.PolicyStageId === stageId);
    if (stage.length > 0) return stage[0];
    return null;
  }

  getComponent() {
    const homeUrl: string = '/Portal/Business/';
    const path: string[] = window.location.pathname.split(homeUrl);
    return path[1];
  }

  getPolicyDetails() {
    if (this.policyGuid) {
      this.reportsApiService.getIsVariablePolicy(this.policyGuid)
        .subscribe(isVariable => {
          this.isVariable = isVariable;
          let policyDetailsHttp: Observable<PolicyDetail>;
          if (isVariable) policyDetailsHttp = this.reportsApiService.getVariablePolicyDetailsByGuid(this.policyGuid);
          else policyDetailsHttp = this.reportsApiService.getPolicyDetailsByGuid(this.policyGuid);

          forkJoin({ policyDetails: policyDetailsHttp, fundings: this.reportsApiService.getPolicyFundingByGuid(this.policyGuid), suitability: this.reportsApiService.getPolicySuitabilityStatusByGuid(this.policyGuid) })
            .subscribe(
              (res) => {
                this.policyDetails = res.policyDetails;
                this.currentPolicyStage = this.policyDetails.StageHistory.length > 0 ? this.policyDetails.StageHistory[0].PolicyStage! : null;
                this.currentPolicyStageDate = this.policyDetails.StageHistory.length > 0 ? this.policyDetails.StageHistory[0].PolicyStageDate! : null;
                this.policyDetails.StageHistoryObject = this.getStageHistory();
                this.policyFunding = res.fundings;
                this.policySuitability = res.suitability;
                this.policyFunding.forEach(funding => {
                  funding.IssuedPremiumAmount = funding.IssuedPremiumAmount ? funding.IssuedPremiumAmount : 0;
                  funding.IsFullyIssued = funding.Amount ? false : true;
                });
                if (this.simonPolicyUrl === null && this.policyDetails.PolicyNumber !== null) {
                  this.simonApiService.getAnnuityHolding(this.policyDetails.PolicyNumber).subscribe(p => {
                    this.simonPolicyUrl = p.policyDetailsUri;
                  },
                  (error) => { });
                }
                this.loading = false;
              },
              (error) => {
                this.loading = false;
              });
        },
        (error) => console.log(error));
    } else this.loading = false;
  }

  getStageHistory() {
    const stages: any[] = [];
    policyStages.forEach(stage => {
      stages.push(this.filterStageById(stage));
    });

    return stages;
  }

  goBack() {
    window.history.back();
  }

  goBack2(): void {
    if (!this.componentUrl) window.history.back();
    else {
      let simonUrl: string = '';
      this.activatedRoute.queryParamMap.subscribe(params => simonUrl = params.get('simonUrl') as string);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['Portal/Business/' + this.componentUrl], { queryParams: { simonUrl: simonUrl } }));
    }
  }

  // goToPolicyList() {
  //   if (this.pastPolicyListState != null) {
  //     this.router.navigate([this.pastPolicyListState.Url]);
  //   } else {
  //     this.router.navigate(["/Portal/MyBusiness/PolicyList"]);
  //   }
  // }

  openSimonSso(policyUrl?: string) {
    if (this.showLocalSimon) {
      this.iframeUrl = environment.simonLocalUrl;
    } else {
      this.iframeUrl = environment.simonUrl;
    }
    const portalPolicyUrl = policyUrl ? '&policyUrl=' + encodeURIComponent(policyUrl) : '';
    this.iframeUrl += this.simonPolicyUrl + portalPolicyUrl;
  }

  switchView() {
    this.showSimonView = !this.showSimonView;
    if (this.showSimonView) this.openSimonSso(this.componentUrl);
  }

  switchToSimonView() {
    this.showSimonView = true;
    this.openSimonSso(this.componentUrl);
  }
}

interface FundingModel {
  FundingAmountTotal: number | null,
  FundingIssuedTotal: number | null,
  FundingTotal: number | null,
  FundingFullyIssued: boolean | null
}
