import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { sortBy, uniqBy } from 'lodash';
import { forkJoin, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { fadeIn } from 'src/app/animations';
import { PermissionService } from 'src/app/core/services/auth/permission.service';
import { CarrierReportsApiService } from 'src/app/core/services/http/carrier-reports-api.service';
import { ProductsApiService } from 'src/app/core/services/http/products-api.service';
import { MessagePanelConfigModel } from 'src/app/shared/components/message-notification/message-notification.component';
import { CarrierTraining } from 'src/app/shared/models/carrier.models';
import { ExternalVendorCarrier, ExternalVendorProduct } from 'src/app/shared/models/eapps.models';
import { ErrorMessageModel } from 'src/app/shared/models/error-message.model';
import { SimonTrainingStatus } from 'src/app/shared/models/training.models';

@Component({
  templateUrl: './dashboard-product-training.component.html',
  selector: 'app-dashboard-product-training',
  animations: [fadeIn]
})

export class DashboardProductTrainingComponent implements OnInit {
  loading = false;
  formSubmitted = false;
  formSubmitting = false;
  error: ErrorMessageModel = {
    hasError: false,
    message: 'There was a problem retrieving data. Please try again later.'
  };
  messagePanelConfig: MessagePanelConfigModel = {
    type: 'error',
  };
  allProducts: ExternalVendorProduct[] = [];
  allCarriersFromContractsAndTraining: CarrierTraining[] = [];
  filteredCarriersFromEappWorkflow: ExternalVendorProduct[] = [];
  filteredProducts: ExternalVendorProduct[] = [];
  selectedProductTraining?: DashboardProductTraining;
  hasGuidePermissions = this.permissionService.hasAllPermissions('FigTraining:View|MenuResources:View');
  hasQuinciPermission = this.permissionService.hasOnePermission('ProductRDOptedIn:View');
  hasEappPermission = this.permissionService.hasAllPermissions('SubmitPaperApp:View|IpipelineEApps:Access|Firelight:Access');
  carrierChangeSub?: Subscription;
  productTrainingForm = this.fb.group({
    carrier: [null, Validators.required],
    product: new FormControl({
      value: null,
      disabled: true,
    }, [Validators.required])
  }) as ProductTrainingFormGroup;

  constructor(
    private permissionService: PermissionService,
    private carrierReportsApiService: CarrierReportsApiService,
    private productsApiService: ProductsApiService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.getCarrierTrainingList();
    this.onCarrierSelectionChange();
  }

  onCarrierSelectionChange(): void {
    this.carrierChangeSub = this.productTrainingForm.controls.carrier.valueChanges.subscribe({
      next: value => {
        if (value) {
          this.productTrainingForm.controls.product.enable();
          this.setFilteredProductList(value);
        } else {
          this.productTrainingForm.controls.product.disable();
        }
      }
    });
  }

  getCarrierTrainingList(): void {
    this.loading = true;
    forkJoin({
      allCarriers: this.carrierReportsApiService.getCarrierTraining().pipe(catchError(error => {
        throw error;
      })),
      allProducts: this.productsApiService.getPermittedProducts().pipe(catchError(error => {
        throw error;
      }))
    })
      .subscribe({
        next: (res) => {
          this.allCarriersFromContractsAndTraining = res.allCarriers;
          this.allProducts = res.allProducts;
          this.setFilteredCarrierList(res.allProducts);
        },
        error: () => {
          this.error.hasError = true;
        }
      })
      .add(() => {
        this.loading = false;
      });
  }

  setFormToSubmitting(): void {
    this.formSubmitting = true;
    this.formSubmitted = false;
    this.productTrainingForm.disable();
  }

  setFormToSubmitted(): void {
    this.formSubmitting = false;
    this.formSubmitted = true;
    this.productTrainingForm.enable();
  }

  onSubmit(): void {
    if (this.productTrainingForm.invalid) {
      this.productTrainingForm.markAllAsTouched();
      return;
    }
    const annuityLobId = 1;
    const product = this.productTrainingForm.value.product;
    this.setFormToSubmitting();

    // Ideally we would run getProductTrainingStatus() first and determine from there, but the simon call takes a while to complete
    // so I'm splitting the logic here to save load time on unsupported product trainings
    if (product.ProductCusip && product.LineOfBusinessID === annuityLobId && this.hasQuinciPermission) {
      this.getProductTrainingStatus(product, product.ProductCusip);
    } else {
      this.setCarrierTrainingGuide(product);
    }
  }

  getProductTrainingStatus(product: ExternalVendorProduct, cusip: string): void {
    this.productsApiService.getSimonTrainingStatus(cusip).subscribe({
      next: res => {
        if (res) {
          // TODO: This response is slightly flawed where it may return as null if product is not found, rendering this property useless in that case
          // Will either need to res to always match model or remove res.ProductNotFound from it
          switch (res.ProductNotFound) {
            case true:
              this.setCarrierTrainingGuide(product);
              break;

            case false:
              this.setSimonProductTraining(product, res);
              break;

            default:
              this.error.hasError = true;
              this.setFormToSubmitted();
              break;
          }
        } else {
          this.setCarrierTrainingGuide(product);
        }
      },
      error: () => {
        this.error.hasError = true;
        this.setFormToSubmitted();
      }
    });
  }

  setCarrierTrainingGuide(product: ExternalVendorProduct): void {
    const trainingGuide = this.allCarriersFromContractsAndTraining.find(p => p.CarrierId === product?.CarrierID);
    if (trainingGuide) {
      this.selectedProductTraining = {
        hasSimonTraining: false,
        trainingStatus: undefined,
        productName: product?.Product,
        carrierName: product?.Business,
        trainingGuideUrl: trainingGuide.TrainingUrl,
        simonCarrierName: trainingGuide.SimonTitle,
        productCusip: product?.ProductCusip
      };
    } else {
      this.selectedProductTraining = undefined;
    }
    this.setFormToSubmitted();
  }

  setSimonProductTraining(product: ExternalVendorProduct, simonTrainingStatus: SimonTrainingStatus): void {
    this.selectedProductTraining = {
      hasSimonTraining: true,
      trainingStatus: simonTrainingStatus.HasCompletedTraining ? 'Completed' : 'Incomplete',
      productName: product?.Product,
      carrierName: product?.Business,
      productCusip: product?.ProductCusip
    };
    this.setFormToSubmitted();
  }

  setFilteredProductList(carrier?: ExternalVendorProduct): void {
    if (carrier) {
      this.filteredProducts = sortBy( this.allProducts.filter(p => p.Business === carrier.Business), 'Product');
    }
  }

  setFilteredCarrierList(productList: ExternalVendorProduct[]): void {
    this.filteredCarriersFromEappWorkflow = sortBy(uniqBy(productList, 'Business'), 'Business');
  }
}

export interface ProductTrainingFormValueModel {
  carrier: ExternalVendorCarrier;
  product: ExternalVendorProduct;
}

export interface ProductTrainingFormGroup extends FormGroup {
  value: ProductTrainingFormValueModel;
  controls: {
    carrier: FormControl;
    product: FormControl;
  }
}

export interface DashboardProductTraining {
  hasSimonTraining: boolean;
  trainingStatus: 'Completed' | 'Incomplete' | undefined;
  productName?: string;
  carrierName?: string;
  simonCarrierName?: string | null;
  trainingGuideUrl?: string | null;
  productCusip?: string | null;
}