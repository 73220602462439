<section class="grid w-full grid-cols-8 mx-auto max-w-1280 gap-x-20 gap-y-20" @fadeIn>

  <app-dashboard-business-summary-v2 class="col-span-full md:col-span-5 min-h-128"></app-dashboard-business-summary-v2>

  <app-dashboard-production-summary class="col-span-full md:col-span-3 min-h-128"></app-dashboard-production-summary>

  <app-dashboard-recent-aplications class="col-span-full lg:col-span-5 min-h-256"></app-dashboard-recent-aplications>

  <app-dashboard-product-training class="col-span-full lg:col-span-3 min-h-256"></app-dashboard-product-training>

  <app-dashboard-whats-new class="col-span-full lg:col-span-4 min-h-256"></app-dashboard-whats-new>

  <app-dashboard-upcoming-events-v2 class="col-span-full lg:col-span-4 min-h-256"></app-dashboard-upcoming-events-v2>
</section>