import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CpcModel } from 'src/app/shared/models/cpc.models';
import { EntryResponse } from 'src/app/shared/models/generic.models';

@Injectable({
  providedIn: 'root'
})
export class CpcApiService {

  constructor(private http:HttpClient) { }

  getUserCPCs(startDate:Date, endDate:Date):Observable<CpcModel>{
    return this.http.get<EntryResponse<CpcModel>>(`/api/CPCs?start=${startDate.toUTCString()}&end=${endDate.toUTCString()}`)
      .pipe(map((x:EntryResponse<CpcModel>) => x.Entry));
  }
}
