<div class="h-full w-full flex flex-col items-center justify-center flex-grow p-20 text-center bg-blue-lightest">
    <div class="flex flex-col items-center m-10 mx-auto xs:w-384">
        <span class="flex items-center justify-center w-64 h-64 m-10 rounded-full bg-green-lightest text-green">
            <i class="material-icons mi-36">verified</i>
        </span>
        <h2 class="my-10 md:my-20 pb-10 text-lg font-bold">Application is pending for signatures, a new tab will be opened for signing.
        </h2>
        <button mat-button  class="rounded-full button is-primary is-lg mb-10 md:mb-20" routerLink="../PrepareEApplication">
				Start a New Application
			</button>
        <button mat-button  class="my-10 rounded-full button is-primary is-inverted is-outlined is-lg" routerLink="../../History">
            View Application History
			</button>
    </div>
</div>