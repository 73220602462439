<!-- Cansell Form -->
<section class="flex flex-col flex-grow order-2 p-20 overflow-y-auto px-30 md:order-1" [@fadeIn]>
	<div *ngIf="isMappingApplication || isLoadingProducts" class="p-20 m-auto loader-sm is-blue"></div>
	<form *ngIf="!isMappingApplication && !isLoadingProducts" class="flex flex-col" name="firelightApp">
		<div class="flex flex-wrap">
			<div *ngIf="!queryActivityId" class="flex flex-col flex-none w-1/2 p-10 md:pb-20 md:w-full">
				<label class="mb-10 text-sm font-semibold text-gray">Client First Name</label>
				<input [disabled]="!!queryActivityId || isRunningCanSellCheck"
					class="h-40 px-10 border border-solid input is-inverted border-gray-light" type="text"
					name="clientFirstName" [(ngModel)]="clientFirstName" aria-label="clientFirstName" required
					minlength="1" />
			</div>
			<div *ngIf="!queryActivityId" class="flex flex-col flex-none w-1/2 p-10 md:pb-20 md:w-full">
				<label class="mb-10 text-sm font-semibold text-gray">Client Last Name</label>
				<input [disabled]="!!queryActivityId || isRunningCanSellCheck"
					class="h-40 px-10 border border-solid input is-inverted border-gray-light" type="text"
					name="clientLastName" [(ngModel)]="clientLastName" aria-label="Client LastName" required
					minlength="1" />
			</div>
		</div>
		<div class="flex flex-col flex-none m-10 sm:mb-20">
			<label class="mb-10 text-sm font-semibold text-gray">Line Of Business</label>
			<mat-radio-group class="flex flex-wrap"
				[disabled]="!!queryActivityId || isRunningCanSellCheck || isLoadingProducts"
				aria-label="Select an option" [(ngModel)]="selectedLobId"
				(change)="selectedCarrier=null;selectedProduct=null;filterCarrierList();" name="lobId" 
				*ngIf="!!allProducts.length">
				<ng-container *ngFor="let lob of linesOfBusiness; let index = index">
					<mat-radio-button class="pr-40 md:pr-0 md:w-full"
						[ngClass]="{'md:pb-10': index < linesOfBusiness.length - 1}" [value]="lob.Id" required
						*ngIf="!!filterByLob(lob.Id).length">
						{{lob.Name}}</mat-radio-button>
				</ng-container>
			</mat-radio-group>
			<span class="italic text-gray-light" *ngIf="!allProducts.length">No products found.</span>
		</div>
		<div class="flex flex-wrap">
			<div class="flex flex-col flex-none w-1/2 p-10 md:pb-20 md:w-full">
				<label class="mb-10 text-sm font-semibold text-gray">Carrier</label>
				<mat-select
					[disabled]="!selectedLobId || !!queryActivityId || isRunningCanSellCheck || isLoadingProducts"
					(selectionChange)="selectedProduct = null;errorMessage=null;filterProducts()" [(ngModel)]="selectedCarrier"
					name="carrier"
					class="h-40 px-10 m-0 border border-solid select is-inverted md-no-underline border-gray-light"
					required placeholder="Select Carrier" aria-label="Select Carrier">
					<mat-option *ngFor="let c of filteredCarriers" [value]="c" required>
						{{c.Business}}
					</mat-option>
				</mat-select>
			</div>
			<div class="flex flex-col flex-none w-1/2 p-10 md:pb-20 md:w-full">
				<label class="mb-10 text-sm font-semibold text-gray">State</label>
				<mat-select name="state"
					[disabled]="!selectedLobId || !!queryActivityId || isRunningCanSellCheck || isLoadingProducts"
					(selectionChange)="selectedProduct=null;filterProducts();" [(value)]="selectedState"
					class="h-40 px-10 m-0 border border-solid select is-inverted md-no-underline border-gray-light"
					required placeholder="Select State" aria-label="Select State">
					<mat-option *ngFor="let s of states" [value]="s" required>
						{{s.LongName}}
					</mat-option>
				</mat-select>
			</div>
		</div>
		<div class="flex flex-col flex-none m-10 sm:mb-20">
			<label class="mb-10 text-sm font-semibold text-gray">Product</label>
			<mat-select name="product"
				[disabled]="!selectedLobId || !selectedCarrier || !selectedState || !!queryActivityId || isRunningCanSellCheck  || isLoadingProducts"
				(selectionChange)="canSellModel=null;runCanSellCheck();" [(value)]="selectedProduct"
				class="h-40 px-10 m-0 border border-solid select is-inverted md-no-underline border-gray-light" required
				placeholder="Select Product" aria-label="Select Product">
				<mat-option *ngFor="let p of filteredProducts" [value]="p" required>
					{{p.Product}}
				</mat-option>
			</mat-select>
		</div>
	</form>
	<div class=""></div>
	<button class="mt-10 ml-auto mr-10 rounded-full md:mt-20 button is-primary is-inverted is-lg"
		*ngIf="queryActivityId || canSellModel || errorMessage" (click)="resetForm()">
		<span class="flex items-center justify-center h-42">
			<i class="mr-10 material-icons mi-18">cached</i>
			<span>Reset Form</span>
		</span>
	</button>
</section>
<!-- CanSell Viewport -->
<section class="flex flex-col flex-grow order-1 w-full p-20 md:max-w-1/2 md:order-2 md:p-40 bg-blue-lightest">
	<div *ngIf="sessionStore.UserSettings?.EAppPreference !== eAppPreferenceEnum.None && !isStartingApplication" class="flex items-center justify-between px-20 py-10 bg-white border border-solid rounded border-blue-lighter">
		<p class="font-bold">Want to change your e-application experience?</p>
		<button (click)="openEAppPreferenceSelectorModal()" mat-button class="button is-primary is-sm">Click Here</button>
	</div>
	<div class="flex flex-col items-center justify-center w-full mx-auto max-w-384" [@fadeIn]
		[ngClass]="{'my-auto': !canSellModel && !errorMessage && !unableToRunCanSellCheck}">
		<span class="m-20 h-256 w-256">
			<img [ngClass]="{'hidden': isRunningCanSellCheck}" src="./assets/images/can-sell-v2.svg"
				class="w-full h-full" />
			<img [ngClass]="{'hidden': !isRunningCanSellCheck}" src="./assets/images/can-sell-v2-animate.svg"
				class="w-full h-full" />
		</span>
		<p class="flex flex-col m-20 mt-0 font-bold text-center md:text-lg"
			*ngIf="!isRunningCanSellCheck && !canSellModel && !errorMessage && !unableToRunCanSellCheck">
			<span>Welcome to the E-Application Wizard.</span>
			<span>Please select a product to get started.</span>
		</p>

		<!-- Checking In-progress -->
		<div [@fadeIn] *ngIf="isStartingApplication || isRunningCanSellCheck"
			class="m-20 mt-0 font-bold text-center md:text-lg">
			<ng-container *ngIf="isStartingApplication">Initializing application. This may take a moment...
			</ng-container>
			<ng-container *ngIf="isRunningCanSellCheck">Checking application. This may take a moment...</ng-container>
		</div>

		<!-- Cansell Results -->
		<div [@fadeIn] *ngIf="canSellModel && !isRunningCanSellCheck" class="flex items-center px-20 py-10">
			<i class="mr-20 material-icons"
				[class]="productTrainingSupported && canSellModel.CanSell ? 'text-green' : !productTrainingSupported ? 'text-gray-light' : 'text-red'">{{productTrainingSupported && canSellModel.CanSell ? 'check_circle' : !productTrainingSupported ? 'info' : 'cancel'}}</i>
			<div *ngFor="let reason of canSellModel.Reasons" class="flex flex-col">
				<h3 class="mb-5 font-bold" [ngClass]="{'text-red': !canSellModel.CanSell}">{{reason.Reason}}</h3>
				<span class="text-sm">{{reason.Message}}</span>
			</div>
		</div>

		<!-- State Appointment Info -->
		<div [@fadeIn] *ngIf="canSellModel && selectedCarrier && selectedState"
			class="flex items-center px-20 py-10">
			<i class="mr-20 material-icons text-gray-light">info</i>
			<div class="flex flex-col">
				<h3 class="mb-5 font-bold">Carrier & State Rules:
					{{canSellModel!.CarrierStateAppointmentType | splitCamelCase}}</h3>
				<span class="text-sm">
					<div
						*ngIf="canSellModel?.CarrierStateAppointmentType == carrierStateAppointmentType.PreAppointment">
						{{selectedCarrier.Business}} requires an active contract in {{selectedState.LongName}}
						<strong> prior to submission of business.</strong> Verify your contract status to avoid re-doing
						your application.
					</div>
					<div *ngIf="canSellModel?.CarrierStateAppointmentType == carrierStateAppointmentType.JustInTime || canSellModel?.CarrierStateAppointmentType == carrierStateAppointmentType.DoesNotProcess">
						{{selectedCarrier.Business}} accepts contracting at time of submission of business. Verify your contract status or 
						<a class="no-underline cursor-pointer text-blue" [routerLink]="'/Portal/CarriersTraining/Overview'"><strong>submit a contracting request.</strong></a>
					</div>
				</span>
			</div>
		</div>

		<!-- Error & Warning -->
		<div [@fadeIn]
			class="flex items-center px-20 py-10 m-20 border border-solid rounded bg-orange-lightest border-orange"
			*ngIf="errorMessage">
			<i class="mr-20 material-icons text-orange">warning</i>
			<ng-container *ngIf="errorMessage">
				{{errorMessage}}
			</ng-container>
		</div>

		<div class="flex flex-col p-20 mx-auto">
			<button *ngIf="isMissingTraining && productTrainingSupported" mat-button [disabled]="isStartingApplication"
				(click)="goToTraining();" class="my-5 rounded-full button is-primary is-lg">
				Go To Product Training
			</button>
			<ng-container *ngIf="canSellModel || unableToRunCanSellCheck">
				<button *ngIf="queryActivityId" mat-button
					(click)="eAppService.goToFirelight(queryActivityId, {isNew: false})" class="my-10 rounded-full button is-primary is-lg" amplitudeEvent="Eapp Workflow - Continue Embedded Eapp"
					[ngClass]="{'is-outlined': isMissingTraining}">
					Continue E-Application</button>
				<button *ngIf="selectedProduct && !queryActivityId"
					mat-button amplitudeEvent="Eapp Workflow - Start Embedded Eapp"
					[disabled]="isStartingApplication || (!hasFirelightPermission && selectedProduct.ExternalVendorID == 1) || !selectedProduct?.ProductCusip"
					(click)="startEApplication()" class="my-10 rounded-full button is-primary is-lg"
					[ngClass]="{'is-outlined': isMissingTraining && productTrainingSupported}">
					Start E-Application
				</button>
				<button *ngIf="selectedProduct && !queryActivityId" amplitudeEvent="Eapp Workflow - Upload Paper App"
					mat-button
					[disabled]="isStartingApplication || (!hasIPipelinePermission && selectedProduct.ExternalVendorID == 4)"
					(click)="openPaperApplication()" class="mt-20 rounded-full button is-primary is-inverted is-lg">
					Upload Paper Application
				</button>
				
				<!-- DOL Message -->
				<div @fadeIn
					class="flex items-center px-20 py-10 m-10 border border-solid rounded md:my-20 bg-blue-lightest border-blue"
					*ngIf="selectedLobId === 1">
					<i class="mr-20 material-icons text-blue">warning</i>
					<div>
						<span [innerHtml]="dolMessage"></span>
						<a class="text-blue hover:text-blue-darker" routerLink="/Portal/FigPlatform/Details/RegulatoryUpdates" target="_blank" aria-label="Link DOL information">Click here</a> to learn more.
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</section>