import { Component, OnInit } from '@angular/core';
import { fadeIn } from 'src/app/animations';
import { structuredInvestment } from 'src/app/shared/constants/simon-education-nav-items.constants';
import { SimonIframeUrl } from 'src/app/shared/models/simon-iframe-url.models';

@Component({
  animations: [fadeIn],
  selector: 'app-structured-investments',
  templateUrl: './structured-investments.component.html',
})
export class StructuredInvestmentsComponent {
  navItems: SimonIframeUrl[] = structuredInvestment;
  constructor() { }

}
