import { AgentSubAgent } from 'src/app/shared/models/agent.models';
import { CarrierReportsApiService } from 'src/app/core/services/http/carrier-reports-api.service';
import { CarrierTrainingAndContract, CarrierTraining, WritingNumber } from 'src/app/shared/models/carrier.models';
import { Component, OnInit } from '@angular/core';
import { Contract } from 'src/app/shared/models/contract.models';
import { downloadFile } from 'src/app/shared/helpers/filedownloader.helper';
import { fadeIn, fadeInOut, slideInOut } from 'src/app/animations';
import { FeatureToggleService } from 'src/app/core/services/featuretoggle.service';
import { orderBy } from 'lodash';
import { ReportsApiService } from 'src/app/core/services/http/reports-api.service';
import { ThemePalette } from '@angular/material/core';
import { PermissionService } from 'src/app/core/services/auth/permission.service';
import { environment } from 'src/environments/environment';
import { of, forkJoin } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'app-my-carriers',
  templateUrl: './my-carriers.component.html',
  animations: [fadeIn, fadeInOut, slideInOut]
})
export class MyCarriersComponent implements OnInit {
  environment = environment;
  matColor: ThemePalette = 'primary';
  allCarriersVisible: boolean = false;
  allCarriersList!: CarrierTraining[];
  columnName: string = 'Carrier';
  downloadIncrement: number = 0;
  exportingList: boolean = false;
  loading: boolean = true;
  menuOpen: boolean = false;
  myCarrierList: CarrierTrainingAndContract[] = [];
  reverseOrder: boolean = false;
  simonLOBs: string[] = ['Annuity', 'Structured Investment', 'Structured Notes', 'Structured Product', 'Structured']

  constructor(
    private carrierReportsApiService: CarrierReportsApiService,
    private featureToggleService: FeatureToggleService,
    private permissionService: PermissionService,
    private reportsApiService: ReportsApiService) {
  }

  ngOnInit(): void {
    this.loading = true;
    forkJoin({
      allCarriers: this.carrierReportsApiService.getCarrierTraining()
        .pipe(
          map(trainings => trainings.filter(t => t.TrainingTitle && t.TrainingTitle !== '')),
          catchError(() => of([]))),
      myContracts: this.reportsApiService.getContracts().pipe(catchError(() => of([])))
    })
      .subscribe((res) => {
        this.allCarriersList = res.allCarriers;
        this.setCarrierList(res.myContracts);
        if (this.myCarrierList.length == 0) this.allCarriersVisible = true;
      })
      .add(() => {
        this.loading = false;
      });
  }

  exportContractReport(): void {
    this.exportingList = false;
    this.reportsApiService.exportContractingReport()
      .subscribe(res => {
        this.exportingList = false;
        downloadFile(res, 'ContractData.xlsx');
      });
  }

  hasApprovedLOB(lobName: string): boolean {
    return this.simonLOBs.includes(lobName);
  }

  hasSimonTraining(carrierName: string): boolean {
    let carrierTraining: CarrierTraining | undefined;
    if (this.allCarriersList) {
      carrierTraining = this.allCarriersList.find(carrier => {
        return carrier.Carrier === carrierName;
      });
    }
    return !!carrierTraining ? carrierTraining.HasSimonTraining : false;
  }

  hasGuidePermissions(): boolean {
    // check for "Annuity and Carrier Training" permission
    return this.permissionService.hasAllPermissions('FigTraining:View|MenuResources:View');
  }

  getStateAppointments(carrier: CarrierTrainingAndContract): void {
    if (carrier.ShowDetails) {
      carrier.ShowDetails = false;
      return;
    }

    carrier.GettingDetails = true;

    this.reportsApiService.getContractDetail(carrier.ContractGuid, carrier.LineOfBusinessGuid)
      .subscribe(res => {
        carrier.GettingDetails = false;
        carrier.ContractDetails = res;
        carrier.ShowDetails = true;
      });
  }

  openTraining(url: string): void {
    window.open(url, '_blank');
  }

  orderRows(columnName: string): void {
    if (columnName) {
      this.reverseOrder = (columnName === this.columnName) ? !this.reverseOrder : false;
      this.columnName = columnName;
    } else {
      columnName = this.columnName;
    }
    this.myCarrierList = orderBy(this.myCarrierList, columnName, this.reverseOrder ? ['desc'] : ['asc']);
    this.allCarriersList = orderBy(this.allCarriersList, columnName, this.reverseOrder ? ['desc'] : ['asc']);
  }

  setCarrierList(contracts: Contract[]): void {
    contracts.forEach(contract => {
      const carrier: CarrierTrainingAndContract = { ...contract, ...{ ContractDetails: null, GettingDetails: false, ShowDetails: false, WritingNumberArray: [], CarrierTraining: null } };

      const indvNumbers: WritingNumber[] = !carrier.WritingNumbers ? [] : carrier.WritingNumbers.split(', ').map(number => {
        return {
          value: number,
          type: 'Individual',
          icon: 'person'
        };
      });
      const corpNumbers: WritingNumber[] = !carrier.CorpWritingNumbers ? [] : carrier.CorpWritingNumbers!.split(', ').map(number => {
        return {
          value: number,
          type: 'Corporate',
          icon: 'business'
        };
      });
      const carrierTraining = this.allCarriersList.find(carrier => {
        return carrier.Carrier === contract.Carrier;
      });
      carrier.WritingNumberArray = indvNumbers.concat(corpNumbers);
      carrier.CarrierTraining = !!carrierTraining ? carrierTraining : null;
      this.myCarrierList.push(carrier);
    });
  }
}
