<div class="flex flex-col h-full">
    <section class="flex flex-col flex-grow overflow-hidden card">
        <section class="flex items-center justify-start p-20 border-b border-solid lg:p-20 border-gray-lighter">
            <h2 class="m-10 mr-auto text-lg font-bold">Toolbox</h2>
            <div class="p-10 ml-20 md:hidden">
                <button class="icon-button is-primary is-inverted" attr.aria-label="Open Filters"
                    (click)="toggleSidenav(toolboxFilterSidenav)">
                    <span class="material-icons-outlined">filter_alt</span>
                </button>
            </div>
            <div class="relative flex p-10 sm:w-384">
                <i
                    class="absolute w-24 ml-10 transform -translate-y-1/2 text-gray top-1/2 material-icons-outlined">search</i>
                <input class="flex-grow w-full pl-40 input" type="text" name="search" placeholder="Search"
                    (keyup)='applyFilters()' [(ngModel)]="searchText" />
                <button class="py-10 icon-button is-primary is-inverted" attr.aria-label="Clear search box"
                    *ngIf="searchText.length > 0" (click)="clearSearch()"><i class="material-icons">close</i></button>
            </div>
        </section>
        <mat-sidenav-container class="relative flex flex-row items-stretch justify-start flex-grow overflow-hidden">
            <mat-sidenav #toolboxFilterSidenav [opened]="sidenavConfig.IsOpened" [mode]="sidenavConfig.Mode"
                class="flex flex-col w-auto h-full max-w-full p-10 bg-white shadow md:flex-grow-0 md:flex sm:w-320 sm:min-w-320 sm:shadow-none md-sidenav-left lg:p-20">
                <div class="flex flex-col flex-grow-0 m-10">
                    <label class="mb-20 text-sm font-medium text-gray">Filter by categories:</label>
                    <ul class="flex flex-col">
                        <ng-container *ngFor="let cat of categories">
                            <li class="mb-20" *ngIf="filterCategory(tiles,cat.Name).length" @fadeIn>
                                <mat-checkbox class="checkbox is-category-filter {{cat.Name}} m-0"
                                    (change)="updateSearchCategories()" [(ngModel)]="cat.Selected"
                                    aria-label="Checkbox {{cat.Name}}">
                                    <div class="flex flex-row items-center justify-start">
                                        <span
                                            class="flex flex-row items-center justify-center mr-10 rounded-full w-36 h-36">
                                            <span class="material-icons {{cat.Class}}">{{cat.Icon}}</span>
                                        </span>
                                        <span class="capitalize"
                                            [class]="cat.Selected ? cat.Class : 'text-gray-dark'">{{cat.Name}}</span>
                                    </div>
                                </mat-checkbox>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </mat-sidenav>
            <mat-sidenav-content class="flex flex-col flex-grow overflow-hidden bg-white">
                <div class="relative flex-grow w-full h-0 overflow-auto bg-opacity-25 bg-gray-lightest"
                    *ngIf="!isLoading" [@fadeIn]>
                    <section #featuredToolboxTile id="featuredToolboxTile" class="p-10 lg:px-20"
                        *ngIf="!!featuredTiles.length && !showFilteredList" [@fadeIn]>
                        <h3 class="m-10 text-lg font-bold">Featured</h3>
                        <div
                            class="grid grid-cols-2 p-10 gap-x-10 gap-y-10 md:grid-cols-3 lg:grid-cols-4 lg:gap-x-20 lg:gap-y-20">
                            <div class="flex flex-col h-full overflow-hidden bg-center bg-no-repeat bg-cover cursor-pointer card feature-card min-h-192 focus:outline-none"
                                [style.background-image]="'url(' + featuredTile.ImgUrl + ')'"
                                (click)="featuredTile.Act()" *ngFor="let featuredTile of featuredTiles">
                                <span class="feature-icon">
                                    <span class="transition-colors duration-150 material-icons mi-18"
                                        [class]="featuredTile.Class"
                                        [title]="featuredTile.Category">{{featuredTile.Icon}}</span>
                                </span>
                                <div class="feature-banner">
                                    <h4 class="mb-10 text-base text-white heading">{{featuredTile.Name}}</h4>
                                    <p class="text-sm text-gray-lighter md:h-36 overflow-ellipsis"
                                        [innerHtml]="featuredTile.ShortDescription"></p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section #favoriteToolboxTile id="favoriteToolboxTile" class="p-10 lg:px-20"
                        *ngIf="!!favoriteTiles.length && !showFilteredList" [@fadeIn]>
                        <h3 class="m-10 text-lg font-bold">Favorites</h3>
                        <div
                            class="grid grid-cols-3 p-10 gap-x-10 gap-y-10 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-20 lg:gap-y-20">
                            <div class="h-full overflow-hidden transition-colors duration-150 cursor-pointer card focus:outline-none hover:bg-blue-lighter hover:bg-opacity-25"
                                (click)="!!favoriteTile.Loading ? '' : favoriteTile.Act()"
                                *ngFor="let favoriteTile of favoriteTiles ">
                                <div class="flex items-center content-center justify-between">
                                    <span class=""
                                        [class]="'w-36 h-36 rounded-full m-10 flex items-center justify-center ' + favoriteTile.Class">
                                        <span class="mi-18 material-icons"
                                            [title]="favoriteTile.Category">{{favoriteTile.Icon}}</span>
                                    </span>
                                    <button class="m-10 icon-button is-alert is-inverted"
                                        attr.aria-label="Add tool to favorites" [disabled]="favoriteTile.Loading"
                                        (click)="updateToolboxFavorites(favoriteTile)">
                                        <i class="material-icons mi-18">{{favoriteTile.IsFavorite ? 'star' :
                                            'star_outline'}}</i>
                                    </button>
                                </div>
                                <div class="h-full p-20">
                                    <h4 class="mb-10 text-sm font-bold text-blue-dark">{{favoriteTile.Name}}</h4>
                                    <p class="text-sm" [innerHtml]="favoriteTile.ShortDescription"></p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div *ngIf="!showFilteredList" [@fadeIn]>
                        <ng-container *ngFor="let category of categories; let last = last">
                            <section #toolboxTile id="toolboxTile" class="p-10 lg:px-20"
                                [class]="last ? 'lg:pb-20' : ''" *ngIf="filterCategory(tiles,category.Name).length">
                                <h3 class="m-10 mb-20 text-lg font-bold capitalize">{{category.Name}}
                                    ({{filterCategory(tiles,
                                    category.Name).length}})</h3>
                                <div
                                    class="grid grid-cols-3 p-10 gap-x-10 gap-y-10 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-20 lg:gap-y-20">
                                    <div class="h-full overflow-hidden transition-colors duration-150 cursor-pointer card focus:outline-none hover:bg-blue-lighter hover:bg-opacity-25"
                                        (click)="!!toolboxTile.Loading ? '' : toolboxTile.Act()"
                                        *ngFor="let toolboxTile of orderBy(filterCategory(tiles, category.Name), ['Name'])">
                                        <div class="flex items-center content-center justify-between w-full">
                                            <span class="flex items-center justify-center m-10 rounded-full w-36 h-36"
                                                [class]="toolboxTile.Class">
                                                <span class="mi-18 material-icons"
                                                    [title]="toolboxTile.Category">{{toolboxTile.Icon}}</span>
                                            </span>
                                            <button class="m-10 icon-button is-alert is-inverted"
                                                attr.aria-label="Add tool to favorites" [disabled]="toolboxTile.Loading"
                                                (click)="updateToolboxFavorites(toolboxTile)">
                                                <i class="material-icons mi-18">{{toolboxTile.IsFavorite ? 'star' :
                                                    'star_outline'}}</i>
                                            </button>
                                        </div>
                                        <div class="h-full p-10 pb-20 md:p-20">
                                            <h4 class="mb-5 text-sm font-bold text-blue-dark md:mb-10">
                                                {{toolboxTile.Name}}
                                            </h4>
                                            <p class="text-sm" [innerHtml]="toolboxTile.ShortDescription"></p>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </ng-container>
                    </div>
                    <section class="p-10 lg:px-20" *ngIf="!!showFilteredList" [@fadeIn]>
                        <h3 class="m-10 text-lg font-bold capitalize ">Results ({{filteredList.length}})</h3>
                        <div
                            class="grid grid-cols-3 p-10 gap-x-10 gap-y-10 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-20 lg:gap-y-20">
                            <div class="h-full overflow-hidden transition-colors duration-150 cursor-pointer card focus:outline-none hover:bg-blue-lighter hover:bg-opacity-25"
                                (click)="!!resultTile.Loading ? '' : resultTile.Act()"
                                *ngFor="let resultTile of filteredList | orderBy: ['Name'] ">
                                <div class="flex items-center content-center justify-between">
                                    <span class="flex items-center justify-center m-10 rounded-full w-36 h-36"
                                        [class]="resultTile.Class">
                                        <span class="mi-18 material-icons"
                                            [title]="resultTile.Category">{{resultTile.Icon}}</span>
                                    </span>
                                    <button class="m-10 icon-button is-alert is-inverted"
                                        attr.aria-label="Add tool to favorites" [disabled]="resultTile.Loading"
                                        (click)="updateToolboxFavorites(resultTile)">
                                        <i class="material-icons mi-18">{{resultTile.IsFavorite ? 'star' :
                                            'star_outline'}}</i>
                                    </button>
                                </div>
                                <div class="h-full p-10 pb-20 md:p-20">
                                    <h4 class="mb-5 text-sm font-bold text-blue-dark md:mb-10">{{resultTile.Name}}
                                    </h4>
                                    <p class="text-sm" [innerHtml]="resultTile.ShortDescription"></p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div class="relative p-20 loader-wrap" *ngIf="!!isLoading" [@fadeIn]>
                    <div class="loader-sm is-blue"></div>
                </div>
            </mat-sidenav-content>
        </mat-sidenav-container>
    </section>
</div>