import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InformalTablePolicy } from 'src/app/portal/components/business/components/informal-policy-list/models/informalTablePolicy.model';
import { AgentBusinessSummary, AgentCommissionGroupList, AgentGuidList, AgentSubAgent, AgentSubEntity, AgentYearlyProduction } from 'src/app/shared/models/agent.models';
import { Contract, ContractDetail } from 'src/app/shared/models/contract.models';
import { EntryResponse, ListResponse, SingleResponse } from 'src/app/shared/models/generic.models';
import { PolicyDetail, Policy, PolicyFunding, PolicySuitability, PolicyAgentUpload, PolicyTask, Note, InformalPolicyDetails, InformalPolicyDetailsEntry, ExportPolicyListModel } from 'src/app/shared/models/policy.models';

@Injectable({
  providedIn: 'root'
})
export class ReportsApiService {

  constructor(private http: HttpClient) { }

  postNewPolicyFiles(formData: FormData): Observable<string> {
    return this.http.post('/api/FileUpload/Policy', formData, { responseType: 'text' });
  }

  postPolicyFiles(policyGuid: string, formData: FormData): Observable<number> {
    return this.http.post<number>(`/api/FileUpload/${policyGuid}`, formData);
  }

  getPendingPolicies(agentGuidList: AgentGuidList): Observable<Policy[]> {
    return this.http.post<ListResponse<Policy>>('/api/PolicyReport/Pending', agentGuidList)
      .pipe(map((x: ListResponse<Policy>) => x.Entries.map(p => {
        p.PolicyStatusDate = p.PolicyStatusDate ? new Date(p.PolicyStatusDate) : null;
        return p;
      })));
  }

  getCommissionGroupPendingPolicies(agentCommissionGroupList: AgentCommissionGroupList): Observable<Policy[]> {
    return this.http.post<ListResponse<Policy>>('/api/PolicyReport/Pending/CommissionGroup', agentCommissionGroupList)
      .pipe(map((x: ListResponse<Policy>) => x.Entries.map(p => {
        p.PolicyStatusDate = p.PolicyStatusDate ? new Date(p.PolicyStatusDate) : null;
        return p;
      })));
  }

  getPendingVariablePolicies(agentGuidList: AgentGuidList): Observable<Policy[]> {
    return this.http.post<ListResponse<Policy>>('/api/PolicyReport/PendingVariable', agentGuidList)
      .pipe(map((x: ListResponse<Policy>) => x.Entries.map(p => {
        p.PolicyStatusDate = p.PolicyStatusDate ? new Date(p.PolicyStatusDate) : null;
        return p;
      })));
  }

  getCommissionGroupPendingVariablePolicies(agentCommissionGroupList: AgentCommissionGroupList): Observable<Policy[]> {
    return this.http.post<ListResponse<Policy>>('/api/PolicyReport/PendingVariable/CommissionGroup', agentCommissionGroupList)
      .pipe(map((x: ListResponse<Policy>) => x.Entries.map(p => {
        p.PolicyStatusDate = p.PolicyStatusDate ? new Date(p.PolicyStatusDate) : null;
        return p;
      })));
  }

  getCommissionGroupInforcePolicies(agentCommissionGroupList: AgentCommissionGroupList): Observable<Policy[]> {
    return this.http.post<ListResponse<Policy>>('/api/PolicyReport/Inforce/CommissionGroup', agentCommissionGroupList)
      .pipe(map((x: ListResponse<Policy>) => x.Entries.map(p => {
        p.InforceDate = p.InforceDate ? new Date(p.InforceDate) : null;
        p.PolicyStatusDate = p.PolicyStatusDate ? new Date(p.PolicyStatusDate) : null;
        return p;
      })));
  }

  getInforcePolicies(agentGuidList: AgentGuidList): Observable<Policy[]> {
    return this.http.post<ListResponse<Policy>>('/api/PolicyReport/Inforce', agentGuidList)
      .pipe(map((x: ListResponse<Policy>) => x.Entries.map(p => {
        p.InforceDate = p.InforceDate ? new Date(p.InforceDate) : null;
        p.PolicyStatusDate = p.PolicyStatusDate ? new Date(p.PolicyStatusDate) : null;
        return p;
      })));
  }

  getInforceVariablePolicies(agentGuidList: AgentGuidList): Observable<Policy[]> {
    return this.http.post<ListResponse<Policy>>('/api/PolicyReport/InforceVariable', agentGuidList)
      .pipe(map((x: ListResponse<Policy>) => x.Entries.map(p => {
        p.InforceDate = p.InforceDate ? new Date(p.InforceDate) : null;
        p.PolicyStatusDate = p.PolicyStatusDate ? new Date(p.PolicyStatusDate) : null;
        return p;
      })));
  }

  getCommissionGroupInforceVariablePolicies(agentCommissionGroupList: AgentCommissionGroupList): Observable<Policy[]> {
    return this.http.post<ListResponse<Policy>>('/api/PolicyReport/InforceVariable/CommissionGroup', agentCommissionGroupList)
      .pipe(map((x: ListResponse<Policy>) => x.Entries.map(p => {
        p.InforceDate = p.InforceDate ? new Date(p.InforceDate) : null;
        p.PolicyStatusDate = p.PolicyStatusDate ? new Date(p.PolicyStatusDate) : null;
        return p;
      })));
  }

  getInforcePaidPolicies(agentGuidList: AgentGuidList): Observable<Policy[]> {
    return this.http.post<ListResponse<Policy>>('/api/PolicyReport/InforcePaid', agentGuidList)
      .pipe(map((x: ListResponse<Policy>) => x.Entries.map(p => {
        p.InforceDate = p.InforceDate ? new Date(p.InforceDate) : null;
        p.PolicyStatusDate = p.PolicyStatusDate ? new Date(p.PolicyStatusDate) : null;
        return p;
      })));
  }

  getCommissionGroupInforcePaidPolicies(agentCommissionGroupList: AgentCommissionGroupList): Observable<Policy[]> {
    return this.http.post<ListResponse<Policy>>('/api/PolicyReport/InforcePaid', agentCommissionGroupList)
      .pipe(map((x: ListResponse<Policy>) => x.Entries.map(p => {
        p.InforceDate = p.InforceDate ? new Date(p.InforceDate) : null;
        p.PolicyStatusDate = p.PolicyStatusDate ? new Date(p.PolicyStatusDate) : null;
        return p;
      })));
  }

  exportPendingPolicies(agentGuidList: AgentGuidList): Observable<Blob> {
    return this.http.post<Blob>('/api/PolicyReport/Export/Policies/Pending', agentGuidList, { responseType: 'blob' as 'json' });
  }

  exportInforcePolicies(agentGuidList: AgentGuidList): Observable<Blob> {
    return this.http.post<Blob>('/api/PolicyReport/Export/Policies/Inforce', agentGuidList, { responseType: 'blob' as 'json' });
  }

  exportPolicies(model: ExportPolicyListModel): Observable<Blob> {
    return this.http.post<Blob>('/api/PolicyReport/Export/Policies', model, { responseType: 'blob' as 'json' });
  }

  exportContractingReport(): Observable<Blob> {
    return this.http.get<Blob>('/api/ContractReport/Export', { responseType: 'blob' as 'json' });
  }

  getPolicyDetailsByGuid(policyGuid: string): Observable<PolicyDetail> {
    return this.http.get<EntryResponse<PolicyDetail>>(`/api/PolicyReport/${policyGuid}`)
      .pipe(map((x: EntryResponse<PolicyDetail>) => x.Entry));
  }

  getVariablePolicyDetailsByGuid(policyGuid: string): Observable<PolicyDetail> {
    return this.http.get<EntryResponse<PolicyDetail>>(`/api/PolicyReport/Variable/${policyGuid}`)
      .pipe(map((x: EntryResponse<PolicyDetail>) => x.Entry));
  }

  getPolicyFundingByGuid(policyGuid: string): Observable<PolicyFunding[]> {
    return this.http.get<EntryResponse<PolicyFunding[]>>(`/api/PolicyReport/${policyGuid}/Funding`)
      .pipe(map((x: EntryResponse<PolicyFunding[]>) => x.Entry));
  }

  getPolicySuitabilityStatusByGuid(policyGuid: string): Observable<PolicySuitability> {
    return this.http.get<EntryResponse<PolicySuitability>>(`/api/PolicyReport/${policyGuid}/SuitabilityStatus`)
      .pipe(map((x: EntryResponse<PolicySuitability>) => x.Entry));
  }

  getAgentUploadsByGuid(policyGuid: string): Observable<PolicyAgentUpload[]> {
    return this.http.get<ListResponse<PolicyAgentUpload>>(`/api/PolicyReport/${policyGuid}/AgentUploads`)
      .pipe(map((x: ListResponse<PolicyAgentUpload>) => x.Entries));
  }

  getPolicyCaseStatusHistoryByGuid(policyGuid: string): Observable<PolicyTask[]> {
    return this.http.get<EntryResponse<PolicyTask[]>>(`/api/PolicyReport/${policyGuid}/CaseStatus/History`)
      .pipe(map((x: EntryResponse<PolicyTask[]>) => x.Entry));
  }

  getVariablePolicyCaseStatusHistoryByGuid(policyGuid: string): Observable<PolicyTask[]> {
    return this.http.get<EntryResponse<PolicyTask[]>>(`/api/PolicyReport/Variable/${policyGuid}/CaseStatus/History`)
      .pipe(map((x: EntryResponse<PolicyTask[]>) => x.Entry));
  }

  getPolicyCaseStatusLatestByGuid(policyGuid: string): Observable<PolicyTask> {
    return this.http.get<EntryResponse<PolicyTask>>(`/api/PolicyReport/${policyGuid}/CaseStatus/Latest`)
      .pipe(map((x: EntryResponse<PolicyTask>) => x.Entry));
  }

  getVariablePolicyCaseStatusLatestByGuid(policyGuid: string): Observable<PolicyTask> {
    return this.http.get<EntryResponse<PolicyTask>>(`/api/PolicyReport/Variable/${policyGuid}/CaseStatus/Latest`)
      .pipe(map((x: EntryResponse<PolicyTask>) => x.Entry));
  }

  getPolicyRequirmentHistoryByGuid(policyGuid: string): Observable<PolicyTask[]> {
    return this.http.get<EntryResponse<PolicyTask[]>>(`/api/PolicyReport/${policyGuid}/Requirements`)
      .pipe(map((x: EntryResponse<PolicyTask[]>) => x.Entry));
  }

  postPolicyTaskNotes(taskId: number, note: string): Observable<Note> {
    return this.http.post<Note>(`/api/PolicyReport/Tasks/${taskId}/Notes`, { Note: note });
  }

  getSubAgents(): Observable<AgentSubAgent[]> {
    return this.http.get<ListResponse<AgentSubAgent>>('/api/SubAgents')
      .pipe(map((x: ListResponse<AgentSubAgent>) => x.Entries));
  }

  getSubEntities(): Observable<AgentSubEntity[]> {
    return this.http.get<ListResponse<AgentSubEntity>>('/api/SubAgents/SubEntities')
      .pipe(map((x: ListResponse<AgentSubEntity>) => x.Entries));
  }

  getContractDetail(contractGuid: string, lineOfBusinessGuid: string): Observable<ContractDetail> {
    return this.http.get<SingleResponse<ContractDetail>>(`/api/ContractReport/${contractGuid}/LineOfBusiness/${lineOfBusinessGuid}`)
      .pipe(map((x: SingleResponse<ContractDetail>) => x.Entries));
  }

  getContracts(): Observable<Contract[]> {
    return this.http.get<ListResponse<Contract>>('/api/ContractReport/AgentContracts')
      .pipe(map((x: ListResponse<Contract>) => x.Entries));
  }

  getAgentYearlyProduction(arcAgentGuid: string): Observable<AgentYearlyProduction[]> {
    return this.http.get<AgentYearlyProduction[]>(`/api/Agents/${arcAgentGuid}/YearlyProduction`);
  }

  getAgentBusinessSummary(arcAgentGuid: string): Observable<AgentBusinessSummary> {
    return this.http.get<AgentBusinessSummary>(`/api/Agents/${arcAgentGuid}/BusinessSummary`);
  }

  getIsVariablePolicy(policyGuid: string): Observable<boolean> {
    return this.http.get<boolean>(`/api/PolicyReport/${policyGuid}/IsVariable`);
  }

  exportPendingFixedPolicies(agentGuidList: AgentGuidList): Observable<Blob> {
    return this.http.post<Blob>('/api/PolicyReport/Export/Policies/Pending/Fixed', agentGuidList, { responseType: 'blob' as 'json' });
  }

  exportPendingVariablePolicies(agentGuidList: AgentGuidList): Observable<Blob> {
    return this.http.post<Blob>('/api/PolicyReport/Export/Policies/Pending/Variable', agentGuidList, { responseType: 'blob' as 'json' });
  }

  exportInforceFixedPolicies(agentGuidList: AgentGuidList): Observable<Blob> {
    return this.http.post<Blob>('/api/PolicyReport/Export/Policies/Inforce/Fixed', agentGuidList, { responseType: 'blob' as 'json' });
  }

  exportInforceVariablePolicies(agentGuidList: AgentGuidList): Observable<Blob> {
    return this.http.post<Blob>('/api/PolicyReport/Export/Policies/Inforce/Vairable', agentGuidList, { responseType: 'blob' as 'json' });
  }

  getInformalPolicies(): Observable<InformalTablePolicy[]> {
    return this.http.get<ListResponse<InformalTablePolicy>>('/api/PolicyReport/Informal')
      .pipe(map((x: ListResponse<InformalTablePolicy>) => x.Entries));
  }

  exportInformalPolicies(): Observable<Blob> {
    return this.http.post<Blob>('/api/PolicyReport/Export/Informal/Agents', { AgentIds: '', CanSeeAgentComissions: true, IncludeUser: true }, { responseType: 'blob' as 'json' });
  }

  getInformalDetail(policyGuid: string): Observable<InformalPolicyDetails> {
    return this.http.get<InformalPolicyDetailsEntry>(`/api/PolicyReport/Informal/${policyGuid}`)
      .pipe(map((x: InformalPolicyDetailsEntry) => x.Entry));
  }
}
