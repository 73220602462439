// Dividing by 1000 milliseconds to count only seconds to prevent delay between when the inputDate is defined and when the targetDate is
// If you need to comparing down to seconds or beyond, write the specific time compare functions. Be careful how you pass in the date agurments though as there might be a delay between when each gets resolved

export const DateComparisonHelper = {
  trueIfBeforeDate: (inputDate: Date, targetDate: Date):boolean => {
    const inputTime: number = Math.floor((inputDate.getTime() / 1000));
    const targetTime: number = Math.floor((targetDate.getTime() / 1000));

    if(inputTime < targetTime) return true;
    else return false;
  },
  trueIfAfterDate: (inputDate: Date, targetDate: Date):boolean => {
    const inputTime: number = Math.floor((inputDate.getTime() / 1000));
    const targetTime: number = Math.floor((targetDate.getTime() / 1000));

    if(inputTime > targetTime) return true;
    else return false;
  },
  trueIfBeforeOrEqualDate: (inputDate: Date, targetDate: Date):boolean => {
    const inputTime: number = Math.floor((inputDate.getTime() / 1000));
    const targetTime: number = Math.floor((targetDate.getTime() / 1000));

    if(inputTime <= targetTime) return true;
    else return false;
  },
  trueIfAfterOrEqualDate: (inputDate: Date, targetDate: Date):boolean => {
    const inputTime: number = Math.floor((inputDate.getTime() / 1000));
    const targetTime: number = Math.floor((targetDate.getTime() / 1000));

    if(inputTime >= targetTime) return true;
    else return false;
  },
  trueIfEqualDate: (inputDate: Date, targetDate: Date):boolean => {
    const inputTime: number = Math.floor((inputDate.getTime() / 1000));
    const targetTime: number = Math.floor((targetDate.getTime() / 1000));

    if(inputTime === targetTime) return true;
    else return false;
  },

};