import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { fadeIn, fadeInOut, routerTransition } from 'src/app/animations';
import { Router, RouterOutlet } from '@angular/router';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { UserGroup } from 'src/app/shared/models/usergroups.models';
import { User } from 'src/app/shared/models/user.models';

@Component({
  animations: [fadeIn, fadeInOut, routerTransition ],
  selector: 'app-dashboard-container',
  templateUrl: './dashboard-container.component.html',
})
export class DashboardContainerComponent implements OnInit {
  selectedTab: string = 'Dashboard';
  user: any;
  currentUser: any;
  loading: boolean = false;
  onBehalfOfUser!: User | null;
  onBehalfOfUserGroup!: UserGroup | null;
  userGroup!: UserGroup | null;
  @ViewChild('dashboardContainerContent')
  private dashboardContentDiv!: ElementRef<HTMLElement>

  constructor(
    private sessionStore: SessionStoreService,) { }

  ngOnInit(): void {
    this.loading = true;
    this.user = this.sessionStore.User;
    this.currentUser = {...this.sessionStore.User, ...{ selected: true}};
    this.onBehalfOfUser = this.sessionStore.OnBehalfOfUser;
    this.userGroup = this.sessionStore.OnBehalfOfUserGroup ?? this.sessionStore.UserGroup;
    this.loading = false;
  }

  getComponent() {
    const homeUrl: string = '/Portal/';
    const path: string[] = window.location.pathname.split(homeUrl);
    return path[1];
  }

  getState(outlet: RouterOutlet) {
    const ret = outlet.activatedRoute.component;
    return ret;
  }

  onActivate(_event: any): void {
    // Scrolling back to the top
    // Reference: https://stackoverflow.com/questions/48048299/angular-5-scroll-to-top-on-every-route-click/48048822
    if (this.dashboardContentDiv) {
      (this.dashboardContentDiv.nativeElement as HTMLElement).scrollTop = 0;
    }
  }
}
