import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { forkJoin, Observable } from 'rxjs';
import { fadeIn } from 'src/app/animations';
import { AmplitudeEventService } from 'src/app/core/services/amplitude/amplitude-event.service';
import { AgentLicenseApiService } from 'src/app/core/services/http/agent-license-api.service';
import { OnboardingApiService } from 'src/app/core/services/http/onboarding-api.service';
import { getRequiredSteps, isFirstStep, isLastStep } from 'src/app/shared/helpers/onboarding.helper';
import { AgentLicenses } from 'src/app/shared/models/agent.models';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { privacyPolicyUrl, termsOfUseUrl } from 'src/app/shared/constants/fig-terms-of-use.constants';
import { catchError, tap } from 'rxjs/operators';
import { UserApiService } from 'src/app/core/services/http/user-api.service';
import { PermissionApiService } from 'src/app/core/services/http/permission-api.service';
import { PermissionService } from 'src/app/core/services/auth/permission.service';

@Component({
  selector: 'app-platform-configuration',
  templateUrl: './platform-configuration.component.html',
  animations: [fadeIn]
})
export class PlatformConfigurationComponent implements OnInit {
  hasOptedOutNpnCrd!: boolean;
  hasAcceptedTermsOfUse!:boolean;
  needsQuinciOptIn!:boolean;
  agentLicenses!: AgentLicenses;
  taskCompleted: boolean = false;
  taskCompletedFromParent: boolean = false;
  isFirstStep: boolean = false;
  isLastStep: boolean = false;
  Id: number = 3;
  errorMessage!: string;
  isError!: boolean;
  isLoading!: boolean;
  requiredSteps!: number[] | null;
  myForm: any;
  termsOfUseUrl!: string;
  privacyPolicyUrl: string = privacyPolicyUrl;
  @Output() taskCompletedEvent: EventEmitter<any> = new EventEmitter();
  @Output() goToPreviousEvent: EventEmitter<any> = new EventEmitter();
  @Output() goToNextEvent: EventEmitter<any> = new EventEmitter();
  @Output() goToPortalEvent: EventEmitter<string> = new EventEmitter();

  constructor(
    private cookieService: CookieService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private onboardingApiService: OnboardingApiService,
    private agentLicensesApiService: AgentLicenseApiService,
    private fb: FormBuilder,
    private amplitudeEventService: AmplitudeEventService,
    private sessionStore:SessionStoreService,
    private userApiService:UserApiService,
    private permissionApiService:PermissionApiService,
    private permissionService:PermissionService
  ) { }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      npn: ['', [Validators.pattern('^[0-9]*$')]],
      crd: ['', [Validators.pattern('^[0-9]*$')]],
    });
    this.activatedRoute.data.subscribe(resolve => {
      this.isLoading = true;
      this.needsQuinciOptIn = !resolve.termsOfUseCompleted?.HasAgreedToTermsOfUse && this.permissionService.hasOnePermission('ShowProductRDOptIn:View') && !this.permissionService.hasOnePermission('ProductRDOptedIn:View');
      this.hasOptedOutNpnCrd = resolve.userAgentLicensesOptOut !== null && resolve.userAgentLicensesOptOut.HasOptedOut == true;
      this.setCrdAndNpn();
      this.requiredSteps = getRequiredSteps(this.activatedRoute.snapshot.queryParamMap.get('requiredSteps'));
      this.isLastStep = isLastStep(this.requiredSteps, this.Id);
      this.termsOfUseUrl = this.needsQuinciOptIn ? termsOfUseUrl.figSimon : termsOfUseUrl.figOnly;
      if (((this.sessionStore.User.AgentLicenses.Crd && this.sessionStore.User.AgentLicenses.Npn) || this.hasOptedOutNpnCrd) && !this.needsQuinciOptIn) this.completeTask();
      this.isLoading = false;
    });
  }

  setCrdAndNpn() {
    if (this.sessionStore.User.AgentLicenses.Crd) this.myForm.get('crd').setValue(this.sessionStore.User.AgentLicenses.Crd);
    if (this.sessionStore.User.AgentLicenses.Npn) this.myForm.get('npn').setValue(this.sessionStore.User.AgentLicenses.Npn);
  }

  toggleOptout(event: MatCheckboxChange) {
    this.hasOptedOutNpnCrd = event.checked;
    const npnCtrl = this.myForm.get('npn');
    const crdCtrl = this.myForm.get('crd');
    event.checked ? npnCtrl.disable() : npnCtrl.enable();
    event.checked ? crdCtrl.disable() : crdCtrl.enable();
  }

  async submit() {
    this.isLoading = true;

    if (this.taskCompleted) {
      this.goToNext();
      return;
    } else if (this.hasUpdatedLicenses()) {
      await this.sessionStore.setAgentLicenses(this.myForm.value.crd!, this.myForm.value.npn!).toPromise();
    } else if (this.hasOptedOutNpnCrd) {
      await this.optOut().toPromise();
    }

    if (this.needsQuinciOptIn) {
      await this.onboardingApiService.acceptTermsOfUse().toPromise();
      await this.userApiService.updateOptIn().toPromise();
      await this.userApiService.updateOnboardingCompletedPermissions().toPromise();
      const permissions = await this.permissionApiService.getPolicyStatements().toPromise();
      this.permissionService.setPermissions(permissions);
    }

    this.isLoading = false;
    this.goToNext();
  }

  hasUpdatedLicenses() {
    const crdInput = this.myForm.value.crd!;
    const npnInput = this.myForm.value.npn!;

    if ((crdInput && crdInput != this.sessionStore.User.AgentLicenses.Crd)
    || (npnInput && npnInput != this.sessionStore.User.AgentLicenses.Npn)) return true;
    else return false;
  }

  // updateAgentLicenses() {
  //   this.isLoading = true;
  //   this.sessionStore.setAgentLicenses(this.myForm.value.crd!, this.myForm.value.npn!)
  //     .subscribe(res => {
  //       this.completeTask();
  //     },
  //       (error) => {
  //         this.errorMessage = 'An error occurred. Please try again.';
  //         this.isError = true;
  //       }).add(() => {
  //         this.isLoading = false;
  //         this.goToNext();
  //       });
  // }

  optOut(){
    return this.onboardingApiService.optOutOfAgentLicense()
      .pipe(tap(() => {
        this.amplitudeEventService.logCustomEvent('Onboarding: Agent License Opt Out', 'Custom', {});
      }));
  }

  // optOut() {
  //   this.isLoading = true;
  //   this.onboardingApiService.optOutOfAgentLicense()
  //     .subscribe(res => {
  //       this.amplitudeEventService.logCustomEvent('Onboarding: Agent License Opt Out', 'Custom', {});
  //       this.completeTask();
  //     },
  //       (error) => {
  //         this.isError = true;
  //       })
  //     .add(() => {
  //       this.isLoading = false;
  //       this.goToNext();
  //     })
  // }

  completeTask(): void {
    this.taskCompleted = true;
    this.taskCompletedEvent.emit(this.taskCompleted);
  }

  loadTaskCompleted(fromParent: boolean): void {
    this.taskCompletedFromParent = fromParent;
    this.taskCompleted = this.taskCompletedFromParent;
  }

  goToNext(): void {
    if (this.isLastStep) {
      this.goToPortalEvent.emit('Dashboard');
    } else {
      this.taskCompletedEvent.emit(true);
      this.goToNextEvent.emit();
    }
  }

  goToPrevious(): void {
    this.goToPreviousEvent.emit();
  }
}
