import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AvailableCarrier, AvailableCarrierProduct } from 'src/app/shared/models/available-carriers.model';

@Injectable({
  providedIn: 'root'
})
export class AvailableCarriersApiService {

  constructor(
    private http: HttpClient,
  ) { }

  getAvailableCarriers():Observable<AvailableCarrier[]>{
    return this.http.get<AvailableCarrier[]>('/api/AirTable/AvailableCarriers');
  }

  getAvailableCarrierProducts():Observable<AvailableCarrierProduct[]>{
    return this.http.get<AvailableCarrierProduct[]>('/api/AirTable/AvailableCarriers/Products');
  }
}
