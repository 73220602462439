<button class="mt-20 mr-20 button is-primary md:mt-30" (click)="openEnrollmentModal()" *ngIf="!hasSubmittedAnyForm && !enrolled">
  <span *ngIf="!hasSubmittedAnyForm">Enroll Today</span>
  <span  matTooltip="Creative Services enrollment form already submitted" matTooltipPosition="above" 
   *ngIf="hasSubmittedAnyForm">Submitted</span>
</button>
<button class="mt-20 mr-20 md:mt-30 button is-primary" *ngIf="resource.AccessPermissions && enrolled" (click)="onClick()">Access Creative Services Platform</button>
<div *ngIf="pendingRenewal">
  <p class="mt-10 mb-0"><em>Your enrollment in Elevate will expire soon, please click the button below to view your
      renewal options.</em></p>
  <button class="mt-20 mr-20 button is-primary md:mt-30" (click)="openSurveyRenewalDialog()">
    <span>
      View My Renewal
    </span>
  </button>
</div>