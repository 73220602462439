import {GeneralTraining} from '../models/training.models';

export const generalTrainingConstant:GeneralTraining[] = [
  {
    Title: 'Long Term Care Training',
    Description: 'Initial and ongoing LTC training to keep you ahead of the game. ',
    Link: 'https://www.training.figmarketing.com/post/long-term-care-training',
  },
  {
    Title: 'Anti-Money Laundering',
    Description: 'Familiarize yourself with all things money laundering and the trainings required. ',
    Link: 'https://www.training.figmarketing.com/post/anti-money-laundering',
  },
  {
    Title: 'NAIC 4-Hour Annuity Training',
    Description: 'One-time training required before you write that first piece of annuity business. ',
    Link: 'https://www.training.figmarketing.com/post/naic-annuity-suitability',
  },
  {
    Title: 'New York - Reg 187 Training',
    Description: 'New regulations, new training. Stay up-to-date on NY Best Interest training. ',
    Link: 'https://www.training.figmarketing.com/post/new-york-reg-187-training',
  },
];