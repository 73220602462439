import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { downloadFile } from 'src/app/shared/helpers/filedownloader.helper';
import { PolicyList } from 'src/app/shared/models/policy.models';
import { fadeIn, fadeInOut } from 'src/app/animations';

@Component({
  animations:[fadeIn, fadeInOut],
  selector: 'app-business',
  templateUrl: './business.component.html',
})
export class BusinessComponent implements OnInit {
  childComponentReference!:PolicyList;
  componentUrl:string = '';
  isExporting!:boolean;
  loading!:boolean;
  policyListType!:string;
  searchText:string|null = null;
  selectedReportType!:string;
  showFullScreenSimonIframe: boolean = false;
  @ViewChild('business-container-content')
  private businessContentDiv!: ElementRef<HTMLElement>

  constructor(
    private activatedRoute:ActivatedRoute,
    private snackbar:MatSnackBar,
    private router:Router,) { }

  ngOnInit(): void {
    this.showFullScreenSimonIframe = !!this.activatedRoute.snapshot.firstChild?.data['hasFullScreenSimonIframe']; // Not working for some
    this.selectedReportType = this.activatedRoute.snapshot.firstChild?.data['reportType'];
    this.componentUrl = this.getComponent();
  }

  changeReportType(reportType:string){
    this.selectedReportType = reportType;
    this.childComponentReference.updateReportType();
  }

  clearSearchText(){
    this.searchText = null;
    this.childComponentReference.setSearchText(null);
  }

  export(){
    this.isExporting = true;
    this.childComponentReference.export().subscribe(blob => {
      downloadFile(blob, this.policyListType == 'Wealth' ? 'InvestmentAccountData.xlsx' : 'PolicyData.xlsx');
      this.isExporting = false;
    },
    (error) => {
      console.log(error);
      this.openSnackbar('Sorry, an error has occurred. Please try again later.', 'is-error');
      this.isExporting = false;
    });
  }

  getComponent() {
    const homeUrl: string = '/Portal/Business/';
    const path: string[] = window.location.pathname.split(homeUrl);
    return path[1];
  }

  onActivate(childComponentReference:PolicyList){
    if (this.businessContentDiv) {
      (this.businessContentDiv.nativeElement as HTMLElement).scrollTop = 0;
    }
    this.childComponentReference = childComponentReference;
    this.policyListType = this.childComponentReference.title;
    this.changeReportType(this.activatedRoute.snapshot.firstChild?.data['reportType']);
  }

  openSnackbar(message:string, toastClass:string){
    this.snackbar.open(message, 'Close', {
      panelClass: [toastClass]
    });
  }

  toggleSidenav() {
    this.childComponentReference.toggleSideNavMenu();
  }

  updateSearchText(){
    this.childComponentReference.setSearchText(this.searchText);
  }
}
