<div class="flex flex-col flex-grow h-0 overflow-auto" [@fadeIn]>
  <div class="flex flex-col flex-none w-full bg-center bg-no-repeat bg-cover h-300"
  style="background-image: url('./assets/images/fig-building-2.jpg');">
    <img src="./assets/images/wave-sm.svg" class="w-full h-auto mt-auto" />
  </div>
  <div class="flex flex-col items-center justify-center flex-none flex-grow p-20 text-center">
    <div class="flex flex-col items-center m-10 mx-auto xs:w-384">
      <span class="flex items-center justify-center w-64 h-64 m-10 rounded-full bg-green-lightest text-green">
        <i class="material-icons mi-36">verified</i>
      </span>
      <h2 class="m-10 text-lg font-bold">You're all set,
        {{' ' + user.FirstName}}!
      </h2>
      <p class="m-10 leading-loose">
        You will be redirected to Carriers and Training to complete product trainings at your discretion.
      </p>
    </div>
  </div>
</div>
<section
  class="flex items-center justify-start flex-none p-10 mt-auto border-t border-solid lg:p-20 border-gray-lighter">
  <button mat-button class="m-10 mr-auto button is-lg is-primary is-outlined" (click)='goToPrevious()'>
    <span class="flex flex-row items-center justify-center">
      <span class="material-icons mi-18">
        chevron_left
      </span>
      <span class="mr-10 leading-none">Previous</span>
    </span>
  </button>
  <button mat-button class="m-10 ml-auto button is-lg is-primary" (click)="returnToPortal()">
    <span class="flex flex-row items-center justify-center">
      <span class="ml-10 leading-none">Go to Portal</span>
      <span class="material-icons mi-18">
        chevron_right
      </span>
    </span>
  </button>
</section>