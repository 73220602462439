<section class="flex flex-row items-center justify-start p-10 border-b border-solid lg:p-20 border-gray-lighter">
  <div class="flex flex-row items-center h-24 m-10 mr-20">
    <h2 class="text-lg font-bold leading-none">{{!allCarriersVisible ? 'My Carriers' : 'All Carriers'}}</h2>
  </div>
  <div class="flex flex-row items-center ml-auto">
    <mat-slide-toggle class="m-10" [(ngModel)]="allCarriersVisible"
      amplitudeEvent="Carriers & Training: Show all carriers - {{ !!allCarriersVisible ? 'off' : 'on'}}">Show All
      Carriers</mat-slide-toggle>

    <button mat-button class="flex items-center justify-center w-full m-10 button is-primary is-outlined"
      (click)="exportContractReport()" aria-label="Export Carriers List">
      Export List
    </button>
  </div>
</section>
<div class="flex flex-col flex-grow overflow-auto">
  <ul class="sticky top-0 flex flex-row pr-8 overflow-x-auto min-w-640 min-h-36"
    [class]="(allCarriersVisible && allCarriersList && allCarriersList.length > 15) || (!allCarriersVisible && myCarrierList && myCarrierList.length > 15) ? 'pr-10' : ''">
    <li
      class="flex flex-row items-center justify-start p-10 pl-20 text-xs font-semibold uppercase border-b border-solid w-1/10 lg:pl-30 border-gray-lighter text-gray focus:outline-none hover:bg-blue-lightest">
      <span *ngIf="!allCarriersVisible">State Appt.</span>
    </li>
    <li
      class="flex flex-row items-center justify-start w-1/5 p-10 text-xs font-semibold uppercase border-b border-solid cursor-pointer min-w-110 border-gray-lighter text-gray focus:outline-none hover:bg-blue-lightest"
      (click)="orderRows('Carrier')">
      <span class="">Carrier</span>
      <span *ngIf="'Carrier' === columnName" class="ml-5 material-icons mi-18">{{reverseOrder ? 'arrow_drop_down' :
        'arrow_drop_up'}}</span>
    </li>
    <li
      class="flex flex-row items-center justify-start p-10 text-xs font-semibold uppercase border-b border-solid min-w-80 w-3/20 border-gray-lighter text-gray focus:outline-none hover:bg-blue-lightest"
      (click)="orderRows('LineOfBusinessName')">
      <span *ngIf="!allCarriersVisible">LOB</span>
      <span *ngIf="'LineOfBusinessName' === columnName" class="ml-5 material-icons mi-18">{{reverseOrder ?
        'arrow_drop_down' : 'arrow_drop_up'}}</span>
    </li>
    <li
      class="flex flex-row items-center justify-start p-10 text-xs font-semibold uppercase border-b border-solid cursor-pointer min-w-80 w-3/20 border-gray-lighter text-gray focus:outline-none hover:bg-blue-lightest"
      (click)="orderRows('ContractStatus')">
      <span *ngIf="!allCarriersVisible">Status</span>
      <span *ngIf="'ContractStatus' === columnName" class="ml-5 material-icons mi-18">{{reverseOrder ? 'arrow_drop_down'
        : 'arrow_drop_up'}}</span>
    </li>
    <li
      class="flex flex-row items-center justify-start p-10 text-xs font-semibold uppercase border-b border-solid cursor-pointer min-w-110 w-3/20 border-gray-lighter text-gray focus:outline-none hover:bg-blue-lightest"
      (click)="orderRows('WritingNumbers')">
      <span *ngIf="!allCarriersVisible">Writing Numbers</span>
      <span *ngIf="'WritingNumbers' === columnName" class="ml-5 material-icons mi-18">{{reverseOrder ? 'arrow_drop_down'
        : 'arrow_drop_up'}}</span>
    </li>
    <li
      class="flex flex-row items-center justify-start w-1/4 p-10 pr=10 lg:pr-20 min-w-256 text-xs font-semibold uppercase border-b border-solid border-gray-lighter text-gray focus:outline-none">
      <span>Training Guides</span>
    </li>
  </ul>
  <div class="flex flex-col flex-grow pb-20 overflow-auto" *ngIf="!loading" [@fadeIn]>
    <ng-container *ngIf="!!allCarriersVisible">
      <div class="flex flex-col min-w-640"
        *ngFor="let c of allCarriersList; let first = first; let last = last; let index = index" @fadeIn>
        <div class="flex flex-row w-auto border-solid border-gray-lighter focus:outline-none"
          [class]="last ? '' : 'border-b'">
          <div class="flex flex-row items-center justify-start p-10 pl-20 w-1/10 lg:pl-30">
            <span class="text-sm text-grey-light"><em></em></span>
          </div>
          <div class="flex flex-row items-center justify-start w-1/5 p-10 text-sm font-bold min-w-110">
            <span>{{c.Carrier}}</span>
          </div>
          <div class="flex flex-row items-center justify-start p-10 text-sm min-w-80 w-3/20">
            <span class="text-sm text-grey-light"><em></em></span>
          </div>
          <div class="flex flex-row items-center justify-start p-10 text-sm min-w-80 w-3/20">
            <span class="text-sm text-grey-light"><em></em></span>
          </div>
          <div class="flex flex-col items-stretch justify-center p-10 text-sm min-w-110 w-3/20">
            <span class="text-sm text-grey-light"><em></em></span>
          </div>
          <div class="flex flex-row items-center justify-start w-1/4 p-10 pr-10 lg:pr-20 min-w-256">
            <button
              class="flex flex-row items-center justify-center pl-0 mr-20 text-xs text-center button is-primary is-outlined is-sm"
              [class]="!!c.TrainingUrl ? '' : 'disabled'" (click)="!!c.TrainingUrl ? openTraining(c.TrainingUrl) : ''"
              target="_blank" attr.aria-label="{{c.TrainingUrl ? '' : 'No training available yet'}}"
              [attr.aria-label]="'link to ' + c.Carrier + ' Training'" *ngIf="c.TrainingUrl && hasGuidePermissions()">
              <span class="flex items-center justify-center h-full px-5 mr-10 border-r border-solid">
                <span class="material-icons mi-18">school</span>
              </span>
              <span>Guide</span>
            </button>
            <ng-container *showIfOne="'ProductRDOptedIn:View'">
              <a class="flex flex-row items-center justify-center pl-0 text-xs text-center button is-primary is-outlined is-sm"
                [class]="!!c.HasSimonTraining ? '' : 'disabled'"
                [routerLink]="'/Portal/CarriersTraining/ProductTraining'" [queryParams]="{searchValue: !!c.SimonTitle ? c.SimonTitle : c.Carrier}"
                [attr.aria-label]="'link to ' + c.Carrier + ' Training'"
                *ngIf="c.TrainingUrl && hasSimonTraining(c.Carrier)">
                <span class="flex items-center justify-center h-full px-5 mr-10 border-r border-solid">
                  <span class="material-icons-outlined mi-18">format_list_bulleted</span>
                </span>
                <span>Courses</span>
              </a>
            </ng-container>
            <p class="text-sm text-grey-light " *ngIf="!c.TrainingUrl || !hasGuidePermissions()"><em>Not Applicable</em>
            </p>
          </div>
        </div>
      </div>
      <div *ngIf="!allCarriersList.length" @fadeInOut>
        <p class="w-full p-40 text-center">No carriers are available.</p>
      </div>
    </ng-container>
    <ng-container *ngIf="!allCarriersVisible">
      <div class="flex flex-col min-w-640"
        *ngFor="let c of myCarrierList; let first = first; let last = last; let index = index" @fadeIn>
        <div class="flex flex-row w-auto border-solid border-gray-lighter focus:outline-none"
          [class]="last ? '' : 'border-b'">
          <div class="flex flex-row items-center justify-start p-10 pl-20 w-1/10 lg:pl-30">
            <button class="icon-button is-primary is-inverted is-sm" (click)="getStateAppointments(c)"
              matTooltip="Toggle State Appointments"
              attr.aria-label="{{c.ShowDetails ? 'Hide' : 'Show'}} state appointments">
              <span class="material-icons mi-18">{{c.ShowDetails ? 'keyboard_arrow_down' :
                'keyboard_arrow_right'}}</span>
            </button>
          </div>
          <div class="flex flex-row items-center justify-start w-1/5 p-10 text-sm font-bold min-w-110">
            <span>{{c.Carrier}}</span>
          </div>
          <div class="flex flex-row items-center justify-start p-10 text-sm min-w-80 w-3/20">
            <span>{{c.LineOfBusinessName}}</span>
          </div>
          <div class="flex flex-row items-center justify-start p-10 text-sm min-w-80 w-3/20"
            [class]="'Active'=== c.ContractStatus ? 'text-green font-semibold' : ''">
            <span [textContent]="c.ContractStatus"></span>
          </div>
          <div class="flex flex-col items-stretch justify-center p-10 text-sm min-w-110 w-3/20">
            <span class="flex flex-row items-center justify-start" [ngClass]="{'mb-5':!last}"
              *ngFor="let num of c.WritingNumberArray; let last = last" attr.aria-label="{{num.type}}">
              <span class="mr-5 material-icons mi-18 text-gray">{{num.icon}}</span>
              <span>{{num.value}}</span>
            </span>
          </div>
          <div class="flex flex-row items-center justify-start w-1/4 p-10 pr-10 lg:pr-20 min-w-256">
            <button
              class="flex flex-row items-center justify-center pl-0 mr-20 text-xs text-center button is-primary is-outlined is-sm"
              [class]="!!c.TrainingUrl ? '' : 'disabled'" (click)="!!c.TrainingUrl ? openTraining(c.TrainingUrl) : ''"
              target="_blank" attr.aria-label="{{c.TrainingUrl ? '' : 'No training available yet'}}"
              [attr.aria-label]="'link to ' + c.Carrier + ' Training'" *ngIf="c.TrainingUrl && hasGuidePermissions()">
              <span class="flex items-center justify-center h-full px-5 mr-10 border-r border-solid">
                <span class="material-icons mi-18">school</span>
              </span>
              <span>Guide</span>
            </button>
            <ng-container *showIfOne="'ProductRDOptedIn:View'">
              <a class="flex flex-row items-center justify-center pl-0 text-xs text-center button is-primary is-outlined is-sm"
                [class]="!!c.CarrierTraining && !!c.CarrierTraining.HasSimonTraining ? '' : 'disabled'"
                [routerLink]="'/Portal/CarriersTraining/ProductTraining'" [queryParams]="{searchValue: !!c.CarrierTraining && !!c.CarrierTraining.SimonTitle ? c.CarrierTraining.SimonTitle : c.Carrier}"
                target="_self" attr.aria-label="{{c.TrainingUrl ? '' : 'No training available yet'}}"
                [attr.aria-label]="'link to ' + c.Carrier + ' Training'"
                *ngIf="c.TrainingUrl && hasApprovedLOB(c.LineOfBusinessName) && !!c.CarrierTraining && !!c.CarrierTraining.HasSimonTraining">
                <span class="flex items-center justify-center h-full px-5 mr-10 border-r border-solid">
                  <span class="material-icons-outlined mi-18">format_list_bulleted</span>
                </span>
                <span>Courses</span>
              </a>
            </ng-container>
            <p class="text-sm text-grey-light " *ngIf="!c.TrainingUrl || !hasGuidePermissions()"><em>Not Applicable</em>
            </p>
          </div>
        </div>
        <div *ngIf="!!c.ShowDetails">
          <div class="flex flex-row bg-opacity-25 bg-gray-lighter"
            [ngClass]="{'pb-5 border-b border-solid border-gray-lighter': last, 'pt-5': first}"
            *ngFor="let a of c?.ContractDetails?.Appointments; let first = first; let last = last">
            <div class="px-10 py-5 text-sm w-1/10"></div>
            <div class="w-1/5 px-10 py-5 text-sm font-semibold" [textContent]="a.StateName"></div>
            <div class="px-10 py-5 text-sm w-3/20 " [textContent]="a.LineOfBusiness"></div>
            <div class="px-10 py-5 text-sm w-3/20 "
              [ngClass]="{'text-green font-semibold' : a.AppointmentStatus === 'Active'}"
              [textContent]="a.AppointmentStatus"></div>
          </div>
        </div>
      </div>
      <div *ngIf="!myCarrierList.length" @fadeInOut>
        <p class="w-full p-40 text-center">No carriers are available.</p>
      </div>
    </ng-container>
  </div>

  <div class="relative flex-grow p-40 m-auto loader-wrap" *ngIf="!!loading" [@fadeIn]>
    <div class="loader-sm is-blue"></div>
  </div>
</div>