import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { QuestionnairePopupComponent } from 'src/app/portal/components/toolbox/questionnaire-popup/questionnaire-popup/questionnaire-popup.component';
import { ToolboxCategory, ToolboxCategoryEnum, ToolboxClassEnum, ToolboxGrouping, ToolboxIconEnum, ToolboxTile } from 'src/app/shared/models/toolbox.models';
import { PermissionService } from './auth/permission.service';
import { SsoService } from './sso.service';
import { UserApiService } from './http/user-api.service';
import { tileIdsBySimonGrouping } from 'src/app/shared/constants/toolbox.constants';
import { nationwideLifeHtml } from 'src/app/shared/constants/nationwide-life.constant';
import { MoneyguideSsoModalComponent } from 'src/app/portal/modals/moneyguide-sso-modal/moneyguide-sso-modal.component';
import { SsoApiService } from './http/sso-api.service';

@Injectable({
  providedIn: 'root'
})
export class ToolboxService {

  constructor(
    private dialog: MatDialog,
    private permissionService: PermissionService,
    private router: Router,
    private ssoApiService: SsoApiService,
    private ssoService: SsoService,
    private userApiService: UserApiService,) { }

  private tileIdsBySimonGrouping: Record<string, ToolboxGrouping> = tileIdsBySimonGrouping;

  getTiles(): ToolboxTile[] {
    return this.getUserPermittedTiles().filter(x => x.IsVisible);
  }

  getUserPermittedTiles(): ToolboxTile[] {
    const permittedTiles: ToolboxTile[] = [];

    this.toolboxTiles.forEach(tile => {
      if (tile.Permission == null || this.permissionService.hasAllPermissions(tile.Permission))
        permittedTiles.push(tile);
    });

    return permittedTiles;
  }

  getFeaturedTiles(): ToolboxTile[] {
    const permittedTiles = this.getTiles();
    const featuredTiles: ToolboxTile[] = [];

    permittedTiles.forEach(tile => {
      if (tile.IsFeatured)
        featuredTiles.push(tile);
    });

    return featuredTiles;
  }

  getUserFavorites(userId: string): ToolboxTile[] {
    const permittedTiles = this.getTiles();
    const favoriteTiles: ToolboxTile[] = [];

    this.userApiService.getUserToolboxFavorites(userId).subscribe(favoriteIds => {
      favoriteIds.forEach(id => {
        const tile = permittedTiles.find(x => x.Id == id);
        if (tile) {
          tile.IsFavorite = true;
          favoriteTiles.push(tile);
        }
      });
    });

    return favoriteTiles;
  }

  getTilesBySimonGrouping(grouping: string): ToolboxTile[] {
    const desiredTiles = this.tileIdsBySimonGrouping[grouping];
    const ids = Object.keys(desiredTiles);
    const tiles = this.getUserPermittedTiles().filter(tile => ids.includes(tile.Id));
    tiles.forEach(tile => {
      tile.Category = desiredTiles[tile.Id];
    });
    return tiles;
  }

  getAllCategories(): ToolboxCategory[] {
    return this.toolboxCategories.filter(x => !x.IsVisible);
  }

  getVisibleCategories(): ToolboxCategory[] {
    return this.toolboxCategories.filter(x => x.IsVisible).sort((a, b) => a.Order - b.Order);
  }

  readonly toolboxCategories: ToolboxCategory[] = [
    {
      Name: ToolboxCategoryEnum.Forms,
      Description: 'Collection of downloadable and submittable forms',
      Class: ToolboxClassEnum.Green,
      Icon: ToolboxIconEnum.Document,
      Selected: false,
      Order: 2,
      IsVisible: true
    },
    {
      Name: ToolboxCategoryEnum.RatesAndQuotes,
      Description: 'Find and run quotes on different platforms',
      Class: ToolboxClassEnum.Pink,
      Icon: ToolboxIconEnum.Coin,
      Selected: false,
      Order: 1,
      IsVisible: true
    },
    {
      Name: ToolboxCategoryEnum.Calculators,
      Description: 'Financial planing and retirement tools',
      Class: ToolboxClassEnum.Blue,
      Icon: ToolboxIconEnum.Calculator,
      Selected: false,
      Order: 3,
      IsVisible: true
    },
    {
      Name: ToolboxCategoryEnum.Tutorials,
      Description: 'Tutorials',
      Class: ToolboxClassEnum.Purple,
      Icon: ToolboxIconEnum.School,
      Selected: false,
      Order: 4,
      IsVisible: true
    },
    {
      Name: ToolboxCategoryEnum.SimpleFinancialPlanning,
      Description: 'Financial planning made simple with easy to use tools that provide high level illustrations of your clients portfolio.',
      Class: ToolboxClassEnum.Orange,
      Icon: ToolboxIconEnum.Assessment,
      Selected: false,
      Order: 6,
      IsVisible: false
    },
    {
      Name: 'Full Financial Planning',
      Description: 'These tools provide the full spectrum of financial planning that provide unique approaches to analyzing and displaying your clients portfolio.',
      Class: ToolboxClassEnum.Indigo,
      Icon: ToolboxIconEnum.Analytics,
      Selected: false,
      Order: 7,
      IsVisible: false
    },
    {
      Name: ToolboxCategoryEnum.Application,
      Description: 'Description',
      Class: ToolboxClassEnum.Blue,
      Icon: ToolboxIconEnum.Calculator,
      Selected: false,
      Order: 5,
      IsVisible: false
    },
    {
      Name: ToolboxCategoryEnum.CalculatorsAndRates,
      Description: 'Deep dive into your clients distinct needs with these tailored tools.',
      Class: ToolboxClassEnum.Blue,
      Icon: ToolboxIconEnum.Calculator,
      Selected: false,
      Order: 8,
      IsVisible: false
    },
    {
      Name: ToolboxCategoryEnum.IndustryEvents,
      Description: 'Events',
      Class: ToolboxClassEnum.Teal,
      Icon: ToolboxIconEnum.Event,
      Selected: false,
      Order: 9,
      IsVisible: true
    },
  ];

  readonly toolboxTiles: ToolboxTile[] = [
    //GUID Generator: https://www.guidgenerator.com/online-guid-generator.aspx
    {
      Id: 'e836d534-8558-45df-83be-52e7c84374e9',
      Name: 'Annuity Forms Wizard',
      ShortDescription: 'Quick access to up-to-date annuity form packets.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.Forms,
      Tags: [ToolboxCategoryEnum.Forms, ToolboxCategoryEnum.Application, 'Annuity'],
      Act: (): void => {
        this.ssoService.iPipelineFormsSso();
      },
      Permission: 'IpipelineFormsQuotesAnnuity:Access|AnnuityFormsWizard:View',
      Class: ToolboxClassEnum.Green,
      Icon: ToolboxIconEnum.Document,
      Order: null,
      ImgUrl: null,
      IsFeatured: false,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: 'abd9cc24-16d7-461e-ab1f-ee54d20904f9',
      Name: 'Start Application',
      ShortDescription: 'Begin a Firelight, IPipeline, or paper application with the click of a button.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.Application,
      Tags: [ToolboxCategoryEnum.Application, 'Firelight', 'e-applications'],
      Act: (): void => {
        // If user is navigating from an education page, prefill their line of business
        const pathname = window.location.pathname;
        let lobId;
        if (pathname.includes('Annuities')) lobId = 1;
        if (pathname.includes('CarePlanning')) lobId = 2;
        if (pathname.includes('Life')) lobId = 4;

        this.router.navigate(['Portal/ProductRD/EApplication/Workflow/PrepareEApplication'], lobId ? { queryParams: { lobId: lobId } } : {});
      },
      Permission: 'ProductRDOptedIn:View',
      Class: ToolboxClassEnum.Green,
      Icon: ToolboxIconEnum.Document,
      Order: null,
      ImgUrl: null,
      IsFeatured: false,
      IsFavorite: false,
      Loading: null,
      IsVisible: false
    },
    {
      Id: 'e9d34063-14d3-4e3f-8a96-a337926f738c',
      Name: 'RetireUp Pro',
      ShortDescription: 'An online platform for personalized retirement planning.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.RatesAndQuotes,
      Tags: [this.toolboxCategories[1].Name, 'RetireUp Pro', 'Retire Up Pro'],
      Act: (): void => {
        this.router.navigate(['/Portal/FigPlatform/Details/RetireUp']);
      },
      Permission: 'RetireUp:View',
      Class: ToolboxClassEnum.Pink,
      Icon: ToolboxIconEnum.Coin,
      IsFeatured: true,
      Order: 2,
      ImgUrl: 'https://images.unsplash.com/photo-1533444273691-ebf51af8fd9c?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MXx8cmV0aXJlbWVudHxlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: '196ec350-038c-4303-b3f7-7c166775f62a',
      Name: 'Annuity Rate Watch',
      ShortDescription: 'An extensive rate comparison tool for annuity products.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.RatesAndQuotes,
      Tags: [ToolboxCategoryEnum.RatesAndQuotes, 'Annuity'],
      Act: (): void => {
        this.ssoService.annuityRateWatchSso();
      },
      Permission: 'AnnuityRateWatch:Access',
      Class: ToolboxClassEnum.Pink,
      Icon: ToolboxIconEnum.Coin,
      IsFeatured: true,
      Order: 1,
      ImgUrl: 'https://images.unsplash.com/photo-1520632461690-67205b04e73d?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MTV8fGxpZmV8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: 'b07f4e9e-3a82-4952-a7ad-e84e4cdd7ff9',
      Name: 'Annuity Rate Watch: Lifetime Income Tool',
      ShortDescription: 'An extensive rate comparison tool for annuity products.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.Calculators,
      Tags: [ToolboxCategoryEnum.Calculators, 'Annuity'],
      Act: (): void => {
        this.router.navigate(['/Portal/Toolbox/ARWLifetimeIncomeTool']);
      },
      Permission: 'AnnuityRateWatch:Access',
      Class: ToolboxClassEnum.Blue,
      Icon: ToolboxIconEnum.Calculator,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: '11abc464-68ff-4c65-94d4-8bb6dbda329d',
      Name: 'Annuity Rate Watch: Top MYGA',
      ShortDescription: 'Top Multi-Year Guaranteed Annuities.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.RatesAndQuotes,
      Tags: [ToolboxCategoryEnum.RatesAndQuotes, 'Annuity'],
      Act: (): void => {
        this.router.navigate(['/Portal/Toolbox/ARWTopMYGA']);
      },
      Permission: 'AnnuityRateWatch:Access',
      Class: ToolboxClassEnum.Pink,
      Icon: ToolboxIconEnum.Coin,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: '115a41bb-654f-4b74-8d64-a8119180b70b',
      Name: 'ACM Signature Guarantee Agreement',
      ShortDescription: 'Download the Alphastar Medallion Signature Guarantee Agreement.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.Forms,
      Tags: [ToolboxCategoryEnum.Forms, 'Annuity'],
      Act: (): void => {
        window.open('https://www.figmarketing.com/files/ASCM_Signature_Guarantee_Agreement.pdf');
      },
      Permission: 'AscmMedallionAgreement:View',
      Class: ToolboxClassEnum.Green,
      Icon: ToolboxIconEnum.Document,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: 'f6d1af6a-b915-4fb6-9691-9c4b27f12504',
      Name: 'ACM Signature Guarantee Guide',
      ShortDescription: 'Download the Alphastar Medallion Signature Guarantee Guide.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.Forms,
      Tags: [ToolboxCategoryEnum.Forms, 'Annuity'],
      Act: (): void => {
        window.open('https://www.figmarketing.com/files/ASCM_Signature_Guarantee_Guide.pdf');
      },
      Permission: 'AscmMedallionGuide:View',
      Class: ToolboxClassEnum.Green,
      Icon: ToolboxIconEnum.Document,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: '4f93707e-e0fe-4faf-bbb9-034ae2dbf939',
      Name: 'Life Forms Wizard',
      ShortDescription: 'Quick access to up-to-date life form packets.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.Forms,
      Tags: [this.toolboxCategories[0].Name, this.toolboxCategories[3].Name, 'Life'],
      Act: (): void => {
        this.ssoService.iPipelineFormsSso();
      },
      Permission: 'IpipelineFormsQuotesLife:Access|LifeFormsWizard:View',
      Class: ToolboxClassEnum.Green,
      Icon: ToolboxIconEnum.Document,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: '4ad2eb64-6bfd-428c-9e43-55442223f653',
      Name: 'HIPAA Form',
      ShortDescription: 'Download FIG’s HIPAA form.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.Forms,
      Tags: [ToolboxCategoryEnum.Forms, 'Life'],
      Act: (): void => {
        window.open('https://www.figmarketing.com/files/FIG_HIPAA.pdf');
      },
      Permission: 'HipaaForm:View',
      Class: ToolboxClassEnum.Green,
      Icon: ToolboxIconEnum.Document,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: '604a987d-6014-4078-afe7-7f375ba8084d',
      Name: 'Life Application Process',
      ShortDescription: 'Walk through our application process for life.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.Forms,
      Tags: [ToolboxCategoryEnum.Forms, 'Life'],
      Act: (): void => {
        window.open('https://www.figmarketing.com/files/Life_Application_Process.pdf');
      },
      Permission: 'LifeApplicationProcess:View',
      Class: ToolboxClassEnum.Green,
      Icon: ToolboxIconEnum.Document,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: 'ca665c03-d696-4210-8e1d-a8d6075d418a',
      Name: 'Care Planning Forms Wizard',
      ShortDescription: 'Quick access to up-to-date Care Planning form packets.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.Forms,
      Tags: [ToolboxCategoryEnum.Forms, 'Care Planning', 'LTC', 'Long Term Care'],
      Act: (): void => {
        this.ssoService.iPipelineFormsSso();
      },
      Permission: 'IpipelineFormsQuotesLtc:Access|LtcFormsWizard:View',
      Class: ToolboxClassEnum.Green,
      Icon: ToolboxIconEnum.Document,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: '04c7df60-bbc3-464b-993e-5190add96143',
      Name: 'Care Planning Liability Release Form',
      ShortDescription: 'Download the Care Planning Liability Release Form.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.Forms,
      Tags: [ToolboxCategoryEnum.Forms, 'Care Planning', 'LTC', 'Long Term Care'],
      Act: (): void => {
        window.open('https://www.figmarketing.com/files/LTC-Liabilty-Form.pdf');
      },
      Permission: 'LtcLiabilityReleaseForm:View',
      Class: ToolboxClassEnum.Green,
      Icon: ToolboxIconEnum.Document,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: 'fe83f194-d0e6-40ed-9dcf-79f847711391',
      Name: 'Care Planning Pre-qualification Form',
      ShortDescription: 'Return to FIG to get carrier underwriter feedback before taking an application.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.Forms,
      Tags: [ToolboxCategoryEnum.Forms, 'Care Planning', 'LTC', 'Long Term Care'],
      Act: (): void => {
        this.dialog.open(QuestionnairePopupComponent);
      },
      Permission: 'LtcLiabilityReleaseForm:View',
      Class: ToolboxClassEnum.Green,
      Icon: ToolboxIconEnum.Document,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: '408a04a9-2fb5-47aa-8f22-b9e37dda2740',
      Name: 'Nationwide CareMatters Quoter',
      ShortDescription: 'Get quoted for Nationwide\'s CareMatters long-term healthcare solution.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.Forms,
      Tags: [ToolboxCategoryEnum.Forms, ToolboxCategoryEnum.RatesAndQuotes, 'Care Planning', 'LTC', 'Long Term Care'],
      Act: (): void => {
        const w = window.open()!;
        w?.document.write(nationwideLifeHtml);
      },
      Permission: 'LtcLiabilityReleaseForm:View',
      Class: ToolboxClassEnum.Green,
      Icon: ToolboxIconEnum.Document,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: '7ec14b46-abc8-4221-9d97-1db59e2c6a17',
      Name: 'Asset Identifier',
      ShortDescription: 'Use in conjunction with "3 Steps" Sales Process. Revisit annually.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.Forms,
      Tags: [ToolboxCategoryEnum.Forms, ToolboxCategoryEnum.RatesAndQuotes, 'Care Planning', 'LTC', 'Long Term Care'],
      Act: (): void => {
        window.open('https://www.figmarketing.com/files/Asset_Identifier_2018.pdf');
      },
      Permission: 'LtcLiabilityReleaseForm:View',
      Class: ToolboxClassEnum.Green,
      Icon: ToolboxIconEnum.Document,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: 'aa8bf0ef-ffbf-4841-9f62-d5c9d94342ad',
      Name: 'Care Planning Pre-Approach Letter',
      ShortDescription: 'Go back to your existing book of business using this product agnostic prospecting letter.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.Forms,
      Tags: [ToolboxCategoryEnum.Forms, 'Care Planning', 'LTC', 'Long Term Care'],
      Act: (): void => {
        window.open('https://www.figmarketing.com/files/Care_Planning_Pre-Approach_Letter.pdf');
      },
      Permission: 'LtcLiabilityReleaseForm:View',
      Class: ToolboxClassEnum.Green,
      Icon: ToolboxIconEnum.Document,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: 'a4009ea4-2d89-4223-aa64-833fd60aea59',
      Name: 'LTCI Quote Engine',
      ShortDescription: 'Quick side-by-side quoting for standalone LTC products.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.RatesAndQuotes,
      Tags: [ToolboxCategoryEnum.RatesAndQuotes, 'Care Planning', 'LTC'],
      Act: (): void => {
        window.open('https://www.ltcipartners.com/generate-a-quote-stratecision');
      },
      Permission: 'LtciPartnersWebsite:View',
      Class: ToolboxClassEnum.Pink,
      Icon: ToolboxIconEnum.Coin,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: '4bb324e0-c42a-4a15-867a-d1af0c91c81e',
      Name: 'SPIA/DIA Quotes',
      ShortDescription: 'Access to real-time quoting engine for SPIA\'s and DIA\'s.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.RatesAndQuotes,
      Tags: [ToolboxCategoryEnum.RatesAndQuotes, 'Annuity', 'SPIA', 'DIA'],
      Act: (): void => {
        this.ssoService.cannexSso();
      },
      Permission: 'Cannex:Access',
      Class: ToolboxClassEnum.Pink,
      Icon: ToolboxIconEnum.Coin,
      IsFeatured: true,
      Order: 3,
      ImgUrl: 'https://images.unsplash.com/photo-1561112079-2c3226d9c559?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8MzB8fGNhbGN1bGF0b3JzfGVufDB8fDB8&auto=format&fit=crop&w=500&q=60',
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: '1de65e6f-238c-4ea4-ade5-1adafe3ea152',
      Name: 'Annexus Direct Quotes',
      ShortDescription: 'Run quotes on the Annexus platform.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.RatesAndQuotes,
      Tags: [ToolboxCategoryEnum.RatesAndQuotes, 'Annuity'],
      Act: (): void => {
        this.ssoService.annexusSso();
      },
      Permission: 'AnnexusQuotes:Access',
      Class: ToolboxClassEnum.Pink,
      Icon: ToolboxIconEnum.Coin,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: '3c335d5e-cf51-4363-8199-642fa5e723cf',
      Name: 'Ensight',
      ShortDescription: 'Simplify insurance sales with Ensight\'s<sup>&trade;</sup> stunning visual customer experience.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.RatesAndQuotes,
      Tags: [ToolboxCategoryEnum.RatesAndQuotes, 'Life'],
      Act: (): void => {
        this.ssoService.ensightSso();
      },
      Permission: 'Ensight:Access',
      Class: ToolboxClassEnum.Pink,
      Icon: ToolboxIconEnum.Coin,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: 'abd7fba2-7d2f-4e92-a6a4-5488d588fd4a',
      Name: 'Term Life Quote Engine',
      ShortDescription: 'Access to real-time life quoting.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.RatesAndQuotes,
      Tags: [ToolboxCategoryEnum.RatesAndQuotes, 'Term Life', 'Life'],
      Act: (): void => {
        this.ssoService.iPipelineLifeQuotesSso();
      },
      Permission: 'IpipelineFormsQuotesLife:Access|LifeQuoteEngine:View',
      Class: ToolboxClassEnum.Pink,
      Icon: ToolboxIconEnum.Coin,
      IsFeatured: true,
      Order: 4,
      ImgUrl: 'https://images.unsplash.com/photo-1502651492682-6c7b46d75d85?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MTF8fGxpZmV8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: '76eeb1b0-8aaa-4d53-bbe4-4a509a363bf9',
      Name: 'ABLTC Quote Request',
      ShortDescription: 'Return to FIG for customized solutions for your clients.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.Forms,
      Tags: [ToolboxCategoryEnum.Forms, ToolboxCategoryEnum.RatesAndQuotes, 'Care Planning', 'Long Term Care', 'LTC'],
      Act: (): void => {
        window.open('https://www.figmarketing.com/files/2019_ASSET-BASED_LTC_QUOTE_REQUEST_FORM.pdf');
      },
      Permission: 'LtcLiabilityReleaseForm:View',
      Class: ToolboxClassEnum.Green,
      Icon: ToolboxIconEnum.Document,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: 'fa4998bb-8950-42af-a4d9-9abeaa7d6f7b',
      Name: 'U.S. 1040EZ Tax Estimator',
      ShortDescription: 'The 1020EZ tool lets you quickly calculate your tax bill compared to the 1040 form.',
      LongDescription: 'Many people can use the 1040EZ tax form instead of the more complicated 1040 to calculate their taxes.Containing only seven required inputs, the 1040EZ is one of the quickest forms to calculate your tax bill.',
      Category: ToolboxCategoryEnum.Calculators,
      Tags: [ToolboxCategoryEnum.Calculators, 'Tax'],
      Act: (): void => {
        window.open('/DinkyTown/TaxEZ1040.html', 'dinkytown');
      },
      Permission: 'Calculators:View',
      Class: ToolboxClassEnum.Blue,
      Icon: ToolboxIconEnum.Calculator,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: 'fa4998bb-8950-42af-a4d9-9abeaa7d6f7b',
      Name: 'Life Settlement Policy Valuation',
      ShortDescription: 'Get non-mortality based pricing for life policies in the life settlement market. Used for trust valuations, replacement policies and surrender alternatives for maximum value.',
      LongDescription: 'Use this calculator to get a non-mortality based pricing for your client’s life policy in the life settlement market.  Use for trust valuations, replacement policies, and surrender alternatives to get the maximum value for your client.',
      Category: ToolboxCategoryEnum.Calculators,
      Tags: [ToolboxCategoryEnum.Calculators, 'Life'],
      Act: (): void => {
        this.ssoService.mapleLifeSso();
      },
      Permission: 'Calculators:View',
      Class: ToolboxClassEnum.Blue,
      Icon: ToolboxIconEnum.Calculator,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: '814b38a7-62c3-4ddd-bd3c-70f5b7cf27b4',
      Name: 'Savings Goals',
      ShortDescription: 'What will it take to reach your savings goal? This financial calculator helps you find out.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.Calculators,
      Tags: [ToolboxCategoryEnum.Calculators, 'Savings'],
      Act: (): void => {
        window.open('/DinkyTown/Savings.html', 'dinkytown');
      },
      Permission: 'Calculators:View',
      Class: ToolboxClassEnum.Blue,
      Icon: ToolboxIconEnum.Calculator,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: '80dd3598-5869-42f8-bf41-f05a7c7eb6ec',
      Name: 'Savings, Taxes, & Inflation',
      ShortDescription: 'Determine how much your savings will be worth with these two important variables in mind.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.Calculators,
      Tags: [ToolboxCategoryEnum.Calculators, 'Savings', 'Taxes', 'Inflation'],
      Act: (): void => {
        window.open('/DinkyTown/SavingsVariables.html', 'dinkytown');
      },
      Permission: 'Calculators:View',
      Class: ToolboxClassEnum.Blue,
      Icon: ToolboxIconEnum.Calculator,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: 'd286ea71-d62b-4a3f-9c1d-068255beed17',
      Name: 'Net Worth',
      ShortDescription: 'Determine your net worth, and how it could grow (or shrink!) over the next ten years.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.Calculators,
      Tags: [ToolboxCategoryEnum.Calculators, 'Finance'],
      Act: (): void => {
        window.open('/DinkyTown/NetWorth.html', 'dinkytown');
      },
      Permission: 'Calculators:View',
      Class: ToolboxClassEnum.Blue,
      Icon: ToolboxIconEnum.Calculator,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: 'f49d50b4-3c7c-4b34-8629-f38c7cf40c4e',
      Name: 'Investment Returns',
      ShortDescription: 'There is more to investing than knowing your annual rate of return. Use this calculator to help you see how inflation, taxes and your time horizon can impact your bottom line.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.Calculators,
      Tags: [ToolboxCategoryEnum.Calculators, 'Investments'],
      Act: (): void => {
        window.open('/DinkyTown/InvestmentReturn.html', 'dinkytown');
      },
      Permission: 'Calculators:View',
      Class: ToolboxClassEnum.Blue,
      Icon: ToolboxIconEnum.Calculator,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: 'bffcfaf0-4338-43c5-8740-43d43068160e',
      Name: '72(t)',
      ShortDescription: 'The IRS Rule 72T allows for penalty free, early withdrawals from retirement accounts. Use this calculator to determine your allowable 72T Distribution and how it can help fund your early retirement.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.Calculators,
      Tags: [ToolboxCategoryEnum.Calculators, 'Retire', 'Retirement'],
      Act: (): void => {
        window.open('/DinkyTown/Retire72T.html', 'dinkytown');
      },
      Permission: 'Calculators:View',
      Class: ToolboxClassEnum.Blue,
      Icon: ToolboxIconEnum.Calculator,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: '112546bd-c193-44ec-86bf-e1771dfbeef6',
      Name: 'Retirement Income',
      ShortDescription: 'Determine how much monthly income your retirement savings may provide you in your retirement.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.Calculators,
      Tags: [ToolboxCategoryEnum.Calculators, 'Retire', 'Retirement'],
      Act: (): void => {
        window.open('/DinkyTown/RetirementIncome.html', 'dinkytown');
      },
      Permission: 'Calculators:View',
      Class: ToolboxClassEnum.Blue,
      Icon: ToolboxIconEnum.Calculator,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: '92c8f735-349e-41c1-b1c8-6586fbceb29e',
      Name: 'Retirement Planner',
      ShortDescription: 'Determine if your retirement plan is on track - and learn how to keep it there.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.Calculators,
      Tags: [ToolboxCategoryEnum.Calculators, 'Retire', 'Retirement'],
      Act: (): void => {
        window.open('/DinkyTown/RetirementPlan.html', 'dinkytown');
      },
      Permission: 'Calculators:View',
      Class: ToolboxClassEnum.Blue,
      Icon: ToolboxIconEnum.Calculator,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: 'b8c63bd3-6293-4dd4-b33b-282596187921',
      Name: 'RMD & Stretch IRA',
      ShortDescription: 'Determine how you can stretch out your retirement plan distributions for as long as possible.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.Calculators,
      Tags: [ToolboxCategoryEnum.Calculators, 'Retire', 'Retirement'],
      Act: (): void => {
        window.open('/DinkyTown/StretchIRA.html', 'dinkytown');
      },
      Permission: 'Calculators:View',
      Class: ToolboxClassEnum.Blue,
      Icon: ToolboxIconEnum.Calculator,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: '0e9db232-ea40-4249-ab71-3e52a7c9c43f',
      Name: 'Roth IRA',
      ShortDescription: 'Compare the Roth IRA to an ordinary taxable investment.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.Calculators,
      Tags: [ToolboxCategoryEnum.Calculators, 'Retire', 'Retirement'],
      Act: (): void => {
        window.open('/DinkyTown/RothIRA.html', 'dinkytown');
      },
      Permission: 'Calculators:View',
      Class: ToolboxClassEnum.Blue,
      Icon: ToolboxIconEnum.Calculator,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: '6117ed2e-7e1b-456d-96dc-9cd1c81d47cf',
      Name: 'Roth IRA Conversion',
      ShortDescription: 'This calculator will show the advantage, if any, of converting your IRA to a Roth.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.Calculators,
      Tags: [ToolboxCategoryEnum.Calculators, 'Retire', 'Retirement'],
      Act: (): void => {
        window.open('/DinkyTown/RothTransfer.html', 'dinkytown');
      },
      Permission: 'Calculators:View',
      Class: ToolboxClassEnum.Blue,
      Icon: ToolboxIconEnum.Calculator,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: '225a3946-8700-4a40-b915-3ba64c2a9c6c',
      Name: 'Roth IRA Conversion with Distributions',
      ShortDescription: 'Determine how you can stretch out your retirement plan distributions for as long as possible.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.Calculators,
      Tags: [ToolboxCategoryEnum.Calculators, 'Retire', 'Retirement'],
      Act: (): void => {
        window.open('/DinkyTown/RothConversion.html', 'dinkytown');
      },
      Permission: 'Calculators:View',
      Class: ToolboxClassEnum.Blue,
      Icon: ToolboxIconEnum.Calculator,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: '9720e085-8fa7-4cb6-8c2b-11c219840d80',
      Name: 'Traditional IRA',
      ShortDescription: 'How can contributing to a regular IRA help you in your retirement?',
      LongDescription: null,
      Category: ToolboxCategoryEnum.Calculators,
      Tags: [ToolboxCategoryEnum.Calculators, 'Retire', 'Retirement'],
      Act: (): void => {
        window.open('/DinkyTown/RegularIRA.html', 'dinkytown');
      },
      Permission: 'Calculators:View',
      Class: ToolboxClassEnum.Blue,
      Icon: ToolboxIconEnum.Calculator,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: '940d2b94-b521-4e5a-b374-a050b20fee69',
      Name: 'Life Quotes Needs Analysis',
      ShortDescription: 'Determine how much life insurance would be suitable for an individual’s needs.',
      LongDescription: 'This calculator is designed to help determine how much life insurance would be suitable for an individual’s needs. The amount of coverage a company is willing to offer based on its financial underwriting review may not match the amount of coverage determined using a needs analysis calculator.',
      Category: ToolboxCategoryEnum.Calculators,
      Tags: [ToolboxCategoryEnum.Calculators, 'Life'],
      Act: (): void => {
        this.ssoService.iPipelineLifeQuotesAnalysisSso();
      },
      Permission: 'IpipelineFormsQuotesLife:Access',
      Class: ToolboxClassEnum.Blue,
      Icon: ToolboxIconEnum.Calculator,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: '15e74ae3-7cec-4320-b3b1-9a12c03245cd',
      Name: 'Life Settlement Abacus Calculator',
      ShortDescription: 'Receive a range of estimates for life insurance settlement based on health rankings. Recommended for individuals age 65 and higher.',
      LongDescription: 'Use this calculator for a range of estimates for life insurance settlements based on various health rankings. These estimates are focused on age 65 and higher. This calculator is not a substitute for a full life insurance product illustration. Customers should receive a full illustration prior to applying for any life insurance policy. The results this calculator produces is subject to change without notice. This calculator, and the results it produces, should not be construed as an offer to buy, sell, replace or exchange any product. Life insurance policy applications are vetted through an underwriting process set forth by the issuing insurance company. Some applications may not be accepted based upon adverse underwriting results. Premium payments vary by customer and are derived from the results of the underwriting process. Death benefit payouts are backed by the claims paying ability of the issuing insurance company.',
      Category: ToolboxCategoryEnum.Calculators,
      Tags: [ToolboxCategoryEnum.Calculators, 'Life'],
      Act: (): void => {
        window.open('https://abacuslifesettlements.com/fig/');
      },
      Permission: 'Calculators:View',
      Class: ToolboxClassEnum.Blue,
      Icon: ToolboxIconEnum.Calculator,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: '317b48fb-a079-461f-8942-4abef012cae8',
      Name: 'Life Product Search',
      ShortDescription: 'Access to an array of life products.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.RatesAndQuotes,
      Tags: [ToolboxCategoryEnum.RatesAndQuotes, 'Life'],
      Act: (): void => {
        this.ssoService.iPipelineLifeProductsSso();
      },
      Permission: 'IpipelineFormsQuotesLife:Access|LifeProductSearch:View',
      Class: ToolboxClassEnum.Pink,
      Icon: ToolboxIconEnum.Coin,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: '6d7a19c1-af27-4e47-9ea0-4579a2eea7dc',
      Name: 'E-App Quick Start Guide',
      ShortDescription: 'Get started with E-Applications.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.Tutorials,
      Tags: [ToolboxCategoryEnum.Tutorials, 'E-Applications', 'EApplications'],
      Act: (): void => {
        window.open('https://www.figmarketing.com/files/resources/EApp_Quick_Start_Guide.pdf');
      },
      Permission: null,
      Class: ToolboxClassEnum.Purple,
      Icon: ToolboxIconEnum.School,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: '7b8898be-0e2c-4c4a-a5b7-e5f6f324cd6c',
      Name: 'Winflex',
      ShortDescription: 'Multi-Carrier insurance software system that provides standard entry for multiple life insurance carriers.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.RatesAndQuotes,
      Tags: [ToolboxCategoryEnum.RatesAndQuotes, 'Winflex'],
      Act: (): void => {
        window.open('https://www.winflexweb.com/');
      },
      Permission: null,
      Class: ToolboxClassEnum.Pink,
      Icon: ToolboxIconEnum.Coin,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: false
    },
    {
      Id: '030c2eab-07fc-4b02-8089-7dcf57589e4e',
      Name: 'Annuities Genius',
      ShortDescription: 'A fully-encompassing annuity planning tool targeting all areas of your client’s goals.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.Calculators,
      Tags: [ToolboxCategoryEnum.Calculators, 'Annuities', 'Genius', 'planning', 'tool', this.toolboxCategories[7].Name],
      Act: (): void => {
        window.open('https://app.annuitiesgenius.com/login');
      },
      Permission: 'AnnuitiesGenius:View',
      Class: ToolboxClassEnum.Blue,
      Icon: ToolboxIconEnum.Calculator,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: false
    },
    {
      Id: '9535c0f4-4bca-4afd-8978-8e6fbd642cbc',
      Name: 'SIMON Spectrum™',
      ShortDescription: 'Take a simple, five dimensional look into the investment objectives of your client\'s portfolio.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.SimpleFinancialPlanning,
      Tags: [ToolboxCategoryEnum.SimpleFinancialPlanning],
      Act: (): void => {
        this.router.navigate(['/Portal/ProductRD/FinancialPlanning/SimonSpectrum']);
      },
      Permission: 'FinancialPlanningSIMONSpectrum:View',
      Class: null,
      Icon: null,
      IsFeatured: true,
      Order: 1,
      ImgUrl: './assets/images/simon.jpg',
      IsFavorite: false,
      Loading: null,
      IsVisible: false
    },
    // {
    //   Id: '3e7e3346-54dc-4e58-b852-d7df494686c2',
    //   Name: 'Annuity Income Analysis',
    //   ShortDescription: `Calculate and visualize your client's income gap and how an annuity could play a part in their portfolio.`,
    //   LongDescription: null,
    //   Category: ToolboxCategoryEnum.SimpleFinancialPlanning,
    //   Tags: [ToolboxCategoryEnum.SimpleFinancialPlanning],
    //   Act: ():void => {

    //   },
    //   Permission: 'AnnuityIncomeAnalysis:View',
    //   Class: null,
    //   Icon: null,
    //   IsFeatured: true,
    //   Order: 2,
    //   ImgUrl: './assets/images/lifetime-income.jpg',
    //   IsFavorite: false,
    //   Loading: null,
    //   IsVisible: false
    // },
    // {
    //   Id: '1ef1b49f-c390-4031-b046-19071e1764cc',
    //   Name: 'Annuity Income Analysis',
    //   ShortDescription: `Calculate and visualize your client's income gap and how an annuity could play a part in their portfolio.`,
    //   LongDescription: null,
    //   Category: ToolboxCategoryEnum.SimpleFinancialPlanning,
    //   Tags: [ToolboxCategoryEnum.SimpleFinancialPlanning],
    //   Act: ():void => {
    //     this.router.navigate(['/Portal/ProductRD/FinancialPlanning/IBlock']);
    //   },
    //   Permission: 'AnnuityIncomeAnalysis:View',
    //   Class: null,
    //   Icon: null,
    //   IsFeatured: true,
    //   Order: 2,
    //   ImgUrl: './assets/images/moneyguide-income-analysis-img.png',
    //   IsFavorite: false,
    //   Loading: null,
    //   IsVisible: false
    // },
    {
      Id: '3c1ae436-e6de-4c6c-95ee-75bc20ec934c',
      Name: 'MoneyGuide Elite',
      ShortDescription: 'An interactive planning solution for illustrating income distribution, tax planning, and much more.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.FullFinancialPlanning,
      Tags: [ToolboxCategoryEnum.FullFinancialPlanning,],
      Act: (): void => {
        // window.open('https://www.moneyguidepro.com/ifa/SignOn.aspx');
        this.ssoApiService.getMoneyGuideInstitutionId()
          .subscribe({
            next: () => {
              window.open('../../api/money-guide-sso', '_blank','noopener');
            },
            error: () => {
              this.dialog.open(MoneyguideSsoModalComponent, {
                autoFocus: true,
              });
            }
          });
      },
      Permission: 'MoneyGuideElite:View',
      Class: null,
      Icon: null,
      IsFeatured: true,
      Order: 1,
      ImgUrl: './assets/images/moneyguide-elite-img.png',
      IsFavorite: false,
      Loading: null,
      IsVisible: false
    },
    {
      Id: '53e4426d-0dec-42a2-8db2-6f295086c17d',
      Name: 'Riskalyze',
      ShortDescription: 'Generate a unique risk number that better helps align a client\'s portfolio with their risk tolerance.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.FullFinancialPlanning,
      Tags: [ToolboxCategoryEnum.FullFinancialPlanning,],
      Act: (): void => {
        window.open('https://pro.riskalyze.com/auth/login');
      },
      Permission: 'Riskalyze:View',
      Class: null,
      Icon: null,
      IsFeatured: true,
      Order: 2,
      ImgUrl: './assets/images/riskalyze.jpg',
      IsFavorite: false,
      Loading: null,
      IsVisible: false
    },
    {
      Id: '0af92984-892a-4785-85e0-860fb0797b70',
      Name: 'eMoney',
      ShortDescription: 'A robust platform for planning, engaging, and managing clients - all while streamlining and growing your business.',
      LongDescription: null,
      Category: ToolboxCategoryEnum.FullFinancialPlanning,
      Tags: [ToolboxCategoryEnum.FullFinancialPlanning],
      Act: (): void => {
        window.open('https://wealth.emaplan.com/ema/SignIn?ema');
      },
      Permission: 'EMoney:View',
      Class: null,
      Icon: null,
      IsFeatured: true,
      Order: 3,
      ImgUrl: './assets/images/e-money.jpg',
      IsFavorite: false,
      Loading: null,
      IsVisible: false
    },
    {
      Id: '74f2eec1-697e-45e9-a5af-99ebcefc80a8',
      Name: 'FIG Symposium 2021',
      ShortDescription: 'The culmination of incredible ideas developed during 2020 and truly transformative models for your continued acceleration!',
      LongDescription: null,
      Category: ToolboxCategoryEnum.IndustryEvents,
      Tags: [ToolboxCategoryEnum.IndustryEvents, 'Events', 'IndustryEvents'],
      Act: (): void => {
        this.router.navigate(['/Portal/Toolbox/IndustryEvent/FIGSymposium2021']);
      },
      Permission: 'IndustryNews:View|FIGTripsAndEvents:View',
      Class: ToolboxClassEnum.Teal,
      Icon: ToolboxIconEnum.Event,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
    {
      Id: 'e562372e-68dc-41d8-b4ac-4d9437ff67fa',
      Name: 'FIG Symposium 2022',
      ShortDescription: 'The culmination of incredible ideas developed during 2021 and truly transformative models for your continued acceleration!',
      LongDescription: null,
      Category: ToolboxCategoryEnum.IndustryEvents,
      Tags: [ToolboxCategoryEnum.IndustryEvents, 'Events', 'IndustryEvents'],
      Act: (): void => {
        this.router.navigate(['/Portal/Toolbox/IndustryEvent/FIGSymposium2022']);
      },
      Permission: 'IndustryNews:View|FIGTripsAndEvents:View',
      Class: ToolboxClassEnum.Teal,
      Icon: ToolboxIconEnum.Event,
      IsFeatured: false,
      Order: null,
      ImgUrl: null,
      IsFavorite: false,
      Loading: null,
      IsVisible: true
    },
  ]
}

