import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fadeIn } from 'src/app/animations';
import { forkJoin } from 'rxjs';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { User, UserDelegate } from '../../../shared/models/user.models';
import { UserApiService } from 'src/app/core/services/http/user-api.service';
import { UserGroupApiService } from 'src/app/core/services/http/user-group-api.service';
import { UserGroup } from '../../../shared/models/usergroups.models';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  animations: [fadeIn]
})
export class WelcomeComponent implements OnInit {
  Id: number = 0;
  isLastStep: boolean = false;
  alternateView!: boolean;
  @Input() user!: User;
  @Input() userGroup!: UserGroup | null;
  @Input() logo!: string | null;
  @Input() onBehalfOfUser!: User | null;
  @Input() onBehalfOfUserGroup!: UserGroup;
  @Output() taskCompletedEvent: EventEmitter<any> = new EventEmitter();
  @Output() goToPreviousEvent: EventEmitter<any> = new EventEmitter();
  @Output() goToNextEvent: EventEmitter<any> = new EventEmitter();


  constructor(private sessionStore: SessionStoreService, private activatedRoute: ActivatedRoute) { }

  welcomeCompleted!: boolean;

  goToNext(): void {
    this.welcomeCompleted = true;
    this.taskCompletedEvent.emit(this.welcomeCompleted);
    this.goToNextEvent.emit();
  }

  loadTaskCompleted(fromParent: boolean): void { }

  ngOnInit(): void {
    this.user = this.sessionStore.User;
    this.alternateView = this.activatedRoute.snapshot.queryParamMap.get('alternateView') == 'true' ? true : false;
    this.userGroup = this.sessionStore.UserGroup;
    // this.logo = this.sessionStore.UserGroupLogo;
    this.logo = this.sessionStore.UserLogo;
  }

}
