import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ShowIfOneDirective } from './directives/show-if-one.directive';
import { ShowIfAllDirective } from './directives/show-if-all.directive';
import { HideIfOneDirective } from './directives/hide-if-one.directive';
import { IframeDataDirective } from './directives/iframe-data.directive';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { AmplitudeEventDirective } from './directives/amplitude-event.directive';
import { SimonPoweredComponent } from './components/simon-powered/simon-powered.component';
import { SplitCamelCasePipe } from './pipes/split-camel-case.pipe';
import { CallbackPipe } from './pipes/callback/callback.pipe';
import { InlineSnackbarComponent } from './components/inline-snackbar/inline-snackbar.component';
import { CountdownTimerComponent } from './components/countdown-timer/countdown-timer.component';
import { MessageNotificationPanelComponent } from './components/message-notification/message-notification.component';

@NgModule({
  declarations: [AmplitudeEventDirective, HideIfOneDirective, IframeDataDirective, SafeUrlPipe, ShowIfAllDirective, ShowIfOneDirective, SimonPoweredComponent, SplitCamelCasePipe, CallbackPipe, InlineSnackbarComponent, CountdownTimerComponent, MessageNotificationPanelComponent],
  imports: [],
  exports: [BrowserAnimationsModule, CommonModule, AmplitudeEventDirective, HideIfOneDirective, IframeDataDirective, SafeUrlPipe, ShowIfAllDirective, ShowIfOneDirective, SimonPoweredComponent, SplitCamelCasePipe, CallbackPipe, InlineSnackbarComponent, CountdownTimerComponent, MessageNotificationPanelComponent]
})
export class SharedModule { }
