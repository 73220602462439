<table *ngIf="!isLoading" [@fadeIn] class="text-sm table-auto -my-60 custom-table">
  <thead>
      <tr>
          <td class="sticky top-0 z-10 w-48 p-10 text-xs font-semibold uppercase bg-white text-gray">
          </td>
          <td *ngFor="let cell of table.Header"
              class="p-10 hover:bg-blue-lightest bg-white z-10 sticky top-0 text-xs text-gray font-semibold uppercase {{cell.WidthClass}}">
              <span (click)="table.orderBy(cell)"
                  class="flex items-center leading-6 cursor-pointer min-h-24 focus:outline-none"
                  attr.aria-label="Sort by {{cell.DisplayName}}">
                  <span class="mr-5">{{cell.DisplayName}}</span>
                  <span *ngIf="cell === table.OrderByColumn" class="material-icons text-gray">
                      {{table.AscendingOrder ? 'arrow_drop_up' : 'arrow_drop_down'}}
                  </span>
              </span>
          </td>
      </tr>
  </thead>
  <tbody>
      <tr id="policyRow" class="bg-opacity-25 cursor-pointer" aria-label="Go to policy details"
          *ngFor="let row of table.Body"
          (click)="openPolicyDetails(row)">
          <td
              class="p-10">
          </td>
          <td class="p-10">
              {{row.Agents}}
          </td>
          <td class="p-10">
              {{row.RelatedPeople}}
          </td>
          <td class="p-10">
              {{row.FaceAmount |
              currency}}
          </td>
          <td class="p-10">
              {{row.AnnualizedPremium |
              currency}}
          </td>
          <td class="p-10">
              {{row.StateName}}
          </td>
          <td class="p-10">
              {{row.Carrier}}
          </td>
      </tr>
      <tr id="policyErrorRow" class="" aria-label="Error getting policies" *ngIf="isError">
          <td class="p-10"></td>
          <td class="p-10" colspan="6"><span class="text-red-dark">Sorry, an error has occurred. Please try again later.</span></td>
      </tr>
      <tr id="policyEmptyRow" class="" aria-label="No policies found" *ngIf="!isError && table.Body.length===0">
          <td class="p-10"></td>
          <td class="p-10" colspan="6">No policies found.</td>
      </tr>
  </tbody>
</table>
