<form [formGroup]="pointAdjustmentForm" (ngSubmit)="onSubmit(pointAdjustmentForm)" class="flex flex-col flex-grow w-full h-0 h-full overflow-auto">
    <section class="flex items-center flex-none p-10 border-b border-solid border-gray-lighter lg:p-20">
        <h1 class="m-10 text-xl font-bold">
            <span *ngIf="activeStep === 0">Submit Marketing Expenses</span>
            <span *ngIf="activeStep  > 0 && activeStep < 5">Step {{activeStep}}</span>
            <span *ngIf="activeStep === 5">Request Submitted!</span>
        </h1>
        <button mat-button tabindex="-1" type="button" mat-dialog-close
            class="m-10 ml-auto icon-button is-primary is-inverted"><i class="material-icons">close</i></button>
    </section>
    <section class="flex flex-col flex-grow p-10 overflow-auto text-base lg:p-20">
        <section class="flex flex-col" *ngIf="activeStep === 0">
            <p class="m-10 text-lg mb-30">Please fill out this form to submit a request to use your marketing dollars
                for marketing expenses.</p>
            <div
                class="p-20 m-10 my-20 leading-snug text-left bg-opacity-25 border border-solid rounded border-blue-lighter bg-blue-lighter">
                <div class="flex items-center justify-start mb-20">
                    <i class="mr-10 fig-24px fig-info-circle text-gray"></i>
                    <span class="font-semibold">Quick tips:</span>
                </div>
                <p class="py-5 mb-20"><em>- All fields are required unless noted otherwise.</em></p>
                <p class="py-5"><em>- Please submit one request per vendor. Any requests with multiple vendors will be
                        rejected until submitted correctly.</em></p>
                <p class="py-5"><em>- Please have the invoice receipt available as you will be required to upload
                        it.</em></p>
                <p class="py-5 mb-20"><em>- You will receive a confirmation email once the request has been approved or
                        denied.</em></p>
                <p class="py-5"><a class="font-semibold underline text-blue-dark hover:text-blue-darker" target="_blank"
                        href="/Documents/How_to_Submit_A_Points_Adjustment.pdf">Click here</a> for our 'How To' guide
                    for submitting marketing expenses.</p>
            </div>
        </section>
        <section class="flex flex-col" *ngIf="activeStep === 1">
            <div class="flex flex-col p-10">
                <label class="mb-10 text-sm font-medium text-gray">Marketing Dollar Balance</label>
                <h2 class="text-lg font-bold"
                    [ngClass]="agentMarketingDollar.Entry.MarketingDollarsTotal < 0 ? 'text-orange' : 'text-green'">
                    {{agentMarketingDollar.Entry.MarketingDollarsTotal | currency: '$'}}</h2>
            </div>
            <div class="flex flex-col p-10">
                <label for="purchaseCategory" class="mb-10 text-sm font-medium text-gray required-label">Purchase
                    Category</label>
                <div class="mb-10 text-sm font-medium text-gray">
                  <p class="py-5"><a class="font-semibold underline text-blue-dark hover:text-blue-darker" target="_blank"
                    href="/Documents/Marketing_Expense_Outline.pdf">Click here</a> for a list of qualified and unqualified purchases.</p>
                </div>
                <mat-select formControlName="purchaseCategory" name="purchaseCategory" class="select"
                    placeholder="Select a purchase category">
                    <mat-option *ngFor="let category of purchaseCategories" [value]="category.Id">
                        {{category.Title}}
                    </mat-option>
                </mat-select>
                <div *ngIf="pointAdjustmentForm.controls.purchaseCategory.touched && pointAdjustmentForm.controls.purchaseCategory.invalid"
                    class="mt-5 text-sm text-red">
                    <div *ngIf="pointAdjustmentForm.controls.purchaseCategory.errors?.required">This field is required
                    </div>
                </div>
            </div>
        </section>
        <section class="flex flex-col" *ngIf="activeStep === 2">
            <div class="flex flex-col p-10">
                <label class="mb-10 text-sm font-medium text-gray">Remaining Balance</label>
                <h2 class="text-lg font-bold" [ngClass]="remainingBalance < 0 ? 'text-orange' : 'text-green'">
                    {{remainingBalance | currency: '$'}}</h2>
            </div>
            <div class="flex flex-col p-10">
                <label for="invoiceAmount" class="mb-10 text-sm font-medium text-gray required-label">Invoice
                    Amount</label>
                <input formControlName="invoiceAmount" name="invoiceAmount" class="input" currencyMask
                    placeholder="$0.00" (blur)="updateRemainingBalance()" />
                <div *ngIf="pointAdjustmentForm.controls.invoiceAmount.touched && pointAdjustmentForm.controls.invoiceAmount.invalid"
                    class="mt-5 text-sm text-red">
                    <div *ngIf="pointAdjustmentForm.controls.invoiceAmount.errors?.required">This field is required
                    </div>
                </div>
            </div>
            <div class="flex flex-col p-10">
                <label for="purchaseCategory" class="mb-10 text-sm font-medium text-gray required-label">Payment
                    Type</label>
                <mat-select formControlName="paymentType" name="purchaseCategory" class="select"
                    placeholder="Select a payment type">
                    <mat-option *ngFor="let type of paymentTypes" [value]="type.Id">
                        {{type.Title}}
                    </mat-option>
                </mat-select>
                <div *ngIf="pointAdjustmentForm.controls.paymentType.touched && pointAdjustmentForm.controls.paymentType.invalid"
                    class="mt-5 text-sm text-red">
                    <div *ngIf="pointAdjustmentForm.controls.paymentType.errors?.required">This field is required
                    </div>
                </div>
            </div>
            <!-- Check -->
            <div formGroupName="paymentTypeCheck" *ngIf="pointAdjustmentForm.controls.paymentType.value === 1"
                class="flex flex-col">
                <div class="flex flex-col p-10">
                    <label for="vendorName" class="mb-10 text-sm font-medium text-gray required-label">Make Payable
                        To</label>
                    <input formControlName="vendorName" name="vendorName" class="input" placeholder="Enter a value" />
                    <div *ngIf="pointAdjustmentForm.controls.paymentTypeCheck.controls.vendorName.touched && pointAdjustmentForm.controls.paymentTypeCheck.controls.vendorName.invalid"
                        class="mt-5 text-sm text-red">
                        <div *ngIf="pointAdjustmentForm.controls.paymentTypeCheck.controls.vendorName.errors?.required">
                            This field is required</div>
                    </div>
                </div>
                <div class="flex flex-col p-10">
                    <label for="vendorStreetAddress" class="mb-10 text-sm font-medium text-gray required-label">Vendor's
                        Street Address</label>
                    <input formControlName="vendorStreetAddress" name="vendorStreetAddress" class="input"
                        placeholder="Enter street address" />
                    <div *ngIf="pointAdjustmentForm.controls.paymentTypeCheck.controls.vendorStreetAddress.touched && pointAdjustmentForm.controls.paymentTypeCheck.controls.vendorStreetAddress.invalid"
                        class="mt-5 text-sm text-red">
                        <div
                            *ngIf="pointAdjustmentForm.controls.paymentTypeCheck.controls.vendorStreetAddress.errors?.required">
                            This field is required</div>
                    </div>
                </div>
                <div class="flex flex-col p-10">
                    <label for="vendorCity" class="mb-10 text-sm font-medium text-gray required-label">Vendor's
                        City</label>
                    <input formControlName="vendorCity" name="vendorCity" class="input" placeholder="Enter a value" />
                    <div *ngIf="pointAdjustmentForm.controls.paymentTypeCheck.controls.vendorCity.touched && pointAdjustmentForm.controls.paymentTypeCheck.controls.vendorCity.invalid"
                        class="mt-5 text-sm text-red">
                        <div *ngIf="pointAdjustmentForm.controls.paymentTypeCheck.controls.vendorCity.errors?.required">
                            This field is required</div>
                    </div>
                </div>
                <div class="flex flex-wrap">
                    <div class="flex flex-col p-10 sm:w-1/2">
                        <label for="vendorState" class="mb-10 text-sm font-medium text-gray required-label">Vendor's
                            State</label>
                        <mat-select formControlName="vendorState" name="vendorState" class="select"
                            placeholder="Select a state">
                            <mat-option *ngFor="let state of states" [value]="state">
                                {{state.StateName}}
                            </mat-option>
                        </mat-select>
                        <div *ngIf="pointAdjustmentForm.controls.paymentTypeCheck.controls.vendorState.touched && pointAdjustmentForm.controls.paymentTypeCheck.controls.vendorState.invalid"
                            class="mt-5 text-sm text-red">
                            <div
                                *ngIf="pointAdjustmentForm.controls.paymentTypeCheck.controls.vendorState.errors?.required">
                                This field is required</div>
                        </div>
                    </div>
                    <div class="flex flex-col p-10 sm:w-1/2">
                        <label for="vendorZip" class="mb-10 text-sm font-medium text-gray required-label">Vendor's
                            Zip Code</label>
                        <input formControlName="vendorZip" name="vendorZip" class="input" placeholder="Enter a value" />
                        <div *ngIf="pointAdjustmentForm.controls.paymentTypeCheck.controls.vendorZip.touched && pointAdjustmentForm.controls.paymentTypeCheck.controls.vendorZip.invalid"
                            class="mt-5 text-sm text-red">
                            <div
                                *ngIf="pointAdjustmentForm.controls.paymentTypeCheck.controls.vendorZip.errors?.required">
                                This field is required</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ACH -->
            <div formGroupName="paymentTypeAch" *ngIf="pointAdjustmentForm.controls.paymentType.value === 5"
                class="flex flex-col p-10">
                <label for="vendorName" class="mb-10 text-sm font-medium text-gray required-label">Vendor Name</label>
                <mat-select formControlName="vendorName" name="vendorName" class="select"
                    placeholder="Select a vendor name">
                    <mat-option *ngFor="let vendor of vendors" [value]="vendor.Id">
                        {{vendor.Title}}
                    </mat-option>
                </mat-select>
                <div *ngIf="pointAdjustmentForm.controls.paymentTypeAch.controls.vendorName.touched && pointAdjustmentForm.controls.paymentTypeAch.controls.vendorName.invalid"
                    class="mt-5 text-sm text-red">
                    <div *ngIf="pointAdjustmentForm.controls.paymentTypeAch.controls.vendorName.errors?.required">
                        This field is required</div>
                </div>
            </div>
            <!-- Other -->
            <div formGroupName="paymentTypeOther" *ngIf="pointAdjustmentForm.controls.paymentType.value === 2005"
                class="flex flex-col p-10">
                <label for="paymentTypeName" class="mb-10 text-sm font-medium text-gray required-label">Specify Payment
                    Type</label>
                <textarea formControlName="paymentTypeName" name="paymentTypeName" class="textarea" rows="6"
                    placeholder="Specify preferred payment type"></textarea>
                <div *ngIf="pointAdjustmentForm.controls.paymentTypeOther.controls.paymentTypeName.touched && pointAdjustmentForm.controls.paymentTypeOther.controls.paymentTypeName.invalid"
                    class="mt-5 text-sm text-red">
                    <div
                        *ngIf="pointAdjustmentForm.controls.paymentTypeOther.controls.paymentTypeName.errors?.required">
                        This field is required</div>
                </div>
            </div>
        </section>
        <section class="flex flex-col" *ngIf="activeStep === 3">
            <div class="flex flex-col p-10">
                <label for="contactEmail" class="mb-10 text-sm font-medium text-gray required-label">Contact
                    Email</label>
                <input formControlName="contactEmail" name="contactEmail" class="input" type="text"
                    placeholder="Enter an email address" />
                <div *ngIf="pointAdjustmentForm.controls.contactEmail.touched && pointAdjustmentForm.controls.contactEmail.invalid"
                    class="mt-5 text-sm text-red">
                    <div *ngIf="pointAdjustmentForm.controls.contactEmail.errors?.required">This field is required</div>
                    <div *ngIf="pointAdjustmentForm.controls.contactEmail.errors?.email">This field is required</div>
                </div>
            </div>
            <div class="flex flex-col p-10">
                <label for="notes" class="mb-10 text-sm font-medium text-gray">Notes & Memoes (Optional)</label>
                <textarea formControlName="notes" name="notes" class="textarea" rows="6"
                    placeholder="Provide additional information"></textarea>
            </div>
        </section>
        <section class="flex flex-col" *ngIf="activeStep === 4">
            <div class="flex flex-col p-10">
                <label for="contactEmail" class="mb-10 text-sm font-medium text-gray required-label">Upload Invoice
                    Receipt</label>
                <div class="relative flex flex-col items-center justify-center p-20 text-center transition duration-150 border-2 border-dashed rounded cursor-pointer border-gray-lighter min-h-128 hover:text-blue hover:border-blue text-gray"
                    [class]="dragover? 'border-blue text-blue':''">
                    <input formControlName="fileUploads" name="fileUploads" class="absolute top-0 left-0 w-full h-full opacity-0" accept=".pdf,.png,.jpg,.jpeg"
                        (dragover)="dragOverHandler($event)" type="file" multiple id="file"
                        (change)="onFileInput($event)">
                    <label class="text-sm font-semibold">Click or drag files here to upload</label>
                    <label class="text-sm font-semibold">PDF, PNG, JPG</label>
                </div>
                <!-- <input formControlName="fileUploads" name="fileUploads" class="h-auto p-10 input" type="file" multitple
                    (change)="onFileInput($event)" placeholder="Enter an email address" /> -->
                <div *ngIf="pointAdjustmentForm.controls.fileUploads.touched && pointAdjustmentForm.controls.fileUploads.invalid"
                    class="mt-5 text-sm text-red">
                    <div *ngIf="pointAdjustmentForm.controls.fileUploads.errors?.required">This field is required</div>
                </div>
            </div>
            <div class="flex flex-wrap flex-none p-10 mt-10" *ngIf="filesToUpload.length">
                <div class="flex items-center w-full p-10" *ngFor="let file of filesToUpload">
                    <p class="mr-10">{{file.name}}</p>
                    <button class="ml-auto icon-button is-warning is-inverted is-sm" aria-label="Delete file button"
                        type="button" (click)="removeFile(file)">
                        <span class="material-icons mi-18">close</span>
                    </button>
                </div>
            </div>
        </section>
        <section class="flex flex-col" *ngIf="activeStep === 5">
            <div class="flex flex-col items-center justify-center flex-grow p-20 m-10">
                <i class="m-20 fig-48px fig-check-circle-filled text-green"></i>
                <p class="text-lg text-center">
                    Thank You! You will receive an email confirmation once the request has been reviewed.
                </p>
            </div>
        </section>

    </section>
    <section class="flex flex-none p-10 border-t border-solid lg:p-20 border-gray-lighter">
        <button type="button" mat-button class="m-10 mr-auto button is-primary is-outlined is-lg"
            *ngIf="activeStep !== 5" (click)="prevStep()" [disabled]="isUploading || isSubmitting">
            {{activeStep === 0 ? 'Close' : 'Back'}}
        </button>
        <button type="button" mat-button class="m-10 button is-primary is-lg" *ngIf="activeStep < 4"
            (click)="nextStep()" [disabled]="isUploading
        || isSubmitting
        || (this.activeStep === 1 && this.pointAdjustmentForm.controls.purchaseCategory.invalid)
            || (this.activeStep === 2 && (this.pointAdjustmentForm.controls.invoiceAmount.invalid
              || this.pointAdjustmentForm.controls.paymentType.invalid
              || this.pointAdjustmentForm.controls.paymentTypeCheck.invalid
              || this.pointAdjustmentForm.controls.paymentTypeAch.invalid
              || this.pointAdjustmentForm.controls.paymentTypeOther.invalid))
            || (this.activeStep === 3 && this.pointAdjustmentForm.controls.contactEmail.invalid)
            || (this.activeStep === 4 && this.pointAdjustmentForm.controls.fileUploads.invalid)"
            class="m-10 ml-auto button is-primary is-lg">
            Next
        </button>
        <button type="submit" mat-button class="m-10 ml-auto button is-primary is-lg" *ngIf="activeStep === 4"
            [disabled]="isUploading || isSubmitting || pointAdjustmentForm.invalid">
            {{isSubmitting ? 'Submitting' : '  Submit'}}
        </button>
        <button type="button" mat-button mat-dialog-close="true" class="m-10 ml-auto button is-primary is-lg"
            *ngIf="activeStep === 5" [disabled]="isUploading || isSubmitting || pointAdjustmentForm.invalid">
            Close
        </button>
    </section>
</form>
