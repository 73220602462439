import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-structured-indication-of-interest',
  templateUrl: './structured-indication-of-interest.component.html',
})
export class StructuredIndicationOfInterestComponent implements OnInit {
  iframeUrl!:string;
  showLocalSimon!:boolean;
  environment = environment;

  constructor() { }

  ngOnInit(): void {
    this.openSimonSso();
  }

  openSimonSso() {
    if (this.showLocalSimon) {
      this.iframeUrl = environment.simonLocalUrl;
    } else {
      this.iframeUrl = environment.simonUrl;
    }
    this.iframeUrl += '/investment-activity/pending-investments/pending-iois';
  }

}
