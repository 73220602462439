import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { externalLoadingHtml } from 'src/app/shared/constants/externalLoading.constants';
import { ExternalVendorProduct } from 'src/app/shared/models/eapps.models';
import { CreateActivityBasicResponse, CreateActivityRequest } from 'src/app/shared/models/firelightApi.models';
import { StateBasicModel } from 'src/app/shared/models/generic.models';
import { iGoApplication } from 'src/app/shared/models/iGo-application.models';
import { EAppPreferenceEnum } from 'src/app/shared/models/user-settings.model';
import { PermissionService } from './auth/permission.service';
import { FirelightApiService } from './http/firelight-api.service';
import { SsoApiService } from './http/sso-api.service';
import { SsoService } from './sso.service';
import { SessionStoreService } from './stores/session-store.service';

@Injectable({
  providedIn: 'root'
})
export class EAppService {

  constructor(
    private firelightApiService:FirelightApiService,
    private permissionService:PermissionService,
    private ssoApiService:SsoApiService,
    private router:Router,
    private sessionStore:SessionStoreService,
    private ssoService:SsoService
  ) { }

  startiPipelineEApplication(stateCode:string, productId:string, productTypeId: string): void {
    if (this.permissionService.hasOnePermission('IpipelineEApps:Access')) {
      const request:iGoApplication = {
        StateCode: stateCode,
        ProductId: productId,
        ProductTypeId: productTypeId
      };
      const w = window.open();
      w?.document.write(externalLoadingHtml);
      this.ssoApiService.initiateIPipelineEappsSso(request)
        .subscribe(data => {
          w?.document.write(data);
          w?.document.forms.namedItem('samlform')?.submit();
        },
        () => w?.document.write(' Failed.  There was an error accessing this resource.'));
    }
  }

  startFirelightEApplication(product:ExternalVendorProduct, state:StateBasicModel, clientFirstName:string, clientLastName:string):Observable<CreateActivityBasicResponse>{
    const request:CreateActivityRequest = {
      ActivityName: `${clientFirstName}${clientLastName}-${product.Business}-${product.Product}`,
      CarrierCode: product.CarrierCode!,
      CUSIP: product.ProductCusip!,
      Jurisdiction: product.States.find(s => s.StateAbbrev === state.ShortName)!.JurisdictionId.toString(),
      Client: {
        FirstName: clientFirstName,
        LastName: clientLastName
      }
    };

    return this.firelightApiService.postFirelightApplication(request);
  }

  goToFirelight(activityId:string, options?:Options):void{
    const eAppPreference = this.sessionStore.UserSettings?.EAppPreference;
    if (eAppPreference == EAppPreferenceEnum.FirelightEmbedded) {
      this.router.navigate(['Portal/ProductRD/EApplication/Workflow/ViewEApplication'], { queryParams: { activityId: activityId, isNew: options?.isNew ?? false } });
    } else {
      this.ssoService.firelightSso(activityId);
    }
  }

}

export interface Options {
  isNew:boolean
}
