import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { AuthInterceptorService } from './services/interceptors/auth-interceptor.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  exports: [HttpClientModule],
  providers: [CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: Window,
      useValue: window
    }
  ],
})
export class CoreModule { }
