import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-submitted',
  templateUrl: './submitted.component.html',
})
export class SubmittedComponent {

  constructor() { }

}
