import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { forEach } from 'lodash';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AmplitudeEventService } from 'src/app/core/services/amplitude/amplitude-event.service';
import { PermissionService } from 'src/app/core/services/auth/permission.service';
import { FigPlatformService } from 'src/app/core/services/figplatform.service';
import { FigPlatformSpotlightTile, FigPlatformTile } from 'src/app/shared/models/figplatform.models';

@Component({
  selector: 'app-dashboard-spotlight',
  templateUrl: './dashboard-spotlight.component.html',
})
export class DashboardSpotlightComponent implements OnInit {
  @Output() loaded: EventEmitter<any> = new EventEmitter();
  spotlightTiles: FigPlatformSpotlightTile[] = [];
  isOptedIntoQuinci: boolean = false;

  constructor(
    private amplitudeEventService: AmplitudeEventService,
    private figPlatformService: FigPlatformService,
    private permissionService: PermissionService
  ) { }

  ngOnInit(): void {
    this.isOptedIntoQuinci = this.permissionService.hasOnePermission('ProductRDOptedIn:View');
    this.getSpotlightTiles();
  }

  getSpotlightTiles(): void {
    forkJoin({
      spotlightTiles: this.figPlatformService.getUserPermittedSpotlightTiles().pipe(catchError(error => of(error))),
      // tiles: this.figPlatformService.getUserPermittedTiles().pipe(catchError(error => of(error)))
    })
      .subscribe(res => {
        this.spotlightTiles = res.spotlightTiles;
        // console.log(res.spotlightTiles);
      })
      .add(() => {
        this.loaded.emit();
        this.spotlightTiles = _.orderBy(this.spotlightTiles, ['Fields.Order']).slice(0, 3);
      });
  }

  sendToAmplitude(tileName: string): void {
    // console.log('Financial Planning - ' + tileName);
    if (tileName) this.amplitudeEventService.logClickEvent('Spotlight Tile - ' + tileName);
  }
}
