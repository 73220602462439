import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { fadeIn } from 'src/app/animations';
import { AdditionalResourceGroup } from 'src/app/shared/models/resource-page.models';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { orderBy, indexOf } from 'lodash';
import { CdkAccordion, CdkAccordionItem } from '@angular/cdk/accordion';
import { searchFilter } from 'src/app/shared/helpers/search.helper';
import { LifeInsuranceResourcesApiService } from 'src/app/core/services/http/airtable-api/life-insurance-resources-api.service';
import { forkJoin, Observable, of } from 'rxjs';
import { LifeInsuranceResources, LifeInsuranceResourcesCategory, LifeInsuranceResourcesSubCategory } from 'src/app/shared/models/life-insurance-resources.models';
import { MatDialog } from '@angular/material/dialog';
import { FactFinderInstructionsComponent } from 'src/app/portal/modals/fact-finder-instructions/fact-finder-instructions.component';
import { UserVpApiService } from 'src/app/core/services/http/user-vp-api.service';
import { LifeVp } from 'src/app/shared/models/vp.models';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { User } from 'src/app/shared/models/user.models';
import { catchError, map } from 'rxjs/operators';

@Component({
  animations: [fadeIn],
  selector: 'app-life-insurance-new',
  templateUrl: './life-insurance-new.component.html',
})
export class LifeInsuranceNewComponent implements OnInit {
  user!: User;
  userLifeVp!: LifeVp;
  allPanelsOpened: boolean = false;
  categories: LifeInsuranceResourcesCategory[] = [];
  loading: boolean = false;
  resources: LifeInsuranceResources[] = [];
  searchText: string = '';
  showFilteredList: boolean = false;
  subCategories: LifeInsuranceResourcesSubCategory[] = [];
  resourceUrl: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private lifeInsuranceResourcesApiService: LifeInsuranceResourcesApiService,
    private userVpApiService: UserVpApiService,
    private sessionStoreService: SessionStoreService,
  ) {
    this.user = this.sessionStoreService.User;
  }

  ngOnInit(): void {
    this.loading = true;
    this.resourceUrl = this.getResourceUrl();

    forkJoin({
      lifeVp: this.userVpApiService.getUserLifeVP().pipe(catchError(error => {
        console.log(error);
        return of({ FirstName: '', LastName: '', EmailAddress: '', });
      })),
      resources: this.lifeInsuranceResourcesApiService.getUserPermittedTiles().pipe(catchError(error => of(error))),
      categories: this.lifeInsuranceResourcesApiService.getCategories().pipe(catchError(error => of(error))),
      subCategories: this.lifeInsuranceResourcesApiService.getSubCategories().pipe(catchError(error => of(error))),
    })
      .subscribe(res => {
        this.userLifeVp = res.lifeVp !== undefined ? res.lifeVp : { FirstName: '', LastName: '', EmailAddress: '', };
        this.resources = res.resources.map((item: LifeInsuranceResources) => {
          if (!item.Fields.HasDocuSignAutoFill) return item;
          else {
            const userParams = `&Agent_UserName=${encodeURIComponent(`${this.user.FirstName} ${this.user.LastName}`)}&Agent_Email=${this.user.Email}`;
            const VpParams = `&${encodeURIComponent('Insert FIG Life VP Name And Email')}_UserName=${encodeURIComponent(`${this.userLifeVp.FirstName} ${this.userLifeVp.LastName}`)}&${encodeURIComponent('Insert FIG Life VP Name And Email')}_Email=${this.userLifeVp.EmailAddress}`;
            item.Fields.ExternalUrl += VpParams + userParams;
            return item;
          }
        });
        this.subCategories = res.subCategories;
        this.categories = res.categories.map((item: LifeInsuranceResourcesCategory) => {
          const index = indexOf(res.categories, item);
          item.Expanded = index === 0 ? true : false;
          return item;
        });
      })
      .add(() => {
        this.loading = false;
      });
  }

  filterBySearchText(resources: LifeInsuranceResources[], searchText: string): LifeInsuranceResources[] {
    if (searchText.length > 0) {
      this.showFilteredList = true;
      return resources.filter(item => searchFilter(item.Fields, searchText));
    } else {
      this.showFilteredList = false;
      return resources;
    }
  }

  getResourceUrl() {
    return this.activatedRoute.snapshot.paramMap.get('resource')!;
  }

  getSpecialFeaturedItems(items: LifeInsuranceResources[]) {
    return items.filter(item => item.Fields.SubCategoryName === null);
  }

  openFactFinderModal() {
    const dialogRef = this.dialog.open(FactFinderInstructionsComponent, {});
  }

  scrollTo(category: any, id: string) {
    category.Expanded = true;
    const el = document.getElementById(id);
    setTimeout(() => {
      el?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  }

  toggleAccordion() {
    let count: number = 0;
    this.categories.forEach(item => {
      if (item.Expanded) count++;
    });
    if (count == 0 || count < this.categories.length) {
      this.categories.forEach(item => {
        item.Expanded = true;
      });
      this.allPanelsOpened = true;
    } else {
      this.categories.forEach(item => {
        item.Expanded = false;
      });
      this.allPanelsOpened = false;
    }
  }
}