<section class="w-full mx-auto"
    [class]="!!getComponent() ? !showSimonView ?  'p-20 md:p-30 max-w-1440': 'hidden' : 'max-w-1440'">
    <div class="flex flex-row items-center mb-20" [ngClass]="{'md:mb-30': !!getComponent() }" *ngIf="!loading" @fadeIn>
        <a class="flex items-center text-sm font-medium cursor-pointer"
            [class]="!!componentUrl ? 'text-white hover:text-blue-lightest' : 'text-blue-dark hover:text-blue-darker'"
            (click)="goBack()">
            <i class="mr-5 no-underline fig-24px fig-arrow-left"></i>
            <span class="">Back to List View</span>
        </a>
        <!-- <div *ngIf="simonPolicyUrl && policyGuid" (click)="switchView()">Switch View</div> -->
        <!-- <div class="flex flex-row items-center justify-center ml-auto rounded"
            [class]="!!componentUrl ? '' : 'border border-solid border-gray-lighter'"
            *ngIf="simonPolicyUrl && policyGuid && environment.production" @fadeIn>
            <button mat-button class="flex-auto px-20 transition-colors transform button"
                attr.aria-label="Dashboard view"
                [class]="!showSimonView ? 'is-primary' : 'is-inverted is-outlined text-white bg-transparent hover:text-blue-lightest'"
                (click)="switchView()" amplitudeEvent="Policy Details - Policy View">
                <span class="flex items-center justify-center">
                    <span>Policy Details</span>
                </span>
            </button>
            <button mat-button class="flex-auto px-20 transition-colors transform button"
                attr.aria-label="Explore FIG Platforms"
                [class]="!!showSimonView ? 'is-primary' : 'is-inverted is-outlined text-white bg-transparent hover:text-blue-lightest'"
                (click)="switchView()" amplitudeEvent="Policy Details - Simon View">
                <span class="flex items-center justify-center">
                    <span>Policy Lifecycle</span>
                </span>
            </button>
        </div> -->
    </div>
    <section class="flex flex-col w-full" *ngIf="!loading && !showSimonView">
        <section class="grid grid-cols-2 gap-x-20 gap-y-20">
            <div class="flex col-span-2 text-sm font-bold rounded-lg card">
                <div class="flex items-center m-20 md:mx-40" matTooltip="Policy Information">
                    <span class="mr-10 material-icons text-gray mi-18">work</span>
                    <div class="flex flex-col">
                        <span class="text-semibold">{{policyDetails.Carrier}} - {{policyDetails.Product}}</span>
                        <span *ngIf="policyDetails.PolicyNumber">({{policyDetails.PolicyNumber}})</span>
                    </div>
                </div>
                <div class="flex items-center m-20 md:mx-40" *ngIf="policyDetails.LineOfBusiness"
                    matTooltip="Line of Business">
                    <span class="mr-10 material-icons text-gray">health_and_safety</span>
                    <span>{{policyDetails.LineOfBusiness}}</span>
                </div>
                <div class="flex items-center m-20 md:mx-40" *ngIf="policyDetails.Clients" matTooltip="Clients">
                    <span class="mr-10 material-icons text-gray">people</span>
                    <span>{{policyDetails.Clients}}</span>
                </div>
                <div class="flex items-center m-20 md:mx-40" *ngIf="policyDetails.FaceAmount" matTooltip="Face Amount">
                    <span class="mr-10 material-icons text-gray">monetization_on</span>
                    <span>{{policyDetails.FaceAmount | currency}}</span>
                </div>
                <div class="flex items-center m-20 md:mx-40" *ngIf="policyDetails.LineOfBusiness !== 'Disability' && policyDetails.SinglePremium"
                    matTooltip="Premium Amount">
                    <span class="mr-10 material-icons text-gray">monetization_on</span>
                    <span>{{policyDetails.SinglePremium | currency}}</span>
                </div>
                <div class="flex items-center m-20 md:mx-40" *ngIf="policyDetails.LineOfBusiness === 'Disability'"
                    matTooltip="Premium Amount">
                    <span class="mr-10 material-icons text-gray">monetization_on</span>
                    <span>{{policyDetails.ModalPremium | currency}}</span>
                </div>
                <button mat-button class="px-20 my-auto ml-auto mr-30 button is-primary"
                    *ngIf="simonPolicyUrl && policyGuid" (click)="switchToSimonView()"
                    amplitudeEvent="Policy Details - Simon View">
                    <span class="flex items-center justify-center">
                        <span>Advanced Policy Analytics</span>
                        <i class="mr-10 material-icons mi-18">insights</i>
                    </span>
                </button>
            </div>
            <div class="flex flex-col col-span-2 p-10 card md:col-span-1">
                <app-requirements class="block w-full h-full md:mx-auto" [policyStage]="currentPolicyStage"
                    [policyStageDate]="currentPolicyStageDate" [policyGuid]="policyDetails.PolicyGuid"
                    [isVariable]="isVariable" [lobId]="policyDetails.LineOfBusinessId" [hideResponseSubmission]="policyDetails.LineOfBusiness === 'Disability'"></app-requirements>
            </div>
            <div class="flex flex-col col-span-2 rounded-lg p-30 md:col-span-1 card md:p-40"*ngIf="policyDetails.LineOfBusiness !== 'Disability'">
                <h2 class="mb-20 text-lg font-bold">Enhanced Case Insights
                    <!--<sup class="text-sm">&trade;</sup>-->
                </h2>
                <div class="flex items-center p-10 mb-20 border rounded border-gray-lighter">
                    <div class="flex flex-col items-center p-10 w-256">
                        <span class="flex items-center justify-center p-10 mb-10 text-white rounded-full bg-purple">
                            <span class="material-icons">
                                assignment
                            </span>
                        </span>
                        <label class="text-sm font-bold uppercase">Pre-application</label>
                    </div>
                    <ul class="flex flex-col">
                        <li class="flex items-center p-10">
                            <span class="mr-10 material-icons text-gray">
                                {{!!policyDetails.StageHistoryObject[0] ? 'check_circle_outline' :
                                'radio_button_unchecked'}}
                            </span>
                            <div class="flex flex-col">
                                <h3 class="text-sm font-semibold capitalize">Application Signed</h3>
                                <label class="text-xs font-semibold text-gray">{{ !!policyDetails.StageHistoryObject[0]
                                    ? (policyDetails.StageHistoryObject[0].PolicyStageDate | date: 'MMM dd, yyyy' : 'UTC') :
                                    '--'}}</label>
                            </div>
                        </li>
                        <li class="flex items-center p-10">
                            <span class="mr-10 material-icons text-gray">
                                {{!!policyDetails.StageHistoryObject[1] ? 'check_circle_outline' :
                                'radio_button_unchecked'}}
                            </span>
                            <div class="flex flex-col">
                                <h3 class="text-sm font-semibold capitalize">Received At FIG</h3>
                                <label class="text-xs font-semibold text-gray">{{ !!policyDetails.StageHistoryObject[1]
                                    ? (policyDetails.StageHistoryObject[1].PolicyStageDate | date: 'MMM dd, yyyy' : 'UTC') :
                                    '--'}}</label>
                            </div>
                        </li>
                        <li class="flex items-center p-10">
                            <span class="mr-10 material-icons text-gray">
                                {{!!policyDetails.StageHistoryObject[2] ? 'check_circle_outline' :
                                'radio_button_unchecked'}}
                            </span>
                            <div class="flex flex-col">
                                <h3 class="text-sm font-semibold capitalize">Submitted to Carrier</h3>
                                <label class="text-xs font-semibold text-gray">{{ !!policyDetails.StageHistoryObject[2]
                                    ? (policyDetails.StageHistoryObject[2].PolicyStageDate | date: 'MMM dd, yyyy' : 'UTC') :
                                    '--'}}</label>
                            </div>
                        </li>
                        <!-- <li class="flex items-center p-10">
                        <i class="mr-10 fig-24px"
                            [ngClass]="!!policyDetails.StageHistoryObject[3] ? 'fig-check-circle text-gray' : 'fig-check-circle-blank text-orange'"></i>
                        <div class="flex flex-col">
                            <h3 class="text-sm font-semibold capitalize">Pending</h3>
                            <label
                                class="text-xs font-semibold text-gray">{{ !!policyDetails.StageHistoryObject[3] ? (policyDetails.StageHistoryObject[3].PolicyStageDate | date: 'MMM dd, yyyy') : '--'}}</label>
                        </div>
                    </li> -->
                    </ul>
                </div>
                <div class="flex items-center p-10 mb-20 border rounded border-gray-lighter" *ngIf="!!policyFunding">
                    <div class="flex flex-col items-center p-10 w-256">
                        <span class="flex items-center justify-center p-10 mb-10 text-white rounded-full bg-purple">
                            <span class="material-icons">
                                account_balance
                            </span>
                        </span>
                        <label class="text-sm font-bold uppercase">{{ policyDetails.LineOfBusinessId === 1 ? 'Funding' :
                            'Funding/Premium'}}</label>
                    </div>
                    <ul class="flex flex-col">
                        <li class="flex items-center p-10" *ngFor="let fund of policyFunding">
                            <span class="mr-10 material-icons text-gray">
                                {{fund.IssuedPremiumAmount? 'check_circle_outline' : 'radio_button_unchecked'}}
                            </span>
                            <div class="flex flex-col">
                                <h3 class="text-base font-bold capitalize">
                                    {{(fund.IssuedPremiumAmount ? fund.IssuedPremiumAmount: fund.Amount) | currency}}
                                </h3>
                                <label class="text-xs font-semibold text-gray">{{fund.Method}}</label>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="flex items-center p-10 mb-20 border rounded border-gray-lighter">
                    <div class="flex flex-col items-center p-10 w-256">
                        <span class="flex items-center justify-center p-10 mb-10 text-white rounded-full bg-purple">
                            <span class="material-icons">
                                assignment_turned_in
                            </span>
                        </span>
                        <label class="text-sm font-bold uppercase">Completion</label>
                    </div>
                    <ul class="flex flex-col">
                        <li class="flex items-center p-10"
                            *ngIf="policyDetails.LineOfBusiness === 'Annuity' && !!policySuitability">
                            <span class="mr-10 material-icons text-gray">
                                {{!!policySuitability.IsSuitable ? 'check_circle_outline' : 'radio_button_unchecked'}}
                            </span>
                            <div class="flex flex-col">
                                <h3 class="text-sm font-semibold capitalize">Suitability Approved</h3>
                                <label class="text-xs font-semibold text-gray">{{ !!policySuitability ?
                                    (policySuitability.ModifiedOn | date: 'MMM dd, yyyy' : 'UTC') : '--'}}</label>
                            </div>
                        </li>
                        <li class="flex items-center p-10" *ngIf="policyDetails.LineOfBusiness !== 'Annuity'">
                            <span class="mr-10 material-icons text-gray">
                                {{!!policyDetails.StageHistoryObject[4] ? 'check_circle_outline' :
                                'radio_button_unchecked'}}
                            </span>
                            <div class="flex flex-col">
                                <h3 class="text-sm font-semibold capitalize">Approved</h3>
                                <label class="text-xs font-semibold text-gray">{{ !!policyDetails.StageHistoryObject[4]
                                    ? (policyDetails.StageHistoryObject[4].PolicyStageDate | date: 'MMM dd, yyyy' : 'UTC') :
                                    ''}}</label>
                            </div>
                        </li>
                        <li class="flex items-center p-10">
                            <span class="mr-10 material-icons text-gray">
                                {{!!policyDetails.StageHistoryObject[5] ? 'check_circle_outline' :
                                'radio_button_unchecked'}}
                            </span>
                            <div class="flex flex-col">
                                <h3 class="text-sm font-semibold capitalize">Issued</h3>
                                <label class="text-xs font-semibold text-gray">{{ !!policyDetails.StageHistoryObject[5]
                                    ? (policyDetails.StageHistoryObject[5].PolicyStageDate | date: 'MMM dd, yyyy' : 'UTC') :
                                    ''}}</label>
                            </div>
                        </li>
                        <li class="flex items-center p-10">
                            <span class="mr-10 material-icons text-gray">
                                {{!!policyDetails.StageHistoryObject[6] || policyDetails.IsPartial ? 'check_circle_outline' :
                                'radio_button_unchecked'}}
                            </span>
                            <div class="flex flex-col">
                                <h3 class="text-sm font-semibold capitalize">Inforce</h3>
                                <label class="text-xs font-semibold text-gray">{{ !!policyDetails.StageHistoryObject[6]
                                    ? (policyDetails.StageHistoryObject[6].PolicyStageDate | date: 'MMM dd, yyyy' : 'UTC') :
                                    ''}}</label>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="flex flex-col col-span-2 rounded-lg p-30 md:col-span-1 card md:p-40" *ngIf="policyDetails.LineOfBusiness === 'Disability'">
                <h2 class="mb-20 text-lg font-bold">Enhanced Case Insights
                </h2>
                <div class="flex items-center p-10 mb-20 border rounded border-gray-lighter">
                    <ul class="flex flex-col">
                        <li class="flex items-center p-10">
                            <span class="mr-10 material-icons text-gray">
                                {{!!policyDetails.StageHistoryObject[2] ? 'check_circle_outline' :
                                'radio_button_unchecked'}}
                            </span>
                            <div class="flex flex-col">
                                <h3 class="text-sm font-semibold capitalize">Submitted</h3>
                                <label class="text-xs font-semibold text-gray">{{ !!policyDetails.StageHistoryObject[2]
                                    ? (policyDetails.StageHistoryObject[2].PolicyStageDate | date: 'MMM dd, yyyy' : 'UTC') :
                                    '--'}}</label>
                            </div>
                        </li>
                        <li class="flex items-center p-10">
                            <span class="mr-10 material-icons text-gray">
                                {{!!policyDetails.StageHistoryObject[3] ? 'check_circle_outline' :
                                'radio_button_unchecked'}}
                            </span>
                            <div class="flex flex-col">
                                <h3 class="text-sm font-semibold capitalize">Pending</h3>
                                <label class="text-xs font-semibold text-gray">{{ !!policyDetails.StageHistoryObject[3]
                                    ? (policyDetails.StageHistoryObject[3].PolicyStageDate | date: 'MMM dd, yyyy' : 'UTC') :
                                    '--'}}</label>
                            </div>
                        </li>
                        <li class="flex items-center p-10">
                            <span class="mr-10 material-icons text-gray">
                                {{!!policyDetails.StageHistoryObject[4] ? 'check_circle_outline' :
                                'radio_button_unchecked'}}
                            </span>
                            <div class="flex flex-col">
                                <h3 class="text-sm font-semibold capitalize">Approved</h3>
                                <label class="text-xs font-semibold text-gray">{{ !!policyDetails.StageHistoryObject[4]
                                    ? (policyDetails.StageHistoryObject[4].PolicyStageDate | date: 'MMM dd, yyyy' : 'UTC') :
                                    '--'}}</label>
                            </div>
                        </li>
                        <li class="flex items-center p-10">
                            <span class="mr-10 material-icons text-gray">
                                {{!!policyDetails.StageHistoryObject[5] ? 'check_circle_outline' :
                                'radio_button_unchecked'}}
                            </span>
                            <div class="flex flex-col">
                                <h3 class="text-sm font-semibold capitalize">Issued</h3>
                                <label class="text-xs font-semibold text-gray">{{ !!policyDetails.StageHistoryObject[5]
                                    ? (policyDetails.StageHistoryObject[5].PolicyStageDate | date: 'MMM dd, yyyy' : 'UTC') :
                                    '--'}}</label>
                            </div>
                        </li>
                        <li class="flex items-center p-10">
                            <span class="mr-10 material-icons text-gray">
                                {{!!policyDetails.StageHistoryObject[6] ? 'check_circle_outline' :
                                'radio_button_unchecked'}}
                            </span>
                            <div class="flex flex-col">
                                <h3 class="text-sm font-semibold capitalize">Inforce</h3>
                                <label class="text-xs font-semibold text-gray">{{ !!policyDetails.StageHistoryObject[6]
                                    ? (policyDetails.StageHistoryObject[6].PolicyStageDate | date: 'MMM dd, yyyy' : 'UTC') :
                                    '--'}}</label>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    </section>
</section>
<div *ngIf="showSimonView" class="relative flex flex-grow w-full">
    <div *ngIf="environment.qa" class="absolute top-0 right-0 px-10 m-10 rounded-full shadow-lg bg-gray-lighter">
        <mat-slide-toggle class="m-10 ml-auto" [(ngModel)]="showLocalSimon" (change)="openSimonSso()">Local
            Simon</mat-slide-toggle>
    </div>
    <iframe class="flex-grow w-full min-h-720" [appIframeData]="policyGuid ? componentUrl : undefined"
        [src]="iframeUrl | safeUrl"></iframe>
</div>
