import { Policy } from '../models/policy.models';

export const getCPCSum = (policies:Policy[]):string => {
  if (policies.length < 1) return '0';

  // let test1 = policies
  // .filter(policy => policy.IssuedCpcs != null && policy.Cpcs !== null)
  // let test2 = test1.map(policy =>  policy.IssuedCpcs)
  // let test3 = test2.reduce((a,b) => a + b, 0);
  // console.log(test2);

  // test2.forEach(item => {
  //     if (item > 0) console.log(item)
  // })

  return Math.round(policies
    .filter(policy => policy.IssuedCpcs != null && policy.Cpcs !== null)
    .map(policy =>  policy.IssuedCpcs !== 0 ? policy.IssuedCpcs : policy.Cpcs)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0)).toLocaleString();
};