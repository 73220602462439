import { userSettingsComponentsEnum } from '../enums/user-settings-components.enum';

export const userSettingsComponents: userSettingsComponents[] = [
  {
    name: userSettingsComponentsEnum.UserProfile,
    icon: 'person',
    hidden: true,
  },
  {
    name: userSettingsComponentsEnum.WebsiteLogo,
    icon: 'image',
    hidden: false,
  },
  {
    name: userSettingsComponentsEnum.Renewals,
    icon: 'event',
    hidden: false,
  }
];

export interface userSettingsComponents {
  name: string;
  icon: string;
  hidden: boolean;
}
