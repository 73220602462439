import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const simonLogoutCommand = 'logout-done';
export const simonLogout = new Observable<boolean>((observer) => {
  sendSimonLogout();

  window.addEventListener('message', (event:any) => {
    if (isValidEvent(event)) {
      observer.next(true);
    } else {
      console.log('Invalid event sent via simon iframe', event);
    }
  });

  setTimeout(() => {
    // if we don't receive a message back from simon just logout user
    observer.next(true);
  }, 3500);
});

const isValidEvent = (event:any): boolean => {
  if ((event.origin !== environment.simonIframeOrigin || event.origin !== environment.simonIframeLocalOrigin) ||
  (event.data.command != simonLogoutCommand)) {
    return true;
  } else {
    return false;
  }
};

const sendSimonLogout = (): void => {
  const simonIframe = document.getElementById('simonIframe') as HTMLIFrameElement;
  if (simonIframe){
    if (!simonIframe.src) simonIframe.src = environment.simonIframeOrigin;
    simonIframe.contentWindow?.postMessage('logout', environment.simonIframeOrigin);
  }
};