import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { PermissionService } from 'src/app/core/services/auth/permission.service';
import { fadeIn } from 'src/app/animations';
import { SsoService } from 'src/app/core/services/sso.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SimonIframeUrl } from 'src/app/shared/models/simon-iframe-url.models';
import { fia } from 'src/app/shared/constants/simon-education-nav-items.constants';

@Component({
  animations: [fadeIn],
  selector: 'app-fixed-indexed-annuities',
  templateUrl: './fixed-indexed-annuities.component.html',
})
export class FixedIndexedAnnuitiesComponent implements OnInit {
  param: string = 'Fixed Indexed Annuity'
  resourceUrl: string = 'Fixed/Indexed Annuities';
  localSimon: boolean = false;
  iframeUrl: string = environment.simonUrl;
  navItems: SimonIframeUrl[] = fia;

  constructor(private activatedRoute:ActivatedRoute, private titleService: Title) {
  }

  ngOnInit(): void {
    this.openSimonSso(this.param);
    this.titleService.setTitle(this.resourceUrl + ' | Agent Portal');
  }

  getResourceUrl() {
    // const homeUrl: string = '/Portal/FigPlatform/Details/';
    // return window.location.pathname.split(homeUrl)[1];
    return this.activatedRoute.snapshot.paramMap.get('resource')!;
  }

  openSimonSso(param?: string) {
    this.iframeUrl =  this.localSimon ? environment.simonLocalUrl : environment.simonUrl;

    if (!!param) {
      const encodedParam = encodeURI(param);
      this.iframeUrl += '/learning-center2/annuities/resources?contractTypeName=' + encodedParam;
    }
  }
}