<!--<p>Firelight ActivityId: {{activityId}}</p>
<p>isNew: {{isNew}}</p>-->
<div class="flex flex-col flex-grow" [class]="isExpanded ? 'is-expanded' : 'relative'" @fadeIn>
  <mat-checkbox *ngIf="environment.qa" class="p-5 bg-white checkbox" [(ngModel)]="localSimon" (change)="openSimonSso()">Local Simon
  </mat-checkbox>
  <span class="absolute flex items-center justify-center transition-colors duration-300 bg-white rounded cursor-pointer top-5 right-5 bg-opacity-80 hover:bg-blue-lighter hover:bg-opacity-80 w-36 h-36" 
  (click)="isExpanded = !isExpanded">
    <span class="material-icons-outlined mi-18 text-blue-dark" @fadeIn>
      <span *ngIf="!isExpanded">open_in_full</span>
      <span *ngIf="isExpanded">close_fullscreen</span>
    </span>
  </span>
  <iframe class="w-full min-h-720" [appIframeData] [src]="iframeUrl | safeUrl"></iframe>
</div>