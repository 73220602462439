import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Table } from 'src/app/shared/classes/table';
import { RequirementsComponent } from '../../../requirements/requirements.component';
import { PendingPolicyTableHeaders } from '../../constants/pendingPolicyTableHeaders.constants';
import { PendingTablePolicy } from '../../models/pendingTablePolicy.model';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';


@Component({
  selector: 'app-pending-policy-table',
  templateUrl: './pending-policy-table.component.html'
})
export class PendingPolicyTableComponent implements OnInit, OnChanges {

  @Input() pendingPolicies: PendingTablePolicy[] = [];
  @Input() selectedAgentIds: string[] = [];
  table: Table<PendingTablePolicy> = new Table<PendingTablePolicy>(PendingPolicyTableHeaders, 1, true);
  isError: boolean = false;
  loadingModal: boolean = false;
  reportType: string = '';
  isLoading: boolean = true;
  showAgentColumn: boolean = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private sessionStore: SessionStoreService,) { }

  ngOnInit(): void {
    this.reportType = this.activatedRoute.snapshot.data['reportType'] as string;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes[ 'pendingPolicies' ]?.isFirstChange() && changes[ 'pendingPolicies' ]?.previousValue !== changes[ 'pendingPolicies' ]?.currentValue) {
      this.table.setBody(this.pendingPolicies);
      this.isLoading = false;
    }
    if (!changes[ 'selectedAgentIds' ]?.isFirstChange()) {
      this.toggleAgentColumn();
    }
  }

  openPolicyDetails(row: PendingTablePolicy): void {
    this.router.navigate(['/Portal/Business/' + this.reportType + '/PolicyDetails/' + row.PolicyGuid]);
  }

  openRequirementModal(row: PendingTablePolicy): void {
    this.loadingModal = true;
    const dialogRef = this.dialog.open(RequirementsComponent, {
      data: {
        policyGuid: row.PolicyGuid,
        isVariable: row.IsVariable,
        lobId: row.LineOfBusinessId,
        requirementHeader: { Carrier: row.Carrier, Product: row.Product },
        detailsUrl: `/Portal/Business/${this.reportType}/PolicyDetails/${row.PolicyGuid}`
      },
      width: '72rem',
      height: '84rem'
    });
    dialogRef.afterClosed().subscribe(() => {
      this.loadingModal = false;
    });
  }

  toggleAgentColumn(): void {
    if (this.selectedAgentIds.length == 1 && this.selectedAgentIds.includes(this.sessionStore.User.AgentArcGuid!)) {
      this.table.Header = PendingPolicyTableHeaders.filter(h => h.DisplayName !== 'Agent');
      this.showAgentColumn = false;
    } else {
      this.table.Header = PendingPolicyTableHeaders;
      this.showAgentColumn = true;
    }
  }
}
