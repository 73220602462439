import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import {ListResponse} from '../../../shared/models/generic.models';
import {CarrierTraining} from '../../../shared/models/carrier.models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CarrierReportsApiService {

  constructor(private http:HttpClient) { }

  getCarrierTraining():Observable<CarrierTraining[]> {
    return this.http.get<ListResponse<CarrierTraining>>('/api/CarrierReports/CarrierTraining')
      .pipe(map((x:ListResponse<CarrierTraining>) => x.Entries));
  }
}
