import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EAppPreferenceEnum } from 'src/app/shared/models/user-settings.model';
import { EAppOption } from '../../../models/e-application.model';

@Component({
  selector: 'app-eapp-option',
  templateUrl: './eapp-option.component.html',
  styles: [
  ]
})
export class EAppOptionComponent {
  @Input() eAppOption?: EAppOption
  @Output() optionSelected = new EventEmitter<EAppPreferenceEnum>();
  eAppPreferenceEnum = EAppPreferenceEnum;
  constructor() { }

  selectOption(option: EAppPreferenceEnum): void {
    this.optionSelected.emit(option);
  }
}

