import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
})
export class MarketplaceComponent implements OnInit {
  resourceUrl: string = 'Marketplace';

  constructor(private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.resourceUrl + ' | Agent Portal');
  }
}
