import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { orderBy } from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import { fadeIn, fadeInOut } from 'src/app/animations';
import { ReportsApiService } from 'src/app/core/services/http/reports-api.service';
import { SimonApiService } from 'src/app/core/services/http/simon-api.service';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { getAverage } from 'src/app/shared/helpers/average.helper';
import { searchFilter } from 'src/app/shared/helpers/search.helper';
import { getSum } from 'src/app/shared/helpers/sum.helper';
import { AgentCommissionGroupList, AgentGuidList } from 'src/app/shared/models/agent.models';
import { Policy, PolicyList, PolicyListFilter } from 'src/app/shared/models/policy.models';
import { sidenavConfig } from 'src/app/shared/models/sidenav-config.model';
import { Title } from '@angular/platform-browser';
import { ArcApiService } from 'src/app/core/services/http/arc-api.service';
import { catchError } from 'rxjs/operators';
import { PermissionService } from 'src/app/core/services/auth/permission.service';
import { getCPCSum } from 'src/app/shared/helpers/cpc.helper';
import { AmplitudeEventService } from 'src/app/core/services/amplitude/amplitude-event.service';
import { InforceTablePolicy } from './models/inforceTablePolicy.model';
import { LineOfBusinessId } from 'src/app/shared/enums/line-business-id.enum';

@Component({
  animations: [fadeIn, fadeInOut],
  selector: 'app-lifecycle-policy-list',
  templateUrl: './lifecycle-policy-list.component.html',
})
export class LifecyclePolicyListComponent implements OnInit, PolicyList {
  @ViewChild('businessSidenav') businessSidenav!: MatSidenav;
  title: string = 'Lifecycle';
  allPolicies!: Policy[];
  columnName: string[] = ['PolicyStatusDate'];
  currentFilters!: PolicyListFilter;
  getSum = getSum;
  getAverage = getAverage;
  getCPCSum = getCPCSum;
  hasSimonOptIn: boolean = false;
  isError: boolean = false;
  isLoading: boolean = true;
  loadingModal!: boolean;
  orderBy = orderBy;
  reportType!: string;
  reverseOrder: boolean = false;
  searchText!: string | null;
  sidenavConfig: sidenavConfig;
  queryStartDate!: Date;
  queryEndDate!: Date;
  needToQueryAgentPolicies: boolean = true;
  averagePremium = 0;
  totalPremium = 0;
  averageFaceAmount = 0;
  totalFaceAmount = 0;
  averageAnnualPremium = 0;
  totalAnnualPremium = 0;
  policyCount = 0;
  cpcTotal = '';

  filteredPolicies: Policy[] = [];
  inforcePolicies: InforceTablePolicy[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private reportsApiService: ReportsApiService,
    private router: Router,
    private sessionStore: SessionStoreService,
    private simonApiService: SimonApiService,
    private arcApiService: ArcApiService,
    private permissionService: PermissionService,
    private amplitudeEventService: AmplitudeEventService) {
    this.sidenavConfig = {
      IsOpened: false,
      Mode: 'side',
    };
    this.titleService.setTitle(activatedRoute.snapshot.data.title + ' | Agent Portal');
  }

  ngOnInit(): void {
    this.queryStartDate = this.currentFilters.StartDate;
    this.queryEndDate = this.currentFilters.EndDate;
    this.reportType = this.activatedRoute.snapshot.data['reportType'] as string;
  }

  agentFilter(policy: Policy): boolean {
    const agentIds = this.currentFilters.SelectedAgentIds;
    let match = false;
    if (agentIds && agentIds.length > 0) {
      match = agentIds.some(function (a) {
        return (a.replace(/[{}]/g, '').toLowerCase() === policy.AgentGuid.replace(/[{}]/g, '').toLowerCase())
          || (policy.AssociatedPortalAgentGUIDS != null ? policy.AssociatedPortalAgentGUIDS.split(',').some(function (aa: any) {
            return a.replace(/[{}]/g, '').toLowerCase() === aa.replace(/[{}]/g, '').toLowerCase();
          }) : false);
      });
    }
    return match;

  }

  dateFilter(value: Policy): boolean {
    if (value.InforceDate == null) return true;
    return (new Date(value.InforceDate!) > new Date(this.currentFilters.StartDate) && (new Date(value.InforceDate!)) < new Date(this.currentFilters.EndDate));
  }

  export(): Observable<Blob> {
    if (this.filteredPolicies.length === 0) {
      this.openSnackbar('No policies. Please select different filters.', 'is-default');
    }

    const model = {
      IsInforceReport: true,
      AveragePremium: this.getAverage(this.filteredPolicies.filter(t => !t.IsPartial), 'SinglePremium'),
      TotalPremium: this.getSum(this.filteredPolicies.filter(t => !t.IsPartial), 'SinglePremium'),
      AverageFaceAmount: this.getAverage(this.filteredPolicies.filter(t => !t.IsPartial), 'FaceAmount'),
      TotalFaceAmount: this.getSum(this.filteredPolicies.filter(t => !t.IsPartial), 'FaceAmount'),
      AverageAnnualPremium: this.getAverage(this.filteredPolicies.filter(t => !t.IsPartial), 'AnnualizedPremium'),
      TotalAnnualPremium: this.getSum(this.filteredPolicies.filter(t => !t.IsPartial), 'AnnualizedPremium'),
      Policies: this.filteredPolicies.filter(t => !t.IsPartial)
    };
    return this.reportsApiService.exportPolicies(model);
  }

  getAgentGuidList(): AgentGuidList {
    // All agent and lob id's are being used since we are filtering on the front end
    return {
      AgentIds: this.currentFilters.AllAgentIds.join(','),
      LobIds: this.currentFilters.AllLobIds,
      StartDate: this.currentFilters.StartDate,
      EndDate: this.currentFilters.EndDate,
      IncludeUser: false,
      CanSeeAgentCommissions: false
    };
  }

  getCommissionGroupList(): AgentCommissionGroupList {
    return {
      ParentAgentGuid: this.currentFilters.ParentAgentGuid,
      CommissionGroupIds: this.currentFilters.SelectedSubEntities.join(','),
      LobIds: this.currentFilters.AllLobIds,
      StartDate: this.currentFilters.StartDate,
      EndDate: this.currentFilters.EndDate,
      IncludeUser: false,
      CanSeeAgentCommissions: false
    };
  }

  getFilteredPolicies(): Policy[] {
    return this.allPolicies.filter(policy => {
      return this.lobFilter(policy) && (this.currentFilters.SelectedSubEntities.length > 0 || this.agentFilter(policy)) && this.searchTextFilter(policy) && this.dateFilter(policy);
    });
  }

  getPolicies(isSubEntities: boolean): void {
    const httpCalls: Observable<Policy[]>[] = [];

    if (isSubEntities) {
      // SubEntities
      const commissionGroupList: AgentCommissionGroupList = this.getCommissionGroupList();
      this.isLoading = true;
      if (this.reportType === 'Fixed') {
        httpCalls.push(this.reportsApiService.getCommissionGroupInforcePolicies(commissionGroupList).pipe(catchError(error => of(error))));
        httpCalls.push(this.reportsApiService.getCommissionGroupInforcePaidPolicies(commissionGroupList).pipe(catchError(error => of(error))));
      } else httpCalls.push(this.reportsApiService.getCommissionGroupInforceVariablePolicies(commissionGroupList).pipe(catchError(error => of(error))));
    } else {
      // Agents
      this.isLoading = true;
      const agentGuidList = this.getAgentGuidList();
      if (this.reportType === 'Fixed') {
        httpCalls.push(this.reportsApiService.getInforcePolicies(agentGuidList).pipe(catchError(error => of(error))));
        httpCalls.push(this.reportsApiService.getInforcePaidPolicies(agentGuidList).pipe(catchError(error => of(error))));
      } else httpCalls.push(this.reportsApiService.getInforceVariablePolicies(agentGuidList).pipe(catchError(error => of(error))));
    }

    forkJoin(httpCalls).subscribe({
      next: res => {
        this.reportType == 'Fixed' ? this.allPolicies = res[0].concat(res[1]) : this.allPolicies = res[0];
        this.setTable();

        this.amplitudeEventService.logBusinessReporting(this.reportType, this.title, this.allPolicies.length);
        this.isError = false;
        this.isLoading = false;
        if (this.currentFilters.CommisionGroupsFilterOn) {
          this.needToQueryAgentPolicies = true;
        } else {
          this.needToQueryAgentPolicies = false;
        }
      },
      error: () => {
        this.isError = true;
        this.openSnackbar('Sorry, an error has occurred. Please try again later.', 'is-error');
      }
    }).add(() => {
      this.isLoading = false;
    });
  }

  lobFilter(policy: Policy): boolean {
    let match = false;
    if (this.currentFilters.SelectedLobIds && this.currentFilters.SelectedLobIds.length > 0) {
      match = this.currentFilters.SelectedLobIds.some((lobId) => lobId == policy.LineOfBusinessId);
    }
    return match;
  }

  needsExpandedDateQuery(): boolean {
    return (this.currentFilters.StartDate < this.queryStartDate
      || this.currentFilters.StartDate > this.queryEndDate
      || this.currentFilters.EndDate > this.queryEndDate
      || this.currentFilters.EndDate < this.queryStartDate);
  }

  onFilterChange(filters: PolicyListFilter): void {
    this.currentFilters = filters;

    if (this.currentFilters.SelectedSubEntities.length > 0) {
      this.getPolicies(true);
    } else if (this.needToQueryAgentPolicies || !this.allPolicies || this.currentFilters.IsClearingPolicies || this.needsExpandedDateQuery()) {
      //if policies haven't been loaded make api call OR if changing from subEntities to subAgents
      this.queryStartDate = this.currentFilters.StartDate;
      this.queryEndDate = this.currentFilters.EndDate;
      this.getPolicies(false);
    } else {
      // else just filter existing policies
      this.setTable();
    }
  }

  openSnackbar(message: string, toastClass: string): void {
    this.snackbar.open(message, 'Close', {
      panelClass: [toastClass]
    });
  }

  searchTextFilter(policy: Policy): boolean {
    if (!this.searchText) return true;
    return searchFilter(policy, this.searchText);
  }

  setSearchText(searchText: string | null): void {
    this.searchText = searchText;
    this.setTable();
  }

  setTable(): void {
    this.isLoading = true;

    this.filteredPolicies = this.getFilteredPolicies();
    this.inforcePolicies = this.filteredPolicies.map(p => {
      let premium: number | null = null;
      if (p.IsPaidPolicy && p.PolicyGuid) {
        premium = p.IssuedPremium;
      } else {
        if (p.LineOfBusinessId === LineOfBusinessId.Annuity) {
          premium = p.IssuedPremium;
        } else if (p.TotalIssuedPremium) {
          // For All non Annuities, we will show the Totaled Issued Premium = (ModalIssued Premium + 1035Amount + IssuedLumpSum)
          premium = p.TotalIssuedPremium;
        } else if (!p.TotalIssuedPremium) {
          // For All non Annuities, If the Totaled Issued Premium is null or 0, then use the total Modal Premium = (Modal Premium + 1035Amount + IssuedLumpSum)
          premium = p.ModalPremium || null;
        }
      }
      return {
        Agent: p.FirstName + ' ' + p.LastName,
        ActionNeeded: p.ActionNeeded,
        Carrier: p.Carrier,
        Clients: p.Clients,
        FaceAmount: p.FaceAmount,
        Target: p.Target,
        LineOfBusinessId: p.LineOfBusinessId,
        PolicyGuid: p.PolicyGuid,
        PolicyNumber: p.PolicyNumber,
        PolicyStatus: p.PolicyStatus,
        PolicyStatusDate: p.PolicyStatusDate,
        Premium: premium,
        Product: p.Product,
        IsVariable: p.IsVariable,
        InforceDate: p.InforceDate,
        IsPaidPolicy: p.IsPaidPolicy
      };
    });

    this.averagePremium = this.getAverage(this.filteredPolicies.filter(t => !t.IsPartial), 'SinglePremium');
    this.totalPremium = this.getSum(this.filteredPolicies.filter(t => !t.IsPartial), 'SinglePremium');
    this.averageFaceAmount = this.getAverage(this.filteredPolicies.filter(t => !t.IsPartial), 'FaceAmount');
    this.totalFaceAmount = this.getSum(this.filteredPolicies.filter(t => !t.IsPartial), 'FaceAmount');
    this.averageAnnualPremium = this.getAverage(this.filteredPolicies.filter(t => !t.IsPartial), 'AnnualizedPremium');
    this.totalAnnualPremium = this.getSum(this.filteredPolicies.filter(t => !t.IsPartial), 'AnnualizedPremium');
    this.cpcTotal = this.getCPCSum(this.filteredPolicies.filter(t => !t.IsPartial));
    this.policyCount = this.filteredPolicies.filter(t => !t.IsPartial).length;

    this.isLoading = false;
  }

  toggleNav(sidenav: MatSidenav): void {
    sidenav.toggle();
  }

  toggleSideNavMenu(): void {
    this.toggleNav(this.businessSidenav);
  }

  updateReportType(): void {
    this.reportType = this.activatedRoute.snapshot.data['reportType'] as string;
    this.getPolicies(false);
  }
}
