<!-- Post-quinci launch -->
<ng-container *ngIf="data.isPostQuinciLaunch && !data.onBehalfOfUser">
    <form class="relative flex flex-col w-full h-full" #optInForm="ngForm" (ngSubmit)="submitUserResponse(5000)" *ngIf="!data.onBehalfOfUser" @fadeIn>
        <section class="flex flex-col flex-auto overflow-auto" @fadeIn>
            <section class="flex flex-col flex-grow">
                <!-- Pre-form submissions -->
                <section *ngIf="!optInForm.submitted && !showOptOutConfirmation" class="flex flex-col flex-grow">
                    <section @fadeIn class="flex flex-col items-center justify-center w-full text-white bg-center bg-no-repeat bg-cover p-30 min-h-220 gap-y-10"
                             style="background-image:url('./assets/images/modal-wave.svg')">
                        <div class="flex flex-col m-10">
                            <span class="text-xs tracking-widest text-center uppercase text-blue-light">Introducing</span>
                            <img src="./assets/images/icons/quinci-logo-light.svg" alt="Quinci Logo" class="h-auto w-256">
                        </div>
                        <img class="mt-auto -mb-50" src="./assets/images/rocket-graphics.svg" />
                    </section>

                    <!-- TOU -->
                    <section class="flex flex-col items-center justify-center flex-none p-20 m-auto text-center max-w-512">
                        <div class="flex flex-col m-10 gap-y-10">
                            <h1 class="text-2xl font-bold">Welcome!</h1>
                            <p class="text-lg text-center">Your Brand-new Quinci Portal Experience Has Arrived. Please Click Below to Enroll.</p>
                        </div>
                    </section>
                    <p class="mt-auto font-semibold text-center m-30">By clicking the ENROLL button below you acknowledge that you have read, understand, and agree to our <a [href]="termsOfUseUrl" target="_blank" class="font-bold underline text-blue-dark">Terms of Use</a> and <a [href]="privacyPolicyUrl" target="_blank" class="font-bold underline text-blue-dark">Privacy Policy</a>.</p>
                </section>

                <!-- Confirm Opt-out -->
                <section *ngIf="!optInForm.submitted && showOptOutConfirmation" @fadeIn
                         class="flex flex-col flex-none p-20 m-auto text-center max-w-512">
                    <span class="flex items-center justify-center w-48 h-48 m-10 mx-auto rounded-full bg-orange-lightest">
                        <i class="material-icons text-orange">warning</i>
                    </span>
                    <h1 class="m-10 text-lg font-bold">Are you sure you would like to decline enrollment?</h1>
                    <p class="m-10">Continued use of the FIG Portal requires enrollment into the Quinci Platform. For any questions, please contact your sales team.</p>
                </section>

                <!-- Post-form submission -->
                <section *ngIf="optInForm.submitted && !showOptOutConfirmation" @fadeIn
                         class="flex flex-col flex-none p-20 m-auto text-center max-w-512">
                    <span class="flex items-center justify-center w-48 h-48 m-10 mx-auto rounded-full bg-green-lightest">
                        <i class="material-icons text-green">check_circle</i>
                    </span>
                    <h1 class="m-10 text-lg font-bold">Thank you!</h1>
                    <p class="m-10">In a moment, you'll be redirected to the Quinci powered by SIMON. You may also close
                        this pop-up at this point.</p>
                </section>
            </section>
        </section>
        <section class="flex flex-none p-20 border-t border-solid border-gray-lighter">
            <!-- Pre-form submissions -->
            <ng-container *ngIf="!optInForm.submitted">
                <!-- Pre-opt-out confirmation message -->
                <ng-container *ngIf="!showOptOutConfirmation">
                    <button mat-button class="m-10 mr-auto button is-warning is-outlined" type="button" (click)="optOutConfirmation()">Decline Enrollment</button>
                    <button mat-button class="m-10 button is-primary" type="submit" [disabled]="optInForm.invalid">Enroll in Quinci</button>
                </ng-container>

                <!-- Confirm Opt-out -->
                <ng-container *ngIf="showOptOutConfirmation">
                    <button *ngIf="data.isPostQuinciLaunch" mat-button class="m-10 mr-auto button is-warning is-outlined" type="button" (click)="declineQuinciOptIn()">Log Out</button>
                    <button mat-button class="m-10 button is-primary"
                            type="button" (click)="changedMyMind($event)"
                            [disabled]="optInForm.invalid">Enroll in Quinci</button>
                </ng-container>
            </ng-container>

            <!-- Post-form submissions -->
            <ng-container *ngIf="optInForm.submitted">
                <button mat-button class="m-10 ml-auto button is-primary" type="button" (click)="submitUserResponse(0)">Close Pop-up</button>
            </ng-container>
        </section>
    </form>
</ng-container>

<!-- On behalf of -->
<ng-container *ngIf="!!data.onBehalfOfUser">
    <section class="flex flex-col h-full overflow-hidden">
        <ng-container *ngIf="data.isPostQuinciLaunch">
            <section @fadeIn class="flex flex-col flex-grow p-40 overflow-auto">
                <div class="flex flex-col m-auto">
                    <span class="flex items-center justify-center w-48 h-48 m-10 mx-auto mb-20 rounded-full bg-orange-lightest">
                        <i class="material-icons text-orange">warning</i>
                    </span>
                    <ng-container *ngIf="data.isPostHardDeadline">
                        <p class="m-10">
                            The user you are trying to view has not yet enrolled into the Quinci platform. Enrollment into Quinci is required for continued use of the FIG Portal. Until the user you are trying to view has enrolled into the Quinci platform, you will no longer be able to view this user's account and will no longer write business on their behalf.
                        </p>
                        <p class="m-10">
                            Please contact the account owner to log-into their portal account and enroll into the Quinci platform to avoid any further interruptions to your business. For any questions, please have the account owner contact their sales team. Thanks!
                        </p>
                    </ng-container>
                </div>
            </section>
            <section class="flex mt-auto border-t border-solid p-30 gap-x-10 border-gray-lighter">
                <button mat-button class="ml-auto button is-primary" type="button" *ngIf="!data.isPostHardDeadline" mat-dialog-close>Close Pop-up</button>
                <button mat-button class="ml-auto button is-warning" type="button" *ngIf="data.isPostHardDeadline" (click)="declineQuinciOptIn()">Log Out</button>
            </section>
        </ng-container>
    </section>
</ng-container>