import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { MasterAdvisorAgreement, MasterAdvisorAgreementRequiredModel } from '../../../shared/models/agreement.models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AgreementsApiService {

  constructor(
    private http:HttpClient
  ) { }

  getMasterAdvisorAgreementRequiredRecord():Observable<MasterAdvisorAgreementRequiredModel|null> {
    return this.http.get<MasterAdvisorAgreementRequiredModel|null>('/api/MasterAdvisorAgreement/AdvisorAgreementRequired');
  }

  getLoggedInUserMasterAdvisorAgreement():Observable<MasterAdvisorAgreement> {
    return this.http.get<MasterAdvisorAgreement>('/api/MasterAdvisorAgreement/LoggedInUser');
  }

  initMasterAdvisorAgreement(workflow:string, requiredSteps?:number[]) {
    let url = `/api/MasterAdvisorAgreement?workflow=${workflow}&fromNewPortal=true`;

    if(requiredSteps){
      const arrayQueryParams = requiredSteps.map(step => `requiredSteps=${step}`).join('&');
      url += `&${arrayQueryParams}`;
    }

    return this.http.get(url, { responseType: 'text' as const});
  }

  createMasterAdvisorAgreement(envelopeData:MasterAdvisorAgreement):Observable<any>{
    return this.http.post('/api/MasterAdvisorAgreement', envelopeData);
  }

  getSurveyAgreementRenewalDocusignLink(surveyId: number, surveyRenewalId: number):Observable<string>{
    return this.http.get('/api/SurveyAgreement?surveyId=' + surveyId + '&surveyRenewalId=' + surveyRenewalId + '&newPortal=true', {responseType: 'text'});
  }

  docusignEnvelopeAcceptResponse(envelopeId:string, accepted:boolean):Observable<any>{
    return this.http.post('/api/SurveyAgreement/Docusign/Envelope/' + envelopeId + '/Accepted/' + accepted, null);
  }
}
