import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PolicyStatement } from '../../../shared/models/permission.models';
import { ListResponse, SingleResponse } from '../../../shared/models/generic.models';
import { User, UserDelegate } from '../../../shared/models/user.models';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {

  constructor(private http: HttpClient) { }

  readonly user = this.http.get<SingleResponse<User>>('/api/Users/LoggedInUser').pipe(map((x: SingleResponse<User>) => x.Entries), shareReplay(1))

  getLoggedInUserPermissions(): Observable<PolicyStatement[]> {
    return this.http.get<ListResponse<PolicyStatement>>('/api/Users/Me/Permissions/All')
      .pipe(map((x: ListResponse<PolicyStatement>) => x.Entries));
  }

  getUsersForDelegateUser(userId: string): Observable<UserDelegate[]> {
    return this.http.get<ListResponse<UserDelegate>>(`/api/Users/${userId}/DelegateOfUsers`)
      .pipe(map((x: ListResponse<UserDelegate>) => x.Entries));
  }

  get(userId: string): Observable<User> {
    return this.http.get<SingleResponse<User>>(`/api/Users/${userId}`).pipe(map((x: SingleResponse<User>) => x.Entries));
  }

  getUserInfo(): Observable<User> {
    return this.http.get<SingleResponse<User>>('/api/Users/LoggedInUser')
      .pipe(map((x: SingleResponse<User>) => x.Entries));
  }

  updateOnboardingCompletedPermissions() {
    return this.http.put('/api/Users/OnboardingCompletedPermissions', {});
  }

  getUserToolboxFavorites(userId: string): Observable<string[]> {
    return this.http.get<ListResponse<string>>(`/api/Users/${userId}/ToolboxFavorites`)
      .pipe(map((x: ListResponse<string>) => x.Entries));
  }

  updateUserToolboxFavorites(userId: string, favoriteIds: string[]) {
    return this.http.put<ListResponse<string>>(`/api/Users/${userId}/ToolboxFavorites`, favoriteIds)
      .pipe(map((x: ListResponse<string>) => x.Entries));
  }

  getIsArcUser(): Observable<boolean> {
    return this.http.get<boolean>('/api/Users/Fig/Arc/IsArcUser');
  }

  getIsOptedIn(): Observable<boolean> {
    return this.http.get<boolean>('/api/Users/ProductRD/IsOptedIn');
  }

  updateOptIn(): Observable<any> {
    return this.http.post('/api/Users/ProductRD/OptIn', null);
  }

  updateOptOut(): Observable<any> {
    return this.http.post('/api/Users/ProductRD/OptOut', null);
  }

  postUserLogo(userId: string, formData: FormData): Observable<string> {
    return this.http.post(`/api/Users/${userId}/Logo`, formData, { responseType: 'text' });
  }

  getUserLogo(userId: string): Observable<Blob> {
    return this.http.get(`/api/Users/${userId}/Logo`, { responseType: 'blob' });
  }

  deleteUserLogo(userId: string): Observable<any> {
    return this.http.delete(`/api/Users/${userId}/Logo`);
  }
}
