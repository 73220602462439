<div class="flex flex-col flex-grow w-full h-full overflow-auto">
<form #paperAppForm="ngForm" class="flex flex-col w-full h-full" (ngSubmit)="sendPolicyAndFilesToArc(paperPolicyApplication)">
    <section id="submitPaperApplicationToolbar" class="flex-none dialog-toolbar">
        <button class="absolute top-0 right-0 m-20 icon-button is-primary is-inverted" attr.aria-label="Close"
            [mat-dialog-close] type="button">
            <i class="material-icons">close</i>
        </button>
        <h2 class="mb-20 text-lg heading">Start A Paper Application</h2>
    </section>
    <div class="relative p-20 m-auto" *ngIf="isSubmitting || loading">
        <div class="loader-wrap">
            <div class="loader-sm is-blue"></div>
          </div>
        <p *ngIf="isSubmitting" class="m-10 text-base leading-loose text-center">Uploading application. This may take a moment...</p>
    </div>
      <div [@fadeIn] class="flex flex-col items-center my-auto text-lg font-bold text-center"
      *ngIf="isSuccess && !isSubmitting && !isError">
      <span class="flex items-center justify-center w-64 h-64 m-10 rounded-full bg-green-lightest text-green">
        <i class="material-icons mi-36">verified</i>
      </span>
      <p class="m-10 leading-loose text-center">Application Uploaded Successfully!</p>
      <p class="m-10 text-base leading-loose">
        Click <span class="no-underline cursor-pointer text-blue" (click)="reset()">here</span> to upload a new application. <br />
      </p>
    </div>
    <div [@fadeIn] class="flex flex-col items-center my-auto text-center" *ngIf="isError && !isSubmitting && !isSuccess">
        <span class="flex items-center justify-center w-64 h-64 m-10 rounded-full bg-orange-lightest text-orange">
          <i class="material-icons mi-36">warning</i>
        </span>
        <p class="m-10 leading-loose">
          Error uploading Application. Click <span class="no-underline cursor-pointer text-blue" (click)="reset()">here</span> to try to upload a new application. <br />
          .
        </p>
      </div>
    <section *ngIf="!isSubmitting && !isSuccess && !isError && !loading" class="flex flex-col p-20 overflow-auto lg:p-30 dialog-content">
        <p class="mx-10 text-base">
            To submit a paper application to FIG for processing, please complete the information below and upload the
            application.
        </p>
        <section class="flex flex-col" name="paperApp">
            <div class="flex flex-col flex-wrap w-full md:flex-row">
                <div class="flex w-full sm:w-1/2">
                    <div class="flex flex-col flex-grow mx-10 mt-20">
                        <label class="mb-10 text-sm font-semibold text-gray required-label">Client First Name</label>
                        <input class="h-40 px-10 border border-solid input is-inverted border-gray-light" type="text"
                            name="clientFirstName"
                            #clientFirstName="ngModel" 
                            [(ngModel)]="paperPolicyApplication.ClientFirstName"
                            aria-label="Applicant First Name" required minlength="1" />
                        <div [hidden]="clientFirstName.valid || clientFirstName.pristine"
                          class="mt-5 text-sm text-red">
                          Please Enter a First Name
                        </div>   
                    </div>
                </div>
                <div class="flex w-full sm:w-1/2"> 
                    <div class="flex flex-col flex-grow mx-10 mt-20">
                        <label class="mb-10 text-sm font-semibold text-gray required-label">Client Last Name</label>
                        <input class="h-40 px-10 border border-solid input is-inverted border-gray-light" type="text"
                            name="clientLastName" 
                            #clientLastName="ngModel"
                            [(ngModel)]="paperPolicyApplication.ClientLastName"
                            aria-label="Applicant Last Name" required minlength="1" />
                        <div [hidden]="clientLastName.valid || clientLastName.pristine"
                          class="mt-5 text-sm text-red">
                          Please Enter a Last Name
                        </div>   
                    </div>
                </div>
                <div class="flex w-full sm:w-1/2" name="autocompleteCarrier">
                    <div class="flex flex-col flex-grow mx-10 mt-20">
                        <label class="mb-10 text-sm font-semibold text-gray required-label">Carrier</label>
                        <input name='searchTextCarrier' [(ngModel)]="searchTextCarrier" type="text"
                            [matAutocomplete]='carrierauto'
                            class="h-40 px-10 border border-solid select is-inverted border-gray-light md-no-underline wrap-shadow-none"
                            flex requried
                            (ngModelChange)="filterCarriers()">
                        <mat-autocomplete #carrierauto="matAutocomplete"
                            class="border border-solid select min-h-256 is-inverted border-gray-light md-no-underline wrap-shadow-none"
                            flex
                            (optionSelected)="setCarrier($event.option.value)"
                            >
                            <mat-option *ngFor="let c of filteredCarriers" [value]="c.Business">
                                {{c.Business}}
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                </div>
                <div class="flex w-full sm:w-1/2" name="autocompleteProduct">
                    <div class="flex flex-col flex-grow mx-10 mt-20">
                        <label class="mb-10 text-sm font-semibold text-gray required-label">Product</label>
                        <input [disabled]="!carrier" 
                          name="searchTextProduct"
                          #searchProduct="ngModel"
                          [(ngModel)]="searchTextProduct"
                            type="text" [matAutocomplete]='productauto'
                            class="h-40 px-10 border border-solid select is-inverted border-gray-light md-no-underline wrap-shadow-none"
                            flex required
                            (ngModelChange)="filterProducts()">
                        <mat-autocomplete #productauto="matAutocomplete"
                            class="border border-solid select h-256 is-inverted border-gray-light md-no-underline wrap-shadow-none"
                            flex
                            (optionSelected)="setProduct($event.option.value)"
                            >
                            <mat-option *ngFor="let p of filteredProducts" [value]="p.Product">
                                {{p.Product}}
                            </mat-option>
                        </mat-autocomplete>

                        <div [hidden]="searchProduct.valid || searchProduct.pristine || paperPolicyApplication.PublicProductGuid"
                          class="mt-5 text-sm text-red">
                          Please Select a Product
                        </div>   
                    </div>
                </div>
                <div class="flex w-full">
                    <div class="flex flex-col flex-grow mx-10 mt-20">
                        <label class="mb-10 text-sm font-semibold text-gray required-label">State</label>
                        <mat-select name="state"
                            class="h-40 px-10 m-0 border border-solid select is-inverted md-no-underline border-gray-light"
                            required placeholder="Select State" aria-label="Select State"
                            name="stateId"
                            #stateId="ngModel"
                            [(ngModel)]="paperPolicyApplication.StateId">
                            <mat-option *ngFor="let state of states" [value]="state.StateID"
                                (onSelectionChange)="setState(state)" required>
                                {{state.StateName}}
                            </mat-option>
                        </mat-select>
                        <div [hidden]="stateId.valid || stateId.pristine"
                          class="mt-5 text-sm text-red">
                          Please Select a State
                        </div>   
                    </div>
                </div>
                <div class="flex w-full" flex="100">
                    <div class="flex flex-col flex-grow mx-10 mt-20">
                        <label class="mb-10 text-sm font-semibold text-gray">Notes &amp; Memos <span
                                class="font-normal">(optional)</span></label>
                        <textarea class="px-10 py-5 textarea" name="notes" rows="4"
                            placeholder="Provide any additional information regarding the application"
                            aria-label="Notes input" [(ngModel)]="paperPolicyApplication.Notes"></textarea>
                    </div>
                </div>
            </div>
            <div class="flex flex-none">
                <div class="flex flex-col flex-grow mx-10 mt-20">
                    <label class="mb-10 text-sm font-semibold text-gray required-label">Upload Policy Documents</label>
                    <div>
                    </div>
                    <div class="relative flex flex-col items-center justify-center p-20 text-center transition duration-150 border-2 border-dashed rounded cursor-pointer border-gray-lighter min-h-128 hover:text-blue hover:border-blue text-gray"
                        [class]="dragover? 'border-blue text-blue':''">
                        <input class="absolute top-0 left-0 w-full h-full opacity-0" accept=".pdf, .png, .jpg, .jpeg"
                            (dragover)="dragOverHandler($event)" type="file" multiple id="file"
                            (change)="uploadFiles($event)">
                        <label class="text-sm font-semibold">Click or drag files here to upload</label>
                        <label class="text-sm font-semibold">PDF, PNG, JPG</label>
                    </div>
                </div>
                <div class="flex flex-wrap flex-none mt-20" *ngIf="uploadingFiles.length">
                    <div class="flex items-center w-1/2 p-10 pr-20" *ngFor="let file of uploadingFiles">
                        <p class="mr-10">{{file.name}}</p>
                        <button class="ml-auto icon-button is-warning is-inverted is-sm" aria-label="Delete file button"
                            type="button" (click)="removeFile(file)">
                            <span class="material-icons mi-18">close</span>
                        </button>
                    </div>
                </div>
            </div>
            
            <div [hidden]="!(removedAFile && uploadingFiles.length === 0)"
              class="mt-5 text-sm text-red">
              Please Upload a File
            </div>   
          
        </section>
    </section>
    <section class="flex flex-none p-10 border-t border-solid lg:p-20 border-gray-lighter">
        <button mat-button class="m-10 ml-auto button is-primary is-lg p1-20 pr-25" type="submit"
            name="submitApplication" aria-label="Submit Paper Application"
            (click)="sendPolicyAndFilesToArc(paperPolicyApplication);"
            [disabled]="!paperAppForm.valid || uploadingFiles.length === 0 || isSubmitting || isUploading || isSuccess || isError">

            <span class="flex items-center justify-center">
                <i class="mr-5 fig-24px fig-send"></i>
                <span>Submit{{ isUploading || isSubmitting ? 'ting' : ''}} Paper Application</span>
            </span>
        </button>
    </section>
</form>
</div>