import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmailApiService } from 'src/app/core/services/http/email-api.service';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { createSupportEmailTemplate, emailRequestTypes } from 'src/app/shared/constants/support.constants';
import { User } from 'src/app/shared/models/user.models';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { supportTopicId } from 'src/app/shared/enums/support-topic-id.enum';
import { SupportTopic } from 'src/app/shared/models/email.models';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
})

export class SupportComponent implements OnInit {
  loading: boolean = false;
  message: string = '';
  toastClass: string = '';
  supportForm: any = {};
  supportTopics: SupportTopic[] = emailRequestTypes;
  selectedSupportTopic: SupportTopic | null = null;

  private generateForm(user: User) {
    this.supportForm = new FormGroup({
      FullName: new FormControl(user.FirstName + ' ' + user.LastName, [Validators.required]),
      Email: new FormControl(user.Email, [Validators.required, Validators.email]),
      Phone: new FormControl(''),
      SupportTopic: new FormControl(this.selectedSupportTopic, Validators.required),
      Message: new FormControl('', Validators.required),
    });
  }

  //reactive forms bypasses native validation by default in favor of form controler Validators, this touches all the form fields in event user tries to submit an invalid form without touching all required fields
  private markFormAsTouched(form: FormGroup) {
    Object.keys(form.controls).forEach(field => { // {1}
      const control = form.get(field);            // {2}
      control?.markAsTouched({ onlySelf: true });       // {3}
    });
  }

  onSubmit(form: FormGroup) {
    this.loading = true;
    this.markFormAsTouched(form);

    if (this.supportForm.invalid) {
      this.markFormAsTouched(form);
      return this.loading = false;
    } else {
      const user: User = {
        ...this.sessionStore.User,
        FullName: form.value.FullName,
        Email: form.value.Email,
        Phone: form.value.Phone,
      };

      const message: string = form.value.Message;
      const supportTopic = form.value.SupportTopic;
      const email = createSupportEmailTemplate(user, message, supportTopic);

      return this.emailApiService.createAndSendSupportEmail(email)
        .subscribe(
          resolve => {
            this.message = 'Ticket submitted successfully!';
            this.toastClass = 'is-success';
            this.dialog.closeAll();
          },
          error => {
            this.message = 'There was a problem sending your ticket. Please try again shortly.';
            this.toastClass = 'is-error';
          })
        .add(() => {
          this.loading = false;
          this._snackBar.open(this.message, 'Close', {
            panelClass: [this.toastClass]
          });
        });
    }
  }

  constructor(
    private emailApiService: EmailApiService,
    private sessionStore: SessionStoreService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.generateForm(this.sessionStore.User);
  }
}

