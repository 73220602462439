<section class="flex flex-col flex-grow h-0 p-20 overflow-auto" [@fadeIn]>
  <!-- *ngIf="!taskCompleted && !loading && !signError" -->
  <div [@fadeIn] class="flex flex-col flex-none my-auto" *ngIf="!taskCompleted && !loading && !signError">
    <div class="flex flex-col items-center m-10 my-20 text-center">
      <span class="flex items-center justify-center w-64 h-64 m-10 rounded-full bg-blue-lightest text-blue">
        <i class="material-icons mi-36">history_edu</i>
      </span>
      <h2 class="m-10 text-lg font-bold capitalize">The Master Advisor Agreement</h2>
    </div>
    <p class="m-10 leading-loose">
      FIG is thankful for the opportunity to work with you! The DocuSign&reg; button below will take you to FIG’s Master
      Advisor Agreement. The agreement sets forth, among other things, various responsibilities and expectations of the
      parties related to their business relationship.
    </p>
    <div
      class="flex flex-row p-20 m-10 my-20 text-sm leading-loose bg-opacity-25 border border-solid rounded border-blue-lighter bg-blue-lighter">
      <i class="mt-5 mr-10 material-icons mi-18 text-gray">info</i>
      Please note that if the need arises for you to also sign a FIG IMO or Broker Dealer Agreement, or certain other
      related FIG Agreements, any such document may be incorporated into the Master Advisor Agreement in accordance with
      the terms therein. Should you have any questions, please contact your FIG sales person.
    </div>
    <!--<button class="m-10 button is-lg is-primary" ng-disabled="$ctrl.isRedirecting || $ctrl.docIsSigned" ng-click="$ctrl.createAgreement()">
            <span ng-if="!$ctrl.isRedirecting">Go to docusign</span>
            <span ng-if="$ctrl.isRedirecting">Redirecting...</span>
        </button>-->
  </div>
  <div [@fadeIn] id="docIsSigned" class="flex flex-col items-center flex-none my-auto text-lg font-bold text-center"
    *ngIf="taskCompleted && !loading">
    <span class="flex items-center justify-center w-64 h-64 m-10 rounded-full bg-green-lightest text-green">
      <i class="material-icons mi-36">verified</i>
    </span>
    <p class="m-10 text-base leading-loose text-center" *ngIf="signError ">Looks like you've already signed.</p>
    <p class="m-10 leading-loose">
      Thank you for completing the agreement! <br />
    </p>
  </div>
  <div [@fadeIn] id="docError" class="flex flex-col items-center my-auto text-center" *ngIf="!taskCompleted && !loading && signError">
    <span class="flex items-center justify-center w-64 h-64 m-10 rounded-full bg-orange-lightest text-orange">
      <i class="material-icons mi-36">warning</i>
    </span>
    <p class="m-10 leading-loose">
      Error signing agreement. Please contact Support.
    </p>
  </div>
  <div *ngIf="loading" class="relative p-20 m-auto loader-wrap">
    <div class="loader-sm is-blue"></div>
  </div>
</section>
<section class="flex items-center justify-start flex-none p-20 mt-auto border-t border-solid border-gray-lighter">
  <button *ngIf="!isFirstStep" mat-button class="m-10 mr-auto button is-lg is-primary is-outlined" (click)='goToPrevious()'>
    <span class="flex flex-row items-center justify-center">
      <span class="material-icons mi-18">
        chevron_left
      </span>
      <span class="mr-10 leading-none">Previous</span>
    </span>
  </button>
  <button mat-button class="m-10 ml-auto button is-lg is-primary" (click)="goToNext()" *ngIf="taskCompleted">
    <span class="flex flex-row items-center justify-center">
      <span class="ml-10 leading-none">{{isLastStep ? 'Return To Portal' : 'Next' }}</span>
      <span class="material-icons mi-18">
        chevron_right
      </span>
    </span>
  </button>
  <button class="m-10 button is-lg is-primary" *ngIf="!taskCompleted"  (click)="createAgreement()"
    >
    <span *ngIf="!isRedirecting">Go to docusign</span>
    <span *ngIf="isRedirecting">Redirecting...</span>
  </button>
</section>