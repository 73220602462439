<section *ngIf="!isLoading" [@fadeIn]>
  <div class="flex flex-row" *ngFor="let survey of submitted; let index = index">
    <button class="mt-20 mr-20 button is-primary md:mt-30"
      (click)="!!survey && !survey.isEnrolled ? openEnrollmentModal(survey.surveyId) : ''" [disabled]="survey.isEnrolled">
      <span *ngIf="!!survey && !survey.isEnrolled">
        Enroll Today
      </span>
      <span matTooltip="Force CRM enrollment form already submitted" matTooltipPosition="above" 
       *ngIf="!!survey && survey.isEnrolled">Submitted</span>
    </button>
    <!-- Disable if purchased and expiration duration has not elapsed yet -->
    <button class="mt-20 mr-20 button is-primary md:mt-30" (click)="openPurchaseDialog()"
      *ngIf="!!survey && survey.isEnrolled && !!resource.PurchasePageData" [disabled]="!purchased?.Expired && purchased?.Complete">
      <span *ngIf="!purchased || purchased?.Expired">
        Purchase
      </span>
      <span *ngIf="!!purchased && purchased.Complete && !purchased.Expired">
        Purchased On {{purchased?.PurchaseDate}}
      </span>
    </button>
  </div>
</section>