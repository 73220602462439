import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PermissionService } from 'src/app/core/services/auth/permission.service';

@Component({
  selector: 'app-dashboard-upcoming',
  templateUrl: './dashboard-upcoming.component.html',
})
export class DashboardUpcomingComponent implements OnInit {
  upcomingEvents: UpcomingEvent[] = [
    {
      Title: 'Symposium 2023 | Sheraton Dallas',
      RegistrationUrl: '',
      InterestUrl: '',
      Img: './assets/images/icons/sym23-logo.svg',
      AttendUrl: '',
      ViewNowUrl: '',
      Agenda: '',
      Location: 'Dallas, TX',
      Date: 'February 22-24, 2023',
      ViewPermissions: 'FigSymposium:View|FIGTripsAndEvents:View'
    },
    {
      Title: 'Aruba Agent Convention 2023',
      RegistrationUrl: '',
      InterestUrl: '/NewPortal/Portal/FigPlatform/Details/Aruba',
      Img: 'https://www.figmarketing.com/files/images/aruba-3.png',
      AttendUrl: '',
      ViewNowUrl: '',
      Agenda: '',
      Location: 'Aruba',
      Date: 'May 8-13,&nbsp;2023',
      ViewPermissions: 'FIGTripsAndEvents:View'
    },
  ];
  @Output() loaded: EventEmitter<any> = new EventEmitter();
  constructor(
    private permissionService: PermissionService
  ) { }

  ngOnInit(): void {
    this.loaded.emit();
    this.filterEvents();
  }

  filterEvents(){
    this.upcomingEvents = this.upcomingEvents.filter(event => {
      return !!event.ViewPermissions ? this.permissionService.hasOnePermission(event.ViewPermissions) : true;
    });
  }
}

export interface UpcomingEvent {
  Agenda?: string,
  AttendUrl?: string,
  Date?: string,
  Img?: string,
  InterestUrl?: string,
  Location?: string,
  RegistrationUrl?: string,
  Title: string,
  ViewNowUrl?: string,
  ViewPermissions?: string
}
