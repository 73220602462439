export const isLastStep = (requiredSteps:number[]|null, stepId:number) => {
  if (requiredSteps !== null) {
    return requiredSteps[requiredSteps.length -1] == stepId;
  } else {
    return false;
  }
};

export const isFirstStep = (requiredSteps:number[]|null, stepId:number) => {
  if (requiredSteps !== null) {
    return requiredSteps[0] == stepId;
  } else {
    return false;
  }
};

export const getRequiredSteps = (steps:string|null):number[]|null => {
  return steps ? JSON.parse(decodeURIComponent(steps)) : null;
};