import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { fadeIn } from 'src/app/animations';
import { EAppWorkflowStartApplicationComponent } from 'src/app/portal/modals/eapp-workflow-start-application/eapp-workflow-start-application.component';

@Component({
  animations:[fadeIn],
  selector: 'app-workflow',
  templateUrl: './workflow.component.html'
})
export class WorkflowComponent {
  activeStep!: WorkflowStep;
  workflowSteps: WorkflowStep[] = [
    {Id: 1, State: 'PrepareEApplication', Title: 'Choose Your Product'},
    {Id: 2, State: 'WorkflowProductTraining', Title: 'Product Training'},
    {Id: 3, State: 'ViewEApplication', Title: 'E-Application'},
    {Id: 4, State: 'Submitted', Title: 'Submitted'},
  ]
  constructor(private dialog:MatDialog, private activatedRoute:ActivatedRoute) { }

  onActivate($event:any) {
    this.activeStep = this.workflowSteps.find(step => step.State.toLowerCase() === this.getComponent().toLowerCase()) || this.workflowSteps[0];
  }

  getComponent():string{
    const homeUrl: string = '/Portal/ProductRD/EApplication/Workflow/';
    const path: string[] = window.location.pathname.split(homeUrl);

    return path[1];
  }

  openSkipTraining(){
    this.dialog.open(EAppWorkflowStartApplicationComponent);
  }

}
export interface WorkflowStep {
  Id: number, Title: string, State: string
}