<div class="flex flex-col">
    <div class="max-w-full w-4/5 block relative m-20 mx-auto">
        <img class="w-full h-auto" src="./assets/images/12-Step_Process.png">
    </div>
    <div class="flex flex-row flex-wrap">
        <div class="flex flex-row flex-grow-0 p-20 lg:p-30 w-1/2" *ngFor="let step of nextSteps">
            <span
                class="flex flex-row items-center justify-center min-w-32 w-32 min-h-32 h-32 rounded-full bg-blue-lighter mr-10 -mt-5">
                <span class="font-bold leading-none text-blue-dark" [textContent]="step.Id">1</span>
            </span>
            <div class="w-auto">
                <p class="font-semibold mb-10" [innerHtml]="step.Title"></p>
                <p class="mb-10" *ngFor="let paragraph of step.Text" [innerHtml]="paragraph"></p>
            </div>
        </div>
    </div>
</div>