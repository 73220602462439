import { Component, OnInit } from '@angular/core';
import { fadeIn } from 'src/app/animations';
import { Title } from '@angular/platform-browser';
import { EnrollmentFormApiService } from 'src/app/core/services/http/enrollment-form-api.service';
import { RenewalFormComponent } from 'src/app/portal/modals/renewal-form/renewal-form.component';
import { MatDialog } from '@angular/material/dialog';
import { PermissionService } from 'src/app/core/services/auth/permission.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  animations: [fadeIn]
})
export class DashboardComponent implements OnInit {
  canSeeTrips: boolean = false;
  loading: boolean = false;
  loadedCount: number = 0;
  resourceUrl: string = 'Dashboard ';

  constructor(
    private dialog: MatDialog,
    private enrollmentFormApiService: EnrollmentFormApiService,
    private permissionService: PermissionService,
    private titleService: Title,) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.resourceUrl + ' | Agent Portal');
    this.loading = true;
    // this.checkForRenewals();
    this.canSeeTrips = this.permissionService.hasOnePermission('FIGTripsAndEvents:View');
  }

  addEvent(event: any) {
    this.loadedCount++;
    if (this.loadedCount == 4 || (!this.canSeeTrips && this.loadedCount == 3)) {
      this.loading = false;
    }
  }

  // checkForRenewals(): void {
  //   this.enrollmentFormApiService.getSurveyRenewals(null).subscribe(result => {
  //     if (result.some(r => r.SurveyRenewalStatusId === 1)) {
  //       const ref = this.dialog.open(RenewalFormComponent, {
  //         width: '72rem',
  //         disableClose: true,
  //         data: {
  //           SurveyRenewals: result,
  //           FilterBySurveyId: null
  //         }
  //       });
  //     }
  //   });
  // }
}
