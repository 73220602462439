import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { PermissionService } from '../../auth/permission.service';

@Injectable({
  providedIn: 'root'
})
export class CanSeeProductRDOptInGuardService {

  constructor(
    private permissionService: PermissionService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const canSeeSimonOptIn = this.permissionService.hasOnePermission('ShowProductRDOptIn:View');
    const hasSimonOptIn = this.permissionService.hasOnePermission('ProductRDOptedIn:View');
    if (!hasSimonOptIn && canSeeSimonOptIn) return true;
    else if(hasSimonOptIn) {
      this.router.navigate(['Portal/ProductRD']);
      return false;
    } else {
      this.router.navigate(['Portal/FigPlatform']);
      return false;
    }
  }
}
