import { Component, Input, OnInit } from '@angular/core';
import { ToolboxService } from 'src/app/core/services/toolbox.service';
import { ToolboxTile } from 'src/app/shared/models/toolbox.models';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-toolbox-sidebar',
  templateUrl: './toolbox-sidebar.component.html',
})
export class ToolboxSidebarComponent implements OnInit {
  @Input() grouping!:string;
  @Input() headerIcon?:string;
  tiles!:ToolboxTile[];
  categories!:string[];
  tilesByCategory:Record<string, ToolboxTile[]> = {};

  constructor(
    private dialog: MatDialog,
    private toolboxService:ToolboxService) { }

  ngOnInit(): void {
    this.tiles = this.toolboxService.getTilesBySimonGrouping(this.grouping);
    this.categories = [...new Set(this.tiles.map(x => x.Category))];
    this.setTilesByCategory();
  }

  setTilesByCategory(){
    this.categories.forEach(category => {
      const matchingTiles = this.tiles.filter(x => x.Category === category);
      this.tilesByCategory[category] = matchingTiles;
    });
  }
}
