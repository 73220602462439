<div class="relative" @fadeIn>
    <app-splash (splashClick)="scrollTo($event)"></app-splash>
    <div class="z-10 flex flex-col w-full h-full px-20 py-20 mx-auto md:py-50 max-w-1280" id="tutorials">
        <h2 class="mb-20 text-xl font-bold leading-tight text-center capitalize md:mb-40">Guides & Tutorials</h2>
        <div class="flex flex-wrap justify-center gap-x-40 gap-y-40">
            <ng-container *ngFor="let tutorial of tutorials">
                <div class="flex flex-col w-full md:max-w-256">
                    <div class="relative w-full mb-10 overflow-hidden rounded shadow min-h-150 wistia_responsive_padding"
                        *ngIf="!!tutorial.WistiaId">
                        <div class="wistia_responsive_wrapper"
                            style="height: 100%; left: 0; position: absolute; top: 0; width: 100%;">
                            <span id="resourceVideo{{tutorial.WistiaId}}"
                                class="wistia_embed wistia_async_{{tutorial.WistiaId}} popover=true popoverAnimateThumbnail=false videoFoam=false videoQuality=hd-only"
                                style="display: inline-block; height: 100%; width: 100%;" @fadeIn> </span>
                        </div>
                    </div>
                    <span class="text-lg font-bold text-center" [innerHtml]="tutorial.Name"></span>
                </div>
            </ng-container>
        </div>
    </div>
    <div id="productAvailability" class="z-10 flex flex-col w-full h-full px-20 py-20 mx-auto md:py-50 max-w-1280">
        <h2 class="text-xl font-bold leading-tight text-center capitalize">Carrier Availability</h2>
        <div class="flex flex-wrap">
            <section class="flex flex-col flex-grow m-30">
                <table *ngIf="!loading" class="text-sm table-auto" @fadeIn>
                    <thead>
                        <tr class="">
                            <td
                                class="sticky top-0 z-10 w-24 p-10 py-20 text-xs font-semibold uppercase text-gray bg-gray-lightest">
                            </td>
                            <td
                                class="sticky top-0 z-10 flex-grow p-10 py-20 text-xs font-semibold uppercase text-gray bg-gray-lightest min-w-320">
                                <span class="flex items-center min-h-24 focus:outline-none"
                                    attr.aria-label="Carrier Name">
                                </span>
                            </td>
                            <td
                                class="sticky top-0 z-10 p-10 py-20 text-xs font-semibold text-center uppercase text-gray bg-gray-lightest min-w-150 md:w-1/6">
                                <span class="flex items-center w-full min-h-24 focus:outline-none"
                                    (click)="orderRows('ProductTraining')" attr.aria-label="Sort by Product Training">
                                    <span class="w-full">Product Training</span>
                                    <!-- <span *ngIf="cell.ColumnName === columnName" class="material-icons text-gray">
                                    {{reverseOrder ? 'arrow_drop_up' : 'arrow_drop_down'}}
                                </span> -->
                                </span>
                            </td>
                            <td
                                class="sticky top-0 z-10 p-10 py-20 text-xs font-semibold text-center uppercase text-gray bg-gray-lightest min-w-150 md:w-1/6">
                                <span class="flex items-center w-full min-h-24 focus:outline-none"
                                    (click)="orderRows('ProductMarketplace')"
                                    attr.aria-label="Sort by Product Marketplace">
                                    <span class="w-full">Product Marketplace</span>
                                    <!-- <span *ngIf="cell.ColumnName === columnName" class="material-icons text-gray">
                                    {{reverseOrder ? 'arrow_drop_up' : 'arrow_drop_down'}}
                                </span> -->
                                </span>
                            </td>
                            <td
                                class="sticky top-0 z-10 p-10 py-20 text-xs font-semibold text-center uppercase text-gray bg-gray-lightest min-w-150 md:w-1/6">
                                <span class="flex items-center w-full min-h-24 focus:outline-none"
                                    (click)="orderRows('ProductIllustrations')"
                                    attr.aria-label="Sort by Product Illustrations">
                                    <span class="w-full">Product Illustrations</span>
                                    <!-- <span *ngIf="cell.ColumnName === columnName" class="material-icons text-gray">
                                    {{reverseOrder ? 'arrow_drop_up' : 'arrow_drop_down'}}
                                </span> -->
                                </span>
                            </td>
                            <td
                                class="sticky top-0 z-10 p-10 py-20 text-xs font-semibold text-center uppercase text-gray bg-gray-lightest min-w-150 md:w-1/6">
                                <span class="flex items-center w-full min-h-24 focus:outline-none"
                                    (click)="orderRows('Lifecycle')"
                                    attr.aria-label="Sort by Product Lifecycle Status">
                                    <span class="w-full">Lifecycle</span>
                                    <!-- <span *ngIf="cell.ColumnName === columnName" class="material-icons text-gray">
                                    {{reverseOrder ? 'arrow_drop_up' : 'arrow_drop_down'}}
                                </span> -->
                                </span>
                            </td>
                        </tr>
                    </thead>
                    <tbody class="w-full overflow-hidden card">
                        <ng-container *ngIf="!isError">
                            <ng-container *ngFor="let row of availableCarriers; let index = index; let last = last">
                                <tr id="carrierRow{{index}}"
                                    class="transition-colors hover:bg-blue-lightest "
                                    [class]="!last ? 'border-b border-solid border-gray-lighter' : ''"
                                    [attr.aria-label]="row.Fields.Name + 'Carrier Info'">
                                    <!-- 
                                    matTooltip="{{!loading ? 'Expand to Products' : ''}}" (click)="!loading && !!row.Fields.Products ? expandCarrierProducts(row) : ''" -->
                                    <td class="w-24 p-10 py-20">&nbsp;
                                        <!-- <button class="icon-button is-primary is-inverted is-sm"
                                            *ngIf="!!row.Fields.Products && row.Fields.Products.length > 0">
                                            <span class="material-icons mi-18">{{row.Fields.ShowProducts ?
                                                'keyboard_arrow_down' :
                                                'keyboard_arrow_right'}}</span>
                                        </button> -->
                                    </td>
                                    <td class="flex-grow p-10 py-20 font-bold min-w-320">
                                        {{row.Fields.Name}}
                                    </td>
                                    <td class="p-10 py-20 text-center min-w-150 md:w-1/6">
                                        <span class="px-20 py-5 text-sm font-bold rounded-full" [ngClass]="{'bg-green-lightest text-green-dark' : row.Fields.ProductTraining === 'Yes' || row.Fields.ProductTraining === 'Available',
                                    'bg-red-lighter text-red-dark' : row.Fields.ProductTraining === 'No',
                                    'bg-gray-lighter text-gray-dark' : row.Fields.ProductTraining === 'Coming Soon'}">
                                            {{row.Fields.ProductTraining}}
                                        </span>
                                    </td>
                                    <td class="p-10 py-20 text-center min-w-150 md:w-1/6">
                                        <span class="px-20 py-5 text-sm font-bold rounded-full" [ngClass]="{'bg-green-lightest text-green-dark' : row.Fields.Marketplace === 'Yes' || row.Fields.Marketplace === 'Available',
                                    'bg-red-lighter text-red-dark' : row.Fields.Marketplace === 'No',
                                    'bg-gray-lighter text-gray-dark' : row.Fields.Marketplace === 'Coming Soon'}">
                                            {{row.Fields.Marketplace}}
                                        </span>
                                    </td>
                                    <td class="p-10 py-20 text-center min-w-150 md:w-1/6">
                                        <span class="px-20 py-5 text-sm font-bold rounded-full" [ngClass]="{'bg-green-lightest text-green-dark': row.Fields.Illustrations === 'Yes' || row.Fields.Illustrations === 'Available',
                                    'bg-red-lighter text-red-dark' : row.Fields.Illustrations === 'No',
                                    'bg-gray-lighter text-gray-dark' : row.Fields.Illustrations === 'Coming Soon'}">
                                            {{row.Fields.Illustrations}}
                                        </span>
                                    </td>
                                    <td class="p-10 py-20 text-center min-w-150 md:w-1/6">
                                        <span class="px-20 py-5 text-sm font-bold rounded-full" [ngClass]="{'bg-green-lightest text-green-dark': row.Fields.Lifecycle === 'Yes' || row.Fields.Lifecycle === 'Available',
                                    'bg-red-lighter text-red-dark' : row.Fields.Lifecycle === 'No',
                                    'bg-gray-lighter text-gray-dark' : row.Fields.Lifecycle === 'Coming Soon'}">
                                            {{row.Fields.Lifecycle}}
                                        </span>
                                    </td>
                                </tr>
                                <!-- <ng-container *ngIf="!!row.Fields.ShowProducts">
                                    <tr [ngClass]="{'border-b border-gray-lighter': !!last}"
                                        *ngFor="let product of row.Fields.ProductList; let last = last; let even = even">
                                        < !-- <td class="p-10 w-24 bg-gray-lightest {{!!even ? 'bg-opacity-50': ''}}">
                                        </td> -- >
                                        <td
                                            class="p-10 min-w-320 flex-grow bg-gray-lightest {{!!even ? 'bg-opacity-50': ''}}">
                                            {{product.Fields.Name}}
                                        </td>
                                        <td
                                            class="p-10 min-w-150 md:w-1/6 bg-gray-lightest {{!!even ? 'bg-opacity-50': ''}} text-center">
                                            <span class="h-24 material-icons" [class]="!!product.Fields.ProductTraining ? 'text-green' : 'text-gray'">
                                                {{ !!product.Fields.ProductTraining ? 'check_circle' : 'remove_circle' }}
                                            </span>
                                        </td>
                                        <td
                                            class="p-10 min-w-150 md:w-1/6 bg-gray-lightest {{!!even ? 'bg-opacity-50': ''}} text-center">
                                            <span class="h-24 material-icons" [class]="!!product.Fields.Marketplace ? 'text-green' : 'text-gray'">
                                                {{!!product.Fields.Marketplace ? 'check_circle' : 'remove_circle'}}
                                            </span>
                                        </td>
                                        <td
                                            class="p-10 min-w-150 md:w-1/6 bg-gray-lightest {{!!even ? 'bg-opacity-50': ''}} text-center">
                                            <span class="h-24 material-icons" [class]="!!product.Fields.Illustrations ? 'text-green' : 'text-gray'">
                                                {{!!product.Fields.Illustrations ? 'check_circle' : 'remove_circle'}}
                                            </span>
                                        </td>
                                    </tr>
                                </ng-container> -->
                            </ng-container>
                        </ng-container>
                        <tr id="carrierErrorRow" class="" aria-label="Error getting policies" *ngIf="!!isError">
                            <td class="w-48 p-10"></td>
                            <td class="p-10" colspan="4">Sorry, an error has occurred. Please try again later.</td>
                        </tr>
                        <tr id="carrierErrorRow" class="" aria-label="No carriers found"
                            *ngIf="!isError && availableCarriers.length === 0">
                            <td class="w-48 p-10"></td>
                            <td class="p-10" colspan="4">No carriers found.</td>
                        </tr>
                    </tbody>
                </table>
                <div class="flex-grow w-full loader-wrap" *ngIf="loading" @fadeIn>
                    <div class="loader-sm is-blue"></div>
                </div>
            </section>
        </div>
    </div>
    <!--for when we need it :)-->
    <!-- <div class="z-10 flex flex-col w-full h-full p-20 mx-auto max-w-1280 md:p-30">
        <div class="grid grid-cols-2 py-10 mb-10 md:grid-cols-3 lg:grid-cols-4 gap-y-20 gap-x-20">
            <ng-container *ngFor="let cat of categories; let index = index">
                <div class="flex flex-row overflow-hidden transition duration-150 bg-center bg-no-repeat bg-cover card hover:shadow-lg"
                    *ngIf="cat.Id !== 0">
                    <a attr.aria-label="View Product R&D Categories"
                        [amplitudeEvent]="'Product RD Preview - ' + cat.Name"
                        class="flex flex-col flex-grow hover:no-underline hover:text-gray-dark focus:no-underline focus:text-gray-dark"
                        [class]="'cursor-pointer'" routerLink="/Portal/ProductRD/Preview" [fragment]="'Section' + index" target="_self">
                        <div class="flex flex-col bg-center bg-no-repeat bg-cover bg-gray-lightest" [class]="'h-96'"
                            style="background: url({{cat.TileBackgroundImage}})">
                            <div class="flex-grow-0 w-full h-48 mt-auto bg-bottom bg-no-repeat bg-cover"
                                style="background: url('./assets/images/wave-sm.svg')"></div>
                        </div>
                        <div class="flex-grow p-10 bg-white lg:p-20">
                            <span
                                class="flex flex-row items-center justify-center w-48 h-48 mx-auto mb-20 -mt-64 rounded-full"
                                [class]="'bg-blue text-white'">
                                <i class="material-icons" *ngIf="cat.Icon">{{cat.Icon}}</i>
                                <i class="fig-24px fig-box-open" *ngIf="!!cat.FigIcon"></i>
                            </span>
                            <h2 class="m-10 text-lg font-semibold text-center" [innerHtml]="cat.Name"></h2>
                            <p class="m-10 text-center" [textContent]="cat.DescriptionShort"></p>
                        </div>
                    </a>
                </div>
            </ng-container>
        </div>
        <ng-container *ngFor="let catSection of categories; let index = index">
            <section [id]="'Section' + index" class="pt-10 mt-10 md:mt-20 md:pt-30" *ngIf="catSection.Id !== 0">
                <div class="flex flex-row w-full">
                    <div class="flex flex-col w-full mx-auto md:w-1/2">
                        <h2 class="m-10 text-lg font-semibold text-center" [innerHtml]="catSection.Name"></h2>
                        <p class="px-10 mb-10 text-base text-center text-gray-dark md:mb-20 md:px-20"
                            [innerHtml]="catSection.Description"></p>
                    </div>
                </div>
                <div class="flex flex-row flex-wrap justify-center w-full mb-10 md:mb-20"
                    *ngIf="!!catSection.SubNav && !!catSection.SubNav.length">
                    <ng-container *ngFor="let tile of catSection.SubNav">
                        <div class="flex w-2/5 m-5 overflow-hidden transition duration-150 bg-center bg-no-repeat bg-cover flex-column sm:w-1/3 lg:w-1/6 sm:m-10 card hover:shadow-lg"
                            *ngIf="catSection.Id !== 0">
                            <a attr.aria-label="View resource page"
                                [amplitudeEvent]="'Product RD Preview | ' + catSection + ' - ' + tile.Name"
                                class="flex flex-col flex-grow hover:no-underline hover:text-gray-dark focus:no-underline focus:text-gray-dark"
                                [class]="'cursor-pointer'" [routerLink]="'/Portal/' + tile.RouterUrl" *ngIf="!!tile.RouterUrl">
                                <div class="flex flex-col bg-center bg-no-repeat bg-cover bg-gray-lightest"
                                    [class]="'h-96'" style="background: url({{tile.TileBackgroundImage}})">
                                    <div class="flex-grow-0 w-full h-48 mt-auto bg-bottom bg-no-repeat bg-cover"
                                        style="background: url('./assets/images/wave-sm.svg')"></div>
                                </div>
                                <div class="flex-grow p-10 bg-white lg:p-20">
                                    <span
                                        class="flex flex-row items-center justify-center w-48 h-48 mx-auto mb-20 -mt-64 rounded-full"
                                        [class]="'bg-blue-lightest text-white'">
                                        <i class="material-icons text-blue"
                                            *ngIf="catSection.Icon">{{catSection.Icon}}</i>
                                        <i class="fig-24px fig-box-open {{catSection.FigIcon}} text-blue"
                                            *ngIf="!!catSection.FigIcon"></i>
                                    </span>
                                    <h2 class="m-10 text-lg font-semibold text-center" [innerHtml]="tile.Name"></h2>                                    
                                </div>
                            </a>
                            <a attr.aria-label="View resource page"
                                [amplitudeEvent]="'Product RD Preview | ' + catSection + ' - ' + tile.Name"
                                class="flex flex-col flex-grow hover:no-underline hover:text-gray-dark focus:no-underline focus:text-gray-dark"
                                [class]="'cursor-pointer'" [href]="tile.ExternalUrl" target="_blank" *ngIf="!!tile.ExternalUrl">
                                <div class="flex flex-col bg-center bg-no-repeat bg-cover bg-gray-lightest"
                                    [class]="'h-96'" style="background: url({{tile.TileBackgroundImage}})">
                                    <div class="flex-grow-0 w-full h-48 mt-auto bg-bottom bg-no-repeat bg-cover"
                                        style="background: url('./assets/images/wave-sm.svg')"></div>
                                </div>
                                <div class="flex-grow p-10 bg-white lg:p-20">
                                    <span
                                        class="flex flex-row items-center justify-center w-48 h-48 mx-auto mb-20 -mt-64 rounded-full"
                                        [class]="'bg-blue-lightest text-white'">
                                        <i class="material-icons text-blue"
                                            *ngIf="catSection.Icon">{{catSection.Icon}}</i>
                                        <i class="fig-24px fig-box-open {{catSection.FigIcon}} text-blue"
                                            *ngIf="!!catSection.FigIcon"></i>
                                    </span>
                                    <h2 class="m-10 text-lg font-semibold text-center" [innerHtml]="tile.Name"></h2>                                    
                                </div>
                            </a>
                        </div>
                    </ng-container>
                </div>
                <div class="flex flex-row flex-wrap justify-center w-full mb-10 md:mb-20"
                    *ngIf="!!catSection.TileSubNav && !!catSection.TileSubNav.length">
                    <ng-container *ngFor="let tile of catSection.TileSubNav">
                        <div class="flex w-2/5 m-5 overflow-hidden transition duration-150 bg-center bg-no-repeat bg-cover flex-column sm:w-1/3 lg:w-1/6 sm:m-10 card hover:shadow-lg"
                            *ngIf="catSection.Id !== 0">
                            <a attr.aria-label="View resource page"
                                [amplitudeEvent]="'Product RD Preview | ' + catSection + ' - ' + tile.Name"
                                class="flex flex-col flex-grow hover:no-underline hover:text-gray-dark focus:no-underline focus:text-gray-dark"
                                [class]="'cursor-pointer'" [routerLink]="'/Portal/' + tile.RouterUrl" *ngIf="!!tile.RouterUrl">
                                <div class="flex flex-col bg-center bg-no-repeat bg-cover bg-gray-lightest"
                                    [class]="'h-96'" style="background: url({{tile.TileBackgroundImage}})">
                                    <div class="flex-grow-0 w-full h-48 mt-auto bg-bottom bg-no-repeat bg-cover"
                                        style="background: url('./assets/images/wave-sm.svg')"></div>
                                </div>
                                <div class="flex-grow p-10 bg-white lg:p-20">
                                    <span
                                        class="flex flex-row items-center justify-center w-48 h-48 mx-auto mb-20 -mt-64 rounded-full"
                                        [class]="'bg-blue-lightest text-white'">
                                        <i class="material-icons text-blue"
                                            *ngIf="catSection.Icon">{{catSection.Icon}}</i>
                                        <i class="fig-24px fig-box-open {{catSection.FigIcon}} text-blue"
                                            *ngIf="!!catSection.FigIcon"></i>
                                    </span>
                                    <h2 class="m-10 text-lg font-semibold text-center" [innerHtml]="tile.Name"></h2>                                    
                                </div>
                            </a>
                            <a attr.aria-label="View resource page"
                                [amplitudeEvent]="'Product RD Preview | ' + catSection + ' - ' + tile.Name"
                                class="flex flex-col flex-grow hover:no-underline hover:text-gray-dark focus:no-underline focus:text-gray-dark"
                                [class]="'cursor-pointer'" [href]="tile.ExternalUrl" target="_blank" *ngIf="!!tile.ExternalUrl">
                                <div class="flex flex-col bg-center bg-no-repeat bg-cover bg-gray-lightest"
                                    [class]="'h-96'" style="background: url({{tile.TileBackgroundImage}})">
                                    <div class="flex-grow-0 w-full h-48 mt-auto bg-bottom bg-no-repeat bg-cover"
                                        style="background: url('./assets/images/wave-sm.svg')"></div>
                                </div>
                                <div class="flex-grow p-10 bg-white lg:p-20">
                                    <span
                                        class="flex flex-row items-center justify-center w-48 h-48 mx-auto mb-20 -mt-64 rounded-full"
                                        [class]="'bg-blue-lightest text-white'">
                                        <i class="material-icons text-blue"
                                            *ngIf="catSection.Icon">{{catSection.Icon}}</i>
                                        <i class="fig-24px fig-box-open {{catSection.FigIcon}} text-blue"
                                            *ngIf="!!catSection.FigIcon"></i>
                                    </span>
                                    <h2 class="m-10 text-lg font-semibold text-center" [innerHtml]="tile.Name"></h2>
                                </div>
                            </a>
                        </div>
                    </ng-container>
                </div>
            </section>
        </ng-container>
    </div> -->
</div>