import { Component, OnInit, EventEmitter } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router, RouterLinkWithHref } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import * as _ from 'lodash';
import { NavItem } from '../shared/models/NavItem';
import { onboardingSteps } from '../shared/constants/onboarding.constants';
import { User, UserDelegate } from '../shared/models/user.models';
import { UserGroup } from '../shared/models/usergroups.models';
import { AgreementsApiService } from '../core/services/http/agreements-api.service';
import { AuthService } from '../core/services/auth/auth.service';
import { PermissionApiService } from '../core/services/http/permission-api.service';
import { PermissionService } from '../core/services/auth/permission.service';
import { UserApiService } from '../core/services/http/user-api.service';
import { SessionStoreService } from '../core/services/stores/session-store.service';
import { fadeIn, fadeInOut } from '../animations';
import { getRequiredSteps } from '../shared/helpers/onboarding.helper';


@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  animations: [fadeIn, fadeInOut]
})
export class OnboardingComponent implements OnInit {
  alternateView!: boolean;
  currentStep!: number | null;
  user!: User;
  delegates!: UserDelegate[];
  delegateId!: string | null;
  userGroup!: UserGroup | null;
  logo!: string | null;
  onBehalfOfUser!: User | null;
  onBehalfOfUserGroup!: UserGroup | null;
  workflow: string = 'ror';
  viewingUser!: User;
  isRedirecting: boolean = false;
  isLoading!: boolean;
  menuOpen: boolean = false;
  requiredSteps!: number[] | null;
  currentNavItemIndex: number = 0;
  navItems!: NavItem[];
  allNavItems: NavItem[] = onboardingSteps;

  constructor(
    private activatedRoute: ActivatedRoute,
    private agreementsApi: AgreementsApiService,
    private authService: AuthService,
    private permissionApiService: PermissionApiService,
    private permissionService: PermissionService,
    private router: Router,
    private sessionStore: SessionStoreService,
    private userApiService: UserApiService,
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.alternateView = this.activatedRoute.snapshot.queryParamMap.get('alternateView') == 'true' ? true : false;
    this.requiredSteps = getRequiredSteps(this.activatedRoute.snapshot.queryParamMap.get('requiredSteps'));
    this.configureNav(this.requiredSteps);
    this.configureUserInfo();
    this.currentNavItemIndex = this.getCurrentNavItemIndex();
    this.isLoading = false;
  }

  configureNav(requiredNavItems: number[] | null) {
    if (requiredNavItems == null) {
      this.navItems = this.allNavItems;
    } else {
      this.navItems = this.allNavItems.filter(item => {
        return requiredNavItems.includes(item.Id) || item.Id == 0;
      });
    }
  }

  configureUserInfo() {
    this.user = this.sessionStore.User;
    this.userApiService.getUsersForDelegateUser(this.user.Id).subscribe(res => {
      this.delegates = res;
    });
    this.userGroup = this.sessionStore.UserGroup;
    this.onBehalfOfUser = this.sessionStore.OnBehalfOfUser;
    this.onBehalfOfUserGroup = this.onBehalfOfUserGroup;
    // this.logo = this.sessionStore.OnBehalfOfUserGroupLogo ?? this.sessionStore.UserGroupLogo;
    this.logo = this.sessionStore.UserLogo;

    this.delegateId = this.permissionService.getUserDelegateId();

    if (this.delegateId !== null && this.onBehalfOfUser === null) {
      const delegate = this.delegates.find(d => d.Id === this.delegateId);
      this.onBehalfOfUser = delegate === undefined ? null : delegate.OnBehalfOfUser;
    }
  }

  getCurrentNavItemIndex() {
    const match = this.navItems.findIndex(i => window.location.pathname.includes(i.Url));
    if (match > -1) return match;
    else return 0;
  }

  // goToFirstStep(){
  //   let queryParams = {};
  //   if (this.requiredSteps) queryParams = {requiredSteps: encodeURIComponent(JSON.stringify(this.requiredSteps))};
  //   this.router.navigate([`${this.navItems[this.currentNavItemIndex].Url}`], { relativeTo: this.activatedRoute, queryParams: queryParams });
  // }

  goToNext(): void {
    if (this.currentNavItemIndex <= this.navItems.length - 1) {
      this.currentNavItemIndex++;
      const queryParams = this.getQueryParams();
      this.router.navigate([`${this.navItems[this.currentNavItemIndex].Url}`], { relativeTo: this.activatedRoute, queryParams: queryParams });
    }
  }

  goToPrevious(): void {
    if (this.currentNavItemIndex > 0) {
      this.currentNavItemIndex--;
      const queryParams = this.getQueryParams();
      this.router.navigate([`${this.navItems[this.currentNavItemIndex].Url}`], { relativeTo: this.activatedRoute, queryParams: queryParams });
    }
  }

  getQueryParams() {
    let queryParams = {};
    if (this.requiredSteps) queryParams = { requiredSteps: encodeURIComponent(JSON.stringify(this.requiredSteps)) };
    if (this.alternateView) queryParams = { ...queryParams, alternateView: this.alternateView };
    return queryParams;
  }

  logout() {
    this.authService.logout();
  }

  onActivate(childComponentReference: any) {
    // allows us to set the taskCompleted flag on child to what it currently is the navItems (this is so the user doesn't have to redo the MAA again if they click a previous button)
    childComponentReference.loadTaskCompleted(this.navItems[this.currentNavItemIndex].TaskCompleted);

    //Below will subscribe to the taskCompletedEvent emitter, allows us to comunicate from child to parent
    childComponentReference.taskCompletedEvent.subscribe((data: any) => {
      // Will receive the data from child here
      this.navItems[this.currentNavItemIndex].TaskCompleted = data;
    });

    childComponentReference.goToNextEvent.subscribe(() => {
      this.goToNext();
    });

    childComponentReference.goToPreviousEvent.subscribe(() => {
      this.goToPrevious();
    });

    childComponentReference.goToPortalEvent.subscribe((value: string) => {
      const queryParams = { fromOnboarding: true };
      if (value == 'Training') this.router.navigate(['/Portal/CarriersTraining'], { queryParams: queryParams });
      else this.router.navigate(['/Portal/Dashboard'], { queryParams: queryParams });
    });
  }

  openMenu() {
    this.menuOpen = !this.menuOpen;
  }

  selectMeAsUser() {
    return;
    // this.delegateId = null;
    // this.viewingUser = this.user;
    // this.userApiService.getLoggedInUserPermissions().subscribe((result) => {
    //   this.permissionService.removeStoredOnBehalfOfUserId();
    //   this.permissionService.setPermissions(result);
    //   window.location.href = '/Portal';
    // });
  }

  selectOnBehalfOfUser(delegateId: string) {
    const delegate = this.delegates.find((row) => row.Id == delegateId);
    if (delegate) {
      this.viewingUser = delegate.User;
      this.delegateId = delegateId;
      this.permissionService.setOnBehalfOfUserId(delegate.OnBehalfOfUserId);
      this.permissionService.setUserDelegateId(delegate.Id);
      return this.permissionApiService.getDelegatePermissions(delegate.Id).subscribe((permissions) => {
        this.permissionService.setPermissions(permissions);
        window.location.href = '/Portal';
      });
    }
    return;
  }

  setCurrentNavItem(index: number): void {
    if (index >= 0 && index <= this.navItems.length - 1) {
      this.currentNavItemIndex = index;
    }
  }
}
