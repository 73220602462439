import { Component, OnInit } from '@angular/core';
import { fadeIn, slideInOut } from 'src/app/animations';
import { PermissionService } from 'src/app/core/services/auth/permission.service';

@Component({
  animations: [fadeIn, slideInOut],
  selector: 'app-countdown-timer',
  templateUrl: './countdown-timer.component.html',
})
export class CountdownTimerComponent implements OnInit {
  countDownDate = new Date(Date.UTC(2021, 10, 15, 5, 0, 0));
  countDownUTCTime = this.countDownDate.getTime() + this.countDownDate.getTimezoneOffset();
  result: string = '';
  interval: any = 0;
  results: TimeUnit = {};

  constructor(
  ) { }

  ngOnInit(): void {
    this.interval = setInterval(this.update.bind(this), 1000);
  }

  update(): void {
    // Get today's date and time
    const nowDate = new Date();
    const now = nowDate.getTime() + nowDate.getTimezoneOffset();

    // Find the distance between now and the count down date
    const distance = this.countDownUTCTime - now;

    // Time calculations for days, hours, minutes and seconds
    const days = Math.floor(distance / (1000 * 60 * 60 * 24)),
      hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
      seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Display the result in the element with id="demo"
    this.result = days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's ';
    this.results = { Days: days, Hours: hours, Minutes: minutes, Seconds: seconds };
    if (distance <= 0) {
      this.result = '0d 0h 0m 0s';
      clearInterval(this.interval); // stop timer
    }
  }
}

interface TimeUnit {
  Days?: number,
  Hours?: number,
  Minutes?: number,
  Seconds?: number
}