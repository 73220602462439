import { Injectable } from '@angular/core';
import { PortalNavItem } from 'src/app/shared/models/portal-nav-item';
import { environment } from 'src/environments/environment';
import { PermissionService } from './auth/permission.service';
import { SessionStoreService } from './stores/session-store.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private environment = environment;
  private isArcUser = this.sessionStore.User.IsArcUser;
  private isFigProd = this.sessionStore.User.Id === 'a608d7e6-a9fc-44f5-850b-6c660f0f1fca';
  get portalNavItems(): PortalNavItem[] {
    return this._portalNavItems;
  }

  constructor (
    private permissionService: PermissionService,
    private sessionStore: SessionStoreService,
  ) {
  }

  filterSubNavItem(items: PortalNavItem[]): PortalNavItem[] {
    return items.filter(item => {
      if (item.Resolve) return item.Resolve();
      else if (item.SubNav && item.SubNav.length) {
        for (const subnav of item.SubNav) {
          if (subnav.Resolve && subnav.Resolve()) return true;
        }
      }
      return true;
    });
  }

  private _portalNavItems: PortalNavItem[] = [
    {
      Name: 'Home',
      RouterUrl: 'Dashboard',
      Icon: 'home',
    },
    {
      Name: 'Explore',
      RouterUrl: 'FigPlatform',
      Icon: 'map',
    },
    {
      Name: 'Quinci',
      RouterUrl: 'ProductRDPreview',
      FigIcon: 'fig-quinci',
      Resolve: (): boolean => {
        return this.permissionService.hasAllPermissions('ShowProductRDOptIn:View')
          && !this.permissionService.hasOnePermission('ProductRDOptedIn:View');
      },
      IsNew: false,
    },
    {
      Name: 'Quinci',
      RouterUrl: 'ProductRD',
      FigIcon: 'fig-quinci',
      Resolve: (): boolean => {
        return this.permissionService.hasAllPermissions('ProductRDOptedIn:View');
      },
      IsNew: false,
      SubNav: [
        {
          Name: 'Home',
          RouterUrl: 'ProductRD/Preview',
          FigIcon: 'fig-quinci',
          Id: 0,
          Resolve: (): boolean => this.permissionService.hasAllPermissions('ProductRDOptedIn:View')
        },
        {
          Name: 'Education',
          RouterUrl: 'ProductRD/Education',
          Icon: 'local_library',
          Id: 1,
          // replace showIfAll with ShowIfOne on frontend nav
          SubNav: [
            { Name: 'Fixed Indexed Annuities', RouterUrl: 'ProductRD/Education/FixedIndexedAnnuities', Resolve: (): boolean => this.permissionService.hasAllPermissions('EducationFixedIndexedAnnuities:View') },
            { Name: 'Life', RouterUrl: 'ProductRD/Education/LifeInsuranceNew', Resolve: (): boolean => this.permissionService.hasAllPermissions('EducationLifeInsurance:View') },
            { Name: 'Care Planning', RouterUrl: 'ProductRD/Education/CarePlanning', Resolve: (): boolean => this.permissionService.hasAllPermissions('EducationCarePlanning:View') },
            { Name: 'Disability', RouterUrl: 'ProductRD/Education/Disability', Resolve: (): boolean => this.permissionService.hasAllPermissions('EducationDisability:View') },
            { Name: 'Medicare', RouterUrl: 'ProductRD/Education/Medicare', Resolve: (): boolean => this.permissionService.hasAllPermissions('EducationMedicare:View') },
            { Name: 'RILAs', RouterUrl: 'ProductRD/Education/RILAs', Resolve: (): boolean => this.permissionService.hasAllPermissions('EducationRILAs:View') },
            { Name: 'Structured Investments', RouterUrl: 'ProductRD/Education/StructuredInvestments', Resolve: (): boolean => this.permissionService.hasAllPermissions('EducationStructuredInvestments:View') },
            { Name: 'Variable Annuities', RouterUrl: 'ProductRD/Education/VariableAnnuities', Resolve: (): boolean => this.permissionService.hasAllPermissions('EducationVariableAnnuities:View') },
          ],
          Description: 'We partner with SIMON Technologies to bring you product training and educational resources across a suite of product lines from Fixed Indexed Annuity to Structured Investments and everything in-between. Become equipped to write new business instantly&nbsp;today!',
          DescriptionShort: 'Learn and explore various financial products and offerings.',
          TileBackgroundImage: 'https://images.unsplash.com/photo-1481627834876-b7833e8f5570?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=841&q=80',
        },
        {
          Description: 'Financial planning made simple with easy to use tools that provide high level illustrations of your clients portfolio.',
          DescriptionShort: 'Create a financial plan that is tailored to your clients needs.',
          TileBackgroundImage: 'https://images.unsplash.com/photo-1567427017947-545c5f8d16ad?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=995&q=80',
          Icon: 'show_chart',
          Id: 2,
          Name: 'Financial Planning',
          RouterUrl: 'ProductRD/FinancialPlanning',
          Resolve: (): boolean => this.permissionService.hasOnePermission('RetireUp:View|IpipelineFormsQuotesAnnuity:Access|AnnuityFormsWizard:View|LtciPartnersWebsite:View|Cannex:Access|AnnuitiesGenius:View|FinancialPlanningSIMONSpectrum:View|AnnuityIncomeAnalysis:View|MoneyGuideElite:View'),
          TileSubNav: [
            { Name: 'SIMON Spectrum', RouterUrl: 'ProductRD/FinancialPlanning/SimonSpectrum', Resolve: (): boolean => this.permissionService.hasAllPermissions('FinancialPlanningSIMONSpectrum:View') },
            { Name: 'RetireUp Pro', RouterUrl: 'FigPlatform/Details/RetireUp', Resolve: (): boolean => this.permissionService.hasAllPermissions('RetireUp:View') },
            { Name: 'MoneyGuid Pro', ExternalUrl: 'https://www.moneyguidepro.com/ifa/SignOn.aspx', Resolve: (): boolean => this.permissionService.hasAllPermissions('MoneyGuideElite:View') },
          ]
        },
        {
          Description: 'Delivering risk-managed solutions to investment professionals across multiple asset classes, centralized under one marketplace. Powered by our exclusive carriers and&nbsp;issuers.',
          DescriptionShort: 'Shop the marketplace for products that fit your clients financial plan.',
          TileBackgroundImage: 'https://images.unsplash.com/photo-1472851294608-062f824d29cc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1050&q=80',
          Name: 'Marketplace',
          RouterUrl: 'ProductRD/Marketplace',
          Icon: 'storefront',
          Id: 3,
          Resolve: (): boolean => this.permissionService.hasOnePermission('MarketplaceAnnuity:View|MarketplaceStructuredInvestments:View'),
          SubNav: [
            {
              Name: 'Overview',
              RouterUrl: 'ProductRD/Marketplace/Overview',
              Resolve: (): boolean => this.permissionService.hasOnePermission('MarketplaceAnnuity:View|MarketplaceStructuredInvestments:View')
            },
            {
              Name: 'Annuities',
              RouterUrl: 'ProductRD/Marketplace/Details/Annuities',
              Resolve: (): boolean => this.permissionService.hasAllPermissions('MarketplaceAnnuity:View')
            },
            {
              Name: 'Structured Investments',
              RouterUrl: 'ProductRD/Marketplace/Details/Structured Notes',
              Resolve: (): boolean => this.permissionService.hasAllPermissions('MarketplaceStructuredInvestments:View')
            },
            {
              Name: 'SI Builder',
              RouterUrl: 'ProductRD/Marketplace/StructuredInvestmentsBuilder',
              Resolve: (): boolean => this.permissionService.hasAllPermissions('MarketplaceStructuredInvestments:View')
            },
          ]
        },
        {
          Description: 'Procure a full suite of financial products via our exclusive E-Application&nbsp;process.',
          DescriptionShort: 'Procure a full suite of financial products via our exclusive E-Application&nbsp;process.',
          TileBackgroundImage: 'https://images.unsplash.com/photo-1598970434795-0c54fe7c0648?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80',
          Name: 'E-Application',
          RouterUrl: 'ProductRD/EApplication/Workflow',
          Icon: 'drive_file_rename_outline',
          Id: 4,
          Resolve: (): boolean => this.permissionService.hasOnePermission('SubmitPaperApp:View|IpipelineEApps:Access|Firelight:Access'),
          SubNav: [
            { Name: 'Start E-Application', RouterUrl: 'ProductRD/EApplication/Workflow', Resolve: (): boolean => this.permissionService.hasOnePermission('SubmitPaperApp:View|IpipelineEApps:Access|Firelight:Access') },
            { Name: 'Application History', RouterUrl: 'ProductRD/EApplication/History', Icon: 'storefront' },
          ]
        },
        {
          Description: 'Track and analyze all of your pending and inforce business across all lines of business.',
          DescriptionShort: 'Track and analyze all of your pending and inforce business across all lines of business.',
          Icon: 'work',
          Id: 5,
          Name: 'Lifecycle',
          RouterUrl: 'Business/Fixed/Lifecycle',
          Resolve: (): boolean => this.permissionService.hasAllPermissions('Reports:NewBusiness|ProductRDOptedIn:View'),
          IsNew: false,
        },
      ]
    },
    {
      Name: 'Contracting & Training',
      RouterUrl: 'CarriersTraining',
      Icon: 'school',
      Resolve: (): boolean => this.permissionService.hasAllPermissions('SureLc:Access')
    },
    {
      Name: 'Business',
      RouterUrl: 'MyBusiness',
      Icon: 'work',
      Resolve: (): boolean => {
        return this.permissionService.hasAllPermissions('Reports:NewBusiness') && !this.permissionService.hasOnePermission('ProductRDOptedIn:View');
      }
    },
    {
      Name: 'Business',
      RouterUrl: 'Business',
      Icon: 'cases',
      Resolve: (): boolean => this.permissionService.hasAllPermissions('Reports:NewBusiness|ProductRDOptedIn:View'),
      IsNew: false,
      SubNav: [
        {
          Name: 'Fixed',
          FigIcon: 'fig-product-fixed',
          RouterUrl: 'Business/Fixed',
          SubNav: [
            { Name: 'Pending', RouterUrl: 'Business/Fixed/Pending' },
            { Name: 'Inforce', RouterUrl: 'Business/Fixed/Inforce' },
            { Name: 'Lifecycle', RouterUrl: 'Business/Fixed/Lifecycle', IsNew: false },
            { Name: 'Informal', RouterUrl: 'Business/Fixed/Informal' },
          ]
        },
        {
          Name: 'Variable',
          FigIcon: 'fig-product-variable',
          RouterUrl: 'Business/Variable',
          Resolve: (): boolean => this.permissionService.hasAllPermissions('ProductRDOptedIn:View|Reports:VariableBusiness'),
          SubNav: [
            { Name: 'Pending', RouterUrl: 'Business/Variable/Pending', Resolve: (): boolean => this.permissionService.hasAllPermissions('ProductRDOptedIn:View|Reports:VariableBusiness') },
            { Name: 'Inforce/Lifecycle', RouterUrl: 'Business/Variable/Lifecycle', Resolve: (): boolean => this.permissionService.hasAllPermissions('ProductRDOptedIn:View|Reports:VariableBusiness') },
          ]
        },
        {
          Name: 'Structured',
          FigIcon: 'fig-product-structured',
          RouterUrl: 'Business/Structured',
          Resolve: (): boolean => this.permissionService.hasAllPermissions('ProductRDOptedIn:View|Reports:StructuredBusiness'),
          SubNav: [
            { Name: 'Indication of Interest', RouterUrl: 'Business/Structured/Structured-Indication-Of-Interest' },
            {
              Name: 'Holdings', RouterUrl: 'Business/Structured/Structured-Holdings', Resolve: (): boolean => {
                return !this.environment.production || this.isArcUser || this.isFigProd;
              }
            },
            { Name: 'Lifecycle', RouterUrl: 'Business/Structured/Structured-Lifecycle' },
            { Name: 'Past Offerings', RouterUrl: 'Business/Structured/Past-Offerings' },
          ]
        },
        {
          Name: 'Wealth',
          Icon: 'attach_money',
          RouterUrl: 'Business/Wealth',
          Resolve: (): boolean => {
            return !!this.sessionStore.UserAcmAgent;
          }
        }
      ]
    },
    {
      Name: 'Toolbox',
      RouterUrl: 'Toolbox',
      Icon: 'home_repair_service',
    }
  ];
}
