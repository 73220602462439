<div class="flex flex-col items-center m-auto mt-40 p-30 gap-y-40 sm:mt-72 text-gray-darker">
    <img class="h-48 sm:h-64" [src]="logoUrl" *ngIf="logoUrl">
    <div class="flex flex-col items-center justify-center w-full p-20 sm:h-512 sm:w-400 h-384 card gap-y-20">
        <span class="flex flex-none p-10 rounded-full bg-gray-lighter">
            <span class="m-auto material-icons text-gray">
                power_settings_new
            </span>
        </span>
        <p class="text-lg">You have successfully logged out</p>
        <!-- <span class="mt-5 text-base cursor-pointer text-blue hover:underline" (click)="goToLogIn()">Click here to log in again</span> -->
        <button class="button is-primary" mat-button (click)="goToLogIn()">Click here to log in again</button>
    </div>
    <span class="text-sm text-gray">Copyright &copy; <span>{{getYear()}}</span> Financial Independence Group, Inc. All Rights Reserved.</span>
    <!-- <iframe src="/api/SimonSso/Logout" style="display:none"></iframe> -->
    <!-- <iframe id="simonIframe" [src]="environment.simonIframeOrigin | safeUrl" class="hidden"></iframe> -->
</div>