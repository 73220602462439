import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-structured-past-offerings',
  templateUrl: './structured-past-offerings.component.html'
})
export class StructuredPastOfferingsComponent implements OnInit {
  iframeUrl!: string;
  showLocalSimon = false;
  environment = environment;

  constructor () { }

  ngOnInit(): void {
    this.openSimonSso();
  }

  openSimonSso(): void {
    if (this.showLocalSimon) {
      this.iframeUrl = environment.simonLocalUrl;
    } else {
      this.iframeUrl = environment.simonUrl;
    }
    this.iframeUrl += '/marketplace/past-offerings/all';
  }

}
