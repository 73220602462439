import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carriers-product-training',
  templateUrl: './carriers-product-training.component.html',
})
export class CarriersProductTrainingComponent {

  constructor() { }
}
