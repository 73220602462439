<div class='flex flex-col w-full h-full overflow-hidden'>
  <section class="flex flex-col flex-none">
    <div id="userLogoUploadToolbar"
      class="flex items-center flex-none p-20 border-b border-solid lg:p-30 border-gray-lighter">
      <h1 class="mr-auto text-lg font-bold">Manage Site Logo</h1>
      <!-- <button class="icon-button is-primary is-inverted" tabindex="-1" attr.aria-label="Close"
        (click)="closeModal($event, refresh)" type="button"><i class="material-icons">close</i>
      </button> -->
    </div>
  </section>
  <section class="flex flex-col justify-center flex-auto p-10 overflow-auto">

    <div class="flex flex-col items-center m-20 text-center gap-y-20" @fadeIn *ngIf="!uploadingFile">
      <p class="text-sm text-gray">{{ user.HasUserLogo ? 'Current Logo' : 'Default Logo'}}</p>
      <div class="flex flex-col items-center m-10 gap-y-10">
        <ng-container *ngIf="user.HasUserLogo">
          <img class="w-auto h-48" [src]="logo">
          <button mat-button class="mx-auto button is-warning is-inverted is-sm" aria-label="Delete file button"
            type="button" (click)="confirmDeleteLogo()">
            Remove Logo Image
          </button>
        </ng-container>
        <ng-container *ngIf="!user.HasUserLogo">
          <img class="w-auto h-48" [src]="figLogo">
        </ng-container>
      </div>
    </div>

    <div class="flex flex-col items-center m-20 text-center gap-y-20"  @fadeIn>
      <ng-container *ngIf="!uploadingFile">
        <div
          class="relative flex flex-col items-center justify-center w-full p-20 text-center transition duration-150 border-2 border-dashed rounded cursor-pointer cursror-pointer border-gray-lighter min-h-128 hover:text-blue hover:border-blue text-gray"
          [class]="dragover? 'border-blue text-blue':''">
          <input class="absolute top-0 left-0 w-full h-full opacity-0" accept=".png, .jpg, .jpeg"
            (dragover)="dragOverHandler($event)" type="file" id="file" (change)="uploadFiles($event)">
          <label class="text-sm font-semibold">Click or drag image here to upload a new logo.</label>
          <label class="text-sm font-semibold">(Accepts PNG, JPG)</label>
        </div>
      </ng-container>

      <ng-container *ngIf="uploadingFile">
        <p class="text-sm text-gray">New Logo Preview</p>
        <div class="flex flex-col items-center w-full gap-y-10">
          <div class="flex items-center w-full h-64 shadow px-30">
            <img class="w-auto h-48" [src]="previewUrl">
            <div class="flex items-center ml-auto text-sm font-bold uppercase gap-x-10">
              <span>{{user.FirstName}} {{user.LastName}}</span>
              <span class="flex items-center justify-center rounded-full w-36 h-36 bg-gray-lightest text-blue-dark">
                <span>{{user.FirstName[0]}}{{user.LastName[0]}}</span>
              </span>
            </div>
          </div>
          <button mat-button class="mx-auto button is-primary is-inverted is-sm" type="button" (click)="removeFile()">
            Choose a different image
          </button>
        </div>
      </ng-container>
      <!-- TODO: Would be nice to make this "smarter & more self-contained" when it comes to show/hide behavior-->
      <app-inline-snackbar *ngIf="showInlineSnackbar" class="w-full" [data]="snackbarData" (inlineSnackbarClose)="showInlineSnackbar = false"></app-inline-snackbar>
    </div>
  </section>
  <section class="flex flex-none p-20 mt-auto border-t border-solid lg:p-30 border-gray-lighter">
    <button tabindex="-1" [disabled]="!uploadingFile" (click)="uploadLogo()"
      class="ml-auto button is-primary" type="button">
      Upload</button>
  </section>
</div>