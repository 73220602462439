
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { fadeIn } from 'src/app/animations';
import { searchFilter } from 'src/app/shared/helpers/search.helper';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { Title } from '@angular/platform-browser';
import { ToolboxCategory, ToolboxTile } from 'src/app/shared/models/toolbox.models';
import { ToolboxService } from 'src/app/core/services/toolbox.service';
import { User } from 'src/app/shared/models/user.models';
import { UserApiService } from 'src/app/core/services/http/user-api.service';
import { MatSidenav } from '@angular/material/sidenav';
import { sidenavConfig } from 'src/app/shared/models/sidenav-config.model';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CansellApiService } from 'src/app/core/services/http/cansell-api.service';
@Component({
  animations: [fadeIn],
  selector: 'app-toolbox-overview',
  templateUrl: './toolbox-overview.component.html'
})
export class ToolboxOverviewComponent implements OnInit {

  categories: ToolboxCategory[] = this.toolboxService.getVisibleCategories();
  favoriteTiles!: ToolboxTile[];
  featuredTiles: ToolboxTile[] = this.toolboxService.getFeaturedTiles();
  filteredList: ToolboxTile[] = [];
  isLoading: boolean = false;
  lodash = _;
  resourceUrl: string = 'Toolbox';
  searchCategories: ToolboxCategory[] = [];
  searchText: string = '';
  showFilteredList: boolean = false;
  tiles: ToolboxTile[] = this.toolboxService.getTiles();
  user!: User;
  sidenavConfig: sidenavConfig;

  constructor(
    private activatedRoute: ActivatedRoute,
    private sessionStore: SessionStoreService,
    private titleService: Title,
    private toolboxService: ToolboxService,
    private userApiService: UserApiService,
    private breakpointObserver: BreakpointObserver) {
    this.sidenavConfig = {
      IsOpened: true,
      Mode: 'side',
    };
  }


  ngOnInit(): void {
    this.breakpointObserver.observe([
      Breakpoints.WebLandscape,
    ]).subscribe(result => {
      if (result.matches) {
        this.sidenavConfig.IsOpened = true;
        this.sidenavConfig.Mode = 'side';
      } else {
        this.sidenavConfig.IsOpened = false;
        this.sidenavConfig.Mode = 'over';
      }
    });
    this.titleService.setTitle(this.resourceUrl + ' | Agent Portal');

    this.isLoading = true;
    this.user = this.sessionStore.User;
    this.favoriteTiles = this.toolboxService.getUserFavorites(this.user.Id);
    this.isLoading = false;
    const selectedCategory: string = this.activatedRoute.snapshot.paramMap.get('category')!;
    const selectedCategoryIndex: number = this.categories.findIndex(cat => cat.Name === selectedCategory);
    if (!!selectedCategory && selectedCategoryIndex !== -1) {
      this.categories[selectedCategoryIndex].Selected = true;
      this.updateSearchCategories();
    }
    // clear search, and selected cats when navigating back to sibling state
    if (!selectedCategory) {
      this.clearSearch();
      this.categories.forEach(category => {
        category.Selected = false;
      });
    }
  }

  toggleSidenav(sidenav: MatSidenav): void {
    sidenav.toggle();
  }

  applyFilters() {
    this.showFilteredList = !!this.hasFilterCriteria();
    let filteredList: ToolboxTile[] = [...this.tiles];
    filteredList = filteredList.filter(tile => searchFilter(tile, this.searchText));
    filteredList = !!this.searchCategories && this.searchCategories.length > 0 ? filteredList.filter(tile => this.filterTilesByCategory(tile, this.searchCategories)) : filteredList;
    this.filteredList = filteredList;
  }

  clearSearch() {
    this.searchText = '';
    this.showFilteredList = false;
  }

  filterCategory(items: any[], filterName: string) {
    return _.filter(items, item => {
      return item.Category === filterName;
    });
  }

  filterTilesByCategory(tile: ToolboxTile, searchCategories: ToolboxCategory[]): boolean {
    let categoryMatch: boolean = true;

    if (searchCategories.length) {
      categoryMatch = searchCategories.some(category => {
        return category.Name.toLowerCase() == tile.Category.toLowerCase();
      });
    }

    return categoryMatch;
  }

  orderBy(items: any[], sortProperty: string[]) {
    return _.sortBy(items, sortProperty);
  }

  hasFilterCriteria(): boolean {
    return !!this.searchText || this.searchCategories.length > 0;
  }

  updateToolboxFavorites(tileToFavorite: ToolboxTile) {
    tileToFavorite.Loading = true;

    const favoriteTileIndex = this.favoriteTiles.findIndex(tile => tile.Id === tileToFavorite.Id);
    const tileIndex = this.tiles.findIndex(tile => tile.Id === tileToFavorite.Id);

    // temp Favorites array to cach potential changes incase the post fails
    const tempFavorites = [...this.favoriteTiles];

    // If tile is not a favorite yet, add it otherwise un-favorite it
    if (favoriteTileIndex === -1) tempFavorites.push(tileToFavorite);
    else tempFavorites.splice(favoriteTileIndex, 1);

    this.userApiService.updateUserToolboxFavorites(this.user.Id, tempFavorites.map((x: ToolboxTile) => x.Id))
      .subscribe(
        (res) => {
          if (favoriteTileIndex === -1) {
            this.favoriteTiles.push(tileToFavorite);
            this.tiles[tileIndex].IsFavorite = true;
          } else {
            this.favoriteTiles.splice(favoriteTileIndex, 1);
            this.tiles[tileIndex].IsFavorite = false;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          tileToFavorite.Loading = false;
        }
      );

  }

  updateSearchCategories() {
    this.searchCategories = this.categories.filter(x => x.Selected);
    this.applyFilters();
  }
}
